import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import MoreDetail from './MoreDetail'
import { ManageRefContainer, ManageRefWrap, ManageRefTable, ManageRefHeadWrap, ManageRefHeadRow, ManageRefHeader, ManageRefBodyWrap, ManageRefBodyRow, ManageRefCell, DetailButton, DeleteButtonWrap, Paginationwrap, PreviosWrap, PreviosIcon, NumberPageWrap, NumberPage, NextWrap, NextIcon } from './ManageRefElements';
import { manageRefTableHead, manageSmallRefTableHead } from '../../data/manageRefData';
import { CheckWrap, CheckLabel, CheckInput, CheckMark } from '../Auth/SignUpElements';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';

const ManageRef = ({ info, pageNumber, isNext, smallView }) => {
    const [isClicked, setIsClicked] = useState(false);
    const [dataIndex, setDataIndex] = useState();
    const [checkIndex, setCheckIndex] = useState([]);
    const [checkId, setCheckId] = useState(Object.freeze([]));
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState('');
    const [lenCheck, setLenCheck] = useState();
    const handleMoreClick = (index) => {
        setDataIndex(index);
        setIsClicked(true);
    };
    const handleCheck = (index, id) => {
        if (!checkIndex.includes(index)) {
            setCheckIndex(checkIndex => [...checkIndex, index]);
        } else {
            setCheckIndex(checkIndex.filter(removeIndex => removeIndex !== index));
        };
        if (!checkId.includes(id)) {
            setCheckId(checkId => [...checkId, id]);
        } else {
            setCheckId(checkId.filter(removeId => removeId !== id));
        };
    };
    const handleSubmit = () => {
        setSubmitted(true);
        if (checkId.length > 0) {
            setLenCheck(checkId.length);
        };
    };
    const fetchDeleteReferrals = async () => {
        try {
            await axiosInstance.post('ref/manage/referrals/delete/', checkId);
            setTimeout(() => window.location.reload(false), 3000);
            setDeleted(true);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    useEffect(() => {
        if (!deleted && checkId.length > 0 && submitted) {
            fetchDeleteReferrals();
        };
    });
    return (
        <>
            <ManageRefContainer>
                <ManageRefWrap>
                    <ManageRefTable>
                        <ManageRefHeadWrap>
                            <ManageRefHeadRow>
                                {!smallView
                                    ? manageRefTableHead.map((item, index) => (
                                        <ManageRefHeader key={index}>
                                            {item}
                                        </ManageRefHeader>
                                    ))
                                    : manageSmallRefTableHead.map((item, index) => (
                                        <ManageRefHeader key={index}>
                                            {item}
                                        </ManageRefHeader>
                                    ))
                                }
                            </ManageRefHeadRow>
                        </ManageRefHeadWrap>
                        <ManageRefBodyWrap>
                            {info.data.map((row, index) => (
                                <ManageRefBodyRow key={pageNumber > 1 && info.number / ((pageNumber - 1) * 50) < 1 ? info.number - index : info.number - index - 50 * (pageNumber - 1)}>
                                    <ManageRefCell>
				    	{pageNumber > 1 && info.number / ((pageNumber - 1) * 50) < 1 ? info.number - index : info.number - index - 50 * (pageNumber - 1)}
                                    </ManageRefCell>
                                    {!smallView &&
                                        <>
                                            <ManageRefCell>
                                                {row.profile.username}
                                            </ManageRefCell>
                                            <ManageRefCell>
                                                {row.date_joined.replace('-', '/').split('T')[0].replace('-', '/')}
                                            </ManageRefCell>
                                        </>
                                    }
                                    <ManageRefCell>
                                        {row.is_active ? 'active' : 'deactivate'}
                                    </ManageRefCell>
                                    <ManageRefCell>
                                        <DetailButton onClick={() => handleMoreClick(index + 1 + ((pageNumber - 1) * 2))}>
                                            more
                                        </DetailButton>
                                    </ManageRefCell>
                                    <ManageRefCell>
                                        <CheckWrap style={{ margin: 'auto' }}>
                                            <CheckLabel>
                                                <CheckInput type='checkbox' checked={checkIndex.includes(index + 1 + ((pageNumber - 1) * 2)) && true} onChange={() => handleCheck(index + 1 + ((pageNumber - 1) * 2), row.id)} />
                                                <CheckMark />
                                            </CheckLabel>
                                        </CheckWrap>
                                    </ManageRefCell>
                                    {!smallView && isClicked && dataIndex === index + 1 + ((pageNumber - 1) * 2)
                                        ? <ManageRefCell>
                                            <MoreDetail
                                                isClicked={isClicked}
                                                setIsClicked={setIsClicked}
                                                gender={row.gender}
                                                profileImg={row.profile.photo}
                                                lastLogin={row.last_login}
                                                level={row.interest.level}
                                                icecoin={row.balance.icecoin}
                                                pcoin={row.balance.pcoin}
                                                adscoin={row.balance.adcoin}
                                                refNumber={row.ref_number}
                                                refViewAdsClick={row.ref_clicks[0]}
                                                refFaucetClick={row.ref_clicks[1]}
					        refAdsDeposit={row.ref_ads_deposit ? row.ref_ads_deposit : 0}
                                            />
                                        </ManageRefCell>
                                        : smallView && isClicked && dataIndex === index + 1 + ((pageNumber - 1) * 2)
                                            ? <ManageRefCell>
                                                <MoreDetail
                                                    isClicked={isClicked}
                                                    setIsClicked={setIsClicked}
                                                    gender={row.gender}
                                                    profileImg={row.profile.photo}
                                                    lastLogin={row.last_login}
                                                    level={row.interest.level}
                                                    icecoin={row.balance.icecoin}
                                                    pcoin={row.balance.pcoin}
                                                    adscoin={row.balance.adcoin}
                                                    refNumber={row.ref_number}
                                                    refViewAdsClick={row.ref_clicks[0]}
                                                    refFaucetClick={row.ref_clicks[1]}
                                                    username={row.profile.username}
                                                    dateJoined={row.date_joined.replace('-', '/').split('T')[0].replace('-', '/')}
                                                />
                                            </ManageRefCell>
                                            : null
                                    }
                                </ManageRefBodyRow>
                            ))}
                        </ManageRefBodyWrap>
                    </ManageRefTable>
                    {deleted &&
                        <MsgWrap primary={1}>
                            <MsgText primary={1}>
                                You deleted {lenCheck} referrals successfully and the cost of this process became {lenCheck * 10} primecoins.
                            </MsgText>
                        </MsgWrap>
                    }
	            {!error && info.number > 50 &&
                    	<Paginationwrap>
                            {pageNumber !== 1 &&
                            	<PreviosWrap to={`${pageNumber - 1}`}>
                                    <PreviosIcon />
                            	</PreviosWrap>
                            }
                            <NumberPageWrap>
                            	<NumberPage>
                                    {pageNumber}
                            	</NumberPage>
                            </NumberPageWrap>
                            {isNext &&
                            	<NextWrap to={`${pageNumber + 1}`}>
                                    <NextIcon />
                            	</NextWrap>
                            }
                    	</Paginationwrap>
		    }
                    {error &&
                        <MsgWrap primary={0} style={{ width: '80%' }}>
                            <MsgText primary={0}>
                                {error}
                            </MsgText>
                        </MsgWrap>
                    }
                    <DeleteButtonWrap>
                        <TinyFormButton type='button' onClick={handleSubmit}>
                            Delete
                        </TinyFormButton>
                    </DeleteButtonWrap>
                </ManageRefWrap>
            </ManageRefContainer>
        </>

    )
}

export default ManageRef;
