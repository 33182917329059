import React, { useState } from 'react';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../Stack/StackElements';
import { InputWrap, Input } from './PersonalityElements';
import { PasswordIcon, ConfirmPasswordIcon, InputContainer } from './ChangePasswordElements';
import { MsgWrap, MsgText } from '../MessageElements';

const ChangePassword = ({ setValues, values }) => {
    const [errorPassword, setErrorPassword] = useState('');
    const [errorPassword2, setErrorPassword2] = useState('');

    const handleChange = (e) => {
        let { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setErrorPassword('');
        setErrorPassword2('');
    };

    const ValidateChangePassword = () => {
        if (!values.password) {
	    setErrorPassword('The password is required.')
	} else if (values.password.length < 8) {
	    setErrorPassword('Password needs to be 8 characters or more')
	} else if (values.password.length > 32) {
	    setErrorPassword('Your password is too long.')
	} else if (/^[0-9]+$/i.test(values.password)) {
	    setErrorPassword('Your password should not be entirely numeric.')
	} else if (/^[A-Z]+$/i.test(values.password)) {
	    setErrorPassword('Your password should not be entirely alphabetic.')
	} else if (/^[-_.*$#@]+$/i.test(values.password)) {
	    setErrorPassword('Your password should have at least one alphabetic and numeric character.')
	} else if (/^[A-Z0-9]+$/i.test(values.password)) {
	    setErrorPassword('Your password should have at least one character in {@, #, $, *, _, -, .}.')
	} else if (/^[A-Z-_.*$#@]+$/i.test(values.password)) {
	    setErrorPassword('Your password should have at least one numeric character.')
	} else if (/^[0-9-_.*$#@]+$/i.test(values.password)) {
	    setErrorPassword('Your password should have at least one alphabetic character.')
	} else if (!(/^[A-Z0-9-_.*$#@]+$/i.test(values.password))) {
	    setErrorPassword('Password is invalid')
	};
    };

    const handleBlurPassword = () => {
        values.password && ValidateChangePassword();
    };

    const handleBlurPassword2 = () => {
        values.password !== values.confirm_password && setErrorPassword2('Passwords don\'t match');
    };

    return (
        <StackContainer primary={1}>
            <StackWrap primary={1}>
                <TitleWrap primary={1}>
                    <h2>
                        change password
                    </h2>
                </TitleWrap>
                <StackDiscWrap style={{ width: '70%' }}>
                    <InputContainer action='#'>
                        <InputWrap>
                            <PasswordIcon />
                            <Input
                                primary={1}
                                type='password'
                                name='password'
                                placeholder='Enter your new password'
                                onChange={handleChange}
                                value={values.password}
                                onBlur={handleBlurPassword}
                            />
                        </InputWrap>
                        <InputWrap>
                            <ConfirmPasswordIcon />
                            <Input
                                primary={1}
                                type='password'
                                name='confirm_password'
                                placeholder='Confirm your password'
                                onChange={handleChange}
                                value={values.confirm_password}
                                onBlur={handleBlurPassword2}
                            />
                        </InputWrap>
                        {errorPassword &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {errorPassword}
                                </MsgText>
                            </MsgWrap>
                        }
                        {errorPassword2 &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {errorPassword2}
                                </MsgText>
                            </MsgWrap>
                        }
                    </InputContainer>
                </StackDiscWrap>
            </StackWrap>
        </StackContainer>
    )
}

export default ChangePassword;
