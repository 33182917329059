import React from 'react';
import SetUpAds from '../components/SetUpAds';

const SetUpAdsPage = () => {
    return (
        <>
            <SetUpAds />
        </>
    )
}
export default SetUpAdsPage;