import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../Stack/StackElements';
import { TypeContainer, TypeWrap, TypeImgWrap, TypeImg, CryptoContainer, CryptoWrapper, CryptoWrap, CryptoImgWrap, CryptoImg } from './FinancialInfoElements';
import { SettingsTypePaymentsData } from '../../data/paymentsData';
import { InputWrap, Input } from './PersonalityElements';
import { MsgWrap, MsgText } from '../MessageElements';

const FinancialInfo = ({ info, values, setValues }) => {
    const [typeWallet, setTypeWallet] = useState('');
    const [cryptoInfo, setCryptoInfo] = useState();
    const [uniqueError, setUniqueError] = useState('');
    const handleBlurAddress = () => {
        (values.tetherFP.length > 0 || values.bitcoinFP.length > 0) && fetchCheckFaucetpayAddress();
    };
    const fetchCheckFaucetpayAddress = async () => {
        try {
            await axiosInstance.post('settings/address-faucetpay-check/', {
                tetherFP: values.tetherFP,
                bitcoinFP: values.bitcoinFP
            });
        } catch (error) {
            error.response && error.response.data.detail ? setUniqueError(error.response.data.detail) : setUniqueError(error.message);
        };
    };
    const handleTypeClick = (type) => {
	if (type !== typeWallet) {
	    setTypeWallet(type);
	    fetchCryptoGatewayInfo(type);
	}
    };
    const fetchCryptoGatewayInfo = async (type) => {
	try {
	    const { data } = await axiosInstance.post('settings/crypto-info/', {
		'type': type
	    });
	    setCryptoInfo(data.info);
	} catch (error) {
	    error.response && error.response.data.detail ? setUniqueError(error.response.data.detail) : setUniqueError(error.message);
	};
    };
    const handleChange = (e) => {
        let { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setUniqueError('');
    };
    return (
        <StackContainer primary={0}>
            <StackWrap primary={0}>
                <TitleWrap primary={0}>
                    <h2>
                        financial information
                    </h2>
                </TitleWrap>
                <StackDiscWrap>
                    <TypeContainer>
                        <TypeWrap>
                            {SettingsTypePaymentsData.map((gateway, index) => (
				<TypeImgWrap key={index} onClick={() => handleTypeClick(gateway.type)} active={typeWallet === gateway.type ? 1 : 0}>
				    <TypeImg src={gateway.src} alt={gateway.alt} />
				</TypeImgWrap>
			    ))}
                        </TypeWrap>
                    </TypeContainer>
	    	    {cryptoInfo && cryptoInfo.map((crypto, index) => (
                        <CryptoContainer action='#' key={index}>
			    <CryptoWrapper>
                                <CryptoWrap active={crypto.active_withdraw ? 1 : 0}>
                                    <CryptoImgWrap>
                                        <CryptoImg src={crypto.image} alt={crypto.name} />
                                    </CryptoImgWrap>
                                    <InputWrap>
                                        <Input
                                            style={{ paddingLeft: '15px' }}
                                            type='text'
                                            name={crypto.name}
                                            primary={0}
                                            placeholder={crypto.active_withdraw ? `Enter your faucetpay RECEIVED address for ${crypto.name}` : crypto.reason_deactive_withdraw}
                                            onChange={handleChange}
                                            value={crypto.withdraw_name === 'tron' && info.tron !== undefined ? values.tron
						: crypto.withdraw_name === 'tether' && info.tether !== undefined ? values.tether
						    : crypto.withdraw_name === 'dogecoin' && info.dogecoin !== undefined ? values.dogecoin
						        : crypto.withdraw_name === 'bitcoin' && info.bitcoin !== undefined ? values.bitcoin
						            : crypto.withdraw_name === 'toncoin' && info.toncoin !== undefined ? values.toncoin
						                : crypto.withdraw_name === 'solana' && info.solana !== undefined ? values.solana
						                    : crypto.withdraw_name === 'tetherFP' && info.tetherFP !== undefined ? values.tetherFP
						                        : crypto.withdraw_name === 'bitcoinFP' && info.bitcoinFP !== undefined ? values.bitcoinFP
						                            : ''}
                                            onBlur={handleBlurAddress}
                                        >
                                        </Input>
                                    </InputWrap>
                                </CryptoWrap>
			    </CryptoWrapper>
                        </CryptoContainer>
		    ))}
                </StackDiscWrap>
                {uniqueError &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {uniqueError}
                        </MsgText>
                    </MsgWrap>
                }
            </StackWrap>
        </StackContainer>
    )
}
export default FinancialInfo;
