export const instructionsLangData = [
    {
        lang: 'en',
        info: {
            text: 'In order to validate the selected language, you have to answer five easy questions within 1 minute. You must answer all questions correctly. Please press the following button if you are ready.',
            button: 'start',
	    completeButton: 'complete'
        }
    },
    {
        lang: 'es',
        info: {
            text: 'Para validar el idioma seleccionado, debe responder cinco preguntas fáciles en 1 minuto. Debe responder todas las preguntas correctamente. Por favor presione el siguiente botón si está listo.',
            button: 'comenzar',
	    completeButton: 'completo'
        }
    },
    {
        lang: 'ru',
        info: {
            text: 'Чтобы подтвердить выбранный язык, вам необходимо ответить на пять простых вопросов в течение 1 минуты. Вы должны правильно ответить на все вопросы. Пож�луйста, нажмите кнопку, если вы готовы.',
            button: 'начало',
	    completeButton: 'полный'
        }
    },
    {
        lang: 'pt',
        info: {
            text: 'Para validar o idioma selecionado, você deve responder a cinco perguntas fáceis em 1 minuto. Você deve responder todas as perguntas corretamente. Por favor, pressione o botão a seguir se estiver pronto.',
            button: 'começar',
	    completeButton: 'completo'
        }
    },
    {
        lang: 'fr',
        info: {
            text: 'Afin de valider la langue sélectionnée, vous devez répondre à cinq questions faciles en 1 minute. Vous devez répondre correctement à toutes les questions. Veuillez appuyer sur le bouton suivant si vous êtes p',
            button: 'commencer',
	    completeButton: 'complet'
        }
    },
    {
        lang: 'de',
        info: {
            text: 'Um die ausgewählte Sprache zu bestätigen, müssen Sie innerhalb von 1 Minute fünf einfache Fragen beantworten. Sie müssen alle Fragen richtig beantworten. Bitte drücken Sie die folgende Schaltfläche, wenn Sie bereit sind.',
            button: 'start',
	    completeButton: 'vollständig'
        }
    },
    {
        lang: 'it',
        info: {
            text: 'Per convalidare la lingua selezionata, devi rispondere a cinque semplici domande entro 1 minuto. Devi rispondere correttamente a tutte le domande. Premete il seguente pulsante se siete pronti.',
            button: 'inizio',
	    completeButton: 'completare'
        }
    }
];
