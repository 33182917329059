import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { FaBitcoin } from 'react-icons/fa';

export const featureHead = {
    header: 'Our Unique Special Features'
};

export const langFeatureHead = [
    {
        lang: 'es',
        info: {
            header: 'Nuestras características especiales únicas'
        }
    },
    {
        lang: 'ru',
        info: {
            header: 'Наш уникальный oсобые возможности'
        }
    },
    {
        lang: 'pt',
        info: {
            header: 'Nossos recursos especiais exclusivos'
        }
    },
    {
        lang: 'fr',
        info: {
            header: 'Notre caractéristiques spécial unique'
        }
    },
    {
        lang: 'de',
        info: {
            header: 'Unser einzigartiges besondere Merkmale'
        }
    },
    {
        lang: 'it',
        info: {
            header: 'Le nostre caratteristiche speciali uniche'
        }
    }
];

export const homeFeatures = [
    {
        title: 'Advertise',
        description: [
            {
                icon: <IoMdCheckmarkCircleOutline />,
                line: 'High performance with a low price',
            },
            {
                icon: <IoMdCheckmarkCircleOutline />,
                line: 'Set up your ads with many filters',
            },
            {
                icon: <IoMdCheckmarkCircleOutline />,
                line: 'Display your ads only to your referrals',
            },
            {
                icon: <IoMdCheckmarkCircleOutline />,
                line: 'Manage and control your ads with real information',
            },
            {
                icon: <IoMdCheckmarkCircleOutline />,
                line: 'Get more ads as a reward with iceFaucet Upcode system.',
            }
        ]
    },
    {
        title: 'Make Money',
        description: [
            {
                icon: <FaBitcoin />,
                line: 'New style to earn more money online',
            },
            {
                icon: <FaBitcoin />,
                line: 'A unique affiliate program with many promotional materials',
            },
            {
                icon: <FaBitcoin />,
                line: 'Ability to upgrade membership',
            },
            {
                icon: <FaBitcoin />,
                line: 'The harder you work, the more you earn',
            },
            {
                icon: <FaBitcoin />,
                line: 'Minimum withdrawal is as low as 0.25 USDT',
            }
        ]

    },
];
export const langHomeFeatures = [
    {
        lang: 'es',
        info: [
            {
                title: 'Anunciar',
                description: [
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Alto rendimiento con un precio bajo',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Configure your ads with many filters',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Muestre sus anuncios solo a sus referencias',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Gestiona y controla tus anuncios con información real',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Obtenga más anuncios como recompensa con el sistema iceFaucet Upcode',
                    }
                ]
            },
            {
                title: 'Ganar dinero',
                description: [
                    {
                        icon: <FaBitcoin />,
                        line: 'Nuevo estilo para ganar más dinero en línea',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Un programa de afiliados único con muchos materiales promocionales.',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Posibilidad de actualizar la membresía',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Cuanto más trabajas, más ganas',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'El retiro mínimo es tan bajo como0.25 USDT',
                    }
                ]

            },
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                title: 'Рекламировать',
                description: [
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Высокая производительность по низкой цене',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Настройте свои объявления с множеством фильтров',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Показывайте свою рекламу только своим рефералам',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Управляйте и контролируйте свои объявления с помощью реальной информации',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Получите больше рекламы в качестве награды с системой iceFaucet Upcode',
                    }
                ]
            },
            {
                title: 'Зарабатывать',
                description: [
                    {
                        icon: <FaBitcoin />,
                        line: 'Новый стиль, чтобы заработать больше денег в Интернете',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Уникальная партнерская программа с множеством рекламных материалов',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Возможность обновить членство',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Чем усерднее вы работаете, тем больше вы зарабатываете',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Минимальная сумма вывода всего 0.25 USDT',
                    }
                ]

            },
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                title: 'Anunciar',
                description: [
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Alta performance com preço baixo',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Configure seus anúncios com muitos filtros',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Exiba seus anúncios apenas para suas referências',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Gerencie e controle seus anúncios com informações reais',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Obtenha mais anúncios como recompensa com o sistema iceFaucet Upcode',
                    }
                ]
            },
            {
                title: 'Fazer dinheiro',
                description: [
                    {
                        icon: <FaBitcoin />,
                        line: 'Novo estilo para ganhar mais dinheiro online',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Um programa de afiliados exclusivo com muitos materiais promocionais',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Capacidade de atualizar filiação',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Quanto mais você trabalha, mais você ganha',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'A retirada mínima é tão baixa quanto 0.25 USDT',
                    }
                ]

            },
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                title: 'Afficher',
                description: [
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Haute performance à petit prix',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Configurez vos annonces avec de nombreux filtres',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Diffusez vos annonces uniquement auprès de vos filleuls',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Gérez et contrôlez vos annonces avec de vraies informations',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: "Obtenez plus d'annonces en récompense avec le système iceFaucet Upcode",
                    }
                ]
            },
            {
                title: "Faire de l'argent",
                description: [
                    {
                        icon: <FaBitcoin />,
                        line: "Nouveau style pour gagner plus d'argent en ligne",
                    },
                    {
                        icon: <FaBitcoin />,
                        line: "Un programme d'affiliation unique avec de nombreux supports promotionnels",
                    },
                    {
                        icon: <FaBitcoin />,
                        line: "Possibilité de mettre à niveau l'adhésion",
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Plus vous travaillez dur, plus vous gagnez',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Le retrait minimum est aussi bas que 0.25 USDT',
                    }
                ]

            },
        ]
    },
    {
        lang: 'de',
        info: [
            {
                title: 'Werben',
                description: [
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Hohe Leistung zum kleinen Preis',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Richten Sie Ihre Anzeigen mit vielen Filtern ein',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Zeigen Sie Ihre Anzeigen nur Ihren Empfehlungen an',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Verwalten und steuern Sie Ihre Anzeigen mit echten Informationen',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Erhalten Sie mehr Anzeigen als Belohnung mit dem iceFaucet Upcode-System',
                    }
                ]
            },
            {
                title: 'Geld verdienen',
                description: [
                    {
                        icon: <FaBitcoin />,
                        line: 'Neuer Stil, um online mehr Geld zu verdienen',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Ein einzigartiges Partnerprogramm mit vielen Werbematerialien',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Möglichkeit, die Mitgliedschaft zu aktualisieren',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Je härter Sie arbeiten, desto mehr verdienen Sie',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Die Mindestauszahlung beträgt nur 0.25 USDT',
                    }
                ]

            },
        ]
    },
    {
        lang: 'it',
        info: [
            {
                title: 'Pubblicizza',
                description: [
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Alte prestazioni con un prezzo basso',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Imposta i tuoi annunci con molti filtri',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Mostra i tuoi annunci solo ai tuoi referral',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Gestisci e controlla i tuoi annunci con informazioni reali',
                    },
                    {
                        icon: <IoMdCheckmarkCircleOutline />,
                        line: 'Ottieni più annunci come ricompensa con il sistema di upcode di iceFaucet',
                    }
                ]
            },
            {
                title: 'Fare soldi',
                description: [
                    {
                        icon: <FaBitcoin />,
                        line: 'Nuovo stile per guadagnare più soldi online',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Un programma di affiliazione unico con molti materiali promozionali',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: "Possibilità di aggiornare l'appartenenza",
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Più lavori duro, più guadagni',
                    },
                    {
                        icon: <FaBitcoin />,
                        line: 'Il prelievo minimo è di soli 0.25 USDT',
                    }
                ]

            }
        ]
    }
];
