import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const ContentWrap = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 15px 0;

    @media screen and ${device.lg} {
        grid-template-columns : 1fr 1fr;
        grid-row-gap: 30px;
    }

    @media screen and ${device.md} {
        grid-template-columns : 1fr;
        grid-row-gap: 30px;
    }
`
export const Card = styled.div`
    background: rgba(29, 107, 187, 0.5);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 2px 10px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    width: 200px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 15px;
    transform: ${({ primary }) => (primary ? 'scale(1.1)' : 'scale(1)')};

    @media screen and ${device.sm} {
        width: 160px;
        height: 144px;
        padding: 7px;
    }
`
export const ImgWrap = styled.div`
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : ' rgba(39, 117, 85, 0.8)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : ' rgba(39, 117, 85, 0.8)')};
    color: #fff;
    font-size: 1rem;
    border-radius: 5px;

    @media screen and ${device.sm} {
        width: 85px;
        height: 85px;
    }

    img {
        width: 90%;
        height: 90%;
        position: absolute;
    }
`