import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const FilterContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(29, 107, 187, 0.7);
    margin-top: 10px;
    padding: 10px;
`
export const InstructionWrap = styled.div``
export const Instruction = styled.h3`
    color: #fff;
    font-size: clamp(0.7rem, 3vw, 0.9rem);
    font-weight: 400;
    letter-spacing: 1px;
    word-spacing: 1.8px;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);

    strong {
        color: #01DBBB;
        text-shadow: 0px 1px 5px rgba(1, 219, 187, 0.7);
        line-height: 2;
    }
`

export const ItemWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease-in-out;

    @media screen and ${device.sm} {
        width: 55px;
        height: 55px;
    }}

    &:hover {
        transform: scale(1.09);
        transition: 0.3s ease-in-out;
    }
`
export const ItemName = styled.span`
    color: #fff;
    font-size: clamp(0.55rem, 1.8vw, 0.65rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#1D6BBB')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &:hover {
        background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#277555')};
        transition: 0.3s ease-in-out;
    }
`
export const FilterDurationWrap = styled.div`
    display: grid;
    grid-column-gap: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 10px 0;

    @media screen and ${device.tn} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 25px;
        grid-row-gap: 25px;
    }
`
export const FilterInputWrap = styled.div`
    width: 100%;
    background: #1D6BBB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 10px 5px;
`
export const RangeInput = styled.input`
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: rgba(39, 117, 85, 0.75);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5);
    border: 1px solid #01DBBB; 
    border-radius: 5px;
    transition: 0.3s ease-in-out;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5);
        background: #277555;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:hover {
            background: #01DBBB;
            transition: 0.3s ease-in-out;
        }
    }

    &::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border-bos
        background: #04AA6D;
        cursor: pointer;
    }

    &:hover {
        opacity: 1;
        transition: 0.3s ease-in-out;
    }

`
export const NumberInputWrap = styled.div`
    display: grid;
    grid-column-gap: 35px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
`
export const NumberInput = styled.input`
    width: 75px;
    padding: 2px 0 2px 5px;
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;

    ::placeholder {
        color: rgba(29, 107, 187, 0.7);
        font-size: 0.9rem;
    }
    &:focus {
        outline: 2px solid #01DBBB;;
    }
`
export const UpcodeWrap = styled.div`
    padding: 5px;

    span {
        color: #fff;
        font-size: 0.8rem;
        text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
`