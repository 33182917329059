import React from 'react';
import SettingContainer from '../components/SettingContainer';

const SettinigsPage = () => {
    return (
        <>
            <SettingContainer />
        </>
    )
}
export default SettinigsPage;
