export const ptcDeviceData = [
    {
        device: 'mobile',
        name: 'M'
    },
    {
        device: 'desktop',
        name: 'D'
    }
];
export const ptcGenderData = [
    {
        gender: 'male',
        name: 'M'
    },
    {
        gender: 'female',
        name: 'F'
    }
];
export const ptcRepeatData = [
    {
        duration: '24 hours',
        name: '1'
    },
    {
        duration: '12 hours',
        name: '2'
    },
    {
        duration: '8 hours',
        name: '3'
    }
];
export const ptcDurationData = [
    {
        duration: 10,
        name: '1.0',
    },
    {
        duration: 20,
        name: '1.5',
        price: '+50% CPC'
    },
    {
        duration: 30,
        name: '2.0',
        price: '+100% CPC'
    },
    {
        duration: 40,
        name: '2.5',
        price: '+150% CPC'
    },
];
