import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import axiosInstance from '../../axios';
import { Header, LeftWrap, LogoWrapLink, SubjectWrap } from '../TOS/TOSElements';
import { BigContainer, ContainerLogout, WrapperLogout, ContentWrap } from './LogoutElements';
import logo from '../../images/logo.png';
import { MsgWrap, MsgText } from '../MessageElements.js';
import { Circle } from '../Loading';

const Logout = () => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const fetchLogout = async () => {
        setLoading(true);
        try {
            await axios.post('https://icefaucet.com/api/logout/blacklist/', {
                refresh_token: localStorage.getItem('refresh_token')
            });
            setComplete(true);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
        setTimeout(() => setLoading(false), 2000);
    };
    useEffect(() => {
        fetchLogout();
        localStorage.clear();
        axiosInstance.defaults.headers['Authorization'] = null;
    }, []);
    return (
        <>
	    <Helmet>
	        <meta name="description" content="Logout process on iceFaucet." />
	        <title>iceFaucet | logout</title>
	    </Helmet>
            <BigContainer>
                <ContainerLogout>
                    <WrapperLogout>
                        <Header style={{ background: 'rgba(29, 107, 187, 0.4)' }}>
                            <LeftWrap>
                                <LogoWrapLink to='/'>
                                    <img src={logo} alt={'logo of icefaucet'} />
                                </LogoWrapLink>
                            </LeftWrap>
                            <SubjectWrap>
                                <h1>logout</h1>
                            </SubjectWrap>
                        </Header>
                        {!error &&
                            <ContentWrap>
                                {loading &&
                                    <>
                                        <span><Circle /></span>
                                        <p style={{ color: '#01DBBB' }}>Your account is logging out, please wait.</p>
                                    </>
                                }
                                {complete && !loading &&
                                    <p style={{ color: '#01DBBB' }}>We wait for your coming back. </p>
                                }
                            </ContentWrap>
                        }
                        {error &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {error}
                                </MsgText>
                            </MsgWrap>
                        }
                    </WrapperLogout>
                </ContainerLogout>
            </BigContainer>
        </>
    )
}
export default Logout;
