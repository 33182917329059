import axiosInstance from '../../axios';
import { BannerFaucetWrap, BigContainerFaucet } from '../DesktopBanner/DesktopBannerElements';
import Sponsor from '../Sponsor';

const FaucetActiveDesktopBanners = ({ desktopBanner }) => {
    const handleBannerClicked = (id) => {
	fetchBannerClicked(id);
    };
    const fetchBannerClicked = async (id) => {
	await axiosInstance.post('banner/clicked/', {
	    'id': id
	});
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    return (
	<>
	    {desktopBanner.p2 !== null &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto' }}>
		    <ins id='surf1'></ins>
		</div>
	    }
	    <BigContainerFaucet>
	        {desktopBanner.p3 !== null && desktopBanner.plan === 'A' &&
		    <div>
			<BannerFaucetWrap href={desktopBanner.p3.url} target='_blank' onClick={() => handleBannerClicked(desktopBanner.p3.banner_id)} onContextMenu={handleRightClick}>
			    <img src={desktopBanner.p3.banner_img ? desktopBanner.p3.banner_img : desktopBanner.p3.banner_url ? desktopBanner.p3.banner_url : null} alt={desktopBanner.p3.title} title={desktopBanner.p3.url.split('/')[2]} />
			</BannerFaucetWrap>
		    </div>
		}
	        {desktopBanner !== undefined && desktopBanner.p3 !== null && desktopBanner.plan === 'B' &&
		    <div>
			<iframe title='cryptocoinsad'
			    src={desktopBanner.p3.src}
			    style={{ width: `${desktopBanner.p3.width}px`, height: `${desktopBanner.p3.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			</iframe>
		    </div>
		}
	        {desktopBanner.p4 !== null && (
		    desktopBanner.is_sponsor ?
			<div style={{ maxWidth: '50%' }}>
			    <Sponsor data={desktopBanner.p4} />
			</div> :
			<div>
			    <iframe title='cryptocoinsad'
			        src={desktopBanner.p4.src}
			        style={{ width: `${desktopBanner.p4.width}px`, height: `${desktopBanner.p4.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			    </iframe>
			</div>
		)}
	    </BigContainerFaucet>
	    {desktopBanner.p5 !== null &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <iframe title='bittrafficads'
		        src={desktopBanner.p5.src}
		        style={{ width: `${desktopBanner.p5.width}px`, height: `${desktopBanner.p5.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	</>
    )
}
export default FaucetActiveDesktopBanners;
