import React, { useState, useEffect } from 'react';
import { Wrapper, Card, Head, Body, DescWrap } from './NotificationElements';
import axiosInstance from '../../axios';
import { MsgWrap, MsgText } from '../MessageElements';
import { StackContainer, StackWrap, TitleWrap } from '../Stack/StackElements';
import moment from 'moment';
import { Circle } from '../Loading';

const Notifications = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    const fetchProfitHistory = async () => {
        try {
            const { data } = await axiosInstance.get('notification/views/');
            setInfo(data);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
	setLoading(false);
    };
    useEffect(() => {
        fetchProfitHistory();
    }, []);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && apiError.length === 0 &&
                <StackContainer primary={1}>
                    <StackWrap primary={1}>
                        <TitleWrap primary={1}>
                            <h2>your notifications</h2>
                        </TitleWrap>
	    	        <DescWrap>
	                    <p>Green notification: Public message from the administrator</p>
	                    <p>Blue notification: Private message from the iceFaucet</p>
	                </DescWrap>
                        <Wrapper>
                            {info !== undefined && apiError.length === 0 && info.info.map((item, index) => (
                                <Card key={index} visited={item.visited ? 1 : 0}>
                                    <Head admin={item.admin ? 1 : 0}>
                                        <h3>{item.title}</h3>
                                    </Head>
                                    <Body admin={item.admin ? 1 : 0}>
                                        <p>{item.description}</p>
                                    </Body>
                                    <Head admin={item.admin ? 1 : 0} style={{ borderRadius: '0 0 15px 15px' }}>
                                        <h4>{moment(item.created).fromNow()}</h4>
                                    </Head>
                                </Card>
                            ))}
                        </Wrapper>
		    </StackWrap>
                    {!loading && apiError.length > 0 &&
                        <MsgWrap primary={0} style={{ width: '80%', margin: '0 auto 25px auto' }}>
                            <MsgText primary={0}>
                                {apiError}
                            </MsgText>
                        </MsgWrap>
                    }
                </StackContainer>
            }
	</>
    )
}
export default Notifications;
