import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import axiosInstance from '../axios';
import UserSetLang from '../components/UserSetLang';
import AccountInfo from '../components/AccountInfo';
import AccountBalance from '../components/AccountBalance';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import IceStory from '../components/IceStory';
import { StoryContainer, StoryHeroTitleWrap, ActiveStoryContainer, ActiveStoryList, ActiveStoryImg } from '../components/IceStory/IceStoryElements';

const DashboardPage = () => {
    const [info, setInfo] = useState();
    const [error, setError] = useState('');
    const [lang, setLang] = useState();
    const [loading, setLoading] = useState(true);
    const [storyInfo, setStoryInfo] = useState();
    const [activeStory, setActiveStory] = useState(0);
    const [stop, setStop] = useState(false);
    const [finishStory, setFinishStory] = useState(false);
    const dark = localStorage.getItem('lightness') ? true : false;
    useEffect(() => {
        const fetchAccountInfo = async () => {
            try {
                const { data } = await axiosInstance.get('dashboard/');
                setInfo({
		    'userId': data.info.user_id,
                    'gender': data.info.gender,
                    'country': data.info.country,
                    'membership': data.info.membership,
                    'timer': data.info.timer,
                    'days': data.info.days,
                    'memberSince': data.info.date_joined.replace('-', '/').split('T')[0].replace('-', '/'),
                    'userName': data.info.username,
                    'profileImg': data.info.photo,
        	    'icecoin': parseFloat(data.info.icecoin).toFixed(3),
		    'primecoin': parseFloat(data.info.pcoin).toFixed(2),
		    'adscoin': parseFloat(data.info.adcoin).toFixed(2),
                    'level': data.info.level,
                    'referrals': data.info.referrals,
                    'ref_earn': parseFloat(data.info.ref_earn).toFixed(1),
		    'device': data.info.device,
		    'promo': data.info.promo,
		    'desc_promo': data.info.desc_promo,
		    'ppromo': data.info.ppromo,
		    'desc_ppromo': data.info.desc_ppromo
                });
                setLang(data.info.language !== undefined ? data.info.language : null);
                if (data.info.language !== undefined && data.info.language !== 'en') {
                    localStorage.setItem('pageLang', data.info.language)
                };
		fetchStoryInfo();
            } catch (error) {
                error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            };
	    setLoading(false);
        };
	fetchAccountInfo();
    }, []);
    const fetchStoryInfo = async () => {
	const { data } = await axiosInstance.get('story/view/');
	setStoryInfo(data.info);
    };
    useEffect(() => {
	if (!loading && info !== undefined && !error && storyInfo !== undefined && Object.keys(storyInfo).length > 0 && !stop && !finishStory) {
	    if (activeStory === Object.keys(storyInfo).length) {
		setFinishStory(true);
		return;
	    };
	    const storyNext = setTimeout(() => {
		setActiveStory(activeStory + 1);
	    }, 7000);
	    return () => clearTimeout(storyNext);
	};
    }, [activeStory, error, loading, info, storyInfo, stop, finishStory]);
    const handleClickStoryList = (e, index) => {
	finishStory && setFinishStory(false);
	setActiveStory(index);
    };
    return (
        <>
	    <Helmet>
	        <title>iceFaucet</title>
	    </Helmet>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
            {!loading && error &&
                <MsgWrap style={{ width: '80%', margin: '10px auto' }} primary={0}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
	    {!loading && info !== undefined && !error && storyInfo !== undefined && Object.keys(storyInfo).length > 0 &&
		<StoryContainer dark={dark ? 1 : 0} stop={stop ? 1 : 0} onContextMenu={(e) => e.preventDefault()}>
		    <StoryHeroTitleWrap dark={dark ? 1 : 0}><h2>ice stories</h2></StoryHeroTitleWrap>
		    {storyInfo.map((story, index) => (
			<IceStory key={index} info={story} index={index} activeStory={activeStory} setStop={setStop} dark={dark} />
		    ))}
		    <ActiveStoryContainer>
			{storyInfo.map((story, index) => (
			    <ActiveStoryList key={index} active={index === activeStory ? 1 : 0} onClick={(e) => handleClickStoryList(e, index)}>
				<ActiveStoryImg src={story.logo} alt={index + 1} />
			    </ActiveStoryList>
			))}
		    </ActiveStoryContainer>
		</StoryContainer>
	    }
            {!loading && info !== undefined && !error && (lang !== null ? null : <UserSetLang lang={lang} />)}
            {!loading && info !== undefined && !error && <AccountInfo info={info} />}
            {!loading && info !== undefined && !error && <AccountBalance info={info} />}
        </>
    )
}
export default DashboardPage;
