export const depositInfo = [
    {
	src: 'https://icefaucet.com/media/help/blog/manual_wallet.png',
	alt: 'manual deposit image',
	title: 'Icefaucet API',
	name: 'manual (fast)',
	max: '$999',
	limitation: 'once daily',
	type: 'M'
    },
    {
	src: 'https://icefaucet.com/media/help/blog/pay.PNG',
	alt: 'Payeer deposit image',
	title: '3rd party API',
	name: 'auto (instant)',
	max: '$999',
	limitation: 'once daily',
	type: 'P'
    },
    {
	src: 'https://icefaucet.com/media/help/blog/faucetpay.jpg',
	alt: 'FaucetPay deposit image',
	title: '3rd party API',
	name: 'auto (instant)',
	max: '$999',
	limitation: 'once daily',
	type: 'F'
    }
];
