import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const MiningContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    padding: 25px;
`
export const MiningWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 2fr;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 0 20px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 10px;
    padding: 0 10px;
    @media screen and ${device.md} {
	grid-template-columns : 1fr;
    };
    @media screen and ${device.tn} {
	grid-template-columns : 1fr;
	padding: 0;
    };
`
export const MiningImgWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const MiningImg = styled.img`
    max-width: 420px;
    @media screen and ${device.sm} {
	width: 95%;
    };
`
export const ContentWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
`
