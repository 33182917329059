import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { GoDashboard } from 'react-icons/go';
import { ImEye, ImUserPlus } from 'react-icons/im';
import { FaCrown } from 'react-icons/fa';

export const TabContainer = styled.div`
    width: 92%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    justify-content: start-flex;
    align-items: center;
`
export const TabHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: start-flex;
    align-items: center;
    background: rgba(29, 107, 187, 0.5);
    @media screen and ${device.sm} {
	flex-direction: column;
    }
`
export const TabHeaderWrap = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: ${({ active }) => (active ? 'rgba(1, 219, 187, 0.8)' : 'none')};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
	transition: all 0.2s ease-in-out;
	background: ${({ active }) => (active ? 'rgba(1, 219, 187, 0.8)' : ' rgba(39, 117, 85, 1)')};
	box-shadow: 0 0 5px ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : '#277555')};
	letter-spacing: 1px;
	opacity: 1;
    }
    h2 {
	color: #f9f9f9;;
	font-size: clamp(0.7rem, 2.4vw, 0.85rem);
	font-weight: 700;
	letter-spacing: 1.3px;
	text-transform: uppercase;
    }
`
export const TabWrap = styled.div`
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    background: rgba(39, 117, 85, 0.5)
`
export const ChallengeWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
`
export const ChallengeName = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    h2 {
	color: #f9f9f9;;
	font-size: clamp(0.7rem, 2.4vw, 0.85rem);
	font-weight: 700;
	letter-spacing: 1.3px;
	text-transform: uppercase;
    }
`
export const ChallengeIcon = css`
    font-size: 1rem;
    color: rgba(1, 219, 187, 1);
    margin-right: 7px;
`
export const FaucetIcon = styled(GoDashboard)`
    ${ChallengeIcon}
`
export const ViewAdsIcon = styled(ImEye)`
    ${ChallengeIcon}
`
export const OfferIcon = styled(FaCrown)`
    ${ChallengeIcon}
`
export const AffiliateIcon = styled(ImUserPlus)`
    ${ChallengeIcon}
`
export const ProgressBarWrap = styled.div`
    width: 80%;
    outline: 2px solid rgba(1, 219, 187, 0.5);
    box-shadow: 0 0 5px rgba(1, 219, 187, 0.8);
    border-radius: 0 0 30px 0;
    @media screen and ${device.md} {
	width: 70%;
    }
    @media screen and ${device.sm} {
	width: 60%;
    }
    @media screen and ${device.tn} {
	width: 50%;
    }
`
export const ProgressBar = styled.div`
    width: ${({ width }) => (width)};
    height: 6px;
    background: rgba(1, 219, 187, 0.7);
    border-radius: 0 0 30px 0;
    span {
        position: absolute;
	left: 79%;
	margin-top: 8px;
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: clamp(0.7rem, 2.4vw, 0.8rem);
	@media screen and ${device.md} {
	    left: 75%;
	}
	@media screen and ${device.sm} {
	    left: 70%;
	}
	@media screen and ${device.tn} {
	    left: 66%;
	}
    }
`
export const WheelContainer = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.5) 0% , rgba(29, 107, 187, 0.5) 30%, rgba(39, 117, 85, 0.5) 70%, rgba(0, 0, 0, 0.5) 105%);
    border: 2px solid rgba(1, 219, 187, 1);
    border-radius: 25px 25px 0 0;
    @media screen and ${device.sm} {
	width: 100%;
    }
`
export const ImgLogo = styled.img`
    width: 50px;
    height: 54px;
    position: relative;
    top: ${({ small }) => (small ? '-197px' : '-142px')};
`
export const MembershipContainer = styled.div`
    width: 90%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr ;
    margin: 10px auto;
    padding: 10px 0;
    @media screen and ${device.sm} {
	grid-template-columns : 1fr;
	grid-row-gap: 30px;
    }
`
export const MembershipWrap = styled.div`
    opacity: ${({ active }) => (active ? '1' : '0.6')};
    background: rgba(29, 107, 187, 0.6);
    border: 1px solid rgba(29, 107, 187, 0.8);
    box-shadow: 0 2px 10px rgba(29, 107, 187, 0.3);
    border-radius: 5px;
    width: 110px;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
`
export const ImageWrap = styled.div`
    width: 60px;
    height: 60px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(39, 117, 85, 0.5);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.7);
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;
`
export const Image = styled.img`
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 50%;
`
export const Button = styled.button`
    width: 90px;
    background: rgba(39, 117, 85, 0.6);
    border-radius: 50px;
    white-space: nowrap;
    color: #fff;
    font-size: 0.7rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
    cursor: pointer;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-top: 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
	transition: all 0.2s ease-in-out;
	background: ${({ active }) => (active ? 'rgba(29, 107, 187, 1)' : ' rgba(39, 117, 85, 1)')};
	box-shadow: 0 0 5px ${({ active }) => (active ? '#1D6BBB' : '#277555')};
	letter-spacing: 1px;
	opacity: 1;
    }
`
export const UserSpinWrap = styled.div`
    display: felx;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
`
export const Title = styled.h2`
    color: rgba(1, 219, 187, 1);
    font-size: clamp(0.75rem, 2.4vw, 0.85rem);
    font-weight: 700;
    text-transform: uppercase;
`
export const Timer = styled.h1`
    color: ${({ red }) => (red ? 'rgb(240, 75, 75, 1)' : 'rgba(1, 219, 187, 1)')};
    text-shadow: 0 0 5px rgb(0, 0, 0, 0.7);
    font-size: clamp(1.1rem, 2.4vw, 1.5rem);
    letter-spacing: 1.3px;
`
export const WheelContainerPromo = styled.div`
    width: 100%;
    padding: 10px;
    margin: 15px auto;
    display: flex;
    align-items: center;
    text-align: center;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.5) 0% , rgba(29, 107, 187, 0.5) 30%, rgba(39, 117, 85, 0.5) 70%, rgba(0, 0, 0, 0.5) 105%);
    border: 2px solid rgba(1, 219, 187, 1);
    @media screen and ${device.sm} {
	flex-direction: column;
    }
`
export const ImgLogoPromo = styled.img`
    width: 50px;
    height: 54px;
    position: relative;
    top: 0;
    left: -102px;
    @media screen and ${device.sm} {
	top: -143px;
	left: 0;
    }
`
export const Wheel = styled.canvas`
    margin-right: -28px;
    @media screen and ${device.sm} {
	margin-right: 0;
    }
`
