import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PtcFiltersAction, PtcSetUpEndAction, PtcSuccessAction } from '../../actions/PtcSetUpAction';
import axiosInstance from '../../axios';
import { PtcForm, PtcFormInputContainer } from './SetUpPtcFormElements';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName } from './SetUpPtcFilterElements';
import { ItemLink } from './SetUpPtcEndElements';
import { FilterLangWrap } from './SetUpPtcFormElements';
import { countriesData } from '../../data/countriesData';
import { TinyFormButton } from "../ButtonElements";
import { FormButtonsWrap } from './CreditCheckElements';
import { MsgText, MsgWrap } from '../MessageElements';

const SetUpPtcEnd = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [watch, setWatch] = useState(false);
    const [autoplay, setAutoplay] = useState(false);
    const [error, setError] = useState();
    const lang = localStorage.getItem('lang') ? 0.1 : 0;
    const duration = parseFloat(localStorage.getItem('duration'));
    const country = localStorage.getItem('target_country') ? 0.25 : 0;
    const type = location.pathname === '/setup-ads/ptc' ? 10
        : location.pathname === '/setup-ads/surf'
            ? 8
            : location.pathname === '/setup-ads/video'
                ? 6
                : null;
    const visit = parseInt(localStorage.getItem('visit'));
    const totalCost = type * visit * (lang + country + duration);
    const upcodeValue = Math.floor(parseInt(localStorage.getItem('visit')) * (parseFloat(localStorage.getItem('upcode')) - 1.00));
    const remainAdscoin = parseInt(localStorage.getItem('adcoin')) - totalCost;
    const geoData = countriesData;
    const geoObjects = geoData.objects.ne_110m_admin_0_countries.geometries;
    const geoAllCountriesIso = geoObjects.map((geoAllCountry) => geoAllCountry.properties.ISO_A2);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (location.pathname !== '/setup-ads/video' && !watch) {
	    setError('You should validate your ads first.');
	} else {
	    setIsSubmitting(true);
	};
    };
    const fetchSetUpPtcAds = async () => {
        const { data } = await axiosInstance.post('ptc/setup/', {
            type: type,
            target_language: localStorage.getItem('lang') ? localStorage.getItem('lang') : null,
            duration: duration,
            repeat: localStorage.getItem('repeat') ? parseInt(localStorage.getItem('repeat')) : 1,
            visit: visit,
            url: localStorage.getItem('url'),
            title: localStorage.getItem('title'),
            description: localStorage.getItem('description') ? localStorage.getItem('description') : null,
            target_country: localStorage.getItem('target_country') ? JSON.parse(localStorage.getItem('target_country')) : geoAllCountriesIso,
            target_device: localStorage.getItem('target_device') ? JSON.parse(localStorage.getItem('target_device')) : ['M', 'D'],
            target_gender: localStorage.getItem('target_gender') ? JSON.parse(localStorage.getItem('target_gender')) : ['M', 'F'],
            social1: localStorage.getItem('social1') ? localStorage.getItem('social1') : null,
            social2: localStorage.getItem('social2') ? localStorage.getItem('social2') : null,
            social3: localStorage.getItem('social3') ? localStorage.getItem('social3') : null,
            refshow: localStorage.getItem('refshow') === 'true' ? true : false,
            status: autoplay ? 'P' : 'S'
        });
        if (data.status) {
            localStorage.removeItem('lang');
            localStorage.removeItem('duration');
            localStorage.removeItem('repeat');
            localStorage.removeItem('visit');
            localStorage.removeItem('url');
            localStorage.removeItem('title');
            localStorage.removeItem('description');
            localStorage.removeItem('target_country');
            localStorage.removeItem('target_device');
            localStorage.removeItem('target_gender');
            localStorage.removeItem('social1');
            localStorage.removeItem('social2');
            localStorage.removeItem('social3');
            localStorage.removeItem('refshow');
            localStorage.removeItem('adcoin');
            localStorage.removeItem('upcode');
            dispatch(PtcSetUpEndAction({ prop: false }));
            dispatch(PtcSuccessAction({ prop: true }));
        };
    };
    useEffect(() => {
        if (isSubmitting) {
            fetchSetUpPtcAds();
        };
    });
    return (
        <>
            <PtcForm action='#' onSubmit={handleSubmit}>
                <PtcFormInputContainer>
	    	    {location.pathname !== '/setup-ads/video' &&
			<>
                    	    <FormHeader>
                        	<h2>watch your ads as a user</h2>
                    	    </FormHeader>
                    	    <FilterContainer>
                        	<InstructionWrap>
                            	    <Instruction>
                                	See how users could watch your ads well.
                            	    </Instruction>
                        	</InstructionWrap>
                            	<FilterLangWrap>
                            	    <ItemWrap >
                                	<ItemLink href={location.pathname === '/setup-ads/ptc' ? '/watch/ptc' : location.pathname === '/setup-ads/surf' ? '/watch/surf' : location.pathname === '/setup-ads/video' ? '/watch/video' : '#'} target="_blank" onClick={() => { setWatch(true); setIsSubmitting(false); setError('') }} active={watch ? 1 : 0}>
                                    	    Watch
                                	</ItemLink>
                            	    </ItemWrap>
                        	</FilterLangWrap>
                        	{error &&
                            	    <MsgWrap>
                                	<MsgText>
                                    	    {error}
                                	</MsgText>
                            	    </MsgWrap>
                        	}
                    	    </FilterContainer>
			</>
		    }
                    <FormHeader>
                        <h2>cost of your ads</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                CPC (Cost Per Click) = <strong>{type} adcoin</strong><br />
                                Duraion of your ads = <strong>
                                    {duration === 1.0
                                        ? '10 seconds (0% CPC)'
                                        : duration === 1.5
                                            ? '20 seconds (+50% CPC)'
                                            : duration === 2.0
                                                ? '30 seconds (+100% CPC)'
                                                : duration === 2.5
                                                    ? '40 seconds (+150% CPC)'
                                                    : null
				    }
                                </strong> <br />
                                Target language filter = <strong>{lang === 0.1 ? 'enabled (+0.10% CPC)' : 'disabled'}</strong><br />
                                Target country filter = <strong>{country === 0.25 ? 'enabled (+0.25% CPC)' : 'disabled'}</strong><br />
                                Number of visits = <strong>{visit} visits</strong><br />
                                The total cost of your ads = <strong>{totalCost} adscoin</strong> <br />
                                Remain your adscoin = <strong>{remainAdscoin} adscoin</strong> <br />
                                Number of upcode visits as a gift = {totalCost >= 250000 ? <strong>{upcodeValue} visits</strong> : 0}
                            </Instruction>
                        </InstructionWrap>
                    </FilterContainer>
                    <FormHeader>
                        <h2>autoplay your ads</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                If you want to show your ads instantly after acceptance in the monitoring process, press the following button.
                            </Instruction>
                        </InstructionWrap>
                        <FilterLangWrap>
                            <ItemWrap >
                                <ItemName onClick={() => setAutoplay(!autoplay)} itemActive={autoplay ? 1 : 0}>
                                    Accept
                                </ItemName>
                            </ItemWrap>
                        </FilterLangWrap>
                    </FilterContainer>
                </PtcFormInputContainer>
                <FormButtonsWrap>
                    <TinyFormButton type='submit' primary={1} big={0}>
                        complete
                    </TinyFormButton>
                    <TinyFormButton type='button' primary={1} big={0} onClick={() => { dispatch(PtcSetUpEndAction({ prop: false })); dispatch(PtcFiltersAction({ prop: true })) }}>
                        back
                    </TinyFormButton>
                </FormButtonsWrap>
            </PtcForm>
        </>
    )
};
export default SetUpPtcEnd;
