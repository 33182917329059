import React from 'react';
import { Helmet } from "react-helmet";
import HelpDesk from '../components/HelpDesk';
import { helpData, BlogCategories, PlayListCategories, FAQButtonsCategories } from '../data/helpData';

const HelpPage = ({ code }) => {
    return (
	<>
	   <Helmet>
	        <meta name="description" content="You could solve your problem here." />
	        <title>iceFaucet | help desk</title>
	    </Helmet>
            <HelpDesk code={code} info={helpData} BlogCategories={BlogCategories} PlayListCategories={PlayListCategories} FAQButtons={FAQButtonsCategories} />
	</>
    )
}
export default HelpPage;
