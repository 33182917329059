import styled, { css } from 'styled-components';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md';

export const SponsorContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    :before {
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`
export const SponsorWrap = styled.div`
    width: 100%;
    max-width: 728px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px 15px 0 0;
`
export const SponsorHeader = styled.div`
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.2);
    border-radius: 15px 15px 0 0;
    border: 1px solid rgba(29, 107, 187, 0.4);
    border-bottom: none;
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);

    h3 {
        font-size: clamp(0.9rem, 3.3vw, 1.1rem);
        color: rgba(29, 107, 187, 0.6);
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        letter-spacing: 1px;
        word-spacing: 5px;
        text-align: center;
    }
`
export const SponsorBody = styled.div`
    width: 100%;
`
export const RowWrap = styled.div`
    background: rgba(39, 117, 85, 0.2);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px 0;
    border: 1px solid rgba(29, 107, 187, 0.4);
    border-bottom: none;
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);

    &:nth-child(2n) {
        background: rgba(29, 107, 187, 0.2);
    }
`
export const RowTitleWrap = styled.div`
    background: ${({ even }) => (even ? 'rgba(39, 117, 85, 0.4)' : 'rgba(29, 107, 187, 0.4)')};
    width: 100%;
    height: 25%;

    h2 {
        font-size: clamp(1rem, 3.5vw, 1.3rem);
        color: ${({ even }) => (even ? '#277555' : '#1D6BBB')};
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        letter-spacing: 0.7px;
        word-spacing: 2px;
        text-align: center;
    }
`
export const RowContentContainer = styled.div`
    width: 75%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
`
export const RowContentWrap = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-column-gap: 35px;
    grid-template-columns: 7fr 1fr;
    align-items: center;
    justify-content: center;
`
export const RowDescriptionWrap = styled.div`
    width: 100%;
    max-height: 90px;
    background: ${({ even }) => (even ? 'rgba(39, 117, 85, 0.4)' : 'rgba(29, 107, 187, 0.4)')};
    border: 1px solid ${({ even }) => (even ? 'rgba(39, 117, 85, 0.4)' : 'rgba(29, 107, 187, 0.4)')};
    border-radius: 5px;
    padding: 5px 10px;
    overflow-y: auto;

    p {
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
        font-size: clamp(0.8rem, 2.2vw, 1rem);
        letter-spacing: 0.5px;
        word-spacing: 1.5px;
    }

    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px ${({ even }) => (even ? 'rgba(39, 117, 85, 0.5)' : 'rgba(29, 107, 187, 0.5)')};
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${({ even }) => (even ? 'rgba(39, 117, 85, 0.3)' : 'rgba(29, 107, 187, 0.3)')};
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${({ even }) => (even ? 'rgba(39, 117, 85, 0.8)' : 'rgba(29, 107, 187, 0.8)')};
    }
`
export const SponsorLinkWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid ${({ even }) => (even ? 'rgba(39, 117, 85, 0.4)' : 'rgba(29, 107, 187, 0.4)')};
    background: ${({ even }) => (even ? 'rgba(39, 117, 85, 0.4)' : 'rgba(29, 107, 187, 0.4)')};

    &:hover {
        background: ${({ even }) => (even ? 'rgba(39, 117, 85, 1)' : 'rgba(29, 107, 187, 1)')};

    }
`
export const SponsorLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
`
export const SponsorIcons = css`
    color: #fff;
    font-size: 1.3rem;
`
export const SponsorIcon = styled(MdArrowForward)`
    ${SponsorIcons}
`
export const SponsorArrow = styled(MdKeyboardArrowRight)`
    ${SponsorIcons}
`