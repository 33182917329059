import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { CategoryWrap, TitleWrap, ButtonsWrap, Buttons, ButtonsName, FAQWrap, QuestionWrap, Question, IconWrap, DownIcon, UpIcon, AnswerWrap } from './FAQElements';
import { MsgWrap, MsgText } from '../MessageElements.js';
import Ticket from '../Ticket';

const FAQ = ({ category, setCategory, indexCategory, setIndexCategory, FAQButtons }) => {
    const [infoFAQ, setInfoFAQ] = useState();
    const [error, setError] = useState('');
    const [arrowClick, setArrowClick] = useState(false);
    const [arrowIndex, setArrowIndex] = useState();
    const authorized = localStorage.getItem('access_token') ? true : false;
    const handleFAQCategory = (e, index) => {
        const categoryTarget = e.target.getAttribute('name');
        if (categoryTarget && categoryTarget !== category) {
            setCategory(categoryTarget);
            setIndexCategory(index);
            fetchFAQ(categoryTarget);
        };
        setArrowClick();
        setArrowIndex();
    };
    const fetchFAQ = async (categoryTarget) => {
        try {
            const { data } = await axiosInstance.post('help/FAQs/', {
                category: categoryTarget
            });
            setInfoFAQ(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    const handleArrowClick = (index) => {
        if (arrowIndex === undefined) {
            setArrowIndex(index);
            setArrowClick(true);
        } else if (arrowIndex !== index) {
            setArrowIndex(index);
            setArrowClick(true);
        } else {
            setArrowIndex();
            setArrowClick(false);
        };
    };
    return (
        <>
            <CategoryWrap>
                <TitleWrap>
                    <p>Select one of the following categories first:</p>
                </TitleWrap>
                <ButtonsWrap>
                    {FAQButtons.map((button, index) => (
                        <Buttons key={index} >
                            <ButtonsName name={button.name} onClick={(e) => handleFAQCategory(e, index)} itemActive={indexCategory === index ? 1 : 0}>
                                {button.value}
                            </ButtonsName>
                        </Buttons>
                    ))}
                </ButtonsWrap>
                {infoFAQ !== undefined && infoFAQ.map((item, index) => (
                    <FAQWrap key={index}>
                        <QuestionWrap onClick={() => handleArrowClick(index)} primary={index % 2 !== 0 ? 1 : 0}>
                            <Question>
                                <h4>{item.question}</h4>
                            </Question>
                            <IconWrap>
                                {index === arrowIndex && arrowClick ? <UpIcon /> : <DownIcon />}
                            </IconWrap>
                        </QuestionWrap>
                        <AnswerWrap primary={index % 2 !== 0 ? 1 : 0} active={arrowClick && arrowIndex === index ? 1 : 0}>
                            <p>
                                {item.answer}
                            </p>
                        </AnswerWrap>
                    </FAQWrap>
                ))}
            </CategoryWrap>
            {authorized ? <Ticket /> :
                <MsgWrap primary={0} style={{ marginTop: '25px' }}>
                    <MsgText primary={0}>
                        You must log in to send an email to us.
                    </MsgText>
                </MsgWrap>
            }
            {error &&
                <MsgWrap primary={0}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
        </>
    )
}

export default FAQ;