import { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch, useSelector } from 'react-redux';
import { activateCodeAction, regTabAction } from '../../actions/authAction';
import { useLocation, useParams } from 'react-router-dom';

const UseForm = (ValidateEmail, ValidateInfo) => {
    const location = useLocation();
    const { code } = useParams();
    const [values, setValues] = useState(Object.freeze({
        email: '',
        gender: '',
        password: '',
        password2: ''
    }));
    const [errors, setErrors] = useState({
        email: '',
        gender: '',
        password: '',
        password2: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFetchUnique, setIsFetchUnique] = useState(false);
    const [isUnique, setIsUnique] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useSelector(state => state.submitRegForm);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setErrors({ email: '' });
        setIsSubmitting(false);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateInfo(values));
        setIsSubmitting(true);
    };
    const handleUnique = () => {
        setErrors(ValidateEmail(values));
        setIsFetchUnique(true);
    };
    const handleCheck = () => {
        setIsSubmitting(false);
        setIsChecked(!isChecked);
    };
    const fetchUniqueEmail = async () => {
        const { data } = await axiosInstance.post('sign-up/unique/', {
            email: values.email,
        });
        data.unique ? setIsUnique(true) : setErrors({ email: data.detail });
    };
    const fetchRegistration = async () => {
	setLoading(true);
        try {
            const { data } = await axiosInstance.post('sign-up/', {
                email: values.email,
                gender: values.gender,
                password: values.password,
                confirm_password: values.password2
            });
            localStorage.setItem('id', data.id);
            localStorage.setItem('activateTimer', Date.now() + data.timer * 1000);
            dispatch(regTabAction({ prop: false }));
            dispatch(activateCodeAction({ prop: true }));
        } catch (error) {
            setErrors({
                email: error.response && error.response.data.detail ? error.response.data.detail : error.message
            });
        };
	setLoading(false);
    };
    const fetchRefRegistration = async () => {
	setLoading(true);
        try {
            const { data } = await axiosInstance.post(`ref/${code}/`, {
                email: values.email,
                gender: values.gender,
                password: values.password,
                confirm_password: values.password2
            });
            localStorage.setItem('id', data.id);
            localStorage.setItem('activateTimer', Date.now() + data.timer * 1000);
            dispatch(regTabAction({ prop: false }));
            dispatch(activateCodeAction({ prop: true }));
        } catch (error) {
            setErrors({
                email: error.response && error.response.data.detail ? error.response.data.detail : error.message
            });
        };
	setLoading(false);
    };
    useEffect(() => {
        if (values.email && Object.keys(errors).length === 0 && isFetchUnique) {
            fetchUniqueEmail();
        };
        if (localStorage.getItem('id') && localStorage.getItem('activateTimer') && (parseInt(localStorage.getItem('activateTimer')) - Date.now() > 0)) {
            dispatch(regTabAction({ prop: false }));
            dispatch(activateCodeAction({ prop: true }));
        };
        if (localStorage.getItem('id') && localStorage.getItem('activateTimer') && (parseInt(localStorage.getItem('activateTimer')) - Date.now() <= 0)) {
            localStorage.removeItem('id');
            localStorage.removeItem('activateTimer');
        };
        if (!loading && Object.keys(errors).length === 0 && isSubmitting && isChecked && isUnique && !localStorage.getItem('id') && !localStorage.getItem('activateTimer') && location.pathname === '/auth') {
            fetchRegistration();
        };
        if (!loading && Object.keys(errors).length === 0 && isSubmitting && isChecked && isUnique && !localStorage.getItem('id') && !localStorage.getItem('activateTimer') && location.pathname === `/auth/ref/${code}`) {
            fetchRefRegistration();
        };
    });
    return { values, handleChange, handleUnique, handleSubmit, errors, isChecked, isSubmitting, handleCheck, loading };
}
export default UseForm;
