import styled, { css } from 'styled-components/macro';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md';

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 580px;
    position: relative;

    :before {
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`
export const HeroBg = styled.div`
    position: absolute;
    color: #fff;
    font-size: 1.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        background: #000;
    }
`
export const HeroContent = styled.div`
    z-index: 3;
    max-width: 60vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.8;

    p{
        margin-top: 24px;
        color: #fff;
        font-size: clamp(1rem, 3.5vw, 1.5rem);
        text-align: center;
        max-width: 550px;
        line-height: 2rem;
        text-shadow: 0 1px 2px #000;
    }
`
export const HeroHeading = styled.h1`
    color: ${({ headingBlue }) => (headingBlue ? '#1D6BBB' : '#277555')};
    font-size: clamp(1.5rem, 3.5vw, 3.5rem);
    text-align: center;
    text-shadow: 0 1px 3px #000;
`
export const HeroBtnWrapper = styled.button`
    background: transparent;
    border: none;
    margin-top: 32px;
    display: flex;
    align-items: center;
`
export const ArrowIcon = css`
    margin-left: 8px;
    font-size: 20px;`

export const ArrowFrorward = styled(MdArrowForward)`
    ${ArrowIcon}
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
    ${ArrowIcon}
`
