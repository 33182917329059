import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { FaCrown } from 'react-icons/fa';

export const ContestContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    padding: ${({ big }) => (big ? '25px' : '0')};
    opacity: ${({ active }) => (active ? '1' : '0.5')};
`
export const ContestWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1.5fr;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 0 20px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: ${({ big }) => (big ? '10px' : '0px')};
    padding: 10px;
    @media screen and ${device.md} {
	grid-template-columns : 1fr;
    };
`
export const ContestImgWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const ContestImg = styled.img`
    width: ${({ big }) => (big ? '220px' : '120px')};
    @media screen and ${device.tn} {
	width: ${({ big }) => (big ? '180px' : '120px')};
    };
`
export const ContentWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
`
export const LoadingWrap = styled.div`
    width: ${({ big }) => (big ? '80%' : '100%')};
    max-width: 94vw;
    display: flex;
    justify-content: space_between;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : 'rgba(39, 117, 85, 0.4)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 10px;
    padding: 10px;
    @media screen and ${device.sm} {
	width: 100%;
    }
    h4 {
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: clamp(0.55rem, 2.4vw, 0.85rem);
	font-weight: 400;
	letter-spacing: 1.2px;
	word-spacing: 1.5px;
	text-transform: uppercase;
    }
`
export const Table = styled.table`
    border-collapse: collapse;
    border-left: 2px solid rgba(29, 107, 187, 0.7);
    border-right: 2px solid rgba(29, 107, 187, 0.7);
    width: 80%;
    @media screen and ${device.sm} {
	width: 100%;
    }
`
export const HeadWrap = styled.thead`
    width: 100%;
`
export const HeadRow = styled.tr`
    width: 100%;
`
export const Header = styled.th`
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.55rem, 2.7vw, 0.85rem);
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 1.5px;
    text-transform: uppercase;
    padding: 10px 3px;
    background: rgba(29, 107, 187, 0.8);
`
export const BodyWrap = styled.tbody`
    max-height:160px;
    overflow: auto;
    &::-webkit-scrollbar {
	width: 7px;
	height: 10px;
    }
    &::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5);
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
	background: rgba(1, 219, 187, 0.3);
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
	background: rgba(1, 219, 187, 0.8);
    }
`
export const BodyRow = styled.tr`
    background: rgba(29, 107, 187, 0.5);
    &:nth-child(even) {
	background: rgba(39, 117, 85, 0.5);
    }
`
export const Cell = styled.td`
    border-bottom: 2px solid rgba(29, 107, 187, 0.7);
    text-align: center;
    color: ${({ blue }) => (blue ? 'rgba(1, 219, 187, 1)' : '#fff')};
    font-size: clamp(0.66rem, 2.7vw, 0.85rem);
    text-shadow: 0 0 1px ${({ blue }) => (blue ? 'rgba(1, 219, 187, 1)' : 'rgba(0, 0, 0, 0.8)')};
    padding: 7px 3px;
`
export const LevelContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px auto;
    padding-left: 10px;
`
export const LevelWrap = styled.div`
    width: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and ${device.md} {
	width: 55px;
    };
    @media screen and ${device.sm} {
	width: 45px;
    };
    @media screen and ${device.tn} {
	width: 30px;
    };
`
export const ProgressBarWrap = styled.div`
    width: 68px;
    margin: auto;
    outline: 1px solid rgba(29, 107, 187, 1);
    box-shadow: 0 0 2px rgba(29, 107, 187, 1);
    @media screen and ${device.md} {
	width: 43px;
    };
    @media screen and ${device.sm} {
	width: 33px;
    };
    @media screen and ${device.tn} {
	width: 18px;
    };
`
export const ProgressBar = styled.div`
    width: ${({ width }) => (width)};
    height: 4px;
    background: rgba(1, 219, 187, 1);
    overflow: hidden;
`
export const Desc1 = styled.span`
    position: relative;
    top: 11px;
    color: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(29, 107, 187, 1)')};
    font-size: clamp(0.7rem, 1.24vw, 0.8rem);
    @media screen and ${device.md} {
	top: 6px;
    };
`
export const Desc2 = styled.span`
    position: relative;
    top: 4px;
    color: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(29, 107, 187, 1)')};
    font-size: clamp(0.7rem, 1.24vw, 0.8rem);
`
export const Ring = styled.div`
    background: ${({ active }) => (active ? 'rgba(29, 107, 187, 1)' : 'none')};
    position: relative;
    left: -50%;
    top: 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    outline: 2px solid ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(29, 107, 187, 1)')};
    z-index: 2;
    @media screen and ${device.md} {
	width: 10px;
	height: 10px;
	top: 8px;
    };
`
export const RewardShower = styled.div`
    width: 170px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(39, 117, 85, 0.1);
    background: rgba(29, 107, 187, 0.35);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.7);
    border-radius: 10px;
    margin: 10px auto 15px auto;
`
export const BaseReward = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
	text-align: center;
	color: rgba(1, 219, 187, 1);
	font-size: clamp(0.66rem, 2.2vw, 0.85rem);
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
	letter-spacing: 1.3px;
    }
`
export const ImgWrap = styled.div`
    width: 90%;
    max-width: 600px;
    border: 1px solid rgba(39, 117, 85, 0.1);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto 5px auto;
`
export const InfinityImage = styled.img`
    width: 100%;
`
export const InfinityLevelWrap = styled.div`
    width: 95%;
    margin-left: 2%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and ${device.md} {
	margin-left: 7%;
    };
    @media screen and ${device.tn} {
	margin-left: 13%;
    };
`
export const InfinityRingWrap = styled.div`
    width: 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
`
export const InfinityRing = styled.div`
    background: rgba(29, 107, 187, 1);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: 2px solid rgba(1, 219, 187, 1);
    @media screen and ${device.md} {
	width: 15px;
	height: 15px;
    };
    @media screen and ${device.tn} {
	width: 10px;
	height: 10px;
    };
`
export const InfinityProgressBarWrap = styled.div`
    width: 90%;
    margin: auto;
    @media screen and ${device.md} {
	width: 85%;
    };
    @media screen and ${device.tn} {
	width: 80%;
    };
`
export const InfinityDescWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    left: -50%;
    width: 5%;
    height: 50px;
    color: rgba(1, 219, 187, 1);
    font-size: clamp(0.75rem, 2.2vw, 0.95rem);
    @media screen and ${device.md} {
	width: 10%;
    };
    @media screen and ${device.tn} {
	width: 15%;
    };
`
export const InfinityDesc = styled.span`
    color: rgba(1, 219, 187, 1);
    font-size: clamp(0.85rem, 1.24vw, 0.95rem);
    @media screen and ${device.md} {
	top: 6px;
    };
`
export const CellWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const CrownsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const CrownWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    span {
	font-size: clamp(0.66rem, 2.7vw, 0.75rem);
    }
`
export const Crown = css`
    font-size: clamp(0.70rem, 2.7vw, 0.85rem);
`
export const Golden = styled(FaCrown)`
    color: #FFDF00;
    ${Crown}
`
export const Silver = styled(FaCrown)`
    color: #BCC6CC;
    ${Crown}
`
export const Bronze = styled(FaCrown)`
    color: #A05822;
    ${Crown}
`
