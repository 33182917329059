import React from 'react';
import { useSelector } from 'react-redux';
import { NavPtcContainer, NavPtcItem } from './SetUpAdsNavigateElements';

const SetUpAdsNavigate = () => {
    const showSponsorSetUp = useSelector(state => state.sponsorSetUp);
    const showSponsorFilters = useSelector(state => state.sponsorFilters);
    const showSponsorEnd = useSelector(state => state.sponsorSetUpEnd);
    return (
        <NavPtcContainer>
            <NavPtcItem active={showSponsorSetUp ? 1 : 0}>
                <span>1</span>
            </NavPtcItem>
            <NavPtcItem active={showSponsorFilters ? 1 : 0}>
                <span>2</span>
            </NavPtcItem>
            <NavPtcItem active={showSponsorEnd ? 1 : 0}>
                <span>3</span>
            </NavPtcItem>
        </NavPtcContainer>
    )
}
export default SetUpAdsNavigate