import React from 'react';
import Promotions from '../components/Promotions';

const PromotionMaterialsPage = () => {
    return (
        <>
            <Promotions />
        </>
    )
}
export default PromotionMaterialsPage;