import styled from 'styled-components';

export const CreditFormWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    height: 150px;
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(39, 117, 85, 0.5);
    box-shadow: 0 2px 5px rgba(39, 117, 85, 0.7);
    padding: 10px;
`
export const CreditContentWrap = styled.div`
    display: flex;
    justify-content: space-between;

    span {
        color: #fff;
        font-size: clamp(0.65rem, 3.4vw, 0.8rem);
        letter-spacing: 0.5px;
        word-spacing: 1.5px;
        text-transform: uppercase;
        text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    }

`
export const FormButtonsWrap = styled.div`
    width: 60%;
    margin-top: 10px;
    display: grid;
    grid-row-gap: 7px;
    grid-template-rows: 1fr 1fr;
`
