import styled, { css } from 'styled-components/macro';
import { FaCrown } from 'react-icons/fa';

export const HelperContainer = styled.div`
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    margin: 7px auto;
    padding: 5px 4px;
    cursor: pointer;
    width: 170px;
    &:hover {
	transition: all 0.3s ease-in-out;
	transform: scale(1.04);
    }
`
export const HelperWrap = styled.div`
    display: flex;
`
export const HelperImgWrap = styled.div`
    width: 50px;
    height: 50px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(1, 219, 187, 0.9);
    box-shadow: 0 0 7px rgba(1, 219, 187, 0.7);
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;
`
export const HelperImg = styled.img`
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 50%;
`
export const HelperNameWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 115px;
    margin-left: 7px;
`
export const HelperName = styled.h3`
    margin-left: 3px;
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    font-size: 0.75rem;
    letter-spacing: 1.2px;
    word-spacing: 1.7px;
`
export const HelperMedalWrap = styled.div`
    display: flex;
`
export const MedalWrap = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2px;
    span {
	color: #fff;
	font-size: 0.7rem;
    }
`
export const Crown = css`
    font-size: 0.75rem;
`
export const Gold = styled(FaCrown)`
    color: #FFDF00;
    ${Crown}
`
export const Silver = styled(FaCrown)`
    color: #BCC6CC;
    ${Crown}
`
export const Bronze = styled(FaCrown)`
    color: #A05822;
    ${Crown}
`
export const HelperEditWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 108px;
    padding: 0 3px;
    span {
	color: #fff;
	font-size: 0.6rem;
    }
`
export const Free = styled.h3`
    color: rgba(29, 107, 187, 1);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    font-size: 0.75rem;
    letter-spacing: 1.2px;
    text-transform: uppercase;
`
