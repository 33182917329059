import React from 'react';
import { Container, Wrapper, Wrap, NewEarningWrap } from './HowEarnElements';
import { PIItemsCard, TitleWrap, ItemsContentWrap } from '../AdsTools/AdsToolsElements';


const HowEarn = ({ howEarnData, howEarnEndData }) => {
    return (
        <Container id='how to earn'>
            <Wrapper>
                <Wrap>
                    {howEarnData.map((detail, index) => (
                        <PIItemsCard key={index} primary={index % 2 !== 0 ? 1 : 0}>
                            <TitleWrap primary={index % 2 !== 0 ? 1 : 0}>
                                <h4>{detail.title}</h4>
                            </TitleWrap>
                            <ItemsContentWrap>
                                <div>{detail.icon}</div>
                                <p>{detail.desc1}</p>
                            </ItemsContentWrap>
                            <ItemsContentWrap>
                                <div>{detail.icon}</div>
                                <p>{detail.desc2}</p>
                            </ItemsContentWrap>
                            {detail.desc3.length !== 0 &&
                                <ItemsContentWrap>
                                    <div>{detail.icon}</div>
                                    <p>{detail.desc3}</p>
                                </ItemsContentWrap>
                            }
                            {detail.desc4.length !== 0 &&
                                <ItemsContentWrap>
                                    <div>{detail.icon}</div>
                                    <p>{detail.desc4}</p>
                                </ItemsContentWrap>
                            }
                        </PIItemsCard>
                    ))}
                </Wrap>
                <NewEarningWrap>
                    <h4>{howEarnEndData.desc}</h4>
                </NewEarningWrap>
            </Wrapper>
        </Container>
    )
}

export default HowEarn;