import { CLOSE_SIDEBAR, TOGGLE_SIDEBAR, SCROLL_HEADER } from "../constants/headerRedux";

export const headerOpenAction = ({ prop }) => {
    return (dispatch) => {
        if (prop === false) {
            dispatch({
                type: CLOSE_SIDEBAR
            })
        }
        if (prop === 'toggle') {
            dispatch({
                type: TOGGLE_SIDEBAR
            })
        }
    }
}
export const headerScrollAction = () => {
    return (dispatch) => {
        if (window.scrollY > 80) {
            dispatch({
                type: SCROLL_HEADER,
                payload: true
            })
        } else {
            dispatch({
                type: SCROLL_HEADER,
                payload: false
            })
        }

    }
}