import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { PromotionContainer, PromotionBody } from './ReferralLinkTabElements';
import { BannerTableContainer, BannerImgWrap, BannerImg, BannerDetailWrap, BannerButton } from './BannerTabElements';
import { TextContainer, TextDetailWrap } from './SocialTabElements';
import { FormLangContainer, FormLangWrap, LangWrap, LangName } from '../SetUpPtc/SetUpPtcFormElements.js';
import { ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements';
import { SizeBannerContainer, SizeBannerWrap } from '../SetUpBanner/SetUpBannerFilter2Elements';
import { langData } from '../../data/langData';
import { socialTypeData, socialTypeReferralData, socialImageRatioData } from '../../data/socialPromotionData';

const SocialTab = () => {
    const [langName, setLangName] = useState(Object.freeze(''));
    const [langIndex, setLangIndex] = useState();
    const [type, setType] = useState(Object.freeze(''));
    const [typeIndex, setTypeIndex] = useState();
    const [refType, setRefType] = useState(Object.freeze(''));
    const [refTypeIndex, setRefTypeIndex] = useState();
    const [imgRatio, setImgRatio] = useState();
    const [imgRatioIndex, setImgRatioIndex] = useState();
    const [socialData, setSocialData] = useState();
    const [copied, setCopied] = useState(false);
    const [linkIndex, setLinkIndex] = useState();

    const handleTypeClick = (e, index) => {
        const typeName = e.target.getAttribute('name');
        if (typeName && typeName !== type) {
            setType(typeName);
            setTypeIndex(index);
        };
    };

    const handleLangClick = (e, index) => {
        const LANG = e.target.getAttribute('name');
        if (LANG && langName !== LANG) {
            setLangName(LANG);
            setLangIndex(index);
        };
    };

    const handleRefTypeClick = (e, index) => {
        const refTypeName = e.target.getAttribute('name');
        if (refTypeName && refTypeName !== refType) {
            setRefType(refTypeName);
            setRefTypeIndex(index);
        };
    };

    const handleRatioClick = (e, index) => {
        const imgRatioName = e.target.getAttribute('name');
        if (imgRatioName && imgRatioName !== imgRatio) {
            setImgRatio(imgRatioName);
            setImgRatioIndex(index);
        };
    };

    const fetchSocialTextPromotion = async () => {
        const { data } = await axiosInstance.get('ref/promotion/social/txt/');
        setSocialData(data);
    };

    const fetchSocialImgPromotion = async () => {
        const { data } = await axiosInstance.get('ref/promotion/social/img/');
        setSocialData(data);
    };

    const handleDownload = async (item, index) => {
        const { data } = await axiosInstance.get(
            `ref/promotion/social/img/download/${item.id}/`, {
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `icefaucet-social-${item.social_lang}.png`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const handleCopy = (item, index) => {
        linkIndex !== index && setLinkIndex(index);
        if (navigator.clipboard && window.isSecureContext) {
            setCopied(true);
            return navigator.clipboard.writeText(item.description);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = item.description;
            textArea.style.position = 'absolute';
            textArea.style.opacity = 0
            document.body.appendChild(textArea);
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopied(true);
            });
        };
    };

    const handleURLCopy = (item, index) => {
        linkIndex !== index && setLinkIndex(index);
        if (navigator.clipboard && window.isSecureContext) {
            setCopied(true);
            return navigator.clipboard.writeText(`https://icefaucet.com${item.image}`);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = `https://icefaucet.com${item.image}`;
            textArea.style.position = 'absolute';
            textArea.style.opacity = 0
            document.body.appendChild(textArea);
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopied(true);
            });
        };
    };

    useEffect(() => {
        if (socialData === undefined && type === 'image' && langName.length > 0 && refType.length > 0 && imgRatio !== undefined) {
            fetchSocialImgPromotion();
        };
        if (socialData === undefined && type === 'text' && langName.length > 0 && refType.length > 0) {
            fetchSocialTextPromotion();
        };
    });
    return (
        <PromotionContainer>
            <PromotionBody>
                {
                    type.length === 0 &&
                    <>
                        <p>
                            What do you need to share on your social media?
                        </p>
                        <SizeBannerContainer style={{ marginTop: '-10px', marginBottom: '15px' }}>
                            <SizeBannerWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
                                {socialTypeData.map((item, index) => (
                                    <ItemWrap key={index}>
                                        <ItemName name={item.name} onClick={(e) => handleTypeClick(e, index)} itemActive={typeIndex === index ? 1 : 0}>
                                            {item.name}
                                        </ItemName>
                                    </ItemWrap>
                                ))}
                            </SizeBannerWrap>
                        </SizeBannerContainer>
                    </>
                }
                {
                    type.length > 0 &&
                    <>
                        <p>
                            Select the language of the content.
                        </p>
                        <FormLangContainer style={{ marginBottom: '25px' }}>
                            <FormLangWrap>
                                {langData.map((lang, index) => (
                                    <LangWrap key={index}>
                                        <LangName name={lang.value} onClick={(e) => handleLangClick(e, index)} itemActive={langIndex === index ? 1 : 0}>{lang.name}</LangName>
                                    </LangWrap>
                                ))}
                            </FormLangWrap>
                        </FormLangContainer>
                        <p>
                            What kind of referrals are you marketing?
                        </p>
                        <SizeBannerContainer style={{ marginTop: '-10px', marginBottom: '15px' }}>
                            <SizeBannerWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
                                {socialTypeReferralData.map((item, index) => (
                                    <ItemWrap key={index}>
                                        <ItemName name={item.value} onClick={(e) => handleRefTypeClick(e, index)} itemActive={refTypeIndex === index ? 1 : 0}>
                                            {item.name}
                                        </ItemName>
                                    </ItemWrap>
                                ))}
                            </SizeBannerWrap>
                        </SizeBannerContainer>
                        {
                            type === 'image' &&
                            <>
                                <p>
                                    Select the ratio of the image.
                                </p>
                                <SizeBannerContainer style={{ marginTop: '-10px', marginBottom: '15px' }}>
                                    <SizeBannerWrap>
                                        {socialImageRatioData.map((item, index) => (
                                            <ItemWrap key={index}>
                                                <ItemName name={item.value} onClick={(e) => handleRatioClick(e, index)} itemActive={imgRatioIndex === index ? 1 : 0}>
                                                    {item.name}
                                                </ItemName>
                                            </ItemWrap>
                                        ))}
                                    </SizeBannerWrap>
                                </SizeBannerContainer>
                            </>
                        }
                    </>
                }
                {type === 'text' && socialData !== undefined && socialData.map((item, index) => {
                    return item.social_lang === langName && item.type_referral === refType &&
                        <TextContainer key={index}>
                            <BannerImgWrap primary={index % 2 === 0 ? 1 : 0}>
                                <p>
                                    {item.description}
                                </p>
                            </BannerImgWrap>
                            <TextDetailWrap primary={index % 2 === 0 ? 1 : 0}>
                                <BannerButton onClick={() => handleCopy(item, index)}>
                                    {copied && linkIndex === index ? 'copied' : 'copy text'}
                                </BannerButton>
                            </TextDetailWrap>
                        </TextContainer>
                })
                }
                {type === 'image' && socialData !== undefined && socialData.map((item, index) => {
                    return item.social_lang === langName && item.type_referral === refType && item.ratio === parseInt(imgRatio) &&
                        <BannerTableContainer key={index}>
                            <BannerImgWrap primary={index % 2 === 0 ? 1 : 0}>
                                <BannerImg
                                    src={item.image}
                                    alt='icefaucet social images'
                                />
                            </BannerImgWrap>
                            <BannerDetailWrap primary={index % 2 === 0 ? 1 : 0}>
                                <BannerButton onClick={() => handleDownload(item, index)}>
                                    download
                                </ BannerButton>
                                <BannerButton onClick={() => handleURLCopy(item, index)}>
                                    {copied && linkIndex === index ? 'copied' : 'copy URL'}
                                </BannerButton>
                            </BannerDetailWrap>
                        </BannerTableContainer>
                })
                }
            </PromotionBody>
        </PromotionContainer>
    )
}

export default SocialTab