import React, { useEffect, useState } from 'react';
import SurfDetail from '../components/SurfDetail';
import axiosInstance from '../axios';
import { MotherContainer, MsgContainer, MsgItem } from '../components/PtcDetail/PtcDetailElements';
import { MsgText, MsgWrap } from '../components/MessageElements';
import { TinyButton } from '../components/ButtonElements';
import ButtonTimer from '../components/SurfDetail/ButtonTimer';
import DesktopBanner from '../components/DesktopBanner';
import MobileBanner from '../components/MobileBanner';

const SurfDetailPage = ({ match }) => {
    const [ads, setAds] = useState();
    const [error, setError] = useState('');
    const [errorDevice, setErrorDevice] = useState('');
    const [boolian, setBoolian] = useState(false);
    const dark = localStorage.getItem('lightness') ? true : false;
    const deviceScreenhandler = () => {
        if (window.innerWidth < 1023 && ads.device === 'D') {
            setErrorDevice('The width of the window for desktop devices has been should more than 1000px!');
        };
        setBoolian(true);
    };
    useEffect(() => {
        const fetchSurfDetail = async () => {
            try {
                const { data } = await axiosInstance.get(`ptc/surf/${match.params.code}/`);
                setAds(data);
            } catch (error) {
                error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            };
        };
        fetchSurfDetail();
    }, [match.params.code]);
    useEffect(() => {
        if (ads !== undefined) {
            window.addEventListener('resize', deviceScreenhandler);
            deviceScreenhandler();
        };
        return () => {
            window.removeEventListener('resize', deviceScreenhandler);
        };
    });
    return (
        <MotherContainer dark={dark ? 1 : 0}>
            {
                error.length !== 0 &&
                <MsgContainer>
                    <MsgItem>
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {error}
                            </MsgText>
                        </MsgWrap>
                        <TinyButton to={`/surf/${localStorage.getItem('ptc_page_number')}`} primary={1} big={1}>
                            return
                        </TinyButton>
                    </MsgItem>
                </MsgContainer>
            }
            {
                errorDevice.length !== 0 &&
                <MsgContainer>
                    <MsgItem>
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errorDevice}
                            </MsgText>
                        </MsgWrap>
                        <TinyButton to={`/surf/${localStorage.getItem('surf_page_number')}`} primary={1} big={1}>
                            return
                        </TinyButton>
                    </MsgItem>
                </MsgContainer>
            }
            {ads !== undefined && errorDevice.length === 0 && boolian && error.length === 0 && <SurfDetail ads={ads} />}
            {ads !== undefined && errorDevice.length === 0 && boolian && error.length === 0 && (ads.device === 'D' ? <DesktopBanner /> : ads.device === 'M' ? <MobileBanner /> : null)}
            {ads !== undefined && errorDevice.length === 0 && boolian && error.length === 0 && <ButtonTimer ads={ads} />}
        </MotherContainer>
    )
}
export default SurfDetailPage;
