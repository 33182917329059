import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '15px 60px' : '10px 20px')};
    color: #fff;
    font-size: ${({ fontBig }) => (fontBig ? '1.2rem' : '1rem')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: transparent;
        color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
        border-inline: 3px solid ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
        box-shadow: 0 0 5px ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
        opacity: 1;
    }
`
export const TinyButtonCSS = css`
    border-radius: 50px;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '7px 60px' : '7px 20px')};
    color: #fff;
    font-size: 0.7rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1)' : ' rgba(39, 117, 85, 1)')};
        box-shadow: 0 0 5px ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
        letter-spacing: 1px;
        opacity: 1;
}
`
export const TinyButton = styled(Link)`
    ${TinyButtonCSS}
`
export const TinyButtonAnchor = styled.a`
    ${TinyButtonCSS}
`
export const FormButton = styled.button`
    background: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
    border: none;
    padding: ${({ big }) => (big ? '15px 60px' : '10px 20px')};
    color: #fff;
    font-size: 1.1rem;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
        background: transparent;
        border-inline: 3px solid ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
        box-shadow: 0 0 5px ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
    }
`
export const TinyFormButton = styled.button`
    border-radius: 50px;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '7px 60px' : '7px 20px')};
    color: #fff;
    font-size: ${({ big }) => (big ? '0.9rem' : '0.7rem')};
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1)' : ' rgba(39, 117, 85, 1)')};
        box-shadow: 0 0 5px ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
        letter-spacing: 1px;
        opacity: 1;
}
`
export const TinyButtonPtc = styled(Link)`
    border-radius: 50px;
    background: ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.5)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.5)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.5)'
                : 'rgba(29, 107, 187, 0.5)')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '7px 60px' : '7px 20px')};
    color: #fff;
    font-size: 0.8rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
        box-shadow: 0 0 5px ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
        letter-spacing: 1px;
        opacity: 1;
    }
`
export const TinyButtonSurf = styled.button`
    width: 100%;
    border-radius: 50px;
    background: ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.5)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.5)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.5)'
                : 'rgba(29, 107, 187, 0.5)')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '7px 60px' : '7px 20px')};
    color: #fff;
    font-size: 0.8rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
        box-shadow: 0 0 5px ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
        letter-spacing: 1px;
        opacity: 1;
    }
`
