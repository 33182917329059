import styled, { css } from 'styled-components/macro';
import { RefButtons } from '../Promotions/ReferralLinkTabElements';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export const ManageRefContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.2);
    padding: 25px;
`
export const ManageRefWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 10px;
`
export const ManageRefTable = styled.table`
    border-collapse: collapse;
    border-left: 2px solid rgba(29, 107, 187, 0.7);
    border-right: 2px solid rgba(29, 107, 187, 0.7);
    width: 90%;
`
export const ManageRefHeadWrap = styled.thead`
    width: 100%;
    background: rgba(29, 107, 187, 0.7);
`
export const ManageRefHeadRow = styled.tr`
    width: 100%;
`
export const ManageRefHeader = styled.th`
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 1.7px;
    text-transform: uppercase;
    padding: 5px 3px;
`
export const ManageRefBodyWrap = styled.tbody``
export const ManageRefBodyRow = styled.tr`
    &:nth-child(even) {
        background: rgba(39, 117, 85, 0.2);
    }
    &:nth-child(odd) {
        background: rgba(29, 107, 187, 0.2);
    }
`
export const ManageRefCell = styled.td`
    border-bottom: 2px solid rgba(29, 107, 187, 0.7);
    text-align: center;
    color: #fff;
    font-size: 0.9rem;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    padding: 5px 3px;
`
export const DetailButton = styled.button`
    ${RefButtons}
    background: rgba(39, 117, 85, 0.6);
    height: 25px;
    font-size: clamp(0.6rem, 2.1vw, 0.7rem);
    cursor: pointer;
    margin: auto;

    &: hover {
        background: rgba(29, 107, 187, 0.9);
    }
`
export const Paginationwrap = styled.div`
    height: 25px;
    background: rgba(29, 107, 187, 0.5);
    border-radius: 5px;
    margin: 20px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const PaginationIcons = css`
    color: #fff;
    font-size= 1.5rem;
    cursor: pointer;
    margin-top: 3px;
    transition: all 0.2s ease-in-out;

    &: hover {
        color: rgba(29, 107, 187, 0.9);
    }
`
export const PreviosWrap = styled(Link)``
export const PreviosIcon = styled(AiFillCaretLeft)`
    ${PaginationIcons}
`
export const NumberPageWrap = styled.div`
    background: rgba(39, 117, 85, 0.7);
    width: 50px;
    border-radius: 5px;
`
export const NumberPage = styled.div`
    text-align: center;
    color: #fff;
`
export const NextWrap = styled(Link)``
export const NextIcon = styled(AiFillCaretRight)`
    ${PaginationIcons}
`
export const DeleteButtonWrap = styled.div`
    margin-top: 25px;
`
