export const statisticsMessageData = {
    msg: 'Data is updated every 12 hours.'
};

export const statisticsMessageLangData = [
    {
        lang: 'es',
        info: {
            msg: 'Los datos se actualizan cada 12 horas.'
        }
    },
    {
        lang: 'ru',
        info: {
            msg: 'Данные обновляются каждые 12 часов.'
        }
    },
    {
        lang: 'pt',
        info: {
            msg: 'Os dados são atualizados a cada 12 horas.'
        }
    },
    {
        lang: 'fr',
        info: {
            msg: 'Les données sont mises à jour toutes les 12 heures.'
        }
    },
    {
        lang: 'de',
        info: {
            msg: 'Die Daten werden alle 12 Stunden aktualisiert.'
        }
    },
    {
        lang: 'it',
        info: {
            msg: 'I dati vengono aggiornati ogni 12 ore.'
        }
    }
];