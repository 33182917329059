import styled, { css } from 'styled-components/macro';
import { GiMoebiusStar } from 'react-icons/gi';
import { IoIosEye } from 'react-icons/io';
import { TitleWrap } from '../History/DepositTabElements';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import device from '../../constants/breakPoints';
import { Link } from 'react-router-dom';

export const PtcHeaderWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const IconWrapStyle = css`
    width: 50px;
    height: 90px;
    background : rgba(29, 107, 187, 0.5);
    border-radius: 5px;
    border: 1px solid rgba(29, 107, 187, 0.7);
    box-shadow: 0 0 10px rgba(29, 107, 187, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin: 25px 5px;
    transition: all 0.2s ease-in-out;
    @media screen and ${device.sm} {
        width: 30px;
    }
    &:hover {
        background: rgba(1, 219, 187, 0.5);
        transition: all 0.2s ease-in-out;  
    }
`
export const PtcBackPage = styled(Link)`
    ${IconWrapStyle}
`
export const PtcNextPage = styled(Link)`
    ${IconWrapStyle}
`
export const IconStyled = css`
    font-size: 2.2rem;
    color: #fff;
`
export const BackIcon = styled(IoIosArrowBack)`
    ${IconStyled}
`
export const NextIcon = styled(IoIosArrowForward)`
    ${IconStyled}
`
export const PtcInfoWrap = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.3);
    border: 1px solid rgba(29, 107, 187, 0.4);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 15px;
    margin: 25px;
    @media screen and ${device.sm} {
        margin: 25px 5px;
    }
`
export const InfoContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10%;
    margin: 5px auto;
    padding: 0 2%;
`
export const InfoWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(29, 107, 187, 0.4);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    padding: 5px 7px 0 7px;
`
export const TitleInfoWrap = styled(TitleWrap)`
    background: rgba(29, 107, 187, 0.6);
    h2 { 
        font-size: clamp(0.7rem, 2.4vw, 1rem);
    }
`
export const PtcContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.2)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.2)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.2)'
                : 'rgba(29, 107, 187, 0.2)')};
    padding: 25px;
    opacity: ${({ visitable }) => (visitable ? '1' : '0.5')};
    &:hover {
        opacity: ${({ visitable }) => (visitable ? '1' : '0.6')};
    }
`
export const PtcWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.2)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.2)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.2)'
                : 'rgba(29, 107, 187, 0.2)')};
    border: 1px solid ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.4)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.4)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.4)'
                : 'rgba(29, 107, 187, 0.4)')};
    box-shadow: 0 0 20px ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.7)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.7)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.7)'
                : 'rgba(29, 107, 187, 0.7)')};
    border-radius: 15px;
`
export const PtcTitleWrap = styled.div`
    width: 100%;
    background: ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.25)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.25)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.25)'
                : 'rgba(29, 107, 187, 0.25)')};
    border-radius: 15px 15px 0 0;
    box-shadow: 0 0 7px ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.7)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.7)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.7)'
                : 'rgba(29, 107, 187, 0.7)')};
    display: grid;
    grid-template-columns: ${({ refshow }) => (refshow ? '1fr 8fr 1fr' : '9fr 1fr')};
    grid-column-gap: 15px;
    align-items: center;
    padding: 10px 20px;
`
export const PtcRefIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const PtcTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ refshow }) => (refshow ? 'center' : 'flex-start')};

    h2 {
        font-size: clamp(0.8rem, 2.7vw, 1rem);
        color: ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        letter-spacing: 0.5px;
        word-spacing: 1.5px;
        text-align: center;
    }
`
export const PtcVisits = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const RefIcon = styled(GiMoebiusStar)`
    font-size: clamp(1.2rem, 4vw, 1.6rem);
    color: ${({ coloricon }) => (coloricon === 'red'
        ? '#5a14c8'
        : coloricon === 'yellow'
            ? '#787410'
            : coloricon === 'green'
                ? '#277555'
                : '#1D6BBB')};
`
export const VisitIcon = styled(IoIosEye)`
    font-size: clamp(1.2rem, 4vw, 1.6rem);
    color: ${({ coloricon }) => (coloricon === 'red'
        ? '#5a14c8'
        : coloricon === 'yellow'
            ? '#787410'
            : coloricon === 'green'
                ? '#277555'
                : '#1D6BBB')};
`
export const Number = styled.span`
    color: ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
    padding-left: 5px;
    font-size: clamp(0.8rem, 2.5vw, 1rem);
`
export const PtcContentWrap = styled.div`
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0 10px 0;
`
export const PtcDescription = styled.div`
    max-height:80px;
    width: 80%;
    background: ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.2)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.2)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.2)'
                : 'rgba(29, 107, 187, 0.2)')};
    border: 1px solid ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.4)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.4)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.4)'
                : 'rgba(29, 107, 187, 0.4)')};;
    border-radius: 5px;
    padding: 5px 10px;
    overflow: auto;
    p {
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
        font-size: clamp(0.85rem, 2.7vw, 1rem);
        letter-spacing: 0.5px;
        word-spacing: 1.5px;
    }
    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.5)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.5)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.5)'
                : 'rgba(29, 107, 187, 0.5)')};
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.3)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.3)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.3)'
                : 'rgba(29, 107, 187, 0.3)')};
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: ${({ color }) => (color === 'red'
        ? 'rgba(90, 20, 200, 0.8)'
        : color === 'yellow'
            ? 'rgba(120, 116, 16, 0.8)'
            : color === 'green'
                ? 'rgba(39, 117, 85, 0.8)'
                : 'rgba(29, 107, 187, 0.8)')};
    }
`
export const PtcSocials = styled.div`
    width: ${({ state }) => (state ? '20%' : 'none')};
    display: flex;
    flex-direction: ${({ state }) => (state ? 'column' : 'row')};
    justify-content: ${({ state }) => (state ? 'center' : 'space-between')};
    align-items: center;
    padding: 5px 0;
`
export const SocialWrap = styled.a`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-decoration: none;
    transition: 0.3s ease-in-out;
    margin-top: 2px;

    &:hover {
        background: ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
        transition: 0.3s ease-in-out;
    }
    img {
        width: 90%;
        height: 90%;
    }
`
export const PtcButtonwrap = styled.div`
`
export const PtcTimerWrap = styled.div`
    background: #1D6BBB;
    border-radius: 5px;
    padding: 2px 10px;
    box-shadow: 0 0 7px rgba(29, 107, 187, 0.7);
`
export const Timer = styled.div`
    color: #01DBBB;
    font-size: clamp(0.8rem, 2.4vw, 1.1rem);
`
export const PtcDetailWrap = styled.div`
    width: 75%;
    display: flex;
    justify-content: ${({ show }) => (show ? 'space-between' : 'center')};
    align-items: center;
    margin: auto auto 10px auto;
    span {
	font-size: clamp(0.75rem, 2.7vw, 0.95rem);
	color: ${({ color }) => (color === 'red'
	? 'rgba(90, 20, 200, 1)'
	: color === 'yellow'
	    ? 'rgba(120, 116, 16, 1)'
	    : color === 'green'
		? 'rgba(39, 117, 85, 1)'
		: 'rgba(29, 107, 187, 1)')};
	text-shadow: 0 0 2px ${({ color }) => (color === 'red'
	? 'rgba(90, 20, 200, 0.6)'
	: color === 'yellow'
	    ? 'rgba(120, 116, 16, 0.6)'
	    : color === 'green'
		? 'rgba(39, 117, 85, 0.6)'
		: 'rgba(29, 107, 187, 0.6)')};
	text-transform: uppercase;
	letter-spacing: 0.5px;
	word-spacing: 1.5px;
	text-align: center;
    }
`
