import React from 'react';
import axios from 'axios';
import { StoryDetailHeader, StoryDetailImg, StoryDetailWrap, StoryFeaturesButton, StoryFeaturesWrap, StoryImgWrap, StoryItemWrap, StorySubTitleWrap, SubTitle, StoryTitleWrap, StoryWrapper } from './IceStoryElements';

const IceStory = ({ info, index, activeStory, setStop, dark }) => {
    const handleClick = async (e, id) => {
	e.preventDefault();
	await axios.post('https://icefaucet.com/api/story/click/', { 'id': id });
    window.open(info.url, '_blank');
    };
    return (
	<>
	    {index === activeStory &&
		<StoryWrapper onClick={(e) => handleClick(e, info.id)} onMouseEnter={() => setStop(true)} onMouseLeave={() => setStop(false)} onTouchStart={() => setStop(true)} onTouchEnd={() => setStop(false)}>
		    <StoryItemWrap>
		        <StoryTitleWrap><h3>{info.title}</h3></StoryTitleWrap>
		        <StoryImgWrap primary={index % 2 === 0 ? 1 : 0}>
		            <img src={info.logo} alt={info.title} />
		        </StoryImgWrap>
		        <StorySubTitleWrap><SubTitle dark={dark ? 1 : 0} >{info.subtitle1}</SubTitle></StorySubTitleWrap>
		        <StorySubTitleWrap><SubTitle dark={dark ? 1 : 0}>{info.subtitle2}</SubTitle></StorySubTitleWrap>
		    </StoryItemWrap>
		    <StoryItemWrap>
		        <StoryFeaturesWrap>
		            <h4>Features:</h4>
		            <StoryFeaturesButton primary={index % 2 === 0 ? 1 : 0}>{info.feature1}</StoryFeaturesButton>
		            <StoryFeaturesButton primary={index % 2 === 0 ? 1 : 0}>{info.feature2}</StoryFeaturesButton>
		            <StoryFeaturesButton primary={index % 2 === 0 ? 1 : 0}>{info.feature3}</StoryFeaturesButton>
		        </StoryFeaturesWrap>
		        <StoryDetailWrap>
		            {info.title_content1 &&
				<StoryDetailHeader primary={index % 2 === 0 ? 1 : 0}><h4>{info.title_content1}</h4></StoryDetailHeader>
			    }
		            {info.img_content1 &&
				<StoryDetailImg primary={index % 2 === 0 ? 1 : 0}>
				    <img src={info.img_content1} alt='story images' />
				</StoryDetailImg>
			    }
		            {info.title_content2 &&
				<StoryDetailHeader primary={index % 2 === 0 ? 1 : 0}><h4>{info.title_content2}</h4></StoryDetailHeader>
			    }
		            {info.img_content2 &&
				<StoryDetailImg primary={index % 2 === 0 ? 1 : 0}>
				    <img src={info.img_content2} alt='story images' />
				</StoryDetailImg>
			    }
		        </StoryDetailWrap>
		    </StoryItemWrap>
		</StoryWrapper>
	    }
	</>
    )
}
export default IceStory;
