import React, { useState } from "react";
import { ComposableMap, ZoomableGroup, Geographies, Geography } from "react-simple-maps";
import { countriesData } from '../../data/countriesData';
import { InteractiveMapContainer, MapContainer, InfoCountriesContainer, InfoCardWrap, InfoCardTitle, InfoContentWrap, InfoContentMenu, InfoContentList } from '../SetUpPtc/IntractiveMapElements';

const geoData = countriesData;
const geoObjects = geoData.objects.ne_110m_admin_0_countries.geometries;

const InteractiveMapEdit = ({ alpha2, setAlpha2, countryName, setIsSubmitting }) => {
    const [region, setRegion] = useState('');
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState(countryName);

    const removeCountry = (e) => {
        const NAME = e.target.getAttribute('value');
        setCountries(countries.filter(removeCountry => removeCountry !== NAME));

        const ISO_A2 = geoObjects.filter(geoObject => geoObject.properties.NAME === NAME)
            .map(countryObject => countryObject.properties.ISO_A2)
            .find(ISO_A2Instance => ISO_A2Instance);
        setAlpha2(alpha2.filter(removeAlpha2 => removeAlpha2 !== ISO_A2));

        const SUBREGION = geoObjects.filter(geoObject => geoObject.properties.NAME === NAME)
            .map(countryObject => countryObject.properties.SUBREGION)
            .find(regionInstance => regionInstance);
        const regionCountries = geoObjects.filter(geoObject => geoObject.properties.SUBREGION === SUBREGION)
            .map((countryObject) => countryObject.properties.NAME);
        const results = countries.filter(country => regionCountries.includes(country));
        if (results.length === 0 || results.length === 1) {
            setRegions(regions.filter(removeRegion => removeRegion !== SUBREGION));
        };
    };

    return (
        <InteractiveMapContainer>
            <MapContainer style={{ background: 'rgba(29, 107, 187, 0.75)' }}>
                <ComposableMap projectionConfig={{ scale: 228 }} width={980} height={550}>
                    <ZoomableGroup
                        zoom={region === 'Northern America'
                            ? 2.5
                            : region === 'Central America'
                                ? 3.5
                                : region === 'South America'
                                    ? 2
                                    : region === 'Caribbean'
                                        ? 5
                                        : region === 'Northern Europe'
                                            ? 5
                                            : region === 'Western Europe'
                                                ? 5
                                                : region === 'Eastern Europe'
                                                    ? 3
                                                    : region === 'Southern Europe'
                                                        ? 5
                                                        : region === 'Central Asia'
                                                            ? 4
                                                            : region === 'Eastern Asia'
                                                                ? 3
                                                                : region === 'Western Asia'
                                                                    ? 3.5
                                                                    : region === 'Southern Asia'
                                                                        ? 3
                                                                        : region === 'South-Eastern Asia'
                                                                            ? 3
                                                                            : region === 'Melanesia'
                                                                                ? 5
                                                                                : region === 'Australia and New Zealand'
                                                                                    ? 3
                                                                                    : region === 'Northern Africa'
                                                                                        ? 3
                                                                                        : region === 'Western Africa'
                                                                                            ? 3.5
                                                                                            : region === 'Middle Africa'
                                                                                                ? 2.8
                                                                                                : region === 'Eastern Africa'
                                                                                                    ? 2.5
                                                                                                    : region === 'Southern Africa'
                                                                                                        ? 3
                                                                                                        : region === 'Antarctica'
                                                                                                            ? 1.3
                                                                                                            : 1}
                        center={region === 'Northern America'
                            ? [-80, 47]
                            : region === 'Central America'
                                ? [-85, 20]
                                : region === 'South America'
                                    ? [-66, -19]
                                    : region === 'Caribbean'
                                        ? [-75, 17]
                                        : region === 'Northern Europe'
                                            ? [7, 58]
                                            : region === 'Western Europe'
                                                ? [7, 45]
                                                : region === 'Eastern Europe'
                                                    ? [70, 60]
                                                    : region === 'Southern Europe'
                                                        ? [7, 40]
                                                        : region === 'Central Asia'
                                                            ? [60, 40]
                                                            : region === 'Eastern Asia'
                                                                ? [95, 40]
                                                                : region === 'Western Asia'
                                                                    ? [40, 30]
                                                                    : region === 'Southern Asia'
                                                                        ? [60, 25]
                                                                        : region === 'South-Eastern Asia'
                                                                            ? [120, 8]
                                                                            : region === 'Melanesia'
                                                                                ? [155, -10]
                                                                                : region === 'Australia and New Zealand'
                                                                                    ? [140, -30]
                                                                                    : region === 'Northern Africa'
                                                                                        ? [7, 17]
                                                                                        : region === 'Western Africa'
                                                                                            ? [5, 10]
                                                                                            : region === 'Middle Africa'
                                                                                                ? [20, 2.5]
                                                                                                : region === 'Eastern Africa'
                                                                                                    ? [40, -5]
                                                                                                    : region === 'Southern Africa'
                                                                                                        ? [40, -30]
                                                                                                        : region === 'Antarctica'
                                                                                                            ? [5, -60]
                                                                                                            : [22, 8]}>
                        <Geographies geography={geoData}>
                            {({ geographies }) =>
                                geographies.map(geo => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        onClick={() => {
                                            const { SUBREGION } = geo.properties;
                                            setRegion(SUBREGION);

                                            const regionObjects = geographies.filter(geo => geo.properties.SUBREGION === SUBREGION);
                                            const regionCountries = regionObjects.map((regionObject) => regionObject.properties.NAME);

                                            if (!regions.includes(SUBREGION)) {
                                                setRegions(regions => [...regions, SUBREGION]);
                                            };

                                            const { NAME } = geo.properties;
                                            if (!countries.includes(NAME)) {
                                                setCountries(countries => [...countries, NAME]);
                                            } else {
                                                setCountries(countries.filter(removeCountry => removeCountry !== NAME));
                                                const results = regionCountries.filter(regionCountry => countries.includes(regionCountry));
                                                if (results.length === 0 || results.length === 1) {
                                                    setRegions(regions.filter(removeRegion => removeRegion !== SUBREGION));
                                                };
                                            };

                                            const { ISO_A2 } = geo.properties;
                                            if (!alpha2.includes(ISO_A2)) {
                                                setAlpha2(alpha2 => [...alpha2, ISO_A2]);
                                            } else {
                                                setAlpha2(alpha2.filter(removeAlpha2 => removeAlpha2 !== ISO_A2));
                                            };
                                            setIsSubmitting(false);
                                        }}
                                        style={{
                                            default: {
                                                fill: countries.includes(geo.properties.NAME)
                                                    ? "#01DBBB"
                                                    : "#F0EAD6",
                                                outline: "none",
                                                transition: "all ease-in-out 0.3s"

                                            },
                                            hover: {
                                                fill: countries.includes(geo.properties.NAME)
                                                    ? "rgba(255, 0, 0, 0.5)"
                                                    : "rgba(1, 219, 187, 0.5)",
                                                outline: "none",
                                                cursor: "pointer",
                                                transition: "all 0.3s"
                                            },
                                            pressed: {
                                                fill: "blue",
                                                outline: "none"
                                            },
                                        }}
                                    />
                                ))
                            }
                        </Geographies>
                    </ZoomableGroup>
                </ComposableMap>
            </MapContainer >
            <InfoCountriesContainer style={{ gridTemplateColumns: '1fr' }}>
                <InfoCardWrap>
                    <InfoCardTitle>
                        <span>countries</span>
                    </InfoCardTitle>
                    <InfoContentWrap>
                        <InfoContentMenu>
                            {countries.map((country, index) => (
                                <InfoContentList key={index} value={country} onClick={removeCountry}>{country}</InfoContentList>
                            ))}
                        </InfoContentMenu>
                    </InfoContentWrap>
                </InfoCardWrap>
            </InfoCountriesContainer>
        </InteractiveMapContainer>

    );
};

export default InteractiveMapEdit;
