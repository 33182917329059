import React from 'react';
import { useSelector } from 'react-redux';
import { NavPtcContainer, NavPtcItem } from './SetUpAdsNavigateElements';

const SetUpAdsNavigate = () => {
    const showPtcSetUp = useSelector(state => state.ptcSetUp);
    const showPtcFilters = useSelector(state => state.ptcFilters);
    const showPtcFilters2 = useSelector(state => state.ptcFilters2);
    const showPtcFilters3 = useSelector(state => state.ptcFilters3);
    const showPtcSetUpEnd = useSelector(state => state.ptcSetUpEnd);
    return (
        <NavPtcContainer>
            <NavPtcItem active={showPtcSetUp ? 1 : 0}>
                <span>1</span>
            </NavPtcItem>
            <NavPtcItem active={showPtcFilters2 ? 1 : 0}>
                <span>2</span>
            </NavPtcItem>
            <NavPtcItem active={showPtcFilters3 ? 1 : 0}>
                <span>3</span>
            </NavPtcItem>
            <NavPtcItem active={showPtcFilters ? 1 : 0}>
                <span>4</span>
            </NavPtcItem>
            <NavPtcItem active={showPtcSetUpEnd ? 1 : 0}>
                <span>5</span>
            </NavPtcItem>
        </NavPtcContainer>
    )
}
export default SetUpAdsNavigate