import React from 'react';
import { ButtonWrapperPromo, BuyTicketWrapPromo, ImgWrapPromo, ButtonWrapPromo, ButtonPromo } from '../Lottery/LotteryElements';

const LotteryPromo = ({ big }) => {
    return (
	<ButtonWrapperPromo>
	    <BuyTicketWrapPromo big={big}>
	        <ButtonWrapPromo>
	            <ButtonPromo to='lottery'>
	                WATCH <br />
	                LOTTERY
	            </ButtonPromo>
	        </ButtonWrapPromo>
	        <ImgWrapPromo>
	            <img src='https://icefaucet.com/media/help/blog/lottery.jpg' alt='lottery pic' />
	        </ImgWrapPromo>
	    </BuyTicketWrapPromo>
	</ ButtonWrapperPromo>
    )
}
export default LotteryPromo;
