import styled from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { Link } from 'react-router-dom';
import { ButtonWrap } from '../OfferUserStatus/OfferUserStatusElements';
import { MdDescription } from 'react-icons/md';

export const FilterWraper = styled.div`
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 15px auto 0 auto;
    @media screen and ${device.tn} {
	grid-template-columns: 1fr;
	grid-column-gap: 30px;
    };
`
export const ArticleWrap = styled(Link)`
    text-decoration: none;
    ${ButtonWrap}
`
export const SelectLabel = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background: rgba(29, 107, 187, 1);
    width: 100%;
    padding: 10px 5px;
    margin: 15px auto auto auto;
    h4 {
	color: rgb(1, 219, 187);
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
	font-size: 0.9rem;
	letter-spacing: 1.2px;
	text-transform: uppercase;
    }
`
export const SelectSL = styled.ul`
    background: rgba(29, 107, 187, 0.5);
    width: 100%;
    height: 150px;
    overflow: auto;
    &::-webkit-scrollbar {
	width: 8px;
	height: 8px;
    }
    &::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px rgba(29, 107, 187, 0.5); 
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
	background: rgba(29, 107, 187, 0.6);
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
	background: rgba(29, 107, 187, 0.9);
    }
`
export const OptionSLList = styled.li`
    list-style: none;
    display: ${({ disable }) => (disable ? 'none' : 'flex')};
    justify-content: space-between;''
    border-bottom: 1px solid rgba(29, 107, 187, 0.9);
    padding: 10px 7px;
    cursor: pointer;
    h4 {
	color: ${({ active }) => (active ? 'rgb(1, 219, 187)' : '#fff')};
	font-size: 0.9rem;
	font-weight: 400;
    }
    span {
	color: ${({ active }) => (active ? 'rgb(1, 219, 187)' : '#fff')};
	font-size: 0.75rem;
    }
`
export const InputSectionWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-column-gap: 30px;
    margin: 25px auto 0 auto;
    background: rgba(39, 117, 85, 0.2);
    width: 100%;
    border-bottom: 2px solid rgba(1, 219, 187, 1);
    padding: 15px 5px;
`
export const SectionNumberWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 200px;
    background: rgba(39, 117, 85, 0.3);
    border: 5px solid rgba(39, 117, 85, 0.7);
    h4 {
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
	font-size: 0.9rem;
	letter-spacing: 1.2px;
	text-transform: uppercase;
	text-align: center;
    }
`
export const InputWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justfy-content: center;
`
export const UplaodContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justfy-content: center;
`
export const ImgSectionWrap = styled.div`
    max-width: 100%;
    max-height: 100%;
    color: #fff;
    font-size: 1rem;
    margin: auto;
    display: flex;
    align-items: center;
    justfy-content: center;
    
`
export const ImgSection = styled.img`
    width: 100%;
    height: 100%;
    margin: auto;
`
export const ImgInput = styled.input`
    padding: 5px 10px 6px 40px;
    border: none;
    border-radius: 20px;
    font-size: clamp(0.92rem, 3.4vw, 1.05rem);
    width: 100%;
    color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};

    ::placeholder {
	color: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : 'rgba(39, 117, 85, 0.8)')};
    }

    :disabled {
	background: rgba(10, 10, 10, 0.2);
	opacity: 1;
    }
    &:focus {
	outline: 2px solid ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
    }
`
export const DescInputWrap = styled.div`
    width: 100%;
    margin-top: 10px;
`
export const TextIcon = styled(MdDescription)`
    position: absolute;
    transform: translate(75%, 90%);
    color: #1D6BBB;
    font-size: 1.1rem;
    opacity: 0.70;
`
export const DescInput = styled.textarea`
    padding: 5px 10px 6px 40px;
    margin: 10px 0 5px 0;
    border: none;
    border-radius: 20px;
    font-size: 1.05rem;
    width: 100%;
    resize: none;
    ::placeholder {
	color: rgba(29, 107, 187, 0.7);
	font-size: 0.9rem;
    }
    &:focus {
	outline: 4px solid #1D6BBB;;
    }
`
export const SectionInput = styled.input`
    padding: 5px 10px 6px 40px;
    margin: 10px 0 5px 0;
    border: none;
    border-radius: 20px;
    font-size: 1.05rem;
    width:100%;

    ::placeholder {
	color: rgba(29, 107, 187, 0.7);
	font-size: 0.9rem;
    }
    &:focus {
	outline: 4px solid #1D6BBB;;
    }
`
export const PlusContainer = styled.div`
    width: 100%;
    height: 40px;
    background: rgba(1, 219, 187, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
`
export const PlusWrap = styled.div`
    width: 30px;
    height: 30px;
    background: rgba(1, 219, 187, 1);
    border: 3px solid #1D6BBB;
    border-radius 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
	transform: scale(1.09);
	transition: 0.3s ease-in-out;
    }
    span {
	text-align: center;
	font-size: 2rem;
	font-weight: 700;
	color: #1D6BBB;
    }
`
