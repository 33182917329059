import {
    SHOW_BANNERSETUP,
    NOTSHOW_BANNERSETUP,
    SHOW_BANNERFILTERS,
    NOTSHOW_BANNERFILTERS,
    SHOW_BANNERFILTERS2,
    NOTSHOW_BANNERFILTERS2,
    SHOW_BANNERSUCCESS,
    NOTSHOW_BANNERSUCCESS,
} from '../constants/bannerSetUpRedux';

export const bannerSetUpAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_BANNERSETUP
            })
        } else {
            dispatch({
                type: NOTSHOW_BANNERSETUP
            })
        };
    };
};

export const bannerFiltersAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_BANNERFILTERS
            })
        } else {
            dispatch({
                type: NOTSHOW_BANNERFILTERS
            })
        };
    };
};


export const bannerFilters2Action = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_BANNERFILTERS2
            })
        } else {
            dispatch({
                type: NOTSHOW_BANNERFILTERS2
            })
        };
    };
};
export const bannerSuccessAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_BANNERSUCCESS
            })
        } else {
            dispatch({
                type: NOTSHOW_BANNERSUCCESS
            })
        };
    };
};
