import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { MiningContainer, MiningWrap, MiningImgWrap, MiningImg, ContentWrap } from '../AutoAdsView/AutoAdsViewElements';
import { MembershipContainer, MembershipWrap, ImageWrap, Image, Button } from '../LuckyWheel/LuckyWheelElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../PtcView/PtcViewElements';
import { DescWrap } from '../History/DepositTabElements';
import { MsgWrap, MsgText } from '../MessageElements';
import omega from '../../images/omega.png';
import beta from '../../images/beta.png';
import alpha from '../../images/alpha.png';
import { Circle } from '../Loading';

const VideoView = ({ info }) => {
    const history = useHistory();
    const [active, setActive] = useState(false);
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const fetchVideoView = async () => {
	setLoading(true);
	try {
	    const { data } = await axiosInstance.get('ptc/video/start/');
	    history.push(`/play/video/${data.info.code}/${data.info.uid_enc}/${data.info.token_enc}`);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    const handleClick = () => {
	setActive(true);
	fetchVideoView();
    };
    return (
	<>
	    <MiningContainer primary={1}>
	        <MiningWrap primary={1} >
	    	    <MiningImgWrap style={{ padding: '15px' }}>
	                <MiningImg src='https://icefaucet.com/media/help/blog/video.png' alt='youtube video' style={{ maxWidth: '340px' }} />
	            </MiningImgWrap>
	    	    <ContentWrap>
	            	<PtcHeaderWrap>
	                    <PtcInfoWrap>
	                        <InfoContainer>
	    			    <InfoWrap>
	        			<TitleInfoWrap>
	            			    <h2 style={{ fontSize: 'clamp(0.7rem, 2.4vw, 0.85rem)' }}>number</h2>
	        			</TitleInfoWrap>
	        			<DescWrap>
	            			    <h2 style={{ fontSize: 'clamp(0.7rem, 2.4vw, 0.85rem)' }}>{info.all_info.number}</h2>
	        			</DescWrap>
	    			    </InfoWrap>
	    			    <InfoWrap>
	        			<TitleInfoWrap>
	            			    <h2 style={{ fontSize: 'clamp(0.7rem, 2.4vw, 0.85rem)' }}>primecoin</h2>
	        			</TitleInfoWrap>
	        			<DescWrap>
	            			    <h2 style={{ fontSize: 'clamp(0.7rem, 2.4vw, 0.85rem)' }}>{info.all_info.total}</h2>
	        			</DescWrap>
	    			    </InfoWrap>
	    			</InfoContainer>
	                    </PtcInfoWrap>
	                </PtcHeaderWrap>
	                <MembershipContainer style={{ gridTemplateColumns: '1fr' }}>
	                    <MembershipWrap active={1}>
	    			<ImageWrap>
	        		    <Image src={info.membership === 1 ? alpha : info.membership === 0.75 ? beta : omega} alt='omega' />
	    			</ImageWrap>
	    			<Button type='button' onClick={!active ? handleClick : null}>
	        		    {loading ? <Circle style={{ margin: 'auto', width: '15px', height: '15px' }} /> : 'start'}
	    			</Button>
	    		    </MembershipWrap>
	    		</MembershipContainer>
	    		{apiError &&
			    <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
				<MsgText primary={0}>
				    {apiError}
				</MsgText>
			    </MsgWrap>
			}
	    	    </ContentWrap>
	        </MiningWrap>
	    </MiningContainer>
	</>
    )
}
export default VideoView;
