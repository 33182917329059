import React, { useState } from 'react';
import { OfferCard, TitleWrap, LogoWrap, Logo, ContentWrap, ProgressBarWrap, ProgressBar, ButtonWrap } from './OfferViewElements';
import { AdscoinPromoWrap, AdscoinPromoSpan } from '../PromoStyles';
import { TinyButton } from '../ButtonElements';
import { HelpWrap } from '../HelpElements';

const OfferView = ({ offer, userLevel }) => {
    const [showPromo, setShowPromo] = useState(false);
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    const handlePromo = () => {
	setShowPromo(!showPromo);
    };
    return (
	<>
	    <OfferCard active={offer.level <= userLevel ? 1 : 0}>
	        <TitleWrap><h4>{offer.types}</h4></TitleWrap>
	        {!showPromo &&
	            <LogoWrap>
	                <Logo src={offer.logo} alt={offer.name} />
	            </LogoWrap>
		}
	    	{!showPromo &&
	            <ContentWrap>
	                {offer.cal_pop &&
			    <>
			        <span>pop:</span>
			        <ProgressBarWrap>
				    <ProgressBar width={offer.pop} />
			        </ProgressBarWrap>
			    </>
		        }
	            </ContentWrap>
		}
	        {offer.promo > 0 &&
		    <HelpWrap style={{ marginBottom: '20px', flexDirection: 'column' }} show={showPromo ? 1 : 0}>
			<p>
			    {offer.desc_promo}
			</p>
		    </HelpWrap>
		}
	        <ButtonWrap promo={offer.promo > 0 ? 1 : 0}>
	            {offer.promo > 0 &&
			<AdscoinPromoWrap onClick={() => handlePromo()} style={{ top: '0', left: '0' }}>
			    <AdscoinPromoSpan>
				+{offer.promo}%
			    </AdscoinPromoSpan>
			</AdscoinPromoWrap>
		    }
	    	    <TinyButton
	                to={offer.level <= userLevel && offer.name !== 'bitlabs' ? `offers/${offer.name}` : offer.level <= userLevel && offer.name === 'bitlabs' ? `offers/${offer.name}/${offer.types}` : '#'}
	                primary={0}
	                big={0}
	                title={offer.desc}
	                onContextMenu={handleRightClick}
	            >
	    		{offer.level <= userLevel ? 'start' : `level ${offer.level}`}
	            </TinyButton>
	        </ButtonWrap>
	    </OfferCard >
	</>
    )
}
export default OfferView;
