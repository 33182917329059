import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const Table = styled.table`
    border-collapse: collapse;
    border-left: 2px solid rgba(29, 107, 187, 0.7);
    border-right: 2px solid rgba(29, 107, 187, 0.7);
    width: 80%;
    margin: 0 auto 25px auto;
    @media screen and ${device.sm} {
	width: 98%;
    }
`
export const HeadWrap = styled.thead`
    width: 100%;
`
export const HeadRow = styled.tr`
    width: 100%;
`
export const Header = styled.th`
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.55rem, 2.7vw, 0.85rem);
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 1.5px;
    text-transform: uppercase;
    padding: 15px 3px;
    background: rgba(29, 107, 187, 0.8);
`
export const BodyWrap = styled.tbody`
    max-height:160px;
    overflow: auto;
    &::-webkit-scrollbar {
	width: 7px;
	height: 10px;
    }
    &::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5);
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
	background: rgba(1, 219, 187, 0.3);
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
	background: rgba(1, 219, 187, 0.8);
    }
`
export const BodyRow = styled.tr`
    background: rgba(29, 107, 187, 0.5);
    &:nth-child(even) {
	background: rgba(39, 117, 85, 0.5);
    }
`
export const Cell = styled.td`
    border-bottom: 2px solid rgba(29, 107, 187, 0.7);
    text-align: center;
    color: #fff;
    font-size: clamp(0.66rem, 2.7vw, 0.85rem);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    padding: 10px 3px;
`
