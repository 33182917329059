import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch } from 'react-redux';
import { creditCheckAction } from '../../actions/PtcSetUpAction';
import { sponsorSetUpAction, sponsorFiltersAction } from '../../actions/sponsorSetUpAction';
import { PtcForm, PtcFormInputContainer, PtcInputWrap, PtcFormInput, PtcLinkIcon, PtcTitleIcon, FormLangContainer, FilterLangWrap, FormLangWrap, LangWrap, LangName } from '../SetUpPtc/SetUpPtcFormElements.js';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { TinyFormButton } from '../ButtonElements';
import { FormButtonsWrap } from '../SetUpPtc/CreditCheckElements';
import { ValidateSponsorForm } from './ValidateSponsorForm';
import { langData } from '../../data/langData';
import { MsgText, MsgWrap } from '../MessageElements';

const SetUpSponsorForm = () => {
    const dispatch = useDispatch();
    const [langActive, setLangActive] = useState(Object.freeze(false));
    const [langName, setLangName] = useState(Object.freeze('en'));
    const [langIndex, setLangIndex] = useState(0);
    const [values, setValues] = useState(Object.freeze({
        url: '',
        title: '',
        description: ''
    }));
    const [errors, setErrors] = useState({
        url: '',
        title: '',
        description: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setIsSubmitting(false);
        setErrors('');
        setError('');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateSponsorForm(values));
        setIsSubmitting(true);
    };
    const handleLangActive = () => {
        setLangActive(!langActive);
        if (langActive) {
            localStorage.removeItem('lang');
        };
    };
    const handleLangClick = (e, index) => {
        const LANG = e.target.getAttribute('name');
        if (LANG && langName !== LANG) {
            setLangName(LANG);
            setLangIndex(index);
        };
        setError('');
    };
    const handleBack = () => {
        dispatch(sponsorSetUpAction({ prop: false }));
        dispatch(creditCheckAction({ prop: true }));
    };
    const fetchLangUserCkeck = async () => {
        try {
            await axiosInstance.post('sponsor/advertise/lang-filter-check/', {
                active_lang: langActive,
                lang: langName,
                title: values.title,
                description: values.description
            });
            fetchSponsorSetUp();
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
        setIsSubmitting(false);
    };
    const fetchSponsorSetUp = async () => {
        langActive && localStorage.setItem('lang', langName);
        !langActive && localStorage.removeItem('lang');
        localStorage.setItem('url', values.url);
        localStorage.setItem('title', values.title);
        localStorage.setItem('description', values.description);
        dispatch(sponsorSetUpAction({ prop: false }));
        dispatch(sponsorFiltersAction({ prop: true }));
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            fetchLangUserCkeck();
        };
        return () => setIsSubmitting(false);
    });
    return (
        <PtcForm action='#' onSubmit={handleSubmit}>
            <PtcFormInputContainer>
                <FormHeader>
                    <h2>language of your ads</h2>
                </FormHeader>
                <FilterContainer>
                    <InstructionWrap>
                        <Instruction>
                            If you are sure that visitors understand the language of your ads,
                            press the following button, otherwise, your ads display to all visitors in any language. (it could be really dangerous for CTR.)
                        </Instruction>
                    </InstructionWrap>
                    <FilterLangWrap>
                        <ItemWrap >
                            <ItemName onClick={handleLangActive} itemActive={langActive ? 1 : 0}>
                                Accept <br />
                                +10% CPM
                            </ItemName>
                        </ItemWrap>
                    </FilterLangWrap>
                    <FormLangContainer>
                        <FormLangWrap>
                            {langActive && langData.map((lang, index) => (
                                <LangWrap key={index}>
                                    <LangName name={lang.value} onClick={(e) => handleLangClick(e, index)} itemActive={langIndex === index ? 1 : 0}>{lang.name}</LangName>
                                </LangWrap>
                            ))}
                        </FormLangWrap>
                    </FormLangContainer>
                </FilterContainer>
                <FormHeader>
                    <h2>describe your ads</h2>
                </FormHeader>
                <PtcInputWrap>
                    <PtcLinkIcon />
                    <PtcFormInput
                        type='url'
                        name='url'
                        placeholder='Enter your sponsor URL address.'
                        onChange={handleChange}
                        value={values.url}
                    >
                    </PtcFormInput>
                </PtcInputWrap>
                {errors.url &&
                    <MsgWrap>
                        <MsgText>
                            {errors.url}
                        </MsgText>
                    </MsgWrap>}
                <PtcInputWrap>
                    <PtcTitleIcon />
                    <PtcFormInput
                        type='text'
                        name='title'
                        placeholder='Enter the sponsor title.'
                        onChange={handleChange}
                        value={values.title}
                    >
                    </PtcFormInput>
                </PtcInputWrap>
                {errors.title &&
                    <MsgWrap>
                        <MsgText>
                            {errors.title}
                        </MsgText>
                    </MsgWrap>}
                <PtcInputWrap>
                    <PtcTitleIcon />
                    <PtcFormInput
                        type='text'
                        name='description'
                        placeholder='Enter the sponsor description'
                        onChange={handleChange}
                        value={values.description}
                    >
                    </PtcFormInput>
                </PtcInputWrap>
                {errors.description &&
                    <MsgWrap>
                        <MsgText>
                            {errors.description}
                        </MsgText>
                    </MsgWrap>}
                {error &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {error}
                        </MsgText>
                    </MsgWrap>}
            </PtcFormInputContainer>
            <FormButtonsWrap>
                <TinyFormButton type='submit' primary={1} big={0}>
                    Next
                </TinyFormButton>
                <TinyFormButton type='button' primary={1} big={0} onClick={handleBack}>
                    back
                </TinyFormButton>
            </FormButtonsWrap>
        </PtcForm>
    )
};
export default SetUpSponsorForm;