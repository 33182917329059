export const typeData = {
    subject: 'payments proof',
    card: [
	{
	    src: 'https://icefaucet.com/media/help/blog/manual_wallet.png',
	    alt: 'Wallet',
	    button: 'Wallet',
	    type: 'W'
	},
	{
	    src: 'https://icefaucet.com/media/help/blog/pay.PNG',
	    alt: 'Payeer',
	    button: 'Payeer',
	    type: 'P'
	},
	{
	    src: 'https://icefaucet.com/media/help/blog/faucetpay.jpg',
	    alt: 'Faucetpay',
	    button: 'FaucetPay',
	    type: 'F'
	}
    ]
};
export const headTable = [
    'number',
    'username(id)',
    'address',
    'processor',
    'value',
    'date'
];
export const smallHeadTable = [
    'address',
    'processor',
    'value'
];
export const SettingsTypePaymentsData = [
    {
	src: 'https://icefaucet.com/media/help/blog/manual_wallet.png',
	alt: 'wallet',
	title: 'Cryptocurrency wallet',
	type: 'W'
    },
    {
	src: 'https://icefaucet.com/media/help/blog/pay.PNG',
	alt: 'Payeer',
	title: 'Payeer wallet',
	type: 'P'
    },
    {
	src: 'https://icefaucet.com/media/help/blog/faucetpay.jpg',
	alt: 'Faucetpay',
	title: 'Faucetpay wallet',
	type: 'F'
    }
];
