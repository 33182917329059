export const makeMoneyFeatures = {
    id: 'special features',
    darkBackground: true,
    items: [
        {
            image: require('../images/no-invest.png').default,
            alt: 'no investment',
            description: 'No need for investment'
        },
        {
            image: require('../images/no-popup.png').default,
            alt: 'no pop-up',
            description: 'No annoying pop-up ads'
        },
        {
            image: require('../images/instant-payment.png').default,
            alt: 'instant payment',
            description: 'Instant payment directly to your wallet or Faucetpay'
        },
        {
            image: require('../images/unique-system.png').default,
            alt: 'exclusive application',
            description: 'Exclusive and unique application'
        }
    ]
};
export const langMakeMoneyFeatures = [
    {
        lang: 'es',
        info: {
            id: 'special features',
            darkBackground: true,
            items: [
                {
                    image: require('../images/no-invest.png').default,
                    alt: 'Sin inversión',
                    description: 'Sin necesidad de inversión'

                },
                {
                    image: require('../images/no-popup.png').default,
                    alt: 'Sin anuncios emergentes',
                    description: 'Sin anuncios emergentes molestos'
                },
                {
                    image: require('../images/instant-payment.png').default,
                    alt: 'Pago instantáneo',
                    description: 'Pago instantáneo directamente a su billetera o Faucetpay'
                },
                {
                    image: require('../images/unique-system.png').default,
                    alt: 'Aplicación exclusiva',
                    description: 'Aplicación exclusiva y única'
                }
            ]
        }
    },
    {
        lang: 'ru',
        info: {
            id: 'special features',
            darkBackground: true,
            items: [
                {
                    image: require('../images/no-invest.png').default,
                    alt: 'Без вложений',
                    description: 'Нет необходимости в инвестициях'

                },
                {
                    image: require('../images/no-popup.png').default,
                    alt: 'Нет всплывающих окон',
                    description: 'Нет раздражающих всплывающих окон с рекламой'
                },
                {
                    image: require('../images/instant-payment.png').default,
                    alt: 'Мгновенная оплата',
                    description: 'Мгновенный платеж прямо на ваш кошелек или Faucetpay'
                },
                {
                    image: require('../images/unique-system.png').default,
                    alt: 'Эксклюзивное приложение',
                    description: 'Эксклюзивное и уникальное приложение'
                }
            ]
        }
    },
    {
        lang: 'pt',
        info: {
            id: 'special features',
            darkBackground: true,
            items: [
                {
                    image: require('../images/no-invest.png').default,
                    alt: 'Sem investimento',
                    description: 'Sem necessidade de investimento'

                },
                {
                    image: require('../images/no-popup.png').default,
                    alt: 'Sem pop-up',
                    description: 'Sem anúncios pop-up irritantes'
                },
                {
                    image: require('../images/instant-payment.png').default,
                    alt: 'Pagamento instantâneo',
                    description: 'Pagamento instantâneo diretamente em sua carteira ou Faucetpay'
                },
                {
                    image: require('../images/unique-system.png').default,
                    alt: 'Aplicação exclusiva',
                    description: 'Aplicação exclusiva e única'
                }
            ]
        }
    },
    {
        lang: 'fr',
        info: {
            id: 'special features',
            darkBackground: true,
            items: [
                {
                    image: require('../images/no-invest.png').default,
                    alt: 'Aucun investissement',
                    description: "Pas besoin d'investissement"

                },
                {
                    image: require('../images/no-popup.png').default,
                    alt: 'Pas de publicités pop-up',
                    description: 'Pas de publicités pop-up ennuyeuses'
                },
                {
                    image: require('../images/instant-payment.png').default,
                    alt: 'Paiement instantané',
                    description: 'Paiement instantané directement sur votre portefeuille ou sur Faucetpay'
                },
                {
                    image: require('../images/unique-system.png').default,
                    alt: 'Application exclusive',
                    description: 'Application exclusive et unique'
                }
            ]
        }
    },
    {
        lang: 'de',
        info: {
            id: 'special features',
            darkBackground: true,
            items: [
                {
                    image: require('../images/no-invest.png').default,
                    alt: 'Keine Investition',
                    description: 'Keine Investitionen erforderlich'

                },
                {
                    image: require('../images/no-popup.png').default,
                    alt: 'Kein Popup',
                    description: 'Keine lästigen Pop-up-Werbung'
                },
                {
                    image: require('../images/instant-payment.png').default,
                    alt: 'Sofortzahlung',
                    description: 'Sofortige Zahlung direkt an Ihre Brieftasche oder Faucetpay'
                },
                {
                    image: require('../images/unique-system.png').default,
                    alt: 'Exklusive Anwendung',
                    description: 'Exklusive und einzigartige Anwendung'
                }
            ]
        }
    },
    {
        lang: 'it',
        info: {
            id: 'special features',
            darkBackground: true,
            items: [
                {
                    image: require('../images/no-invest.png').default,
                    alt: 'Nessun investimento',
                    description: 'Non ha bisogno di investimenti'

                },
                {
                    image: require('../images/no-popup.png').default,
                    alt: 'Nessun pop-up',
                    description: 'Nessun fastidioso annuncio pop-up'
                },
                {
                    image: require('../images/instant-payment.png').default,
                    alt: 'Pagamento istantaneo',
                    description: 'Pagamento istantaneo direttamente sul tuo portafoglio o Faucetpay'
                },
                {
                    image: require('../images/unique-system.png').default,
                    alt: 'Applicazione esclusiva e unica',
                    description: 'Applicazione esclusiva e unica'
                }
            ]
        }
    }
];
