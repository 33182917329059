import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Wrapper, Header, LeftWrap, LogoWrapLink, SubjectWrap, DescWrap, Card, Title, Desc } from '../TOS/TOSElements';
import logo from '../../images/logo.png';
import { InfoRow, Column1, TextWrap, Column2, ImgWrap } from '../InfoSection/InfoElements';

const AboutUs = ({ code, info }) => {
    const location = useLocation();
    return (
        <Container>
            <Wrapper>
                <Header>
                    <LeftWrap>
                        <LogoWrapLink
                            to={location.pathname === '/about'
                                ? '/'
                                : location.pathname === `/info/ref/${code}/about`
                                    ? `/ref/${code}`
                                    : '#'
                            }
                        >
                            <img src={logo} alt={'logo of icefaucet'} />
                        </LogoWrapLink>
                    </LeftWrap>
                    <SubjectWrap>
                        <h1>{info.subject}</h1>
                    </SubjectWrap>
                </Header>
                <DescWrap style={{ marginTop: '15px' }}>
                    {info.card.map((card, index) => (
                        <Card key={index} primary={index % 2 === 0 ? 1 : 0}>
                            <Title primary={index % 2 === 0 ? 1 : 0}>
                                <h2>{card.title}</h2>
                            </Title>
                            <InfoRow style={{ gridGap: '3vw' }} imgStart={index % 2 === 0 ? 1 : 0}>
                                <Column1>
                                    <TextWrap style={{ textAlign: 'left', fontSize: 'clamp(0.85rem, 2.8vw, 1rem)' }}>
                                        <Desc>
                                            {card.desc}
                                        </Desc>
			    		{card.desc2 &&
					    <Desc style={{ marginTop: '3px' }}>
						{card.desc2}
					    </Desc>
					}
			    		{card.desc3 &&
					    <Desc style={{ marginTop: '3px' }}>
						{card.desc3}
					    </Desc>
					}
			    		{card.sign &&
					    <span style={{ fontStyle: 'italic', fontSize: 'clamp(0.80rem, 2.8vw, 0.95rem)' }}>{card.sign}</span>
					}
			    		{card.sign2 &&
					    <span style={{ fontStyle: 'italic', fontSize: 'clamp(0.80rem, 2.8vw, 0.95rem)' }}>{card.sign2}</span>
					}
                                    </TextWrap>
                                </Column1>
                                <Column2>
                                    <ImgWrap>
                                        <img style={{ marginTop: '7px' }} src={card.img} alt={card.alt} />
                                    </ImgWrap>
                                </Column2>
                            </InfoRow>
                        </Card>
                    ))}

                </DescWrap>
            </Wrapper>
        </Container>
    )
}

export default AboutUs;
