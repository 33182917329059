import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PtcSetUpAction, PtcFilters2Action, PtcFilters3Action } from '../../actions/PtcSetUpAction';
import axiosInstance from '../../axios';
import { TinyFormButton } from '../ButtonElements';
import { FormButtonsWrap } from './CreditCheckElements';
import { PtcForm, PtcFormInputContainer } from './SetUpPtcFormElements.js';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import InteractiveMap from './IntractiveMap';
import { MsgWrap, MsgText } from '../MessageElements';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName } from './SetUpPtcFilterElements.js';
import { FilterLangWrap } from './SetUpPtcFormElements.js';

const SetUpPtcFilter2 = () => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [alpha2, setAlpha2] = useState(Object.freeze([]));
    const [userNumber, setUserNumber] = useState();
    const [geoClick, setGeoClick] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(alpha2).length === 0 && geoClick) {
            setErrors('You must select at least one country.');
        } else {
            setErrors('');
        };

        setIsSubmitting(true);
    };

    const handleClick = () => {
        setErrors('');
        setIsSubmitting(false);
        setGeoClick(!geoClick);
    };

    const fetchCountryUserCkeck = async (mounted) => {
        const { data } = await axiosInstance.post('ptc/advertise/country-user-check/', {
            target_country: alpha2
        });
        localStorage.setItem('target_country', JSON.stringify(alpha2));
        if (mounted) {
            setUserNumber(data.userNumber);
            if (data.userNumber >= 50) {
                fetchCountryFilterCkeck();
                dispatch(PtcFilters2Action({ prop: false }));
                dispatch(PtcFilters3Action({ prop: true }));
            } else {
                setErrors(`${userNumber} active users could watch your ads, please select more countries.`);
            };
        };
    };
    const fetchCountryFilterCkeck = async () => {
        await axiosInstance.post('ptc/advertise/country-filter-check/', {
            geo_click: geoClick
        });
    };
    useEffect(() => {
        let mounted = true;
        if (errors.length === 0 && isSubmitting && geoClick) {
            fetchCountryUserCkeck(mounted);
        };
        return () => mounted = false;
    });
    useEffect(() => {
        if (!geoClick && isSubmitting) {
            fetchCountryFilterCkeck();
            localStorage.removeItem('target_country');
            dispatch(PtcFilters2Action({ prop: false }));
            dispatch(PtcFilters3Action({ prop: true }));
        };
    });
    return (
        <>
            <PtcForm action='#' onSubmit={handleSubmit}>
                <PtcFormInputContainer>
                    <FormHeader>
                        <h2>where do you like to display your ads?</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                If you want to display your ads to only your target countries press the following button.
                            </Instruction>
                        </InstructionWrap>
                        <FilterLangWrap>
                            <ItemWrap >
                                <ItemName onClick={handleClick} itemActive={geoClick ? 1 : 0}>
                                    Accept <br />
                                    +%25 CPC
                                </ItemName>
                            </ItemWrap>
                        </FilterLangWrap>
                    </FilterContainer>
                    {geoClick && <InteractiveMap alpha2={alpha2} setAlpha2={setAlpha2} setIsSubmitting={setIsSubmitting} setErrors={setErrors} />}
                </PtcFormInputContainer>
                {errors && geoClick
                    ? <MsgWrap primary={0} >
                        <MsgText primary={0}>
                            {errors}
                        </MsgText>
                    </MsgWrap>
                    : null}
                <FormButtonsWrap>
                    <TinyFormButton type='submit' primary={1} big={0}>
                        Next
                    </TinyFormButton>
                    <TinyFormButton type='button' primary={1} big={0} onClick={() => { dispatch(PtcFilters2Action({ prop: false })); dispatch(PtcSetUpAction({ prop: true })) }}>
                        back
                    </TinyFormButton>
                </FormButtonsWrap>
            </PtcForm>
        </>
    )
}
export default SetUpPtcFilter2;
