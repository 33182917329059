import styled from 'styled-components';
import { BodyWrap, HeadWrap } from '../Auth/AuthElements';
import device from '../../constants/breakPoints';

export const PromotionBodyWrap = styled(BodyWrap)`
    width: 80%;
    max-width: 700px;
`
export const PromotionHeadWrap = styled(HeadWrap)`
    width: 90%;
    max-width: 700px;
`
export const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    text-decoration: none;

    p{
        color: #01DBBB;
        font-size: clamp(0.85rem, 2.4vw, 1.15rem);
        font-weight: 700;
        letter-spacing: 1.3px;
        word-spacing: 2.5px;
        text-transform: uppercase;
        margin-left: 15px;
        text-shadow: 1px 2px 5px #000;
    }
`
export const PromotionTabContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const PromotionTabWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;

    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
    }
`
export const Tab = styled.div`    
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;
    color: ${({ active }) => (active ? '#01DBBB' : '#fff')};
    text-shadow: 0 0 1px #000;
    border-bottom: ${({ active }) => (active ? '3px solid #01DBBB' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #01DBBB;
        transition: all 0.3s ease-in-out;
    }
    p {
        font-size: clamp(0.85rem, 2.2vw, 1rem);
    }
`