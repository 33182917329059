import React, { useState, useEffect } from 'react';
import { TinyButtonPtc } from '../ButtonElements';
import { PtcButtonwrap } from './ButtonTimerElements';

const ButtonTimer = ({ ads }) => {
    const [buttonTimer, setButtonTimer] = useState(7);
    const adsColor = ads.duration === 2.5 ? 'red' : ads.duration === 2 ? 'yellow' : ads.duration === 1.5 ? 'green' : 'blue';

    const handleClick = (e) => {
        e.preventDefault();
    };

    const handleRightClick = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (buttonTimer === 0) {
            return;
        };
        if (Object.keys(ads).length > 0) {
            const timer = setTimeout(() => {
                setButtonTimer(buttonTimer - 1);
            }, 1000);
            return () => clearTimeout(timer);
        };
    });

    return (
        <PtcButtonwrap>
            <TinyButtonPtc to={`/play/ptc/${ads.code}/${ads.uid_enc}/${ads.token_enc}`}
                onClick={buttonTimer > 0 ? handleClick : null}
                onContextMenu={handleRightClick}
                title={ads.org_url}
                color={adsColor}
                big={1}
            >
                {buttonTimer > 0 ? buttonTimer + ' sec' : 'visit'}
            </TinyButtonPtc>
        </PtcButtonwrap>
    )
}

export default ButtonTimer