import React from 'react';
import { useSelector } from 'react-redux';
import { SetUpContainer, SetUpBgWrap, AdsSetUpContainer, AdsContent } from '../SetUpAds/SetUpAddsElements';
import setupPtcBg from '../../images/Auth_background.jpg';
import SetUpAdsNavigate from './SetUpAdsNavigate';
import CreditCheck from '../SetUpPtc/CreditCheck';
import SetUpMiningForm from './SetUpMiningForm';
import SetUpMiningEnd from './SetUpMiningEnd';
import SetUpPtcSuccess from '../SetUpPtc/SetUpPtcSuccess';

const SetUpMining = () => {
    const showCreditCheck = useSelector(state => state.creditCheck);
    const showMiningSetUp = useSelector(state => state.miningSetUp);
    const showMiningSetUpEnd = useSelector(state => state.miningSetUpEnd);
    const showMiningSuccess = useSelector(state => state.miningSuccess);
    return (
        <SetUpContainer>
            <SetUpBgWrap>
                <img src={setupPtcBg} alt={'background of set up PTC page'} />
            </SetUpBgWrap>
            <AdsSetUpContainer>
                <AdsContent>
                    {!showCreditCheck && !showMiningSuccess && <SetUpAdsNavigate />}
                    {showCreditCheck && !showMiningSetUp && !showMiningSetUpEnd && !showMiningSuccess
                        ? <CreditCheck />
                        : showMiningSetUp && !showCreditCheck && !showMiningSetUpEnd && !showMiningSuccess
                            ? <SetUpMiningForm />
                            : showMiningSetUpEnd && !showCreditCheck && !showMiningSetUp && !showMiningSuccess
                                ? <SetUpMiningEnd />
                                : showMiningSuccess && !showCreditCheck && !showMiningSetUp && !showMiningSetUpEnd
                                    ? <SetUpPtcSuccess />
                                    : null}

                </AdsContent>
            </AdsSetUpContainer>
        </SetUpContainer>
    )
};
export default SetUpMining;