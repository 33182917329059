import styled, {css} from 'styled-components/macro';
import device from '../../constants/breakPoints';

export const QuestionsWrap = styled.div`
    width: 100%;
    padding: 0 15px;
    p {
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: #ffffff;
        letter-spacing: 1.1px;
        word-spacing: 1.7px;
        line-height: 1.45;
    }
`
export const ChoicesContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 50px;
    margin: 15px auto 25px auto;
`
export const ChoicesWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 10%;
    
    @media screen and ${device.md} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1px;
        grid-row-gap: 15px;
    }
    @media screen and ${device.tn} {
	grid-template-columns: 1fr;
        grid-row-gap: 15px;
    }
`
export const Choices = css`
    width: 75px;
    height: 75px;
    background: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(29, 107, 187, 0.4)')};
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 0 10px rgba(29, 107, 187, 0.8);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    @media screen and ${device.sm} {
        width: 60px;
        height: 60px;
    }

    &:hover {
        transition: all 0.3s ease-in-out;
        background: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(39, 117, 85, 0.4)')};
        border: 1px solid rgba(39, 117, 85, 0.5);
        box-shadow: 0 0 10px rgba(39, 117, 85, 0.8);
    }

    img {
        width: 100%;
        height: 100%;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: #ffffff;
        letter-spacing: 1.1px;
        word-spacing: 1.7px;
        line-height: 1.45;
    }
`
export const ChoicesImg = styled.div`
    ${Choices}
`
export const ChoicesTxt = styled.div`
    ${Choices}
    width: 100px;
    height: 100px;

    @media screen and ${device.sm} {
	width: 80px;
	height: 80px;
    }

`
