import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/logo.png';
import { homeNavbar, adsNavbar, makeMoneyNavbar, statisticsNavbar, langHomeNavbar, langAdsNavbar, langMakeMoneyNavbar, langStatisticsNavbar } from '../../data/navbarData';
import { langData } from '../../data/langData';
import { headerOpenAction, headerScrollAction } from '../../actions/headerAction';
import { NavContainer, NavWrap, Nav, NavLogo, ImgLogo, HamIcon, Bars, CloseIcon, NavMenu, RightIcons, LangIcon, LanguageWrap, LanguageName, NavLinks, AccountIconLink, AccountIcon } from './NavbarElements';

const Navbar = ({ code }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isOpen = useSelector(state => state.isOpen);
    const scrollNav = useSelector(state => state.scrollNav);
    const [langClick, setLangClick] = useState(false);
    const pageLang = localStorage.getItem('pageLang');
    const homeNavbarLang = pageLang ? langHomeNavbar.find(data => data.lang === pageLang).info : homeNavbar;
    const adsNavbarLang = pageLang ? langAdsNavbar.find(data => data.lang === pageLang).info : adsNavbar;
    const makeMoneyNavbarLang = pageLang ? langMakeMoneyNavbar.find(data => data.lang === pageLang).info : makeMoneyNavbar;
    const statisticsNavbarLang = pageLang ? langStatisticsNavbar.find(data => data.lang === pageLang).info : statisticsNavbar;

    const handleClick = (e) => {
        const value = e.target.getAttribute('value');
        if (value !== 'en') {
            localStorage.setItem('pageLang', value);
        } else {
            localStorage.removeItem('pageLang', value);
        };
        window.location.reload(false);
    };

    const changeNav = () => {
        dispatch(headerScrollAction())
    };
    const closeSidebar = () => {
        if (window.innerWidth > 1023) {
            dispatch(headerOpenAction({ prop: false }));
        }
    };
    const closeLangList = () => {
        if (window.innerWidth < 1023) {
            setLangClick(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    });
    useEffect(() => {
        window.addEventListener('resize', closeSidebar);
        window.addEventListener('resize', closeLangList);
        return () => {
            window.removeEventListener('resize', closeSidebar);
            window.removeEventListener('resize', closeLangList);
        };
    });

    return (
        <NavContainer scrollnav={scrollNav ? 1 : 0} >
            <NavWrap>
                <Nav>
                    <NavLogo
                        to={location.pathname === '/' || location.pathname === '/advertise' || location.pathname === '/make-money' || location.pathname === '/statistics'
                            ? '/'
                            : location.pathname === `/ref/${code}` || location.pathname === `/ref/${code}/advertise` || location.pathname === `/ref/${code}/make-money` || location.pathname === `/ref/${code}/statistics`
                                ? `/ref/${code}`
                                : '#'
                        }
                        onClick={() => {
                            dispatch(headerOpenAction({ prop: false }))
                        }}
                    >
                        <ImgLogo src={logo} alt={'IceFaucet'} scrollnav={scrollNav ? 1 : 0} />
                    </NavLogo>
                    <HamIcon onClick={() => dispatch(headerOpenAction({ prop: 'toggle' }))} scrollnav={scrollNav ? 1 : 0}>
                        {isOpen ? <CloseIcon /> : <Bars />}
                    </HamIcon>
                    <NavMenu scrollnav={scrollNav ? 1 : 0}>
                        {(location.pathname === '/' || location.pathname === `/ref/${code}`) && homeNavbarLang.map((item, index) => (
                            <li key={index}>
                                <NavLinks to={item.link} scrollnav={scrollNav ? 1 : 0} smooth={true} duration={500} spy={true} offset={index === 0 ? 1 : index === 3 ? 7 : 4} activeClass='active'>
                                    {item.title}
                                </NavLinks>
                            </li>
                        ))}
                        {(location.pathname === '/advertise' || location.pathname === `/ref/${code}/advertise`) && adsNavbarLang.map((item, index) => (
                            <li key={index}>
                                <NavLinks to={item.link} scrollnav={scrollNav ? 1 : 0} smooth={true} duration={500} spy={true} offset={index !== 0 ? 4 : 1} activeClass='active'>
                                    {item.title}
                                </NavLinks>
                            </li>
                        ))}
                        {(location.pathname === '/make-money' || location.pathname === `/ref/${code}/make-money`) && makeMoneyNavbarLang.map((item, index) => (
                            <li key={index}>
                                <NavLinks to={item.link} scrollnav={scrollNav ? 1 : 0} smooth={true} duration={500} spy={true} offset={index === 0 ? 1 : 4} activeClass='active'>
                                    {item.title}
                                </NavLinks>
                            </li>
                        ))}
                        {(location.pathname === '/statistics' || location.pathname === `/ref/${code}/statistics`) && statisticsNavbarLang.map((item, index) => (
                            <li key={index}>
                                <NavLinks to={item.link} scrollnav={scrollNav ? 1 : 0} smooth={true} duration={500} spy={true} offset={index === 0 ? 0 : 3} activeClass='active'>
                                    {item.title}
                                </NavLinks>
                            </li>
                        ))}
                    </NavMenu>
                    <RightIcons>
                        <LangIcon scrollnav={scrollNav ? 1 : 0} onClick={() => setLangClick(!langClick)} />
                        {langClick &&
                            <LanguageWrap>
                                {langData.map((lang, index) => (
                                    <LanguageName key={index} value={lang.value} onClick={handleClick}>
                                        {lang.name}
                                    </LanguageName>
                                ))}
                            </LanguageWrap>
                        }

                        <AccountIconLink
                            to={
                                location.pathname === '/' || location.pathname === '/advertise' || location.pathname === '/make-money' || location.pathname === '/statistics'
                                    ? '/auth'
                                    : location.pathname === `/ref/${code}` || location.pathname === `/ref/${code}/advertise` || location.pathname === `/ref/${code}/make-money` || location.pathname === `/ref/${code}/statistics`
                                        ? `/auth/ref/${code}`
                                        : '#'
                            }
                        >
                            <AccountIcon scrollnav={scrollNav ? 1 : 0} />
                        </AccountIconLink>
                    </RightIcons>

                </Nav>
            </NavWrap>
        </NavContainer >
    )
}
export default Navbar;
