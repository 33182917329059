import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { StackContainer, StackWrap, TitleWrap } from '../Stack/StackElements';
import { ContentWrap } from '../OfferWalls/CardsElements';
import Cards from './Cards'

const OfferWalls = ({ offerData }) => {
    const [data, setData] = useState();
    const [apiError, setApiError] = useState('');
    useEffect(() => {
	const fetchPtcView = async () => {
	    try {
		const { data } = await axios.get('https://icefaucet.com/api/offer/offerwalls/');
		setData(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	};
	fetchPtcView();
    }, []);
    return (
	<StackContainer primary={1}>
	    <StackWrap primary={1}>
	        <TitleWrap primary={1}>
	            <h2>{offerData.desc}</h2>
	        </TitleWrap>
	        <ContentWrap>
	            {data !== undefined && !apiError && data.data.map((offer, index) => (
			<Cards key={index} offer={offer} index={index} />
		    ))}
	        </ContentWrap>
	    </StackWrap>
	</StackContainer>
    )
}

export default OfferWalls;
