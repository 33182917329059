import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import EarningContainer from '../components/ActivityContainer/EarningContainer';
import TotalEarningContainer from '../components/ActivityContainer/TotalEarningContainer';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';

const ActivitiesPage = () => {
    const [info, setInfo] = useState();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchAccountInfo = async () => {
            try {
                const { data } = await axiosInstance.get('earning/activities/');
                setInfo(data.info);
            } catch (error) {
                error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            };
            setLoading(false);
	};
        fetchAccountInfo();
    }, []);
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
            {!loading && error &&
                <MsgWrap primary={0} style={{ margin: '10px auto', width: '80%' }}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
            {!loading && info !== undefined && !error && <EarningContainer info={info} />}
            {!loading && info !== undefined && !error && <TotalEarningContainer info={info} />}
        </>
    )
}
export default ActivitiesPage;
