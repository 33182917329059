import React from 'react';
import { StackContainer, StackWrap, TitleWrap } from '../Stack/StackElements';
import Cards from './Cards';
import { withdrawalInfo } from '../../data/withdrawData';

const Gateways = ({ gateData }) => {
    return (
	<StackContainer primary={0}>
	    <StackWrap primary={0}>
		<TitleWrap primary={0}>
		    <h2>{gateData.desc}</h2>
		</TitleWrap>
		<Cards info={withdrawalInfo} />
	    </StackWrap>
	</StackContainer>
    )
}
export default Gateways;
