import React from 'react';
import { PtcContainer, PtcWrap, PtcTitleWrap, PtcRefIcon, PtcTitle, PtcVisits, RefIcon, VisitIcon, Number, PtcContentWrap, PtcDescription, PtcSocials, SocialWrap, PtcButtonwrap, PtcDetailWrap } from '../PtcView/PtcViewElements';
import facebook from '../../images/facebook.svg';
import whatsapp from '../../images/whatsapp.svg';
import instagram from '../../images/instagram.svg';
import youtube from '../../images/youtube.svg';
import twitter from '../../images/twitter.svg';
import tiktok from '../../images/tiktok.png';
import telegram from '../../images/telegram.png';
import pinterest from '../../images/pinterest.svg';
import reddit from '../../images/reddit.svg';
import linkedin from '../../images/linkedin.svg';
import { TinyButtonPtc } from '../ButtonElements';

const ViewSetUpPtc = () => {
    const title = localStorage.getItem('title');
    const description = localStorage.getItem('description') ? localStorage.getItem('description') : null;
    const social1 = localStorage.getItem('social1') ? localStorage.getItem('social1') : null;
    const social2 = localStorage.getItem('social2') ? localStorage.getItem('social2') : null;
    const social3 = localStorage.getItem('social3') ? localStorage.getItem('social3') : null;
    const duration = parseFloat(localStorage.getItem('duration'));
    const adsDuration = duration === 2.5 ? '40' : duration === 2 ? '30' : duration === 1.5 ? '20' : '10';
    const pcoin = duration * 10;
    const refshow = localStorage.getItem('refshow') === 'true' ? true : false;
    const adsColor = duration === 2.5 ? 'red' : duration === 2 ? 'yellow' : duration === 1.5 ? 'green' : 'blue';
    return (
        <>
            <PtcContainer color={adsColor} visitable={1}>
                <PtcWrap color={adsColor}>
                    <PtcTitleWrap refshow={refshow ? 1 : 0} color={adsColor}>
                        {refshow
                            && <PtcRefIcon>
                                <RefIcon coloricon={adsColor} />
                            </PtcRefIcon>
                        }
                        <PtcTitle refshow={refshow ? 1 : 0} color={adsColor}>
                            <h2>{title}</h2>
                        </PtcTitle>
                        <PtcVisits>
                            <VisitIcon coloricon={adsColor} />
                            <Number color={adsColor}>0</Number>
                        </PtcVisits>
                    </PtcTitleWrap>
                    <PtcContentWrap>
                        {description
                            && <PtcDescription color={adsColor}>
                                <p>{description}</p>
                            </PtcDescription>
                        }
                        {social1 || social2 || social3
                            ? <PtcSocials state={description ? 1 : 0}>
                                {social1
                                    && <SocialWrap href={social1 && social1} target='_blank' rel='external' color={adsColor}>
                                        {social1.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
                                        {social1.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
                                    </SocialWrap>
                                }
                                {social2
                                    && <SocialWrap href={social2 && social2} target='_blank' rel='external' color={adsColor}>
                                        {social2 && social2.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
                                        {social2 && social2.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
                                    </SocialWrap>
                                }
                                {social3
                                    && <SocialWrap href={social3 && social3} target='_blank' rel='external' color={adsColor}>
                                        {social3 && social3.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
                                        {social3 && social3.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
                                    </SocialWrap>
                                }
                            </PtcSocials>
                            : null
                        }
                    </PtcContentWrap>
	    	    <PtcDetailWrap color={adsColor} show={1}>
	                <span>{adsDuration} sec</span>
                        <PtcButtonwrap>
                            <TinyButtonPtc 
	    			to={'ptc/play'}
                            	color={adsColor}
                            	big={1}
                            >
                                visit
                            </TinyButtonPtc>
                        </PtcButtonwrap>
	    	    	<span>{pcoin} pc</span>
	    	    </PtcDetailWrap>
                </PtcWrap>
            </PtcContainer>
        </>
    )
}
export default ViewSetUpPtc
