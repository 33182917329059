const size = {
    tn: '380px',
    sm: '512px',
    md: '768px',
    lg: '1024px',
}
const device = {
    tn: `(max-width: ${size.tn})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(min-width: ${size.lg})`
}
export default device;