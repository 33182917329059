import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hoverButtonAction } from '../../actions/buttonAction';
import { useLocation, useParams } from 'react-router-dom';
import { HeroContainer, HeroBg, HeroContent, HeroHeading, HeroBtnWrapper, ArrowFrorward, ArrowRight } from './HeroElements';
import { Button } from '../ButtonElements';

const HeroSection = ({ heroData }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { code } = useParams();
    const hover = useSelector(state => state.hoverButton);

    return (
        <HeroContainer>
            <HeroBg >
                <img src={heroData.src} alt={heroData.alt} />
            </HeroBg>
            <HeroContent>
                <HeroHeading headingBlue={heroData.headingBlue ? 1 : 0}>
                    {heroData.headingText}
                </HeroHeading>
                <p>{heroData.description}</p>
                <HeroBtnWrapper>
                    <Button
                        to={
                            location.pathname === '/make-money' || location.pathname === '/advertise'
                                ? '/auth'
                                : location.pathname === `/ref/${code}/make-money` || location.pathname === `/ref/${code}/advertise`
                                    ? `/auth/ref/${code}`
                                    : '#'
                        }
                        onMouseEnter={() => dispatch(hoverButtonAction())}
                        onMouseLeave={() => dispatch(hoverButtonAction())}
                        primary={heroData.buttonBlue ? 1 : 0}
                        big={1}
                        fontbig={1}
                    >
                        {heroData.buttonText} {hover ? <ArrowFrorward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}
export default HeroSection;
