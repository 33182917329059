import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch } from 'react-redux';
import { creditCheckAction } from '../../actions/PtcSetUpAction';
import { miningSetUpAction, miningSetUpEndAction } from '../../actions/miningSetUpAction';
import { ValidateSetUpForm } from './ValidateMining';
import { TinyFormButton } from '../ButtonElements';
import { PtcForm, PtcFormInputContainer, PtcInputWrap, PtcFormInput, PtcLinkIcon, PtcTitleIcon } from '../SetUpPtc/SetUpPtcFormElements.js';
import { FilterContainer, InstructionWrap, Instruction, FilterInputWrap, RangeInput, NumberInput, NumberInputWrap, UpcodeWrap } from '../SetUpPtc/SetUpPtcFilterElements.js';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { FormButtonsWrap } from '../SetUpPtc/CreditCheckElements';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { MsgText, MsgWrap } from '../MessageElements';

const SetUpMiningForm = () => {
    const dispatch = useDispatch();
    const [values, setValues] = useState(Object.freeze({
        url: '',
        title: '',
    }));
    const [errors, setErrors] = useState({
        url: '',
        title: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [visitValue, setVisitValue] = useState(Object.freeze(1000));
    const [error, setError] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const totalCost = 2 * visitValue;
    const upcodeValue = Math.floor(visitValue * parseFloat(localStorage.getItem('upcode')));
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setIsSubmitting(false);
        setErrors({
            url: '',
            title: '',
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateSetUpForm(values));
        const adscoin = localStorage.getItem('adcoin');
        if (visitValue < 1000) {
            setError('Please enter a number greater than or equal to 1000.');
        } else if (parseInt(adscoin) < totalCost) {
            setError('You do not have enogh adscoin.');
        } else if (visitValue > 1000000) {
            setError('Please enter a number less than or equal to 1000000.');
        }
        setIsSubmitting(true);
    };
    const handleChangeVisit = (e) => {
        let number = parseInt(e.target.value)
        if (!isNaN(number)) {
            setVisitValue(parseInt(number));
        };
        setIsSubmitting(false);
        setError('');
    };
    const maxValue = () => {
        const adscoin = parseInt(localStorage.getItem('adcoin'));
        const maximum = Math.floor(adscoin / 2);
        if (maximum <= 100000) {
            setVisitValue(maximum);
        } else if (maximum > 1000000) {
            setVisitValue(1000000);
        };
        setIsSubmitting(false);
        setError('');
    };
    const handleBack = () => {
        dispatch(miningSetUpAction({ prop: false }));
        dispatch(creditCheckAction({ prop: true }))
    }
    const fetchUpCodeUserCkeck = async () => {
        const { data } = await axiosInstance.get('ptc/advertise/upcode-user-check/');
        localStorage.setItem('upcode', data.upcode);
    };
    const fetchVisitCheck = async () => {
        await axiosInstance.post('mining/advertise/visit-check/', {
            visit: visitValue
        });
    };
    const fetchMiningSetUp = async () => {
        localStorage.setItem('url', values.url);
        localStorage.setItem('title', values.title);
        localStorage.setItem('visit', visitValue);
        fetchVisitCheck();
        dispatch(miningSetUpAction({ prop: false }));;
        dispatch(miningSetUpEndAction({ prop: true }));
        setIsSubmitting(false);
    };
    useEffect(() => {
        !localStorage.getItem('upcode') && fetchUpCodeUserCkeck();
        if (Object.keys(errors).length === 0 && error.length === 0 && isSubmitting) {
            fetchMiningSetUp();
        };
    });
    return (
        <>
            <PtcForm action='#' onSubmit={handleSubmit}>
                <PtcFormInputContainer>
                    <FormHeader>
                        <h2>describe your advertise</h2>
                    </FormHeader>
                    <PtcInputWrap>
                        <PtcLinkIcon />
                        <PtcFormInput
                            type='url'
                            name='url'
                            placeholder='Enter your URL address.'
                            onChange={handleChange}
                            value={values.url}
                        >
                        </PtcFormInput>
                    </PtcInputWrap>
                    {errors.url &&
                        <MsgWrap>
                            <MsgText>
                                {errors.url}
                            </MsgText>
                        </MsgWrap>}
                    <PtcInputWrap>
                        <PtcTitleIcon />
                        <PtcFormInput
                            type='text'
                            name='title'
                            placeholder='Enter your ads title.'
                            onChange={handleChange}
                            value={values.title}
                        >
                        </PtcFormInput>
                    </PtcInputWrap>
                    {errors.title &&
                        <MsgWrap>
                            <MsgText>
                                {errors.title}
                            </MsgText>
                        </MsgWrap>}
                    <FormHeader>
                        <h2>Set number of visits</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                It is exactly equal to the number of users who could earn by watching your ads.
                            </Instruction>
                        </InstructionWrap>
                        <FilterInputWrap>
                            <RangeInput
                                type='range'
                                name='visit'
                                min='1000'
                                max='1000000'
                                value={visitValue}
                                onChange={handleChangeVisit}
                            />
                            <NumberInputWrap>
                                <NumberInput
                                    type='number'
                                    name='visit'
                                    value={visitValue}
                                    onChange={handleChangeVisit}
                                />
                                <TinyFormButton type='button' primary={0} big={0} onClick={maxValue}>
                                    max
                                </TinyFormButton>
                            </NumberInputWrap>
                            {totalCost >= 250000 && <UpcodeWrap>
                                <span>
                                    with upcode: {upcodeValue} visits
                                </span>
                                <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                                <HelpWrap show={showHelp ? 1 : 0}>
                                    <p>If cost of your ads is more than 25$, based on your level, you get more credits. Masternode members get 10% more credit.</p>
                                </HelpWrap>
                            </UpcodeWrap>}
                            {error.length > 0 &&
                                <MsgWrap>
                                    <MsgText>
                                        {error}
                                    </MsgText>
                                </MsgWrap>}
                        </FilterInputWrap>
                    </FilterContainer>
                </PtcFormInputContainer>
                <FormButtonsWrap>
                    <TinyFormButton type='submit' primary={1} big={0}>
                        Next
                    </TinyFormButton>
                    <TinyFormButton
                        type='button'
                        primary={1}
                        big={0}
                        onClick={handleBack}
                    >
                        back
                    </TinyFormButton>
                </FormButtonsWrap>
            </PtcForm>
        </>
    )
}
export default SetUpMiningForm;