import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { SidebarContainer } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { ModalWrapper } from '../AdsControl/MoreInfoModalElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { ContentWrap, TimePriceWrap, TimeWrap, PriceWrap, TimerDeposit, InstructionWrap } from '../Deposit/ModalElements';
import Countdown, { zeroPad } from 'react-countdown';
import { FormButton } from '../ButtonElements';
import { QuestionsWrap, ChoicesContainer, ChoicesWrap, ChoicesImg, ChoicesTxt } from './ModalElements';
import { instructionsLangData } from '../../data/selectLangData';

const Modal = ({ lang, langName, modal, setModal }) => {
    const [timer, setTimer] = useState();
    const [clicked, setClicked] = useState(false);
    const [info, setInfo] = useState();
    const [choicesName1, setChoicesName1] = useState('');
    const [choicesIndex1, setChoicesIndex1] = useState('');
    const [choicesName2, setChoicesName2] = useState('');
    const [choicesIndex2, setChoicesIndex2] = useState('');
    const [choicesName3, setChoicesName3] = useState('');
    const [choicesIndex3, setChoicesIndex3] = useState('');
    const [choicesName4, setChoicesName4] = useState('');
    const [choicesIndex4, setChoicesIndex4] = useState('');
    const [choicesName5, setChoicesName5] = useState('');
    const [choicesIndex5, setChoicesIndex5] = useState('');
    const [error, setError] = useState('');
    const [complete, setComplete] = useState(false);
    const instructionsLang = instructionsLangData.find(data => data.lang === lang).info;
    const renderer = ({ minutes, seconds }) => {
        return <TimeWrap red={timer < Date.now() + 1 * 30 * 1000 ? 1 : 0}>
            <TimerDeposit red={timer < Date.now() + 1 * 30 * 1000 ? 1 : 0}>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerDeposit>
        </TimeWrap>;
    };
    const handleClick = () => {
        setTimer(Date.now() + 60000);
        setClicked(true);
        fetchLangQuestions();
    };
    const handleAChoice = (e, index, choiceIndex) => {
        if (index === 0) {
            const choicesName1Target = e.target.getAttribute('name');
            if (choicesName1Target && choicesName1Target !== choicesName1) {
                setChoicesName1(choicesName1Target);
                setChoicesIndex1(choiceIndex);
            };
        } else {
            const choicesName2Target = e.target.getAttribute('name');
            if (choicesName2Target && choicesName2Target !== choicesName2) {
                setChoicesName2(choicesName2Target);
                setChoicesIndex2(choiceIndex);
            };
        };
    };
    const handleBChoice = (e, index, choiceIndex) => {
        if (index === 0) {
            const choicesName3Target = e.target.getAttribute('name');
            if (choicesName3Target && choicesName3Target !== choicesName3) {
                setChoicesName3(choicesName3Target);
                setChoicesIndex3(choiceIndex);
            };
        } else {
            const choicesName4Target = e.target.getAttribute('name');
            if (choicesName4Target && choicesName4Target !== choicesName4) {
                setChoicesName4(choicesName4Target);
                setChoicesIndex4(choiceIndex);
            };
        };
    };
    const handleCChoice = (e, choiceIndex) => {
        const choicesName5Target = e.target.getAttribute('name');
        if (choicesName5Target && choicesName5Target !== choicesName5) {
            setChoicesName5(choicesName5Target);
            setChoicesIndex5(choiceIndex);
        };
    };
    const handleComplete = () => {
        fetchUserSelectLang();
    };
    const fetchLangQuestions = async () => {
        try {
            const { data } = await axiosInstance.get(`dashboard/validate-select-lang/${lang}/`);
            setInfo(data);
        } catch (error) {
            error.response && error.response.data.timer ? setError(error.response.data.timer) : setError(error.message);
        };
    };
    const fetchUserSelectLang = async () => {
        try {
            await axiosInstance.post('dashboard/user-select-language/', {
                user_answer_list: [choicesName1, choicesName2, choicesName3, choicesName4, choicesName5],
                language: lang
            });
            setComplete(true);
            setTimeout(() => {
                window.location.reload(false);
            }, 3000);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            error.response && error.response.data.detail ? setTimer(0) : setTimer(0);
        };
    };
    return (
        <SidebarContainer isOpen={modal ? 1 : 0}>
            <ModalWrapper>
                <CloseIconWrap onClick={() => { setModal(false); setClicked(false); setComplete(false); }}>
                    <CloseIcon />
                </CloseIconWrap>
                {!clicked &&
                    <ContentWrap style={{ height: '90%' }}>
                        <InstructionWrap>
                            <p>{instructionsLang.text}</p>
                        </InstructionWrap>
                        <FormButton
                            type='button'
                            style={{ width: '80%', maxWidth: '500px' }}
                            big={0}
                            primary={1}
                            onClick={handleClick}
                        >
                            {instructionsLang.button}
                        </FormButton>
                    </ContentWrap>
                }
                {clicked &&
                    <ContentWrap style={{ paddingBottom: '25px' }}>
                        <div style={{ width: '100%' }}>
                            <TimePriceWrap>
                                <Countdown
                                    date={timer}
                                    renderer={renderer}
                                    onComplete={() => {
                                        setTimer(0);
                                    }}
                                />
                                <PriceWrap>
                                    <h2>{langName}</h2>
                                </PriceWrap>
                            </TimePriceWrap>
                            {info !== undefined && info.a_question.map((item, index) => (
                                <div key={index}>
                                    <QuestionsWrap>
                                        <p>{item.question}</p>
                                    </QuestionsWrap>
                                    <ChoicesContainer>
                                        <ChoicesWrap>
                                            {item.choices_a.map((choice, choiceIndex) => (
                                                <ChoicesImg key={choiceIndex} active={index === 0 && choiceIndex === choicesIndex1 ? 1 : index === 1 && choiceIndex === choicesIndex2 ? 1 : 0}>
                                                    <img src={choice.image_url ? choice.image_url : choice.image} alt={choice.alt} name={choice.alt} onClick={(e) => handleAChoice(e, index, choiceIndex)} />
                                                </ChoicesImg>
                                            ))}
                                        </ChoicesWrap>
                                    </ChoicesContainer>
                                </div>
                            ))}
                            {info !== undefined && info.b_question.map((item, index) => (
                                <div key={index}>
                                    <QuestionsWrap>
                                        <p>{item.question}</p>
                                    </QuestionsWrap>
                                    <ChoicesContainer>
                                        <ChoicesWrap>
                                            {item.choices_bc.map((choice, choiceIndex) => (
                                                <ChoicesTxt key={choiceIndex} active={index === 0 && choiceIndex === choicesIndex3 ? 1 : index === 1 && choiceIndex === choicesIndex4 ? 1 : 0}>
                                                    <span name={choice.choice} onClick={(e) => handleBChoice(e, index, choiceIndex)}>
                                                        {choice.choice}
                                                    </span>
                                                </ChoicesTxt>
                                            ))}
                                        </ChoicesWrap>
                                    </ChoicesContainer>
                                </div>
                            ))}
                            {info !== undefined &&
                                <>
                                    <QuestionsWrap>
                                        <p>{info.c_question.question}</p>
                                    </QuestionsWrap>
                                    <ChoicesContainer>
                                        <ChoicesWrap>
                                            {info.c_question.choices_bc.map((choice, choiceIndex) => (
                                                <ChoicesTxt key={choiceIndex} active={choiceIndex === choicesIndex5 ? 1 : 0}>
                                                    <span name={choice.choice} onClick={(e) => handleCChoice(e, choiceIndex)}>
                                                        {choice.choice}
                                                    </span>
                                                </ChoicesTxt>
                                            ))}
                                        </ChoicesWrap>
                                    </ChoicesContainer>
                                </>
                            }
                        </div>
                        {timer === 0 && !error &&
                            <MsgWrap style={{ width: '80%', maxWidth: '500px' }} primary={0}>
                                <MsgText primary={0}>
                                    Your time is expired. Please try again in 1 hour.
                                </MsgText>
                            </MsgWrap>
                        }
                        {complete &&
                            <MsgWrap style={{ width: '80%', maxWidth: '500px' }} primary={1}>
                                <MsgText primary={1}>
                                    You have selected language successfully. Thank you.
                                </MsgText>
                            </MsgWrap>
                        }
                        {error &&
                            <MsgWrap style={{ width: '80%', maxWidth: '500px' }} primary={0}>
                                <MsgText primary={0}>
                                    {error}
                                </MsgText>
                            </MsgWrap>
                        }
                        {!complete && !error && timer > 0 && choicesName1 && choicesName2 && choicesName3 && choicesName4 && choicesName5 &&
                            <FormButton
                                type='button'
                                style={{ width: '80%', maxWidth: '500px' }}
                                big={0}
                                primary={1}
                                onClick={handleComplete}
                            >
			        {instructionsLang.completeButton}
                            </FormButton>
                        }
                    </ContentWrap>
                }
            </ModalWrapper>
        </SidebarContainer >
    )
}
export default Modal;
