import styled, { css } from 'styled-components/macro';
import { RiKey2Line } from 'react-icons/ri';
import { BsCheckAll } from 'react-icons/bs';
import { Container } from './FinancialInfoElements';

export const InputContainer = styled.form`
    ${Container}
    flex-direction: column;
    max-width: 768px;
    margin: auto;
`
export const StyleIcon = css`
    position: absolute;
    transform: translate(50%, 5%);
    color: rgba(29, 107, 187, 0.8);
    font-size: 1.2rem;
    opacity: 0.70;
`
export const PasswordIcon = styled(RiKey2Line)`
    ${StyleIcon}
`
export const ConfirmPasswordIcon = styled(BsCheckAll)`
    ${StyleIcon}
`