import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { WheelContainerPromo, ImgLogoPromo, Wheel } from './LuckyWheelElements';
import { FormButton } from '../ButtonElements';
import logo from '../../images/logo-footer.png';
import { Circle } from '../Loading';

const WheelComponentPromo = ({
    segments,
    segColors,
    winningSegment,
    primaryColor,
    contrastColor,
    size,
    upDuration,
    downDuration
}) => {
    let history = useHistory();
    let currentSegment = '';
    let isStarted = false;
    const [click, setClick] = useState(false);
    const [reward, setReward] = useState();
    let timerHandle = 0;
    const timerDelay = segments.length;
    let angleCurrent = 0;
    let angleDelta = 0;
    let canvasContext = null;
    let maxSpeed = Math.PI / `${segments.length}`;
    const upTime = segments.length * upDuration;
    const downTime = segments.length * downDuration;
    let spinStart = 0;
    let frames = 0;
    const centerX = 105;
    const centerY = 115;
    useEffect(() => {
	initCanvas();
	draw();
    }, []);
    const initCanvas = () => {
	let canvas = document.getElementById('canvas');
	if (navigator.userAgent.indexOf('MSIE') !== -1) {
	    canvas = document.createElement('canvas');
	    canvas.setAttribute('width', 210)
	    canvas.setAttribute('height', 230)
	    canvas.setAttribute('id', 'canvas');
	    document.getElementById('wheel').appendChild(canvas);
	};
	canvasContext = canvas.getContext('2d');
    };
    const handleClick = () => {
	setClick(true);
	const ctx = canvasContext;
	if (ctx !== undefined && ctx !== null) {
	    spin();
	} else {
	    initCanvas();
	    spin();
	};
    };
    const spin = () => {
	isStarted = true;
	if (timerHandle === 0) {
	    spinStart = new Date().getTime();
	    maxSpeed = Math.PI / segments.length;
	    frames = 0;
	    timerHandle = setInterval(onTimerTick, timerDelay);
	};
    };
    const onTimerTick = () => {
	frames++;
	draw();
	const duration = new Date().getTime() - spinStart;
	let progress = 0;
	let finished = false;
	if (duration < upTime) {
	    progress = duration / upTime;
	    angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
	} else {
	    if (winningSegment) {
		if (currentSegment === winningSegment && frames > segments.length) {
		    progress = duration / upTime;
		    angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
		    progress = 1;
		} else {
		    progress = duration / downTime;
		    angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
		}
	    } else {
		progress = duration / downTime;
		if (progress >= 0.8) {
		    angleDelta = maxSpeed / 1.2 * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
		} else if (progress >= 0.98) {
		    angleDelta = maxSpeed / 2 * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
		} else
		    angleDelta =maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
	    }
	    if (progress >= 1) finished = true;
	}
	angleCurrent += angleDelta;
	while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;
	if (finished) {
	    setReward(currentSegment);
	    onFinished();
	    clearInterval(timerHandle);
	    timerHandle = 0;
	    angleDelta = 0;
	}
    };
    const onFinished = () => {
	setTimeout(() => history.push('/lucky-wheel'), 1500);
    };
    const draw = () => {
	drawWheel();
	drawNeedle();
    };
    const drawSegment = (key, lastAngle, angle) => {
	const ctx = canvasContext;
	const value = segments[key];
	ctx.save();
	ctx.beginPath();
	ctx.moveTo(centerX, centerY);
	ctx.arc(centerX, centerY, size, lastAngle, angle, false);
	ctx.lineTo(centerX, centerY);
	ctx.closePath();
	ctx.fillStyle = segColors[key];
	ctx.fill();
	ctx.stroke();
	ctx.save();
	ctx.translate(centerX, centerY);
	ctx.rotate((lastAngle + angle) / 2);
	ctx.fillStyle = "rgba(1, 219, 187, 1)";
	ctx.font = 'bold 10px sans-serif';
	ctx.fillText(value.substr(0, 21), size / 2 + 22, 0);
	ctx.restore();
    };
    const drawWheel = () => {
	const ctx = canvasContext;
	let lastAngle = angleCurrent;
	const len = segments.length;
	const PI2 = Math.PI * 2;
	ctx.lineWidth = 1;
	ctx.strokeStyle = primaryColor;
	ctx.font = 'bold 20px sans-serif';
	ctx.textBaseline = 'middle';
	ctx.textAlign = 'center';
	for (let i = 1; i <= len; i++) {
	    const angle = PI2 * (i / len) + angleCurrent;
	    drawSegment(i - 1, lastAngle, angle);
	    lastAngle = angle;
	};
	// Draw a center circle
	ctx.beginPath();
	ctx.arc(centerX, centerY, 33, 0, PI2, false);
	ctx.closePath();
	ctx.fillStyle = '#111121';
	ctx.fill();
	ctx.fillStyle = primaryColor;
	// Draw outer circle
	ctx.beginPath();
	ctx.arc(centerX, centerY, size, 0, PI2, false);
	ctx.closePath();
	ctx.lineWidth = 2;
	ctx.strokeStyle = primaryColor;
	ctx.stroke();
    };
    const drawNeedle = () => {
	const ctx = canvasContext;
	ctx.lineWidth = 1;
	ctx.strokeStyle = contrastColor;
	ctx.fileStyle = contrastColor;
	ctx.beginPath();
	ctx.moveTo(centerX + 20, centerY - 105);
	ctx.lineTo(centerX - 20, centerY - 105);
	ctx.lineTo(centerX, centerY - 80);
	ctx.closePath();
	ctx.fill();
	const change = angleCurrent + Math.PI / 2;
	let i = segments.length - Math.floor((change / (Math.PI * 2)) * segments.length) - 1;
	if (i < 0) i = i + segments.length;
	ctx.textAlign = 'center';
	ctx.textBaseline = 'middle';
	ctx.fillStyle = primaryColor;
	currentSegment = segments[i];
	isStarted && ctx.fillText(currentSegment, centerX + 10, centerY + size + 50);
    };
    return (
	<WheelContainerPromo id='wheel'>
	    <FormButton primary={1} big={0} style={{ margin: 'auto', fontSize: '0.9rem' }} onClick={!click ? handleClick : null}>
	        {click && reward === undefined ?
		    <div style={{ width: '125px' }}>
			<Circle style={{ width: '18px', height: '18px', margin: 'auto' }} />
		    </div>
		    : click && reward !== undefined ?
			`You win ${reward}.` :
			'Get Your Daily Bonus'
		}
	    </FormButton>
	    <Wheel
	        id='canvas'
	        width={210}
	        height={230}
	    />
	    <ImgLogoPromo src={logo} alt={'IceFaucet'} />
	</WheelContainerPromo>
    )
}
export default WheelComponentPromo;

