import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const Container = styled.section`
    width: 100%;
    min-height: 100vh;
    background: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const Wrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 55px;
    margin: auto;

    @media screen and ${device.lg} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
    }

    @media screen and ${device.sm} {
        grid-template-columns: 1fr;
    }
`
export const NewEarningWrap = styled.div`
    width: 90%;
    padding: 10px;
    margin-top: 60px;
    background: linear-gradient(135deg, rgba(29, 107, 187, 0.85), rgba(39, 117, 85, 0.85));
    border-radius: 5px;
    border: 1px solid rgba(1, 219, 187, 0.5);
    box-shadow: 0 0 10px rgba(1, 219, 187, 1);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        background: linear-gradient(135deg, rgba(29, 107, 187, 1), rgba(39, 117, 85, 1));
        transition: all 0.2s ease-in-out;
    }

    h4 {
        color: #01DBBB;
        font-size: clamp(0.85rem, 2vw, 1.05rem);
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 2px;
    }
`