import styled from 'styled-components';

export const ActContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    padding: 25px;
`
export const ActWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 0 20px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 10px;
    padding: 10px;
`
