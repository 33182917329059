import styled, { css, keyframes } from 'styled-components/macro';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md';
import device from '../../constants/breakPoints';

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 580px;
    position: relative;
    background: linear-gradient(135deg, #010606 5% , rgba(29, 107, 187, 0.65) 55%, #010606 100%);

    :before {
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(135deg, #010606 5% , rgba(29, 107, 187, 0.65) 55%, #010606 100%);
	z-index: 2;
    }
`
export const imgClip = keyframes`
    0% { clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%); };
    100% { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); };
`
export const HeroBg = styled.div`
    position: absolute;
    color: #fff;
    font-size: 1.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media (prefers-reduced-motion: no-preference) {
	animation: ${imgClip} 1.5s cubic-bezier(0.6, 0.45, 0.20, 0.30) 0s backwards;
    }
    img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
    }
`
export const HeroContent = styled.div`
    z-index: 3;
    width: 100%;
    max-width: 60vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    @media screen and ${device.md} {
	max-width: 75vw;
    }
    @media screen and ${device.sm} {
	max-width: 85vw;
    }
    @media screen and ${device.tn} {
	max-width: 98vw;
    }
`
export const HeroHeadingWrap = styled.div`
    position: absolute;
    bottom: 75px;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const mainFadeIn = keyframes`
    0% { opacity: 0; }
    25% { opacity: 1; }
    85% { opacity: 1; }
`
export const mainFadeIn2 = keyframes`
    0% { opacity: 0; }
    5% { opacity: 1; }
    95% { opacity: 1; }
`
export const mainFadeInEnd = keyframes`
    0% { opacity: 0; }
    25% { opacity: 1; }
    100% { opacity: 1; }
`
export const HeadItem = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    &:nth-child(1) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${mainFadeIn} 5s ease-out 2s;
	}
    }
    &:nth-child(2) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${mainFadeIn2} 20s ease-out 7s;
	}
    }
    &:nth-child(3) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${mainFadeIn2} 5s ease-out 27s forwards;
	}
    }
    &:nth-child(4) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${mainFadeInEnd} 5s ease-out 32s forwards;
	}
    }
`
export const mainBlock = keyframes`
    0% { width: 0; left: 0; }
    50% { width: 100%; left: 0; }
    100% { width: 0; left: 100%; }
`
export const BoxAnimation = styled.span`
    position: absolute;
    text-align: center;
    background: rgba(29, 107, 187, 1);
    height: 80px;
    width: 0;
    @media (prefers-reduced-motion: no-preference) {
	animation: ${mainBlock} 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) 1.8s forwards, ${mainBlock} 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) 6.8s forwards, ${mainBlock} 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) 26.8s forwards, ${mainBlock} 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) 31.8s forwards;
    }
`
export const HeroHeading = styled.h1`
    text-align: center;
    color: ${({ headingBlue }) => (headingBlue ? '#1D6BBB' : '#277555')};
    font-size: clamp(1.7rem, 3.5vw, 2.2rem);
    text-shadow: 0 1px 3px #000;
`
export const HeroDescWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 150px;
    overflow: hidden;
    max-width: 65vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and ${device.md} {
	max-width: 80vw;
    }
    @media screen and ${device.sm} {
	max-width: 85vw;
    }
    @media screen and ${device.tn} {
	max-width: 98vw;
    }
`
export const descAnimation = keyframes`
    0% { text-shadow:0 0 3em rgba(1, 219, 187, 0.7); opacity:0; left:-1em; }
    25% { text-shadow:0 0 0 rgba(1, 219, 187, 0.7); opacity:1; left:0; }
    85% { text-shadow:0 0 0 rgba(1, 219, 187, 0.7); opacity:1; left:0; }
`
export const descAnimationEnd = keyframes`
    0% { text-shadow:0 0 3em rgba(1, 219, 187, 0.7); opacity:0; left:-1em; }
    25%, 100% { text-shadow:0 0 0 rgba(1, 219, 187, 0.7); opacity:1; left:0; }
`
export const DescItem = styled.div`
    position: absolute;
    width: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.3);
    &:nth-child(1) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${descAnimation} 4.8s ease-in-out 2.2s;
	}
    }
    &:nth-child(2) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${descAnimation} 4.8s ease-in-out 7.2s;
	}
    }
    &:nth-child(3) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${descAnimation} 4.8s ease-in-out 12.2s;
	}
    }
    &:nth-child(4) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${descAnimation} 4.8s ease-in-out 17s;
	}
    }
    &:nth-child(5) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${descAnimation} 4.8s ease-in-out 21.8s;
	}
    }
    &:nth-child(6) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${descAnimation} 4.8s ease-in-out 27s forwards;
	}
    }
    &:nth-child(7) {
	@media (prefers-reduced-motion: no-preference) {
	    animation: ${descAnimationEnd} 5s ease-in-out 32.2s forwards;
	}
    }
`
export const HeroDesc = styled.p`
    color: rgba(1, 219, 187, 0.7);
    font-size: clamp(1.1rem, 3.5vw, 1.6rem);
    font-weight: 700;
    text-align: center;
    line-height: 2.5rem;
    letter-spacing: 1.3px;
    word-spacing: 1.7px;
`
export const buttonAnimation = keyframes`
    0% { opacity:0; margin-left:-.5em; }
    100% { opacity:1; margin-left:0; }
`
export const HeroBtnWrapper = styled.button`
    position: absolute;
    top: 95px;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    opacity: 0;
    @media (prefers-reduced-motion: no-preference) {
	animation: ${buttonAnimation} 1s ease-in-out 2.5s forwards;
    }
`
export const ArrowIcon = css`
    margin-left: 8px;
    font-size: 20px;`

export const ArrowFrorward = styled(MdArrowForward)`
    ${ArrowIcon}
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
    ${ArrowIcon}
`
