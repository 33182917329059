export const langData = [
    {
        name: 'english',
        value: 'en'
    },
    {
        name: 'spanish',
        value: 'es'
    },
    {

        name: 'russian',
        value: 'ru'
    },
    {

        name: 'portuquese',
        value: 'pt'
    },
    {

        name: 'french',
        value: 'fr'
    },
    {

        name: 'germany',
        value: 'de'
    },
    {

        name: 'Italian',
        value: 'it'
    }
]