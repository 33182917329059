import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { SidebarContainer, SidebarWrapper } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { EditAdsForm, TitleWrap, ContentWrap, EditCard, EditInputWrap, EditLinkIcon, EditTextIcon, EditInput, InputTitleWrap, InputContentWrap, InputLangWrap, InputTwoItemsWrap, InputThreeItemsWrap, InputButton } from './EditInfoModalElements';
import { langData } from '../../data/langData';
import { ptcDeviceData, ptcGenderData, ptcRepeatData } from '../../data/ptcFiltersData';
import InteractiveMapEdit from './InteractiveMapEdit';
import { MsgWrap, MsgText } from '../MessageElements';
import { TinyFormButton } from '../ButtonElements';
import { ValidateEdit } from './ValidateEdit';
import { countriesData } from '../../data/countriesData';
import { useHistory } from 'react-router-dom';

const EditInfoModal = ({ editClick, setEditClick, id, CPC, url, language, country, countryName, device, gender, repeat, title, description }) => {
    const history = useHistory();
    const [updateError, setUpdateError] = useState('');
    const [values, setValues] = useState(Object.freeze({
        language: language,
        repeat: repeat,
        title: title,
        description: description,
    }));
    const [errors, setErrors] = useState({
        title: '',
        description: ''
    });
    const [alpha2, setAlpha2] = useState(country);
    const [devices, setDevices] = useState(device);
    const [genders, setGenders] = useState(gender);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [complete, setComplete] = useState(false);
    const [langChanged, setLangChanged] = useState(false);
    const [countryChanged, setCountryChanged] = useState(false);
    const [deviceChanged, setDeviceChanged] = useState(false);
    const [genderChanged, setGenderChanged] = useState(false);
    const [repeatChanged, setRepeatChanged] = useState(false);
    const [titleChanged, setTitleChanged] = useState(false);
    const [descriptionChanged, setDescriptionChanged] = useState(false);
    const geoData = countriesData;
    const geoObjects = geoData.objects.ne_110m_admin_0_countries.geometries;
    const geoAllCountriesIso = geoObjects.map((geoAllCountry) => geoAllCountry.properties.ISO_A2);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setIsSubmitting(false);
        setErrors({
            title: '',
            description: ''
        });
        language !== undefined && name === 'language' && value !== language && setLangChanged(true);
        language !== undefined && name === 'language' && value === language && setLangChanged(false);
        name === 'repeat' && parseInt(value) !== repeat && setRepeatChanged(true);
        name === 'repeat' && parseInt(value) === repeat && setRepeatChanged(false);
        name === 'title' && value !== title && setTitleChanged(true);
        name === 'title' && value === title && setTitleChanged(false);
        name === 'description' && value !== description && setDescriptionChanged(true);
        name === 'description' && value === description && setDescriptionChanged(false);
    };
    const handleDeviceClick = (name) => {
        if (!devices.includes(name) && devices.length <= 2) {
            setDevices(devices => [...devices, name]);
        } else if (devices.includes(name)) {
            setDevices(devices.filter(removeDevice => removeDevice !== name));
        };
        setIsSubmitting(false);
    };

    const handleGenderClick = (gender) => {
        if (!genders.includes(gender) && genders.length <= 2) {
            setGenders(genders => [...genders, gender]);
        } else if (genders.includes(gender)) {
            setGenders(genders.filter(removGenders => removGenders !== gender));
        };
        setIsSubmitting(false);
    };
    const fetchEditViewAdsInfo = async () => {
        try {
            await axiosInstance.put('ptc/control-ads/edit/', {
                id: id,
                target_language: values.language,
                target_country: alpha2 !== undefined ? alpha2 : geoAllCountriesIso,
                target_device: devices,
                target_gender: genders,
                repeat: parseInt(values.repeat),
                title: values.title,
                description: values.description
            });
            setComplete(true);
        } catch (error) {
            error.response && error.response.data.detail ? setUpdateError(error.response.data.detail) : setUpdateError(error.message);
        };
        setIsSubmitting(false);
    };

    const areEqual = (array1, array2) => {
        if (array1 !== undefined && array2 !== undefined) {
            if (array1.length === array2.length) {
                return array1.every(element => {
                    if (array2.includes(element)) {
                        return true;
                    }

                    return false;
                });
            };
            return false;
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateEdit(values));
        setIsSubmitting(true);
        JSON.stringify(alpha2) !== JSON.stringify(country) && setCountryChanged(true);
        areEqual(alpha2, country) && setCountryChanged(false) && setIsSubmitting(false);
        JSON.stringify(devices) !== JSON.stringify(device) && setDeviceChanged(true);
        areEqual(devices, device) && setDeviceChanged(false) && setIsSubmitting(false);
        JSON.stringify(genders) !== JSON.stringify(gender) && setGenderChanged(true);
        areEqual(genders, gender) && setGenderChanged(false) && setIsSubmitting(false);
    };

    useEffect(() => {
        if (isSubmitting
            && !complete
            && Object.keys(errors).length === 0
            && (alpha2 !== undefined ? alpha2.length > 0 : true)
            && devices.length > 0
            && genders.length > 0
            && (langChanged || countryChanged || deviceChanged || genderChanged || repeatChanged || titleChanged || descriptionChanged)
        ) {
            fetchEditViewAdsInfo();
            setTimeout(() => history.go(0), 500);
        };
    });
    return (
        <SidebarContainer isOpen={editClick ? 1 : 0}>
            <SidebarWrapper>
                <CloseIconWrap onClick={() => setEditClick(false)}>
                    <CloseIcon />
                </CloseIconWrap>
                <EditAdsForm action='#' onSubmit={handleSubmit}>
                    <TitleWrap>
                        <h3>CPC: {CPC}</h3>
                    </TitleWrap>
                    <ContentWrap>
                        <EditCard>
                            <EditInputWrap>
                                <EditLinkIcon />
                                <EditInput type='url' value={url} disabled />
                            </EditInputWrap>
                        </EditCard>
                        {language &&
                            <EditCard>
                                <InputTitleWrap>
                                    <h4>target language</h4>
                                </InputTitleWrap>
                                <InputContentWrap>
                                    <InputLangWrap>
                                        {langData.map((lang, index) => (
                                            <InputButton type='button' key={index} name='language' onClick={(e) => handleChange(e)} value={lang.value} itemActive={lang.value === values.language ? 1 : 0}>
                                                {lang.name}
                                            </InputButton>
                                        ))}
                                    </InputLangWrap>
                                </InputContentWrap>
                            </EditCard>
                        }
                        {country &&
                            <EditCard>
                                <InputTitleWrap>
                                    <h4>target country</h4>
                                </InputTitleWrap>
                                <InputContentWrap>
                                    <InteractiveMapEdit alpha2={alpha2} setAlpha2={setAlpha2} countryName={countryName} setIsSubmitting={setIsSubmitting} />
                                </InputContentWrap>
                                {alpha2 && alpha2.length === 0 &&
                                    <MsgWrap primary={0}>
                                        <MsgText primary={0}>
                                            You should select one country at least.
                                        </MsgText>
                                    </MsgWrap>
                                }
                            </EditCard>
                        }
                        <EditCard>
                            <InputTitleWrap>
                                <h4>target device</h4>
                            </InputTitleWrap>
                            <InputContentWrap>
                                <InputTwoItemsWrap>
                                    {ptcDeviceData.map((item, index) => (
                                        <InputButton type='button' key={index} onClick={() => handleDeviceClick(item.name)} itemActive={devices.includes(item.name) ? 1 : 0}>
                                            {item.device}
                                        </InputButton>
                                    ))}
                                </InputTwoItemsWrap>
                            </InputContentWrap>
                            {devices.length === 0 &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        You should select one item at least.
                                    </MsgText>
                                </MsgWrap>
                            }
                        </EditCard>
                        <EditCard>
                            <InputTitleWrap>
                                <h4>target gender</h4>
                            </InputTitleWrap>
                            <InputContentWrap>
                                <InputTwoItemsWrap>
                                    {ptcGenderData.map((item, index) => (
                                        <InputButton type='button' key={index} onClick={() => handleGenderClick(item.name)} itemActive={genders.includes(item.name) ? 1 : 0}>
                                            {item.gender}
                                        </InputButton>
                                    ))}
                                </InputTwoItemsWrap>
                            </InputContentWrap>
                            {genders.length === 0 &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        You should select one item at least.
                                    </MsgText>
                                </MsgWrap>
                            }
                        </EditCard>
                        <EditCard>
                            <InputTitleWrap>
                                <h4>repeat ads</h4>
                            </InputTitleWrap>
                            <InputContentWrap>
                                <InputThreeItemsWrap>
                                    {ptcRepeatData.map((item, index) => (
                                        <InputButton type='button' key={index} name='repeat' onClick={(e) => handleChange(e)} value={item.name} itemActive={parseInt(item.name) === parseInt(values.repeat) ? 1 : 0}>
                                            {item.duration}
                                        </InputButton>
                                    ))}
                                </InputThreeItemsWrap>
                            </InputContentWrap>
                        </EditCard>
                        <EditCard>
                            <InputTitleWrap>
                                <h4>edit title and description</h4>
                            </InputTitleWrap>
                            <EditInputWrap style={{ paddingBottom: '0' }} >
                                <EditTextIcon />
                                <EditInput type='text' name='title' onChange={handleChange} value={values.title} />
                            </EditInputWrap>
                            {errors.title &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        {errors.title}
                                    </MsgText>
                                </MsgWrap>
                            }
                            <EditInputWrap>
                                <EditTextIcon />
                                <EditInput type='text' name='description' onChange={handleChange} value={values.description} />
                            </EditInputWrap>
                            {errors.description &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        {errors.description}
                                    </MsgText>
                                </MsgWrap>
                            }
                        </EditCard>
                        {!complete && !updateError &&
                            <TinyFormButton type='submit' primary={1} big={1} style={{ marginBottom: '15px', width: '70%' }}>
                                Edit
                            </TinyFormButton>
                        }
                        {complete &&
                            <MsgWrap primary={1}>
                                <MsgText primary={1}>
                                    Your advertisement has been successfully edited.
                                </MsgText>
                            </MsgWrap>
                        }
                        {updateError &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {updateError}
                                </MsgText>
                            </MsgWrap>
                        }

                    </ContentWrap>
                </EditAdsForm>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default EditInfoModal;
