import React from 'react';
import Deposit from '../components/Deposit';
import { depositInfo } from '../data/depositData';

const DepositPage = () => {
    return (
        <>
            <Deposit info={depositInfo} />
        </>
    )
}
export default DepositPage;