import styled, { css } from 'styled-components/macro';
import { FiEdit, FiStopCircle, FiPlayCircle } from 'react-icons/fi';
import device from '../../constants/breakPoints';

export const CardContainer = styled.div`
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-top: 30px;
    @media screen and ${device.md} {
	grid-template-columns : 1fr 1fr;
    }
    @media screen and ${device.sm} {
	grid-template-columns : 1fr;
    }
`
export const Card = styled.div`
    width: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
`
export const TitrWrap = styled.div`
    width: 100%;
    text-align: center;
    background: ${({ color }) => (color === 'P' ? 'rgba(29, 107, 187, 0.4)' : color === 'C' ? 'rgba(29, 107, 187, 0.7)' : 'rgba(195, 75, 75, 0.7)')};
    border: 1px solid ${({ color }) => (color === 'P' ? 'rgba(29, 107, 187, 0.3)' : color === 'C' ? 'rgba(39, 117, 85, 0.3)' : 'rgba(195, 75, 75, 0.3)')};
    box-shadow: 0 0 5px ${({ color }) => (color === 'P' ? 'rgba(29, 107, 187, 0.7)' : color === 'C' ? 'rgba(39, 117, 85, 0.7)' : 'rgba(195, 75, 75, 0.7)')};
    margin-bottom: 7px;

    h3{
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: clamp(0.7rem, 3.5vw, 0.8rem);
	font-weight: 400;
	letter-spacing: 1.2px;
	word-spacing: 1.7px;
	text-transform: uppercase;
	padding: 10px 3px;
    }
`
export const ContentWrap = styled.div`
    width: 100%;
    background: ${({ color }) => (color === 'P' ? 'rgba(29, 107, 187, 0.4)' : color === 'C' ? 'rgba(29, 107, 187, 0.7)' : 'rgba(195, 75, 75, 0.9)')};
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);
    margin-bottom: 7px;
`
export const ItemWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    span {
	color: #fff;
	text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
	font-size: 0.6rem;
	letter-spacing: 0.8px;
	word-spacing: 1.1px;
	text-transform: uppercase;
    }
`
export const IconWrap = styled.div`
    width: 50%;
    background: rgba(29, 107, 187, 0.5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 0 3px 0;
    transition: 0.3s ease-in-out;
    &:hover {
	background: rgba(29, 107, 187, 1);
	transition: 0.3s ease-in-out;
    }
    &:nth-child(even) {
	background: rgba(39, 117, 85, 0.5);
	&:hover {
	    background: rgba(39, 117, 85, 1);
	    transition: 0.3s ease-in-out;
	}
    }
`
export const ButtonIcons = css`
    color: rgba(1, 219, 187, 0.85);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: 1.4rem;
`
export const EditIcon = styled(FiEdit)`
    ${ButtonIcons}
`
export const StopIcon = styled(FiStopCircle)`
    ${ButtonIcons}
`
export const PlayIcon = styled(FiPlayCircle)`
    ${ButtonIcons}
`
