import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { SidebarWrapper } from '../Sidebar/SidebarElements';

export const ModalWrapper = styled(SidebarWrapper)`
    overflow-y: auto;
        
    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(256, 256, 256, 0.2);
        box-shadow: inset 0 0 4px rgba(29, 107, 187, 0.7);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(29, 107, 187, 0.7);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(29, 107, 187, 0.8);
    }
`
export const CircleInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 25px;
`
export const CircleInfoWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 25px;

    @media screen and ${device.lg} {
        grid-template-columns : 1fr 1fr;
        grid-row-gap: 25px;
    }
    @media screen and ${device.md} {
        grid-template-columns : 1fr;
        grid-row-gap: 25px;
    }
`
export const CircleInfoCardWrap = styled.div`
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3px;
    background: rgba(29, 107, 187, 0.4);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);
`
export const TitleItemWrap = styled.div`
    width: 100%;
    background: rgba(29, 107, 187, 0.7);
    span {
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(0.85rem, 3.5vw, 0.99rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
        padding: 5px;
    }
`
export const UniqueWrap = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(1.3rem, 3.5vw, 1.5rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }
`
export const TargetContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 25px;
`
export const TargetWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;

    @media screen and ${device.lg} {
        grid-template-columns : 1fr;
        grid-row-gap: 25px;
    }
`
export const TargetCardWrap = styled.div`
    width: 343px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3px;
    background: rgba(29, 107, 187, 0.4);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);

    @media screen and ${device.sm} {
        width: 285px;
    }
    @media screen and ${device.tn} {
        width: 250px;
    }
`