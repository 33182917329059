import styled from 'styled-components';

export const ItemLink = styled.a`
    text-decoration: none;
    color: #fff;
    font-size: clamp(0.55rem, 1.8vw, 0.65rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    background: ${({ active }) => (active ? '#01DBBB' : '#1D6BBB')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &:hover {
        background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#277555')};
        transition: 0.3s ease-in-out;
    }
`