export const ptcSocialData = [
    {
        name: 'facebook',
        img: require('../images/facebook.svg').default,
    },
    {
        name: 'whatsapp',
        img: require('../images/whatsapp.svg').default,
    },
    {
        name: 'instagram',
        img: require('../images/instagram.svg').default,
    },
    {
        name: 'youtube',
        img: require('../images/youtube.svg').default,
    },
    {
        name: 'twitter',
        img: require('../images/twitter.svg').default,
    },
    {
        name: 'tiktok',
        img: require('../images/tiktok.png').default,
    },
    {
        name: 'telegram',
        img: require('../images/telegram.png').default,
    },
    {
        name: 'pinterest',
        img: require('../images/pinterest.svg').default,
    },
    {
        name: 'reddit',
        img: require('../images/reddit.svg').default,
    },
    {
        name: 'linkedin',
        img: require('../images/linkedin.svg').default,
    },
];