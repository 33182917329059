import styled from 'styled-components';
import device from '../constants/breakPoints';

export const MsgWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(39, 117, 85, 0.45)' : 'rgba(255, 0, 0, 0.35)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(39, 117, 85, 0.6)' : 'rgba(255, 0, 0, 0.6)')};
    box-shadow: 0 0 8px ${({ primary }) => (primary ? 'rgba(39, 117, 85, 0.7)' : 'rgba(255, 0, 0, 0.7)')};
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 0;

    @media screen and ${device.tn} {
        padding: 10px 5px;
    }
`
export const MsgText = styled.span`
    color: ${({ primary }) => (primary ? 'rgba(1, 219, 187, 1)' : 'rgb(220, 60, 60)')};
    text-shadow: ${({ primary }) => (primary ? ' 0 0 3px rgba(0, 0, 0, 0.5)' : '0 0 0 #000000')};
    font-size: clamp(0.85rem, 2.4vw, 1.15rem);
`