export const adsSetUpData = [
    {
        tool: 'ptc',
        path: '/setup-ads/ptc'
    },
    {
        tool: 'surf',
        path: '/setup-ads/surf'
    },
    {
        tool: 'video',
        path: '/setup-ads/video'
    },
    {
        tool: 'mining',
        path: '/setup-ads/mining'
    },
    {
        tool: 'banner',
        path: '/setup-ads/banner'
    },
    {
        tool: 'sponsor link',
        path: '/setup-ads/sponsor-link'
    }
];