import React, { useState, useEffect } from 'react';
import { MsgText, MsgWrap } from '../MessageElements';
import { PlayContainer, Iframe, Bar, MessageContainer, MsgItem } from '../PtcPlay/PtcPlaylements';
import { TinyFormButton } from '../ButtonElements';

const PlaySetUpPtc = () => {
    const [width, setWidth] = useState(100);
    const [stop, setStop] = useState(false);
    const [showMsg, setShowMsg] = useState('');
    const duration = parseFloat(localStorage.getItem('duration'));
    const adsDuration = duration === 2.5 ? 40 : duration === 2.0 ? 30 : duration === 1.5 ? 20 : 10;
    let timeStep = 10 * adsDuration;
    const url = localStorage.getItem('url');
    const MousEnterHandler = () => {
        setStop(false);
    };
    const completeTimer = () => {
        setShowMsg(
            'Please press the following button to validate your ads. If your ads do not show in the iframe, use "surfing ads" instead.'
        );
    };
    const completeHandler = async () => {
        window.open(url, "_self");
    };
    useEffect(() => {
        if (width === 0) {
            completeTimer();
            return;
        };
        if (!stop) {
            const timer = setTimeout(() => {
                setWidth(width - 1);
            }, timeStep);
            return () => clearTimeout(timer);
        };
    });
    return (
        <PlayContainer>
            {showMsg &&
                <MessageContainer>
                    <MsgItem>
                        <MsgWrap primary={1}>
                            <MsgText primary={1}>
                                {showMsg}
                            </MsgText>
                        </MsgWrap>
                        <TinyFormButton type='button' primary={1} big={1} onClick={completeHandler}>
                            complete
                        </TinyFormButton>
                    </MsgItem>
                </MessageContainer>
            }
            {width > 0 && <Bar width={width + '%'} stop={stop ? 1 : 0} />}
            <Iframe src={url} title={url} onMouseLeave={() => setStop(true)} onMouseEnter={MousEnterHandler}>
            </Iframe>
        </PlayContainer>
    )
}
export default PlaySetUpPtc;
