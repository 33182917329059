import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { CountTotalContainer, CountTotalWrap, DescWrap } from '../History/DepositTabElements';
import { Container, Wrap, TitleWrap, CodeWrap, NumberFormatCode, ProgressBarWrap, ProgressBar, DescriptionWrap } from './RedeemElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { HelpIcon, HelpWrap } from '../HelpElements';

const Redeem = () => {
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const [codeError, setCodeError] = useState('');
    const [complete, setComplete] = useState(false);
    const fetchRedeemInfo = async () => {
        try {
            const { data } = await axiosInstance.get('redeem/info/');
            setInfo(data);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const handleChangeCode = (e) => {
        let code = e.target.value.replace(/ /g, '').replace('_', '');
        setCodeError('');
        if (code.length === 6) {
            fetchCheckCode(code);
        };
    };
    const fetchCheckCode = async (code) => {
        try {
            await axiosInstance.post('redeem/match/', {
                code: parseInt(code)
            });
            setComplete(true);
        } catch (error) {
            error.response && error.response.data.detail ? setCodeError(error.response.data.detail) : setCodeError(error.message);
        };
    };
    useEffect(() => {
        if (info === undefined && apiError.length === 0) {
            fetchRedeemInfo();
        };
    });
    return (
        <Container>
            <Wrap>
                <CountTotalContainer>
                    <CountTotalWrap style={{ background: 'rgba(29, 107, 187, 0.4)' }}>
                        <TitleWrap>
                            <h2>total</h2>
                        </TitleWrap>
                        <DescWrap>
                            <h2>{info !== undefined && info.count}</h2>
                        </DescWrap>
                    </CountTotalWrap>
                    <CountTotalWrap style={{ background: 'rgba(29, 107, 187, 0.4)' }}>
                        <TitleWrap>
                            <h2>paid</h2>
                        </TitleWrap>
                        <DescWrap>
                            <h2>{info !== undefined && info.paid} USDT</h2>
                        </DescWrap>
                    </CountTotalWrap>
                </CountTotalContainer>
                <CountTotalWrap style={{ background: 'rgba(29, 107, 187, 0.4)' }}>
                    <TitleWrap>
                        <h2>active gift code</h2>
                        <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                    </TitleWrap>
                    <HelpWrap show={showHelp ? 1 : 0}>
                        <p>
                            If there are active codes, you can see their progressive bar here; otherwise, there are currently no active codes.
                        </p>
                    </HelpWrap>
                    {info !== undefined && info.info !== undefined && info.info.map((bar, index) => (
                        <ProgressBarWrap key={index}>
                            <ProgressBar width={info !== undefined ? (String(bar.used / bar.number * 100) + '%') : 0} primary={index % 2 === 0 ? 1 : 0}>
                                {info !== undefined && (bar.used / bar.number * 100) >= 10 &&
                                    <span>{(bar.used / bar.number * 100).toFixed(1)}%</span>
                                }
                            </ProgressBar>
                        </ProgressBarWrap>
                    ))}
                    {info !== undefined && info.info === undefined &&
                        <MsgWrap primary={0} style={{ width: '80%', margin: '25px auto' }}>
                            <MsgText primary={0}>
                                Currently, there is no active redeem code.
                            </MsgText>
                        </MsgWrap>
                    }
                </CountTotalWrap>
                {info !== undefined && info.info !== undefined && !codeError && !complete &&
                    <>
                        <DescriptionWrap>
                            <p>Enter your redeem code here to get your reward quickly. To access the redeem codes, you must follow social media and be an active user.</p>
                        </DescriptionWrap>
                        <CodeWrap>
                            <NumberFormatCode
                                format={"#  #  #  #  #  #"}
                                allowEmptyFormatting mask="_ "
                                onChange={handleChangeCode}
                            />
                        </CodeWrap >
                    </>
                }
                {codeError && !complete &&
                    <MsgWrap style={{ width: '80%', marginTop: '50px' }} primary={0}>
                        <MsgText primary={0}>
                            {codeError}
                        </MsgText>
                    </MsgWrap>
                }
                {complete &&
                    <MsgWrap style={{ width: '80%', marginTop: '50px' }} primary={1}>
                        <MsgText primary={1}>
                            You got your reward successfully.
                        </MsgText>
                    </MsgWrap>
                }
            </Wrap>
        </Container>
    )
}
export default Redeem;
