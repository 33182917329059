export const ValidateEmail = (values) => {
    let errors = {};
    if (!values.email) {
        errors.email = 'The email is required.'
    } else if (!(/^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))) {
        errors.email = 'The email address is invalid.'
    };

    return errors
};

export const ValidateInfo = (values) => {
    let errors = {};

    if (!values.email) {
        errors.email = 'The email is required.'
    } else if (!(/^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))) {
        errors.email = 'The email address is invalid.'
    };

    if (!values.gender) {
        errors.gender = 'The gender is required.'
    };

    if (!values.password) {
        errors.password = 'The password is required.'
    } else if (values.password.length < 8) {
        errors.password = 'Your password is too short.'
    } else if (values.password.length > 32) {
        errors.password = 'Your password is too long.'
    } else if (/^[0-9]+$/i.test(values.password)) {
        errors.password = 'Your password should not be entirely numeric.'
    } else if (/^[A-Z]+$/i.test(values.password)) {
        errors.password = 'Your password should not be entirely alphabetic.'
    } else if (/^[-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one alphabetic and numeric character.'
    } else if (/^[A-Z0-9]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one character in {@, #, $, *, _, -, .}.'
    } else if (/^[A-Z-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one numeric character.'
    } else if (/^[0-9-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one alphabetic character.'
    } else if (!(/^[A-Z0-9-_.*$#@]+$/i.test(values.password))) {
        errors.password = 'The password is invalid.'
    };

    if (!values.password2) {
        errors.password2 = 'The password is required.'
    } else if (values.password2 !== values.password) {
        errors.password2 = 'Passwords do not match.'
    };

    return errors
};
export const ValidateInfoLog = (values) => {
    let errors = {};

    if (!values.email) {
        errors.email = 'The email is required.'
    } else if (!/^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'The email address is invalid.'
    };
    if (!values.password) {
        errors.password = 'The password is required.'
    } else if (values.password.length < 8) {
        errors.password = 'Your password is too short.'
    } else if (values.password.length > 32) {
        errors.password = 'Your password is too long.'
    } else if (/^[0-9]+$/i.test(values.password)) {
        errors.password = 'Your password should not be entirely numeric.'
    } else if (/^[A-Z]+$/i.test(values.password)) {
        errors.password = 'Your password should not be entirely alphabetic.'
    } else if (/^[-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one alphabetic and numeric character.'
    } else if (/^[A-Z0-9]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one character in {@, #, $, *, _, -, .}.'
    } else if (/^[A-Z-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one numeric character.'
    } else if (/^[0-9-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one alphabetic character.'
    } else if (!(/^[A-Z0-9-_.*$#@]+$/i.test(values.password))) {
        errors.password = 'The password is invalid.'
    };
    return errors
};

export const ValidateInfoReset = (value) => {
    let errors = {};

    if (!value.code) {
        errors.code = 'The code is required.'
    } else if (value.code.length < 6) {
        errors.code = 'The code should be exactly 6 characters.'
    };
    return errors
};

export const ValidateInfoResetForm = (values) => {
    let errors = {};

    if (!values.password) {
        errors.password = 'The password is required.'
    } else if (values.password.length < 8) {
        errors.password = 'Your password is too short.'
    } else if (values.password.length > 32) {
        errors.password = 'Your password is too long.'
    } else if (/^[0-9]+$/i.test(values.password)) {
        errors.password = 'Your password should not be entirely numeric.'
    } else if (/^[A-Z]+$/i.test(values.password)) {
        errors.password = 'Your password should not be entirely alphabetic.'
    } else if (/^[-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one alphabetic and numeric character.'
    } else if (/^[A-Z0-9]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one character in {@, #, $, *, _, -, .}.'
    } else if (/^[A-Z-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one numeric character.'
    } else if (/^[0-9-_.*$#@]+$/i.test(values.password)) {
        errors.password = 'Your password should have at least one alphabetic character.'
    } else if (!(/^[A-Z0-9-_.*$#@]+$/i.test(values.password))) {
        errors.password = 'The password is invalid.'
    };

    if (!values.password2) {
        errors.password2 = 'The password is required.'
    } else if (values.password2 !== values.password) {
        errors.password2 = 'Passwords do not match.'
    };

    return errors
}