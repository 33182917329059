import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { HiOutlineMail } from 'react-icons/hi';
import { FaRegUser } from 'react-icons/fa';

export const Form = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 10%;

    @media screen and ${device.sm} {
        grid-template-columns: 1fr;
        row-gap: 25px;
    }
`
export const ImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`
export const ImgWrap = styled.div`
    width: 140px;
    height: 140px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(39, 117, 85, 0.5);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.7);
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;
    margin-bottom: 10px;
`
export const PersonalityImg = styled.img`
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 50%;
`
export const InfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -20px;

    @media screen and ${device.sm} {
        margin-left: 0;
    }
`
export const InputWrap = styled.div`
    position: relative;
    display: flex;  
    align-items: center;
    width: 100%;
    margin: 10px 0;

    &:nth-child(1) {
        margin: 0;
    }
`
export const Input = styled.input`
    padding: 5px 10px 6px 40px;
    border: none;
    border-radius: 20px;
    font-size: clamp(0.92rem, 3.4vw, 1.05rem);
    width:100%;
    color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};

    ::placeholder {
        color: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : 'rgba(39, 117, 85, 0.8)')};
    }
    
    :disabled {
        background: rgba(10, 10, 10, 0.2);
        opacity: 1;
    }
    &:focus {
        outline: 2px solid ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
    }
`
export const StyleIcon = css`
    position: absolute;
    transform: translate(50%, 5%);
    color: rgba(29, 107, 187, 0.8);
    font-size: 1.2rem;
    opacity: 0.70;
`
export const EmailIcon = styled(HiOutlineMail)`
    ${StyleIcon}
`
export const UsernameIcon = styled(FaRegUser)`
    ${StyleIcon}
    font-size: 1.1rem;
`
export const TableWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
export const Table = styled.table`
    border: 1px solid rgba(29, 107, 187, 0.7);
    width: 90%;
`
export const HeadWrap = styled.thead`
    width: 100%;
`
export const BodyWrap = styled.tbody``
export const BodyRow = styled.tr`
    &:nth-child(odd) {
        background: rgba(29, 107, 187, 0.5);
    }
    &:nth-child(even) {
        background: rgba(39, 117, 85, 0.5);
    }
`
export const Cell = styled.td`
    text-align: center;
    color: #fff;
    font-size: clamp(0.7rem, 3.5vw, 0.85rem);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    padding: 10px 3px;
`