import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hoverButtonAction } from '../../actions/buttonAction';
import { useLocation, useParams } from 'react-router-dom';
import { HeroContainer, HeroBg, HeroContent, HeroHeadingWrap, HeadItem, BoxAnimation, HeroHeading, DescItem, HeroDescWrap, HeroDesc, HeroBtnWrapper, ArrowFrorward, ArrowRight } from './HeroHomeElements';
import { Button } from '../ButtonElements';

const HeroHomeSection = ({ heroData, data }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { code } = useParams();
    const hover = useSelector(state => state.hoverButton);
    return (
	<HeroContainer>
	    <HeroBg >
	        <img src={heroData.src} alt={heroData.alt} />
	    </HeroBg>
	    <HeroContent>
	        <HeroHeadingWrap>
	    	    <HeadItem>
	        	<BoxAnimation></BoxAnimation>
	        	<HeroHeading headingBlue={heroData.headingBlue ? 1 : 0}>
	            	    {heroData.headingText1}
	        	</HeroHeading>
	    	    </HeadItem>
	    	    <HeadItem>
	        	<BoxAnimation></BoxAnimation>
	        	<HeroHeading headingBlue={heroData.headingBlue ? 1 : 0}>
	            	    {heroData.headingText2}
	        	</HeroHeading>
	    	    </HeadItem>
	    	    <HeadItem>
	        	<BoxAnimation></BoxAnimation>
	        	<HeroHeading headingBlue={heroData.headingBlue ? 1 : 0}>
	            	    {heroData.headingText3}
	        	</HeroHeading>
	    	    </HeadItem>
	    	    <HeadItem>
	        	<BoxAnimation></BoxAnimation>
	        	<HeroHeading headingBlue={heroData.headingBlue ? 1 : 0}>
	            	    {heroData.headingText1}
	        	</HeroHeading>
	    	    </HeadItem>
	        </HeroHeadingWrap>
	        <HeroDescWrap>
	    	    <DescItem>
	            	<HeroDesc>{data !== undefined && data.info.all_users} {heroData.description1} ${data !== undefined && data.info.all_withdraw} {heroData.description10}</HeroDesc>
	            </DescItem>
	            <DescItem>
	            	<HeroDesc>{heroData.description2}</HeroDesc>
	            </DescItem>
	            <DescItem>
	            	<HeroDesc>{heroData.description3}</HeroDesc>
	            </DescItem>
	            <DescItem>
	            	<HeroDesc>{heroData.description4}</HeroDesc>
	            </DescItem>
	            <DescItem>
	            	<HeroDesc>{heroData.description5}</HeroDesc>
	            </DescItem>
	            <DescItem>
	            	<HeroDesc>{heroData.description6}</HeroDesc>
	            </DescItem>
	            <DescItem>
	                <HeroDesc>{data !== undefined && data.info.all_users} {heroData.description1} ${data !== undefined && data.info.all_withdraw} {heroData.description10}</HeroDesc>
	            </DescItem>
	        </HeroDescWrap>
	        <HeroBtnWrapper>
	    	    <Button
	        	to={location.pathname === '/'
			   ? '/auth'
			   : location.pathname === `/ref/${code}`
			       ? `/auth/ref/${code}`
			       : '#'
			}
	        	onMouseEnter={() => dispatch(hoverButtonAction())}
	        	onMouseLeave={() => dispatch(hoverButtonAction())}
	        	primary={heroData.buttonBlue ? 1 : 0}
	        	big={1}
	        	fontbig={1}
	    	    >
	        	{heroData.buttonText} {hover ? <ArrowFrorward /> : <ArrowRight />}
	    	    </Button>
	        </HeroBtnWrapper>
	    </HeroContent>
	</HeroContainer>
    )
}
export default HeroHomeSection;
