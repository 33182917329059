export const ABData = [
    {
	img: require('../images/icecoin.png').default,
	alt: 'icecoin img',
	name: 'icecoin',
	button: 'withdraw',
	helpmsg: "1 icecoin = 0.001 USDT! You can withdraw your icecoins via cryptocurrencies. The minimum cashout is just $0.25 instant and without fee. Use the mining app to exchange your primecoins for icecoins easily and fast. Stake your icecoins in the refrigerators to get your monthly profit, for more info go to stake page."
    },
    {
	img: require('../images/primecoin.png').default,
	alt: 'primecoin img',
	name: 'primecoin',
	button: 'deposit',
	helpmsg: "1 primecoin = 0.0001 USDT! All earning ways except the stake pay with primecoins. You may purchase primecoins to upgrade your account or mining them to level up your stake account."
    },
    {
	img: require('../images/adscoin.png').default,
	alt: 'adscoin img',
	name: 'adscoin',
	button: 'deposit',
	helpmsg: "1 adscoin = 0.0001 USDT! It is only use in advertising. You may purchase adscoins or use the mining to get adscoins free."
    }
];
