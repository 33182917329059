import styled, { keyframes } from 'styled-components/macro';

export const rotateAnimation = keyframes`
0% { transform: rotate(0deg) }
100% { transform: rotate(360deg) }
`
export const Circle = styled.div`
    height: 25px;
    width: 25px;
    border: solid 3px rgba(29, 107, 187, 0.7);
    border-top-color: #01dbbb;
    border-radius: 50%;
    animation-name: ${rotateAnimation};
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`