import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import VideoView from '../components/VideoView';
import { MsgContainer, MsgItem } from '../components/PtcDetail/PtcDetailElements';
import { TinyFormButton, TinyButton } from '../components/ButtonElements';
import { ValidateSetUpForm } from '../components/SetUpPtc/ValidatePtc';
import { VideoTestForm, StatusTestMsg } from '../components/VideoDetail/VideoPlayElements.js';
import { PtcInputWrap, PtcFormInput, PtcLinkIcon, PtcTitleIcon, VideoWrap } from '../components/SetUpPtc/SetUpPtcFormElements.js';
import { countriesData } from '../data/countriesData';

const VideoPage = () => {
    const [loadYT, setLoadYT] = useState();
    const [videoStatus, setVideoStatus] = useState(-1);
    const [videoInfo, setVideoInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    const [errorDevice, setErrorDevice] = useState('');
    const [boolian, setBoolian] = useState(false);
    const [values, setValues] = useState(Object.freeze({
	url: '',
	title: ''
    }));
    const [errors, setErrors] = useState({
	url: '',
	title: ''
    });
    const [urlError, setUrlError] = useState('');
    const [youtubeCode, setYoutubeCode] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const geoData = countriesData;
    const geoObjects = geoData.objects.ne_110m_admin_0_countries.geometries;
    const geoAllCountriesIso = geoObjects.map((geoAllCountry) => geoAllCountry.properties.ISO_A2);
    useEffect(() => {
        const fetchVideoView = async () => {
            try {
                const { data } = await axiosInstance.get('ptc/video/');
                setVideoInfo(data);
            } catch (error) {
                error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
            };
            setLoading(false);
        };
        fetchVideoView();
    }, []);
    const deviceScreenhandler = () => {
	if (window.innerWidth < 1023 && videoInfo !== undefined && videoInfo.device === 'D') {
	    setErrorDevice('The window width for desktop devices should be more than 1000px!');
	};
	setBoolian(true);
    };
    useEffect(() => {
	if (videoInfo !== undefined && videoInfo.device !== undefined) {
	    window.addEventListener('resize', deviceScreenhandler);
	    deviceScreenhandler();
	};
	return () => {
	    window.removeEventListener('resize', deviceScreenhandler);
	};
    });
    const handleChange = (e) => {
	const { name, value } = e.target;
	setValues({
	    ...values,
	    [name]: value,
	});
	setIsSubmitting(false);
	setErrors({
	    url: '',
	    title: ''
	});
	setUrlError('');
    };
    const handleBlur = () => {
	if (values.url && !values.url.toLowerCase().includes('//')) {
	    setUrlError('Please enter a correct URL.');
	} else if (values.url && !values.url.toLowerCase().split('/')[2].includes('youtube.com')) {
	    setUrlError('Just youtube videos are supported.');
	} else if (values.url && values.url.split('/watch?v=')[1] === undefined) {
	    setUrlError('It seems you have entered an invalid Youtube URL. Your video is visible here if it is valid.');
	} else if (values.url && values.url.split('/watch?v=')[1] !== undefined) {
	    setYoutubeCode(values.url.split('?v=')[1]);
	};
    };
    const handleSubmit = (e) => {
	e.preventDefault();
	setErrors(ValidateSetUpForm(values));
	if (videoStatus === -1) {
	    setUrlError('Play your video first, then click on the submit button.');
	};
	setIsSubmitting(true);
    };
    const fetchSubmitTest = async () => {
	setLoading2(true);
	try {
	    await axiosInstance.post('ptc/video/test/submit/', {
		'url': values.url,
		'title': values.title,
		'target_country': geoAllCountriesIso
	    });
	    setIsSubmitted(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading2(false);
    };
    useEffect(() => {
	if (loadYT === undefined && youtubeCode !== undefined) {
	    var load = new Promise((resolve) => {
		const tag = document.createElement('script')
		tag.src = 'https://www.youtube.com/iframe_api'
		const firstScriptTag = document.getElementsByTagName('script')[0]
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
		window.onYouTubeIframeAPIReady = () => resolve(window.YT)
	   });
	    setLoadYT(load);
	};
    }, [loadYT, youtubeCode]);
    useEffect(() => {
	if (loadYT) {
	    loadYT.then((YT) => {
		new YT.Player('preview', {
		    events: {
			'onStateChange': onPlayerStateChange
		    }
		});
	    });
	};
    });
    function onPlayerStateChange(event) {
	setVideoStatus(event.data);
    };
    useEffect(() => {
	if (videoStatus === 1) {
	    setUrlError('');
	};
    }, [videoStatus]);
    useEffect(() => {
	if (Object.keys(errors).length === 0 && isSubmitting && !loading2 && !isSubmitted && !urlError && !apiError && videoStatus !== -1) {
	    fetchSubmitTest();
	};
	return () => setIsSubmitting(false);
    });
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
	    {!loading && apiError && !errorDevice &&
		<MsgContainer>
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
			        {apiError}
		            </MsgText>
			</MsgWrap>
			<TinyButton to={'/dashboard'} primary={1} big={1}>
			    return
		        </TinyButton>
		    </MsgItem>
		</MsgContainer>
	    }
	    {!loading && !apiError && errorDevice &&
		<MsgContainer>
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
			        {errorDevice}
		            </MsgText>
			</MsgWrap>
			<TinyButton to={'/dashboard'} primary={1} big={1}>
			    return
		        </TinyButton>
		    </MsgItem>
		</MsgContainer>
	    }
	    {!loading && !apiError && !errorDevice &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
			<StackDiscWrap>
			    <p>
			        Please disable your "AdBlock" extensions for the iceFaucet. <br />
			        Please allow the "Pop-ups and redirects" to the iceFaucet on your browser settings. <br />
			        Boost your earnings by upgrading your account.<br />
			        If you don't like watch a video, click on the skip button. Skip videos will be accessed again after 1 hour.<br />
			        If you have a problem to watching a video, just reload the page.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer>
	    }
            {!loading && !apiError && !errorDevice && boolian && videoInfo !== undefined &&
		<VideoView info={videoInfo} />
	    }
            {!loading && !apiError && !errorDevice && boolian && videoInfo !== undefined && videoInfo.status !== 'D' && videoInfo.testable &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>Test video advertising for free</h2>
			</TitleWrap>
			{videoInfo.status === 'N' &&
			    <StackDiscWrap>
				<p>
				    Just enter the URL of your YouTube video and your video title to advertise your video for 500 visits. After validating your video, it will show to users. If you entered the correct URL, your video will be shown here.
				    Example youtube URL: https://www.youtube.com/watch?v=mms2tTXLWZk
				</p>
			    </StackDiscWrap>
			}
		    	<VideoTestForm action='#' onSubmit={handleSubmit}>
			    {(isSubmitted || videoInfo.status === 'P') &&
				<MsgWrap primary={1}>
				    <MsgText primary={1}>
				        Your video has been submitted successfully. After validating, it will be shown.
				    </MsgText>
				</MsgWrap>
			    }
		    	    {!isSubmitted && videoInfo.status === 'N' &&
				<>
				    <PtcInputWrap>
					<PtcLinkIcon />
					<PtcFormInput
				            type='url'
				            name='url'
				            placeholder={'Enter your youtube URL. See example link above.'}
				            onChange={handleChange}
				            onBlur={handleBlur}
				            value={values.url}
				        >
					</PtcFormInput>
				    </PtcInputWrap>
				    {youtubeCode !== undefined &&
					<VideoWrap>
					    <iframe
					    	id='preview'
					        src={`https://www.youtube.com/embed/${youtubeCode}?enablejsapi=1&rel=0`}
						title='preview'
						frameBorder='0'
						allow='autoplay; encrypted-media'
						allowFullScreen
						style={{ width: '100%', height: '100%' }}
					    />
					</VideoWrap>
				    }
				    {errors.url &&
					<MsgWrap primary={0}>
					    <MsgText primary={0}>
						{errors.url}
					    </MsgText>
					</MsgWrap>
				    }
				    {urlError &&
					<MsgWrap primary={0}>
					    <MsgText primary={0}>
						{urlError}
					    </MsgText>
					</MsgWrap>
				    }
				    <PtcInputWrap>
					<PtcTitleIcon />
					<PtcFormInput
					    type='text'
					    name='title'
					    placeholder='Enter your video title.'
					    onChange={handleChange}
					    value={values.title}
					>
				        </PtcFormInput>
				    </PtcInputWrap>
				    {errors.title &&
					<MsgWrap primary={0}>
					    <MsgText primary={0}>
					        {errors.title}
					    </MsgText>
					</MsgWrap>
				    }
				    <TinyFormButton type='submit' primary={1} big={0} style={{ width: '50%', marginTop: '15px' }}>
					{loading2 ? <Circle style={{ margin: 'auto', width: '15px', height: '15px' }} /> : 'submit'}
				    </TinyFormButton>
				</>
			    }
			    {videoInfo.status === 'A' &&
				<StatusTestMsg>
				    <MsgWrap primary={1}>
					<MsgText primary={1}>
					    Your video has been accepted and now users are watching it. For access to more info press the following button.
					</MsgText>
				    </MsgWrap>
				    <TinyButton style={{ width: '50%', margin: '15px auto 0 auto' }} to={'/control-ads'} primary={1} big={1}>
					control ads
				    </TinyButton>
				</StatusTestMsg>
			    }
			    {videoInfo.status === 'C' &&
				<StatusTestMsg>
				    <MsgWrap primary={1}>
					<MsgText primary={1}>
					    Now your video has received 500 visits and your test has been completed. You can advertise your video with a low price and many extra filters (1000 visits = $0.6). Advertising videos are in the top position.
					</MsgText>
				    </MsgWrap>
				    <TinyButton style={{ width: '50%', margin: '15px auto 0 auto' }} to={'/advertise'} primary={1} big={1}>
					advertise
				    </TinyButton>
				</StatusTestMsg>
			    }
			    {videoInfo.status === 'R' &&
				<MsgWrap primary={0}>
				    <MsgText primary={0}>
					Unfortunately, your video is not appropriate for displaying on the iceFaucet. After a while, you can test the video app again.
				    </MsgText>
				</MsgWrap>
			    }
			</VideoTestForm>
		    </StackWrap>
		</StackContainer>
	    }
        </>
    )
}
export default VideoPage;
