import styled from 'styled-components';
import device from '../../constants/breakPoints';


export const FeaturesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #010606;
    text-align: center;

    h2 {
        font-size: clamp(1.1rem, 3.5vw, 2rem);
        color: #01DBBB;
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 4px;
        text-transform: uppercase;
        margin: 80px 0 40px 0;
    }
`
export const FeaturesWrapper = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    margin-bottom: 80px;

    @media screen and ${device.xl} {
        padding: 0 5%;
    }

    @media screen and ${device.md} {
        grid-template-columns : 1fr;
	padding: 5px;
    }
`
export const FeaturesCard = styled.div`
    width: 100%;
    background: rgba(39, 117, 85, 0.75);
    border-radius: 10px;
    height: 470px;
    padding: 15px;
    transition: all 0.2s ease-in-out;
    max-width: 420px;
    margin: auto;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;  
    }
    &:nth-child(2n) {
        background: rgba(29, 107, 187, 0.75);
    }
    @media screen and ${device.lg} {
        height: 540px;
    }
    @media screen and ${device.md} {
	height: 450px;
	padding: 15px 10px;
    }
    @media screen and ${device.tn} {
        height: 550px;
	padding: 15px 5px;
    }
`
export const FeaturesTitle = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};

    h3 {
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(1rem, 3.5vw, 1.3rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
        padding: 10px 3px;
    }
`
export const FeaturesTextWrap = styled.div`
    padding-left: 5px;
`
export const FeaturesDes = styled.div`
    display: flex;

    div {
        color: #01DBBB;
        font-size: 1.4rem;
        margin: 16px 0 0 7px;
    }

    p{
        color: #fff;
        font-size: clamp(0.8rem, 3vw, 1rem);
        text-align: center;
        list-style: none;
        text-align: left;
        margin: 10px 0 5px 10px;
        line-height: 33px;
        text-shadow: 1px 1px 3px #010606;
    }
`
