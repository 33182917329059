import styled, { css } from 'styled-components/macro';
import { FiLink } from 'react-icons/fi';
import { MdDescription } from 'react-icons/md';
import device from '../../constants/breakPoints';

export const EditAdsForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: start-flex;
    align-items: center;
    width: 80%;
    height:90%;
    background: rgba(29, 107, 187, 0.3);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    margin: 10px auto;
    overflow-y: auto;
    @media screen and ${device.md} {
	width: 90%;
    }
        
    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(256, 256, 256, 0.2);
        box-shadow: inset 0 0 4px rgba(29, 107, 187, 0.7);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(29, 107, 187, 0.7);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(29, 107, 187, 0.8);
    }
`
export const TitleWrap = styled.div`
    background: rgba(29, 107, 187, 0.5);
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    
    h3 {
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(1rem, 3.5vw, 1.3rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
    }
`
export const ContentWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const EditCard = styled.div`
    width: 70%;
    background: rgba(29, 107, 187, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: start-flex;
    align-items: center;
    margin: 15px auto;
    border-radius: 15px 15px 0 0;
    @media screen and ${device.lg} {
	width: 80%;
    }
    @media screen and ${device.md} {
	width: 95%;
    }
`
export const EditInputWrap = styled.div`
    width: 85%;
    padding-bottom: 15px;
`
export const PtcIcons = css`
    position: relative;
    transform: translate(75%, 152%);
    color: rgba(1, 219, 187, 1);
    font-size: 1.2rem;
`
export const EditLinkIcon = styled(FiLink)`
    ${PtcIcons}
`
export const EditTextIcon = styled(MdDescription)`
    ${PtcIcons}
`
export const EditInput = styled.input`
    padding: 5px 10px 5px 40px;
    border: none;
    border-radius: 20px;
    font-size: 1.05rem;
    width: 100%;
    color: rgba(1, 219, 187, 1);

    :disabled {
        background: rgba(10, 10, 10, 0.2);
        opacity: 1;
    }
    &:focus {
        outline: 3px solid rgba(1, 219, 187, 1);
    }
    `
export const SocialWrap = styled.div`
    
`
export const SocialIconWrap = styled.div`
    
`
export const InputTitleWrap = styled.div`
    width: 100%;
    text-align: center;
    background: rgba(29, 107, 187, 1);
    border-radius: 15px 15px 0 0;
    padding: 5px 0;
    
    h4 {
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(0.85rem, 3.5vw, 1.1rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
    }
`
export const InputContentWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start-flex;
    align-items: center;
    padding: 15px;
`
export const InputLangWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    @media screen and ${device.sm} {
	grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and ${device.tn} {
        grid-template-columns: 1fr 1fr;
    }
`
export const InputTwoItemsWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
`
export const InputThreeItemsWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
`
export const InputButton = styled.button`
    color: #fff;
    font-size: clamp(0.75rem, 1.8vw, 0.85rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    height: 35px;
    width: 88px;
    background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#1D6BBB')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(1, 219, 187, 0.7);
    transition: 0.3s ease-in-out;
    @media screen and ${device.md} {
	height: 30px;
	width: 70px;
    }

    &:hover {
        background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#277555')};
        transform: scale(1.05);
        transition: 0.3s ease-in-out;
    }
`
