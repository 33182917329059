import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { Card, TitrWrap, ContentWrap, ItemWrap, IconWrap, EditIcon, StopIcon, PlayIcon } from './AssistanceCardElements';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import moment from 'moment';
import EditModal from './EditModal';

const AssistanceCard = ({ assistance, index }) => {
    const [assistanceIndex, setAssistanceIndex] = useState();
    const [publishClick, setPublishClick] = useState(false);
    const [publishComplete, setPublishComplete] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const handlePublishClick = (index) => {
	setPublishClick(!publishClick);
	setAssistanceIndex(index);
    };
    const handlePublishNoClick = () => {
	setPublishClick(false);
    };
    const handlePublishYesClick = (id, publish) => {
	fetchChangePublish(id, publish);
    };
    const fetchChangePublish = async (id, publish) => {
	await axiosInstance.post('shortlink/assistant/publish/', {
	    'id': id,
	    'publish': publish
	});
	setPublishComplete(true);
    };
    const handleEditClick = (index) => {
	setEditClick(true);
	setAssistanceIndex(index);
    };
    return (
	<>
	    <Card>
	        <TitrWrap color={assistance.status} style={{ borderRadius: '15px 15px 0 0' }}>
	            <h3>{assistance.name}</h3>
	        </TitrWrap>
	        {assistance.status === 'P' &&
		    <ContentWrap color={assistance.status}>
			<ItemWrap>
			    <span>We will review it. Please wait...</span>
		        </ItemWrap>
		    </ContentWrap>
		}
	        {assistance.status === 'C' &&
		    <ContentWrap color={assistance.status}>
			<ItemWrap>
			    <span>visited:</span>
			    <span>{assistance.visited}</span>
		        </ItemWrap>
			<ItemWrap>
			    <span>price</span>
			    <span>{assistance.price}</span>
			</ItemWrap>
			    <ItemWrap>
			    	<span>last edit</span>
				<span>
				    {moment(assistance.last_edit).fromNow()}
			        </span>
			</ItemWrap>
		    </ContentWrap>
		}
	        {assistance.status === 'R' &&
		    <ContentWrap color={assistance.status}>
			<ItemWrap>
			    <span>{assistance.admin_comment}</span>
			</ItemWrap>
		    </ContentWrap>
		}
	        <ContentWrap color={assistance.status}>
	            <ItemWrap style={{ padding: '3px' }}>
	                <IconWrap onClick={() => handleEditClick(index)}>
	                    <EditIcon title='Edit' />
	                </IconWrap>
	                <IconWrap onClick={() => handlePublishClick(index, assistance.publish)}>
	                    {!assistance.publish ? <StopIcon title='play' /> : <PlayIcon title='stop' />}
	                </IconWrap>
	            </ItemWrap>
	        </ContentWrap>
	        {publishClick && assistanceIndex === index &&
		    <ContentWrap color={assistance.status} isOpen={publishClick ? 1 : 0}>
			{!publishComplete &&
			    <>
				<ItemWrap style={{ padding: '3px 3px 0 3px' }}>
				    <p style={{ color: '#fff', fontSize: '0.9rem' }}>Are you sure you want to {!assistance.publish ? 'publish' : 'unpublish'} your assistance?</p>
				</ItemWrap>
				<ItemWrap style={{ padding: '10px' }}>
				    <TinyFormButton primary={0} big={0} type='button' onClick={handlePublishNoClick} style={{ width: '60px' }}>
				        no
				    </TinyFormButton>
				    <TinyFormButton primary={0} big={0} type='button' onClick={() => handlePublishYesClick(assistance.id, !assistance.publish)} style={{ width: '60px' }}>
				        yes
				    </TinyFormButton>
				</ItemWrap>
			    </>
			}
			{publishComplete &&
			    <MsgWrap primary={1} style={{ margin: '10px auto', width: '95%' }}>
				<MsgText primary={1}>
				    Your assistnace has been {!assistance.publish ? 'successfully published' : 'unpublished'}.
				</MsgText>
			    </MsgWrap>
			}
		    </ContentWrap>
		}
	        <TitrWrap color={assistance.status} style={{ borderRadius: '0 0 15px 15px' }}>
	            <h3>{assistance.status === 'P' ? 'pending' : assistance.status === 'C' ? 'Confirmed' : assistance.status === 'R' ? 'Rejected' : null}</h3>
	        </TitrWrap>
	    </Card>
	    {editClick && assistanceIndex === index &&
		<EditModal editClick={editClick}
		    setEditClick={setEditClick}
		    assistance={assistance}
		/>
	    }
	</>
    )
}
export default AssistanceCard;
