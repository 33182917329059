import React from 'react';
import { useDispatch } from 'react-redux';
import { creditCheckAction, PtcSetUpAction, PtcFiltersAction, PtcFilters2Action, PtcFilters3Action, PtcSetUpEndAction, PtcSuccessAction } from '../../actions/PtcSetUpAction';
import { SetUpContainer, SetUpBgWrap, AdsSetUpContainer, AdsContent, AdsToolWrap, FormHeader, ItemContainer, ItemWrap, ItemLink } from './SetUpAddsElements';
import { bannerSetUpAction, bannerFiltersAction, bannerFilters2Action, bannerSuccessAction } from '../../actions/bannerSetUpAction';
import SetUpAdsBg from '../../images/advertise.jpg';
import { adsSetUpData } from '../../data/adsSetUpData';
import { miningSetUpAction, miningSetUpEndAction, miningSuccessAction } from '../../actions/miningSetUpAction';
import { sponsorSetUpAction, sponsorFiltersAction, sponsorSetUpEndAction, sponsorSuccessAction } from '../../actions/sponsorSetUpAction';

const SetUpAds = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
        localStorage.removeItem('lang');
        localStorage.removeItem('duration');
        localStorage.removeItem('repeat');
        localStorage.removeItem('visit');
        localStorage.removeItem('url');
        localStorage.removeItem('title');
        localStorage.removeItem('description');
        localStorage.removeItem('target_country');
        localStorage.removeItem('target_device');
        localStorage.removeItem('target_gender');
        localStorage.removeItem('social1');
        localStorage.removeItem('social2');
        localStorage.removeItem('social3');
        localStorage.removeItem('refshow');
        localStorage.removeItem('adcoin');
        localStorage.removeItem('upcode');
        localStorage.removeItem('type');
        localStorage.removeItem('impression');
        dispatch(creditCheckAction({ prop: true }));
        dispatch(PtcSetUpAction({ prop: false }));
        dispatch(PtcFiltersAction({ prop: false }));
        dispatch(PtcFilters2Action({ prop: false }));
        dispatch(PtcFilters3Action({ prop: false }));
        dispatch(PtcSetUpEndAction({ prop: false }));
        dispatch(PtcSuccessAction({ prop: false }));
        dispatch(miningSetUpAction({ prop: false }));;
        dispatch(miningSetUpEndAction({ prop: false }));
        dispatch(miningSuccessAction({ prop: false }));
        dispatch(sponsorSetUpAction({ prop: false }));
        dispatch(sponsorFiltersAction({ prop: false }));
        dispatch(sponsorSetUpEndAction({ prop: false }));
        dispatch(sponsorSuccessAction({ prop: false }));
        dispatch(bannerSetUpAction({ prop: false }));
        dispatch(bannerFiltersAction({ prop: false }));
        dispatch(bannerFilters2Action({ prop: false }));
        dispatch(bannerSuccessAction({ prop: false }));
    };
    return (
        <SetUpContainer>
            <SetUpBgWrap>
                <img src={SetUpAdsBg} alt={'background of set up advertisments page'} />
            </SetUpBgWrap>
            <AdsSetUpContainer>
                <AdsContent>
                    <AdsToolWrap>
                        <FormHeader>
                            <h2>Select your advertising tool</h2>
                        </FormHeader>
                        <ItemContainer>
                            {adsSetUpData.map((adsTool, index) => (
                                <ItemWrap key={index}>
                                    <ItemLink to={adsTool.path} onClick={handleClick}>
                                        {adsTool.tool}
                                    </ItemLink>
                                </ItemWrap>
                            ))}

                        </ItemContainer>
                    </AdsToolWrap>
                </AdsContent>
            </AdsSetUpContainer>
        </SetUpContainer>
    )
}

export default SetUpAds
