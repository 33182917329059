import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { QuestionWrap, Question, AnswerWrap } from './FAQElements';

export const BlogWrap = styled.div`
    width: 90%;
    margin: 20px auto 0 auto;
    @media screen and ${device.sm} {
        width: 100%;
    };
`
export const BlogCategoryWrap = styled(QuestionWrap)`
`
export const BlogCategory = styled(Question)`
`
export const BlogCard = styled(AnswerWrap)`
`
export const BlogTitleWrap = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.9)' : ' rgba(39, 117, 85, 0.9)')};
    padding: 10px;
    margin-bottom: 15px;
h4 {
    color: rgb(1, 219, 187);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.85rem, 2.5vw, 1.1rem);
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 1.7px;
    text-transform: uppercase;
    text-align: center;
}
`
export const BlogContentWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;

    @media screen and ${device.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 25px;
    };
`
export const ImgWrap = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.9)' : ' rgba(39, 117, 85, 0.9)')};
    width: 275px;
    height: 275px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    color: #fff;
    font-size: 1rem;
    margin: auto;

    @media screen and ${device.md} {
        width: 220px;
        height: 220px;
    };
    @media screen and ${device.sm} {
        width: 200px;
        height: 200px;
        border-radius: 30px;
    };
    @media screen and ${device.tn} {
        width: 130px;
        height: 130px;
    };
    img {
        width: 95%;
        height: 95%;
        position: absolute;
        border-radius: 50px;

        @media screen and ${device.sm} {
            border-radius: 30px;
        };
    }
`
export const ContentWrap = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
`
export const DiscWrap = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.9)' : ' rgba(39, 117, 85, 0.9)')};
    border-radius: 5px;
    max-width: 350px;
    max-height: 180px;
    overflow: auto;

    @media screen and ${device.md} {
        margin-bottom: 25px;
    };

    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : ' rgba(39, 117, 85, 0.8)')};
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${({ primary }) => (primary ? 'rgb(29, 107, 187)' : ' rgb(39, 117, 85)')};
    }
`