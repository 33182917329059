import {
    SHOW_MININGSETUP,
    NOTSHOW_MININGSETUP,
    SHOW_MININGEND,
    NOTSHOW_MININGEND,
    SHOW_MININGSUCCESS,
    NOTSHOW_MININGSUCCESS
} from '../constants/miningSetUpRedux';

export const miningSetUpAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_MININGSETUP
            })
        } else {
            dispatch({
                type: NOTSHOW_MININGSETUP
            })
        };
    };
};


export const miningSetUpEndAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_MININGEND
            })
        } else {
            dispatch({
                type: NOTSHOW_MININGEND
            })
        };
    };
};

export const miningSuccessAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_MININGSUCCESS
            })
        } else {
            dispatch({
                type: NOTSHOW_MININGSUCCESS
            })
        };
    };
};
