import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../axios';
import { PtcFilters3Action, PtcFiltersAction, PtcSetUpEndAction, PtcSetUpAction } from '../../actions/PtcSetUpAction';
import { PtcForm, PtcFormInputContainer } from './SetUpPtcFormElements';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { FormButtonsWrap } from './CreditCheckElements';
import { TinyFormButton } from '../ButtonElements';
import { FilterDurationWrap, FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName, FilterInputWrap, RangeInput, NumberInput, NumberInputWrap, UpcodeWrap } from './SetUpPtcFilterElements.js';
import { ptcDurationData } from '../../data/ptcFiltersData';
import { MsgText, MsgWrap } from '../MessageElements';
import { HelpIcon, HelpWrap } from '../HelpElements';

const SetUpPtcFilter = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState('');
    const [duration, setDuration] = useState(Object.freeze(1.0));
    const [durationIndex, setDurationIndex] = useState(0);
    const [visitValue, setVisitValue] = useState(Object.freeze(1000));
    const [showHelp, setShowHelp] = useState(false);
    const type = location.pathname === '/setup-ads/ptc' ? 10
        : location.pathname === '/setup-ads/surf'
            ? 8
            : location.pathname === '/setup-ads/video'
                ? 6
                : null;
    const lang = localStorage.getItem('lang') ? 0.2 : 0;
    const country = localStorage.getItem('target_country') ? 0.2 : 0;
    const CPC = type * (lang + country + duration);
    const totalCost = visitValue * CPC;
    const upcodeValue = Math.floor(visitValue * parseFloat(localStorage.getItem('upcode')));
    const handleChange = (e) => {
        let number = parseInt(e.target.value)
        if (!isNaN(number)) {
            setVisitValue(parseInt(number));
        };
        setIsSubmitting(false);
        setErrors('');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const adscoin = localStorage.getItem('adcoin');
        if (visitValue < 1000) {
            setErrors('Please enter a number greater than or equal to 1000.');
        } else if (parseInt(adscoin) < totalCost) {
            setErrors('You do not have enogh adscoin.');
        } else if (visitValue > 100000) {
            setErrors('Please enter a number less than or equal to 100000.');
        };
        setIsSubmitting(true);
    };
    const handleDurationClick = (e, index) => {
        const durationName = e.target.getAttribute('name');
        if (durationName && duration !== durationName) {
            setDuration(parseFloat(durationName));
            setDurationIndex(index);
        };
    };
    const maxValue = () => {
        const adscoin = parseInt(localStorage.getItem('adcoin'));
        const maximum = Math.floor(adscoin / CPC);
        if (maximum <= 100000) {
            setVisitValue(maximum);
        } else if (maximum > 100000) {
            setVisitValue(100000);
        };
        setIsSubmitting(false);
        setErrors('');
    };
    const handleBack = () => {
        dispatch(PtcFiltersAction({ prop: false }));
        if (localStorage.getItem('refshow') === 'true') {
            dispatch(PtcSetUpAction({ prop: true }))
        } else {
            dispatch(PtcFilters3Action({ prop: true }))
        };
    }
    const fetchUpCodeUserCkeck = async () => {
        const { data } = await axiosInstance.get('ptc/advertise/upcode-user-check/');
        localStorage.setItem('upcode', data.upcode);
    };
    const fetchVisitDurationCkeck = async () => {
        await axiosInstance.post('ptc/advertise/visit-duration-check/', {
            duration: duration,
            visit: visitValue
        });
    };
    const fetchExtraFilters = async () => {
        localStorage.setItem('duration', duration);
        localStorage.setItem('visit', visitValue);
        dispatch(PtcFiltersAction({ prop: false }));
        dispatch(PtcSetUpEndAction({ prop: true }));
    };
    useEffect(() => {
        !localStorage.getItem('upcode') && fetchUpCodeUserCkeck();
        if (errors.length === 0 && isSubmitting) {
            fetchVisitDurationCkeck();
            fetchExtraFilters();
        };
    });
    return (
        <>
            <PtcForm action='#' onSubmit={handleSubmit}>
                <PtcFormInputContainer>
                    <FormHeader>
                        <h2>Set the duration of watching your ads</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                Select the duration your ads should be watched by users.
                            </Instruction>
                        </InstructionWrap>
                        <FilterDurationWrap>
                            {ptcDurationData.map((duration, index) => (
                                <ItemWrap key={index}>
                                    <ItemName name={duration.name} onClick={(e) => handleDurationClick(e, index)} itemActive={durationIndex === index ? 1 : 0}>
				    	{duration.duration} sec <br />
                                        {duration.price && duration.price}
                                    </ItemName>
                                </ItemWrap>
                            ))}
                        </FilterDurationWrap>
                    </FilterContainer>
                    <FormHeader>
                        <h2>Set number of visits</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                It is exactly equal to the number of users who could earn by watching your ads.
                            </Instruction>
                        </InstructionWrap>
                        <FilterInputWrap>
                            <RangeInput
                                type='range'
                                name='visit'
                                min='1000'
                                max='100000'
                                value={visitValue}
                                onChange={handleChange}
                            />
                            <NumberInputWrap>
                                <NumberInput
                                    type='number'
                                    name='visit'
                                    value={visitValue}
                                    onChange={handleChange}
                                />
                                <TinyFormButton type='button' primary={0} big={0} onClick={maxValue}>
                                    max
                                </TinyFormButton>
                            </NumberInputWrap>
                            {totalCost >= 250000 && <UpcodeWrap>
                                <span>
                                    with upcode: {upcodeValue} visits
                                </span>
                                <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                                <HelpWrap show={showHelp ? 1 : 0} style={{ width: '90%', margin: '10px auto 0 auto' }}>
                                    <p>If cost of your ads is more than 25$, based on your level, you get more credits. Masternode members get 10% more credit.</p>
                                </HelpWrap>
                            </UpcodeWrap>}
                        </FilterInputWrap>
                    </FilterContainer>
                </PtcFormInputContainer>
                {errors.length > 0 &&
                    <MsgWrap>
                        <MsgText>
                            {errors}
                        </MsgText>
                    </MsgWrap>}
                <FormButtonsWrap>
                    <TinyFormButton type='submit' primary={1} big={0}>
                        Next
                    </TinyFormButton>
                    <TinyFormButton type='button' primary={1} big={0} onClick={handleBack}>
                        back
                    </TinyFormButton>
                </FormButtonsWrap>
            </PtcForm>
        </>
    )
};
export default SetUpPtcFilter;
