export const TabCradMiningData = [
    {
	value: 'blockchain',
	name: 'B'
    },
    {
	value: 'application',
	name: 'A'
    },
    {
	value: 'terms',
	name: 'T'
    },
    {
	value: 'special',
	name: 'S'
    }
];
