import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../Stack/StackElements';
import { InputWrap, Input } from './PersonalityElements';
import { PasswordIcon } from './ChangePasswordElements';
import { InputContainer } from './ChangePasswordElements';
import { TimerWrap, TimerCode } from './ConfirmChangesElements';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { ResetCodeWrap, NumberFormatCode } from '../Auth/ResetCodeElements';
import Countdown, { zeroPad } from 'react-countdown';

const ConfirmChanges = ({ setIsSubmitting, updateError, updateComplete }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [errorCurrentPassword, setErrorCurrentPassword] = useState('');
    const [correctPassword, setCorrectPassword] = useState(false);
    const [errorCode, setErrorCode] = useState('');
    const [expired, setExpired] = useState(false);
    const [timer, setTimer] = useState();
    const ValidateConfirmPassword = () => {
	if (!currentPassword) {
	    setErrorCurrentPassword('The password is required.')
	} else if (currentPassword.length < 8) {
	    setErrorCurrentPassword('Your password is too short.')
	} else if (currentPassword.length > 32) {
	    setErrorCurrentPassword('Your password is too long.')
	} else if (/^[0-9]+$/i.test(currentPassword)) {
	    setErrorCurrentPassword('Your password should not be entirely numeric.')
	} else if (/^[A-Z]+$/i.test(currentPassword)) {
	    setErrorCurrentPassword('Your password should not be entirely alphabetic.')
	} else if (/^[-_.*$#@]+$/i.test(currentPassword)) {
	    setErrorCurrentPassword('Your password should have at least one alphabetic and numeric character.')
	} else if (/^[A-Z0-9]+$/i.test(currentPassword)) {
	    setErrorCurrentPassword('Your password should have at least one character in {@, #, $, *, _, -, .}.')
	} else if (/^[A-Z-_.*$#@]+$/i.test(currentPassword)) {
	    setErrorCurrentPassword('Your password should have at least one numeric character.')
	} else if (/^[0-9-_.*$#@]+$/i.test(currentPassword)) {
	    setErrorCurrentPassword('Your password should have at least one alphabetic character.')
	} else if (!(/^[A-Z0-9-_.*$#@]+$/i.test(currentPassword))) {
	    setErrorCurrentPassword('Password is invalid')
	} else {
	    return true;
	};
	return false;
    };
    const fetchConfirmPassword = async () => {
        try {
            const { data } = await axiosInstance.post('settings/check-password/', {
                password: currentPassword
            });
            setCorrectPassword(true);
            setTimer(Date.now() + data.timer * 1000);
        } catch (error) {
            error.response && error.response.data.detail ? setErrorCurrentPassword(error.response.data.detail) : setErrorCurrentPassword(error.message);
        };
    };
    const fetchCheckSettingsCode = async (code) => {
        try {
            await axiosInstance.post('settings/check-code/', {
                code: code
            });
            setIsSubmitting(true);
        } catch (error) {
            error.response && error.response.data.detail ? setErrorCode(error.response.data.detail) : setErrorCode(error.message);
        };
    };
    const handleChange = (e) => {
        let password = e.target.value;
        setCurrentPassword(password);
        setErrorCurrentPassword('');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        ValidateConfirmPassword() && fetchConfirmPassword();
    };
    const handleChangeCode = (e) => {
        let code = e.target.value.replace(/ /g, '').replace('_', '')
        if (code.length === 6) {
            fetchCheckSettingsCode(code);
        };
        setErrorCode(false);
    };
    const renderer = ({ minutes, seconds }) => {
        return <TimerWrap><TimerCode>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerCode></TimerWrap>;
    };
    return (
        <StackContainer primary={0}>
            <StackWrap primary={0}>
                <TitleWrap primary={0}>
                    <h2>
                        confirm your changes
                    </h2>
                </TitleWrap>
                <StackDiscWrap style={{ width: '70%' }}>
                    <InputContainer action='#' onSubmit={handleSubmit}>
                        <InputWrap>
                            <PasswordIcon />
                            <Input
                                primary={0}
                                type='password'
                                name='password'
                                placeholder='Enter your current password'
                                value={currentPassword}
                                onChange={handleChange}
                            />
                        </InputWrap>
                        {!correctPassword &&
                            <TinyFormButton type='submit' primary={0} big={1} style={{ width: '70%', margin: ' 15px auto 0 auto' }}>
                                submit
                            </TinyFormButton>
                        }
                        {correctPassword && !updateComplete && timer !== undefined &&
                            <MsgWrap primary={1}>
                                <MsgText primary={1}>
                                    <p>
                                        An email containing a code has been sent to your email address.
                                        After entering the correct code in 15 minutes, all your updates are done.
                                        This is for the safety of your account.
                                    </p>
                                </MsgText>
                                <Countdown
                                    date={timer}
                                    renderer={renderer}
                                    onComplete={() => {
                                        setExpired(true);
                                        setTimer(0);
                                    }}
                                />
                                <ResetCodeWrap>
                                    <NumberFormatCode
                                        format={"#  #  #  #  #  #"}
                                        allowEmptyFormatting mask="_"
                                        onChange={handleChangeCode}
                                    />
                                </ResetCodeWrap >
                            </MsgWrap>
                        }
                    </InputContainer>
                    {errorCurrentPassword.length > 0 &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errorCurrentPassword}
                            </MsgText>
                        </MsgWrap>
                    }
                    {errorCode.length > 0 &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errorCode}
                            </MsgText>
                        </MsgWrap>
                    }
                    {updateError.length > 0 &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {updateError}
                            </MsgText>
                        </MsgWrap>
                    }
                    {updateComplete &&
                        <MsgWrap primary={1}>
                            <MsgText primary={1}>
                                Your changes has been successfully submitted.
                            </MsgText>
                        </MsgWrap>
                    }
                    {expired && <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            time out please try again
                        </MsgText>
                    </MsgWrap>
                    }
                </StackDiscWrap>
            </StackWrap>
        </StackContainer>
    )
}
export default ConfirmChanges;
