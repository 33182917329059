import React, { useState, useEffect } from 'react';
import { Header, LeftWrap, LogoWrapLink, SubjectWrap } from '../components/TOS/TOSElements';
import { BigContainer, ContainerLogout, WrapperLogout, ContentContainer, ContentWrap } from '../components/Auth/LogoutElements';
import logo from '../images/logo.png';
import { TinyButton } from '../components/ButtonElements';
import { Circle } from '../components/Loading';

const SuccessFP = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
	setTimeout(() => setLoading(false), 2000);
    }, []);
    return (
	<BigContainer>
	    <ContainerLogout>
	        <WrapperLogout>
	    	    <Header style={{ background: 'rgba(29, 107, 187, 0.4)' }}>
	                <LeftWrap>
	                    <LogoWrapLink to='/'>
	                        <img src={logo} alt={'logo of icefaucet'} />
	                    </LogoWrapLink>
	                </LeftWrap>
	                <SubjectWrap>
	                    <h1>deposit</h1>
	                </SubjectWrap>
	            </Header>
	    	    <ContentContainer>
	        	<ContentWrap>
	            	    {loading &&
			        <>
			            <span><Circle /></span>
			            <p style={{ color: '#01DBBB' }}>Please wait...</p>
			        </>
			    }
	            	    {!loading &&
				<p style={{ color: '#01DBBB' }}>
				    Your deposit has been successfully paid. We hope so can satisfy you after your purchase. If you have any problem or need any guidance, please do not hesitate to contact us.
				</p>
			    }
	        	</ContentWrap>
	        	{!loading &&
			    <TinyButton to='/help' primary={1} big={0} style={{ width: '250px', margin: 'auto' }}>Support ticket</TinyButton>
			}
	        	{!loading &&
			    <TinyButton to='/history-panel' primary={1} big={0} style={{ width: '250px', margin: '10px auto' }}>your history payments</TinyButton>
			}
	    	    </ContentContainer>
	    	</WrapperLogout>
	    </ContainerLogout>
	</BigContainer>
    )
}
export default SuccessFP;
