import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { VideoContainer, VideoHeader, VideoHeaderWrap, Bar, VideoPlayWrap, VideoDescription, DetailWrap, Duration, DetailTitle, Pcoin, MsgItem, VideoButtonsWrap, GridWrap, AdLinkWrap, LinkWrap, OpenAds, Return, Next, NumberWrap } from './VideoPlayElements';
import { Circle } from '../Loading';
import { MsgText, MsgWrap } from '../MessageElements';
import { TinyFormButton, TinyButton } from '../ButtonElements';
import { ModalContainer, ModalWrapper, CloseIconWrap, CloseIcon } from './VideoPlayElements';
import IcefaCaptcha from '../Captcha/IcefaCaptcha';
import Sponsor from '../Sponsor';

const VideoDetail = ({ ads, setAds }) => {
    const history = useHistory();
    const [loadYT, setLoadYT] = useState();
    const [videoStatus, setVideoStatus] = useState(-1);
    const [width, setWidth] = useState(100);
    const [guide, setGuide] = useState(true);
    const [showMsg, setShowMsg] = useState('');
    const [end, setEnd] = useState('');
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [cryptoCoinsAdInfo, setCryptoCoinsAdInfo] = useState();
    const [sponsorData, setSponsorData] = useState();
    const [captchaDone, setCaptchaDone] = useState(false);
    const adsDuration = ads.duration === 2.5 ? 40 : ads.duration === 2 ? 30 : ads.duration === 1.5 ? 20 : ads.duration === 1 ? 10 : null;
    useEffect(() => {
	if (loadYT === undefined) {
	    var load = new Promise((resolve) => {
		const tag = document.createElement('script')
		tag.src = 'https://www.youtube.com/iframe_api'
		const firstScriptTag = document.getElementsByTagName('script')[0]
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
		window.onYouTubeIframeAPIReady = () => resolve(window.YT)
	    });
	    setLoadYT(load);
	};
    }, [loadYT]);
    useEffect(() => {
	if (loadYT) {
	    loadYT.then((YT) => {
		new YT.Player(ads.youtube, {
		    events: {
			'onStateChange': onPlayerStateChange
		    }
		});
	    });
	};
    });
    function onPlayerStateChange(event) {
	setVideoStatus(event.data);
	setGuide(false);
    };
    let timeStep = 9 * adsDuration;
    const completeTimer = () => {
	setShowMsg(`Press the button to earn ${ads.pcoin} pc.`);
    };
    useEffect(() => {
	if (width === 0) {
	    if (ads.need_captcha) {
		fetchCryptoCoinsAdBanner(ads.device);
		fetchSponsorData();
		setIsOpen(true)
	    } else {
		setCaptchaDone(true);
	    };
	};
    }, [width, ads, captchaDone]);
    useEffect(() => {
	if (captchaDone && !showMsg) {
	    completeTimer();
	};
    });
    useEffect(() => {
	if (captchaDone && !showMsg) {
	    setIsOpen(false);
	};
    }, [captchaDone, showMsg, isOpen]);
    const fetchCryptoCoinsAdBanner = async (device) => {
	const height_list = device === 'D' ? [90, 60] : [250];
	const { data } = await axiosInstance.post('aadsbanner/cryptocoinsad/', { 'height_list': height_list });
	setCryptoCoinsAdInfo(data.info);
    };
    const fetchSponsorData = async () => {
	const { data } = await axiosInstance.get('sponsor/view/fix/');
	setSponsorData(data.info);
    };
    useEffect(() => {
	if (videoStatus === 1) {
	    const timer = setTimeout(() => {
		setWidth(width - 1);
	    }, timeStep);
	    return () => clearTimeout(timer);
	};
    });
    const completeHandler = async () => {
	setLoading(true);
	try {
	    await axiosInstance.post('ptc/done/video/', {
		id: ads.id
	    });
	    setCaptchaDone(false);
	    setShowMsg('');
	    ads.visitable_number > 1 ? fetchVideoView() : handleEnd();
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    const fetchVideoView = async () => {
	try {
	    const { data } = await axiosInstance.get('ptc/video/start/');
	    setWidth(100);
	    setShowMsg('');
	    setAds(data);
	    setVideoStatus(-1);
	    setGuide(false);
	    return history.push(`/play/video/${data.info.code}/${data.info.uid_enc}/${data.info.token_enc}`);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const handleSkipVideo = async () => {
	try {
	    await axiosInstance.post('ptc/video/skip/', {
		id: ads.id
	    });
	    ads.visitable_number > 1 ? fetchVideoView() : handleNextEnd();
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const handleNextClick = () => {
	handleSkipVideo();
    };
    const handleEnd = () => {
	setEnd('Wonderful! You watched all the videos.');
	setTimeout(() => {
	    history.push('/video');
	}, 1500);
    };
    const handleNextEnd = () => {
	 setEnd('No video left!');
	 setTimeout(() => {
	    history.push('/video');
        }, 1500);
    };
    const handleCloseModal = () => {
	setIsOpen(!isOpen);
	return history.push('/video');
    };
    useEffect(() => {
	return () => {
	    if (history.action === 'POP') {
		history.go(1);
	    }
	};
    }, [history]);
    return (
	<VideoContainer>
	    <VideoHeader>
	        <VideoHeaderWrap>
	            {!showMsg && !guide && !apiError && !end &&
			<DetailWrap>
			    <Duration>
			        <span>
			            {adsDuration} sec
			        </span>
			    </Duration>
			    <DetailTitle>
			        <h1>{ads.title}</h1>
			    </DetailTitle>
			    <Pcoin>
			        <span>{ads.pcoin} pcoin</span>
			    </Pcoin>
			</DetailWrap>
		    }
	    	    {guide && !apiError &&
			<MsgWrap primary={1} style={{ width: '80%', margin: 'auto' }}>
			    <MsgText primary={1}>
				Play the video to start watching...
			    </MsgText>
			</MsgWrap>
		    }
	    	    {showMsg && !apiError && !end &&
			<MsgItem>
			    <MsgWrap primary={1}>
				<MsgText primary={1}>
				    {showMsg}
			        </MsgText>
			    </MsgWrap>
			    <TinyFormButton type='button' primary={1} big={0} onClick={!loading ? completeHandler : null}>
				{!loading ? 'done' : <Circle style={{ margin: 'auto', width: '15px', height: '15px' }} />}
			    </TinyFormButton>
			</MsgItem>
		    }
	    	    {end && !apiError &&
			<MsgWrap primary={1}>
			    <MsgText primary={1}>
				{end}
			    </MsgText>
			</MsgWrap>
		    }
	   	    {apiError &&
			<MsgItem>
			    <MsgWrap primary={0}>
				<MsgText primary={0}>
				    {apiError}
			        </MsgText>
			    </MsgWrap>
			    <TinyButton to={'/video'} primary={1} big={1}>
				return
			    </TinyButton>
			</MsgItem>
		    }
	    	</VideoHeaderWrap>
	        {!guide &&
		    <VideoButtonsWrap>
			<GridWrap>
			    <NumberWrap>
			        <span>
			            {!(ads.total_number - ads.visitable_number + 1) ? '' : ads.total_number - ads.visitable_number + 1}/{ads.total_number}
			        </span>
			    </NumberWrap>
			    <Next onClick={handleNextClick} />
			    <AdLinkWrap href={ads.url} target='_blank'>
			        <OpenAds />
			    </AdLinkWrap>
			    <LinkWrap to='/video'>
			        <Return />
			    </LinkWrap>
			</GridWrap>
		    </VideoButtonsWrap>
		}
	        {guide &&
		    <LinkWrap to='/video'>
			<Return />
		    </LinkWrap>
		}
	    </VideoHeader >
	    {width > 0 && <Bar width={width + '%'} />}
	    <VideoPlayWrap>
	        <iframe
	            id={ads.youtube}
	            src={`https://www.youtube.com/embed/${ads.youtube}?enablejsapi=1&rel=0`}
		    title={ads.title}
		    frameBorder='0'
		    allow='encrypted-media'
		    allowFullScreen
		    disablePictureInPicture
		    style={{ width: '100%', height: '100%' }}
	        />
	    </VideoPlayWrap>
	    {ads.description &&
		<VideoDescription>
		    <p>{ads.description}</p>
		</VideoDescription>
	    }
	    {isOpen &&
	    	<ModalContainer isOpen={isOpen ? 1 : 0}>
		    <ModalWrapper>
		        <CloseIconWrap onClick={() => handleCloseModal()}>
			    <CloseIcon />
		        </CloseIconWrap>
		        <IcefaCaptcha appName='video' setCaptchaDone={setCaptchaDone} dark={0} />
		        {cryptoCoinsAdInfo !== undefined && Object.keys(cryptoCoinsAdInfo).length > 0 &&
			    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
			        <iframe title='cryptocoinsad'
				    src={cryptoCoinsAdInfo.src}
				    style={{ width: `${cryptoCoinsAdInfo.width}px`, height: `${cryptoCoinsAdInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			        </iframe>
			    </div>
		        }
		        {sponsorData !== undefined && sponsorData.length > 0 &&
			    <div style={{ maxWidth: '600px', margin: '10px auto' }}>
			        <Sponsor data={sponsorData} />
			    </div>
		        }
		    </ModalWrapper>
	        </ModalContainer>
	    }
	</VideoContainer >
    )
}
export default VideoDetail;
