import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { SidebarContainer, SidebarWrapper } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { EditAdsForm, TitleWrap, ContentWrap, EditCard, EditInputWrap, EditLinkIcon, EditTextIcon, EditInput, InputTitleWrap, InputContentWrap, InputLangWrap, InputButton } from './EditInfoModalElements';
import { langData } from '../../data/langData';
import InteractiveMapEdit from './InteractiveMapEdit';
import { MsgWrap, MsgText } from '../MessageElements';
import { TinyFormButton } from '../ButtonElements';
import { ValidateEdit } from './ValidateEdit';
import { countriesData } from '../../data/countriesData';
import { useHistory } from 'react-router-dom';

const EditBannerModal = ({ editClick, setEditClick, id, CPI, url, language, country, countryName, title }) => {
    const history = useHistory();
    const [updateError, setUpdateError] = useState('');
    const [values, setValues] = useState(Object.freeze({
        language: language,
        title: title
    }));
    const [errors, setErrors] = useState({
        title: ''
    });
    const [alpha2, setAlpha2] = useState(country);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [complete, setComplete] = useState(false);
    const [langChanged, setLangChanged] = useState(false);
    const [countryChanged, setCountryChanged] = useState(false);
    const [titleChanged, setTitleChanged] = useState(false);
    const geoData = countriesData;
    const geoObjects = geoData.objects.ne_110m_admin_0_countries.geometries;
    const geoAllCountriesIso = geoObjects.map((geoAllCountry) => geoAllCountry.properties.ISO_A2);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setIsSubmitting(false);
        setErrors({
            title: ''
        });
        language !== undefined && name === 'language' && value !== language && setLangChanged(true);
        language !== undefined && name === 'language' && value === language && setLangChanged(false);
        name === 'title' && value !== title && setTitleChanged(true);
        name === 'title' && value === title && setTitleChanged(false);
    };

    const fetchEditViewAdsInfo = async () => {
        try {
            await axiosInstance.put('banner/control-ads/edit/', {
                id: id,
                target_language: values.language,
                target_country: alpha2 !== undefined ? alpha2 : geoAllCountriesIso,
                title: values.title
            });
            setComplete(true);
        } catch (error) {
            error.response && error.response.data.detail ? setUpdateError(error.response.data.detail) : setUpdateError(error.message);
        };
        setIsSubmitting(false);
    };

    const areEqual = (array1, array2) => {
        if (array1 !== undefined && array2 !== undefined) {
            if (array1.length === array2.length) {
                return array1.every(element => {
                    if (array2.includes(element)) {
                        return true;
                    }

                    return false;
                });
            };
            return false;
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateEdit(values));
        setIsSubmitting(true);
        JSON.stringify(alpha2) !== JSON.stringify(country) && setCountryChanged(true);
        areEqual(alpha2, country) && setCountryChanged(false) && setIsSubmitting(false);
    };

    useEffect(() => {
        if (isSubmitting
            && !complete
            && Object.keys(errors).length === 0
            && (alpha2 !== undefined ? alpha2.length > 0 : true)
            && (langChanged || countryChanged || titleChanged)
        ) {
            fetchEditViewAdsInfo();
            setTimeout(() => history.go(0), 500);
        };
    });
    return (
        <SidebarContainer isOpen={editClick ? 1 : 0}>
            <SidebarWrapper>
                <CloseIconWrap onClick={() => setEditClick(false)}>
                    <CloseIcon />
                </CloseIconWrap>
                <EditAdsForm action='#' onSubmit={handleSubmit}>
                    <TitleWrap>
                        <h3>CPI: {CPI}</h3>
                    </TitleWrap>
                    <ContentWrap>
                        <EditCard>
                            <EditInputWrap>
                                <EditLinkIcon />
                                <EditInput type='url' value={url} disabled />
                            </EditInputWrap>
                        </EditCard>
                        {language &&
                            <EditCard>
                                <InputTitleWrap>
                                    <h4>target language</h4>
                                </InputTitleWrap>
                                <InputContentWrap>
                                    <InputLangWrap>
                                        {langData.map((lang, index) => (
                                            <InputButton type='button' key={index} name='language' onClick={(e) => handleChange(e)} value={lang.value} itemActive={lang.value === values.language ? 1 : 0}>
                                                {lang.name}
                                            </InputButton>
                                        ))}
                                    </InputLangWrap>
                                </InputContentWrap>
                            </EditCard>
                        }
                        {country &&
                            <EditCard>
                                <InputTitleWrap>
                                    <h4>target country</h4>
                                </InputTitleWrap>
                                <InputContentWrap>
                                    <InteractiveMapEdit alpha2={alpha2} setAlpha2={setAlpha2} countryName={countryName} setIsSubmitting={setIsSubmitting} />
                                </InputContentWrap>
                                {alpha2 && alpha2.length === 0 &&
                                    <MsgWrap primary={0}>
                                        <MsgText primary={0}>
                                            You should select one country at least.
                                        </MsgText>
                                    </MsgWrap>
                                }
                            </EditCard>
                        }
                        <EditCard>
                            <InputTitleWrap>
                                <h4>edit title</h4>
                            </InputTitleWrap>
                            <EditInputWrap>
                                <EditTextIcon />
                                <EditInput type='text' name='title' onChange={handleChange} value={values.title} />
                            </EditInputWrap>
                            {errors.title &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        {errors.title}
                                    </MsgText>
                                </MsgWrap>
                            }
                        </EditCard>
                        {!complete && !updateError &&
                            <TinyFormButton type='submit' primary={1} big={1} style={{ marginBottom: '15px', width: '70%' }}>
                                Edit
                            </TinyFormButton>
                        }
                        {complete &&
                            <MsgWrap primary={1}>
                                <MsgText primary={1}>
                                    Your advertisement has been successfully edited.
                                </MsgText>
                            </MsgWrap>
                        }
                        {updateError &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {updateError}
                                </MsgText>
                            </MsgWrap>
                        }

                    </ContentWrap>
                </EditAdsForm>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default EditBannerModal;