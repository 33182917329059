import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginTabAction, regTabAction, activateCodeAction, confirmRegAction, resetPasswordAction, resetCodeAction, resetPasswordFormAction } from '../../actions/authAction';
import { BodyWrap, HeadWrap, LogoContainer, LogoWrap, TabContainer, TabWrap, LoginTab, RegisterTab } from '../Auth/AuthElements';
import { useLocation, useParams } from 'react-router-dom';
import { HeroContainer, HeroBg } from '../HeroSection/HeroElements';
import Authentication from '../../images/Auth_background.jpg';
import Logo from '../../images/logo-footer.png';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignUpCode from './SignUpCode';
import SuccessForm from './SuccessForm';
import ResetPass from './ResetPass';
import ResetCode from './ResetCode';
import ResetForm from './ResetForm';
import { authData, langAuthData } from '../../data/authData';

const Auth = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { code } = useParams();
    const loginTab = useSelector(state => state.loginTab);
    const regTab = useSelector(state => state.regTab);
    const activateCode = useSelector(state => state.activateCode);
    const confirmReg = useSelector(state => state.confirmReg);
    const resetPass = useSelector(state => state.resetPassword);
    const resetCode = useSelector(state => state.resetCode);
    const resetForm = useSelector(state => state.resetPasswordForm);
    const pageLang = localStorage.getItem('pageLang');
    const authDataLang = pageLang ? langAuthData.find(data => data.lang === pageLang).info : authData;

    return (
        <HeroContainer>
            <HeroBg>
                <img src={Authentication} alt='Authentication background' />
            </HeroBg>
            <BodyWrap>
                <HeadWrap>
                    <LogoContainer
                        to={location.pathname === '/auth'
                            ? '/'
                            : location.pathname === `/auth/ref/${code}`
                                ? `/ref/${code}`
                                : '#'
                        }
                        onClick={() => {
                            dispatch(loginTabAction({ prop: true }))
                            dispatch(regTabAction({ prop: false }));
                            dispatch(activateCodeAction({ prop: false }));
                            dispatch(confirmRegAction({ prop: false }));
                            dispatch(resetPasswordAction({ prop: false }));
                            dispatch(resetCodeAction({ prop: false }));
                            dispatch(resetPasswordFormAction({ prop: false }));
                        }}
                    >
                        <LogoWrap>
                            <img src={Logo} alt='IceFaucet Logo' />
                        </LogoWrap>
                        <p>IceFaucet<span>.com</span></p>
                    </LogoContainer>
                    <TabContainer>
                        <TabWrap>
                            <LoginTab
                                onClick={
                                    () => {
                                        dispatch(loginTabAction({ prop: true }));
                                        dispatch(regTabAction({ prop: false }));
                                        dispatch(activateCodeAction({ prop: false }));
                                        dispatch(confirmRegAction({ prop: false }));
                                        dispatch(resetPasswordAction({ prop: false }));
                                        dispatch(resetCodeAction({ prop: false }));
                                        dispatch(resetPasswordFormAction({ prop: false }));
                                    }}
                                loginTab={loginTab ? 1 : 0}
                            >
                                <p>{authDataLang.signin}</p>
                            </LoginTab>
                            <RegisterTab
                                onClick={
                                    () => {
                                        dispatch(loginTabAction({ prop: false }));
                                        dispatch(regTabAction({ prop: true }));
                                        dispatch(activateCodeAction({ prop: false }));
                                        dispatch(confirmRegAction({ prop: false }));
                                        dispatch(resetPasswordAction({ prop: false }));
                                        dispatch(resetCodeAction({ prop: false }));
                                        dispatch(resetPasswordFormAction({ prop: false }));
                                    }}
                                loginTab={loginTab ? 1 : 0}
                            >
                                <p>{authDataLang.signup}</p>
                            </RegisterTab>
                        </TabWrap>
                    </TabContainer>
                </HeadWrap>
                {
                    loginTab && !regTab && !resetPass && !resetCode && !resetForm && !activateCode && !confirmReg
                        ? <SignIn authDataLang={authDataLang} />
                        : !loginTab && regTab && !resetPass && !resetCode && !resetForm && !activateCode && !confirmReg
                            ? <SignUp authDataLang={authDataLang} />
                            : !loginTab && !regTab && resetPass && !resetCode && !resetForm && !activateCode && !confirmReg
                                ? <ResetPass authDataLang={authDataLang} />
                                : !loginTab && !regTab && !resetPass && resetCode && !resetForm && !activateCode && !confirmReg
                                    ? <ResetCode authDataLang={authDataLang} />
                                    : !loginTab && !regTab && !resetPass && !resetCode && resetForm && !activateCode && !confirmReg
                                        ? <ResetForm authDataLang={authDataLang} />
                                        : !loginTab && !regTab && !resetPass && !resetCode && !resetForm && activateCode && !confirmReg
                                            ? <SignUpCode authDataLang={authDataLang} />
                                            : !loginTab && !regTab && !resetPass && !resetCode && !resetForm && !activateCode && confirmReg
                                                ? <SuccessForm authDataLang={authDataLang} />
                                                : null
                }
            </BodyWrap>
        </HeroContainer>
    )
}

export default Auth;
