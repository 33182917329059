import styled from 'styled-components';
import { PromotionBody } from '../Promotions/ReferralLinkTabElements';
import device from '../../constants/breakPoints';

export const HistoryBody = styled(PromotionBody)`
    @media screen and ${device.sm} {
        margin-top: 220px;
    }
    @media screen and ${device.tn} {
        padding: 10px 3px;
    }
`
export const CountTotalContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    margin: 5px 0 20px 0;
`
export const CountTotalWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(29, 107, 187, 0.8);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    padding: 5px 7px 0 7px;
`
export const TitleWrap = styled.div`
    text-align: center;
    width: 100%;
    background: rgba(29, 107, 187, 1);
    h2 { 
        color: #01DBBB;
        font-size: clamp(0.8rem, 2.4vw, 1.1rem);
        font-weight: 700;
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }
`
export const DescWrap = styled.div`
    text-align: center;
    padding: 10px;
    h2 { 
        color: #ffffff;
        font-size: clamp(0.8rem, 2.4vw, 1.1rem);
        font-weight: 400;
    }
`
export const ImgCrypto = styled.img`
    width: 30px;
    height: 30px;
`
