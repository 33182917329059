export const homeObjOne = {
    id: 'make money',
    lightBg: true,
    blueText: false,
    topLine: 'Increase Your Online Earnings',
    headLine: 'Green Ways to Make Money Online',
    description: 'IceFaucet presents the highest conversion rate for offerwall and shortlink in the market. Keep your level up to get more earnings.',
    buttonLable: 'More Details',
    imgStart: true,
    img: 'https://icefaucet.com/media/help/blog/home1.png',
    alt: 'Make money image',
    darkText: true,
    primary: false,
    big: false,
    fontBig: false,
    link: 'make-money'
};
export const langHomeObjOne = [
    {
        lang: 'es',
        info: {
            id: 'make money',
            lightBg: true,
            blueText: false,
            topLine: 'Aumente sus ganancias en línea',
            headLine: 'Formas ecológicas de ganar dinero en línea',
            description: 'IceFaucet presenta la tasa de conversión más alta para Offerwall y shortlink del mercado. Mantenga su nivel alto para obtener más ganancias.',
            buttonLable: 'Más detalles',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home1.png',
            alt: 'imagen de ganar dinero',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'make-money'
        }
    },
    {
        lang: 'ru',
        info: {
            id: 'make money',
            lightBg: true,
            blueText: false,
            topLine: 'Увеличьте свой онлайн-заработок',
            headLine: 'Зеленые способы заработать деньги в Интернете',
            description: 'IceFaucet демонстрирует самый высокий на рынке коэффициент конверсии для Offerwall и коротких ссылок. Поддерживайте свой уровень, чтобы получать больше заработка.',
            buttonLable: 'Подробнее',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home1.png',
            alt: 'Заработай на картинке',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'make-money'
        }
    },
    {
        lang: 'pt',
        info: {
            id: 'make money',
            lightBg: true,
            blueText: false,
            topLine: 'Aumente seus ganhos on-line',
            headLine: 'Formas Verdes de Ganhar Dinheiro Online',
            description: 'IceFaucet apresenta a maior taxa de conversão para Offerwall e shortlink do mercado. Mantenha seu nível elevado para obter mais ganhos.',
            buttonLable: 'Mais detalhes',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home1.png',
            alt: 'Ganhar dinheiro imagem',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'make-money'
        }
    },
    {
        lang: 'fr',
        info: {
            id: 'make money',
            lightBg: true,
            blueText: false,
            topLine: 'Augmentez vos revenus en ligne',
	    headLine: "Façons vertes de gagner de l'argent en ligne",
	    description: "IceFaucet présente le taux de conversion pour Offerwall et shortlink le plus élevé du marché. Maintenez votre niveau pour obtenir plus de revenus.",
            buttonLable: 'Plus de détails',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home1.png',
            alt: "Image Gagner de l'argent",
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'make-money'
        }
    },
    {
        lang: 'de',
        info: {
            id: 'make money',
            lightBg: true,
            blueText: false,
            topLine: 'Steigern Sie Ihre Online-Einnahmen',
            headLine: 'Grüne Wege, um online Geld zu verdienen',
            description: 'IceFaucet bietet die höchste Conversion-Rate für Offerwall und Shortlink auf dem Markt. Halten Sie Ihr Level hoch, um mehr Einnahmen zu erzielen.',
            buttonLable: 'Mehr Details',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home1.png',
            alt: 'Bild Geld verdienen',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'make-money'
        }
    },
    {
        lang: 'it',
        info: {
            id: 'make money',
            lightBg: true,
            blueText: false,
            topLine: 'Aumenta i tuoi guadagni online',
            headLine: 'Modi verdi per fare soldi online',
            description: 'IceFaucet presenta il tasso di conversione più alto per Offerwall e shortlink sul mercato. Mantieni alto il tuo livello per ottenere più guadagni.',
            buttonLable: 'Più dettagli',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home1.png',
            alt: 'Guadagna immagine di denaro',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'make-money'
        }
    }
];
export const homeObjTwo = {
    id: 'advertise',
    lightBg: false,
    blueText: true,
    topLine: 'high-performance advertising',
    headLine: 'Exhibit Your Business Globally',
    description: 'This is an effective application for easy expansion of your business in the virtual world. You can restrict the circle of viewers until you shoot at the target point.',
    buttonLable: 'More Details',
    imgStart: false,
    img: 'https://icefaucet.com/media/help/blog/home2.png',
    alt: 'Advertisements image',
    darkText: false,
    primary: true,
    big: false,
    fontBig: false,
    link: 'advertise'
};

export const langHomeObjTwo = [
    {
        lang: 'es',
        info: {
            id: 'advertise',
            lightBg: false,
            blueText: true,
            topLine: 'publicidad de alto rendimiento',
            headLine: 'Exponga su negocio a nivel mundial',
            description: 'Esta es una aplicación efectiva para la fácil expansión de su negocio en el mundo virtual. Puede restringir el círculo de espectadores hasta que dispare al punto objetivo.',
            buttonLable: 'Más detalles',
            imgStart: false,
            img: 'https://icefaucet.com/media/help/blog/home2.png',
            alt: 'imagen de anuncios',
            darkText: false,
            primary: true,
            big: false,
            fontBig: false,
            link: 'advertise'
        }
    },
    {
        lang: 'ru',
        info: {
            id: 'advertise',
            lightBg: false,
            blueText: true,
            topLine: 'высокоэффективная реклама',
            headLine: 'Расширьте свой бизнес по всему миру',
            description: 'Это эффективное приложение для легкого расширения вашего бизнеса в виртуальном мире. Вы можете ограничить круг зрителей, пока не выстрелите в целевую точку.',
            buttonLable: 'Подробнее',
            imgStart: false,
            img: 'https://icefaucet.com/media/help/blog/home2.png',
            alt: 'Рекламное изображение',
            darkText: false,
            primary: true,
            big: false,
            fontBig: false,
            link: 'advertise'
        }
    },
    {
        lang: 'pt',
        info: {
            id: 'advertise',
            lightBg: false,
            blueText: true,
            topLine: 'publicidade de alto desempenho',
            headLine: 'Exponha seu negócio globalmente',
            description: 'Este é um aplicativo eficaz para a fácil expansão do seu negócio no mundo virtual. Você pode restringir o círculo de visualizadores até atirar no ponto alvo.',
            buttonLable: 'Mais detalhes',
            imgStart: false,
            img: 'https://icefaucet.com/media/help/blog/home2.png',
            alt: 'imagem de anúncios',
            darkText: false,
            primary: true,
            big: false,
            fontBig: false,
            link: 'advertise'
        }
    },
    {
        lang: 'fr',
        info: {
            id: 'advertise',
            lightBg: false,
            blueText: true,
            topLine: ' haute performance publicité',
            headLine: "Exposez votre entreprise à l'échelle mondiale",
            description: "Il s'agit d'une application efficace pour l'expansion facile de votre entreprise dans le monde virtuel. Vous pouvez restreindre le cercle des téléspectateurs jusqu'à ce que vous tiriez au point cible.",
            buttonLable: 'Plus de détails',
            imgStart: false,
            img: 'https://icefaucet.com/media/help/blog/home2.png',
            alt: 'Image des publicités',
            darkText: false,
            primary: true,
            big: false,
            fontBig: false,
            link: 'advertise'
        }
    },
    {
        lang: 'de',
        info: {
            id: 'advertise',
            lightBg: false,
            blueText: true,
            topLine: 'Hochleistungswerbung',
            headLine: 'Stellen Sie Ihr Unternehmen weltweit aus',
            description: 'Dies ist eine effektive Anwendung für die einfache Erweiterung Ihres Geschäfts in der virtuellen Welt. Sie können den Kreis der Zuschauer einschränken, bis Sie auf den Zielpunkt schießen.',
            buttonLable: 'Mehr Details',
            imgStart: false,
            img: 'https://icefaucet.com/media/help/blog/home2.png',
            alt: 'Werbebild',
            darkText: false,
            primary: true,
            big: false,
            fontBig: false,
            link: 'advertise'
        }
    },
    {
        lang: 'it',
        info: {
            id: 'advertise',
            lightBg: false,
            blueText: true,
            topLine: 'pubblicità ad alte prestazioni',
            headLine: 'Esponi la tua attività a livello globale',
            description: "Questa è un'applicazione efficace per la facile espansione della tua attività nel mondo virtuale. Puoi restringere la cerchia degli spettatori fino a quando non spari al punto bersaglio.",
            buttonLable: 'Più dettagli',
            imgStart: false,
            img: 'https://icefaucet.com/media/help/blog/home2.png',
            alt: 'Immagine pubblicitaria',
            darkText: false,
            primary: true,
            big: false,
            fontBig: false,
            link: 'advertise'
        }
    }
];

export const homeObjThree = {
    id: 'statistics',
    lightBg: true,
    blueText: false,
    topLine: 'Real Graphical Statistics',
    headLine: 'Analyse Yourself Based on True Information',
    description: 'It is your right to have access to real data so that you can make the right decision. Click the button below to access them easily.',
    buttonLable: 'Check out',
    imgStart: true,
    img: 'https://icefaucet.com/media/help/blog/home3.png',
    alt: 'statistics image',
    darkText: true,
    primary: false,
    big: false,
    fontBig: false,
    link: 'statistics'
};

export const langHomeObjThree = [
    {
        lang: 'es',
        info: {
            id: 'statistics',
            lightBg: true,
            blueText: false,
            topLine: 'Estadísticas Gráficas Reales',
            headLine: 'Analícese a sí mismo con base en información verdadera',
            description: 'Es su derecho tener acceso a datos reales para que pueda tomar la decisión correcta. Haga clic en el botón de abajo para acceder a ellas fácilmente.',
            buttonLable: 'Verificar',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home3.png',
            alt: 'Imagen de estadísticas',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'statistics'
        }
    },
    {
        lang: 'ru',
        info: {
            id: 'statistics',
            lightBg: true,
            blueText: false,
            topLine: 'Реальная графическая статистика',
            headLine: 'Проанализируйте себя на основе достоверной информации',
            description: 'Это ваше право иметь доступ к реальным данным, чтобы вы могли принять правильное решение. Нажмите кнопку ниже, чтобы легко получить к ним доступ.',
            buttonLable: 'проверять',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home3.png',
            alt: 'Статистическое изображение',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'statistics'
        }
    },
    {
        lang: 'pt',
        info: {
            id: 'statistics',
            lightBg: true,
            blueText: false,
            topLine: 'Estatísticas Gráficas Reais',
            headLine: 'Analise-se com base em informações verdadeiras',
            description: 'É seu direito ter acesso a dados reais para que você possa tomar a decisão certa. Clique no botão abaixo para acessá-los facilmente.',
            buttonLable: 'Confira',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home3.png',
            alt: 'imagem estatística',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'statistics'
        }
    },
    {
        lang: 'fr',
        info: {
            id: 'statistics',
            lightBg: true,
            blueText: false,
            topLine: 'Statistiques graphiques réelles',
            headLine: "Analysez-vous sur la base d'informations vraies",
            description: "C'est votre droit d'avoir accès à des données réelles afin que vous puissiez prendre la bonne décision. Cliquez sur le bouton ci-dessous pour y accéder facilement.",
            buttonLable: 'vérifier',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home3.png',
            alt: 'Image statistique',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'statistics'
        }
    },
    {
        lang: 'de',
        info: {
            id: 'statistics',
            lightBg: true,
            blueText: false,
            topLine: 'Echte grafische Statistiken',
            headLine: 'Analysieren Sie sich selbst basierend auf wahren Informationen',
            description: 'Es ist Ihr Recht, Zugang zu echten Daten zu haben, damit Sie die richtige Entscheidung treffen können. Klicken Sie auf die Schaltfläche unten, um einfach darauf zuzugreifen.',
            buttonLable: 'Kasse',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home3.png',
            alt: 'Statistikbild',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'statistics'
        }
    },
    {
        lang: 'it',
        info: {
            id: 'statistics',
            lightBg: true,
            blueText: false,
            topLine: 'Statistiche grafiche reali',
            headLine: 'Analizza te stesso sulla base di informazioni vere',
            description: 'È tuo diritto avere accesso a dati reali in modo da poter prendere la decisione giusta. Fai clic sul pulsante in basso per accedervi facilmente.',
            buttonLable: 'Guardare',
            imgStart: true,
            img: 'https://icefaucet.com/media/help/blog/home3.png',
            alt: 'Immagine statistica',
            darkText: true,
            primary: false,
            big: false,
            fontBig: false,
            link: 'statistics'
        }
    }
];
