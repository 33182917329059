import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContentWrap, Card, ImgWrap } from './HelpDeskElements';
import Blog from './Blog';
import PlayList from './PlayList';
import FAQ from './FAQ';
import { Container, Wrapper, Header, LeftWrap, LogoWrapLink, SubjectWrap, Abstract } from '../TOS/TOSElements';
import logo from '../../images/logo.png';
import { TinyFormButton } from '../ButtonElements';

const HelpDesk = ({ code, info, BlogCategories, PlayListCategories, FAQButtons }) => {
    const location = useLocation();
    const [indexHelp, setIndexHelp] = useState();
    const [categoryBlog, setCategoryBlog] = useState();
    const [categoryPlayList, setCategoryPlayList] = useState();
    const [category, setCategory] = useState();
    const [indexCategory, setIndexCategory] = useState();
    const handleHelp = (index) => {
        if (indexHelp !== index) {
            setIndexHelp(index);
        };
        setCategoryBlog();
        setCategoryPlayList();
        setCategory();
        setIndexCategory();
    };
    return (
        <Container>
            <Wrapper>
                <Header>
                    <LeftWrap>
                        <LogoWrapLink
                            to={location.pathname === '/help'
                                ? '/'
                                : location.pathname === `/info/ref/${code}/help`
                                    ? `/ref/${code}`
                                    : '#'
                            }
                        >
                            <img src={logo} alt={'logo of icefaucet'} />
                        </LogoWrapLink>
                    </LeftWrap>
                    <SubjectWrap>
                        <h1>{info.subject}</h1>
                    </SubjectWrap>
                </Header>
                <Abstract>
                    <p>{info.abstract}</p>
                </Abstract>
                <ContentWrap>
                    {info.card.map((card, index) => (
                        <Card key={index}>
                            <ImgWrap primary={index !== indexHelp ? 1 : 0}>
                                <img src={card.img} alt={card.alt} />
                            </ImgWrap>
                            <TinyFormButton type='button' onClick={() => handleHelp(index)} primary={index !== indexHelp ? 1 : 0} big={false} style={{ width: '90%' }}>{card.button}</TinyFormButton>
                        </Card>
                    ))}
                </ContentWrap>
                {indexHelp !== undefined && indexHelp === 0 &&
                    <Blog categoryBlog={categoryBlog} setCategoryBlog={setCategoryBlog} BlogCategories={BlogCategories} />
                }
                {indexHelp !== undefined && indexHelp === 1 &&
                    <PlayList categoryPlayList={categoryPlayList} setCategoryPlayList={setCategoryPlayList} PlayListCategories={PlayListCategories} />
                }
                {indexHelp !== undefined && indexHelp === 2 &&
                    <FAQ category={category} setCategory={setCategory} indexCategory={indexCategory} setIndexCategory={setIndexCategory} FAQButtons={FAQButtons} />
                }
            </Wrapper>
        </Container>
    )
}
export default HelpDesk