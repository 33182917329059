import React, { useEffect, useState } from 'react';
import PtcView from '../components/PtcView';
import axiosInstance from '../axios';
import { useParams } from 'react-router-dom';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { PtcHeaderWrap, PtcBackPage, PtcNextPage, BackIcon, NextIcon, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../components/PtcView/PtcViewElements';
import { DescWrap } from '../components/History/DepositTabElements';
import { ProgressBarWrap, ProgressBar } from '../components/Redeem/RedeemElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';

const PTCPage = () => {
    const params = useParams();
    const pageNumber = params.pageNumber ? parseInt(params.pageNumber) : 1;
    const [ptcInfo, setPtcInfo] = useState();
    const [loading, setLoading] = useState(pageNumber !== 1 ? false : true);
    const [error, setError] = useState('');
    useEffect(() => {
        const fetchPtcView = async () => {
	    pageNumber !== 1 && setLoading(true);
            try {
                const { data } = await axiosInstance.get(`ptc/view/?page=${pageNumber}`);
                setPtcInfo(data);
                localStorage.setItem('ptc_page_number', pageNumber);
            } catch (error) {
                error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            };
            setLoading(false);
        };
        pageNumber !== undefined && fetchPtcView();
    }, [pageNumber]);
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
            {!error && !loading &&
                <PtcHeaderWrap>
                    {pageNumber !== 1 &&
                        <PtcBackPage to={`${pageNumber - 1}`}>
                            <BackIcon />
                        </PtcBackPage>
                    }
                    <PtcInfoWrap>
                        <InfoContainer>
                            <InfoWrap>
                                <TitleInfoWrap>
                                    <h2>number ads</h2>
                                </TitleInfoWrap>
                                <DescWrap>
                                    <h2>{ptcInfo !== undefined && ptcInfo.all_info.number}</h2>
                                </DescWrap>
                            </InfoWrap>
                            <InfoWrap>
                                <TitleInfoWrap>
                                    <h2>total primecoins</h2>
                                </TitleInfoWrap>
                                <DescWrap>
                                    <h2>{ptcInfo !== undefined && ptcInfo.all_info.total}</h2>
                                </DescWrap>
                            </InfoWrap>
                        </InfoContainer>
                        <ProgressBarWrap >
                            <ProgressBar width={ptcInfo !== undefined && ptcInfo.all_info.number > 0 ? (String(ptcInfo.all_info.visitable_number / ptcInfo.all_info.number * 100) + '%') : 0}>
                                {ptcInfo !== undefined && ptcInfo.all_info.number > 0 && (ptcInfo.all_info.visitable_number / ptcInfo.all_info.number * 100) >= 10 &&
                                    <span>{Math.ceil(ptcInfo.all_info.visitable_number / ptcInfo.all_info.number * 100)}%</span>
                                }
                            </ProgressBar>
                        </ProgressBarWrap>
                    </PtcInfoWrap>
                    {ptcInfo !== undefined && ptcInfo.is_next &&
                        <PtcNextPage to={`${pageNumber + 1}`}>
                            <NextIcon />
                        </PtcNextPage>
                    }
                </PtcHeaderWrap>
            }
	    {!loading && !error && pageNumber === 1 &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
			<StackDiscWrap>
			    <p>
			        Please disable your "AdBlock" extensions for the iceFaucet. <br />
			        Please allow the "Pop-ups and redirects" to the iceFaucet on your browser settings. <br />
			       	Anywhere, whenever you encounter a "timeout" error because of your weak internet, just reload the page. <br />
			        Boost your earnings by upgrading your account.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer>
	    }
            {ptcInfo !== undefined && !error && ptcInfo.info.map((ads, index) => (
                <PtcView ads={ads} key={index + 1 + ((pageNumber - 1) * 7)} />
            ))
            }
            {error &&
                <MsgWrap primary={0} style={{ width: '80%', margin: ' 15px auto' }}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
        </>
    )
}
export default PTCPage;
