import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.2);
    padding: 25px;

    @media screen and ${device.tn} {
        padding: 25px 15px;
    }
`
export const Wrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 10px;
`
export const Table = styled.table`
    border-collapse: collapse;
    border-left: 2px solid rgba(29, 107, 187, 0.7);
    border-right: 2px solid rgba(29, 107, 187, 0.7);
    width: 90%;
    margin: 15px 0;
`
export const HeadWrap = styled.thead`
    width: 100%;
`
export const HeadRow = styled.tr`
    width: 100%;
`
export const Header = styled.th`
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.65rem, 3.5vw, 0.85rem);
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 1.7px;
    text-transform: uppercase;
    padding: 10px 3px;

    &:nth-child(1) {
        background: rgba(39, 117, 85, 1);
    }
    &:nth-child(2) {
        background: rgba(29, 107, 187, 0.5);
    }
    &:nth-child(3) {
        background: rgba(29, 107, 187, 0.75);
    }
    &:nth-child(4) {
        background: rgba(29, 107, 187, 1);
    }
`
export const BodyWrap = styled.tbody``
export const BodyRow = styled.tr`
`
export const Cell = styled.td`
    border-bottom: 2px solid rgba(29, 107, 187, 0.7);
    text-align: center;
    color: #fff;
    font-size: clamp(0.7rem, 3.5vw, 0.85rem);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    padding: 7px 3px;

    &:nth-child(1) {
        background: rgba(39, 117, 85, 0.4);
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
    }
    &:nth-child(2) {
        background: rgba(29, 107, 187, 0.2);
    }
    &:nth-child(3) {
        background: rgba(29, 107, 187, 0.4);
    }
    &:nth-child(4) {
        background: rgba(29, 107, 187, 0.6);
    }
`
export const MembershipContainer = styled.div`
    width: 90%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr ;
    margin-top: 10px;
    padding: 10px 0;

    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
        grid-row-gap: 30px;
    }
`
export const MembershipWrap = styled.div`
    background: rgba(29, 107, 187, 0.5);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 2px 10px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    width: 180px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;

    @media screen and ${device.lg} {
        width: 120px;
    }

    &:nth-child(1) {
        background: rgba(29, 107, 187, 0.2);
    }
    &:nth-child(2) {
        background: rgba(29, 107, 187, 0.4);
    }
    &:nth-child(3) {
        background: rgba(29, 107, 187, 0.6);
    }
`
export const ImageWrap = styled.div`
    width: 90px;
    height: 90px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(39, 117, 85, 0.5);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.7);
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;

    @media screen and ${device.lg} {
        width: 80px;
        height: 80px;
    }
`
export const Image = styled.img`
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 50%;
`
export const SelectWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    margin-top: 10px;
`
export const Button = styled.button`
    width: 100px;
    background: ${({ active }) => (active ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    border-radius: 50px;
    white-space: nowrap;
    color: #fff;
    font-size: 0.7rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
    cursor: pointer;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ active }) => (active ? 'rgba(29, 107, 187, 1)' : ' rgba(39, 117, 85, 1)')};
        box-shadow: 0 0 5px ${({ active }) => (active ? '#1D6BBB' : '#277555')};
        letter-spacing: 1px;
        opacity: 1;
    }
`
