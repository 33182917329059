import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const ContentWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`
export const TimePriceWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    @media screen and ${device.sm} {
        flex-direction: column;
    }
`
export const TimeWrap = styled.div`
    width: 50%;
    background: ${({ red }) => (red ? 'rgba(255, 0, 0, 0.4)' : 'rgba(29, 107, 187, 0.4)')};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    @media screen and ${device.sm} {
        width: 100%;
    }
`
export const TimerDeposit = styled.span`
    font-size: clamp(1rem, 3vw, 1.4rem);
    color: ${({ red }) => (red ? 'rgb(240, 75, 75)' : '#01DBBB')};
    font-weight: 400;
    letter-spacing: 1.3px;
    word-spacing: 4px;
    text-transform: uppercase;
`
export const PriceWrap = styled.div`
    width: 50%;
    background: rgba(39, 117, 85, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    @media screen and ${device.sm} {
        width: 100%;
    }

    h2 {
        font-size: clamp(1rem, 3vw, 1.4rem);
        color: #01DBBB;
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 4px;
        text-transform: uppercase;
    }
`
export const InstructionWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    padding: 0 15px;
    p {
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: #ffffff;
        letter-spacing: 1.1px;
        word-spacing: 1.7px;
        line-height: 1.45;
    }
`
export const ValueAddressWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
`
export const ItemContainer = styled.div`
    width: 75%;
    display: grid;
    grid-template-columns: 11fr 1fr;
    grid-column-gap: 5px;
    margin-bottom: 10px;
`
export const ItemWrap = styled.div`
    width: 100%;
    height: 30px;
    background: rgba(29, 107, 187, 0.4);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    h3 {
        font-size: clamp(0.9rem, 2.1vw, 1rem);
        color: #ffffff;
        font-weight: 400;
        letter-spacing: 1.1px;
        word-spacing: 1.7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`