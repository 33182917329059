import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { PromotionContainer, PromotionBody, RefTableContainer, RefTableCell, LinksTableContainer, LinksTableImg, LinksTableDetail, RefButton } from './ReferralLinkTabElements';
import { promotionRefTable, promotionLinksTable } from '../../data/promotionData';

const ReferralLinkTab = () => {
    let history = useHistory();
    const [info, setInfo] = useState();
    const [copied, setCopied] = useState(false);
    const [linkIndex, setLinkIndex] = useState();
    const fetchRefData = async () => {
        const { data } = await axiosInstance.get('ref/ref-data/');
        setInfo(data);
    };
    useEffect(() => {
        if (info === undefined) {
            fetchRefData();
        };
    });
    const handleView = (e, item, index) => {
        e.preventDefault();
        if (info !== undefined) {
            history.push(index === 0 ? `ref/${info.ref_code}` : `lp/${item.number}/${info.ref_code}`);
        };
    };
    const handleCopy = (item, index) => {
        linkIndex !== index && setLinkIndex(index);
        if (navigator.clipboard && window.isSecureContext) {
            setCopied(true);
            return navigator.clipboard.writeText(index === 0 ? `https://icefaucet.com/ref/${info.ref_code}` : `https://icefaucet.com/lp/${item.number}/${info.ref_code}`);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = index === 0 ? `https://icefaucet.com/ref/${info.ref_code}` : `https://icefaucet.com/lp/${item.number}/${info.ref_code}`;
            textArea.style.position = 'absolute';
            textArea.style.opacity = 0
            document.body.appendChild(textArea);
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopied(true);
            });
        };
    };
    return (
        <PromotionContainer>
            <PromotionBody>
                <p>
                    If you are a digital marketer or website owner,
                    you could earn easily by inviting people from all over the world.
                    You could earn based on the below table:
                </p>
                <RefTableContainer>
                    {promotionRefTable.map((item, index) => (
                        <RefTableCell key={index}>
                            <p>{item.detail}</p>
                        </RefTableCell>
                    ))}
                </RefTableContainer>
                <p>
                    With the following simple links, you can invite earners and advertisers:
                </p>
                {promotionLinksTable.map((item, index) => (
                    <LinksTableContainer key={index}>
                        <LinksTableImg primary={index % 2 === 0 ? 1 : 0}>
                            <img src={item.src} alt={item.alt} />
                        </LinksTableImg>
                        <LinksTableDetail primary={index % 2 === 0 ? 1 : 0}>
                            <span>{item.client}</span>
                            <RefButton to='#'
                                onClick={(e) => handleView(e, item, index)}
                            >
                                view
                            </RefButton>
                            <RefButton onClick={() => handleCopy(item, index)}>
                                {copied && linkIndex === index ? 'copied' : 'copy'}
                            </RefButton>
                        </LinksTableDetail>
                    </LinksTableContainer>
                ))}
            </PromotionBody>
        </PromotionContainer >
    )
}
export default ReferralLinkTab;