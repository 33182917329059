export const stackTableHead = [
    'level',
    'annual profit',
    'upcode',
    'icecoin requirement'
];

export const stackTableRow = [
    {
        'level': '1',
        'profit': '0',
        'upcode': '2%',
        'requirement': 'no need'
    },
    {
        'level': '2',
        'profit': '10%',
        'upcode': '3%',
        'requirement': '10000'
    },
    {
        'level': '3',
        'profit': '12%',
        'upcode': '4%',
        'requirement': '50000'
    },
    {
        'level': '4',
        'profit': '14%',
        'upcode': '5%',
        'requirement': '100000'
    },
    {
        'level': '5',
        'profit': '17%',
        'upcode': '7%',
        'requirement': '300000'
    },
    {
        'level': 'master-node',
        'profit': '20%',
        'upcode': '10%',
        'requirement': '500000'
    }
];
export const stackTimeData = [
    {
        'value': 3,
        'name': '3 months'
    },
    {
        'value': 6,
        'name': '6 months'
    },
    {
        'value': 12,
        'name': '12 months'
    },
];
export const refrUserTableHead = [
    'profit (icecoin)',
    'refr type',
    'refr staus',
    'create'
];
export const refrUserStatus = [
    {
	'level': 1,
	'up': 'level 1',
	'icecoin': 0,
	'next_round': 10000,
	'down': '0'
    },
    {
	'level': 2,
	'up': 'level 2',
	'icecoin': 10000,
	'next_round': 50000,
	'down': '+10%'
    },
    {
	'level': 3,
	'up': 'level 3',
	'icecoin': 50000,
	'next_round': 100000,
	'down': '+12%'
    },
    {
	'level': 4,
	'up': 'level 4',
	'icecoin': 100000,
	'next_round': 300000,
	'down': '+14%'
    },
    {
	'level': 5,
	'up': 'level 5',
	'icecoin': 300000,
	'next_round': 500000,
	'down': '+17%'
    },
    {
	'level': 6,
	'up': 'M.N.',
	'icecoin': 500000,
	'down': '+20%'
    }
];
