import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { HelperContainer, HelperWrap, HelperImgWrap, HelperImg, HelperNameWrap, HelperName, HelperMedalWrap, MedalWrap, Gold, Silver, Bronze, HelperEditWrap, Free } from './AssistantCardElements';
import male from '../../images/male_avatar.svg';
import female from '../../images/female_avatar.svg';
import moment from 'moment';
import { Text } from './SLViewElements';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { Circle } from '../Loading';

const AssistantCard = ({ info, assisClicked, setAssisClicked, index, slCode }) => {
    let history = useHistory();
    const [assisIndex, setAssisIndex] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState('');
    const handleAssistantClick = (index) => {
	setAssisIndex(index);
	info.pcoin > 0 && !info.purchased && !info.owner ? setAssisClicked(true) : history.push(`/shortlink/assistant/access/${slCode}/${info.code}/`);
    };
    const handlePay = () => {
	fetchPayAssistance();
    };
    const fetchPayAssistance = async () => {
	setLoading(true);
	try {
	    await axiosInstance.post('shortlink/assistant/pay/', { 'helper': info.id, 'code': info.code });
	    history.push(`/shortlink/assistant/access/${slCode}/${info.code}/`);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
        setLoading(false);
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    return (
	<>
	    {!assisClicked &&
		<HelperContainer onClick={() => handleAssistantClick(index)}>
		    <HelperWrap>
		        <HelperImgWrap>
		            <HelperImg src={info.profile.photo ? info.profile.photo : info.gender === 'F' ? female : male} alt={'profile of user'} />
		    	</HelperImgWrap>
		    	<HelperNameWrap>
		            <HelperName>{info.profile.username ? info.profile.username : info.id}</HelperName>
		            <HelperMedalWrap>
		    		{info.gold > 0 &&
		                    <MedalWrap>
		                        <Gold />
		                        <span>{info.gold}</span>
		                    </MedalWrap>
				}
		    		{info.silver > 0 &&
		                    <MedalWrap>
		                        <Silver />
		                        <span>{info.silver}</span>
		                    </MedalWrap>
				}
		    		{info.bronze > 0 &&
		                    <MedalWrap>
		                        <Bronze />
		                        <span>{info.bronze}</span>
		                    </MedalWrap>
				}
		            </HelperMedalWrap>
		            <HelperEditWrap>
		                <span>{moment(info.edited).fromNow()}</span>
		                {info.pcoin === 0 ? <Free>free</Free> : null}
		            </HelperEditWrap>
		    	    {info.purchased &&
				<HelperName>paid</HelperName>
			    }
		            {info.owner &&
				<HelperName>author</HelperName>
			    }
		        </HelperNameWrap>
		    </HelperWrap>
	        </HelperContainer>
	    }
	    {assisClicked && index === assisIndex && info.pcoin > 0 &&
	        <>
		    <Text>To view this assistance pay {info.pcoin} pc to {info.profile.username ? info.profile.username : info.id}. You can always use it.</Text>
		    <TinyFormButton
		        style={{ margin: '15px auto' }}
		        primary={1}
		        onClick={handlePay}
		        onContextMenu={handleRightClick}
		        big={0}
		    >
		        {!loading ? 'pay' : <Circle style={{ margin: 'auto', width: '15px', height: '15px' }} />}
		    </TinyFormButton>
	        </>
	    }
	    {apiError &&
	        <MsgWrap primary={0} style={{ width: '80%', margin: '10px auto' }}>
		    <MsgText primary={0}>
		        {apiError}
		    </MsgText>
	        </MsgWrap>
	    }
        </>
    )
}
export default AssistantCard;

