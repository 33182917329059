import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const Container = styled.section`
    width: 100%;
    min-height: 100vh;
    background: ${({ dark }) => (dark ? '#010606' : '#f9f9f9')};
    padding: 80px 0;
`
export const Wrapper = styled.div`
    width: 100%;
    background: rgba(29, 107, 187, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
`
export const CardWrapper = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 10px;

    @media screen and ${device.md} {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 20px;
    }

    
    @media screen and ${device.sm} {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    }
`
export const Card = styled.div`
    width: 200px;
    height: 120px;
    background: rgba(29, 107, 187, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(9, 107, 187, 0.7);
    box-shadow: 0 0 10px rgba(29, 107, 187, 1);
    margin: auto;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        background: rgba(29, 107, 187, 0.6);
        transition: all 0.2s ease-in-out;
    }
`
export const TitleWrap = styled.div`
    width: 100%;
    background: rgba(29, 107, 187, 0.7);
    text-align: center;

    h4 {
        color: #01DBBB;
        font-size: clamp(0.85rem, 2vw, 1.05rem);
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 2px;
        text-transform: uppercase;
    }
`
export const ContentWrap = styled.div`
    width: 100%;
    text-align: center;
    margin: auto;

    h4 {
        color: #fff;
        font-size: clamp(1.2rem, 2vw, 1.7rem);
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 2px;
        text-transform: uppercase;
        text-shadow: 0 1px 3px #000;
    }
`