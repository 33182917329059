import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sponsorFiltersAction, sponsorSetUpEndAction, sponsorSuccessAction } from '../../actions/sponsorSetUpAction';
import axiosInstance from '../../axios';
import { PtcForm, PtcFormInputContainer } from '../SetUpPtc/SetUpPtcFormElements';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements';
import { FilterLangWrap } from '../SetUpPtc/SetUpPtcFormElements.js';
import { countriesData } from '../../data/countriesData';
import { TinyFormButton } from "../ButtonElements";
import { FormButtonsWrap } from '../SetUpPtc/CreditCheckElements';
import SponsorWatch from './SponsorWatch';
import { MsgText, MsgWrap } from '../MessageElements';

const SetUpSponsorEnd = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [autoplay, setAutoplay] = useState(false);
    const [watch, setWatch] = useState(false);
    const [error, setError] = useState();
    const lang = localStorage.getItem('lang') ? 0.1 : 0;
    const country = localStorage.getItem('target_country') ? 0.25 : 0;
    let imp = parseInt(localStorage.getItem('impression'));
    const totalCost = Math.floor(imp * (lang + country + 1) * 0.5);
    const upcodeValue = Math.floor(imp * (parseFloat(localStorage.getItem('upcode')) - 1.00));
    const remainAdscoin = localStorage.getItem('adcoin') - totalCost;
    const geoData = countriesData;
    const geoObjects = geoData.objects.ne_110m_admin_0_countries.geometries;
    const geoAllCountriesIso = geoObjects.map((geoAllCountry) => geoAllCountry.properties.ISO_A2);
    const handleSubmit = (e) => {
        e.preventDefault();
        !watch && setError('You should validate your ads first.');
        setIsSubmitting(true);
    };
    const handleBack = () => {
        dispatch(sponsorSetUpEndAction({ prop: false }));
        dispatch(sponsorFiltersAction({ prop: true }));
    };
    const fetchSponsorAds = async () => {
        const { data } = await axiosInstance.post('sponsor/setup/', {
            target_language: localStorage.getItem('lang') ? localStorage.getItem('lang') : null,
            impression: imp,
            url: localStorage.getItem('url'),
            title: localStorage.getItem('title'),
            description: localStorage.getItem('description') ? localStorage.getItem('description') : null,
            target_country: localStorage.getItem('target_country') ? JSON.parse(localStorage.getItem('target_country')) : geoAllCountriesIso,
            status: autoplay ? 'P' : 'S'
        });
        if (data.status) {
            localStorage.removeItem('lang');
            localStorage.removeItem('impression');
            localStorage.removeItem('url');
            localStorage.removeItem('title');
            localStorage.removeItem('description');
            localStorage.removeItem('target_country');
            localStorage.removeItem('adcoin');
            localStorage.removeItem('upcode');
            dispatch(sponsorSetUpEndAction({ prop: false }));
            dispatch(sponsorSuccessAction({ prop: true }));
        };
    }
    useEffect(() => {
        if (isSubmitting && watch) {
            fetchSponsorAds();
        };
    });
    return (
        <>
            <PtcForm action='#' onSubmit={handleSubmit}>
                <PtcFormInputContainer>
                    <FormHeader>
                        <h2>watch your ads as a user</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                See how users could watch your ads well.
                            </Instruction>
                        </InstructionWrap>
                        <FilterLangWrap>
                            <ItemWrap >
                                <ItemName onClick={() => { setWatch(true); setIsSubmitting(false); setError(''); }} itemActive={watch ? 1 : 0}>
                                    watch
                                </ItemName>
                            </ItemWrap>
                        </FilterLangWrap>
                        {watch && <SponsorWatch />}
                        {error &&
                            <MsgWrap>
                                <MsgText>
                                    {error}
                                </MsgText>
                            </MsgWrap>
                        }
                    </FilterContainer>
                    <FormHeader>
                        <h2>cost of your ads</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                CPI (Cost Per Impression) = <strong>0.5 adscoin</strong><br />
                                Target language filter = <strong>{lang === 0.1 ? 'enabled (+0.10% CPI)' : 'disabled'}</strong><br />
                                Target country filter = <strong>{country === 0.25 ? 'enabled (+0.25% CPI)' : 'disabled'}</strong><br />
                                Number of impressions = <strong>{imp} impressions</strong><br />
                                The total cost of your ads  = <strong>{totalCost} adscoin</strong> <br />
                                Remain your adscoin = <strong>{remainAdscoin} adscoin</strong> <br />
                                Number of upcode impressions as gift = {totalCost >= 250000 ? <strong>{upcodeValue} impressions</strong> : 0}
                            </Instruction>
                        </InstructionWrap>
                    </FilterContainer>
                    <FormHeader>
                        <h2>autoplay your ads</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                If you want to show your ads instantly after acceptance in the monitoring process, press the following button.
                            </Instruction>
                        </InstructionWrap>
                        <FilterLangWrap>
                            <ItemWrap >
                                <ItemName onClick={() => setAutoplay(!autoplay)} itemActive={autoplay ? 1 : 0}>
                                    Accept
                                </ItemName>
                            </ItemWrap>
                        </FilterLangWrap>
                    </FilterContainer>
                </PtcFormInputContainer>
                <FormButtonsWrap>
                    <TinyFormButton type='submit' primary={1} big={0}>
                        complete
                    </TinyFormButton>
                    <TinyFormButton type='button' primary={1} big={0} onClick={handleBack}>
                        back
                    </TinyFormButton>
                </FormButtonsWrap>
            </PtcForm>
        </>
    )
};
export default SetUpSponsorEnd;