import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { FormInput } from './SignInElements'
import { HiOutlineMail } from 'react-icons/hi';
import { RiKey2Line } from 'react-icons/ri';
import { BsCheckAll } from 'react-icons/bs';

export const RegFormInput = styled(FormInput)`
    ::placeholder {
        color: #1D6BBB;
    }
    &:focus {
        outline: 3px solid #1D6BBB;
    }
`
export const RegStyleIcon = css`
    position: absolute;
    transform: translate(50%, 40%);
    color: #1D6BBB;
    font-size: 1.2rem;
    opacity: 0.70;
`
export const RegEmailIcon = styled(HiOutlineMail)`
    ${RegStyleIcon}
`
export const RegPasswordIcon = styled(RiKey2Line)`
    ${RegStyleIcon}
`
export const RegPasswordConfirmIcon = styled(BsCheckAll)`
    ${RegStyleIcon}
`
export const RadioWrap = styled.div`
    margin-top: 10px;

    h2 {
        color: #fff;
        font-size: clamp(0.85rem, 2.8vw, 1rem);
        font-weight: 400;
        letter-spacing: 0.8px;
        word-spacing: 2.2px;
        margin-bottom: 15px;
    }
`
export const RadioLabel = styled.label`
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: clamp(0.8rem, 2.8vw, 0.95rem);
    letter-spacing: 0.7px;
    color: #fff;
    padding-left: 50px;
`
export const RadioMark = styled.span`
    position: absolute;;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #eee;
    border-radius: 50%;
    margin-left: 24px;
    transition: all 0.2s ease-in-out;

    &:hover{
        background-color: rgba(29, 107, 187, 1);
        transition: all 0.2s ease-in-out;
    }
`
export const RadioInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + ${RadioMark} {
        &::after {
            content: '';
            display: block;
            border-radius: 50%;
            width: 50%;
            height: 50%;
            background-color: #1D6BBB;
            position: absolute;
            border: 6px solid #01DBBB;
        }
    }
`
export const CheckWrap = styled.div`
    margin-top: 10px;
`
export const CheckLabel = styled.label`
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: clamp(0.85rem, 2.8vw, 1rem);
    letter-spacing: 0.7px;
    color: #fff;
    padding-left: 28px;
`
export const CheckMark = styled.span`
    position: absolute;;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    background-color: #eee;
    transition: all 0.2s ease-in-out;
    
    &:hover{
        background-color: rgba(29, 107, 187, 1);
        transition: all 0.2s ease-in-out;
    }
`
export const CheckInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    

    &:checked + ${CheckMark} {
        background-color: #1D6BBB;
        width: 20px;
        height: 20px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            border: 6px solid #01DBBB;
            width: 7px;
            height: 15px;
            bottom: 4px;
            left: 5px;
            border: solid #01DBBB;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
`
export const RegLinks = css`
    text-decoration: none;
    color: #01DBBB;
    font-size: clamp(0.85rem, 2.8vw, 1rem);
    letter-spacing: 1px;
    word-spacing: 2px;
    transition: all 0.3s ease-in-out;

    &:hover {
        letter-spacing: 1.5px;
        word-spacing: 4px;
        transition: all 0.3s ease-in-out;
    }
`
export const TosLink = styled(Link)`
    ${RegLinks}
`
export const PrivacyLink = styled(Link)`
    ${RegLinks}
`