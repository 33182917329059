import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { TaskContainer, TaskWrap, TaskTitleWrap, TaskLevel, TaskTitle, TaskNumber, ProgressBarWrap, ProgressBar, TaskContentWrap, InfoWrap, TaskImgWrap, TaskImgLink, RepeatWrap, TaskDescription, TableWrap, Table, HeadWrap, HeadRow, Head, BodyWrap, BodyRow, Cell, TaskPrice, TaskInputWrap, TaskLinkIcon, TaskDescIcon, TaskFormInput, UploadButtonWrap, ButtonWrap, Button, ImgUploadedWrap, ImgUploaded } from './TaskViewElements';
import { TinyFormButton } from '../ButtonElements';
import { MsgText, MsgWrap } from '../MessageElements';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from '../Stack/RadialSeparators';
import { Circle } from '../Loading';

const TaskView = ({ task, index }) => {
    const history = useHistory();
    const hiddenFileInput = React.useRef(null);
    const [error, setError] = useState('');
    const [apiError, setApiError] = useState('');
    const [userConfirmLink, setUserConfirmLink] = useState('');
    const [userTaskLink, setUserTaskLink] = useState('');
    const [userDesc, setUserDesc] = useState('');
    const [fileSelected, setFileSelected] = useState();
    const [fileError, setFileError] = useState('');
    const [uploadActive, setUploadActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imgSource, setImgSource] = useState('');
    const fileFormats = ['image/jpeg', 'image/png'];
    const fetchCreateTask = async () => {
	setLoading(true);
	try {
	    const { data } = await axiosInstance.post(`task/click/${task.id}/`, {
		url: userTaskLink.length > 0 ? userTaskLink : null,
		desc: userDesc.length > 0 ? userDesc : null,
		confirm_link: userConfirmLink.length > 0 ? userConfirmLink : null
	    });
	    if (data.status) {
		!task.multi_stage && task.image_required
		    ? fetchUploadImage()
		    : task.multi_stage && task.image_required && task.m_status === 'M'
			? fetchUploadImage()
			: history.go(0);
	    };
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const fetchUploadImage = async () => {
	let fd = new FormData();
	fd.append('image', fileSelected, fileSelected.name);
	await axiosInstance.put(`task/upload-img/${task.id}/`, fd);
	history.go(0);
    };
    const handleUrlChange = (e) => {
	setError('');
	const userLink = e.target.value;
	setUserTaskLink(userLink);
	const accept_url = ['http', 'https'];
	if (userLink.length > 7 && !accept_url.includes(userLink.toLowerCase().split('://')[0])) {
	    setError("You URL address should start with 'https://' or 'http://'.");
	};
    };
    const handleDescChange = (e) => {
	setError('');
	const desc = e.target.value;
	setUserDesc(desc);
	if (desc.length > 128 || desc.length < 16) {
	    setError("The description needs to be 16-256 characters.");
	};
    };
    const handleConfirmChange = (e) => {
	setError('');
	const userConfirm = e.target.value;
	setUserConfirmLink(userConfirm);
	const accept_url = ['http', 'https'];
	if (userConfirm.length > 7 && !accept_url.includes(userConfirm.toLowerCase().split('://')[0])) {
	    setError("You URL address should start with 'https://' or 'http://'.");
	};
    };
    const handleFileChange = (e) => {
	fileError && setFileError('');
	imgSource && setImgSource('');
	if (e.target.files.length > 0) {
	    const uplaodFileType = e.target.files[0].type;
	    if (uplaodFileType && fileFormats.includes(uplaodFileType)) {
		const fileSize = e.target.files[0].size / 1024;
		if (fileSize <= 1000) {
		    setFileSelected(e.target.files[0]);
		    var img = document.createElement("img");
		    var reader = new FileReader();
		    reader.onloadend = function (ended) {
			img.src = ended.target.result;
			setImgSource(img.src);
		    }
		    reader.readAsDataURL(e.target.files[0]);
		} else {
		    setFileError('This image is too large to upload. The maximum supported size is 1000 kb.');
		}
	    } else {
		setFileError('The extension of the image is not supported. Please upload JPEG family and PNG.')
	    };
	};
    };
    const handleClick = () => {
	if (!loading && !task.multi_stage && error.length === 0 && apiError.length === 0) {
	    task.image_required && (fileSelected !== undefined ? fetchCreateTask() : setError('You must upload your image first.'));
	    task.link_required && (userTaskLink.length > 11 ? fetchCreateTask() : setError('You must enter the valid URL first.'));
	    task.desc_required && (userDesc.length > 0 ? fetchCreateTask() : setError('You must write a description.'));
	} else if (!loading && task.multi_stage && (task.m_status === null || task.m_status === 'C') && error.length === 0 && apiError.length === 0) {
	    userConfirmLink.length > 11 ? fetchCreateTask() : setError('You must enter the valid URL first.');
	} else if (!loading && task.multi_stage && task.m_status === 'M' && error.length === 0 && apiError.length === 0) {
	    task.image_required && (fileSelected !== undefined ? fetchCreateTask() : setError('You must upload your image first.'));
	    task.link_required && (userTaskLink.length > 11 ? fetchCreateTask() : setError('You must enter the valid URL first.'));
	    task.desc_required && (userDesc.length > 0 ? fetchCreateTask() : setError('You must write a description.'));
	};
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    return (
	<>
	    <TaskContainer primary={index % 2 === 0 ? 1 : 0} complete={task.complete ? 1 : 0}>
	        <TaskWrap primary={index % 2 === 0 ? 1 : 0}>
	    	    <TaskTitleWrap primary={index % 2 === 0 ? 1 : 0}>
	    		<TaskLevel primary={index % 2 === 0 ? 1 : 0}>
	        	    <h3>{task.level}</h3>
	    		</TaskLevel>
	    		<TaskTitle primary={index % 2 === 0 ? 1 : 0}>
	                    <h2>{task.title}</h2>
	    		</TaskTitle>
	    		<TaskNumber>
	        	    <h3>#{task.id}</h3>
	    		</TaskNumber>
	    	    </TaskTitleWrap>
	    	    {task.multi_stage &&
			<ProgressBarWrap primary={index % 2 === 0 ? 1 : 0}>
			    <ProgressBar width={task.register_user > 0 ? (String(task.register_user / task.number_register * 100) + '%') : 0} />
			        <span>{`${task.register_user}/${task.number_register}`} (Users who want to monetize.)</span>
			    </ProgressBarWrap>
		    }
	    	    <ProgressBarWrap primary={index % 2 === 0 ? 1 : 0}>
	                <ProgressBar width={task.complete_user > 0 ? (String(task.complete_user / task.number * 100) + '%') : 0} />
	                <span>{`${task.complete_user}/${task.number}`} (Users who have submitted their tasks.)</span>
	            </ProgressBarWrap>
	    	    <TaskContentWrap multiValue={task.multi_value ? 1 : 0}>
	    		<InfoWrap>
	    		    <CircularProgressbarWithChildren
	    			value={task.number_user_done / task.repeat * 100}
	                        maxValue={100}
	                        strokeWidth={6}
	                        styles={buildStyles({
				    strokeLinecap: "butt",
				    pathColor: 'rgba(1, 219, 187, 0.9)'
				})}
	                    >
	    		        <TaskImgLink href={task.link} target='_blank'>
	                            <TaskImgWrap primary={index / 2 === 0 ? 1 : 0}>
	                                <img src={task.image} alt='task_pic' />
	                            </TaskImgWrap>
	                        </TaskImgLink>
	    			<RadialSeparators
	                            count={task.repeat}
	                            style={{
				        background: "#fff",
				        width: "2px",
				        height: `${6}%`
				    }}
				/>
			    </CircularProgressbarWithChildren>
			    <RepeatWrap primary={index % 2 === 0 ? 1 : 0}>
				<h3>{`${task.number_user_done}/${task.repeat}`}</h3>
			    </RepeatWrap>
			    {!task.multi_value &&
				<TaskPrice primary={index % 2 === 0 ? 1 : 0}>
				    <h3>{task.value1} pc</h3>
				</TaskPrice>
			    }
	                </InfoWrap>
	    		<TaskDescription primary={index % 2 === 0 ? 1 : 0} multiValue={task.multi_value ? 1 : 0}>
	                    <p>{task.description}</p>
	                </TaskDescription>
	    		{task.multi_value &&
			    <TableWrap>
				<Table>
				    <HeadWrap>
				        <HeadRow>
				            <Head primary={index % 2 === 0 ? 1 : 0}>
				                {task.head1}
				            </Head>
				            <Head primary={index % 2 === 0 ? 1 : 0}>
				                {task.head2}
				            </Head>
				            {task.head3 &&
						<Head primary={index % 2 === 0 ? 1 : 0}>
						    {task.head3}
						</Head>
					    }
				        </HeadRow>
				    </HeadWrap>
				    <BodyWrap>
				        <BodyRow key={index}>
				            <Cell primary={index % 2 === 0 ? 1 : 0}>
				                {task.value1} pc
				            </Cell>
				            <Cell primary={index % 2 === 0 ? 1 : 0}>
				                {task.value2} pc
				            </Cell>
				            {task.value3 !== null &&
						<Cell primary={index % 2 === 0 ? 1 : 0}>
						    {task.value3} pc
						</Cell>
					    }
				        </BodyRow>
				    </BodyWrap>
				</Table>
			    </TableWrap>
			}
	    	    </TaskContentWrap>
	    	    {!task.complete && task.multi_stage && (task.m_status === null || task.m_status === 'C') && task.status === 'P' &&
			<TaskInputWrap>
			    <TaskLinkIcon primary={index % 2 === 0 ? 1 : 0} />
			    <TaskFormInput
			        primary={index % 2 === 0 ? 1 : 0}
			        type='url'
			        placeholder='Enter your URL to start monetizing'
			        onChange={handleConfirmChange}
			        value={userConfirmLink}
			    />
			</TaskInputWrap>
		    }
	    	    {!task.complete && task.m_status === 'M' &&
			<MsgWrap primary={1} style={{ flexDirection: 'row', width: '75%' }}>
			    <MsgText primary={1}>
				Congratulation! You have monetized successfully.
			    </MsgText>
			</MsgWrap>
		    }
	    	    {!task.complete && task.link_required && ((!task.multi_stage && task.m_status === null) || task.m_status === 'M' || (!task.multi_stage && task.m_status === 'C')) &&
			<TaskInputWrap>
			    <TaskLinkIcon primary={index % 2 === 0 ? 1 : 0} />
			    <TaskFormInput
			        primary={index % 2 === 0 ? 1 : 0}
			        type='url'
			        placeholder='Enter your URL'
			        onChange={handleUrlChange}
			        value={userTaskLink}
			    />
			</TaskInputWrap>
		    }
	    	    {!task.complete && task.image_required && ((!task.multi_stage && task.m_status === null) || task.m_status === 'M' || (!task.multi_stage && task.m_status === 'C')) &&
			<>
			    <TaskInputWrap>
				<TaskFormInput
			            type='file'
			            accept='image/jpeg, image/png, image/gif'
			            onChange={handleFileChange}
			            ref={hiddenFileInput}
			            style={{ display: 'none' }}
			        />
			    </TaskInputWrap>
			    <UploadButtonWrap>
				<ButtonWrap primary={index % 2 === 0 ? 1 : 0}>
				    <Button primary={index % 2 === 0 ? 1 : 0} onClick={() => { hiddenFileInput.current.click(); setUploadActive(true) }} itemActive={uploadActive ? 1 : 0}>
				        upload
			            </Button>
				</ButtonWrap>
				{fileError.length === 0 && imgSource.length !== 0 &&
				    <ImgUploadedWrap>
					<ImgUploaded
					    src={imgSource}
					    alt='submit img'
					/>
				    </ImgUploadedWrap>
				}
			    </UploadButtonWrap>
			</>
		    }
	    	    {!task.complete && task.desc_required && ((!task.multi_stage && task.m_status === null) || task.m_status === 'M' || task.m_status === 'C') &&
			<TaskInputWrap>
			    <TaskDescIcon primary={index % 2 === 0 ? 1 : 0} />
			    <TaskFormInput
			        primary={index % 2 === 0 ? 1 : 0}
			        type='text'
			        placeholder='Write your message or describe your file.'
			        onChange={handleDescChange}
			        value={userDesc}
			    />
			</TaskInputWrap>
		    }
	    	    {!task.complete && (task.status === 'R' || task.status === 'C') && task.m_status === null &&
			<MsgWrap primary={1} style={{ flexDirection: 'row', width: '75%' }}>
			    <MsgText primary={1}>
				The capacity has been filled. It doesn't mean the job is done. Please wait...
			    </MsgText>
			</MsgWrap>
		    }
	    	    {!task.complete && ((task.m_status === null && task.status === 'P') || task.m_status === 'M' || task.m_status === 'C') && (task.link_required || task.image_required || task.desc_required) &&
			<TinyFormButton
			    style={{ width: '60%', margin: '15px auto' }}
			    big={0} primary={index % 2 === 0 ? 1 : 0}
			    onClick={handleClick}
			    onContextMenu={handleRightClick}
			>{!loading ? 'submit' : <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} />}
			</TinyFormButton>
		    }
	    	    {(task.m_status === 'R' || task.m_status === 'P') &&
			<MsgWrap primary={1} style={{ flexDirection: 'row', width: '75%' }}>
			    <Circle style={{ marginRight: '7px' }} />
			    <MsgText primary={1}>
				Wait for {task.m_status === 'R' ? 'monetizing...' : 'reviewing...'}
			    </MsgText>
			</MsgWrap>
		    }
	    	    {task.m_status === 'D' &&
			<MsgWrap primary={0} style={{ width: '75%' }}>
			    <MsgText primary={0}>
				You are not eligible to do this task. Thank you.
			    </MsgText>
			</MsgWrap>
		    }
	    	    {error &&
			<MsgWrap primary={0} style={{ width: '75%' }}>
			    <MsgText primary={0}>
				{error}
			    </MsgText>
			</MsgWrap>
		    }
	    	    {fileError &&
			<MsgWrap primary={0} style={{ width: '75%' }}>
			    <MsgText primary={0}>
				{fileError}
			    </MsgText>
			</MsgWrap>
		    }
	    	</TaskWrap>
	    </TaskContainer >
	</>
    )
}
export default TaskView;
