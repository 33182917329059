import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { useParams } from 'react-router-dom';
import { Container, Wrapper } from '../components/TOS/TOSElements';
import { SectionContainer, SectionTitle, SectionContentWrap, ImgSectionWrap, DescSectionWrap } from '../components/HelpDesk/BlogArticleElements';
import { Circle } from '../components/Loading';
import { MsgWrap, MsgText } from '../components/MessageElements';

const AssistantSLPage = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    const code = params.code;
    useEffect(() => {
	const fetchSLAssitance = async () => {
	    try {
		const { data } = await axiosInstance.get(`shortlink/assistant/display/${code}/`);
		setInfo(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchSLAssitance();
    }, [code]);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && info !== undefined && Object.keys(info).length > 0 &&
		<Container>
		    <Wrapper>
			{info.info.blog_assistant_sl.map((section, index) => (
			    <SectionContainer key={index} primary={0}>
				<SectionTitle primary={0}>
				    <h2>section {section.section}</h2>
				</SectionTitle>
				<SectionContentWrap>
				    <ImgSectionWrap>
					<img src={section.image} alt={'image of section' + section.section} />
				    </ImgSectionWrap>
				    <DescSectionWrap>
					<p>{section.description}</p>
				    </DescSectionWrap>
				</SectionContentWrap>
			    </SectionContainer>
			))}
		    </Wrapper>
		</Container>
	    }
	    {apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '10px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	</>
    )
}
export default AssistantSLPage;
