import styled from 'styled-components';

export const NavPtcContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin-bottom: 5px;
`
export const NavPtcItem = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({active}) => (active ? 'rgba(1, 219, 187, 0.7)' : 'rgba(39, 117, 85, 0.5)')};
    border: 1px solid ${({active}) => (active ? 'rgba(1, 219, 187, 0.8)' : 'none')};
    box-shadow: 0 0 7px ${({active}) => (active ? 'rgba(1, 219, 187, 0.9)' : 'none')};
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: #fff;
        text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        font-size: 0.75rem;
    }

`
