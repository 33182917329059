import {
    UNSHOW_MAINBAR,
    SHOW_MAINBAR,
    TOGGLE_MAINBAR,
} from '../constants/mainbarRedux';

export const showMainbarAction = ({ prop }) => {
    return (dispatch) => {
        if (prop === false) {
            dispatch({
                type: UNSHOW_MAINBAR
            })
        }
        if (prop === true) {
            dispatch({
                type: SHOW_MAINBAR
            })
        }
        if (prop === 'toggle') {
            dispatch({
                type: TOGGLE_MAINBAR
            })
        }
    }
};
