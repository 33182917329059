export const cryptoLogoFaucetpayData = [
    {
        'name': 'tetherFP',
        'src': require('../images/tether.svg').default,
        'alt': 'tether logo',
        'value': 'usdttrc20',
        'name2': 'tether'
    },
    {
        'name': 'bitcoinFP',
        'src': require('../images/bitcoin.svg').default,
        'alt': 'bitcoin logo',
        'value': 'btc',
        'name2': 'bitcoin'
    }
];

export const cryptoLogoWalletData = [
    {
        'name': 'tether',
        'src': require('../images/tether.svg').default,
        'alt': 'tether logo',
        'value': 'usdttrc20'

    },
    {
        'name': 'bitcoin',
        'src': require('../images/bitcoin.svg').default,
        'alt': 'bitcoin logo',
        'value': 'btc'

    },
    {
        'name': 'dogecoin',
        'src': require('../images/dogecoin.svg').default,
        'alt': 'dogecoin logo',
        'value': 'doge'
    },
    {
	'name': 'tron',
	'src': 'https://icefaucet.com/media/help/blog/tron.png',
	'alt': 'tron logo',
	'value': 'trx'
    }
];

export const cryptoLogoAutoDepositData = [
    {
        'name': 'tether',
        'src': require('../images/tether.svg').default,
        'alt': 'tether logo',
        'value': 'usdttrc20'
    },
    {
        'name': 'bitcoin',
        'src': require('../images/bitcoin.svg').default,
        'alt': 'bitcoin logo',
        'value': 'btc'
    },
    {
        'name': 'dogecoin',
        'src': require('../images/dogecoin.svg').default,
        'alt': 'dogecoin logo',
        'value': 'doge'
    },
    {
	'name': 'tron',
	'src': 'https://icefaucet.com/media/help/blog/tron.png',
	'alt': 'tron logo',
	'value': 'trx'
    }
];
export const cryptoLogoFPDepositData = [
    {
	'name': 'tether',
	'src': require('../images/tether.svg').default,
	'alt': 'tether logo',
	'value': 'usdttrc20'
    },
    {
	'name': 'bitcoin',
	'src': require('../images/bitcoin.svg').default,
	'alt': 'bitcoin logo',
	'value': 'btc'
    },
    {
        'name': 'dogecoin',
	'src': require('../images/dogecoin.svg').default,
	'alt': 'dogecoin logo',
	'value': 'doge'
    },
    {
	'name': 'tron',
	'src': 'https://icefaucet.com/media/help/blog/tron.png',
	'alt': 'tron logo',
	'value': 'trx'
    }
];
export const cryptoLogoManualDepositData = [
    {
        'name': 'tether',
        'src': require('../images/tether.svg').default,
        'alt': 'tether logo',
        'value': 'usdttrc20'
    },
    {
        'name': 'bitcoin',
        'src': require('../images/bitcoin.svg').default,
        'alt': 'bitcoin logo',
        'value': 'btc'
    },
    {
        'name': 'dogecoin',
        'src': require('../images/dogecoin.svg').default,
        'alt': 'dogecoin logo',
        'value': 'doge'
    },
    {
	'name': 'tron',
	'src': 'https://icefaucet.com/media/help/blog/tron.png',
	'alt': 'tron logo',
	'value': 'trx'
    }
];
