import React from 'react';
import { IceCardWrap, IceImgContainer, IceImgBackground, IceImgWrap, IceTitleWrap } from './IceCardElements';

const IceCard = ({ card }) => {
    return (
	<>
	    <IceCardWrap active={card.image ? 1 : 0}>
		<IceImgContainer>
		    <IceImgBackground active={card.image ? 1 : 0}>
		        <img src={card.image ? card.image : 'https://icefaucet.com/media/help/blog/backcard.PNG'} alt={'card pic'} />
		    </IceImgBackground>
		    <IceImgWrap>
		        <img src={card.image ? card.image : 'https://icefaucet.com/media/help/blog/backcard.PNG'} alt={'card pic'} />
		    </IceImgWrap>
		</IceImgContainer>
		<IceTitleWrap>
		    {card.title ? <h3>{card.title}</h3> : null}
		</IceTitleWrap>
	    </IceCardWrap>
	</>
    )
}
export default IceCard;
