import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { SidebarContainer } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { ModalWrapper, CircleInfoContainer, CircleInfoWrap, CircleInfoCardWrap, TitleItemWrap, UniqueWrap, TargetContainer, TargetWrap, TargetCardWrap } from './MoreInfoModalElements';
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Label,
    PieChart,
    Pie,
    Cell
} from 'recharts';
import { Circle } from '../Loading';
import { MsgWrap, MsgText } from '../MessageElements';

const MoreInfoModal = ({ moreClick, setMoreClick, adsId }) => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {(percent !== 0 && percent !== 1) ? `${(percent * 100).toFixed(0)}%` : null}
            </text>
        );
    };
    const COLORS = ['rgb(29, 107, 187)', 'rgb(39, 117, 85)', 'rgb(1, 219, 187)', 'rgb(120, 116, 16)', 'rgb(90, 20, 200)', 'rgb(177, 190, 75)', 'rgb(190, 75, 177)', 'rgb(240, 75, 75)'];
    const [smallSize, setSmallSize] = useState(false);
    const [apiError, setApiError] = useState('');
    const closeMainbar = () => {
        if (window.innerWidth < 513) {
            setSmallSize(true);
        } else {
            setSmallSize(false);
        };
    };
    useEffect(() => {
        closeMainbar();
        window.addEventListener('resize', closeMainbar);
        return () => {
            window.removeEventListener('resize', closeMainbar);
        };
    });
    useEffect(() => {
	const fetchMoreInfo = async () => {
	    try {
		const { data } = await axiosInstance.post('ptc/control-ads/view-ads/more/', {
		    id: adsId,
		});
		setInfo(data.info);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchMoreInfo();
    }, [adsId]);
    return (
        <SidebarContainer isOpen={moreClick ? 1 : 0}>
            <ModalWrapper>
                <CloseIconWrap onClick={() => setMoreClick(false)}>
                    <CloseIcon />
                </CloseIconWrap>
	        {loading &&
		    <div style={{ width: '100%', height: '65vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		    </div>
		}
	        {!loading && info !== undefined && !apiError &&
		    <>
                	<ResponsiveContainer width="95%" height={180}>
                    	    <AreaChart
                        	data={info.visitors_data}
                        	margin={{
                           	    top: 10,
                            	    right: 0,
                            	    left: 0,
                            	    bottom: 0
                        	}}
                   	    >
                        	<defs>
                            	    <linearGradient id="color_visitors" x1="0" y1="0" x2="0" y2="1">
                                	<stop offset="1%" stopColor="rgb(29, 107, 187)" stopOpacity={0.7} />
                                	<stop offset="99%" stopColor="rgb(29, 107, 187)" stopOpacity={0} />
                            	    </linearGradient>
                        	</defs>
                        	<XAxis dataKey='date' padding={{ right: 50 }} interval="preserveStartEnd" tick={{ fill: 'rgb(29, 107, 187)' }} tickLine={{ stroke: 'rgb(29, 107, 187)' }} />
                        	<YAxis padding={{ top: 10 }} interval={1} tick={{ fill: 'rgb(29, 107, 187)' }} tickLine={{ stroke: 'rgb(29, 107, 187)' }}>
                            	    <Label angle={-90} position='insideLeft' offset={15} fill='rgb(29, 107, 187)' >
                                	visits
                            	    </Label>
                        	</YAxis>
                        	<Tooltip />
                        	< Area
                            	    type="monotone"
                            	    dataKey='visits'
                            	    stroke="rgba(29, 107, 187, 0.7)"
                            	    fill="url(#color_visitors)"
                       		/>
                    	    </AreaChart>
                	</ResponsiveContainer>
                	<CircleInfoContainer>
                    	    <CircleInfoWrap>
                                <CircleInfoCardWrap>
                            	    <TitleItemWrap>
                                	<span>gender</span>
                            	    </TitleItemWrap>
                            	    <ResponsiveContainer width="100%" height={120}>
                                	<PieChart>
                                    	    <Legend verticalAlign='middle' layout='vertical' align='right' iconSize={15} height={50} />
                                    	    <Pie
                                        	data={info.gender_visitors_data}
                                        	labelLine={false}
                                        	label={renderCustomizedLabel}
                                        	outerRadius={50}
                                        	dataKey="value"
                                    	    >
                                        	{info.gender_visitors_data.map((entry, index) => (
                                            	    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        	))}
                                    	    </Pie>
                                	</PieChart>
                            	    </ResponsiveContainer>
                        	</CircleInfoCardWrap>
                        	<CircleInfoCardWrap>
                            	    <TitleItemWrap>
                                	<span>device</span>
                            	    </TitleItemWrap>
                            	    <ResponsiveContainer width="100%" height={120}>
                                	<PieChart>
                                    	    <Legend verticalAlign='middle' layout='vertical' align='right' iconSize={15} height={50} />
                                    	    <Pie
                                        	data={info.device_visitors_data}
                                        	labelLine={false}
                                        	label={renderCustomizedLabel}
                                        	outerRadius={50}
                                        	dataKey="value"
                                            >
                                        	{info.device_visitors_data.map((entry, index) => (
                                            	    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        	))}
                                    	    </Pie>
                                	</PieChart>
                            	    </ResponsiveContainer>
                        	</CircleInfoCardWrap>
                        	<CircleInfoCardWrap>
                            	    <TitleItemWrap>
                                	<span>unique visits</span>
                            	    </TitleItemWrap>
                            	    <UniqueWrap>
                                	<h3>{info.unique_visitors_data}%</h3>
                            	    </UniqueWrap>
                        	</CircleInfoCardWrap>
                    	    </CircleInfoWrap>
                	</CircleInfoContainer>
                	<TargetContainer>
                    	    <TargetWrap>
                        	<TargetCardWrap>
                            	    <TitleItemWrap>
                                	<span>target country visits</span>
                            	    </TitleItemWrap>
                            	    <ResponsiveContainer width="100%" height={220}>
                                	<PieChart>
                                    	    <Legend
                                        	verticalAlign={!smallSize ? 'top' : 'bottom'}
                                        	layout={!smallSize ? 'vertical' : 'horizontal'}
                                        	align={!smallSize ? 'right' : 'center'}
                                        	iconSize={!smallSize ? 15 : 8}
                                        	height={50}
                                    	    />
                                    	    <Pie
                                        	data={info.country_visitors_data}
                                        	labelLine={false}
                                        	label={renderCustomizedLabel}
                                        	outerRadius={!smallSize ? 88 : 70}
                                        	dataKey="value"
                                    	    >
                                        	{info.country_visitors_data.map((entry, index) => (
                                            	    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        	))}
                                    	    </Pie>
                                	</PieChart>
                            	    </ResponsiveContainer>
                        	</TargetCardWrap>
                        	<TargetCardWrap>
                            	    <TitleItemWrap>
                                	<span>target language visits</span>
                            	    </TitleItemWrap>
                            	    <ResponsiveContainer width="100%" height={220}>
                                	<PieChart>
                                    	    <Legend verticalAlign={!smallSize ? 'top' : 'bottom'}
                                        	layout={!smallSize ? 'vertical' : 'horizontal'}
                                        	align={!smallSize ? 'right' : 'center'}
                                        	iconSize={!smallSize ? 15 : 8}
                                        	height={50}
                                    	    />
                                    	    <Pie
                                        	data={info.language_visitors_data}
                                        	labelLine={false}
                                        	label={renderCustomizedLabel}
                                        	outerRadius={!smallSize ? 88 : 70}
                                        	dataKey="value"
                                    	    >
                                        	{info.language_visitors_data.map((entry, index) => (
                                            	    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        	))}
                                    	    </Pie>
                                	</PieChart>
                            	    </ResponsiveContainer>
                        	</TargetCardWrap>
                    	    </TargetWrap>
                	</TargetContainer>
		    </>
		}
	        {!loading && apiError.length > 0 &&
		    <MsgWrap primary={0} style={{ margin: '10px auto', width: '80%' }}>
			<MsgText primary={0}>
			    {apiError}
			</MsgText>
		    </MsgWrap>
		}
            </ModalWrapper>
        </SidebarContainer >
    )
}
export default MoreInfoModal;
