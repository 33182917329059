import React from 'react';
import { Container, Wrapper, ImgWrap, DescWrap, PriceWrap } from './OptionsAdsZElements';

const Options = ({ options, extra }) => {
    return (
        <>
            <Container id={options.id} black={options.darkBackground ? 1 : 0}>
                <>
                    {options.items.map((row, index) => (
                        <Wrapper key={index} extra={extra ? 1 : 0}>
                            <ImgWrap>
                                <img src={row.image} alt={row.alt} />
                            </ImgWrap>
                            <DescWrap>
                                <h4>{row.description}</h4>
                            </DescWrap>
                            {row.price &&
                                <PriceWrap>
                                    <h4>{row.price}</h4>
                                </PriceWrap>
                            }
                        </Wrapper>
                    ))}
                </>
            </Container>
        </>
    )
}

export default Options;