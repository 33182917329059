import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { ControlContainer, ControlCard, ControlTitrWrap, ControlContentWrap, ControlItemWrap, ControlAdsUrl, ControlAdsLink, IconWrap, EditIcon, AddCreditIcon, DeleteIcon, StopIcon, PlayIcon, ControlButtonWrap, AddCreditForm } from './AdsControlElements';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { RangeInput, NumberInput, NumberInputWrap } from '../SetUpPtc/SetUpPtcFilterElements.js';
import { useHistory } from 'react-router-dom';
import EditSponsorModal from './EditSponsorModal';

const SponsorAdsControl = ({ info, adscoin }) => {
    const history = useHistory();
    const [adsIndex, setAdsIndex] = useState();
    const [deleteClick, setDeleteClick] = useState(false);
    const [deleteComplete, setDeleteComplete] = useState(false);
    const [addCreditClick, setAddCreditClick] = useState(false);
    const [impValue, setImpValue] = useState(Object.freeze(100));
    const [addCreditError, setAddCreditError] = useState('');
    const [addCreditComplete, setAddCreditComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [adsId, setAdsId] = useState(Object.freeze());
    const [addCredit, setAddCredit] = useState(Object.freeze());
    const [statusClick, setStatusClick] = useState(false);
    const [statusError, setStatusError] = useState('');
    const [statusComplete, setStatusComplete] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const [editError, setEditError] = useState('');

    const handleDeleteClick = (index) => {
        if (deleteClick && adsIndex !== undefined && adsIndex === index) {
            setDeleteClick(false);
        } else {
            setDeleteClick(true);
        };
        setAdsIndex(index);
        setDeleteComplete(false);
    };
    const fetchDelete = async (id) => {
        await axiosInstance.post('sponsor/control-ads/delete/', {
            'id': id
        });
        setDeleteComplete(true);
        setTimeout(() => history.go(0), 500);
    };
    const handleNoClick = () => {
        setDeleteClick(false);
    };
    const handleYesClick = (id) => {
        fetchDelete(id);
    };
    const handleAddCreditClick = (index) => {
        if (addCreditClick && adsIndex !== undefined && adsIndex === index) {
            setAddCreditClick(false);
        } else {
            setAddCreditClick(true);
        };
        setAdsIndex(index);
        setAddCreditComplete(false);
        setAddCreditError('');
        setIsSubmitting(false);
    };

    const handleChange = (e) => {
        let number = parseInt(e.target.value)
        if (!isNaN(number)) {
            setImpValue(parseInt(number));
        };
        setIsSubmitting(false);
        setAddCreditError('');
    };

    const maxValue = (CPI) => {
        const maximum = Math.floor(adscoin / CPI);
        if (maximum <= 1000000) {
            setImpValue(maximum);
        } else if (maximum > 1000000) {
            setImpValue(1000000);
        };
        setIsSubmitting(false);
        setAddCreditError('');
    };

    const handleSubmit = (e, id, CPI) => {
        e.preventDefault();
        if (impValue < 100) {
            setAddCreditError('Please enter a number greater than or equal to 100.');
        } else if (adscoin < impValue * CPI) {
            setAddCreditError('You do not have enough adscoin.');
        } else if (impValue > 1000000) {
            setAddCreditError('Please enter a number less than or equal to 1000000.');
        };
        setAdsId(id);
        setAddCredit(impValue * CPI);
        setIsSubmitting(true);
    };

    const fetchAddCredit = async () => {
        try {
            await axiosInstance.post('sponsor/control-ads/add-credit/', {
                id: adsId,
                impressoins: impValue,
                credit: addCredit
            });
            setAddCreditComplete(true);
            setTimeout(() => history.go(0), 500);
        } catch (error) {
            error.response && error.response.data.detail ? setAddCreditError(error.response.data.detail) : setAddCreditError(error.message);
        };
    };

    useEffect(() => {
        if (isSubmitting && addCreditError.length === 0 && !addCreditComplete)
            fetchAddCredit();
    });
    const handleStatusClick = (index, m_status) => {
        setStatusError('');
        if (m_status === 'A') {
            if (statusClick && adsIndex !== undefined && adsIndex === index) {
                setStatusClick(false);
            } else {
                setStatusClick(true);
            };
            setAdsIndex(index);
            setStatusComplete(false);
        } else {
            setStatusClick(true);
            setAdsIndex(index);
            setStatusComplete(false);
            setStatusError('It is for just the advertisement that has been accepted.');
        };
    };

    const fetchChangeStatusAds = async (id, status) => {
        await axiosInstance.post('sponsor/control-ads/change-status/', {
            'id': id,
            'status': status === 'S' ? 'P' : 'S'
        });
        setStatusComplete(true);
        setTimeout(() => history.go(0), 500);
    };
    const handleStatusNoClick = () => {
        setStatusClick(false);
    };
    const handleStatusYesClick = (id, status) => {
        fetchChangeStatusAds(id, status);
    };
    const handleEditClick = (index, watching) => {
        setEditClick(true);
        setAdsIndex(index);
        watching > 0 && setEditError('This advertisement is watching now.');
    };

    return (
        <ControlContainer>
            {info.map((ads, index) => (
                <div key={index}>
                    <ControlCard>
                        <ControlTitrWrap color={ads.m_status} style={{ borderRadius: '15px 15px 0 0' }}>
                            <h3>sponsor link</h3>
                        </ControlTitrWrap>
                        <ControlContentWrap color={ads.m_status}>
                            <ControlItemWrap>
                                <ControlAdsUrl>
                                    <ControlAdsLink href={ads.url} target='_blank'>
                                        {ads.url.split('://')[1].includes('www') ? ads.url.split('://')[1].split('www.')[1] : ads.url.split('://')[1]}
                                    </ControlAdsLink>
                                </ControlAdsUrl>
                            </ControlItemWrap>
                        </ControlContentWrap>
                        {ads.m_status === 'P' &&
                            <ControlContentWrap color={ads.m_status}>
                                <ControlItemWrap>
                                    <span>credit:</span>
                                    <span>{ads.credit}</span>
                                </ControlItemWrap>
                                <ControlItemWrap>
                                    <span>impression:</span>
                                    <span> {ads.impression}</span>
                                </ControlItemWrap>
                                <ControlItemWrap>
                                    <span>CPI:</span>
                                    <span>{ads.CPI}</span>
                                </ControlItemWrap>
                            </ControlContentWrap>
                        }
                        {ads.m_status === 'A' &&
                            <ControlContentWrap color={ads.m_status}>
                                <ControlItemWrap>
                                    <span>impressioned:</span>
                                    <span> {ads.impressioned}</span>
                                </ControlItemWrap>
                                <ControlItemWrap>
                                    <span>CTR:</span>
                                    <span> {ads.CTR}%</span>
                                </ControlItemWrap>
                                <ControlItemWrap>
                                    <span>remain credit:</span>
                                    <span> {ads.credit}</span>
                                </ControlItemWrap>
                            </ControlContentWrap>
                        }
                        {ads.m_status === 'R' &&
                            <ControlContentWrap color={ads.m_status}>
                                <ControlItemWrap>
                                    <span>This advertisement is against our terms of service.</span>
                                </ControlItemWrap>
                                <ControlButtonWrap>
                                    <TinyFormButton primary={0} onClick={() => handleDeleteClick(index)}>
                                        delete
                                    </TinyFormButton>
                                </ControlButtonWrap>
                            </ControlContentWrap>
                        }
                        {ads.m_status === 'C' &&
                            <ControlContentWrap color={ads.m_status}>
                                <ControlItemWrap>
                                    <span>impressioned:</span>
                                    <span> {ads.impressioned}</span>
                                </ControlItemWrap>
                                <ControlItemWrap>
                                    <span>CTR:</span>
                                    <span> {ads.CTR}%</span>
                                </ControlItemWrap>
                                <ControlItemWrap>
                                    <span>CPI:</span>
                                    <span>{ads.CPI}</span>
                                </ControlItemWrap>
                            </ControlContentWrap>
                        }
                        {ads.m_status !== 'R' &&
                            <ControlContentWrap color={ads.m_status}>
                                <ControlItemWrap style={{ padding: '3px 3px 0 3px' }}>
                                    <IconWrap onClick={() => handleAddCreditClick(index)}>
                                        <AddCreditIcon title='Add credit' />
                                    </IconWrap>
                                    <IconWrap onClick={() => handleDeleteClick(index)}>
                                        <DeleteIcon title='Delete' />
                                    </IconWrap>
                                </ControlItemWrap>
                                <ControlItemWrap style={{ padding: '3px' }}>
                                    <IconWrap onClick={() => handleEditClick(index, ads.watching)}>
                                        <EditIcon title='Edit' />
                                    </IconWrap>
                                    <IconWrap onClick={() => handleStatusClick(index, ads.m_status)}>
                                        {ads.status === 'S' ? <StopIcon title='play' /> : <PlayIcon title='stop' />}
                                    </IconWrap>
                                </ControlItemWrap>
                            </ControlContentWrap>
                        }
                        {deleteClick && adsIndex === index &&
                            <ControlContentWrap color={ads.m_status} isOpen={deleteClick ? 1 : 0}>
                                {
                                    !deleteComplete &&
                                    <>
                                        <ControlItemWrap style={{ padding: '3px 3px 0 3px' }}>
                                            <p style={{ color: '#fff', fontSize: '0.9rem' }}>Are you sure you want to delete this advertisement?</p>
                                        </ControlItemWrap>
                                        <ControlItemWrap style={{ padding: '10px' }}>
                                            <TinyFormButton primary={0} big={0} type='button' onClick={handleNoClick} style={{ width: '80px' }}>
                                                no
                                            </TinyFormButton>
                                            <TinyFormButton primary={0} big={0} type='button' onClick={() => handleYesClick(ads.id)} style={{ width: '80px' }}>
                                                yes
                                            </TinyFormButton>
                                        </ControlItemWrap>
                                    </>
                                }
                                {deleteComplete &&
                                    <MsgWrap primary={1} style={{ margin: '10px auto', width: '95%' }}>
                                        <MsgText primary={1}>
                                            Your advertisement has been successfully deleted.
                                        </MsgText>
                                    </MsgWrap>
                                }
                            </ControlContentWrap>
                        }
                        {addCreditClick && adsIndex === index &&
                            <ControlContentWrap color={ads.m_status} isOpen={addCreditClick ? 1 : 0}>
                                {
                                    !addCreditComplete && addCreditError.length === 0 &&
                                    <AddCreditForm action='#' onSubmit={(e) => handleSubmit(e, ads.id, ads.CPI)}>
                                        <ControlItemWrap style={{ padding: '3px 3px 0 3px' }}>
                                            <p style={{ color: '#fff', fontSize: '0.9rem', marginBottom: '15px' }}>Add impressions to this advertisement as much as you want:</p>
                                        </ControlItemWrap>
                                        <RangeInput
                                            type='range'
                                            name='visit'
                                            min='100'
                                            max='1000000'
                                            value={impValue}
                                            onChange={handleChange}
                                        />
                                        <NumberInputWrap>
                                            <NumberInput
                                                type='number'
                                                name='visit'
                                                value={impValue}
                                                onChange={handleChange}
                                            />
                                            <TinyFormButton type='button' primary={0} big={0} onClick={() => maxValue(ads.CPI)}>
                                                max
                                            </TinyFormButton>
                                        </NumberInputWrap>
                                        <ControlButtonWrap style={{ marginTop: '15px' }}>
                                            <TinyFormButton type='submit' primary={0}>
                                                submit
                                            </TinyFormButton>
                                        </ControlButtonWrap>
                                    </AddCreditForm>
                                }
                                {addCreditError.length > 0 &&
                                    <MsgWrap primary={0} style={{ margin: '10px auto', width: '95%' }}>
                                        <MsgText primary={0}>
                                            {addCreditError}
                                        </MsgText>
                                    </MsgWrap>
                                }
                                {addCreditComplete &&
                                    <MsgWrap primary={1} style={{ margin: '10px auto', width: '95%' }}>
                                        <MsgText primary={1}>
                                            The credit has been successfully added to this ads.
                                        </MsgText>
                                    </MsgWrap>
                                }
                            </ControlContentWrap>
                        }
                        {statusClick && adsIndex === index &&
                            <ControlContentWrap color={ads.m_status} isOpen={statusClick ? 1 : 0}>
                                {
                                    !statusComplete && statusError.length === 0 &&
                                    <>
                                        <ControlItemWrap style={{ padding: '3px 3px 0 3px' }}>
                                            <p style={{ color: '#fff', fontSize: '0.9rem' }}>Are you sure you want to {ads.status === 'S' ? 'play' : 'stop'} this advertisement?</p>
                                        </ControlItemWrap>
                                        <ControlItemWrap style={{ padding: '10px' }}>
                                            <TinyFormButton primary={0} big={0} type='button' onClick={handleStatusNoClick} style={{ width: '80px' }}>
                                                no
                                            </TinyFormButton>
                                            <TinyFormButton primary={0} big={0} type='button' onClick={() => handleStatusYesClick(ads.id, ads.status)} style={{ width: '80px' }}>
                                                yes
                                            </TinyFormButton>
                                        </ControlItemWrap>
                                    </>
                                }
                                {statusError.length > 0 &&
                                    <MsgWrap primary={0} style={{ margin: '10px auto', width: '95%' }}>
                                        <MsgText primary={0}>
                                            {statusError}
                                        </MsgText>
                                    </MsgWrap>
                                }
                                {statusComplete &&
                                    <MsgWrap primary={1} style={{ margin: '10px auto', width: '95%' }}>
                                        <MsgText primary={1}>
                                            Your advertisement has been successfully {ads.status === 'S' ? 'played' : 'stoped'}.
                                        </MsgText>
                                    </MsgWrap>
                                }
                            </ControlContentWrap>
                        }
                        {editClick && editError.length > 0 && adsIndex === index &&
                            <ControlContentWrap color={ads.m_status} isOpen={deleteClick ? 1 : 0}>
                                <MsgWrap primary={0} style={{ margin: '10px auto', width: '95%' }}>
                                    <MsgText primary={0}>
                                        {editError}
                                    </MsgText>
                                </MsgWrap>
                            </ControlContentWrap>
                        }
                        <ControlTitrWrap color={ads.m_status} style={{ borderRadius: '0 0 15px 15px' }}>
                            <h3>{ads.m_status === 'P' ? 'pending' : ads.m_status === 'A' ? 'Accepted' : ads.m_status === 'R' ? 'rejected' : ads.m_status === 'C' ? 'completed' : null}</h3>
                        </ControlTitrWrap>
                    </ControlCard>
                    {
                        editClick && adsIndex === index && editError.length === 0 &&
                        <EditSponsorModal editClick={editClick}
                            setEditClick={setEditClick}
                            id={ads.id}
                            CPI={ads.CPI}
                            url={ads.url}
                            language={ads.target_language ? ads.target_language : undefined}
                            country={ads.all_countries ? undefined : ads.target_country}
                            countryName={ads.all_countries ? undefined : ads.target_country_name}
                            title={ads.title}
                            description={ads.description ? ads.description : undefined}
                        />
                    }
                </div>
            ))}
        </ControlContainer>
    )
}

export default SponsorAdsControl;