import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Wrapper, Header, LeftWrap, LogoWrapLink, SubjectWrap, Abstract, DescWrap, Card, Title, Desc } from './TOSElements';
import logo from '../../images/logo.png';

const TOS = ({ code, info }) => {
    const location = useLocation();
    return (
        <Container>
            <Wrapper>
                <Header>
                    <LeftWrap>
                        <LogoWrapLink
                            to={location.pathname === '/TOS' || location.pathname === '/privacy'
                                ? '/'
                                : location.pathname === `/info/ref/${code}/TOS` || location.pathname === `/info/ref/${code}/privacy`
                                    ? `/ref/${code}`
                                    : '#'
                            }
                        >
                            <img src={logo} alt={'logo of icefaucet'} />
                        </LogoWrapLink>
                    </LeftWrap>
                    <SubjectWrap>
                        <h1>{info.subject}</h1>
                    </SubjectWrap>
                </Header>
                <Abstract>
                    <p>{info.abstract}</p>
                </Abstract>
                <DescWrap>
                    {info.card.map((card, index) => (
                        <Card key={index} primary={index % 2 === 0 ? 1 : 0}>
                            <Title primary={index % 2 === 0 ? 1 : 0}>
                                <h2>{card.title}</h2>
                            </Title>
                            <Desc>
                                {card.desc.map((li, index) => (
                                    <li key={index}>
                                        {li}
                                    </li>
                                ))}
                            </Desc>
                        </Card>
                    ))}
                </DescWrap>
            </Wrapper>
        </Container>
    )
}
export default TOS;