import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch, useSelector } from 'react-redux';
import { showMainbarAction } from '../../actions/showMainbarAction';
import { ANContainer, ANWrap, ANNav, HamWrap, MBHamIcon, CancleIcon, ShowBar, CenterWrap, RightWrap, RightLink, HelpIcon, DayIcon, NightIcon, BellIcon, PowerIcon, NumberWrap } from './AuthNavbarElements';
import logo from '../../images/logo-footer.png';

const AuthNavbar = () => {
    const dispatch = useDispatch();
    const isShow = useSelector(state => state.showMainbar);
    const [numberNoti, setNumnberNoti] = useState();
    const [bellHover, setBellHover] = useState(false);
    const [dark, setDark] = useState(localStorage.getItem('lightness') ? true : false);
    const fetchNumberNotifications = async () => {
        const { data } = await axiosInstance.get('notification/navbar/');
        setNumnberNoti(data.number);
    };
    useEffect(() => {
        if (numberNoti === undefined) {
            fetchNumberNotifications();
        };
    });
    const bellClick = () => {
        setTimeout(() => setNumnberNoti(0), 3000);
    };
    const lightnessClick = (status) => {
	if (status === 'dark') {
	    localStorage.setItem('lightness', 'dark');
	    setDark(true);
	} else {
	    localStorage.removeItem('lightness');
	    setDark(false);
	};
    };
    return (
        <>
            <ANContainer>
                <ANWrap>
                    <ANNav>
                        <CenterWrap to='/'>
                            <img src={logo} alt={'IceFaucet'} />
                        </CenterWrap>
                        <HamWrap onClick={() => dispatch(showMainbarAction({ prop: 'toggle' }))}>
                            <MBHamIcon>
                                {isShow ? <CancleIcon /> : <ShowBar />}
                            </MBHamIcon>
                        </HamWrap>
                        <RightWrap>
                            {numberNoti !== undefined && numberNoti > 0 && numberNoti < 100 &&
                                <NumberWrap hover={bellHover ? 1 : 0}>
                                    <span>{numberNoti}</span>
                                </NumberWrap>
                            }
                            {numberNoti !== undefined && numberNoti > 0 && numberNoti >= 100 &&
                                <NumberWrap hover={bellHover ? 1 : 0}>
                                    <span>+99</span>
                                </NumberWrap>
                            }
                            <RightLink to='/notifications'>
                                <BellIcon title='notifications' onClick={bellClick} onMouseEnter={() => setBellHover(true)} onMouseLeave={() => setBellHover(false)} animation={!bellHover && numberNoti > 0 ? 1 : 0} />
                            </RightLink>
                            <RightLink to='/help'>
                                <HelpIcon title='helpdesk' />
                            </RightLink>
	    		    <RightLink to='#'>
	                        {dark ? <NightIcon title='dark' onClick={() => lightnessClick('light')} /> : <DayIcon title='light' onClick={() => lightnessClick('dark')} />}
	                    </RightLink>
                            <RightLink to={'/logout'}>
                                <PowerIcon title='logout' />
                            </RightLink>
                        </RightWrap>
                    </ANNav>
                </ANWrap>
            </ANContainer>
        </>
    )
}
export default AuthNavbar
