import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { SidebarContainer, SidebarWrapper } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { EditAdsForm, TitleWrap, ContentWrap, EditCard, EditInputWrap, EditLinkIcon, EditTextIcon, EditInput, InputTitleWrap } from './EditInfoModalElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { TinyFormButton } from '../ButtonElements';
import { ValidateEdit } from './ValidateEdit';
import { useHistory } from 'react-router-dom';

const EditMiningModal = ({ editClick, setEditClick, id, url, title }) => {
    const history = useHistory();
    const [updateError, setUpdateError] = useState('');
    const [values, setValues] = useState(Object.freeze({
        title: title
    }));
    const [errors, setErrors] = useState({
        title: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [complete, setComplete] = useState(false);
    const [titleChanged, setTitleChanged] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setIsSubmitting(false);
        setErrors({
            title: '',
        });
        name === 'title' && value !== title && setTitleChanged(true);
        name === 'title' && value === title && setTitleChanged(false);
    };

    const fetchEditViewAdsInfo = async () => {
        try {
            await axiosInstance.put('mining/control-ads/edit/', {
                id: id,
                title: values.title
            });
            setComplete(true);
            setTimeout(() => history.go(0), 500);
        } catch (error) {
            error.response && error.response.data.detail ? setUpdateError(error.response.data.detail) : setUpdateError(error.message);
        };
        setIsSubmitting(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateEdit(values));
        setIsSubmitting(true);
    };

    useEffect(() => {
        if (isSubmitting
            && !complete
            && Object.keys(errors).length === 0
            && titleChanged
        ) {
            fetchEditViewAdsInfo();
        };
    });
    return (
        <SidebarContainer isOpen={editClick ? 1 : 0}>
            <SidebarWrapper>
                <CloseIconWrap onClick={() => setEditClick(false)}>
                    <CloseIcon />
                </CloseIconWrap>
                <EditAdsForm action='#' onSubmit={handleSubmit}>
                    <TitleWrap>
                        <h3>CPC: 2</h3>
                    </TitleWrap>
                    <ContentWrap>
                        <EditCard>
                            <EditInputWrap>
                                <EditLinkIcon />
                                <EditInput type='url' value={url} disabled />
                            </EditInputWrap>
                        </EditCard>
                        <EditCard>
                            <InputTitleWrap>
                                <h4>edit title</h4>
                            </InputTitleWrap>
                            <EditInputWrap>
                                <EditTextIcon />
                                <EditInput type='text' name='title' onChange={handleChange} value={values.title} />
                            </EditInputWrap>
                            {errors.title &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        {errors.title}
                                    </MsgText>
                                </MsgWrap>
                            }
                        </EditCard>
                        {!complete && !updateError &&
                            <TinyFormButton type='submit' primary={1} big={1} style={{ marginBottom: '15px', width: '70%' }}>
                                Edit
                            </TinyFormButton>
                        }
                        {complete &&
                            <MsgWrap primary={1}>
                                <MsgText primary={1}>
                                    Your advertisement has been successfully edited.
                                </MsgText>
                            </MsgWrap>
                        }
                        {updateError &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {updateError}
                                </MsgText>
                            </MsgWrap>
                        }
                    </ContentWrap>
                </EditAdsForm>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default EditMiningModal;