import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { BannerTableContainer, BannerImgWrap, BannerImg, BannerDetailWrap, BannerButton } from './BannerTabElements';
import { PromotionContainer, PromotionBody } from './ReferralLinkTabElements';
import { FormLangContainer, FormLangWrap, LangWrap, LangName } from '../SetUpPtc/SetUpPtcFormElements.js';
import { ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements';
import { SizeBannerContainer, SizeBannerWrap } from '../SetUpBanner/SetUpBannerFilter2Elements';
import { langData } from '../../data/langData';
import { bannerSizeData } from '../../data/bannerFiltersData';

const BannerTab = () => {
    const [langName, setLangName] = useState(Object.freeze(''));
    const [langIndex, setLangIndex] = useState();
    const [bannerSize, setBannerSize] = useState(Object.freeze(''));
    const [sizeIndex, setSizeIndex] = useState();
    const [bannerData, setBannerData] = useState();
    const [copied, setCopied] = useState(false);
    const [linkIndex, setLinkIndex] = useState();

    const handleLangClick = (e, index) => {
        const LANG = e.target.getAttribute('name');
        if (LANG && langName !== LANG) {
            setLangName(LANG);
            setLangIndex(index);
        };
    };

    const handleSizeClick = (e, index) => {
        const bannerSizeName = e.target.getAttribute('name');
        if (bannerSizeName && bannerSize !== bannerSizeName) {
            setBannerSize(bannerSizeName);
            setSizeIndex(index);
        };
    };

    const fetchRefBanners = async () => {
        const { data } = await axiosInstance.get('ref/promotion/banners/');
        setBannerData(data);
    };

    const handleDownload = async (item, index) => {
        const { data } = await axiosInstance.get(
            `ref/promotion/banners/download/${item.id}/`, {
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            data.type === 'image/gif' ? `icefaucet-banner-${item.banner_lang}.gif` : `icefaucet-banner-${item.banner_lang}.png`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const handleCopy = (item, index) => {
        linkIndex !== index && setLinkIndex(index);
        if (navigator.clipboard && window.isSecureContext) {
            setCopied(true);
            return navigator.clipboard.writeText(`https://icefaucet.com${item.banner_img}`);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = `https://icefaucet.com${item.banner_img}`;
            textArea.style.position = 'absolute';
            textArea.style.opacity = 0
            document.body.appendChild(textArea);
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopied(true);
            });
        };
    };

    useEffect(() => {
        if (langName.length > 0 && bannerSize.length > 0 && bannerData === undefined) {
            fetchRefBanners();
        };
    });

    return (
        <PromotionContainer>
            <PromotionBody>
                <p>
                    Select the language of banner content.
                </p>
                <FormLangContainer style={{ marginBottom: '15px' }}>
                    <FormLangWrap>
                        {langData.map((lang, index) => (
                            <LangWrap key={index}>
                                <LangName name={lang.value} onClick={(e) => handleLangClick(e, index)} itemActive={langIndex === index ? 1 : 0}>{lang.name}</LangName>
                            </LangWrap>
                        ))}
                    </FormLangWrap>
                </FormLangContainer>
                <p>
                    Select the banner size here. You could download the banner or use its URL.
                </p>
                <SizeBannerContainer style={{ marginTop: '-10px', marginBottom: '15px' }}>
                    <SizeBannerWrap>
                        {bannerSizeData.map((banner, index) => (
                            <ItemWrap key={index}>
                                <ItemName name={banner.db_name} onClick={(e) => handleSizeClick(e, index)} itemActive={sizeIndex === index ? 1 : 0}>
                                    {banner.size}
                                </ItemName>
                            </ItemWrap>
                        ))}
                    </SizeBannerWrap>
                </SizeBannerContainer>

                {bannerData !== undefined && bannerData.map((item, index) => {
                    return item.banner_lang === langName && item.banner_size === bannerSize &&
                        <BannerTableContainer key={index}>
                            <BannerImgWrap primary={index % 2 === 0 ? 1 : 0}>
                                <BannerImg
                                    src={item.banner_img}
                                    alt='icefaucet banners'
                                    size={bannerSize === 'D' ? 'd' : bannerSize === 'E' ? 'e' : null}
                                />
                            </BannerImgWrap>
                            <BannerDetailWrap primary={index % 2 === 0 ? 1 : 0}>
                                <BannerButton onClick={() => handleDownload(item, index)}>
                                    download
                                </ BannerButton>
                                <BannerButton onClick={() => handleCopy(item, index)}>
                                    {copied && linkIndex === index ? 'copied' : 'copy URL'}
                                </BannerButton>
                            </BannerDetailWrap>
                        </BannerTableContainer>
                })
                }
            </PromotionBody>
        </PromotionContainer >
    )
}

export default BannerTab
