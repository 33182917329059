import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const SelectLangContainer = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const SelectLangWrap = styled.div`
    width: 80%;
`
export const LangContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 15px 0 25px 0;
`
export const LangWraper = styled.div`
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media screen and ${device.md} {
        grid-column-gap: 15px;
    }
    @media screen and ${device.tn} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
    }
`