import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from '../ButtonElements';
import { InfoContainer, InfoWrap, InfoRow, Column1, TextWrap, Text, Title, Subtitle, BtnWrap, Column2, ImgWrap } from './InfoElements';

const InfoElements = ({ homeObj }) => {
    const location = useLocation();
    const { code } = useParams();

    return (
        <>
            <InfoContainer lightBg={homeObj.lightBg ? 1 : 0} id={homeObj.id}>
                <InfoWrap>
                    <InfoRow imgStart={homeObj.imgStart ? 1 : 0}>
                        <Column1>
                            <TextWrap>
                                <Text>
                                    <h3>{homeObj.topLine}</h3>
                                    <Title blueText={homeObj.blueText ? 1 : 0}>{homeObj.headLine}</Title>
                                    <Subtitle darkText={homeObj.darkText ? 1 : 0}>{homeObj.description}</Subtitle>
                                </Text>
                                <BtnWrap>
                                    <Button
                                        to={
                                            location.pathname === '/'
                                                ? `${homeObj.link}`
                                                : location.pathname === `/ref/${code}`
                                                    ? `${code}/${homeObj.link}`
                                                    : '#'
                                        }
                                        primary={homeObj.primary ? 1 : 0}
                                        big={homeObj.big ? 1 : 0}
                                        fontbig={homeObj.fontBig ? 1 : 0}
                                    >{homeObj.buttonLable}</Button>
                                </BtnWrap>
                            </TextWrap>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <img src={homeObj.img} alt={homeObj.alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrap>
            </InfoContainer>
        </>
    )
}

export default InfoElements;
