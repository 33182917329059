import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { TinyFormButton, TinyButton } from '../ButtonElements';
import { MsgText, MsgWrap } from '../MessageElements';
import { PlayContainer, Iframe, Bar, HeaderContainer, MsgItem, TitleWrap, StufWrap, AdLinkWrap, LinkWrap, OpenAds, Return, NumberWrap } from './AutoAdsPlayElements';
import { Circle } from '../Loading';
import IcefaCaptcha from '../Captcha/IcefaCaptcha';

const AutoAdsPlay = ({ data, setData }) => {
    const history = useHistory();
    const [width, setWidth] = useState(100);
    const [showMsg, setShowMsg] = useState('');
    const [end, setEnd] = useState('');
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const [iceCaptcha, setIceCaptcha] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const duration = 7;
    let timeStep = 10 * duration;
    const completeTimer = () => {
	setShowMsg(`Press the button to earn ${data.pcoin} ${data.pcoin > 1 ? 'primecoins:' : 'primecoin:'}`);
    };
    useEffect(() => {
	if (width === 0) {
	    if (data.need_captcha) {
		setIceCaptcha(true);
		return;
	    } else {
		setCaptchaDone(true);
	    };
	};
    }, [width, data, captchaDone]);
    useEffect(() => {
	if (captchaDone) {
	    completeTimer();
	};
    });
    useEffect(() => {
	if (Object.keys(data).length > 0) {
	    const timer = setTimeout(() => {
		setWidth(width - 1);
	    }, timeStep);
	    return () => clearTimeout(timer);
	};
    });
    const fetchAutoAdsPlayContinue = async () => {
	try {
	    const { data } = await axiosInstance.get('ptc/auto/play/continue/');
	    setData(data.info);
	    setWidth(100);
	    setShowMsg('');
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const completeHandler = async () => {
	setLoading(true);
        try {
	    await axiosInstance.post('ptc/auto/done/', {
		id: data.id
	    });
	    setIceCaptcha(false);
	    setCaptchaDone(false);
	    data.visitable_number > 1 ? fetchAutoAdsPlayContinue() : handleEnd();
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    const handleEnd = () => {
	setEnd('Wow! You watched all ads.');
	setTimeout(() => {
	    history.push('/auto');
	}, 1500);
    };
    return (
	<PlayContainer>
	    <HeaderContainer>
	        {iceCaptcha && !captchaDone && !showMsg && !apiError &&
		    <IcefaCaptcha appName='auto-ads' setCaptchaDone={setCaptchaDone} dark={1} />
		}
	        {!showMsg && !apiError && !end && !iceCaptcha &&
		    <TitleWrap>
			<h1>{data.title}</h1>
		    </TitleWrap>
		}
	        {showMsg && !apiError && !end &&
		    <MsgItem>
			<MsgWrap primary={1}>
			    <MsgText primary={1}>
				{showMsg}
			    </MsgText>
			</MsgWrap>
			<TinyFormButton type='button' primary={1} big={0} onClick={!loading ? completeHandler : null}>
			    {!loading ? 'done' : <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} />}
			</TinyFormButton>
		    </MsgItem>
		}
	        {end &&
		    <MsgWrap primary={1} style={{width: '80%', margin: 'auto'}}>
			<MsgText primary={1}>
			    {end}
			</MsgText>
		    </MsgWrap>
		}
	        {apiError &&
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
				{apiError}
			    </MsgText>
			</MsgWrap>
			<TinyButton to={'/auto'} primary={1} big={1}>
			    return
			</TinyButton>
		    </MsgItem>
		}
	        <StufWrap>
	            <AdLinkWrap href={data.url} target='_blank'>
	                <OpenAds />
	            </AdLinkWrap>
	            <NumberWrap>
	                <span>
	                    {data.total_number - data.visitable_number + 1}/{data.total_number}
	                </span>
	            </NumberWrap>
	            <LinkWrap to='/auto'>
	                <Return />
	            </LinkWrap>
	        </StufWrap>
	    </HeaderContainer>
	    {width > 0 && <Bar width={width + '%'} />}
	    <Iframe
	        src={data.url}
	    />
	</PlayContainer>
    )
}
export default AutoAdsPlay;
