import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import { useParams } from 'react-router-dom';
import SurfView from '../components/SurfView';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { PtcHeaderWrap, PtcBackPage, PtcNextPage, BackIcon, NextIcon, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../components/PtcView/PtcViewElements';
import { DescWrap } from '../components/History/DepositTabElements';
import { ProgressBarWrap, ProgressBar } from '../components/Redeem/RedeemElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import completeVoice from '../voices/complete.mp3';
import errorVoice from '../voices/error.mp3';

const SurfPage = () => {
    const params = useParams();
    const pageNumber = params.pageNumber ? parseInt(params.pageNumber) : 1;
    const [surfInfo, setSurfInfo] = useState();
    const [doneJS, setDoneJS] = useState(0);
    const [loading, setLoading] = useState(pageNumber !== 1 ? false : true);
    const [error, setError] = useState('');
    const [errorResult, setErrorResult] = useState(false);
    const [completeResult, setCompleteResult] = useState(false);
    useEffect(() => {
        const fetchSurfView = async () => {
	    pageNumber !== 1 && setLoading(true);
            try {
                const { data } = await axiosInstance.get(`ptc/surf/?page=${pageNumber}`);
                setSurfInfo(data);
                localStorage.setItem('surf_page_number', pageNumber);
            } catch (error) {
                error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            };
            setLoading(false);
        };
        pageNumber !== undefined && fetchSurfView();
    }, [pageNumber, doneJS]);
    useEffect(() => {
	if (errorResult && surfInfo !== undefined && surfInfo.device === 'M') {
	    var Audio2Play = new Audio(errorVoice);
	    Audio2Play.play();
	    setErrorResult(false);
	};
	if (completeResult && surfInfo !== undefined && surfInfo.device === 'M') {
	    var AudioPlay = new Audio(completeVoice);
	    AudioPlay.play();
	    setCompleteResult(false);
	};
    }, [errorResult, completeResult, surfInfo]);
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
            {!error && !loading &&
                <PtcHeaderWrap>
                    {pageNumber !== 1 &&
                        <PtcBackPage to={`${pageNumber - 1}`}>
                            <BackIcon />
                        </PtcBackPage>
                    }
                    <PtcInfoWrap>
                        <InfoContainer>
                            <InfoWrap>
                                <TitleInfoWrap>
                                    <h2>number ads</h2>
                                </TitleInfoWrap>
                                <DescWrap>
                                    <h2>{surfInfo !== undefined && surfInfo.all_info.number}</h2>
                                </DescWrap>
                            </InfoWrap>
                            <InfoWrap>
                                <TitleInfoWrap>
                                    <h2>total primecoins</h2>
                                </TitleInfoWrap>
                                <DescWrap>
                                    <h2>{surfInfo !== undefined && surfInfo.all_info.total}</h2>
                                </DescWrap>
                            </InfoWrap>
                        </InfoContainer>
                        <ProgressBarWrap >
                            <ProgressBar width={surfInfo !== undefined && surfInfo.all_info.number > 0 ? (String(surfInfo.all_info.visitable_number / surfInfo.all_info.number * 100) + '%') : 0}>
                                {surfInfo !== undefined && surfInfo.all_info.number > 0 && (surfInfo.all_info.visitable_number / surfInfo.all_info.number * 100) >= 10 &&
                                    <span>{Math.ceil(surfInfo.all_info.visitable_number / surfInfo.all_info.number * 100)}%</span>
                                }
                            </ProgressBar>
                        </ProgressBarWrap>
                    </PtcInfoWrap>
                    {surfInfo !== undefined && surfInfo.is_next &&
                        <PtcNextPage to={`${pageNumber + 1}`}>
                            <NextIcon />
                        </PtcNextPage>
                    }
                </PtcHeaderWrap>
            }
	    {!loading && !error && pageNumber === 1 &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
		        <StackDiscWrap>
			    <p>
			        Please disable your "AdBlock" extensions for the iceFaucet. <br />
			        Please allow the "Pop-ups and redirects" to the iceFaucet on your browser settings. <br />
			        Double your earning by upgrading your account to alpha membership.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer>
	    }
            {surfInfo !== undefined && !error && surfInfo.info.map((ads, index) => (
                <SurfView ads={ads} key={index + 1 + ((pageNumber - 1) * 7)} doneJS={doneJS} setDoneJS={setDoneJS} setErrorResult={setErrorResult} setCompleteResult={setCompleteResult} />
            ))}
            {error &&
                <MsgWrap primary={0} style={{ width: '80%', margin: ' 15px auto' }}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
        </>
    )
}
export default SurfPage;
