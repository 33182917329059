export const SHOW_LOGIN = 'SHOW_LOGIN';
export const NOTSHOW_LOGIN = 'NOTSHOW_LOGIN';
export const SHOW_REG = 'SHOW_REG';
export const NOTSHOW_REG = 'NOTSHOW_REG';
export const ACTIVATE_CODE = 'ACTIVATE_CODE';
export const NOT_ACTIVATE_CODE = 'NOT_ACTIVATE_CODE';
export const CONFRIM_REG = 'CONFRIM_REG';
export const NOT_CONFRIM_REG = 'NOT_CONFRIM_REG';
export const SHOW_RESET = 'SHOW_RESET';
export const NOTSHOW_RESET = 'NOTSHOW_RESET';
export const SHOW_CODE = 'SHOW_CODE';
export const NOTSHOW_CODE = 'NOTSHOW_CODE';
export const SHOW_FORM = 'SHOW_FORM';
export const NOTSHOW_FORM = 'NOTSHOW_FORM';