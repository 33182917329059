import {
    SHOW_BANNERSETUP,
    NOTSHOW_BANNERSETUP,
    SHOW_BANNERFILTERS,
    NOTSHOW_BANNERFILTERS,
    SHOW_BANNERFILTERS2,
    NOTSHOW_BANNERFILTERS2,
    SHOW_BANNERSUCCESS,
    NOTSHOW_BANNERSUCCESS,
} from '../constants/bannerSetUpRedux';

export const bannerSetUpReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_BANNERSETUP):
            return true
        case (NOTSHOW_BANNERSETUP):
            return false
        default:
            return state
    }
};

export const bannerFiltersReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_BANNERFILTERS):
            return true
        case (NOTSHOW_BANNERFILTERS):
            return false
        default:
            return state
    }
};

export const bannerFilters2Reducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_BANNERFILTERS2):
            return true
        case (NOTSHOW_BANNERFILTERS2):
            return false
        default:
            return state
    }
};
export const bannerSuccessReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_BANNERSUCCESS):
            return true
        case (NOTSHOW_BANNERSUCCESS):
            return false
        default:
            return state
    }
};
