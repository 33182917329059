import {
    SHOW_LOGIN,
    NOTSHOW_LOGIN,
    SHOW_REG,
    NOTSHOW_REG,
    ACTIVATE_CODE,
    NOT_ACTIVATE_CODE,
    CONFRIM_REG,
    NOT_CONFRIM_REG,
    SHOW_RESET,
    NOTSHOW_RESET,
    SHOW_CODE,
    NOTSHOW_CODE,
    SHOW_FORM,
    NOTSHOW_FORM
} from "../constants/authRedux";

export const loginTabReducer = (state = true, action) => {
    switch (action.type) {
        case SHOW_LOGIN:
            return true
        case NOTSHOW_LOGIN:
            return false
        default:
            return state
    }
};

export const regTabReducer = (state = false, action) => {
    switch (action.type) {
        case SHOW_REG:
            return true
        case NOTSHOW_REG:
            return false
        default:
            return state
    }
};

export const activateCodeReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIVATE_CODE:
            return true
        case NOT_ACTIVATE_CODE:
            return false
        default:
            return state
    }
};

export const confirmRegReducer = (state = false, action) => {
    switch (action.type) {
        case CONFRIM_REG:
            return true
        case NOT_CONFRIM_REG:
            return false
        default:
            return state
    }
};

export const resetPasswordReducer = (state = false, action) => {
    switch (action.type) {
        case SHOW_RESET:
            return true
        case NOTSHOW_RESET:
            return false
        default:
            return state
    }
};

export const resetCodeReducer = (state = false, action) => {
    switch (action.type) {
        case SHOW_CODE:
            return true
        case NOTSHOW_CODE:
            return false
        default:
            return state
    }
};

export const resetPasswordFormReducer = (state = false, action) => {
    switch (action.type) {
        case SHOW_FORM:
            return true
        case NOTSHOW_FORM:
            return false
        default:
            return state
    }
};