import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import Withdraw from '../components/Withdraw';
import { withdrawalInfo } from '../data/withdrawData';
import { MsgWrap, MsgText } from '../components/MessageElements';

const WithdrawPage = () => {
    const [userIcecoin, setUserIcecoin] = useState();
    const [apiError, setApiError] = useState('');

    const fetchValueIcecoin = async () => {
        try {
            const { data } = await axiosInstance.get('financial/cash-out/value-icecoin/');
            setUserIcecoin(data.icecoin);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    useEffect(() => {
        userIcecoin === undefined && fetchValueIcecoin();
    });
    return (
        <>
            <div style={{ width: '80%', margin: 'auto' }}>
                {apiError &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
            </div>
            {apiError.length === 0 && userIcecoin !== undefined &&
                <Withdraw info={withdrawalInfo} userIcecoin={userIcecoin} />
            }
        </>
    )
}
export default WithdrawPage;