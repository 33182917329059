import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { sponsorSetUpAction, sponsorFiltersAction, sponsorSetUpEndAction } from '../../actions/sponsorSetUpAction';
import { useDispatch } from 'react-redux';
import InteractiveMap from '../SetUpPtc/IntractiveMap';
import { PtcForm, PtcFormInputContainer, FilterLangWrap } from '../SetUpPtc/SetUpPtcFormElements.js';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName, FilterInputWrap, RangeInput, NumberInput, NumberInputWrap, UpcodeWrap } from '../SetUpPtc/SetUpPtcFilterElements';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { TinyFormButton } from '../ButtonElements';
import { FormButtonsWrap } from '../SetUpPtc/CreditCheckElements';

const SetUpSponsorFilter = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState('');
    const [impError, setImpError] = useState('');
    const [geoClick, setGeoClick] = useState(false);
    const [alpha2, setAlpha2] = useState(Object.freeze([]));
    const [userNumber, setUserNumber] = useState();
    const [impressionValue, setImpressionValue] = useState(Object.freeze(10000));
    const [showHelp, setShowHelp] = useState(false);
    const lang = localStorage.getItem('lang') ? 0.2 : 0;
    const country = geoClick ? 0.2 : 0;
    const CPI = 0.5 * (lang + country + 1);
    const totalCost = impressionValue * CPI;
    const upcodeValue = Math.floor(impressionValue * parseFloat(localStorage.getItem('upcode')));
    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(alpha2).length === 0 && geoClick) {
            setErrors('You must select at least one country.');
        } else {
            setErrors('');
        };
        const adscoin = localStorage.getItem('adcoin');
        if (impressionValue < 10000) {
            setImpError('Please enter a number greater than or equal to 10000.');
        } else if (parseInt(adscoin) < totalCost) {
            setImpError('You do not have enogh adscoin.');
        } else if (impressionValue > 1000000) {
            setImpError('Please enter a number less than or equal to 1000000.');
        };
        localStorage.setItem('impression', impressionValue);
        setIsSubmitting(true);
    };
    const handleClick = () => {
        setErrors('');
        setIsSubmitting(false);
        setGeoClick(!geoClick);
    };
    const handleRangeChange = (e) => {
        let number = parseInt(e.target.value)
        if (!isNaN(number)) {
            setImpressionValue(parseInt(number));
        };
        setIsSubmitting(false);
        setImpError('');
    };
    const maxValue = () => {
        const adscoin = parseInt(localStorage.getItem('adcoin'));
        const maximum = Math.floor(adscoin / CPI);
        if (maximum <= 1000000) {
            setImpressionValue(maximum);
        } else if (maximum > 1000000) {
            setImpressionValue(1000000);
        };
        setIsSubmitting(false);
        setImpError('');
    };
    const handleBack = () => {
        dispatch(sponsorFiltersAction({ prop: false }));
        dispatch(sponsorSetUpAction({ prop: true }));
    };
    const fetchCountryUserCkeck = async (mounted) => {
        const { data } = await axiosInstance.post('ptc/advertise/country-user-check/', {
            target_country: alpha2
        });
        localStorage.setItem('target_country', JSON.stringify(alpha2));
        if (mounted) {
            setUserNumber(data.userNumber);
            if (data.userNumber >= 50) {
                fetchCountryFilterCkeck();
                dispatch(sponsorFiltersAction({ prop: false }));
                dispatch(sponsorSetUpEndAction({ prop: true }));
            } else {
                setErrors(`${userNumber} active users could watch your ads, please select more countries.`);
            };
        };
    };
    const fetchCountryFilterCkeck = async () => {
        await axiosInstance.post('sponsor/advertise/country-impression-filter-check/', {
            geo_click: geoClick,
            impression: impressionValue
        });
    };
    const fetchUpCodeUserCkeck = async () => {
        const { data } = await axiosInstance.get('ptc/advertise/upcode-user-check/');
        localStorage.setItem('upcode', data.upcode);
    };
    useEffect(() => {
        !localStorage.getItem('upcode') && fetchUpCodeUserCkeck();
        let mounted = true;
        if (errors.length === 0 && isSubmitting && geoClick) {
            fetchCountryUserCkeck(mounted);
        };
        return () => mounted = false;
    });
    useEffect(() => {
        if (!geoClick && impError.length === 0 && isSubmitting) {
            fetchCountryFilterCkeck();
            localStorage.removeItem('target_country');
            dispatch(sponsorFiltersAction({ prop: false }));
            dispatch(sponsorSetUpEndAction({ prop: true }));
        };
    });
    return (
        <PtcForm action='#' onSubmit={handleSubmit}>
            <PtcFormInputContainer>
                <FormHeader>
                    <h2>where do you like to display your ads?</h2>
                </FormHeader>
                <FilterContainer>
                    <InstructionWrap>
                        <Instruction>
                            If you want to display your ads to only your target countries press the following button.
                        </Instruction>
                    </InstructionWrap>
                    <FilterLangWrap>
                        <ItemWrap >
                            <ItemName onClick={handleClick} itemActive={geoClick ? 1 : 0}>
                                Accept <br />
                                +%25 CPC
                            </ItemName>
                        </ItemWrap>
                    </FilterLangWrap>
                    {errors && geoClick
                        ? <MsgWrap primary={0} >
                            <MsgText primary={0}>
                                {errors}
                            </MsgText>
                        </MsgWrap>
                        : null}
                </FilterContainer>
                {geoClick && <InteractiveMap alpha2={alpha2} setAlpha2={setAlpha2} setIsSubmitting={setIsSubmitting} setErrors={setErrors} />}
                <FormHeader>
                    <h2>Set number of impressions</h2>
                </FormHeader>
                <FilterContainer>
                    <InstructionWrap>
                        <Instruction>
                            It is exactly eqaul with number of user who views your banner, so you are going to get more impressions.
                        </Instruction>
                    </InstructionWrap>
                    <FilterInputWrap>
                        <RangeInput
                            type='range'
                            name='visit'
                            min='10000'
                            max='1000000'
                            value={impressionValue}
                            onChange={handleRangeChange}
                        />
                        <NumberInputWrap>
                            <NumberInput
                                type='number'
                                name='visit'
                                value={impressionValue}
                                onChange={handleRangeChange}
                            />
                            <TinyFormButton type='button' primary={0} big={0} onClick={maxValue}>
                                max
                            </TinyFormButton>
                        </NumberInputWrap>
                        {totalCost >= 250000 &&
                            <UpcodeWrap>
                                <span>
                                    with upcode: {upcodeValue} impressions
                                </span>
                                <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                                <HelpWrap show={showHelp ? 1 : 0}>
                                    <p>If cost of your ads is more than 25$, based on your level, you get more credits. Masternode members get 10% more credit.</p>
                                </HelpWrap>
                            </UpcodeWrap>}
                    </FilterInputWrap>
                    {impError &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {impError}
                            </MsgText>
                        </MsgWrap>
                    }
                </FilterContainer>
            </PtcFormInputContainer>
            <FormButtonsWrap>
                <TinyFormButton type='submit' primary={1} big={0}>
                    Next
                </TinyFormButton>
                <TinyFormButton type='button' primary={1} big={0} onClick={handleBack}>
                    back
                </TinyFormButton>
            </FormButtonsWrap>
        </PtcForm>
    )
};
export default SetUpSponsorFilter;
