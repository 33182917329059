import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import { MsgText, MsgWrap } from '../components/MessageElements';
import { MsgContainer, MsgItem } from '../components/PtcDetail/PtcDetailElements';
import { TinyButton } from '../components/ButtonElements';
import DesktopBanner from '../components/DesktopBanner';
import MobileBanner from '../components/MobileBanner';
import { Circle } from '../components/Loading';

const BannerMiningPage = () => {
    const [device, setDevice] = useState('');
    const [errorDevice, setErrorDevice] = useState('');
    const [error, setError] = useState('');
    const [boolian, setBoolian] = useState(false);
    const [loading, setLoading] = useState(true);
    const fetchBannerMiningCheck = async () => {
        try {
	const { data } = await axiosInstance.get('mining/view/banner/check/');
	if (data.status) {
	    setDevice(data.device);
	};
	} catch (error) {
	    error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    error.response && error.response.data.device && setDevice(error.response.data.device);
	};
	setLoading(false);
    };
    const deviceScreenhandler = () => {
        if (window.innerWidth < 1023 && device === 'D') {
            setErrorDevice('The window width for desktop devices should be more than 1000px!');
        };
        setBoolian(true);
    };
    useEffect(() => {
        if (device.length === 0 && error.length === 0) {
            fetchBannerMiningCheck();
        };
        if (device.length > 0) {
            window.addEventListener('resize', deviceScreenhandler);
            deviceScreenhandler();
	    return () => {
	        window.removeEventListener('resize', deviceScreenhandler);
	    };
        };
    });
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
            {
                !loading && errorDevice.length !== 0 &&
                <MsgContainer>
                    <MsgItem>
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errorDevice}
                            </MsgText>
                        </MsgWrap>
                        <TinyButton to={'/dashboard'} primary={1} big={1}>
                            return
                        </TinyButton>
                    </MsgItem>
                </MsgContainer>
            }
            {!loading && errorDevice.length === 0 && boolian && error.length === 0 && (device === 'D' ? <DesktopBanner /> : device === 'M' ? <MobileBanner /> : null)}
            {!loading && error.length !== 0 &&
                <MsgWrap primary={0} style={{ width: '80%', margin: '10px auto', textAlign: 'center' }}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
        </>
    )
}
export default BannerMiningPage;
