import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { QuestionWrap, Question, AnswerWrap } from './FAQElements';

export const PlayListWrap = styled.div`
    width: 90%;
    margin: 20px auto 0 auto;
    @media screen and ${device.sm} {
        width: 100%;
    };
`
export const VideoCategoryWrap = styled(QuestionWrap)`
`
export const Category = styled(Question)`
`
export const VideoTitleContainer = styled(AnswerWrap)`
    margin-bottom: 10px;
`
export const VideoTitleLinkWrap = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    text-decoration: none;

    span {
        width: 70px;
        text-align: right;
        color: rgb(1, 219, 187);
        font-size: clamp(0.85rem, 2.5vw, 1rem);
    }
`