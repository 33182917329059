export const TOSData = {
    subject: 'terms of service',
    abstract: 'These terms of service apply to you as a user of iceFaucet. You must not register at iceFaucet if you do not agree to the following terms.',
    card: [
        {
            title: 'member terms',
            desc: [
                'You should be adult to register an account.',
                'You are not allowed to create multiple accounts. All these accounts will be terminated.',
                'You should enter correct and real information when you create an account.',
                'You are responsible for keeping your account password safe.',
                'Only you can modify the data in your own accounts.',
                'Accounts are not transferable.',
                'Any attempts to use software/robot to abuse our system will lead to termination of your account.',
                'Your account will be deactivated if you do not login in 60 consecutive days.',
                'Deactivate accounts are able to active their accounts easily in 6 months at most, otherwise their accounts will be terminated.',
                'You should not use Proxy/VPN/TOR while using our service.'
            ]
        },
        {
            title: 'payment terms',
            desc: [
                'The payment address should be unique for each user and can not be shared with another user.',
                'You could use Faucetpay wallet, direct Wallet or both of them to cash out your money. ',
                'The withdrawal minimum amount for the Faucetpay wallet is $0.25 and for the direct Wallet is $10.',
                'All payments should be made via Tether, Bitcoin, Tron, and Dogecoin.'
            ]
        },
        {
            title: 'purchase terms',
            desc: [
                'All deposits on Icefaucet.com are final and non-refundable.',
                'Deposit Adscoin to purchase advertising. Deposit Primecoin to purchase membership and level up your account. Icecoin is not for purchase.',
                'All deposits should be made via Tether, Bitcoin, Tron, and Dogecoin. No other method of payment will be accepted.',
            ]
        },
        {
            title: 'advertising terms',
            desc: [
                'The website must not contain any frame breakers and any viruses.',
                'The websites must not contain or prompt such as download dialogs or confirmation alerts.',
                'The websites must not have a pop-up/pop-under window.',
                'The websites must not contain pornographic, racist, discriminating, vulgar, illegal or other adult materials of any kind.',
                'We reserve the right to deny any advertisement that we do not see fit to be displayed.'
            ]
        },
        {
            title: 'referral terms',
            desc: [
                'All members from any country are welcome to join.',
                'Referral earning are credited only for DIRECTLY referred members.',
                'Some special tools are provided in order to help our members promote their referral links.',
                'You must not send unsolicited email or force anyone in any other way into becoming your referral. You also cannot use the third party service that attempts to sell you referrals.',
                'Buying, selling, and renting referrals on Icefaucet.com is forbidden. We never have any applications for these.'
            ]
        },
        {
            title: 'termination terms',
            desc: [
                'We have the right to terminate the account for a valid reason if you break our Terms of Service.',
                'Any abuse such as: created multiple accounts, using hidden iframe and auto-surfs, hacking, cheating, spamming, steal, abusive referral earnings and sharing network environments will lead to account termination. ',
                'Having a username and profile image that we deem to be inappropriate may lead to the termination of your account.',
                'All terminated accounts will have their earnings forfeited, referrals removed and balance rested to zero.'
            ]
        },
        {
            title: 'rights & liabilities',
            desc: [
                'We are not responsible for any content served through advertisements.',
                'We are not liable for any kind of delays or failures that are not directly related to us and therefore beyond our control. ',
                'We reserve the right to modify the terms of service at any time without notice.'
            ]
        }
    ]
};
