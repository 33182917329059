import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { creditCheckAction, PtcSetUpAction, PtcFiltersAction, PtcFilters2Action } from '../../actions/PtcSetUpAction';
import { ptcSocialData } from '../../data/ptcSocialData';
import { langData } from '../../data/langData';
import { ValidateSetUpForm } from './ValidatePtc';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { PtcForm, PtcFormInputContainer, FilterLangWrap, FormLangContainer, FormLangWrap, LangWrap, LangName, PtcInputWrap, PtcFormInput, FormSocialContainer, FormSocialWrap, SocialIconWrap, SocialImg, PtcLinkIcon, PtcTitleIcon, VideoWrap } from './SetUpPtcFormElements.js';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { FormButtonsWrap } from './CreditCheckElements';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName } from './SetUpPtcFilterElements.js';

const SetUpPtcForm = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [values, setValues] = useState(Object.freeze({
        url: '',
        title: '',
        description: '',
        social1: '',
        social2: '',
        social3: ''
    }));
    const [errors, setErrors] = useState({
        url: '',
        title: '',
        description: ''
    });
    const [urlError, setUrlError] = useState('');
    const [letClick, setLetClick] = useState(true);
    const [errorSocial1, setErrorSocial1] = useState({ social1: '' });
    const [errorSocial2, setErrorSocial2] = useState({ social2: '' });
    const [errorSocial3, setErrorSocial3] = useState({ social3: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [iconNames, setIconNames] = useState([]);
    const [iconIndex, setIconIndex] = useState('');
    const [iconIndexList, setIconIndexList] = useState([]);
    const [langActive, setLangActive] = useState(false);
    const [langName, setLangName] = useState(Object.freeze('en'));
    const [langIndex, setLangIndex] = useState(0);
    const [refClick, setRefClick] = useState(Object.freeze(false));
    const [refError, setRefError] = useState('');
    const [youtubeCode, setYoutubeCode] = useState();
    const [error, setError] = useState('');
    const handleClick = () => {
        setLangActive(!langActive);
        if (langActive) {
            localStorage.removeItem('lang');
        };
    };
    const handleLangClick = (e, index) => {
        const LANG = e.target.getAttribute('name');
        if (LANG && langName !== LANG) {
            setLangName(LANG);
            setLangIndex(index);
        };
        setError('');
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setIsSubmitting(false);
        setErrors({
            url: '',
            title: '',
            description: '',
        });
        setError('');
        setUrlError('');
    };
    const handleBlur = () => {
	if (values.url && !values.url.toLowerCase().includes('//')) {
	    setUrlError('Please enter a correct URL.');
    	} else if (values.url && !values.url.toLowerCase().split('/')[2].includes('youtube.com')) {
            setUrlError('Just youtube videos are supported.');
        } else if (values.url && values.url.split('/watch?v=')[1] === undefined) {
            setUrlError('It seems you have entered an invalid Youtube URL. Your video is visible here if it is valid.');
        } else if (values.url && values.url.split('/watch?v=')[1] !== undefined) {
            setYoutubeCode(values.url.split('?v=')[1]);
        };
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateSetUpForm(values));
        setIsSubmitting(true);
    };
    const handleIconClick = (e, index) => {
        const ICONNAME = e.target.getAttribute('name');
        if (ICONNAME && !iconNames.includes(ICONNAME) && iconNames.length <= 3 && letClick) {
            setIconNames(iconNames => [...iconNames, ICONNAME]);
            setLetClick(false);

            if (iconIndex === index) {
                return setIconIndex(null);
            };
            setIconIndex(index);

            if (!iconIndexList.includes(index)) {
                setIconIndexList(iconIndexList => [...iconIndexList, index]);
            }
        };
    };
    const matchSocial1 = () => {
        if (values.social1) {
            const selectedSocial1 = iconNames.filter(iconName => values.social1.toLowerCase().split('/')[2] && values.social1.toLowerCase().split('/')[2].includes(iconName));
            if (selectedSocial1.length > 0) {
                setLetClick(true);
                setErrorSocial1({
                    social1: ''
                });
            } else {
                setErrorSocial1({
                    social1: 'It has not matched your selected social media.'
                });
                setLetClick(false);
            };
        };
    };
    const matchSocial2 = () => {
        if (values.social2) {
            const selectedSocial2 = iconNames.filter(iconName => values.social2.toLowerCase().split('/')[2] && values.social2.toLowerCase().split('/')[2].includes(iconName));
            if (selectedSocial2.length > 0) {
                setLetClick(true);
                setErrorSocial2({
                    social2: ''
                });
            } else {
                setErrorSocial2({
                    social2: 'It has not matched your selected social media.'
                });
                setLetClick(false);
            };
            if (values.social2 === values.social1) {
                setErrorSocial2({
                    social2: 'You must not entered the same social media link.'
                });
                setLetClick(false);
            };
        };
    };
    const matchSocial3 = () => {
        if (values.social3) {
            const selectedSocial3 = iconNames.filter(iconName => values.social3.toLowerCase().split('/')[2] && values.social3.toLowerCase().split('/')[2].includes(iconName));
            if (selectedSocial3.length > 0) {
                setLetClick(true);
                setErrorSocial3({
                    social3: ''
                });
            } else {
                setErrorSocial3({
                    social3: 'It has not matched your selected social media.'
                });
                setLetClick(false);
            };
            if (values.social3 === values.social2) {
                setErrorSocial3({
                    social3: 'You must not entered the same social media link.'
                });
                setLetClick(false);
            };
            if (values.social3 === values.social1) {
                setErrorSocial3({
                    social3: 'You must not entered the same social media link.'
                });
                setLetClick(false);
            };
        };
    };
    const handleRefClick = () => {
        setRefClick(!refClick);
        setRefError('');
        setIsSubmitting(false);
    };
    const fetchLangUserCkeck = async () => {
        try {
            await axiosInstance.post('ptc/advertise/lang-filter-check/', {
                active_lang: langActive,
                lang: langName,
                title: values.title,
                description: values.description
            });
            urlError.length === 0 && fetchPtcSetUp();
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            setIsSubmitting(false);
        };
    };
    const fetchReferralsCkeck = async () => {
        try {
            await axiosInstance.get('ptc/advertise/referrals-check/');
        } catch (error) {
            error.response && error.response.data.detail ? setRefError(error.response.data.detail) : setRefError(error.message);
            setIsSubmitting(false);
        };
    };
    const fetchPtcSetUp = async () => {
        langActive && localStorage.setItem('lang', langName);
        !langActive && localStorage.removeItem('lang');
        localStorage.setItem('url', values.url);
        localStorage.setItem('title', values.title);
        localStorage.setItem('description', values.description);
        localStorage.setItem('social1', values.social1);
        localStorage.setItem('social2', values.social2);
        localStorage.setItem('social3', values.social3);
        localStorage.setItem('refshow', refClick);
        if (refClick) {
            dispatch(PtcSetUpAction({ prop: false }));
            dispatch(PtcFiltersAction({ prop: true }));
        } else {
            dispatch(PtcSetUpAction({ prop: false }));
            dispatch(PtcFilters2Action({ prop: true }));
        };
        setIsSubmitting(false);
    };
    useEffect(() => {
        refClick && refError.length === 0 && fetchReferralsCkeck();
    });
    useEffect(() => {
        if (Object.keys(errors).length === 0
            && errorSocial1.social1.length === 0
            && errorSocial2.social2.length === 0
            && errorSocial3.social3.length === 0
            && refError.length === 0
            && isSubmitting) {
            fetchLangUserCkeck();
        };
        return () => setIsSubmitting(false);
    });
    return (
        <>
            <PtcForm action='#' onSubmit={handleSubmit}>
                <PtcFormInputContainer>
                    <FormHeader>
                        <h2>Language of your ads</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                If you are sure that visitors understand the language of your ads,
                                press the following button, otherwise, your ads display to all visitors in any language.
                            </Instruction>
                        </InstructionWrap>
                        <FilterLangWrap>
                            <ItemWrap >
                                <ItemName onClick={handleClick} itemActive={langActive ? 1 : 0}>
                                    Accept <br />
                                    +10% CPC
                                </ItemName>
                            </ItemWrap>
                        </FilterLangWrap>
                        <FormLangContainer>
                            <FormLangWrap>
                                {langActive && langData.map((lang, index) => (
                                    <LangWrap key={index}>
                                        <LangName name={lang.value} onClick={(e) => handleLangClick(e, index)} itemActive={langIndex === index ? 1 : 0}>{lang.name}</LangName>
                                    </LangWrap>
                                ))}
                            </FormLangWrap>
                        </FormLangContainer>
                    </FilterContainer>
                    <FormHeader>
                        <h2>describe your ads</h2>
                    </FormHeader>
                    <PtcInputWrap>
                        <PtcLinkIcon />
                        <PtcFormInput
                            type='url'
                            name='url'
                            placeholder={location.pathname === '/setup-ads/video' ? 'Enter your URL of the youtube video address.' : 'Enter your URL address.'}
                            onChange={handleChange}
                            onBlur={location.pathname === '/setup-ads/video' ? handleBlur : null}
                            value={values.url}
                        >
                        </PtcFormInput>
                    </PtcInputWrap>
                    {location.pathname === '/setup-ads/video' && youtubeCode !== undefined &&
                        <VideoWrap>
                            <iframe
                                src={`https://www.youtube.com/embed/${youtubeCode}`}
                                title='preview'
                                frameBorder='0'
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                style={{ width: '100%', height: '100%' }}
                            />
                        </VideoWrap>
                    }
                    {errors.url &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errors.url}
                            </MsgText>
                        </MsgWrap>
                    }
                    {urlError &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {urlError}
                            </MsgText>
                        </MsgWrap>
                    }
                    <PtcInputWrap>
                        <PtcTitleIcon />
                        <PtcFormInput
                            type='text'
                            name='title'
                            placeholder='Enter your ads title.'
                            onChange={handleChange}
                            value={values.title}
                        >
                        </PtcFormInput>
                    </PtcInputWrap>
                    {errors.title &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errors.title}
                            </MsgText>
                        </MsgWrap>
                    }
                    <PtcInputWrap>
                        <PtcTitleIcon />
                        <PtcFormInput
                            type='text'
                            name='description'
                            placeholder='Enter your ads description.'
                            onChange={handleChange}
                            value={values.description}
                        >
                        </PtcFormInput>
                    </PtcInputWrap>
                    {errors.description &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errors.description}
                            </MsgText>
                        </MsgWrap>
                    }
                    {error &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {error}
                            </MsgText>
                        </MsgWrap>}
		    {location.pathname === '/setup-ads/ptc' &&
			<>
                    	    <FormHeader>
                                <h2>add social media</h2>
                    	    </FormHeader>
                    	    <FormSocialContainer>
                        	<FormSocialWrap>
                            	    {ptcSocialData.map((social, index) => (
                                	<SocialIconWrap key={index} iconActive={iconIndex === index
                                    	    ? 'active'
                                    	    : iconIndexList.includes(index)
                                        	? 'clicked'
                                        	: null}>
                                            <SocialImg src={social.img} alt={social.name} name={social.name} onClick={(e) => { handleIconClick(e, index) }} />
                                        </SocialIconWrap>
                            	    ))}
                                </FormSocialWrap>
                            </FormSocialContainer>
                    	    {iconNames.length >= 1 &&
                        	<PtcInputWrap>
                            	    <PtcLinkIcon />
                            	    <PtcFormInput
                                	type='url'
                                	name='social1'
                                	placeholder='Enter your first social link.'
                                	onChange={handleChange}
                                	onBlur={matchSocial1}
                                	value={values.social1}
                            	    >
                            	    </PtcFormInput>
                                </PtcInputWrap>}
                    	    {errorSocial1.social1 &&
                        	<MsgWrap primary={0}>
                            	    <MsgText primary={0}>
                                        {errorSocial1.social1}
                                    </MsgText>
                                </MsgWrap>
                    	    }
                    	    {iconNames.length >= 2 &&
                        	values.social1 &&
                        	<PtcInputWrap>
                            	    <PtcLinkIcon />
                            	    <PtcFormInput
                                	type='url'
                                	name='social2'
                                	placeholder='Enter your second social link.'
                                	onChange={handleChange}
                                	onBlur={matchSocial2}
                                	value={values.social2}
                            	    >
                            	    </PtcFormInput>
                        	</PtcInputWrap>}
                    	    {errorSocial2.social2 &&
                        	<MsgWrap primary={0}>
                            	    <MsgText primary={0}>
                                	{errorSocial2.social2}
                            	    </MsgText>
                        	</MsgWrap>
                    	    }
                    	    {iconNames.length >= 3 && values.social1 && values.social2 &&
                        	<PtcInputWrap PtcInputWrap >
                            	    <PtcLinkIcon />
                            	    <PtcFormInput
                                	type='url'
                                	name='social3'
                                	placeholder='Enter your last social link.'
                                	onChange={handleChange}
                                	onBlur={matchSocial3}
                                	value={values.social3}
                            	    >
                            	    </PtcFormInput>
                            	</PtcInputWrap>}
                    	    {errorSocial3.social3 &&
                                <MsgWrap primary={0}>
                            	    <MsgText primary={0}>
                                	{errorSocial3.social3}
                            	    </MsgText>
                        	</MsgWrap>}
                    	    {iconNames.length >= 4 && <MsgWrap primary={0} >
                       		<MsgText primary={0}>
                            	    Not have been allowed more than three selections!
                        	</MsgText>
                    	    </MsgWrap>}
			</>
		    }
                    <FormHeader>
                        <h2>Do you like display your ads to only your referrals?</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                If you want display your ads to only YOUR REFERRALS press on following button:
                            </Instruction>
                        </InstructionWrap>
                        <FilterLangWrap>
                            <ItemWrap >
                                <ItemName onClick={handleRefClick} itemActive={refClick ? 1 : 0}>
                                    Accept <br />
                                    +250 ref
                                </ItemName>
                            </ItemWrap>
                        </FilterLangWrap>
                        {refError &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {refError}
                                </MsgText>
                            </MsgWrap>
                        }
                    </FilterContainer>
                </PtcFormInputContainer>
                <FormButtonsWrap>
                    <TinyFormButton type='submit' primary={1} big={0}>
                        Next
                    </TinyFormButton>
                    <TinyFormButton
                        type='button'
                        primary={1}
                        big={0}
                        onClick={() => { dispatch(PtcSetUpAction({ prop: false })); dispatch(creditCheckAction({ prop: true })) }}
                    >
                        back
                    </TinyFormButton>
                </FormButtonsWrap>
            </PtcForm>
        </>
    )
}
export default SetUpPtcForm;
