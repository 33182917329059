import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const InteractiveMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 96%;
    margin-top: 10px;
    align-items: center;
    justfy-content: center;
`
export const MapContainer = styled.div`
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 2px 5px rgba(29, 107, 187, 0.7);
    width: 100%;
`
export const MapButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
`
export const MapButtonWrap = styled.div`
    display: grid;
    grid-template-columns : 1fr 1fr;
    grid-column-gap: 10px;

    @media screen and ${device.tn} {
        grid-template-columns : 1fr;
        grid-row-gap: 10px;
    }}
`
export const ButtonHelpWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const InfoCountriesContainer = styled.div`
    display: grid;
    grid-template-columns : 1fr 1fr;
    grid-column-gap: 10px;
    width: 100%;
`
export const InfoCardWrap = styled.div`
    display: flex;
    flex-direction: column;
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 2px 5px rgba(29, 107, 187, 0.7);
    margin-top: 10px;
`
export const InfoCardTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #FFF;
    
    span {
        color: #fff;
        font-size: 0.85rem;
        letter-spacing: 0.5px;
        word-spacing: 1.5px;
        text-transform: uppercase;
        text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
`
export const InfoContentWrap = styled.div`
    width: 100%;
    height: 80px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5); 
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(1, 219, 187, 0.5);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background:rgba(1, 219, 187, 0.5);
    }
`
export const InfoContentMenu = styled.ul`
    list-style: none;
`
export const InfoContentList = styled.div`
    color: #01DBBB;
    font-size: 0.85rem;
    padding: 5px 5px 0 5px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;

    :hover {
        color: rgba(255, 0, 0, 0.8);
        transition: all  ease-in-out 0.3s;
    }
`