import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';
import { DepositTypeWrap, CryptoWrap } from './DepositElements';
import { WContainer, WWrap, TypeCard, ImageWrap, Image, WContext, WContextItem, LimitWrap, CryptoDeactiveWrap, PromoWrap } from '../Withdraw/WithdrawElements';
import { NumberInputWrap, NumberInput } from '../SetUpPtc/SetUpPtcFilterElements';
import { TinyFormButton, FormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import Modal from './Modal';
import moment from 'moment';

const Deposit = ({ info }) => {
    const location = useLocation();
    const [type, setType] = useState(Object.freeze(''));
    const [typeIndex, setTypeIndex] = useState();
    const [apiError, setApiError] = useState('');
    const [errors, setErrors] = useState('');
    const [cryptoGatewayInfo, setCryptoGatewayInfo] = useState();
    const [limitationTime, setLimitationTime] = useState();
    const [cryptoSelectedInfo, setCryptoSelectedInfo] = useState(Object.freeze());
    const [cryptoIndex, setCryptoIndex] = useState();
    const [value, setValue] = useState(Object.freeze(''));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [timer, setTimer] = useState();
    const [payeerInfo, setPayeerInfo] = useState();
    const [customFP, setCustomFP] = useState('');
    const [fetchSignature, setFetchSignature] = useState(false);
    const [fetchCustom, setFetchCustom] = useState(false);
    const coinName = location.pathname === '/deposit/pcoin' ? 'primecoins' : 'adscoins';
    const coin = location.pathname === '/deposit/pcoin' ? 'P' : 'A';
    const handleClick = (e, index) => {
        const typeTarget = e.target.getAttribute('name');
        if (typeTarget && typeTarget !== type) {
            setType(typeTarget);
            setTypeIndex(index);
            fetchLimitationCheck(typeTarget);
            setErrors('');
            setApiError('');
            setCryptoSelectedInfo();
            setCryptoIndex();
            setIsSubmitting(false);
        };
    };
    const fetchLimitationCheck = async (typeTarget) => {
	const coin = location.pathname === '/deposit/pcoin' ? 'P' : 'A';
	try {
	    const { data } = await axiosInstance.get(`financial/cash-in/check/${typeTarget}/${coin}/`);
	    data.status ? setCryptoGatewayInfo(data.info) : setLimitationTime(data.detail);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const selectCrypto = (index, crypto) => {
	if (crypto.active_deposit) {
            setCryptoSelectedInfo(crypto);
	    setCryptoIndex(index);
	    setValue(crypto.min_deposit * 10000);
            setIsSubmitting(false);
	};
    };
    const handleChange = (e) => {
        let number = parseInt(e.target.value);
        if (!isNaN(number)) {
            setValue(parseInt(number));
        };
        setErrors('');
        setIsSubmitting(false);
    };
    const handleSubmit = () => {
        if (value < cryptoSelectedInfo.min_deposit * 10000) {
	    setErrors(`The minimum deposit is ${cryptoSelectedInfo.min_deposit * 10000} ${coinName}.`);
	} else if (value > 9990000) {
	    setErrors(`The maximum withdrawal is ${9990000} ${coinName}.`);
	} else {
	    (type === 'P' || type === 'F') && autoValueSave();
	    type === 'M' && setIsSubmitting(true);
	    type === 'M' && setTimer(Date.now() + 20 * 60 * 1000);
	};
    };
    const autoValueSave = async () => {
	try {
	    await axiosInstance.post('financial/cash-in/auto/set-value/', { 'value': value });
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const fetchPayeerSignature = async () => {
	setFetchSignature(true);
	try {
	    const { data } = await axiosInstance.get('financial/cash-in/payeer/signature/');
	    setPayeerInfo(data);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const fetchFPCustom = async () => {
	setFetchCustom(true);
	try {
	    const { data } = await axiosInstance.get('financial/cash-in/faucetpay/custom/');
	    setCustomFP(data.custom);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    useEffect(() => {
	if (type === 'P' && cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !fetchSignature && !payeerInfo && apiError.length === 0) {
	    fetchPayeerSignature();
	};
	if (type === 'F' && cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !fetchCustom && customFP.length === 0 && apiError.length === 0) {
	    fetchFPCustom();
	};
    });
    return (
        <WContainer>
            <WWrap>
                <DepositTypeWrap>
                    {info.map((item, index) => (
                        <TypeCard key={index} primary={1} active={index === typeIndex ? 1 : 0} cryptoDeactive={0}>
                            <ImageWrap primary={0}>
                                <Image src={item.src} alt={item.alt} title={item.title} />
                            </ImageWrap>
                            <WContext>
                                <WContextItem>
                                    <span>Type:</span>
                                    <span>{item.name}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>max deposit:</span>
                                    <span>{item.max}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>limitation:</span>
                                    <span>{item.limitation}</span>
                                </WContextItem>
                            </WContext>
                            <TinyFormButton type='button' name={item.type} primary={index === typeIndex ? 1 : 0} onClick={(e) => handleClick(e, index)}>
                                select
                            </TinyFormButton>
                        </TypeCard>
                    ))}
                </DepositTypeWrap>
                {cryptoGatewayInfo && Object.keys(cryptoGatewayInfo).length > 0 &&
                    <CryptoWrap>
                        {cryptoGatewayInfo.map((crypto, index) => (
			    <TypeCard key={index} style={{ width: '105px', height: '145px', padding: '7px 0 0 0' }} primary={0} active={cryptoIndex === index ? 1 : 0} cryptoDeactive={!crypto.active_deposit ? 1 : 0}>
				{((coin === 'P' && crypto.promo_pcoin > 0) || (coin === 'A' && crypto.promo_adscoin > 0)) &&
				    <PromoWrap>
					<h4>{coin === 'P' ? crypto.promo_pcoin : crypto.promo_adscoin}%</h4>
				    </PromoWrap>
				}
				<ImageWrap primary={1} style={{ width: '70px', height: '70px' }}>
				    <Image src={crypto.image} alt={crypto.name} />
				    {!crypto.active_deposit &&
					<CryptoDeactiveWrap>
					    <h4>{crypto.reason_deactive_deposit}</h4>
					</CryptoDeactiveWrap>
				    }
				</ImageWrap>
				<TinyFormButton style={{ padding: '3px 7px' }} primary={cryptoIndex === index ? 1 : 0} onClick={() => selectCrypto(index, crypto)}>
				    {crypto.name}
				</TinyFormButton>
				{crypto.network &&
				    <LimitWrap>
					<h4>netwotk:</h4>
					<h4>{crypto.network}</h4>
				    </LimitWrap>
				}
				<LimitWrap>
				    <h4>min deposit:</h4>
				    <h4>${crypto.min_deposit}</h4>
				</LimitWrap>
			    </TypeCard>
			))}
                    </CryptoWrap>
                }
                {cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 &&
                    <NumberInputWrap>
                        <NumberInput
                            type='number'
                            value={value}
                            onChange={handleChange}
                            min={cryptoSelectedInfo.min_deposit * 10000}
                            max={9990000}
                            step={100}
                        />
                        <TinyFormButton type='button' onClick={() => setValue(9990000)} primary={0} big={0} >
                            max
                        </TinyFormButton>
                    </NumberInputWrap>
                }
                {errors &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {errors}
                        </MsgText>
                    </MsgWrap>
                }
                {apiError &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
                {limitationTime &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            You could deposit again {moment(Date.now() + limitationTime * 1000).fromNow()}.
                        </MsgText>
                    </MsgWrap>
                }
                {cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !isSubmitting && !apiError && !limitationTime && !errors && type === 'M' &&
                    <FormButton
                        type='button'
                        style={{ width: '80%', maxWidth: '500px', marginBottom: '15px' }}
                        big={0}
                        primary={1}
                        onClick={() => handleSubmit()}
                    >
                        Deposit
                    </FormButton>
                }
	        {cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && customFP.length > 0 && type === 'F' &&
		    <form action="https://faucetpay.io/merchant/webscr" target="_blank" method="post">
			{value >= 10000 && value <= 9990000 &&
			    <FormButton type="submit" name="submit" style={{ width: '100%', maxWidth: '500px', marginBottom: '15px' }} big={0} primary={1} onClick={() => handleSubmit()} >Deposit</FormButton>
			}
			<input type="hidden" name="merchant_username" value="icefa" />
			<br />
			<input type="hidden" name="item_description" value="deposit to iceFaucet" />
			<br />
			<input type="hidden" name="amount1" value={value / 10000} />
			<br />
			<input type="hidden" name="currency1" value="USD" />
			<br />
			<input type="hidden" name="currency2" value={cryptoSelectedInfo.abbreviation} />
			<br />
			<input type="hidden" name="custom" value={customFP} />
			<br />
			<input type="hidden" name="callback_url" value={`https://icefaucet.com/api/financial/cash-in/faucetpay/callback/${coin}/`} />
			<br />
			<input type="hidden" name="success_url" value="https://icefaucet.com/faucetpay/deposit/success" />
			<br />
			<input type="hidden" name="cancle_url" value="https://icefaucet.com/faucetpay/deposit/fail" />
		    </form>
		}
		{cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && payeerInfo && Object.keys(payeerInfo).length > 0 && type === 'P' &&
		    <form action={isSubmitting ? "https://payeer.com/merchant/" : '#'} target="_blank" method="post">
			{value >= 10000 && value <= 9990000 &&
			    <FormButton type="submit" style={{ width: '100%', maxWidth: '500px', marginBottom: '15px' }} big={0} primary={1} onClick={() => handleSubmit()} > Deposit payeer</FormButton>
			}
			<input type="hidden" name="m_shop" value={payeerInfo.m_shop} />
			<input type="hidden" name="m_orderid" value={payeerInfo.m_orderid} />
			<input type="hidden" name="m_amount" value={(value / 10000).toFixed(2)} />
			<input type="hidden" name="m_curr" value="USD" />
			<input type="hidden" name="m_desc" value={payeerInfo.m_desc} />
			<input type="hidden" name="m_sign" value={payeerInfo.signature} />
			<input type="hidden" name="m_params" value={[payeerInfo.token]} />
			<input type="hidden" name="form[ps]" value="2609" />
			<input type="hidden" name="form[curr[2609]]" value={cryptoSelectedInfo.abbreviation} />
			<input type="hidden" name="status_url" value={`https://icefaucet.com/api/financial/cash-in/payeer/callback/${coin}/`} />
			<input type="hidden" name="success_url" value="https://icefaucet.com/faucetpay/deposit/success" />
			<input type="hidden" name="fail_url" value="https://icefaucet.com/faucetpay/deposit/success" />
		    </form>
		}
		{cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !apiError && !errors && type === 'M' &&
		    <Modal crypto={cryptoSelectedInfo.abbreviation} cryptoName={cryptoSelectedInfo.name} address={cryptoSelectedInfo.deposit_address} value={value} timer={timer} setTimer={setTimer} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
		}
            </WWrap>
        </WContainer >
    )
}
export default Deposit;
