import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { TiTimes } from 'react-icons/ti';

export const CloseIconWrap = styled.div`
    text-align: right;
`
export const CloseIcon = styled(TiTimes)`
    color: #fff;
    font-size: 1.8rem;
    cursor: pointer;
`
export const MoreDetailWrapItem = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr ;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 10px;
`
export const MoreDetailCard = styled.div`
    background: rgba(29, 107, 187, 0.5);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 2px 10px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;

    @media screen and ${device.md} {
        width: 60%;
    }

    @media screen and ${device.sm} {
        width: 80%;
    }

    @media screen and ${device.tn} {
        width: 100%;
    }
`