import React from 'react';
import { useSelector } from 'react-redux';
import { NavPtcContainer, NavPtcItem } from './SetUpAdsNavigateElements';

const SetUpAdsNavigate = () => {
    const showMiningSetUp = useSelector(state => state.miningSetUp);
    const showMiningSetUpEnd = useSelector(state => state.miningSetUpEnd);
    return (
        <NavPtcContainer>
            <NavPtcItem active={showMiningSetUp ? 1 : 0}>
                <span>1</span>
            </NavPtcItem>
            <NavPtcItem active={showMiningSetUpEnd ? 1 : 0}>
                <span>2</span>
            </NavPtcItem>
        </NavPtcContainer>
    )
}
export default SetUpAdsNavigate