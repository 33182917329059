import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { SLCard, LevelInfo, DescSpan, NameWrap, ProgressBarWrap, ProgressBar, ContentWrap, LevelPriceContainer, SpanWrapper, Buttonwrap, HeartIcon, HeartClickedIcon, HelpIcon, HelpClickedIcon, CardContainer, IconContainer, Text } from './SLViewElements';
import { TinyFormButton } from '../ButtonElements';
import { Circle } from '../Loading';
import {
	    CircularProgressbarWithChildren,
	    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from '../Stack/RadialSeparators';
import { MsgWrap, MsgText } from '../MessageElements';
import AssistantCard from './AssistantCard';

const SLView = ({ sl, setClicked, clicked, id, cryptoCoinsAdInfo, bitTrafficInfo, userLevel}) => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [indexClick, setIndexClick] = useState();
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState('');
    const [heartClick, setHeartClick] = useState(false);
    const [helpClick, setHelpClick] = useState(false);
    const [actionLike, setActionLike] = useState(false);
    const [infoAssis, setInfoAssis] = useState();
    const [assisClicked, setAssisClicked] = useState(false);
    const fetchShortLinkClick = async () => {
        setLoading(true);
	try {
	    const { data } = await axiosInstance.get(`shortlink/click/${sl.code}/`);
	    window.open(`${data.url}`, '_blank', 'noreferrer');
	} catch (error) {
		error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	};
	setComplete(true);
	setLoading(false);
    };
    const handleClick = (e) => {
	e.preventDefault();
	!loading && !clicked && !complete && fetchShortLinkClick();
	setClicked(true);
	setIndexClick(id);
    };
    const handleClick2 = (e) => {
	e.preventDefault();
	setError('You should watch the shortlink that already have been clicked.')
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    const handleHeartIconClick = () => {
	!actionLike && setHeartClick(!heartClick);
	helpClick && setHelpClick(false);
    };
    const handleHelpIconClick = () => {
	setHelpClick(!helpClick);
	!actionLike && heartClick && setHeartClick(false);
	fetchAssistantSL();
    };
    const handleLike = (e) => {
	const ACTION = e.target.getAttribute('value');
	fetchLikeSL(ACTION);
    };
    const fetchLikeSL = async (ACTION) => {
	setLoading2(true);
	try {
	    await axiosInstance.post('shortlink/like/', { 'action': ACTION, 'code': sl.code });
	    setActionLike(true);
	    ACTION === 'dislike' && setHeartClick(false);
	} catch (error) {
	    error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	};
	setLoading2(false);
    };
    const fetchAssistantSL = async () => {
	setLoading3(true);
	try {
	    const { data } = await axiosInstance.post('shortlink/assistant/sl/', { 'code': sl.code });
	    setInfoAssis(data);
	} catch (error) {
	    error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	};
	setLoading3(false);
    };
    return (
	<>
	    {id === 0 &&
		<SLCard active={1} style={{ alignItems: 'start', padding: '0 5px', background: 'none' }}>
		    <LevelInfo>
		        <SpanWrapper>
		            <span>easy</span>
		        </SpanWrapper>
		        <DescSpan>
		            {'timer < 1 minute'} <br />
		            {'stage < 3'}
		        </DescSpan>
		    </LevelInfo>
		    <LevelInfo>
		        <SpanWrapper>
		            <span>normal</span>
		        </SpanWrapper>
		        <DescSpan>
		            {'timer < 3 minutes'} <br />
		            {'stage < 6'}
		        </DescSpan>
		    </LevelInfo>
		    <LevelInfo>
		        <SpanWrapper>
		            <span>hard</span>
		        </SpanWrapper>
		        <DescSpan>
		            {'timer > 3 minutes'} <br />
		            {'stage > 6'}
		        </DescSpan>
		    </LevelInfo>
		    <LevelInfo>
		        <SpanWrapper>
		            <span>+x%</span>
		        </SpanWrapper>
		        <DescSpan>
		            x% increase in revenue
		        </DescSpan>
		    </LevelInfo>
		</SLCard>
	    }
	    {(id + 1) % 9 === 0 && cryptoCoinsAdInfo !== undefined && Object.keys(cryptoCoinsAdInfo).length > 0 &&
		<SLCard active={1} style={{ width: '300px', height: '250px', background: 'none' }}>
		    <div>
			<iframe title='cryptocoinsad'
		            src={cryptoCoinsAdInfo.src}
		            style={{ width: `${cryptoCoinsAdInfo.width}px`, height: `${cryptoCoinsAdInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			</iframe>
		    </div>
		</SLCard>
	    }
	    {(id + 1) % 9 === 0 && cryptoCoinsAdInfo !== undefined && Object.keys(cryptoCoinsAdInfo).length === 0 && bitTrafficInfo !== undefined && Object.keys(bitTrafficInfo).length > 0 &&
		<SLCard active={1} style={{ width: '300px', height: '250px', background: 'none' }}>
		    <div>
			<iframe title='bittrafficads'
		            src={bitTrafficInfo.src}
		            style={{ width: `${bitTrafficInfo.width}px`, height: `${bitTrafficInfo.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			</iframe>
		    </div>
		</SLCard>
	    }
	    <SLCard active={sl.sl_level <= userLevel ? 1 : 0}>
	    	{!error && !sl.blocked &&
		    <>
			{((!heartClick && !helpClick) || (actionLike && !helpClick)) &&
			    <CardContainer>
	        	   	<NameWrap><h4>{sl.name}</h4></NameWrap>
	        	    	<ProgressBarWrap title={`${sl.visited}/${sl.visit}`}>
	            	            <ProgressBar width={sl.visited > 0 ? (String(sl.visited / sl.visit * 100) + '%') : 0}>
	                	        <span>{`${9999 < sl.visited && sl.visited <= 999999 ? (sl.visited / 1000).toFixed(1) + 'k' : 999999 < sl.visited ? (sl.visited / 1000000).toFixed(3) + 'm' : sl.visited}/${9999 < sl.visit && sl.visit <= 999999 ? (sl.visit / 1000).toFixed(1) + 'k' : 999999 < sl.visit ? (sl.visit / 1000000).toFixed(3) + 'm' : sl.visit}`}</span>
	            	            </ProgressBar>
	        	    	</ProgressBarWrap>
	        	    	<ContentWrap>
	    	    	            <div style={{ width: '80px', height: '80px' }}>
	    			    	<CircularProgressbarWithChildren
	    		    	            value={sl.number_user_visit / sl.visit_times * 100}
	                    	            maxValue={100}
	                    	            text={`${sl.number_user_visit}/${sl.visit_times}`}
	                    	            strokeWidth={6}
	                    	            styles={buildStyles({
					    	strokeLinecap: "butt",
					    	textColor: 'rgba(1, 219, 187, 0.7)',
					    	pathColor: 'rgba(1, 219, 187, 0.7)',
					    	textSize: '1.2rem'
			                    })}
	                	        >
	    		    	            <RadialSeparators
	                        	    	count={sl.visit_times}
	                        	    	style={{
				    	            background: "#fff",
				    	            width: "2px",
				    	            height: `${6}%`
					        }}
	                    	            />
	    			    	</CircularProgressbarWithChildren>
	    	    	            </div>
	    	    	            <LevelPriceContainer promo={sl.promo > 0 ? 1 : 0}>
	                	    	<SpanWrapper>
	                    	            <span>{sl.level}</span>
	                	    	</SpanWrapper>
	                	        <SpanWrapper>
	                    	            <span>{sl.pcoin} pc</span>
	                	    	</SpanWrapper>
	    			    	{sl.promo > 0 &&
			    	            <SpanWrapper>
					    	<span>+{sl.promo}%</span>
			    	            </SpanWrapper>
				    	}
	            	            </LevelPriceContainer>
	        	    	</ContentWrap>
			    </CardContainer>
		        }
			{heartClick && !actionLike &&
			    <IconContainer>
				{sl.user_liked ?
				    <Text>If you don't like it anymore press the following button:</Text> :
				    <Text>{sl.number_like > 0 ? `${sl.number_like} ${sl.number_like > 1 ? 'users' : 'user'} like this shortlink. The most popular shortlinks get promo. Do you like it?` : 'Be the first user to like this shortlink.'}</Text>
				}
				{loading2 &&
				    <Circle style={{ margin: '15px auto', width: '15px', height: '15px' }} />
				}
				{!loading2 &&
				    <TinyFormButton
					style={{ margin: '15px auto' }}
					primary={1}
					value={sl.user_liked ? 'dislike' : 'like'}
					onClick={handleLike}
					onContextMenu={handleRightClick}
					big={0}
				    >
					{sl.user_liked ? 'dislike' : 'like'}
				    </TinyFormButton>
				}
			    </IconContainer>
			}
			{helpClick &&
			    <IconContainer>
				{loading3 &&
				    <Circle style={{ margin: '60px auto', width: '25px', height: '25px' }} />
				}
				{!loading3 &&
				    <>
					{!assisClicked && <Text style={{ marginLeft: '7px' }}>Choose one of the following assistance:</Text>}
					{infoAssis.info.map((data, index) => (
					    <AssistantCard key={index} info={data} assisClicked={assisClicked} setAssisClicked={setAssisClicked} index={index} slCode={sl.code} />
					))}
				    </>
				}
			    </IconContainer>
			}
		    	<Buttonwrap>
			    {(!heartClick && (!sl.user_liked || actionLike)) ? <HeartIcon onClick={handleHeartIconClick} /> : <HeartClickedIcon onClick={handleHeartIconClick} />}
		            <TinyFormButton
			    	style={{ padding: '6px 35px' }}
			   	primary={clicked && id === indexClick ? 1 : 0}
			    	title={sl.name}
			    	onClick={sl.sl_level <= userLevel ? (clicked ? handleClick2 : handleClick) : null}
			    	onContextMenu={handleRightClick}
			    	big={0}
			    >
			    	{sl.sl_level <= userLevel ? (!loading && clicked && id === indexClick ? 'watching' : !loading ? 'visit' : <Circle style={{ margin: 'auto', width: '15px', height: '15px' }} />) : `level ${sl.sl_level}`}
			    </TinyFormButton>
			    {!helpClick ? <HelpIcon onClick={handleHelpIconClick} animation={sl.sl_assistance ? 1 : 0} /> : <HelpClickedIcon onClick={handleHelpIconClick} />}

		    	</Buttonwrap>
		    </>
		}
	    	{error &&
		    <MsgWrap primary={0} style={{ width: '80%' }}>
			<MsgText primary={0}>
			    {error}
			</MsgText>
		    </MsgWrap>
		}
	        {sl.blocked &&
		    <MsgWrap primary={0} style={{ width: '80%' }}>
			<MsgText primary={0}>
			    {sl.name} will be unblocked after you can level up your shortlink status.
		        </MsgText>
		    </MsgWrap>
		}
	    </SLCard>
	</>
    )
}
export default SLView;

