import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { BigContainer, BannerWrap } from '../DesktopBanner/DesktopBannerElements';
import Sponsor from '../Sponsor';
import { MsgText, MsgWrap } from '../MessageElements';
import { useHistory } from 'react-router-dom';
import { TinyButtonSurf } from '../ButtonElements';
import { Circle } from '../Loading';
import IcefaCaptcha from '../Captcha/IcefaCaptcha';

const MobileBanner = () => {
    const history = useHistory();
    const [banner320Data, setBanner320Data] = useState();
    const [banner300Data, setBanner300Data] = useState();
    const [sponsorData, setSponsorData] = useState();
    const [cryptoCoinsAdInfo2, setCryptoCoinsAdInfo2] = useState();
    const [surfBannerInfo2, setSurfBannerInfo2] = useState();
    const [error, setError] = useState('');
    const [buttonTimer, setButtonTimer] = useState(10);
    const [isFetching, setIsFetching] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [needCaptcha, setNeedCaptcha] = useState(false);
    const [iceCaptcha, setIceCaptcha] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const fetchBanner = async () => {
	setIsFetching(true);
        const { data } = await axiosInstance.get('banner/view/mobile/');
	setNeedCaptcha(data.need_captcha);
        setBanner320Data(data.info_320);
        setBanner300Data(data.info_300);
	setCryptoCoinsAdInfo2(data.cryptocoinsad_info2);
	setSurfBannerInfo2(data.surf_info2);
        fetchSponsor(data);
    };
    const fetchBannerClicked = async (id) => {
        await axiosInstance.post('banner/clicked/', {
            'id': id
        });
    };
    const fetchSponsor = async (info) => {
        try {
            const { data } = await axiosInstance.get('sponsor/view/');
            setSponsorData(data.info);
        } catch (error) {
        };
	setIsFetched(true);
	info.info_300 === null && info.surf_info2 !== null && scriptSurf2(info.surf_info2);
    };
    const scriptSurf2 = (info) => {
	const ins = document.getElementById('surf2');
	ins.className = 'surfe-be';
	ins.setAttribute('data-sid', info.data);
	const script = document.createElement('script');
	script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
	document.body.appendChild(script);
	return new Promise(() => {
	    script.remove();
	});
    };
    const fetchBannerMining = async () => {
	setLoading(true);
        try {
            const { data } = await axiosInstance.get('mining/view/banner/');
            if (data.icecoin !== undefined) {
                setInfo(data);
                setTimeout(() => history.go(0), 1000);
            }
        } catch (error) {
        };
	setLoading(false);
    };
    const handleBannerClicked = (id) => {
        fetchBannerClicked(id);
    };
    const handleRightClick = (e) => {
        e.preventDefault();
    };
    useEffect(() => {
        if (!isFetching && banner320Data === undefined && banner300Data === undefined) {
            fetchBanner();
        };
    });
    useEffect(() => {
        if (isFetched && banner320Data === null && banner300Data === null && sponsorData.length === 0 && cryptoCoinsAdInfo2 && surfBannerInfo2 === null) {
            setError('There are not any advertisements to continue mining, come back later.')
        };
    }, [isFetched, banner320Data, banner300Data, sponsorData, cryptoCoinsAdInfo2, surfBannerInfo2]);
    useEffect(() => {
	if (buttonTimer === 0 && !error) {
	    if (needCaptcha) {
		setIceCaptcha(true);
		return;
	    };
	};
	if (buttonTimer > 0 && !error) {
	    const timer = setTimeout(() => {
		setButtonTimer(buttonTimer - 1);
	    }, 1000);
	    return () => clearTimeout(timer);
	};
    }, [needCaptcha, buttonTimer, error]);
    useEffect(() => {
	if (captchaDone) {
	    setIceCaptcha(false);
	};
    }, [captchaDone]);
    return (
        <>
	    {isFetched && (banner320Data !== null || banner300Data !== null || sponsorData.length > 0 || (cryptoCoinsAdInfo2 !== undefined && cryptoCoinsAdInfo2 !== null) || (surfBannerInfo2 !== undefined && surfBannerInfo2 !== null)) &&
                <BigContainer>
                    {
                        banner320Data !== null &&
                        <BannerWrap href={banner320Data.url} target='_blank' onClick={() => handleBannerClicked(banner320Data.banner_id)} onContextMenu={handleRightClick}>
                            <img src={banner320Data.banner_img ? banner320Data.banner_img : banner320Data.banner_url ? banner320Data.banner_url : null} alt={banner320Data.title} title={banner320Data.url.split('/')[2]} />
                        </BannerWrap>
                    }
                    {
                        sponsorData.length !== 0 &&
                        <div style={{ marginBottom: '25px' }}>
                            <Sponsor data={sponsorData} />
                        </div>
                    }
		    {banner300Data === null && cryptoCoinsAdInfo2 !== undefined && cryptoCoinsAdInfo2 !== null &&
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto auto 25px auto' }}>
			    <iframe title='a-ads'
			        src={cryptoCoinsAdInfo2.src}
			        style={{ width: `${cryptoCoinsAdInfo2.width}px`, height: `${cryptoCoinsAdInfo2.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			    </iframe>
			</div>
		    }
		    {surfBannerInfo2 !== undefined && surfBannerInfo2 !== null &&
			<div style={{ margin: 'auto auto 25px auto' }}>
			    <ins id='surf2'></ins>
			</div>
		    }
                    {
                        banner300Data !== null &&
                        <BannerWrap href={banner300Data.url} target='_blank' onClick={() => handleBannerClicked(banner300Data.banner_id)} onContextMenu={handleRightClick}>
                            <img src={banner300Data.banner_img ? banner300Data.banner_img : banner300Data.banner_url ? banner300Data.banner_url : null} alt={banner300Data.title} title={banner300Data.url.split('/')[2]} />
                        </BannerWrap>
                    }
                </BigContainer >
            }
            {error &&
                <MsgWrap primary={0} style={{ width: '80%', margin: '10px auto', textAlign: 'center' }}>
                    <MsgText primary={0}>
                        {error}
                    </MsgText>
                </MsgWrap>
            }
            {info !== undefined &&
                <MsgWrap primary={1} style={{ width: '80%', margin: '0 auto 15px auto', textAlign: 'center' }}>
                    <MsgText primary={1}>
                        You mined {info.icecoin} icecoin and earned {(info.adscoin).toFixed(2)} adscoin.
                    </MsgText>
                </MsgWrap>
            }
            {!error && isFetched && info === undefined && !iceCaptcha &&
                <TinyButtonSurf
                    style={{ width: '80%', margin: '0 auto 15px auto' }}
                    type='button'
                    onClick={buttonTimer === 0 && !loading ? fetchBannerMining : null}
                    onContextMenu={handleRightClick}
                    big={1}
                >
		    {buttonTimer > 0 ? buttonTimer : loading ? <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} /> : 'click'}
                </TinyButtonSurf>
            }
	    {iceCaptcha && !captchaDone &&
		<IcefaCaptcha appName='banner-mining' setCaptchaDone={setCaptchaDone} dark={0} />
	    }
        </>
    )
}
export default MobileBanner;
