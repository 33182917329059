export const heroHomeData = {
    src: require('../images/home.webp').default,
    alt: 'Home background',
    headingBlue: true,
    headingText1: 'iceFaucet Deep Online Earnings Platform',
    headingText2: 'Many Ways To Earn Online',
    headingText3: 'Enjoy Earnings Online Everyday',
    description1: 'users have joined us so far. They earned ',
    description10: 'and received their money INSTANTLY!',
    description2: 'Take a survey, do a task, test apps, and play games to earn massively.',
    description3: 'Earn magic income by promoting your referral link.',
    description4: 'View advertisements and earn easily.',
    description5: 'Claim up to $0.03 every hour by using our Faucet. On iceday claim every 10 minutes!',
    description6: 'Lucky wheel, lottery, weekly offerwall contest and weekly shortlink contest with INFINITY rewards!',
    buttonBlue: true,
    buttonText: 'Get Start'
};

export const langHeroHomeData = [
    {
        lang: 'es',
        info: {
            src: require('../images/home.webp').default,
            alt: 'Fondo de casa',
            headingBlue: true,
            headingText1: 'Plataforma de ganancias en línea profunda iceFaucet',
	    headingText2: 'Muchas formas de ganar dinero en línea',
	    headingText3: 'Disfrute ganando en línea todos los días',
	    description1: 'usuarios se han unido a nosotros hasta el momento. ¡Ganaron ',
	    description10: 'y recibieron su dinero al instante!',
	    description2: 'Realice una encuesta, realice una tarea, pruebe aplicaciones y juegue para ganar mucho dinero.',
	    description3: 'Obtenga ingresos mágicos promocionando su enlace de referencia.',
	    description4: 'Vea anuncios y gane fácilmente.',
	    description5: 'Reclama hasta $0.03 cada hora usando nuestro Faucet. ¡En Iceday reclama cada 10 minutos!',
	    description6: 'Rueda de la suerte, lotería, concurso semanal de Offerwall y concurso semanal de enlaces cortos con recompensas infinitas.',
            buttonBlue: true,
            buttonText: 'Comenzar'
        }

    },
    {
        lang: 'ru',
        info: {
            src: require('../images/home.webp').default,
            alt: 'Домашний фон',
            headingBlue: true,
            headingText1: 'iceFaucet платформа для глубокого онлайн-заработка',
	    headingText2: 'Множество способов заработать в Интернете',
	    headingText3: 'Наслаждайтесь заработком в Интернете каждый день',
	    description1: 'пользователей Присоединились. Они заработали',
	    description10: 'доллар и мгновенно получили свои деньги!',
	    description2: 'Проходите опрос, выполняйте задания, тестируйте приложения и играйте в игры, чтобы заработать огромные деньги.',
	    description3: 'Получайте волшебный доход, продвигая свою реферальную ссылку.',
	    description4: 'Просматривайте рекламу и легко зарабатывайте.',
	    description5: 'Зарабатывайте до 0,03 доллара каждый час, используя наш кран. В ледовый день зарабатывайте каждые 10 минут!',
	    description6: 'Колесо удачи, лотерея, еженедельный конкурс Offerwall и еженедельный конкурс коротких ссылок с бесконечными наградами.',
            buttonBlue: true,
            buttonText: 'начинать'
        }
    },
    {
        lang: 'pt',
        info: {
            src: require('../images/home.webp').default,
            alt: 'Fundo de casa',
            headingBlue: true,
            headingText1: 'Plataforma de ganhos online profundos iceFaucet',
	    headingText2: 'Muitas maneiras de ganhar online',
	    headingText3: 'Aproveite para ganhar online todos os dias',
	    description1: 'usuários se juntaram a nós até agora. Eles ganharam ',
	    description10: 'e receberam seu dinheiro instantaneamente!',
	    description2: 'Responda a uma pesquisa, execute uma tarefa, teste aplicativos e jogue para ganhar muito.',
	    description3: 'Ganhe uma renda mágica promovendo seu link de indicação.',
	    description4: 'Veja anúncios e ganhe facilmente.',
	    description5: 'Reivindique até US$ 0,03 por hora usando nossa Torneira. No dia do gelo, reivindique a cada 10 minutos!',
	    description6: 'Roda da sorte, loteria, concurso semanal de Offerwall e concurso semanal de shortlink com recompensas infinitas.',
            buttonBlue: true,
            buttonText: 'Começar'
        }
    },
    {
        lang: 'fr',
        info: {
            src: require('../images/home.webp').default,
            alt: 'Contexte de la maison',
            headingBlue: true,
            headingText1: 'Plateforme de gains en ligne profonde iceFaucet',
	    headingText2: 'De nombreuses façons de gagner en ligne',
	    headingText3: 'Profitez de gagner en ligne tous les jours',
	    description1: "utilisateurs nous ont rejoint jusqu'à présent. Ils ont gagné  ",
	    description10: 'et ont reçu leur argent instantanément!',
	    description2: 'Répondez à une enquête, effectuez une tâche, testez des applications et jouez à des jeux pour gagner énormément.',
	    description3: 'Gagnez un revenu magique en faisant la promotion de votre lien de parrainage.',
	    description4: 'Regardez des publicités et gagnez facilement.',
	    description5: "Réclamez jusqu'à 0,03$ par heure en utilisant notre robinet. Le jour de la glace, réclamez toutes les 10 minutes!",
	    description6: 'Roue de la chance, loterie, concours hebdomadaire Offerwall et concours hebdomadaire de liens courts avec des récompenses infinies.',
            buttonBlue: true,
            buttonText: 'Commencer'
        }
    },
    {
        lang: 'de',
        info: {
            src: require('../images/home.webp').default,
            alt: 'Home-Hintergrund',
            headingBlue: true,
            headingText1: 'Die umfassende Online-Verdienstplattform von iceFaucet',
	    headingText2: 'Viele Möglichkeiten, online zua verdienen',
	    headingText3: 'Genießen Sie es, jeden Tag online zu verdienen',
	    description1: 'Benutzer angeschlossen haben sich uns . Sie haben ',
	    description10: 'verdient und ihr Geld sofort erhalten!',
	    description2: 'Nehmen Sie an einer Umfrage teil, erledigen Sie eine Aufgabe, testen Sie Apps und spielen Sie Spiele, um massiv zu verdienen.',
	    description3: 'Verdienen Sie magisches Einkommen, indem Sie Ihren Empfehlungslink bewerben.',
	    description4: 'Sehen Sie sich Werbung an und verdienen Sie ganz einfach.',
	    description5: 'Fordern Sie mit unserem Faucet jede Stunde bis zu 0,03 $ ein. An Eistagen alle 10 Minuten abrufbar!',
	    description6: 'Wheel of Fortune, Lottery, Weekly Offerwall Contest and Weekly Shortlink Contest with infinite rewards.',
            buttonBlue: true,
            buttonText: 'Start'
        }
    },
    {
        lang: 'it',
        info: {
            src: require('../images/home.webp').default,
            alt: 'Sfondo domestico',
            headingBlue: true,
            headingText1: 'iceFaucet piattaforma di guadagni online profonda',
	    headingText2: 'Molti modi per guadagnare online',
	    headingText3: 'Divertiti a guadagnare online ogni giorno',
	    description1: 'utenti finora si sono uniti a noi. Hanno guadagnato ',
	    description10: 'e hanno ricevuto i loro soldi immediatamente!',
	    description2: "Partecipa a un sondaggio, svolgi un'attività, prova le app e gioca per guadagnare tantissimo.",
	    description3: 'Guadagna entrate magiche promuovendo il tuo link di riferimento.',
	    description4: 'Guarda gli annunci pubblicitari e guadagna facilmente.',
	    description5: 'Richiedi fino a $ 0,03 ogni ora utilizzando il nostro rubinetto. Su iceday richiedi ogni 10 minuti!',
	    description6: 'Ruota della fortuna, lotteria, concorso Offerwall settimanale e concorso shortlink settimanale con premi infiniti.',
            buttonBlue: true,
            buttonText: 'Inizio'
        }
    }
];
export const heroMakeMoneyData = {
    src: require('../images/make-money.jpg').default,
    alt: 'Make money background',
    headingBlue: false,
    headingText: 'Better earn iceFaucet',
    description: 'Enjoy earnings online with many ways and many features, start today!',
    buttonBlue: false,
    buttonText: 'Claim Now'
};
export const langHeroMakeMoneyData = [
    {
        lang: 'es',
        info: {
            src: require('../images/make-money.jpg').default,
            alt: 'Ganar dinero de fondo',
            headingBlue: false,
            headingText: 'Mejor gana iceFaucet',
	    description: 'Disfrute ganando en línea de muchas formas y funciones, ¡comience hoy!',
            buttonBlue: false,
            buttonText: 'Ganar Ahora'
        }
    },
    {
        lang: 'ru',
        info: {
            src: require('../images/make-money.jpg').default,
            alt: 'Заработать деньги фон',
            headingBlue: false,
            headingText: 'Лучше зарабатывайте на iceFaucet',
	    description: 'Наслаждайтесь заработком в Интернете разными способами и множеством функций, начните сегодня!',
            buttonBlue: false,
            buttonText: 'Заработай сейчас'
        }
    },
    {
        lang: 'pt',
        info: {
            src: require('../images/make-money.jpg').default,
            alt: 'ganhar dinheiro fundo',
            headingBlue: false,
            headingText: 'Melhor ganhar iceFaucet',
	    description: 'Aproveite para ganhar online de muitas maneiras e recursos, comece hoje mesmo!',
            buttonBlue: false,
            buttonText: 'Ganhe Agora'
        }
    },
    {
        lang: 'fr',
        info: {
            src: require('../images/make-money.jpg').default,
            alt: "Gagnez de l'argent",
            headingBlue: false,
            headingText: "Mieux vaut gagner iceFaucet",
	    description: "Profitez de gagner de l'argent en ligne de nombreuses manières et de nombreuses fonctionnalités, commencez dès aujourd'hui!",
            buttonBlue: false,
            buttonText: 'Gagnez maintenant'
        }
    },
    {
        lang: 'de',
        info: {
            src: require('../images/make-money.jpg').default,
            alt: 'Hintergrund Geld verdienen',
            headingBlue: false,
            headingText: 'Verdiene dir besser IceFaucet',
	    description: 'Genießen Sie das Online-Verdienen mit vielen Möglichkeiten und vielen Funktionen, beginnen Sie noch heute!',
            buttonBlue: false,
            buttonText: 'Jetzt verdienen'
        }
    },
    {
        lang: 'it',
        info: {
            src: require('../images/make-money.jpg').default,
            alt: 'Guadagna sfondo di denaro',
            headingBlue: false,
            headingText: 'Meglio guadagnare iceFaucet',
	    description: "Divertiti a guadagnare online con tanti modi e tante funzionalità, inizia oggi!",
            buttonBlue: false,
            buttonText: 'Guadagna ora'
        }
    },
];
export const heroAdvertiseData = {
    src: require('../images/advertise.jpg').default,
    alt: 'Advertising background',
    headingBlue: true,
    headingText: 'Display your ads only to your target audience',
    description: 'Advertise with many filters and receive much useful data about your ads. We are proud that the delivered traffic is 100% real.',
    buttonBlue: true,
    buttonText: 'Advertise Now'
};
export const langHeroAdvertiseData = [
    {
        lang: 'es',
        info: {
            src: require('../images/advertise.jpg').default,
            alt: 'Fondo publicitario',
            headingBlue: true,
            headingText: 'Muestre sus anuncios solo a su público objetivo',
	    description: 'Anuncie con muchos filtros y reciba muchos datos útiles sobre sus anuncios. Estamos orgullosos de que el tráfico entregado sea 100% real.',
	    buttonBlue: true,
            buttonText: 'Anúnciate ahora'
        }
    },
    {
        lang: 'ru',
        info: {
            src: require('../images/advertise.jpg').default,
            alt: 'Рекламный фон',
            headingBlue: true,
            headingText: 'Показывайте свою рекламу только вашей целевой аудитории',
	    description: 'Рекламируйтесь с помощью множества фильтров и получайте много полезных данных о своих объявлениях. Мы гордимся тем, что доставленный трафик на 100% реален.',
            buttonBlue: true,
            buttonText: 'Рекламировать сейчас'
        }
    },
    {
        lang: 'pt',
        info: {
            src: require('../images/advertise.jpg').default,
            alt: 'Fundo de publicidade',
            headingBlue: true,
            headingText: 'Exiba seus anúncios apenas para seu público-alvo',
	    description: 'Anuncie com diversos filtros e receba muitos dados úteis sobre seus anúncios. Estamos orgulhosos de que o tráfego entregue seja 100% real.',
            buttonBlue: true,
            buttonText: 'Anuncie agora'
        }
    },
    {
        lang: 'fr',
        info: {
            src: require('../images/advertise.jpg').default,
            alt: 'Fond publicitaire',
            headingBlue: true,
            headingText: "Diffusez vos annonces uniquement auprès de votre public cible",
	    description: 'Faites de la publicité avec de nombreux filtres et recevez de nombreuses données utiles sur vos annonces. Nous sommes fiers que le trafic délivré soit 100 % réel.',
            buttonBlue: true,
            buttonText: 'Annoncez maintenant'
        }
    },
    {
        lang: 'de',
        info: {
            src: require('../images/advertise.jpg').default,
            alt: 'Werbehintergrund',
            headingBlue: true,
            headingText: 'Zeigen Sie Ihre Anzeigen nur Ihrer Zielgruppe an',
	    description: 'Werben Sie mit vielen Filtern und erhalten Sie viele nützliche Daten zu Ihren Anzeigen. Wir sind stolz darauf, dass der gelieferte Traffic zu 100 % echt ist.',
            buttonBlue: true,
            buttonText: 'Jetzt werben'
        }
    },
    {
        lang: 'it',
        info: {
            src: require('../images/advertise.jpg').default,
            alt: 'Sfondo pubblicitario',
            headingBlue: true,
            headingText: 'Zeigen Sie Ihre Anzeigen nur Ihrer Zielgruppe an',
	    description: 'Werben Sie mit vielen Filtern und erhalten Sie viele nützliche Daten zu Ihren Anzeigen. Wir sind stolz darauf, dass der gelieferte Traffic zu 100 % echt ist.',
            buttonBlue: true,
            buttonText: 'Pubblicizza ora'
        }
    }
];
