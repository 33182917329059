import React, { useEffect, useState } from 'react';
import PtcPlay from '../components/PtcPlay';
import axiosInstance from '../axios';
import InvalidToken from '../components/InvalidToken';
import { MessageContainer, MsgItem } from '../components/PtcPlay/PtcPlaylements';
import { MsgText, MsgWrap } from '../components/MessageElements';
import { TinyButton } from '../components/ButtonElements';
import { MotherContainer } from '../components/PtcDetail/PtcDetailElements';

const PtcPlayPage = ({ match }) => {
    const [data, setData] = useState([]);
    const [invalidToken, setInvalidToken] = useState(false);
    const [error, setError] = useState('');
    const removeTime = () => localStorage.removeItem(`clickTimer:${match.params.code}`);
    const dark = localStorage.getItem('lightness') ? true : false;
    const fetchPtcPlay = async () => {
        try {
            const { data } = await axiosInstance.get(`ptc/view/${match.params.code}/${match.params.uidb64}/${match.params.token}/`);
            setData(data);
        } catch (error) {
            error.response && error.response.data.detail && !error.response.data.invalid ? setError(error.response.data.detail) : setError(error.message);
	    error.response.data.invalid && setInvalidToken(true);
        };
    };
    useEffect(() => {
        if (data.length === 0 && error.length === 0 && !invalidToken) {
            fetchPtcPlay();
        }
    });
    return (
        <MotherContainer dark={dark ? 1 : 0}>
	    {data.length !== 0 && !invalidToken && error.length === 0 && <PtcPlay data={data} removeTime={removeTime} />}
	    {!invalidToken && error.length !== 0 &&
		<MessageContainer>
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
				{error}
			    </MsgText>
			</MsgWrap>
			<TinyButton to={`/ptc/${localStorage.getItem('ptc_page_number')}`} primary={1} big={1}>
			    return
			</TinyButton>
		    </MsgItem>
		</MessageContainer>
	    }
	    {invalidToken && <InvalidToken />}
        </MotherContainer>
    )
};
export default PtcPlayPage;
