import React, { useState } from 'react';
import { HelpWrap } from '../HelpElements';
import { OfferCard, LogoWrap, Logo, AdscoinPromoWrap, AdscoinPromoSpan } from './CardsElements';

const Cards = ({ offer, index }) => {
    const [rateClick, setRateClick] = useState(false);
    const handleRateClick = () => {
	setRateClick(!rateClick);
    };
    return (
	<OfferCard primary={index % 2 !== 0 ? 1 : 0}>
	    {!rateClick &&
		<LogoWrap>
		    <Logo src={offer.logo} alt={offer.name} />
		</LogoWrap>
	    }
	    {rateClick &&
		<HelpWrap style={{ margin: '0 auto 7px auto', background: 'rgba(0, 0,.0, 0.2)' }} show={rateClick ? 1 : 0}>
		    <p>
			Currently, the conservation rate of {offer.name} company is %{offer.rate}.
		    </p>
		</HelpWrap>
	    }
	    <AdscoinPromoWrap onClick={() => handleRateClick()} style={{ top: '0', left: '0' }}>
	        <AdscoinPromoSpan>
	            %{offer.rate}
	        </AdscoinPromoSpan>
	    </AdscoinPromoWrap>
	</OfferCard >
    )
}

export default Cards;
