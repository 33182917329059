import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { Timer, LotteryHeaderWrap, LotteryInfo, LotteryContainer, LotteryInfoWrap, LotteryTitle, LotteryDesc } from '../Lottery/LotteryElements';
import { LevelContainer, LevelWrap, ProgressBarWrap, ProgressBar, Desc1, Desc2, Ring, RewardShower, BaseReward, ImgWrap, InfinityRingWrap, InfinityImage, InfinityLevelWrap, InfinityProgressBarWrap, InfinityDescWrap, InfinityDesc, InfinityRing, Cell, CellWrap, CrownsContainer, CrownWrap, Golden, Silver, Bronze } from './ContestElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { Circle } from '../Loading';
import Countdown, { zeroPad } from 'react-countdown';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow } from '../TaskView/TaskPageElements';
import { offerLiveContestTableHead, offerSmallLiveContestTableHead, offerInfinityReward, offerSmallInfinityReward, infinityRewardTableHead, offerInfinityRewardTableBody } from '../../data/contestData';
import moment from 'moment';
import { HelpIcon, HelpWrap } from '../HelpElements';

const Contest = ({ smallVeiw, smallVeiw2 }) => {
    const [info, setInfo] = useState();
    const [table, setTable] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const handleClick = () => {
	setShowHelp(!showHelp);
    };
    const renderer = ({ days, hours, minutes, seconds }) => {
	return <Timer>{zeroPad(days)} days + {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    useEffect(() => {
	const fetchLiveOfferContestInfo = async () => {
	    try {
		const { data } = await axiosInstance.get('earning/contest/offer/live/');
		setInfo(data.info);
		setTable(data.table);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchLiveOfferContestInfo();
    }, []);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && !apiError && info !== undefined &&
		<Countdown
		    date={Date.now() + info.timer * 1000}
		    renderer={renderer}
		/>
	    }
	    {!loading && !apiError && info !== undefined &&
		<LotteryHeaderWrap>
		    <LotteryInfo >
			<LotteryTitle style={{ display: 'flex', justifyContent: 'center' }}>
			    <h2>infinity reward</h2>
			    <HelpIcon style={{ left: '15%' }} onClick={() => handleClick()} show={showHelp ? 1 : 0} />
			</LotteryTitle>
			{!showHelp && info.level !== 6 &&
			    <>
				<LevelContainer>
				    {!smallVeiw2 && offerInfinityReward.map((item, index) => (
					<LevelWrap key={index}>
					    <Desc1 active={item.level === info.level ? 1 : 0}>{item.winners}</Desc1>
					    <Ring active={index === 0 || (item.level < info.level) || (item.level === info.level) ? 1 : 0} />
					    <ProgressBarWrap>
					        <ProgressBar width={item.reward < info.base_reward && item.level === info.level && info.level !== 6 ? (String((info.base_reward - item.reward) / (item.next_reward - item.reward) * 100)) + '%' : item.level < info.level ? '100%' : '0'} />
					    </ProgressBarWrap>
					    <Desc2 active={item.level === info.level ? 1 : 0}>{item.up}</Desc2>
					</LevelWrap>
				    ))}
				    {smallVeiw2 && offerSmallInfinityReward.map((item, index) => (
					<LevelWrap key={index}>
					    <Desc1 active={item.level === info.level ? 1 : 0}>{item.winners}</Desc1>
					    <Ring active={index === 0 || (item.level < info.level) || (item.level === info.level) ? 1 : 0} />
					    <ProgressBarWrap>
					        <ProgressBar width={item.reward < info.base_reward && item.level === info.level ? (String((info.base_reward - item.reward) / (item.next_reward - item.reward) * 100)) + '%' : item.level < info.level ? '100%' : '0'} />
					    </ProgressBarWrap>
					    <Desc2 active={item.level === info.level ? 1 : 0}>{item.up}</Desc2>
					</LevelWrap>
				    ))}
				</LevelContainer>
				<RewardShower>
				    <BaseReward>
				        <h2>Prime Reward</h2>
				    </BaseReward>
				    <BaseReward style={{ background: 'rgba(29, 107, 187, 0.35)', width: '50%', height: '100%', borderRadius: '0 10px 10px 0' }}>
				        <h2 style={{ color: '#fff' }}>{info.base_reward}</h2>
				    </BaseReward>
				</RewardShower>
			    </>
			}
		        {!showHelp && info.level === 6 &&
			    <>
				<InfinityLevelWrap>
				    <InfinityRingWrap>
					<InfinityRing active={1} />
				    </InfinityRingWrap>
				    <InfinityProgressBarWrap>
					<ProgressBar width={'100%'} />
				    </InfinityProgressBarWrap>
				    <InfinityDescWrap>
					<InfinityDesc>15</InfinityDesc>
					<InfinityDesc>x 2.5</InfinityDesc>
				    </InfinityDescWrap>
				</InfinityLevelWrap>
				<ImgWrap>
				    <InfinityImage src='https://icefaucet.com/media/help/blog/with_a_speed_of_2.5_times_towards_infinity.png' alt='infinity-contest' />
				</ImgWrap>
				<RewardShower>
				    <BaseReward>
					<h2>Prime Reward</h2>
				    </BaseReward>
				    <BaseReward style={{ background: 'rgba(29, 107, 187, 0.35)', width: '50%', height: '100%', borderRadius: '0 10px 10px 0' }}>
					<h2 style={{ color: '#fff' }}>{info.base_reward}</h2>
				    </BaseReward>
				</RewardShower>
			    </>
			}
		    	{showHelp &&
			    <HelpWrap style={{ marginBottom: '10px', flexDirection: 'column' }} show={showHelp ? 1 : 0}>
				<p>
				    The weekly smart contest starts when the Prime Reward(PR) touches 1500 PC.<br />
				    Completing more offers makes a bigger prize and more winners based on the following table.
				</p>
				<Table style={{ marginTop: '20px' }}>
				    <HeadWrap>
					<HeadRow>
					    {infinityRewardTableHead.map((header, index) => (
						<Header key={index}>
						    {header}
						</Header>
					    ))}
				        </HeadRow>
				    </HeadWrap>
				    <BodyWrap>
					{offerInfinityRewardTableBody.map((row, index) => (
					    <BodyRow key={index}>
						<Cell>
						    {row.PR_required}
						</Cell>
						<Cell>
						    {row.ice_bonus}
						</Cell>
						<Cell>
						    {row.winners}
						</Cell>
					    </BodyRow>
					))}
				    </BodyWrap>
				</Table>
			    </HelpWrap>
			}
		    </LotteryInfo>
		    <LotteryInfo>
			<LotteryContainer>
			    <LotteryInfoWrap>
			        <LotteryTitle>
			            <h2>reward</h2>
			        </LotteryTitle>
			        <LotteryDesc>
			            <h2>{info.reward} pc</h2>
			        </LotteryDesc>
			    </LotteryInfoWrap>
			    <LotteryInfoWrap>
			        <LotteryTitle>
			            <h2>winners</h2>
			        </LotteryTitle>
			        <LotteryDesc>
			            <h2>{info.winners}</h2>
			        </LotteryDesc>
			    </LotteryInfoWrap>
			</LotteryContainer>
		    </LotteryInfo>
		    {!loading && !apiError && info !== undefined && table.length > 0 &&
			<Table style={{ marginTop: '25px' }}>
			    <HeadWrap>
			        <HeadRow>
			            {!smallVeiw
					? offerLiveContestTableHead.map((header, index) => (
					    <Header key={index}>
						{header}
					    </Header>
					))
					: offerSmallLiveContestTableHead.map((header, index) => (
					    <Header key={index}>
						{header}
					    </Header>
					))
				    }
			        </HeadRow>
			    </HeadWrap>
			    <BodyWrap>
			        {table.map((row, index) => (
				    <BodyRow key={index}>
					<Cell blue={row.user_in_winners ? 1 : 0}>
					    {index + 1}
					</Cell>
					<Cell blue={row.user_in_winners ? 1 : 0}>
					    <CellWrap>
					    	{row.username ? row.username : row.user_id}
					    	{(row.gold > 0 || row.silver > 0 || row.bronze > 0) &&
						    <CrownsContainer>
							{row.gold > 0 &&
							    <CrownWrap>
								<Golden />
								<span>{row.gold}</span>
							    </CrownWrap>
							}
						        {row.silver > 0 &&
							    <CrownWrap style={{ marginLeft: '3px' }}>
								<Silver />
								<span>{row.silver}</span>
							    </CrownWrap>
							}
						        {row.bronze > 0 &&
							    <CrownWrap style={{ marginLeft: '3px' }}>
								<Bronze />
								<span>{row.bronze}</span>
							    </CrownWrap>
							}
						    </CrownsContainer>
						}
					    </CellWrap>
					</Cell>
					<Cell blue={row.user_in_winners ? 1 : 0}>
					    {row.earned} PC
					</Cell>
					{!smallVeiw &&
					    <Cell blue={row.user_in_winners ? 1 : 0}>
						{moment(row.last_time).fromNow()}
					    </Cell>
					}
				    </BodyRow>
				))}
			    </BodyWrap>
			</Table>
		    }
		</LotteryHeaderWrap >
	    }
	</>
    )
}
export default Contest;

