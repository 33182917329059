import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const CardContainer = styled.div`
    width: 90%;
    max-width: 94vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 25px;
    margin: 25px auto;
    align-items: center;
    justify-content: center;
    @media screen and ${device.md} {
	grid-template-columns: 1fr 1fr;
    }
    @media screen and ${device.sm} {
	grid-template-columns: 1fr;
    }
`
export const CardWrap = styled.div`
    width: 210px;
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6);' : ' rgba(39, 117, 85, 0.6)')};
    border: ${({ border }) => (border ? '3px solid rgba(1, 219, 187, 1)' : 'none')};
    border-radius: 0 0 10px 10px;
    margin: auto;
    opacity: ${({ lock }) => (lock ? '0.6' : '1')};
`
export const ImgContainer = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ImgBackground = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
	width: 90%;
	height: 90%;
	filter: blur(8px);
	-webkit-filter: blur(8px);
	-o-object-fit: cover;
	object-fit: cover;
	background: #000;
    }
`
export const ImgWrap = styled.div`
    position: absolute;
    width: 180px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6);' : ' rgba(39, 117, 85, 0.6)')};
    border-radius: 5px;
    img {
	width: 100%;
	height: 100%;
    }
`
export const TitleWrap = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    h3 {
	color: rgba(1, 219, 187, 1);
	font-size: 0.9rem;
	letter-spacing: 1.1px;
	word-spacing: 1.4px;
	text-transform: uppercase;
    }
`
export const EndTimeWrap = styled.div`
    position: absolute;
    width: 180px;
    margin-top: 140px;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
`
export const AbstractWrap = styled.div`
    width: 90%;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.35);' : ' rgba(39, 117, 85, 0.35)')};
    padding: 3px;
    p {
	color: #f9f9f9;
	font-size: 0.80rem;
	line-height: 1.2;
	word-spacing: 1.2px;
    }
`
export const BottomWrap = styled.div`
    width: 90%;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 5%;
    grid-row-gap: 20px;
    padding: 5px 0;
`
export const LevelWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2);' : ' rgba(39, 117, 85, 0.2)')};
    border: 1px solid rgba(29, 107, 187, 0.35);
    border-radius: 0 0 0 10px;
    span {
	color: #f9f9f9;
	font-size: 0.80rem;
	word-spacing: 1.2px;
    }
`
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4);' : ' rgba(39, 117, 85, 0.4)')};
    border: 1px solid rgba(29, 107, 187, 0.35);
    border-radius: 0 0 10px 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
	transition: all 0.2s ease-in-out;
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1);' : ' rgba(39, 117, 85, 1)')};
    }
    span {
	color: rgba(1, 219, 187, 1);
	font-size: 0.90rem;
	word-spacing: 1.2px;
    }
`
export const Timer = styled.h4`
    color: rgba(1, 219, 187, 1);
    font-size: 0.7rem;
    letter-spacing: 1.3px;
`
