import React, { useEffect, useState } from 'react';
import TaskView from '../components/TaskView';
import axiosInstance from '../axios';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../components/TaskView/TaskPageElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../components/PtcView/PtcViewElements';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { DescWrap } from '../components/History/DepositTabElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import { taskTableHead } from '../data/taskData';
import moment from 'moment';

const TaskPage = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    useEffect(() => {
	const fetchShortLinkView = async () => {
	    try {
	        const { data } = await axiosInstance.get('task/view/');
		setInfo(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchShortLinkView();
    }, []);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && !apiError &&
		<PtcHeaderWrap>
		    <PtcInfoWrap>
			<InfoContainer>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>total</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.all_info.number}</h2>
			        </DescWrap>
			    </InfoWrap>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>paid</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.all_info.total} USDT</h2>
			        </DescWrap>
			    </InfoWrap>
			</InfoContainer>
		    </PtcInfoWrap>
		</PtcHeaderWrap>
	    }
	    {!loading && !apiError && info !== undefined && !info.user_ban &&
		<Table>
		    <HeadWrap>
			<HeadRow>
			    {taskTableHead.map((header, index) => (
				<Header key={index}>
				    {header}
				</Header>
			    ))}
		        </HeadRow>
		    </HeadWrap>
		    <BodyWrap>
			{info.done_info.map((row, index) => (
			    <BodyRow key={index}>
				<Cell>
				    {row.username ? row.username : row.id}
				</Cell>
				<Cell>
				    {row.reward / 10000}
				</Cell>
				<Cell>
				    #{row.task_id}
				</Cell>
				<Cell>
				    {moment(row.created).fromNow()}
				</Cell>
			    </BodyRow>
			))}
		    </BodyWrap>
		</Table>
	    }
	    {!loading && !apiError && info !== undefined && !info.user_ban &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
		        <StackDiscWrap>
			    <p>
			        If you really have the conditions to perform the task, volunteer. <br />
			        By doing the task professionally, double your income and increase the chances of being monetized in the next tasks.<br />
			        You can entrust the task to those who are skilled in doing it to receive 10% of its income.<br />
			        Please note that if you do not have the conditions or do not perform the work as described, you will be rejected.<br />
			        If you send a malicious link or a virus file, you will be banned from the task app forever.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer >
	    }
	    {
		!loading && info !== undefined && info.user_ban &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '0 auto 25px auto' }}>
		    <MsgText primary={0}>
			You are banned because you did not respect to rules. If you think you should not have been baned contact us.
		    </MsgText>
		</MsgWrap>
	    }
	    {
		!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {info !== undefined && !info.user_ban && info.info.map((task, index) => (
		<TaskView task={task} key={index} index={index} />
	    ))}
	</>
    )
}
export default TaskPage;
