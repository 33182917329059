import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const DepositTypeWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    grid-row-gap: 20px;
    margin-bottom: 15px;
    @media screen and ${device.md} {
	grid-template-columns : 1fr 1fr;
    }
    @media screen and ${device.sm} {
	grid-template-columns : 1fr;
    }
`
export const CryptoWrap = styled.div`
    background: rgba(39, 117, 85, 0.2);
    border: 1px solid rgba(39, 117, 85, 0.5);
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(39, 117, 85, 0.7);
    width: 75%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr 1fr;
    grid-row-gap: 20px;
    padding: 10px;
    margin: 15px 0 25px 0;
    @media screen and ${device.lg} {
        grid-template-columns : 1fr 1fr 1fr;
    }
    @media screen and ${device.md} {
	grid-template-columns : 1fr 1fr;
    }
    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
    }
`
