import {
    UNSHOW_MAINBAR,
    SHOW_MAINBAR,
    TOGGLE_MAINBAR,
} from '../constants/mainbarRedux';

export const showMainbarReducer = (state = false, action) => {
    switch (action.type) {
        case (UNSHOW_MAINBAR):
            return false
        case (SHOW_MAINBAR):
            return true
        case (TOGGLE_MAINBAR):
            return !state
        default:
            return state
    }
};
