export const AboutUsData = {
	    subject: 'about icefaucet',
	    card: [
		            {
				                title: 'about iceFaucet',
				                img: 'https://icefaucet.com/media/help/blog/adout-icefa.jpg',
				                alt: 'icefa team image',
				                desc: 'IceFaucet.com is a bridge between advertisers and viewers. Where advertisers can show their ads only to specified visitors to acquire customers at a fair cost. Where viewers can have an online business without leaving home.',
				                desc2: 'IceFaucet.com is an environmental assistant because it not only promotes online businesses but also donates a portion of its income to the intention of "Green Earth, Blue Sky".',
				                desc3: 'Icefa is not just a team name but also a system that was created with the aim of promoting online businesses. In order to know more about the icefa team, follow the rest of the content.'
				            },
		            {
				                title: 'management',
				                img: 'https://icefaucet.com/media/help/blog/management.jpg',
				                alt: 'management image',
				                desc: "Hello guys! I am the manager and webmaster of the iceFaucet website. I graduated with a master's degree in energy conversion. I am not very interested in leaving home, staying in traffic, and breathing in the city air. So I have been trying to make money online for several years. I worked in various fields including digital marketing, cryptocurrency trading, and website coding. This website is the result of many years of experience of me and other icefa members in making money online. The logic of the codes is written in such a way that it leads to the highest satisfaction for advertisers and the highest possible income for users.",
				                sign: 'Respect the nature',
				                sign2: 'Icefa team administrator'
				            }
		        ]
};
