import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { WContainer, WWrap, TypeCard, ImageWrap, Image, WContext, WContextItem, LimitWrap, CryptoWrap, CryptoDeactiveWrap } from './WithdrawElements';
import { DepositTypeWrap } from '../Deposit/DepositElements';
import { NumberInputWrap, NumberInput } from '../SetUpPtc/SetUpPtcFilterElements';
import { TinyFormButton, FormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import Modal from './Modal';
import Countdown, { zeroPad } from 'react-countdown';
import { TimerWrap, TimerCode } from '../SettingContainer/ConfirmChangesElements';
import { ResetCodeWrap, NumberFormatCode } from '../Auth/ResetCodeElements';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const Withdraw = ({ info, userIcecoin }) => {
    const history = useHistory();
    const [type, setType] = useState(Object.freeze(''));
    const [typeIndex, setTypeIndex] = useState();
    const [apiError, setApiError] = useState('');
    const [errors, setErrors] = useState('');
    const [codeError, setCodeError] = useState('');
    const [cryptoGatewayInfo, setCryptoGatewayInfo] = useState();
    const [limitationTime, setLimitationTime] = useState();
    const [cryptoSelectedInfo, setCryptoSelectedInfo] = useState(Object.freeze());
    const [cryptoIndex, setCryptoIndex] = useState();
    const [value, setValue] = useState(Object.freeze(''));
    const [userAddress, setUserAddress] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [timer, setTimer] = useState();
    const [modalTimer, setModalTimer] = useState();
    const handleClick = (e, index) => {
        const typeTarget = e.target.getAttribute('name');
        if (typeTarget && typeTarget !== type) {
            setType(typeTarget);
            setTypeIndex(index);
            fetchLimitationCheck(typeTarget);
            setErrors('');
            setApiError('');
            setCryptoSelectedInfo();
            setCryptoIndex();
            setTimer();
            setIsSubmitting(false);
        };
    };
    const fetchLimitationCheck = async (typeTarget) => {
	try {
	    const { data } = await axiosInstance.get(`financial/cash-out/check/${typeTarget}/`);
	    data.status ? setCryptoGatewayInfo(data.info) : setLimitationTime(data.detail);
	} catch (error) {
	    error.response && error.response.data.detail ? setLimitationTime(error.response.data.detail) : setLimitationTime(error.message);
	};
    };
    const selectCrypto = (index, crypto) => {
	if (crypto.active_withdraw && userIcecoin >= crypto.min_withdraw * 1000) {
	    setCryptoSelectedInfo(crypto);
	    setCryptoIndex(index);
	    setValue(crypto.min_withdraw * 1000);
	    setIsSubmitting(false);
	};
	userIcecoin < crypto.min_withdraw * 1000 && setErrors('Your icecoins are lower than the minimum withdrawal. To collect icecoins, you first need to earn primecoins in many ways, then use the "MINING ICECOIN" to exchange your primecoins for icecoins.')
    };
    const handleChange = (e) => {
        let number = parseInt(e.target.value);
        if (!isNaN(number)) {
            setValue(parseInt(number));
        };
        setErrors('');
        setIsSubmitting(false);
    };
    const handleMaxClick = () => {
        type === 'P' && userIcecoin > 100000
	    ? setValue(100000)
	    : type === 'F' && userIcecoin > 5000
	        ? setValue(5000)
	        : type === 'W' && userIcecoin > 200000
	            ? setValue(200000)
	            : setValue(userIcecoin);
    };
    const handleCashOutClick = () => {
        if (value < cryptoSelectedInfo.min_withdraw * 1000) {
	    setErrors(`The minimum withdrawal is ${cryptoSelectedInfo.min_withdraw * 1000} icecoins.`);
	} else if (value > cryptoSelectedInfo.max_withdraw * 1000) {
	    setErrors(`The maximum withdrawal is ${cryptoSelectedInfo.max_withdraw * 1000} icecoins.`);
	} else if (value > userIcecoin) {
	    setErrors(`You have not ${value} icecoins! Your maximum withdrawal amount is ${userIcecoin}.`);
	} else {
	    fetchUserAddress();
	};
    };
    const fetchUserAddress = async () => {
        try {
            const { data } = await axiosInstance.post('financial/cash-out/user-address/', {
                'withdraw_name': cryptoSelectedInfo.withdraw_name,
                'type': type
            });
            userAddress.length === 0 && apiError.length === 0 && setUserAddress(data.address);
            fetchSendEmail();
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const fetchSendEmail = async () => {
        const { data } = await axiosInstance.get('financial/cash-out/send/email/');
        setTimer(Date.now() + data.timer * 1000);
    };
    const renderer = ({ minutes, seconds }) => {
        return <TimerWrap><TimerCode>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerCode></TimerWrap>;
    };
    const handleChangeCode = (e) => {
        let code = e.target.value.replace(/ /g, '').replace('_', '');
        setCodeError('');
        if (code.length === 6) {
            fetchCheckCode(code);
        };
    };
    const fetchCheckCode = async (code) => {
        try {
            await axiosInstance.post('financial/cash-out/check-code/', {
                code: code
            });
            setModalTimer(Date.now() + 5 * 60 * 1000);
            setIsSubmitting(true);
        } catch (error) {
            error.response && error.response.data.detail ? setCodeError(error.response.data.detail) : setCodeError(error.message);
        };
    };
    return (
        <WContainer>
            <WWrap>
	        <DepositTypeWrap>
                    {info.map((item, index) => (
                        <TypeCard key={index} primary={1} active={index === typeIndex ? 1 : 0} cryptoDeactive={0}>
                            <ImageWrap primary={0}>
                                <Image src={item.src} alt={item.alt} title={item.title} />
                            </ImageWrap>
                            <WContext>
                                <WContextItem>
                                    <span>min withdraw:</span>
                                    <span>{item.min}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>max withdraw:</span>
                                    <span>{item.max}</span>
                                </WContextItem>
                                <WContextItem>
                                    <span>limitation:</span>
                                    <span>{item.limitation}</span>
                                </WContextItem>
                            </WContext>
                            <TinyFormButton type='button' name={item.type} primary={index === typeIndex ? 1 : 0} onClick={(e) => handleClick(e, index)}>
                                select
                            </TinyFormButton>
                        </TypeCard>
                    ))}
                </DepositTypeWrap>
	    	{cryptoGatewayInfo && cryptoGatewayInfo.length > 0 &&
		    <CryptoWrap>
		        {cryptoGatewayInfo.map((crypto, index) => (
			    <TypeCard key={index} style={{ width: '105px', height: '165px', padding: '7px 0 0 0' }} primary={0} active={cryptoIndex === index ? 1 : 0} cryptoDeactive={!crypto.active_withdraw ? 1 : 0}>
				<ImageWrap primary={1} style={{ width: '70px', height: '70px' }}>
				    <Image src={crypto.image} alt={crypto.name} />
				    {!crypto.active_withdraw &&
					<CryptoDeactiveWrap>
					    <h4>{crypto.reason_deactive_withdraw}</h4>
					</CryptoDeactiveWrap>
				    }
				</ImageWrap>
				<TinyFormButton style={{ padding: '3px 7px' }} primary={cryptoIndex === index ? 1 : 0} onClick={(e) => selectCrypto(index, crypto)}>
				    {crypto.name}
				</TinyFormButton>
				{crypto.network &&
				    <LimitWrap>
					<h4>netwotk:</h4>
					<h4>{crypto.network}</h4>
				    </LimitWrap>
				}
				<LimitWrap>
				    <h4>fee:</h4>
				    <h4>{crypto.fee_withdraw_percent > 0 ? '%' : crypto.fee_withdraw > 0 ? '$' : ''}{crypto.fee_withdraw_percent > 0 ? crypto.fee_withdraw_percent : crypto.fee_withdraw > 0 ? crypto.fee_withdraw : 0}</h4>
				</LimitWrap>
				<LimitWrap style={{ padding: '1px 3px' }}>
				    <h4>min withdraw:</h4>
				    <h4>${crypto.min_withdraw}</h4>
				</LimitWrap>
			    </TypeCard>
			))}
		    </CryptoWrap>
	    	}
                {cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 &&
                    <NumberInputWrap>
                        <NumberInput
                            type='number'
                            value={value}
                            onChange={handleChange}
                            min={cryptoSelectedInfo.min_withdraw * 1000}
			    max={cryptoSelectedInfo.max_withdraw * 1000}
                            step={10}
                        />
                        <TinyFormButton type='button' primary={0} big={0} onClick={handleMaxClick}>
                            max
                        </TinyFormButton>
                    </NumberInputWrap>
                }
                {apiError &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
                {errors &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {errors}
                        </MsgText>
                    </MsgWrap>
                }
                {codeError &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            {codeError}
                        </MsgText>
                    </MsgWrap>
                }
                {limitationTime &&
                    <MsgWrap style={{ width: '80%' }} primary={0}>
                        <MsgText primary={0}>
                            You could withdraw again {moment(Date.now() + limitationTime * 1000).fromNow()}.
                        </MsgText>
                    </MsgWrap>
                }
	        {cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !apiError && !limitationTime && !errors &&
                    <FormButton
                        type='button'
                        style={{ width: '80%', maxWidth: '500px', marginBottom: '15px' }}
                        big={0}
                        primary={1}
                        onClick={() => handleCashOutClick()}
                    >
                        Cash out
                    </FormButton>
                }
                {timer !== undefined && !apiError && !limitationTime && !errors &&
                    <MsgWrap style={{ width: '80%' }} primary={1}>
                        <MsgText primary={1}>
                            <p>
                                An email containing a code has been sent to your email address. Please confirm your withdrawal request:
                            </p>
                        </MsgText>
                        <Countdown
                            date={timer}
                            renderer={renderer}
                            onComplete={() => {
                                setTimeout(() => history.go(0), 3000);
                                setTimer(0);
                            }}
                        />
                        {timer > 0 &&
                            <ResetCodeWrap>
                                <NumberFormatCode
                                    format={"#  #  #  #  #  #"}
                                    allowEmptyFormatting mask="_"
                                    onChange={handleChangeCode}
                                />
                            </ResetCodeWrap >
                        }
                        {timer === 0 &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    Your time is expired. Please try again.
                                </MsgText>
                            </MsgWrap>
                        }
                    </MsgWrap>
                }
            </WWrap>
	    {userAddress && cryptoSelectedInfo && Object.keys(cryptoSelectedInfo).length > 0 && !apiError && !errors && !codeError &&
		<Modal
		    type={type}
		    crypto={cryptoSelectedInfo.abbreviation}
		    cryptoName={cryptoSelectedInfo.name}
		    cryptoWithdrawName={cryptoSelectedInfo.withdraw_name}
		    userAddress={userAddress}
		    fee_dollar={cryptoSelectedInfo.fee_withdraw}
		    fee_percent={cryptoSelectedInfo.fee_withdraw_percent}
		    extraParam={cryptoSelectedInfo.extra_parameter}
		    value={value}
		    timer={modalTimer}
		    setTimer={setModalTimer}
		    isSubmitting={isSubmitting}
		    setIsSubmitting={setIsSubmitting}
		/>
	    }
        </WContainer >
    )
}
export default Withdraw;
