import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { HistoryBody, CountTotalContainer, CountTotalWrap, TitleWrap, DescWrap } from './DepositTabElements';
import { PromotionContainer } from '../Promotions/ReferralLinkTabElements';
import { Table, HeadWrap, HeadRow, Head, BodyWrap, BodyRow, Cell } from '../Payments/PaymentsElements';
import { headSpendTable } from '../../data/historyData';
import { TypeBannerContainer, TypeBannerWrap } from '../SetUpBanner/SetUpBannerFormElements';
import { ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements';
import { MsgWrap, MsgText } from '../MessageElements';
import moment from 'moment';
import { spendCoinTypeData } from '../../data/historyData';
import { Circle } from '../Loading';

const SpendTab = () => {
    const [coinType, setCoinType] = useState('');
    const [coinTypeIndex, setCoinTypeIndex] = useState();
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const handleClick = (e, index) => {
        const coinTypeTraget = e.target.getAttribute('name');
        if (coinTypeTraget && coinType !== coinTypeTraget) {
            setCoinType(coinTypeTraget);
            setCoinTypeIndex(index);
            setInfo();
            setApiError('');
            fetchSpendHistory(coinTypeTraget);
        };
    };
    const fetchSpendHistory = async (coinTypeTraget) => {
        setLoading(true);
        try {
            const { data } = await axiosInstance.get(`spend/history/${coinTypeTraget}/`);
            setInfo(data);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
        setLoading(false);
    };
    return (
        <PromotionContainer>
            <HistoryBody>
                <TypeBannerContainer>
                    <TypeBannerWrap>
                        {spendCoinTypeData.map((coin, index) => (
                            <ItemWrap key={index}>
                                <ItemName name={coin.name} onClick={(e) => handleClick(e, index)} itemActive={coinTypeIndex === index ? 1 : 0}>
                                    {coin.title}
                                </ItemName>
                            </ItemWrap>
                        ))}
                    </TypeBannerWrap>
                </TypeBannerContainer>
                {loading &&
                    <div style={{ marginTop: '20px' }}>
                        <Circle style={{ margin: 'auto', width: '50px', height: '50px' }} />
                    </div>
                }
                {!loading && info !== undefined && apiError.length === 0 &&
                    <>
                        <CountTotalContainer>
                            <CountTotalWrap>
                                <TitleWrap>
                                    <h2>count</h2>
                                </TitleWrap>
                                <DescWrap>
                                    <h2>{info.count}</h2>
                                </DescWrap>
                            </CountTotalWrap>
                            <CountTotalWrap>
                                <TitleWrap>
                                    <h2>total</h2>
                                </TitleWrap>
                                <DescWrap>
                                    <h2>{info.total} USDT</h2>
                                </DescWrap>
                            </CountTotalWrap>
                        </CountTotalContainer>
                        <Table style={{ width: '100%' }}>
                            <HeadWrap>
                                <HeadRow>
                                    {headSpendTable.map((item, index) => (
                                        <Head key={index}>
                                            {item}
                                        </Head>
                                    ))}
                                </HeadRow>
                            </HeadWrap>
                            <BodyWrap>
                                {info.info.map((row, index) => (
                                    <BodyRow key={index}>
                                        <Cell>
                                            {row.value}
                                        </Cell>
                                        <Cell>
                                            {row.verb}
                                        </Cell>
                                        <Cell style={{ fontSize: '0.8rem' }}>
                                            {moment(row.created).fromNow()}
                                        </Cell>
                                    </BodyRow>
                                ))}
                            </BodyWrap>
                        </Table>
                    </>
                }
                {!loading && apiError.length > 0 &&
                    <MsgWrap style={{ width: '80%', margin: '0 auto' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
            </HistoryBody>
        </PromotionContainer >
    )
}
export default SpendTab;
