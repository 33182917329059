import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const Wrapper = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr;
    grid-row-gap: 20px;
    margin-bottom: 25px;

    @media screen and ${device.lg} {
        grid-template-columns : 1fr;
    }
`
export const Card = styled.div`
width: 92%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 15px auto;
opacity: ${({ visited }) => (visited ? '0.6' : '1')};

@media screen and ${device.lg} {
    width: 100%;
}
`
export const Head = styled.div`
width: 100%;
text-align: center;
background: ${({ admin }) => (admin ? 'rgba(39, 117, 85, 0.6)' : 'rgba(29, 107, 187, 0.6)')};
border: 1px solid ${({ admin }) => (admin ? 'rgba(39, 117, 85, 0.3)' : 'rgba(29, 107, 187, 0.3)')};
box-shadow: 0 0 5px ${({ admin }) => (admin ? 'rgba(39, 117, 85, 0.7)' : 'rgba(29, 107, 187, 0.7)')};
margin-bottom: 7px;
border-radius: 15px 15px 0 0;

h3{
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.8rem, 2.2vw, 0.9rem);
    font-weight: 400;
    letter-spacing: 1.1px;
    word-spacing: 1.4px;
    text-transform: uppercase;
    padding: 10px 3px;
}
h4{
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.75rem, 2.2vw, 0.85rem);
    font-weight: 400;
    letter-spacing: 1.1px;
    padding: 10px 3px;
}
`
export const Body = styled.div`
    width: 100%;
    background: ${({ admin }) => (admin ? 'rgba(39, 117, 85, 0.5)' : 'rgba(29, 107, 187, 0.5)')};
    border: 1px solid ${({ admin }) => (admin ? 'rgba(39, 117, 85, 0.3)' : 'rgba(29, 107, 187, 0.3)')};
    box-shadow: 0 0 5px ${({ admin }) => (admin ? 'rgba(39, 117, 85, 0.7)' : 'rgba(29, 107, 187, 0.7)')};
    margin-bottom: 7px;
    padding: 5px;

    p{
        color: #ffffff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
        font-size: clamp(0.85rem, 2.2vw, 0.95rem);
    }
`
export const DescWrap = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
	color: rgba(39, 117, 85, 1);
	font-size: clamp(0.8rem, 3.5vw, 0.95rem);
	&:nth-child(2) {
	    color: rgba(29, 107, 187, 1);
	}
    }
`
