import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Payments from '../components/Payments';
import { typeData } from '../data/paymentsData';

const PaymentsPage = ({ code }) => {
    const [smallVeiw, setSmallView] = useState(false);
    const viewSmallSize = () => {
        if (window.innerWidth < 768) {
            setSmallView(true);
        } else if (window.innerWidth >= 768) {
            setSmallView(false);
        };
    };
    useEffect(() => {
        window.addEventListener('resize', viewSmallSize);
        viewSmallSize();
	return () => {
	    window.removeEventListener('resize', viewSmallSize);
	};
    });
    return (
	<>
	    <Helmet>
	        <meta name="description" content="You could check the payments of users out here." />
	        <title>iceFaucet | withdrawals of members</title>
	    </Helmet>
            <Payments code={code} info={typeData} smallView={smallVeiw} />
	</>
    )
}
export default PaymentsPage;
