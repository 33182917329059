import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';
import { ItemContainer, ItemWrap } from '../Deposit/ModalElements';


export const WItemContainer = styled(ItemContainer)`
grid-template-columns: 1fr 1fr;
`
export const ItemSubjectWrap = styled(ItemWrap)`
    width: 100%;
    background: rgba(39, 117, 85, 0.4);
`
export const ItemDescWrap = styled(ItemWrap)`
    width: 100%;
`
export const TikImg = styled(FaCheckCircle)`
    margin-top: 4px;
    font-size: 1.3rem;
`
export const Reject = styled.div`
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: rgba(255, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;

    span {
        color: rgb(240, 75, 75);
        font-size: 1.2rem;
        font-weight: 900;
    }
`