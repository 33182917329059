import React from 'react';
import { FeaturesContainer, FeaturesWrapper, FeaturesCard, FeaturesTitle, FeaturesTextWrap, FeaturesDes } from './FeaturesElements';

const Features = ({ featureHead, featureLang }) => {
    return (
        <FeaturesContainer id='features'>
            <h2>{featureHead.header}</h2>
            <FeaturesWrapper>
                {featureLang.map((item, index) => (
                    <FeaturesCard key={index}>
                        <FeaturesTitle primary={index === 1 ? 1 : 0}>
                            <h3>{item.title}</h3>
                        </FeaturesTitle>
                        <FeaturesTextWrap>
                            {item.description.map((desc, index) => (
                                <FeaturesDes key={index}>
                                    <div>
                                        {desc.icon}
                                    </div>
                                    <p>
                                        {desc.line}
                                    </p>
                                </FeaturesDes>
                            ))}
                        </FeaturesTextWrap>
                    </FeaturesCard>
                ))}
            </FeaturesWrapper>
        </FeaturesContainer>
    )
}
export default Features;
