import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const IceCardContainer = styled.div`
    width: 90%;
    max-width: 94vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 25px;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
    @media screen and ${device.md} {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and ${device.sm} {
	grid-template-columns: 1fr;
    }
`
export const IceCardWrap = styled.div`
    width: 200px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: rgba(29, 107, 187, 0.6);
    border-radius: 10px;
    margin: auto;
    opacity: ${({ active }) => (active ? '1' : '0.4')};
`
export const IceImgContainer = styled.div`
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const IceImgBackground = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
	display: ${({ active }) => (active ? 'block' : 'none')};
	width: 85%;
	height: 80%;
	filter: blur(8px);
	-webkit-filter: blur(8px);
	-o-object-fit: cover;
	object-fit: cover;
	background: #000;
    }
`
export const IceImgWrap = styled.div`
    position: absolute;
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(1, 219, 187, 0.7);
    border-radius: 5px;
    img {
	width: 100%;
	height: 100%;
    }
`
export const IceTitleWrap = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    h3 {
	color: rgba(1, 219, 187, 1);
	font-size: 0.7rem;
	letter-spacing: 1.1px;
	word-spacing: 1.4px;
	text-transform: uppercase;
    }
`
export const IceTimer = styled.h4`
    color: ${({ red }) => (red ? 'rgb(240, 75, 75, 1)' : 'rgba(1, 219, 187, 1)')};
    background: rgba(39, 117, 85, 0.5);
    border-radius: 15px;
    padding: 3px 10px;
    font-size: clamp(1.1rem, 4.4vw, 1.4rem);
    letter-spacing: 1.3px;
    margin: 15px auto 0 auto;
`
