import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import Stack from '../components/Stack';
import RentRefrigrators from '../components/Stack/RentRefrigrators';
import RefrUserStatus from '../components/RefrUserStatus';
import Refrigrators from '../components/Stack/Refrigrators';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';

const StackPage = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    useEffect(() => {
	const fetchInterestInfo = async () => {
	    try {
		const { data } = await axiosInstance.get('interest/status/');
		setInfo(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchInterestInfo();
    }, []);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && info !== undefined && Object.keys(info.info).length > 0 && !apiError &&
		<>
		    <Stack />
		    <RefrUserStatus info={info} />
		    <RentRefrigrators info={info} />
		    <Refrigrators />
		</>
	    }
	</>
    )
}
export default StackPage;
