import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { bannerFiltersAction, bannerFilters2Action, bannerSuccessAction } from '../../actions/bannerSetUpAction';
import { useDispatch } from 'react-redux';
import { bannerSizeData } from '../../data/bannerFiltersData';
import { PtcForm, PtcFormInputContainer, PtcInputWrap, PtcFormInput, PtcLinkIcon, FilterLangWrap } from '../SetUpPtc/SetUpPtcFormElements.js';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { FilterContainer, InstructionWrap, Instruction, FilterInputWrap, RangeInput, NumberInput, NumberInputWrap, UpcodeWrap, ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements';
import { SizeBannerContainer, SizeBannerWrap, ImgBannerWrap, ImgBanner } from './SetUpBannerFilter2Elements';
import { MsgWrap, MsgText } from '../MessageElements';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { TinyFormButton } from '../ButtonElements';
import { FormButtonsWrap } from '../SetUpPtc/CreditCheckElements';
import { countriesData } from '../../data/countriesData';
import { Circle } from '../Loading';

const SetUpBannerFilter2 = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState('');
    const [bannerSize, setBannerSize] = useState(Object.freeze('C'));
    const [bannerD, setBannerD] = useState('300x250');
    const [sizeIndex, setSizeIndex] = useState(2);
    const [bannerUrl, setBannerUrl] = useState('');
    const [urlError, setUrlError] = useState('');
    const [fileError, setFileError] = useState('');
    const [uploadActive, setUploadActive] = useState(false);
    const [fileSelected, setFileSelected] = useState(null);
    const [imgSource, setImgSource] = useState('');
    const [impressionValue, setImpressionValue] = useState(Object.freeze(10000));
    const [showHelp, setShowHelp] = useState(false);
    const [autoplay, setAutoplay] = useState(false);
    const [upcode, setUpcode] = useState();
    const [loading, setLoading] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const lang = localStorage.getItem('lang') ? 0.1 : 0;
    const bannerLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : null;
    const country = localStorage.getItem('target_country') ? 0.25 : 0;
    const geoData = countriesData;
    const geoObjects = geoData.objects.ne_110m_admin_0_countries.geometries;
    const geoAllCountriesIso = geoObjects.map((geoAllCountry) => geoAllCountry.properties.ISO_A2);
    const bannerCountry = localStorage.getItem('target_country') ? JSON.parse(localStorage.getItem('target_country')) : geoAllCountriesIso;
    const size = bannerSize === 'E' ? 1.5 : bannerSize === 'F' ? 2 : 1
    const CPI = lang + country + size;
    const totalCost = impressionValue * CPI;
    const remainAdscoin = parseInt(localStorage.getItem('adcoin')) - totalCost;
    const upcodeValue = Math.floor(impressionValue * parseFloat(upcode));
    const fileFormats = ['image/jpeg', 'image/png', 'image/gif'];
    const handleSubmit = (e) => {
        e.preventDefault();
        const adscoin = localStorage.getItem('adcoin');
        if (impressionValue < 10000) {
            setErrors('Please enter a number greater than or equal to 10000.');
        } else if (parseInt(adscoin) < totalCost) {
            setErrors('You do not have enogh adscoin.');
        } else if (impressionValue > 1000000) {
            setErrors('Please enter a number less than or equal to 1000000.');
        };
        if (bannerUrl.length === 0 && imgSource.length === 0) {
            setUrlError('Enter a URL banner or upload a banner.');
        };
        const accept_url = ['http', 'https'];
        if (bannerUrl.length !== 0 && !accept_url.includes(bannerUrl.toLowerCase().split('://')[0])) {
            setUrlError("The URL address should start with 'https://' or 'http://'.");
        };
        setIsSubmitting(true);
    };
    const handleSizeClick = (e, index) => {
        const bannerSizeName = e.target.getAttribute('name');
        const bannerDName = e.target.getAttribute('value');
        if (bannerSizeName && bannerSize !== bannerSizeName) {
            setBannerSize(bannerSizeName);
            setBannerD(bannerDName);
            setSizeIndex(index);
        };
    };
    const handleRangeChange = (e) => {
        setImpressionValue(parseInt(e.target.value));
        setIsSubmitting(false);
        setErrors('');
    };
    const maxValue = () => {
        const adscoin = parseInt(localStorage.getItem('adcoin'));
        const maximum = Math.floor(adscoin / CPI);
        if (maximum <= 1000000) {
            setImpressionValue(maximum);
        } else if (maximum > 1000000) {
            setImpressionValue(1000000);
        };
        setIsSubmitting(false);
        setErrors('');
    };
    const handleUrlChange = (e) => {
        setBannerUrl(e.target.value);
        setUrlError('');
        setFileError('');
        setIsSubmitting(false);
    }
    const handleDimensionCheck = (e) => {
        var img = document.createElement("img");
        img.onload = function () {
            if (this.width + "x" + this.height !== bannerD) {
                setFileError('It has not matched your selected banner size.');
            } else {
                setFileSelected(e.target.files[0]);
            };
        };
        setIsSubmitting(false);
        var reader = new FileReader();
        reader.onloadend = function (ended) {
            img.src = ended.target.result;
            setImgSource(img.src);
        }
        reader.readAsDataURL(e.target.files[0]);
    };
    const handleFileChange = (e) => {
        urlError && setUrlError('');
        fileError && setFileError('');
        imgSource && setImgSource('');
        if (e.target.files.length > 0) {
            const uplaodFileType = e.target.files[0].type;
            if (uplaodFileType && fileFormats.includes(uplaodFileType)) {
                const fileSize = e.target.files[0].size / 1024;
                if (fileSize <= 1000) {
                    handleDimensionCheck(e);
                } else {
                    setFileError('This banner is too large to upload. The maximum supported size is 1000 kb.');
                }
            } else {
                setFileError('The extension of the banner is not supported. Please upload JPEG family, PNG, and GIF formats.')
            };
        };
    };
    const handleBack = () => {
        dispatch(bannerFilters2Action({ prop: false }));
        dispatch(bannerFiltersAction({ prop: true }));

    };
    const fetchUpCodeUserCkeck = async () => {
        const { data } = await axiosInstance.get('ptc/advertise/upcode-user-check/');
        setUpcode(data.upcode);
    };
    const fetchSetUpBanner = async () => {
	setLoading(true);
        const { data } = await axiosInstance.post('banner/setup/', {
            type: localStorage.getItem('type'),
            target_language: bannerLang,
            url: localStorage.getItem('url'),
            title: localStorage.getItem('title'),
            target_country: bannerCountry,
            banner_size: bannerSize,
            impression: impressionValue,
            banner_url: bannerUrl,
	    status: autoplay ? 'P' : 'S'
        });
	if (data.status && bannerUrl.length !== 0) {
	    localStorage.removeItem('type');
	    localStorage.removeItem('lang');
	    localStorage.removeItem('url');
	    localStorage.removeItem('title');
	    localStorage.removeItem('description');
	    localStorage.removeItem('target_country');
	    localStorage.removeItem('adcoin');
	    dispatch(bannerFilters2Action({ prop: false }));
	    dispatch(bannerSuccessAction({ prop: true }));
	};
        if (data.status && bannerUrl.length === 0) {
            fetchUploadBanner();
        };
    };
    const fetchUploadBanner = async () => {
        let fd = new FormData();
        fd.append('banner_img', fileSelected, fileSelected.name);
        const { status } = await axiosInstance.put('banner/setup/upload-img/', fd);
        if (status) {
            localStorage.removeItem('type');
	    localStorage.removeItem('lang');
	    localStorage.removeItem('url');
	    localStorage.removeItem('title');
	    localStorage.removeItem('description');
	    localStorage.removeItem('target_country');
	    localStorage.removeItem('adcoin');
	    dispatch(bannerFilters2Action({ prop: false }));
	    dispatch(bannerSuccessAction({ prop: true }));
        }
    };
    useEffect(() => {
        upcode === undefined && fetchUpCodeUserCkeck();
        if (!loading && errors.length === 0 && urlError.length === 0 && isSubmitting) {
            fetchSetUpBanner();
        };
    });
    return (
        <PtcForm action='#' onSubmit={handleSubmit}>
            <PtcFormInputContainer>
                <FormHeader>
                    <h2>size of your banner</h2>
                </FormHeader>
                <SizeBannerContainer>
                    <SizeBannerWrap>
                        {bannerSizeData.map((banner, index) => (
                            <ItemWrap key={index}>
                                <ItemName name={banner.db_name} value={banner.size} onClick={(e) => handleSizeClick(e, index)} itemActive={sizeIndex === index ? 1 : 0}>
                                    {banner.size} <br />
                                    ({banner.device}) <br />
                                    {banner.size === '160x600' ? '(+50% CPM)' : banner.size === '300x600' ? '(+100% CPM)' : null}
                                </ItemName>
                            </ItemWrap>
                        ))}
                    </SizeBannerWrap>
                </SizeBannerContainer>
                <PtcInputWrap>
                    <PtcLinkIcon />
                    <PtcFormInput
                        type='file'
                        name='file'
                        accept='image/jpeg, image/png, image/gif'
                        onChange={handleFileChange}
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                    />
                </PtcInputWrap>
                <FormHeader>
                    <h2>Set number of impressions</h2>
                </FormHeader>
                <FilterContainer>
                    <InstructionWrap>
                        <Instruction>
                            It is exactly equal to the number of users who could view your banner.
                        </Instruction>
                    </InstructionWrap>
                    <FilterInputWrap>
                        <RangeInput
                            type='range'
                            name='visit'
                            min='10000'
                            max='1000000'
                            value={impressionValue}
                            onChange={handleRangeChange}
                        />
                        <NumberInputWrap>
                            <NumberInput
                                type='number'
                                name='visit'
                                value={impressionValue}
                                onChange={handleRangeChange}
                            />
                            <TinyFormButton type='button' primary={0} big={0} onClick={maxValue}>
                                max
                            </TinyFormButton>
                        </NumberInputWrap>
                        {totalCost >= 250000 && <UpcodeWrap>
                            <span>
                                with upcode: {upcodeValue} impressions
                            </span>
                            <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                            <HelpWrap show={showHelp ? 1 : 0}>
                                <p>If cost of your ads is more than 25$, based on your level, you get more credits. Masternode members get 10% more credit.</p>
                            </HelpWrap>
                        </UpcodeWrap>}
                    </FilterInputWrap>
                    {errors &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errors}
                            </MsgText>
                        </MsgWrap>
                    }
                </FilterContainer>
                <FormHeader>
                    <h2>upload your banner</h2>
                </FormHeader>
                <FilterContainer>
                    <InstructionWrap>
                        <Instruction>
                            If you do not have access to the banner file, you could just enter the banner URL here.
                        </Instruction>
                    </InstructionWrap>
                    <PtcInputWrap>
                        <PtcLinkIcon />
                        <PtcFormInput
                            type='url'
                            name='url'
                            placeholder='Enter your banner URL address.'
                            onChange={handleUrlChange}
                            value={bannerUrl}
                        >
                        </PtcFormInput>
                    </PtcInputWrap>
                    {urlError.length !== 0 &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {urlError}
                            </MsgText>
                        </MsgWrap>
                    }
                    <div style={{ marginTop: '10px' }}>
                        <InstructionWrap>
                            <Instruction>
                                Upload your banner here. The format of the banner should be JPEG family, PNG, and GIF and the file size should not be more than 1000 Kb.
                            </Instruction>
                        </InstructionWrap>
                        <FilterLangWrap>
                            <ItemWrap >
                                <ItemName onClick={() => { hiddenFileInput.current.click(); setUploadActive(true) }} itemActive={uploadActive ? 1 : 0}>
                                    upload
                                </ItemName>
                            </ItemWrap>
                        </FilterLangWrap>
                        {fileError.length === 0 && imgSource.length !== 0 &&
                            <ImgBannerWrap>
                                <ImgBanner
                                    src={imgSource}
                                    alt={'banner'}
                                    size={bannerSize === 'D' ? 'd' : bannerSize === 'E' ? 'e' : null} />
                            </ImgBannerWrap>
                        }
                        {fileError &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {fileError}
                                </MsgText>
                            </MsgWrap>
                        }
                    </div>
                </FilterContainer>
	    	<FormHeader>
	            <h2>cost of your ads</h2>
	        </FormHeader>
	        <FilterContainer>
	    	    <InstructionWrap>
	    		<Instruction>
	                    CPI (Cost Per Impression) = <strong>1 adcoin</strong><br />
	                    Target language filter = <strong>{lang === 0.1 ? 'enabled (+0.10% CPI)' : 'disabled'}</strong><br />
	                    Target country filter = <strong>{country === 0.25 ? 'enabled (+0.25% CPI)' : 'disabled'}</strong><br />
	                    Banner size = <strong>{bannerD === '160x600' ? `${bannerD} (+50% CPI)` : bannerD === '300x600' ? `${bannerD} (+100% CPI)` : `${bannerD}`}</strong><br />
	                    Number of impressions = <strong>{impressionValue} impressions</strong><br />
	                    The total cost of your ads = <strong>{totalCost} adscoin</strong> <br />
	                    Remain your adscoin = <strong>{remainAdscoin} adscoin</strong> <br />
	                    Number of upcode impressions as gift = {totalCost >= 250000 ? <strong>{upcodeValue} impressions</strong> : 0}
	                </Instruction>
	            </InstructionWrap>
	        </FilterContainer>
	    	<FormHeader>
	            <h2>autoplay your ads</h2>
	        </FormHeader>
	        <FilterContainer>
	            <InstructionWrap>
	                <Instruction>
	                    If you want to show your ads instantly after acceptance in the monitoring process, press the following button.
	                </Instruction>
	            </InstructionWrap>
	            <FilterLangWrap>
	                <ItemWrap>
	                    <ItemName onClick={() => setAutoplay(!autoplay)} itemActive={autoplay ? 1 : 0}>
	                        Accept
	                    </ItemName>
	                </ItemWrap>
	            </FilterLangWrap>
	        </FilterContainer>
            </PtcFormInputContainer>
            {!loading ?
		<FormButtonsWrap>
		    <TinyFormButton type='submit' primary={1} big={0}>
		        Next
		    </TinyFormButton>
		    <TinyFormButton type='button' primary={1} big={0} onClick={handleBack}>
		        back
		    </TinyFormButton>
		</FormButtonsWrap>
		: <Circle style={{ margin: '25px auto', width: '30px', height: '30px' }} />
	    }
        </PtcForm>
    )
};
export default SetUpBannerFilter2;
