import styled, { keyframes } from 'styled-components/macro';
import device from '../../constants/breakPoints';

export const StoryOutContainer = styled.div`
    position: relative;
    z-index: 3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
`
export const StoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: 66%;
    background: ${({ dark }) => (dark ? '#111121' : '#f9f9f9')};
    border: 1px solid ${({ stop }) => (stop ? 'rgba(255, 0, 0, 0.3)' : 'rgba(29, 107, 187, 0.3)')};
    box-shadow: 0 0 5px ${({ stop }) => (stop ? 'rgba(255, 0, 0, 0.7)' : 'rgba(29, 107, 187, 0.7)')};
    border-radius: 10px;
    margin: 50px auto 20px auto;
    @media screen and ${device.sm} {
	width: 95%;
    }
`
export const StoryHeroTitleWrap = styled.div`
    position: relative;
    top: -18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 35px;
    background: ${({ dark }) => (dark ? '#111121' : '#f9f9f9')};
    border: 1px solid rgba(29, 107, 187, 0.3);
    border-radius: 35%;
    h2 {
	color: rgba(1, 219, 187, 1);
	text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
	font-size: 1rem;
	letter-spacing: 0.5px;
	word-spacing: 1.5px;
	text-transform: uppercase;
    }
`
export const mainFadeIn = keyframes`
    0% { opacity: 0; }
    25% { opacity: 0.25; }
    50% { opacity: 0.50; }
    75% { opacity: 0.75; }
    100% { opacity: 1; }
`
export const StoryWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns : 3fr 9fr;
    grid-column-gap: 7px;
    padding: 10px;
    cursor: pointer;
    opacity: 0;
    overflow: hidden;
    @media (prefers-reduced-motion: no-preference) {
	animation: ${mainFadeIn} 1.5s ease-in 0s forwards;
    }
    @media screen and ${device.md} {
	grid-template-columns : 1fr;
	grid-row-gap: 10px;
    }
    @media screen and ${device.tn} {
	padding: 0;
    }
`
export const StoryItemWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const StoryTitleWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
	color: rgba(1, 219, 187, 1);
	text-shdow: 0 0 5px rgba(0, 0, 0, 0.3);
	font-size: 0.85rem;
	letter-spacing: 0.5px;
	word-spacing: 1.3px;
	text-transform: uppercase;
    }
`
export const StoryImgWrap = styled.div`
    width: 100px;
    height: 100px;
    display: felx;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6);' : 'rgba(39, 117, 85, 0.6)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3);' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4);' : 'rgba(39, 117, 85, 0.4)')};
    border-radius: 50%;
    color: #fff;
    font-size: 0.7rem;
    margin: 10px auto 5px auto;
    img {
	width: 95%;
	height: 95%;
	border-radius: 50%;
	display: felx;
	align-items: center;
	justify-content: center;
    }
`
export const StorySubTitleWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const SubTitle = styled.h4`
    color: ${({ dark }) => (dark ? '#f9f9f9' : '#111121')};
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    font-size: 0.75rem;
    font-weight: 400;
`
export const StoryFeaturesWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    h4 {
	color: rgba(1, 219, 187, 1);
	text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
	font-size: 0.8rem;
	letter-spacing: 0.5px;
    }
`
export const StoryFeaturesButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 5px;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    white-space: nowrap;
    padding: 5px;
    color: #fff;
    font-size: 0.7rem;
    outline: none;
    border: none;
    cursor: pointer;
    margin-left: 5px;
    transition: all 0.2s ease-in-out;
    &:hover {
	transition: all 0.2s ease-in-out;
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1)' : ' rgba(39, 117, 85, 1)')};
	box-shadow: 0 0 5px ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
    }
`
export const StoryDetailWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const StoryDetailHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6);' : 'rgba(39, 117, 85, 0.6)')};
    h4 { 
	color: rgba(1, 219, 187, 1);
	text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
	font-size: 0.8rem;
	letter-spacing: 0.5px;
    }
`
export const StoryDetailImg = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6);' : 'rgba(39, 117, 85, 0.6)')};
    color: #fff;
    font-size: 0.7rem;
    margin-bottom: 5px;
    img { 
	width: 100%;
	max-width: 450px;
	max-height: 90px;
	-o-object-fit: cover;
	object-fit: cover;
    }
`
export const ActiveStoryContainer = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
`
export const ActiveStoryList = styled.div`
    width: 28px;
    height: 28px;
    display: felx;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: ${({ active }) => (active ? 'rgb(1, 219, 187)' : 'gray')};
    border: 1px solid rgba(39, 117, 85, 0.3);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.4);
    border-radius: 50%;
    color: #fff;
    font-size: 0.7rem;
    margin-left: 5px;
    cursor: pointer;
`
export const ActiveStoryImg = styled.img`
    width: 90%;
    height: 90%;
    border-radius: 50%;
    display: felx;
    align-items: center;
    justify-content: center;
`
