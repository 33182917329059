import React from 'react';
import { Helmet } from "react-helmet";
import TOS from '../components/TOS';
import { privacyData } from '../data/privacyData';

const PrivacyPage = ({ code }) => {
    return (
	<>
	    <Helmet>
	        <meta name="description" content="This privacy policy describes how iceFaucet store, use, process and share your personal information." />
	        <title>iceFaucet | privacy policy</title>
	    </Helmet>
            <TOS code={code} info={privacyData} />
	</>
    )
}
export default PrivacyPage;
