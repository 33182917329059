import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import VideoDetail from '../components/VideoDetail/VideoDetail';
import { MotherContainer } from '../components/PtcDetail/PtcDetailElements';
import { MsgText, MsgWrap } from '../components/MessageElements';
import { MsgContainer, MsgItem } from '../components/PtcDetail/PtcDetailElements';
import { TinyButton } from '../components/ButtonElements';

const VideoPlayPage = ({ match }) => {
    const [ads, setAds] = useState();
    const [error, setError] = useState('');
    const [errorDevice, setErrorDevice] = useState('');
    const [boolian, setBoolian] = useState(false);
    const dark = localStorage.getItem('lightness') ? true : false;
    useEffect(() => {
	const fetchPlayVideo = async () => {
	    try {
		const { data } = await axiosInstance.get(`ptc/video/play/${match.params.code}/${match.params.uidb64}/${match.params.token}/`);
		setAds(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    };
	};
	fetchPlayVideo();
    }, [match.params.code, match.params.uidb64, match.params.token]);
    const deviceScreenhandler = () => {
	if (window.innerWidth < 1023 && ads !== undefined && ads.device === 'D') {
	    setErrorDevice('The width of window for desktop devices has been should more than 1000px!');
	};
	setBoolian(true);
    };
    useEffect(() => {
	if (ads !== undefined) {
	    window.addEventListener('resize', deviceScreenhandler);
	    deviceScreenhandler();
	};
	return () => {
	    window.removeEventListener('resize', deviceScreenhandler);
	};
    });
    return (
	<MotherContainer dark={dark ? 1 : 0} style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
	    {error.length !== 0 &&
		<MsgContainer>
		    <MsgItem>
		    	<MsgWrap primary={0}>
		            <MsgText primary={0}>
		                {error}
		            </MsgText>
		        </MsgWrap>
		        <TinyButton to={'/dashboard'} primary={1} big={1}>
		            return
		        </TinyButton>
		    </MsgItem>
		</MsgContainer>
	    }
	    {errorDevice.length !== 0 &&
		<MsgContainer>
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
			        {errorDevice}
		            </MsgText>
			</MsgWrap>
			<TinyButton to={'/dashboard'} primary={1} big={1}>
			    return
		        </TinyButton>
		    </MsgItem>
		</MsgContainer>
	    }
	    {ads !== undefined && errorDevice.length === 0 && boolian && error.length === 0 && (<VideoDetail ads={ads} setAds={setAds} />)}
	    </MotherContainer>
	)
}
export default VideoPlayPage;
