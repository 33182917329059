import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';
import { VscAccount } from 'react-icons/vsc';
import { MdLanguage } from 'react-icons/md';

export const SidebarContainer = styled.div`
    position: fixed;
    z-index: 98;
    width: 100%;
    height: 100vh;
    background: rgba(1, 6, 6, 0.9);
    display: flex;
    justify-content: center;
    left: 0;
    transition: 0.3s ease-in-out;
    overflow: hidden;
    top: ${({ isOpen }) => (isOpen ? '0' : '-120%')};
`
export const SidebarWrapper = styled.div`
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    width: 80%;
    height: 77%;
    background: rgba(1, 6, 6, 1);
    color: #fff;
    overflow-y: auto;
    margin: auto;

    @media screen and ${device.sm} {
        width: 95%;
    }
        
    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(256, 256, 256, 0.2);
        box-shadow: inset 0 0 4px rgba(29, 107, 187, 0.7);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(29, 107, 187, 0.7);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(29, 107, 187, 0.8);
    }
`
export const SidebarNav = styled.nav`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`
export const SidebarMenu = styled.ul`
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    grid-template-rows: repeat(6, 50px);
    margin-bottom: 20px;
    height: 85%;
    width: 100%;

    li {
        height: 50px;
        width: 100%;

        &:hover{
            background: rgba(29, 107, 187, 0.3);
        };
    };
`
export const SidebarLink = styled(LinkS)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; 
    height: 100%;
    color: #fff;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;

    &:hover {
        color: #01DBBB;
        transition: 0.3s ease-out;
    }
`
export const IconsWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70px;
    height: 35px;
    margin: auto;
`
export const AccountIconLink = styled(LinkR)`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const StyleIcon = css`
    font-size: 1.7rem;
    color: #fff;
    transition: 0.3s ease-out;

    &:hover {
        color: #01DBBB;
        transition: 0.3s ease-out;
    }
`
export const AccountIcon = styled(VscAccount)`
    ${StyleIcon}
`
export const LangIcon = styled(MdLanguage)`
    ${StyleIcon}
    font-size: 2rem;
    cursor: pointer;
`
export const LanguageWrap = styled.div`
    list-style: none;
    background: rgba(39, 117, 85, 0.75);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;

    @media screen and ${device.md} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media screen and ${device.tn} {
        grid-template-columns: 1fr 1fr;
    }
`
