import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch } from 'react-redux';
import { creditCheckAction } from '../../actions/PtcSetUpAction';
import { bannerSetUpAction, bannerFiltersAction } from '../../actions/bannerSetUpAction';
import { PtcForm, PtcFormInputContainer, PtcInputWrap, PtcFormInput, PtcLinkIcon, PtcTitleIcon, FormLangContainer, FilterLangWrap, FormLangWrap, LangWrap, LangName } from '../SetUpPtc/SetUpPtcFormElements.js';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { TypeBannerContainer, TypeBannerWrap } from './SetUpBannerFormElements';
import { bannerTypeData } from '../../data/bannerFiltersData';
import { TinyFormButton } from '../ButtonElements';
import { FormButtonsWrap } from '../SetUpPtc/CreditCheckElements';
import { ValidateBannerForm } from './ValidateBannerForm';
import { langData } from '../../data/langData';
import { MsgText, MsgWrap } from '../MessageElements';

const SetUpBannerForm = () => {
    const dispatch = useDispatch();
    const [values, setValues] = useState(Object.freeze({
        url: '',
        title: '',
    }));
    const [errors, setErrors] = useState({
        url: '',
        title: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [bannerType, setBannerType] = useState('');
    const [errorType, setErrorType] = useState('');
    const [typeIndex, setTypeIndex] = useState();
    const [langActive, setLangActive] = useState(false);
    const [langName, setLangName] = useState(Object.freeze('en'));
    const [langIndex, setLangIndex] = useState(0);
    const [error, setError] = useState('');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setIsSubmitting(false);
        setErrors('');
        setError('');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateBannerForm(values));
        if (bannerType.length === 0) {
            setErrorType('Select one type of banner first.');
        };
        setIsSubmitting(true);
    };
    const handleClick = (e, index) => {
        errorType.length !== 0 && setErrorType('');
        const bannerTypeName = e.target.getAttribute('name');
        if (bannerTypeName && bannerType !== bannerTypeName) {
            setBannerType(bannerTypeName);
            setTypeIndex(index);
            setIsSubmitting(false);
        };
        setErrors('');
    };
    const handleLangActive = () => {
        setLangActive(!langActive);
        if (langActive) {
            localStorage.removeItem('lang');
        };
    };
    const handleLangClick = (e, index) => {
        const LANG = e.target.getAttribute('name');
        if (LANG && langName !== LANG) {
            setLangName(LANG);
            setLangIndex(index);
        };
        setError('');
    };
    const handleBack = () => {
        dispatch(bannerSetUpAction({ prop: false }));
        dispatch(creditCheckAction({ prop: true }));
    };
    const fetchLangUserCkeck = async () => {
        try {
            await axiosInstance.post('banner/advertise/lang-filter-check/', {
                active_lang: langActive,
                lang: langName,
                title: values.title
            });
            fetchBannerSetUp();
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            setIsSubmitting(false);
        };
    };
    const fetchBannerSetUp = async () => {
        localStorage.setItem('type', bannerType);
        langActive && localStorage.setItem('lang', langName);
        !langActive && localStorage.removeItem('lang');
        localStorage.setItem('url', values.url);
        localStorage.setItem('title', values.title);
        dispatch(bannerSetUpAction({ prop: false }));
        dispatch(bannerFiltersAction({ prop: true }));
        setIsSubmitting(false);
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            fetchLangUserCkeck();
        };
        return () => setIsSubmitting(false);
    });
    return (
        <PtcForm action='#' onSubmit={handleSubmit}>
            <PtcFormInputContainer>
                <FormHeader>
                    <h2>type of your banner</h2>
                </FormHeader>
                <TypeBannerContainer>
                    <TypeBannerWrap>
                        {bannerTypeData.map((banner, index) => (
                            <ItemWrap key={index}>
                                <ItemName name={banner.name} onClick={(e) => handleClick(e, index)} itemActive={typeIndex === index ? 1 : 0}>
                                    {banner.title}
                                </ItemName>
                            </ItemWrap>
                        ))}
                    </TypeBannerWrap>
                </TypeBannerContainer>
                {bannerType === 'I' &&
                    <>
                        <FormHeader>
                            <h2>language of your ads</h2>
                        </FormHeader>
                        <FilterContainer>
                            <InstructionWrap>
                                <Instruction>
                                    If you are sure that visitors understand the language of your ads,
                                    press the following button, otherwise, your ads display to all visitors in any language. (it could be really dangerous for CTR.)
                                </Instruction>
                            </InstructionWrap>
                            <FilterLangWrap>
                                <ItemWrap >
                                    <ItemName onClick={handleLangActive} itemActive={langActive ? 1 : 0}>
                                        Accept <br />
                                        +10% CPM
                                    </ItemName>
                                </ItemWrap>
                            </FilterLangWrap>
                            <FormLangContainer>
                                <FormLangWrap>
                                    {langActive && langData.map((lang, index) => (
                                        <LangWrap key={index}>
                                            <LangName name={lang.value} onClick={(e) => handleLangClick(e, index)} itemActive={langIndex === index ? 1 : 0}>{lang.name}</LangName>
                                        </LangWrap>
                                    ))}
                                </FormLangWrap>
                            </FormLangContainer>
                        </FilterContainer>
                        <FormHeader>
                            <h2>describe your ads</h2>
                        </FormHeader>
                        <PtcInputWrap>
                            <PtcLinkIcon />
                            <PtcFormInput
                                type='url'
                                name='url'
                                placeholder='Enter the clickable link of your banner.'
                                onChange={handleChange}
                                value={values.url}
                            >
                            </PtcFormInput>
                        </PtcInputWrap>
                        {errors.url &&
                            <MsgWrap>
                                <MsgText>
                                    {errors.url}
                                </MsgText>
                            </MsgWrap>}
                        <PtcInputWrap>
                            <PtcTitleIcon />
                            <PtcFormInput
                                type='text'
                                name='title'
                                placeholder='Enter the banner title.'
                                onChange={handleChange}
                                value={values.title}
                            >
                            </PtcFormInput>
                        </PtcInputWrap>
                        {errors.title &&
                            <MsgWrap>
                                <MsgText>
                                    {errors.title}
                                </MsgText>
                            </MsgWrap>}
                        {error &&
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    {error}
                                </MsgText>
                            </MsgWrap>}
                    </>
                }
            </PtcFormInputContainer>
            {errorType &&
                <MsgWrap>
                    <MsgText>
                        {errorType}
                    </MsgText>
                </MsgWrap>}
            <FormButtonsWrap>
                <TinyFormButton type='submit' primary={1} big={0}>
                    Next
                </TinyFormButton>
                <TinyFormButton type='button' primary={1} big={0} onClick={handleBack}>
                    back
                </TinyFormButton>
            </FormButtonsWrap>
        </PtcForm>
    )
};
export default SetUpBannerForm;
