import {
    SHOW_CREDITCHECK,
    NOTSHOW_CREDITCHECK,
    SHOW_PTCSETUP,
    NOTSHOW_PTCSETUP,
    SHOW_PTCFILTERS,
    NOTSHOW_PTCFILTERS,
    SHOW_PTCFILTERS2,
    NOTSHOW_PTCFILTERS2,
    SHOW_PTCFILTERS3,
    NOTSHOW_PTCFILTERS3,
    SHOW_PTCSETUPEND,
    NOTSHOW_PTCSETUPEND,
    SHOW_PTCSUCCESS,
    NOTSHOW_PTCSUCCESS,
} from '../constants/ShowPtcSetUpRedux';


export const creditCheckReducer = (state = true, action) => {
    switch (action.type) {
        case (SHOW_CREDITCHECK):
            return true
        case (NOTSHOW_CREDITCHECK):
            return false
        default:
            return state
    }
};


export const ptcSetUpReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_PTCSETUP):
            return true
        case (NOTSHOW_PTCSETUP):
            return false
        default:
            return state
    }
};

export const ptcFiltersReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_PTCFILTERS):
            return true
        case (NOTSHOW_PTCFILTERS):
            return false
        default:
            return state
    }
};

export const ptcFilters2Reducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_PTCFILTERS2):
            return true
        case (NOTSHOW_PTCFILTERS2):
            return false
        default:
            return state
    }
};

export const ptcFilters3Reducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_PTCFILTERS3):
            return true
        case (NOTSHOW_PTCFILTERS3):
            return false
        default:
            return state
    }
};
export const ptcSetUpEndReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_PTCSETUPEND):
            return true
        case (NOTSHOW_PTCSETUPEND):
            return false
        default:
            return state
    }
};

export const ptcSuccessReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_PTCSUCCESS):
            return true
        case (NOTSHOW_PTCSUCCESS):
            return false
        default:
            return state
    }
};
