import React from 'react';
import { Container, Wrapper, SubjectWrap } from '../TOS/TOSElements';
import { HeaderArticle, SectionWrap, ImgArticleWrap, VideoLinkWrap, DescWrap, SectionContainer, SectionTitle, SectionContentWrap, SectionWrapper, ImgSectionWrap, DescSectionWrap } from './BlogArticleElements';
import youtube from '../../images/youtube.svg';
import { TinyButtonAnchor } from '../ButtonElements';

const BlogArticle = ({ info }) => {
    return (
        <Container>
            <Wrapper>
                <HeaderArticle>
                    <SubjectWrap>
                        <h1>{info.title}</h1>
                    </SubjectWrap>
                </HeaderArticle>
                <SectionWrap primary={1}>
                    <ImgArticleWrap>
                        <img src={info.cover} alt={info.title} />
	    		{info.video_link &&
			    <VideoLinkWrap>
				<img src={youtube} alt='youtube video' style={{ width: '50px', height: '50px' }} />
				<TinyButtonAnchor href={info.video_link} style={{ width: '100%', marginLeft: '5px' }}>watch now</TinyButtonAnchor>
			    </VideoLinkWrap>
			}
                    </ImgArticleWrap>
                    <DescWrap>
                        <p>{info.abstract}</p>
                    </DescWrap>
                </SectionWrap>
                {info.blog_help_media !== undefined && info.blog_help_media.map((section, index) => (
                    <SectionContainer key={index} primary={0}>
                        <SectionTitle primary={0}>
                            <h2>section #{index + 1}</h2>
                        </SectionTitle>
                        <SectionContentWrap>
			    <SectionWrapper>
			        <ImgSectionWrap primary={0}>
			            <img src={section.image} alt={info.title + index} />
			        </ImgSectionWrap>
			        {section.link &&
				    <TinyButtonAnchor href={section.link} style={{ marginTop: '10px' }}>view link</TinyButtonAnchor>
				}
			    </SectionWrapper>
                            <DescSectionWrap primary={0}>
                                <p>{section.description}</p>
                            </DescSectionWrap>
                        </SectionContentWrap>
                    </SectionContainer>
                ))}
            </Wrapper>
        </Container>
    )
}
export default BlogArticle;
