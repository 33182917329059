import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showMainbarAction } from '../../actions/showMainbarAction';
import { IconContext } from 'react-icons/lib';
import { MBContainer, MBWrap, MBMenu, MenuList, MenuLinks, DropDownLinks, AuthFooter, AuthFooterSocialWrap, AuthSocialIconLink } from './MainbarElements';
import { WebsiteRightsWrap, WebsiteRights, Youtube, Instagram, Twitter } from '../Footer/FooterElements';
import { mainbarData } from '../../data/mainbarData';

const Mainbar = () => {
    const dispatch = useDispatch();
    const isShow = useSelector(state => state.showMainbar);
    const [clicked, setClicked] = useState('');
    const [subClicked, setSubClicked] = useState('');
    const [mobileShow, setMobileShow] = useState(false);

    const toggle = index => {
        if (clicked === index) {
            return setClicked(null);
        };
        setClicked(index);
    };
    const subToggle = index => {
        if (subClicked === index) {
            return setSubClicked(null);
        };
        setSubClicked(index);
    };
    const showMobileHandle = (path) => {
        mobileShow && path !== undefined && dispatch(showMainbarAction({ prop: false }));
    };
    const closeMainbar = () => {
        if (window.innerWidth < 767) {
            setMobileShow(true);
        };
    };

    useEffect(() => {
        window.addEventListener('resize', closeMainbar);
        closeMainbar();
        return () => {
            window.addEventListener('resize', closeMainbar);
        };
    });

    return (

        <MBContainer isShow={isShow ? 1 : 0}>
            <MBWrap>
                <IconContext.Provider value={{ style: { verticalAlign: 'middle', color: 'rgb(1, 219, 187)', fontSize: '1rem' } }} >
                    <MBMenu>
                        {mainbarData.map((item, index) => (
                            <div key={index}>
                                <MenuList
                                    onClick={() => { toggle(index); setSubClicked(false); showMobileHandle(item.path) }}
                                    active={clicked === index
                                        ? 1
                                        : 0}
                                >
                                    <MenuLinks to={item.path
                                        ? item.path
                                        : '#'}>
                                        <div>
                                            <span>{item.icon}</span>
                                            <span>{item.title}</span>
                                        </div>
                                        <div>
                                            {item.subNav && clicked === index
                                                ? item.iconOpened
                                                : item.subNav
                                                    ? item.iconClosed
                                                    : null}
                                        </div>
                                    </MenuLinks>
                                </MenuList>
                                {!item.path && clicked === index && item.subNav.map((subLink, index) => (
                                    <DropDownLinks key={index} to={subLink.path} onClick={() => { subToggle(index); showMobileHandle(subLink.path) }} active={subClicked === index ? 1 : 0}>
                                        <span>{subLink.title}</span>
                                    </DropDownLinks>
                                ))}
                            </div>
                        ))}
                    </MBMenu>
                </IconContext.Provider >
            </MBWrap>
            <AuthFooter>
                <WebsiteRightsWrap>
                    <WebsiteRights>&copy; IceFaucet 2022-{new Date().getFullYear()} | All rights reserved</WebsiteRights>
                </WebsiteRightsWrap>
                <AuthFooterSocialWrap>
                    <AuthSocialIconLink href='http://www.youtube.com/@icefaucet' target='_blank' aria-label='Youtube'>
                        <Youtube />
                    </AuthSocialIconLink>
                    <AuthSocialIconLink href='https://www.instagram.com/icefa.social' target='_blank' aria-label='Instagram'>
                        <Instagram />
                    </AuthSocialIconLink>
                    <AuthSocialIconLink href='https://twitter.com/icefa_social' target='_blank' aria-label='Twitter'>
                        <Twitter />
                    </AuthSocialIconLink>
                </AuthFooterSocialWrap>
            </AuthFooter>
        </MBContainer >
    )
}

export default Mainbar;
