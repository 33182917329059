import React, { useState, useEffect } from 'react';
import axiosInstance from '../axios';
import Speedometer from '../components/Speedometer';
import IcefaCaptcha from '../components/Captcha/IcefaCaptcha';
import { MsgText, MsgWrap } from '../components/MessageElements';
import { MsgContainer, MsgItem } from '../components/PtcDetail/PtcDetailElements';
import { TinyButton, TinyButtonSurf } from '../components/ButtonElements';
import { Circle } from '../components/Loading';
import FaucetActiveDesktopBanners from '../components/FaucetBanners/FaucetActiveDesktopBanners';
import FaucetActiveMobileBanners from '../components/FaucetBanners/FaucetActiveMobileBanners';
import FaucetDeactiveDesktopBanners from '../components/FaucetBanners/FaucetDeactiveDesktopBanners';
import FaucetDeactiveMobileBanners from '../components/FaucetBanners/FaucetDeactiveMobileBanners';

const FaucetPage = () => {
    const [device, setDevice] = useState('');
    const [timer, setTimer] = useState();
    const [values, setValues] = useState();
    const [buttonTimer, setButtonTimer] = useState(10);
    const [errorDevice, setErrorDevice] = useState('');
    const [error, setError] = useState('');
    const [boolian, setBoolian] = useState(false);
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [numClaim, setNumClaim] = useState();
    const [snow, setSnow] = useState(false);
    const [iceday, setIceday] = useState(false);
    const [icedayExpireTimer, setIcedayExpireTimer] = useState();
    const [lastIcedayClaim, setLastIcedayClaim] = useState(false);
    const [iceCaptcha, setIceCaptcha] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const [desktopBanner, setDesktopBanner] = useState();
    const [mobileBanner, setMobileBanner] = useState();
    const [desktopDeactiveBanner, setDesktopDeactiveBanner] = useState(false);
    const [mobileDeactiveBanner, setMobileDeactiveBanner] = useState(false);
    const pcoin = values !== undefined && Number((values.pcoin).toFixed(2));
    const fetchSpeedoMeterValues = async () => {
	try {
	    const { data } = await axiosInstance.get('faucet/values/');
	    setValues(data);
	    setNumClaim(data.num_claim);
            setSnow(data.snow);
	    setIceday(data.ice_day);
	} catch (error) {
	    error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	};
	setLoading(false);
    };
    const fetchFaucetClaim = async () => {
	setLoading2(true);
	try {
	    const { data } = await axiosInstance.get('faucet/claim/');
	    setComplete(true);
	    setNumClaim(data.num_claim);
	    setSnow(data.snow);
	    setIceday(data.ice_day);
	} catch (error) {
	    error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	};
	setLoading2(false);
    };
    const handleClick = () => {
	fetchFaucetClaim();
    };
    useEffect(() => {
	captchaDone && !loading2 && !complete && !error && fetchFaucetClaim();
    });
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    const deviceScreenhandler = () => {
	if (window.innerWidth < 1025 && device === 'D') {
	    setErrorDevice('The width of window for desktop devices has been should more than 1000px!');
	    setLoading(false);
	};
	setBoolian(true);
    };
    useEffect(() => {
	const fetchCheckTimer = async () => {
	    const { data } = await axiosInstance.get('faucet/check-time/');
	    setDevice(data.device);
	    setIcedayExpireTimer(data.moments);
	    setLastIcedayClaim(data.last_ice_claim)
	    if (!data.status) {
		setTimer(data.timer);
		(window.innerWidth > 1023 && data.device === 'D') && setDesktopDeactiveBanner(true);
		data.device === 'M' && setMobileDeactiveBanner(true);
		setLoading(false);
		data.error && setError(data.error);
	    } else {
		((window.innerWidth > 1023 && data.device === 'D') || (data.device === 'M')) && fetchSpeedoMeterValues(data.device);
		(window.innerWidth > 1023 && data.device === 'D') && fetchDesktopBanner();
		data.device === 'M' && fetchMobileBanner();
	    };
	};
	fetchCheckTimer();
    }, []);
    useEffect(() => {
	if (device.length > 0) {
	    window.addEventListener('resize', deviceScreenhandler);
	    deviceScreenhandler();
	};
	return () => window.removeEventListener('resize', deviceScreenhandler)
    });
    useEffect(() => {
	if (buttonTimer === 0) {
	    setIceCaptcha(true);
	    return;
	};
	if (buttonTimer > 0 && device.length > 0 && timer === undefined) {
	    const timer = setTimeout(() => {
		setButtonTimer(buttonTimer - 1);
	    }, 1000);
	    return () => clearTimeout(timer);
	};
    }, [buttonTimer, device.length, timer]);
    const fetchDesktopBanner = async () => {
	const { data } = await axiosInstance.get('banner/faucet/desktop/');
	setDesktopBanner(data);
	if (data.p2 !== null) {
	    const ins = document.getElementById('surf1');
	    ins.className = 'surfe-be';
	    ins.setAttribute('data-sid', data.p2.data);
	    const script = document.createElement('script');
	    script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
	    document.body.appendChild(script);
	    return new Promise(() => {
		script.remove();
	    });
	};
    };
    const fetchMobileBanner = async () => {
	const { data } = await axiosInstance.get('banner/faucet/mobile/');
	setMobileBanner(data);
	if (data.p3 !== null) {
	    const ins = document.getElementById('surf2');
	    ins.className = 'surfe-be';
	    ins.setAttribute('data-sid', data.p3.data);
	    const script = document.createElement('script');
	    script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
            document.body.appendChild(script);
	    return new Promise(() => {
		script.remove();
	    });
	};
	if (data.p5 !== null && data.plan === 'A') {
	    const ins = document.getElementById('surf3');
	    ins.className = 'surfe-be';
	    ins.setAttribute('data-sid', data.p5.data);
	    const script = document.createElement('script');
	    script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
	    document.body.appendChild(script);
	    return new Promise(() => {
		script.remove();
	    });
	};
    };
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && errorDevice.length === 0 && boolian && desktopDeactiveBanner && <FaucetDeactiveDesktopBanners timer={timer} />}
	    {!loading && errorDevice.length === 0 && boolian && mobileDeactiveBanner && <FaucetDeactiveMobileBanners timer={timer} />}
	    {!loading && errorDevice.length === 0 && boolian && values !== undefined && <Speedometer values={values} numClaim={numClaim} snow={snow} iceday={iceday} icedayExpireTimer={icedayExpireTimer} setIcedayExpireTimer={setIcedayExpireTimer} setLastIcedayClaim={setLastIcedayClaim} lastIcedayClaim={lastIcedayClaim} buttonTimer={buttonTimer} banner={desktopBanner} />}
	    {!loading && errorDevice.length !== 0 &&
		<MsgContainer>
		    <MsgItem>
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
			        {errorDevice}
		            </MsgText>
			</MsgWrap>
			<TinyButton to={'/dashboard'} primary={1} big={1}>
			    return
		        </TinyButton>
		    </MsgItem>
		</MsgContainer>
	    }
	    {!loading && !error && errorDevice.length === 0 && boolian && desktopBanner !== undefined &&
		<FaucetActiveDesktopBanners desktopBanner={desktopBanner} />
	    }
	    {!loading && !error && errorDevice.length === 0 && boolian && mobileBanner !== undefined &&
		<FaucetActiveMobileBanners mobileBanner={mobileBanner} />
	    }
	    {!loading && error.length !== 0 &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '5px auto' }}>
		    <MsgText primary={0}>
			{error}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && error.length === 0 && complete &&
		<div style={{ width: '80%', margin: '5px auto' }}>
		    <MsgWrap primary={1}>
			<MsgText primary={1}>
			    {`You claim ${pcoin} pc ${values.ticket_win ? 'and 1 lottery ticket' : ''} successfully.`}
		        </MsgText>
		    </MsgWrap>
		</div>
	    }
	    {iceCaptcha && !captchaDone && !complete &&
		<IcefaCaptcha appName='faucet' setCaptchaDone={setCaptchaDone} dark={0} />
	    }
	    {!loading && timer === undefined && errorDevice.length === 0 && boolian && values !== undefined && !complete && !iceCaptcha &&
		<TinyButtonSurf
		    style={{ margin: '0 auto 15px auto', width: '80%' }}
		    type='button'
		    onClick={buttonTimer === 0 && !loading2 ? handleClick : null}
		    onContextMenu={handleRightClick}
		    big={1}
		>
		    {buttonTimer > 0 ? buttonTimer + ' sec' : loading2 ? <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} /> : 'claim'}
		</TinyButtonSurf>
	    }
	</ >
    )
}
export default FaucetPage;

