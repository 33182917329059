import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { LinksTableContainer, RefButton } from './ReferralLinkTabElements';

export const BannerTableContainer = styled(LinksTableContainer)`
    grid-template-columns: 1fr;
`
export const BannerImgWrap = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : 'rgba(39, 117, 85, 0.8)')};
    box-shadow: 0 0 5px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};;
    border-radius: 5px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const BannerImg = styled.img`
    width: ${({ size }) => (size === 'd' ? '100%' : size === 'e' ? '160px' : '70%')};
    height: ${({ size }) => (size === 'd' ? '80px' : null)};
`
export const BannerDetailWrap = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : 'rgba(39, 117, 85, 0.8)')};
    box-shadow: 0 0 5px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 5px;
    padding: 5px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    @media screen and ${device.tn} {
        padding: 5px;
    }
`

export const BannerButton = styled(RefButton)`
    width: 90px;

    @media screen and ${device.sm} {
        width: 80px;
    }

    @media screen and ${device.tn} {
        width: 75px;
    }
`