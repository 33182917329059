import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { ContestContainer, ContestWrap, ContestImgWrap, ContestImg, ContentWrap, LoadingWrap, Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from './ContestElements';
import { ProgressBarWrap, ProgressBar } from '../TaskView/TaskViewElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../PtcView/PtcViewElements';
import { DescWrap } from '../History/DepositTabElements';
import { offerLoadingContestTableHead } from '../../data/contestData';
import { MsgWrap, MsgText } from '../MessageElements';
import { Button } from '../ButtonElements';

const LoadingContestOffer = ({ big, table }) => {
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    useEffect(() => {
	const fetchOfferContestView = async () => {
	    try {
		const { data } = await axiosInstance.get('earning/contest/offer/');
		setInfo(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	};
	fetchOfferContestView();
    }, []);
    return (
	<>
	    <ContestContainer primary={1} active={info !== undefined && info.percentage === 100 ? 1 : 0} style={{ top: '0' }} big={big}>
	        <ContestWrap primary={1} big={big}>
	    	    <ContestImgWrap>
	       	        <ContestImg src='https://icefaucet.com/media/help/blog/offercontest.png' alt='contest' big={big} />
	    	    </ContestImgWrap>
	    	    {info !== undefined && info.percentage < 100 &&
			<ContentWrap>
			    <LoadingWrap primary={0} big={big}>
			        <h4>loading:</h4>
			        <ProgressBarWrap primary={0} style={{ margin: '0 3px 0 7px' }}>
			            <ProgressBar width={String(info.percentage) + '%'}>
			                <span>{info.percentage}%</span>
			            </ProgressBar>
			        </ProgressBarWrap>
			    </LoadingWrap>
			    {table &&
				<Table style={{ marginTop: '25px' }}>
				    <HeadWrap>
				        <HeadRow>
				            {offerLoadingContestTableHead.map((header, index) => (
						<Header key={index}>
						    {header}
						</Header>
					    ))}
				        </HeadRow>
				    </HeadWrap>
				    <BodyWrap>
				        {info.info.map((row, index) => (
					    <BodyRow key={index}>
						<Cell>
						    {index + 1}
						</Cell>
						<Cell>
						    {row.username ? row.username : row.user_id}
						</Cell>
						<Cell>
						    {row.earned}
						</Cell>
					    </BodyRow>
					))}
				    </BodyWrap>
				</Table>
			    }
			</ContentWrap>
		    }
	    	    {info !== undefined && info.percentage === 100 &&
			<ContentWrap>
			    {table &&
				<PtcHeaderWrap>
				    <PtcInfoWrap>
					<InfoContainer>
					    <InfoWrap>
						<TitleInfoWrap>
						    <h2>reward</h2>
						</TitleInfoWrap>
						<DescWrap>
						    <h2>{info.reward}</h2>
						</DescWrap>
					    </InfoWrap>
					    <InfoWrap>
						<TitleInfoWrap>
						    <h2>winners</h2>
						</TitleInfoWrap>
						<DescWrap>
						    <h2>{info.winners}</h2>
						</DescWrap>
					    </InfoWrap>
					</InfoContainer>
				    </PtcInfoWrap>
				</PtcHeaderWrap>
			    }
			    <Button to='offers/contest' primary={1} big={big} fontbig={big} style={{ width: '80%' }}>WATCH OFFER CONTEST</Button>
			</ContentWrap>
		    }
	    	</ContestWrap>
	        {apiError &&
		    <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
			<MsgText primary={0}>
			    {apiError}
			</MsgText>
		    </MsgWrap>
		}
	    </ContestContainer>
	</>
    )
}
export default LoadingContestOffer;
