import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { StackContainer, StackWrap, TitleWrap } from '../components/Stack/StackElements';
import { OffersContainer } from '../components/OfferView/CPXCardElements';
import CPXCard from '../components/OfferView/CPXCard';
import { Circle } from '../components/Loading';
import { MsgWrap, MsgText } from '../components/MessageElements';

const OfferListCPX = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    useEffect(() => {
	const fetchOfferListCPX = async () => {
	    try {
		const { data } = await axiosInstance.get(`offer/click/CPX/`);
		setInfo(data.info);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchOfferListCPX();
    }, []);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && !apiError && info !== undefined &&
	        <StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>cpx research</h2>
			</TitleWrap>
			{info.surveys.length > 0 &&
			    <OffersContainer>
			        {info.surveys.map((item, index) => (
				    <CPXCard key={index} info={item} surveyNum={index + 1} />
			        ))}
		            </OffersContainer>
			}
		        {info.surveys.length === 0 &&
			    <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
				<MsgText primary={0}>
				    Currently, there are no surveys for you, please try later.
				</MsgText>
			    </MsgWrap>
			}
		    </StackWrap>
		</StackContainer>
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	</>
    )
}
export default OfferListCPX;
