import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { Container, Wrap, Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell, MembershipContainer, MembershipWrap, ImageWrap, Image, SelectWrap, Button } from './UpgradeContainerElements';
import { upgradeTableHead, upgradeTableRow, SelectMembership } from '../../data/upgradeData';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';

const UpgradeContainer = () => {
    const [active, setActive] = useState(false);
    const [buttonIndex, setButtonIndex] = useState();
    const [active2, setActive2] = useState(false);
    const [values, setValues] = useState(Object.freeze());
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [complete, setComplete] = useState(false);
    const handleClick = (e, index) => {
        const name = e.target.getAttribute('name');
        let price = name === '0.75' ? 5 : name === '1' ? 10 : 0;
        setActive2(false);
        setActive(true);
        setValues({ 'membership': name, 'time': 1, 'price': price })
        setButtonIndex(index);
        setError('');
    };
    const handleClick2 = (e, index) => {
        const name = e.target.getAttribute('name');
        let price = name === '0.75' ? 25 : name === '1' ? 50 : 0;
        setActive(false);
        setActive2(true);
        setValues({ 'membership': name, 'time': 6, 'price': price })
        setButtonIndex(index);
        setError('');
    };
    const handleSubmit = () => {
        if (!active && !active2) {
            setError('You should select your membership first.')
        } else {
            setSubmitted(true);
        };
    };
    const fetchPurchaseUpgrade = async () => {
        try {
            await axiosInstance.post('upgrade/', values);
            setComplete(true);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    useEffect(() => {
        if (submitted && error.length === 0 && !complete) {
            fetchPurchaseUpgrade();
        };
    });
    return (
        <Container>
            <Wrap>
                <Table>
                    <HeadWrap>
                        <HeadRow>
                            {upgradeTableHead.map((header, index) => (
                                <Header key={index}>
                                    {header}
                                </Header>
                            ))}
                        </HeadRow>
                    </HeadWrap>
                    <BodyWrap>
                        {upgradeTableRow.map((row, index) => (
                            <BodyRow key={index}>
                                <Cell>
                                    {row.detail}
                                </Cell>
                                <Cell>
                                    {row.omega}
                                </Cell>
                                <Cell>
                                    {row.beta}
                                </Cell>
                                <Cell>
                                    {row.alpha}
                                </Cell>
                            </BodyRow>
                        ))}
                    </BodyWrap>
                </Table>
                <MembershipContainer>
                    {SelectMembership.map((item, index) => (
                        <MembershipWrap key={index}>
                            <ImageWrap>
                                <Image src={item.src} alt={item.alt} />
                            </ImageWrap>
                            {item.name !== 0.50
                                ? <SelectWrap>
                                    <Button type='button' name={item.name} onClick={(e) => handleClick(e, index)} active={index === buttonIndex && active ? 1 : 0}>
                                        {item.button}
                                    </Button>
                                    <Button type='button' name={item.name} onClick={(e) => handleClick2(e, index)} active={index === buttonIndex && active2 ? 1 : 0}>
                                        {item.button2}
                                    </Button>
                                </SelectWrap>
                                : null}
                        </MembershipWrap>
                    ))}
                </MembershipContainer>
                {error &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {error}
                        </MsgText>
                    </MsgWrap>
                }
                {complete && !error &&
                    <MsgWrap primary={1}>
                        <MsgText primary={1}>
                            You have upgraded your account successfully.
                        </MsgText>
                    </MsgWrap>
                }
                {!complete && !error &&
                    <TinyFormButton primary={1} big={true} onClick={handleSubmit} style={{ marginTop: '10px' }}>
                        purchase
                    </TinyFormButton>
                }
            </Wrap>
        </Container>
    )
}
export default UpgradeContainer;
