export const
    ValidateTicketForm = (values) => {
        let errors = {};
        if (!values.subject) {
            errors.subject = 'Your ticket needs a subject.'
        } else if (values.subject.length >= 33 || values.subject.length <= 8) {
            errors.subject = 'The Title needs to be 8-32 characters.'
        };

        if (!values.question) {
            errors.question = 'Your ticket requires text.'
        } else if (values.question.length < 64) {
            errors.question = 'The text needs to be more than 64 characters.'
        };
        return errors
    };