export const adsOptions =
{
    id: 'special filters',
    darkBackground: true,
    items: [
        {
            image: require('../images/ref-ads.png').default,
            alt: 'referral filter',
            description: 'By activating this filter only your referrals watch your ads. Actually, this is your PTC website.',
            price: 'free'

        },
        {
            image: require('../images/lang-ads.png').default,
            alt: 'language filter',
            description: 'Do all visitors understand the language of your ads? To be sure activate this filter.',
            price: '+10% CPC'
        },
        {
            image: require('../images/country-ads.png').default,
            alt: 'country filter',
            description: 'If you consider showing your ads to some special countries or blocking them, just specify them.',
            price: '+25% CPC'
        },
        {
            image: require('../images/VPN-ads.png').default,
            alt: 'proxy filter',
            description: 'Your ads do not access members who use VPN or proxy services.',
            price: 'free'
        },
        {
            image: require('../images/device-ads.png').default,
            alt: 'device filter',
            description: 'Select the device of visitors that are supposed to watch your ads. Desktop, mobile, or both?',
            price: 'free'
        }
    ]
};
export const langAdsOptions = [
    {
        lang: 'es',
        info: {
            id: 'special filters',
            darkBackground: true,
            items: [
                {
                    image: require('../images/ref-ads.png').default,
                    alt: 'filtro de referencia',
                    description: 'Al activar este filtro, solo sus referidos ven sus anuncios. En realidad, este es su sitio web de PTC.',
                    price: 'libre'

                },
                {
                    image: require('../images/lang-ads.png').default,
                    alt: 'filtro de idioma',
                    description: '¿Todos los visitantes entienden el idioma de sus anuncios? Para estar seguro active este filtro.',
                    price: '+10% CPC'
                },
                {
                    image: require('../images/country-ads.png').default,
                    alt: 'filtro de país',
                    description: 'Si considera mostrar sus anuncios a algunos países especiales o bloquearlos, solo especifícalos.',
                    price: '+25% CPC'
                },
                {
                    image: require('../images/VPN-ads.png').default,
                    alt: 'filtro proxy',
                    description: 'Sus anuncios no acceden a los miembros que usan VPN o servicios de proxy.',
                    price: 'libre'
                },
                {
                    image: require('../images/device-ads.png').default,
                    alt: 'filtro de dispositivo',
                    description: 'Seleccione el dispositivo de los visitantes que se supone que deben ver sus anuncios. ¿Escritorio, móvil o ambos?',
                    price: 'libre'
                }
            ]
        }
    },
    {
        lang: 'ru',
        info:
        {
            id: 'special filters',
            darkBackground: true,
            items: [
                {
                    image: require('../images/ref-ads.png').default,
                    alt: 'реферальный фильтр',
                    description: 'Активировав этот фильтр, ваши объявления будут смотреть только ваши рефералы. На самом деле, это ваш веб-сайт PTC.',
                    price: 'бесплатная'

                },
                {
                    image: require('../images/lang-ads.png').default,
                    alt: 'языковой фильтр',
                    description: 'Все ли посетители понимают язык вашей рекламы? Для верности активируйте этот фильтр.',
                    price: '+10% CPC'
                },
                {
                    image: require('../images/country-ads.png').default,
                    alt: 'фильтр страны',
                    description: 'Если вы планируете показывать свои объявления в определенных странах или блокировать их, просто укажите их.',
                    price: '+25% CPC'
                },
                {
                    image: require('../images/VPN-ads.png').default,
                    alt: 'прокси-фильтр',
                    description: 'Ваши объявления не имеют доступа к участникам, которые используют VPN или прокси-сервисы.',
                    price: 'бесплатная'
                },
                {
                    image: require('../images/device-ads.png').default,
                    alt: 'фильтр устройств',
                    description: 'Выберите устройство посетителей, которые должны смотреть вашу рекламу. Настольный компьютер, мобильный или и то, и другое?',
                    price: 'бесплатная'
                }
            ]
        }
    },
    {
        lang: 'pt',
        info:
        {
            id: 'special filters',
            darkBackground: true,
            items: [
                {
                    image: require('../images/ref-ads.png').default,
                    alt: 'filtro de referência',
                    description: 'Ativando este filtro apenas seus referidos assistem seus anúncios. Na verdade, este é o seu site PTC.',
                    price: 'livre'

                },
                {
                    image: require('../images/lang-ads.png').default,
                    alt: 'filtro de idioma',
                    description: 'Todos os visitantes entendem o idioma de seus anúncios? Para ter certeza, ative este filtro.',
                    price: '+10% CPC'
                },
                {
                    image: require('../images/country-ads.png').default,
                    alt: 'filtro de país',
                    description: 'Se você considera exibir seus anúncios para alguns países especiais ou bloqueá-los, basta especificá-los.',
                    price: '+25% CPC'
                },
                {
                    image: require('../images/VPN-ads.png').default,
                    alt: 'filtro proxy',
                    description: 'Seus anúncios não acessam membros que usam VPN ou serviços de proxy.',
                    price: 'livre'
                },
                {
                    image: require('../images/device-ads.png').default,
                    alt: 'filtro de dispositivo',
                    description: 'Selecione o dispositivo dos visitantes que devem assistir aos seus anúncios. Computador, celular ou ambos?',
                    price: 'livre'
                }
            ]
        }
    },
    {
        lang: 'fr',
        info:
        {
            id: 'special filters',
            darkBackground: true,
            items: [
                {
                    image: require('../images/ref-ads.png').default,
                    alt: 'filtre de référence',
                    description: "En activant ce filtre, seuls vos filleuls regardent vos annonces. En fait, il s'agit de votre site Web PTC.",
                    price: 'libre'

                },
                {
                    image: require('../images/lang-ads.png').default,
                    alt: 'filtre de langue',
                    description: "Tous les visiteurs comprennent-ils la langue de vos annonces ? Pour être sûr d'activer ce filtre.",
                    price: '+10% CPC'
                },
                {
                    image: require('../images/country-ads.png').default,
                    alt: 'filtre pays',
                    description: 'Si vous envisagez de diffuser vos annonces dans certains pays particuliers ou de les bloquer, il vous suffit de les spécifier.',
                    price: '+25% CPC'
                },
                {
                    image: require('../images/VPN-ads.png').default,
                    alt: 'filtre proxy',
                    description: "Vos annonces n'accèdent pas aux membres qui utilisent des services VPN ou proxy.",
                    price: 'libre'
                },
                {
                    image: require('../images/device-ads.png').default,
                    alt: "filtre d'appareil",
                    description: "Sélectionnez l'appareil des visiteurs qui sont censés regarder vos publicités. Ordinateur de bureau, mobile ou les deux ?",
                    price: 'libre'
                }
            ]
        }
    },
    {
        lang: 'de',
        info:
        {
            id: 'special filters',
            darkBackground: true,
            items: [
                {
                    image: require('../images/ref-ads.png').default,
                    alt: 'Empfehlungsfilter',
                    description: 'Wenn Sie diesen Filter aktivieren, sehen nur Ihre Empfehlungen Ihre Anzeigen. Eigentlich ist dies Ihre PTC Website.',
                    price: 'kostenloser'

                },
                {
                    image: require('../images/lang-ads.png').default,
                    alt: 'Sprachfilter',
                    description: 'Verstehen alle Besucher die Sprache Ihrer Anzeigen? Um sicher zu sein, diesen Filter zu aktivieren.',
                    price: '+10% CPC'
                },
                {
                    image: require('../images/country-ads.png').default,
                    alt: 'Länderfilter',
                    description: 'Wenn Sie erwägen, Ihre Anzeigen in bestimmten Ländern zu schalten oder sie zu blockieren, geben Sie diese einfach an.',
                    price: '+25% CPC'
                },
                {
                    image: require('../images/VPN-ads.png').default,
                    alt: 'Proxy-Filter',
                    description: 'Ihre Anzeigen greifen nicht auf Mitglieder zu, die VPN- oder Proxy-Dienste verwenden.',
                    price: 'kostenloser'
                },
                {
                    image: require('../images/device-ads.png').default,
                    alt: 'Gerätefilter',
                    description: 'Wählen Sie das Gerät der Besucher aus, die Ihre Anzeigen sehen sollen. Desktop, mobil oder beides?',
                    price: 'kostenloser'
                }
            ]
        }
    },
    {
        lang: 'it',
        info:
        {
            id: 'special filters',
            darkBackground: true,
            items: [
                {
                    image: require('../images/ref-ads.png').default,
                    alt: 'filtro di riferimento',
                    description: 'Attivando questo filtro solo i tuoi referral guardano i tuoi annunci. In realtà, questo è il tuo sito Web PTC.',
                    price: 'libero'

                },
                {
                    image: require('../images/lang-ads.png').default,
                    alt: 'filtro lingua',
                    description: 'Tutti i visitatori comprendono la lingua dei tuoi annunci? Per essere sicuro attiva questo filtro.',
                    price: '+10% CPC'
                },
                {
                    image: require('../images/country-ads.png').default,
                    alt: 'filtro paese',
                    description: 'Se consideri di mostrare i tuoi annunci in alcuni paesi speciali o di bloccarli, specificali.',
                    price: '+25% CPC'
                },
                {
                    image: require('../images/VPN-ads.png').default,
                    alt: 'filtro proxy',
                    description: 'I tuoi annunci non accedono ai membri che utilizzano servizi VPN o proxy.',
                    price: 'libero'
                },
                {
                    image: require('../images/device-ads.png').default,
                    alt: 'filtro del dispositivo',
                    description: 'Seleziona il dispositivo dei visitatori che dovrebbero guardare i tuoi annunci. Desktop, mobile o entrambi?',
                    price: 'libero'
                }
            ]
        }
    }
];