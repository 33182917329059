import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaYoutube, FaInstagram, FaTwitter } from 'react-icons/fa';
import device from '../../constants/breakPoints';

export const FooterContainer = styled.footer`
    position: relative;
    background: linear-gradient(135deg, #010606 0% , #1D6BBB 30%, #277555 70%, #010606 105%);
    display: flex;
    justify-content: center; 
`
export const FooterWrap = styled.div`
    width: 94vw;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
`
export const FooterNav = styled.nav`
    display: grid;
    grid-template-columns : 2fr 1fr 1fr 1fr;   
    width: 100%;
    margin-top: 25px;

    @media screen and (max-width: 1024px) {
        grid-template-columns :1fr 1fr 1fr ; 
    }
    @media screen and (max-width: 768px) {
        grid-template-columns : 1fr; 
    }
`
export const FooterLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    @media screen and (max-width: 1024px) {
        grid-row-start : 1;
        grid-row-end : 3;
    }
    @media screen and ${device.md} {
	margin-bottom: 40px;
    };
`
export const FooterLogoWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const FooterLogoLink = styled(Link)`

`
export const FooterLogo = styled.img`
    width: 130px;
    height: 139px;
`
export const FooterSocialWrap = styled.div`
    width: 120px;
    margin: 8px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
`
export const Youtube = styled(FaYoutube)`
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #FF0000;
        transform: scale(1.11);
        transition: all 0.3s ease-in-out;
    }
`
export const Instagram = styled(FaInstagram)`
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #8a3ab9;
        transform: scale(1.11);
        transition: all 0.3s ease-in-out;
    }
`
export const Twitter = styled(FaTwitter)`
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #00acee;
        transform: scale(1.11);
        transition: all 0.3s ease-in-out;
    }
`
export const FooterContact = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 242px;
`
export const TitleContact = styled.span`
    color: #01DBBB;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px #010606;`
export const DescContact = styled.span`
    color: #fff;
    font-size: 0.7rem;
    letter-spacing: 1.04px;
    word-spacing: 1.07px;
    text-shadow: 0 0 3px #010606;
`
export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;

    @media screen and (max-width: 768px) {
        align-items: center;
    }
`
export const FooterLinkTitle = styled.h3`
    color: #01DBBB;
    font-size: 0.9rem;
    letter-spacing: 1.1px;
    word-spacing: 1.1px;
    text-transform: uppercase;
    margin-bottom: 1.1rem;
    text-shadow: 1px 1px 7px #010606;
`
export const FooterLink = styled(Link)`
    color: #fff;
    font-size: 0.8rem;
    text-decoration: none;
    letter-spacing: 1.04px;
    word-spacing: 1.07px;
    text-transform: uppercase;
    margin-bottom: 0.7rem;
    text-shadow: 1px 1px 3px #010606;

    &:hover {
        color: #01DBBB;
        padding-left: 3px;
        transition: 0.3s ease-out;
    }
`
export const WebsiteRightsWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const WebsiteRights = styled.small`
    color: #fff;
`
