import styled from 'styled-components';

export const ResetContent = styled.div`
    display: ${({ changed, expired }) => (changed || expired ? 'none' : 'flex')};
    flex-direction: column;
`
export const ChangedSuccessContainer = styled.div`
    display: ${({ changed, expired }) => (changed || expired ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    background: ${({ expired }) => (expired ? 'rgba(255, 0, 0, 0.3)' : 'rgba(39, 117, 85, 0.4)')};
    padding: 10px;
    margin: 25px 10px;
`
export const ChangedSuccess = styled.span`
    display: ${({ changed }) => (changed ? 'block' : 'none')};
    color: #01DBBB;
    font-size: 1rem;
`
export const ChangeExpired = styled.span`
    display: ${({ expired }) => (expired ? 'block' : 'none')};
    color: red;
    font-size: 1rem;
`