import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { MsgWrap, MsgText } from '../MessageElements';
import { SelectLangContainer, SelectLangWrap, LangContainer, LangWraper } from './UserSetLangElements';
import { LangWrap, LangName } from '../SetUpPtc/SetUpPtcFormElements';
import { langData } from '../../data/langData';
import { TinyFormButton } from '../ButtonElements';
import Modal from './Modal';

const UserSetLang = () => {
    const [langValue, setLangValue] = useState(Object.freeze(''));
    const [langName, setLangName] = useState('');
    const [langIndex, setLangIndex] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [modal, setModal] = useState(false);
    const [timer, setTimer] = useState();
    const handleLangClick = (e, index) => {
        const LANG = e.target.getAttribute('value');
        if (LANG && langValue !== LANG) {
            setLangValue(LANG);
            setLangIndex(index);
            setError('');
            setIsSubmitting(false);
        };
        const LANGNAME = e.target.getAttribute('NAME');
        if (LANGNAME && langName !== LANGNAME) {
            setLangName(LANGNAME);
        };
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (langValue.length === 0) {
            setError('You should selected a language.');
        };
        setIsSubmitting(true);
    };
    const fetchCheckUserSelectLang = async () => {
        try {
            await axiosInstance.get(`dashboard/user-select-language-check/${langValue}/`);
            setIsSubmitting(false);
            setModal(true);
        } catch (error) {
            error.response && error.response.data.timer
                ? setTimer(error.response.data.timer)
                : error.response && error.response.data.detail
                    ? setError(error.response.data.detail)
                    : setError(error.message);
        };
    };
    useEffect(() => {
        if (timer === undefined && error.length === 0 && isSubmitting) {
            fetchCheckUserSelectLang();
        };
    });
    return (
        <SelectLangContainer action='#' onSubmit={handleSubmit}>
            <SelectLangWrap>
                <MsgWrap primary={1}>
                    <MsgText primary={1}>
                        Please select your native or second language:
                    </MsgText>
                    <LangContainer>
                        <LangWraper>
                            {langData.map((lang, index) => (
                                <LangWrap key={index}>
                                    <LangName value={lang.value} name={lang.name} onClick={(e) => handleLangClick(e, index)} itemActive={langIndex === index ? 1 : 0}>{lang.name}</LangName>
                                </LangWrap>
                            ))}
                        </LangWraper>
                    </LangContainer>
                    {error &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {error}
                            </MsgText>
                        </MsgWrap>
                    }
                    {timer !== undefined &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                You could try again in {Math.ceil(timer / 60)} minutes.
                            </MsgText>
                        </MsgWrap>
                    }
                    {!isSubmitting && timer === undefined &&
                        <TinyFormButton type='submit' primary={1} big={1}>
                            Accept
                        </TinyFormButton>
                    }
                    {langName &&
                        <Modal lang={langValue} langName={langName} modal={modal} setModal={setModal} />
                    }
                </MsgWrap>
            </SelectLangWrap>
        </SelectLangContainer>
    )
}
export default UserSetLang;