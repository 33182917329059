import React from 'react';
import { PtcContainer, PtcWrap } from '../PtcView/PtcViewElements';
import { DetailWrap, Duration, DetailTitle, Pcoin } from '../PtcDetail/PtcDetailElements';

const SurfDetail = ({ ads }) => {
    const adsColor = ads.duration === 2.5 ? 'red' : ads.duration === 2 ? 'yellow' : ads.duration === 1.5 ? 'green' : 'blue';
    const adsDuration = ads.duration === 2.5 ? '60' : ads.duration === 2 ? '45' : ads.duration === 1.5 ? '30' : ads.duration === 1 ? '15' : null;
    return (
        <>
            <PtcContainer color={adsColor} visitable={1}>
                <PtcWrap color={adsColor}>
                    <DetailWrap>
                        <Duration color={adsColor}>
                            <span>
                                {adsDuration} sec
                            </span>
                        </Duration>
                        <DetailTitle color={adsColor}>
                            <h1>{ads.url}</h1>
                        </DetailTitle>
                        <Pcoin color={adsColor}>
                            <span>{ads.pcoin} pcoin</span>
                        </Pcoin>
                    </DetailWrap>
                </PtcWrap>
            </PtcContainer>
        </>
    )
}
export default SurfDetail;