import styled from 'styled-components';
import { Wrapper } from '../TOS/TOSElements';

export const BigContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, #010606 0% , rgba(29, 107, 187, 0.8) 30%, rgba(39, 117, 85, 0.8) 70%, #010606 105%);
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ContainerLogout = styled.div`
    width: 90%;
    max-width: 900px;
    display: flex;
    justify-content: center;
    padding: 25px;
`
export const WrapperLogout = styled(Wrapper)`
    background: linear-gradient(135deg, #010606 0% , rgba(29, 107, 187, 0.8) 30%, rgba(39, 117, 85, 0.8) 70%, #010606 105%);
    border: 1px solid rgba(29, 107, 187, 0.6);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.8);
`
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const ContentWrap = styled.div`
    display: flex;
    margin: 25px 0;
    padding: 0 35px;

    p {
        color: rgb(29, 107, 187);
        font-size: clamp(0.85rem, 2.8vw, 1.05rem);
        font-weight: 700;
        letter-spacing: 1.1px;
        word-spacing: 1.7px;
        margin-left: 15px;
    }
`
