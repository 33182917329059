import React, { useState, useEffect } from 'react';
import { SetUpContainer, SetUpBgWrap } from '../SetUpAds/SetUpAddsElements';
import { PromotionBodyWrap, PromotionHeadWrap, HeaderContent, PromotionTabContainer, Tab } from '../Promotions/PromotionElements';
import { HistoryTabWrap } from './HistoryElements';
import Authentication from '../../images/Auth_background.jpg';
import { historyData } from '../../data/historyData';
import DepositTab from './DepositTab';
import WithdrawTab from './WithdrawTab';
import ProfitTab from './ProfitTab';
import SpendTab from './SpendTab';
import LoginTab from './LoginTab';

const History = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [smallView, setSmallView] = useState(false);
    const viewSmallSize = () => {
        if (window.innerWidth < 768) {
            setSmallView(true);
        } else if (window.innerWidth >= 768) {
            setSmallView(false);
        };
    };
    const handleClick = (index) => {
        setTabIndex(index);
    };
    useEffect(() => {
        window.addEventListener('resize', viewSmallSize);
        viewSmallSize();
        return () => {
            window.removeEventListener('resize', viewSmallSize);
        };
    });
    return (
        <SetUpContainer>
            <SetUpBgWrap>
                <img src={Authentication} alt='History background' />
            </SetUpBgWrap>
            <PromotionBodyWrap>
                <PromotionHeadWrap>
                    <HeaderContent>
                        <p>history panel</p>
                    </HeaderContent>
                    <PromotionTabContainer>
                        <HistoryTabWrap>
                            {historyData.map((tab, index) => (
                                <Tab key={index} onClick={() => handleClick(index)} active={tabIndex === index ? 1 : 0}>
                                    <p>{tab}</p>
                                </Tab>
                            ))}
                        </HistoryTabWrap>
                    </PromotionTabContainer>
                </PromotionHeadWrap>
                {tabIndex === 0 && <DepositTab smallView={smallView} />}
                {tabIndex === 1 && <WithdrawTab smallView={smallView} />}
                {tabIndex === 2 && <ProfitTab />}
                {tabIndex === 3 && <SpendTab />}
                {tabIndex === 4 && <LoginTab smallView={smallView} />}
            </PromotionBodyWrap>
        </SetUpContainer>
    )
}
export default History;