import React, { useState } from 'react';
import { promotionData } from '../../data/promotionData';
import { SetUpContainer, SetUpBgWrap } from '../SetUpAds/SetUpAddsElements';
import { PromotionBodyWrap, PromotionHeadWrap, HeaderContent, PromotionTabContainer, PromotionTabWrap, Tab } from './PromotionElements';
import Authentication from '../../images/Auth_background.jpg';
import ReferralLinkTab from './ReferralLinkTab';
import BannerTab from './BannerTab';
import SocialTab from './SocialTab';

const Promotions = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleClick = (index) => {
        setTabIndex(index);
    };
    return (
        <SetUpContainer>
            <SetUpBgWrap>
                <img src={Authentication} alt='Authentication background' />
            </SetUpBgWrap>
            <PromotionBodyWrap>
                <PromotionHeadWrap>
                    <HeaderContent>
                        <p>Promotion Materials</p>
                    </HeaderContent>
                    <PromotionTabContainer>
                        <PromotionTabWrap>
                            {promotionData.map((tab, index) => (
                                <Tab key={index} onClick={() => handleClick(index)} active={tabIndex === index ? 1 : 0}>
                                    <p>{tab}</p>
                                </Tab>
                            ))}
                        </PromotionTabWrap>
                    </PromotionTabContainer>
                </PromotionHeadWrap>
                {tabIndex === 0 && <ReferralLinkTab />}
                {tabIndex === 1 && <BannerTab />}
                {tabIndex === 2 && <SocialTab />}
            </PromotionBodyWrap>
        </SetUpContainer>
    )
}
export default Promotions;