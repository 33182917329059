import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import device from '../../constants/breakPoints';

export const BodyWrap = styled.div`
    position: absolute;
    top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    max-width: 512px;

    @media screen and ${device.sm} {
        width: 90%;
    }

`
export const HeadWrap = styled.div`
    position: absolute;
    z-index: 3;
    top: -60px;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #277555, #1D6BBB);
    border-radius: 50px 50px 0 0;
    margin: auto;
`
export const LogoContainer = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    text-decoration: none;

    p{
        color: #01DBBB;
        font-size: clamp(0.9rem, 2.8vw, 1.15rem);
        font-weight: 700;
        letter-spacing: 1.3px;
        word-spacing: 2.5px;
        text-transform: uppercase;
        margin-left: 15px;
        text-shadow: 1px 2px 5px #000;

        span{
            font-weight: 400;
            text-transform: lowercase;
        }
    }
`
export const LogoWrap = styled.div`
    color: #fff;
    font-size: 1rem;
    margin-top: 5px;
    
    img {
        width: 50px;
        height: 54px;
    }
`
export const TabContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const TabWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns : 1fr 1fr;

    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
    }
`
export const AuthTabStyle = css`
    color: #fff;    
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;
    text-shadow: 1px 2px 5px #000;
    transition: all 0.3s ease-in-out;

    p{
        font-size: clamp(0.85rem, 2.8vw, 1rem);
    }

    &:hover {
        color: #01DBBB;
        transition: all 0.3s ease-in-out;
    }
`
export const LoginTab = styled.div`
    background: #277555;
    border-bottom: ${({ loginTab }) => (loginTab ? '3px solid #01DBBB' : 'none')};
    ${AuthTabStyle}
`
export const RegisterTab = styled.div`
    background: #1D6BBB;
    border-bottom: ${({ loginTab }) => (!loginTab ? '3px solid #01DBBB' : 'none')};
    ${AuthTabStyle}
`