import React from 'react';
import { useSelector } from 'react-redux';
import { SetUpContainer, SetUpBgWrap, AdsSetUpContainer, AdsContent } from '../SetUpAds/SetUpAddsElements';
import setupPtcBg from '../../images/Auth_background.jpg';
import SetUpAdsNavigate from './SetUpAdsNavigate';
import CreditCheck from '../SetUpPtc/CreditCheck';
import SetUpSponsorForm from './SetUpSponsorForm';
import SetUpSponsorFilter from './SetUpSponsorFilter';
import SetUpSponsorEnd from './SetUpSponsorEnd';
import SetUpPtcSuccess from '../SetUpPtc/SetUpPtcSuccess';

const SetUpSponsor = () => {
    const showCreditCheck = useSelector(state => state.creditCheck);
    const showSponsorSetUp = useSelector(state => state.sponsorSetUp);
    const showSponsorFilters = useSelector(state => state.sponsorFilters);
    const showSponsorEnd = useSelector(state => state.sponsorSetUpEnd);
    const showSponsorSuccess = useSelector(state => state.sponsorSuccess);
    return (
        <SetUpContainer>
            <SetUpBgWrap>
                <img src={setupPtcBg} alt={'background of set up PTC page'} />
            </SetUpBgWrap>
            <AdsSetUpContainer>
                <AdsContent>
                    {!showCreditCheck && !showSponsorSuccess && <SetUpAdsNavigate />}
                    {showCreditCheck && !showSponsorSetUp && !showSponsorFilters && !showSponsorEnd && !showSponsorSuccess
                        ? <CreditCheck />
                        : showSponsorSetUp && !showCreditCheck && !showSponsorFilters && !showSponsorEnd && !showSponsorSuccess
                            ? <SetUpSponsorForm />
                            : showSponsorFilters && !showCreditCheck && !showSponsorSetUp && !showSponsorEnd && !showSponsorSuccess
                                ? <SetUpSponsorFilter />
                                : showSponsorEnd && !showCreditCheck && !showSponsorSetUp && !showSponsorFilters && !showSponsorSuccess
                                    ? <SetUpSponsorEnd />
                                    : showSponsorSuccess && !showCreditCheck && !showSponsorSetUp && !showSponsorFilters && !showSponsorEnd
                                        ? <SetUpPtcSuccess />
                                        : null}
                </AdsContent>
            </AdsSetUpContainer>
        </SetUpContainer>
    )
};
export default SetUpSponsor;