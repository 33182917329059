import React, { useState } from 'react';
import { SponsorContainer, SponsorWrap, SponsorHeader, SponsorBody, RowWrap, RowTitleWrap, RowContentContainer, RowContentWrap, RowDescriptionWrap, SponsorLinkWrap, SponsorLink, SponsorIcon, SponsorArrow } from '../Sponsor/SponsorElements';

const SponsorWatch = () => {
    const [hover, setHover] = useState(false);
    const url = localStorage.getItem('url');
    const title = localStorage.getItem('title');
    const description = localStorage.getItem('description');
    return (
        <div style={{ background: '#f9f9f9', borderRadius: ' 15px 15px 0 0' }}>
            <SponsorContainer>
                <SponsorWrap>
                    <SponsorHeader>
                        <h3>sposnsor links</h3>
                    </SponsorHeader>
                    <SponsorBody>
                        <RowWrap>
                            <RowTitleWrap>
                                <h2>{title}</h2>
                            </RowTitleWrap>
                            <RowContentContainer>
                                <RowContentWrap>
                                    <RowDescriptionWrap>
                                        <p>{description}</p>
                                    </RowDescriptionWrap>
                                    <SponsorLinkWrap
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                    >
                                        <SponsorLink href={url} target='_blank'>
                                            {hover ? <SponsorIcon /> : <SponsorArrow />}
                                        </SponsorLink>
                                    </SponsorLinkWrap>
                                </RowContentWrap>
                            </RowContentContainer>
                        </RowWrap>
                    </SponsorBody>
                </SponsorWrap>
            </SponsorContainer>
        </div>
    )
}
export default SponsorWatch;