import styled from 'styled-components';

export const BigContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    :before {
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`
export const BannerWrap = styled.a`
    max-width: 94vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(29, 107, 187, 0.4);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    margin-bottom: 25px;
    transition: 0.3s ease-in-out;

    &:hover {
        transform: scale(1.03);
        transition: 0.3s ease-in-out;
    }
`
export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: ${({ state }) => (state === 1 ? '1fr 2fr' : state === 2 ? '2fr' : state === 3 ? '1fr' : null)};
    grid-column-gap: 7%;
`
export const BigContainerFaucet = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 7%;
    margin: 0 auto 15px auto;
`
export const BannerFaucetWrap = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(29, 107, 187, 0.4);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    transition: 0.3s ease-in-out;
    &:hover {
	transform: scale(1.03);
	transition: 0.3s ease-in-out;
    }
`
export const DBigContainerFaucet = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 5%;
    margin: 75px auto 15px auto;
    overflow: hidden;
`
export const DLeftContainerFaucet = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 468px;
`
export const DRightContainerFaucet = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
`
export const MobileDeactiveFaucetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 0 10px;
    overflow: hidden;
`
