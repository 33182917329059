import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { TitleWrap } from '../HelpDesk/FAQElements';
import { Instruction, ItemWrap, ItemName } from '../SetUpPtc/SetUpPtcFilterElements.js';
import { PtcFormInput, PtcTitleIcon } from '../SetUpPtc/SetUpPtcFormElements.js';
import { ValidateTicketForm } from './ValidateTicketForm';
import { ItemContainer, TicketContainer, FormWrap, HeaderContainer, NumberTicketWrap, StatusWrap, InputWrap, TextArea } from './TicketElements';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { EmailIcon } from '../Auth/SignInElements';
import { TicketCategories } from '../../data/helpData';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';

const Ticket = () => {
    const [active, setLangActive] = useState(false);
    const [info, setInfo] = useState();
    const [showHelp, setShowHelp] = useState(false);
    const [category, setCategory] = useState(Object.freeze('GE'));
    const [catIndex, setCatIndex] = useState(0);
    const [values, setValues] = useState(Object.freeze({
        subject: '',
        question: ''
    }));
    const [errors, setErrors] = useState({
        subject: '',
        question: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [apiError, setApiError] = useState('');
    const [complete, setComplete] = useState(false);
    const handleClick = () => {
        if (!active) {
            setLangActive(true);
            fetchInfoTicket();
        };
    };
    const fetchInfoTicket = async () => {
        const { data } = await axiosInstance.get('help/ticket/info/');
        setInfo(data.info)
    };
    const handleCategoryClick = (e, index) => {
        const categoryTarget = e.target.getAttribute('name');
        if (categoryTarget && categoryTarget !== category) {
            setCategory(categoryTarget);
            setCatIndex(index);
        };
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setIsSubmitting(false);
        setErrors({
            subject: '',
            question: ''
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateTicketForm(values));
        setIsSubmitting(true);
    };
    const fetchSendEmail = async () => {
	setIsSubmitting(false);
        try {
            await axiosInstance.post('help/ticket/send/', {
                category: category,
                subject: values.subject,
                question: values.question,
                number_answered_ticket: info.number_answered_ticket
            });
            setComplete(true);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting && !complete && !apiError) {
            fetchSendEmail();
        };
        return () => setIsSubmitting(false);
    });
    return (
        <TicketContainer>
            <TitleWrap>
                <p>Please solve your problem with the above tools.
                    If a solution to your problem or an answer to your question is not found,
                    please let us know. Also, if you find any bugs and inform us of their existence,
                    we will appreciate your spending time on them. </p>
            </TitleWrap>
            <ItemWrap style={{ margin: ' 25px auto' }}>
                <ItemName onClick={handleClick} itemActive={active ? 1 : 0}>
                    compose
                </ItemName>
            </ItemWrap>
            {active && info !== undefined &&
                <FormWrap action='#' onSubmit={handleSubmit}>
                    <HeaderContainer>
                        <NumberTicketWrap>
                            <h2>your answered tickets = {info.number_answered_ticket}</h2>
                        </NumberTicketWrap>
                        <StatusWrap red={info.busy ? 1 : 0}>
                            <h2>{info.busy ? 'busy' : 'free'}</h2>
                            <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                        </StatusWrap>
                    </HeaderContainer>
                    <HelpWrap show={showHelp ? 1 : 0}>
                        <p>
                            If you have already sent an email, you will be in busy status until you receive a reply to your email.
                            In other words, you can not send a new ticket when you are in a busy state.
                        </p>
                    </HelpWrap>
                    <InputWrap>
                        <EmailIcon style={{ transform: 'translate(50%, 88%)' }} />
                        <PtcFormInput
                            type='text'
                            name='email'
                            disabled
                            primary={1}
                            value={info.email}
                        />
                    </InputWrap>
                    <Instruction style={{ padding: '0 5px', margin: '15px' }}>
                        Which of the following is your ticket about?
                    </Instruction>
                    <ItemContainer>
                        {TicketCategories.map((item, index) => (
                            <ItemWrap style={{ marginBottom: '10px' }} key={index}>
                                <ItemName name={item.name} onClick={(e) => handleCategoryClick(e, index)} itemActive={index === catIndex ? 1 : 0}>
                                    {item.value}
                                </ItemName>
                            </ItemWrap>
                        ))}
                    </ItemContainer>
                    <InputWrap>
                        <PtcTitleIcon style={{ transform: 'translate(50%, 94%)' }} />
                        <PtcFormInput
                            type='text'
                            name='subject'
                            placeholder='Enter your subject.'
                            onChange={handleChange}
                            value={values.description}
                        />
                    </InputWrap>
                    {errors.subject &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errors.subject}
                            </MsgText>
                        </MsgWrap>
                    }
                    <InputWrap>
                        <TextArea
                            type='text'
                            name='question'
                            placeholder='Type your problem just in English, otherwise, we are unable to reply.'
                            onChange={handleChange}
                            value={values.description}
                        />
                    </InputWrap>
                    {errors.question &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {errors.question}
                            </MsgText>
                        </MsgWrap>
                    }
                    {!apiError && !complete && info !== undefined && !info.busy &&
                        <TinyFormButton type='submit' primary={1} big={0} style={{ width: '90%', maxWidth: '600px', margin: '10px auto 25px auto' }}>
                            send email
                        </TinyFormButton>
                    }
                    {complete &&
                        <MsgWrap primary={1}>
                            <MsgText primary={1}>
                                Your email was successfully sent. Check your email inbox to see your response.
                            </MsgText>
                        </MsgWrap>
                    }
                    {apiError &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {apiError}
                            </MsgText>
                        </MsgWrap>
                    }
                    {info !== undefined && info.busy &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                Your previous ticket has not been answered yet, please wait.
                            </MsgText>
                        </MsgWrap>
                    }
                </FormWrap>
            }
        </TicketContainer>
    )
}
export default Ticket;
