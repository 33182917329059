import styled from 'styled-components';
import { Link } from 'react-router-dom';
import device from '../../constants/breakPoints';

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background: rgba(29, 107, 187, 0.2);
    display: flex;
    justify-content: center;
    padding: 25px;
`
export const Wrapper = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(29, 107, 187, 0.3);
    border: 1px solid rgba(29, 107, 187, 0.6);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.8);
    border-radius: 10px;
    padding: 10px;
`
export const Header = styled.div`
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(29, 107, 187, 0.95);
    border-radius: 10px 10px 0 0;
    padding: 5px 10px;

    @media screen and ${device.sm} {
        justify-content: center;
    }
`
export const LeftWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const LogoWrapLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    img {
        width: 185px;
        height: 60px;

        @media screen and ${device.md} {
            width: 155px;
            height: 50px;
        }
        @media screen and ${device.sm} {
            display: none;
        }
    }
`
export const SubjectWrap = styled.div`
    h1 {
        color: rgba(29, 107, 187, 0.7);
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        font-size: clamp(1.1rem, 2.8vw, 2rem);
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
    }
`
export const Abstract = styled.div`
    margin: 25px 0;
    padding: 0 35px;
    @media screen and ${device.sm} {
        padding: 0 5px;
    }
    p {
        color: rgb(29, 107, 187);
        font-size: clamp(0.85rem, 2.8vw, 1.05rem);
        font-weight: 700;
        letter-spacing: 1.1px;
        word-spacing: 1.7px;
    }
`
export const DescWrap = styled.div`
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
export const Card = styled.div`
    width: 100%;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : ' rgba(39, 117, 85, 0.7)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1)' : ' rgba(39, 117, 85, 1)')};
    margin: 0 auto 50px auto;
`
export const Title = styled.div`
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
    padding: 5px 0;

    h2 {
        color: #fff;
        font-size: clamp(0.95rem, 2.8vw, 1.3rem);
        letter-spacing: 1.3px;
        word-spacing: 2.5px;
        text-transform: uppercase;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    }
`
export const Desc = styled.ul`
    width: 90%;
    margin: 15px 0;

    li {
        color: #fff;
        font-size: clamp(0.85rem, 2.8vw, 1rem);
        letter-spacing: 0.5px;
        word-spacing: 1px;
        line-height: 2;
    }
`