import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { FormContainer, FormWrap, Form, InputWrap, PasswordIcon, FormInput } from './SignInElements';
import { FormButton } from '../ButtonElements';
import { RPInstroduction } from './ResetPassElements';
import { TimerResetCode } from './ResetCodeElements';
import { ResetContent } from './ResetFormElements';
import { ValidateInfoResetForm } from './ValidateInfo';
import Countdown, { zeroPad } from 'react-countdown';
import { MsgText, MsgWrap } from '../MessageElements';

const ResetForm = ({ authDataLang }) => {
    const [values, setValues] = useState(Object.freeze({ password: '', password2: '' }));
    const [errors, setErrors] = useState({ password: '', password2: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [changed, setChanged] = useState(false);
    const [expired, setExpired] = useState(false);
    const timer = localStorage.getItem('resetPassTimer') ? parseInt(localStorage.getItem('resetPassTimer')) : null;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        setErrors({});
        setIsSubmitting(false);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateInfoResetForm(values));
        setIsSubmitting(true);
    };
    const renderer = ({ minutes, seconds }) => {
        return <TimerResetCode>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerResetCode>;
    };
    useEffect(() => {
        if (Date.now() > timer) {
            if (timer != null) {
                localStorage.removeItem('id');
                localStorage.removeItem('resetPassTimer');
            }
        };
    }, [timer]);
    const fetchChangePassword = async () => {
        try {
            await axiosInstance.post('change/reset/password/', {
                password: values.password,
                confirm_password: values.password2,
                id: localStorage.getItem('id')
            });
            localStorage.removeItem('id');
            localStorage.removeItem('resetPassTimer');
            setChanged(true);
        } catch (error) {
            setErrors({ password: error.response && error.response.data.detail ? error.response.data.detail : error.message });
            setIsSubmitting(false);
        };
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting && localStorage.getItem('id') && localStorage.getItem('resetPassTimer')) {
            fetchChangePassword();
        };
    });
    return (
        <FormContainer>
            <FormWrap>
                <Form action='#' onSubmit={handleSubmit}>
                    <ResetContent changed={changed ? 1 : 0} expired={expired ? 1 : 0}>
                        <h1>{authDataLang.resetFormHeader}</h1>
                        <RPInstroduction>{authDataLang.resetFormInstruction}</RPInstroduction>
                        <Countdown
                            date={timer}
                            renderer={renderer}
                            onComplete={() => {
                                if (localStorage.getItem('id') != null && timer != null) {
                                    localStorage.removeItem('id');
                                    localStorage.removeItem('resetPassTimer');
                                    setExpired(true);
                                    setErrors({});
                                }
                            }}
                        />
                        <InputWrap>
                            <PasswordIcon />
                            <FormInput
                                type='password'
                                name='password'
                                placeholder={authDataLang.passwordPlaceHolderReg}
                                value={values.password}
                                onChange={handleChange}
                            />
                        </InputWrap >
                        {errors.password && <p>{errors.password}</p>}
                        <InputWrap>
                            <PasswordIcon />
                            <FormInput
                                type='password'
                                name='password2'
                                placeholder={authDataLang.passwordPlaceHolderConfirm}
                                value={values.password2}
                                onChange={handleChange}
                            />
                        </InputWrap>
                        {errors.password2 && <p>{errors.password2}</p>}
                        <FormButton type='submit' primary={0}>{authDataLang.resetButton}</FormButton>
                    </ResetContent>
                    {changed &&
                        <MsgWrap primary={1}>
                            <MsgText primary={1}>
                                Your password was successfully changed, Now you could log in with your new password.
                            </MsgText>
                        </MsgWrap>
                    }
                    {expired &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                The code is expired. Please try again.
                            </MsgText>
                        </MsgWrap>
                    }
                </Form>
            </FormWrap>
        </FormContainer>
    )
}
export default ResetForm
