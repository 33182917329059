export const footerData = [
    {
        title: 'advertising tools',
        subLinks: [
            {
                title: 'Paid To Click (PTC)',
                link: '#'
            },
            {
                title: 'surfing',
                link: '#'
            },
            {
                title: 'video',
                link: '#'
            },
            {
                title: 'mining',
                link: '#'
            },
            {
                title: 'banner',
                link: '#'
            },
            {
                title: 'sponsor link',
                link: '#'
            }
        ]
    },
    {
        title: 'info',
        subLinks: [
            {
                title: 'about us',
                link: 'about'
            },
            {
                title: 'payments proof',
                link: 'payments/1'
            },
            {
                title: 'privacy policy',
                link: 'privacy'
            },
            {
                title: 'terms of service',
                link: 'TOS'
            },
        ]
    },
    {
        title: 'help',
        subLinks: [
            {
                title: 'help desk',
                link: 'help'
            },
        ]
    }
];

export const footerLangData = [
    {
        lang: 'es',
        info: [
            {
                title: 'herramientas publicitarias',
                subLinks: [
                    {
                        title: 'Pagada para hacer clic (PTC)',
                        link: '#'
                    },
                    {
                        title: 'surf',
                        link: '#'
                    },
                    {
                        title: 'video',
                        link: '#'
                    },
                    {
                        title: 'minería',
                        link: '#'
                    },
                    {
                        title: 'bandera',
                        link: '#'
                    },
                    {
                        title: 'enlace de patrocinador',
                        link: '#'
                    }
                ]
            },
            {
                title: 'información',
                subLinks: [
                    {
                        title: 'Sobre nosotras',
                        link: 'about'
                    },
                    {
                        title: 'comprobante de pago',
                        link: 'payments/1'
                    },
                    {
                        title: 'política de privacidad',
                        link: 'privacy'
                    },
                    {
                        title: 'términos de servicio',
                        link: 'TOS'
                    },
                ]
            },
            {
                title: 'ayuda',
                subLinks: [
                    {
                        title: 'mesa de ayuda',
                        link: 'help'
                    },
                ]
            }
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                title: 'рекламные инструменты',
                subLinks: [
                    {
                        title: 'Платно за клик (PTC)',
                        link: '#'
                    },
                    {
                        title: 'серфинг',
                        link: '#'
                    },
                    {
                        title: 'видео',
                        link: '#'
                    },
                    {
                        title: 'добыча',
                        link: '#'
                    },
                    {
                        title: 'баннер',
                        link: '#'
                    },
                    {
                        title: 'спонсорская ссылка',
                        link: '#'
                    }
                ]
            },
            {
                title: 'Информация',
                subLinks: [
                    {
                        title: 'о нас',
                        link: 'about'
                    },
                    {
                        title: 'подтверждение платежей',
                        link: 'payments/1'
                    },
                    {
                        title: 'политика конфиденциальности',
                        link: 'privacy'
                    },
                    {
                        title: 'Условия использования',
                        link: 'TOS'
                    },
                ]
            },
            {
                title: 'помощь',
                subLinks: [
                    {
                        title: 'служба поддержки',
                        link: 'help'
                    },
                ]
            }
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                title: 'ferramentas de publicidade',
                subLinks: [
                    {
                        title: 'Pago para clicar (PTC)',
                        link: '#'
                    },
                    {
                        title: 'surf',
                        link: '#'
                    },
                    {
                        title: 'vídeo',
                        link: '#'
                    },
                    {
                        title: 'mineração',
                        link: '#'
                    },
                    {
                        title: 'bandeira',
                        link: '#'
                    },
                    {
                        title: 'link do patrocinador',
                        link: '#'
                    }
                ]
            },
            {
                title: 'informação',
                subLinks: [
                    {
                        title: 'sobre nós',
                        link: 'about'
                    },
                    {
                        title: 'prova de pagamentos',
                        link: 'payments/1'
                    },
                    {
                        title: 'política de Privacidade',
                        link: 'privacy'
                    },
                    {
                        title: 'termos de serviço',
                        link: 'TOS'
                    },
                ]
            },
            {
                title: 'ajuda',
                subLinks: [
                    {
                        title: 'Central de Ajuda',
                        link: 'help'
                    },
                ]
            }
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                title: 'outils publicitaires',
                subLinks: [
                    {
                        title: 'Payé pour cliquer (PTC)',
                        link: '#'
                    },
                    {
                        title: 'surfant',
                        link: '#'
                    },
                    {
                        title: 'vidéo',
                        link: '#'
                    },
                    {
                        title: 'exploitation minière',
                        link: '#'
                    },
                    {
                        title: 'bannière',
                        link: '#'
                    },
                    {
                        title: 'lien parrain',
                        link: '#'
                    }
                ]
            },
            {
                title: 'info',
                subLinks: [
                    {
                        title: 'à propos de nous',
                        link: 'about'
                    },
                    {
                        title: 'preuve de paiement',
                        link: 'payments/1'
                    },
                    {
                        title: 'politique de confidentialité',
                        link: 'privacy'
                    },
                    {
                        title: "conditions d'utilisation",
                        link: 'TOS'
                    },
                ]
            },
            {
                title: 'aider',
                subLinks: [
                    {
                        title: "bureau d'aide",
                        link: 'help'
                    },
                ]
            }
        ]
    },
    {
        lang: 'de',
        info: [
            {
                title: 'Werbemittel',
                subLinks: [
                    {
                        title: 'Bezahlt, um zu klicken (PTC)',
                        link: '#'
                    },
                    {
                        title: 'Surfen',
                        link: '#'
                    },
                    {
                        title: 'Video',
                        link: '#'
                    },
                    {
                        title: 'Bergbau',
                        link: '#'
                    },
                    {
                        title: 'Banner',
                        link: '#'
                    },
                    {
                        title: 'Sponsor-Link',
                        link: '#'
                    }
                ]
            },
            {
                title: 'die Info',
                subLinks: [
                    {
                        title: 'über uns',
                        link: 'about'
                    },
                    {
                        title: 'Zahlungsnachweis',
                        link: 'payments/1'
                    },
                    {
                        title: 'Datenschutz-Bestimmungen',
                        link: 'privacy'
                    },
                    {
                        title: 'Nutzungsbedingungen',
                        link: 'TOS'
                    },
                ]
            },
            {
                title: 'Hilfe',
                subLinks: [
                    {
                        title: 'Hilfezentrum',
                        link: 'help'
                    },
                ]
            }
        ]
    },
    {
        lang: 'it',
        info: [
            {
                title: 'strumenti pubblicitari',
                subLinks: [
                    {
                        title: 'Pagato per cliccare (ptc)',
                        link: '#'
                    },
                    {
                        title: 'fare surf',
                        link: '#'
                    },
                    {
                        title: 'video',
                        link: '#'
                    },
                    {
                        title: 'estrazione',
                        link: '#'
                    },
                    {
                        title: 'bandiera',
                        link: '#'
                    },
                    {
                        title: 'link dello sponsor',
                        link: '#'
                    }
                ]
            },
            {
                title: 'informazioni',
                subLinks: [
                    {
                        title: 'chi siamo',
                        link: 'about'
                    },
                    {
                        title: 'prova di pagamento',
                        link: 'payments/1'
                    },
                    {
                        title: 'politica sulla riservatezza',
                        link: 'privacy'
                    },
                    {
                        title: 'Termini di servizio',
                        link: 'TOS'
                    },
                ]
            },
            {
                title: 'aiuto',
                subLinks: [
                    {
                        title: 'centro assistenza',
                        link: 'help'
                    },
                ]
            }
        ]
    }
];

export const footerRightsData = {
    text: 'All rights reserved'
};

export const footerRightsLangData = [
    {
        lang: 'es',
        info: {
            text: 'Reservados todos los derechos'
        }
    },
    {
        lang: 'ru',
        info: {
            text: 'Все права защищены'
        }
    },
    {
        lang: 'pt',
        info: {
            text: 'Todos os direitos reservados'
        }
    },
    {
        lang: 'fr',
        info: {
            text: 'Tous les droits sont réservés'
        }
    },
    {
        lang: 'de',
        info: {
            text: 'Alle Rechte vorbehalten'
        }
    },
    {
        lang: 'it',
        info: {
            text: 'Tutti i diritti riservati'
        }
    }
];