import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { useParams } from 'react-router-dom';
import ManageRef from '../components/ManageRef';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';

const ManageReferralsPage = () => {
    const params = useParams();
    const pageNumber = params.pageNumber ? parseInt(params.pageNumber) : 1;
    const [info, setInfo] = useState();
    const [isNext, setIsNext] = useState(false);
    const [error, setError] = useState('');
    const [smallVeiw, setSmallView] = useState(false);
    const [loading, setLoading] = useState(true);
    const viewSmallSize = () => {
        if (window.innerWidth < 511) {
            setSmallView(true);
        } else if (window.innerWidth >= 511) {
            setSmallView(false);
        };
    };
    useEffect(() => {
        window.addEventListener('resize', viewSmallSize);
        viewSmallSize();
        return () => {
            window.removeEventListener('resize', viewSmallSize);
        };
    });
    useEffect(() => {
        const fetchReferralsInfo = async () => {
            try {
                const { data } = await axiosInstance.get(`ref/manage/referrals/?page=${pageNumber}`);
                setInfo(data);
                setIsNext(data.is_next);
            } catch (error) {
                error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
            };
            setLoading(false);
        };
        fetchReferralsInfo();
    }, [pageNumber]);
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
	    {!loading && info !== undefined && !error &&
		<StackContainer primary={0} style={{ top: '0' }}>
		    <StackWrap primary={0}>
			<TitleWrap primary={0}>
			    <h2>guidance</h2>
			</TitleWrap>
			<StackDiscWrap>
			    <p>
			        Users who have not logged in within two months will be disabled based on the Icefaucet TOS.<br />
			        If your referrals are deactivated and reactivated by inviting someone else, you will lose them.<br />
			        You can also delete your deactivated referrals. The cost of this process is only 10 primecoins.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer>
	    }
            {!loading &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {info !== undefined && !error && <ManageRef info={info} pageNumber={pageNumber} isNext={isNext} smallView={smallVeiw} />}
                    {error &&
                        <MsgWrap primary={0} style={{ width: '80%' }}>
                            <MsgText primary={0}>
                                {error}
                            </MsgText>
                        </MsgWrap>
                    }
                </div>
            }
        </>
    )
}
export default ManageReferralsPage;
