import React from 'react';
import success from '../../images/success-form.svg';
import { SFContainer, SFWrapper, SFTextWrap, SFImageWrap } from './SuccessElements';

const SuccessForm = ({ authDataLang }) => {

    return (
        <SFContainer>
            <SFWrapper>
                <SFTextWrap>
                    <h3>
                        {authDataLang.activateSuccess}
                    </h3>
                </SFTextWrap>
                <SFImageWrap>
                    <img src={success} alt='Confirm email address' />
                </SFImageWrap>
            </SFWrapper>

        </SFContainer>
    )
}

export default SuccessForm
