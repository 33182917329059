import React from 'react';
import { StackContainer, StackWrap, StackDiscWrap } from './StackElements';
import { StackTable, StackHeadWrap, StackHeadRow, StackHeader, StackBodyWrap, StackBodyRow, StackCell } from './StackElements';
import { stackTableHead, stackTableRow } from '../../data/stackData';
const Stack = () => {
    return (
        <>
            <StackContainer primary={1}>
                <StackWrap primary={1}>
                    <StackDiscWrap>
                        <p>
                            Hold your icecoins in the refrigerator to get its profit. You can claim your profit whenever you like. Renting more refrigerators makes your profit bigger.
	                    The annual profit is completely related to your level account based on the bottom table. Holding more icecoins makes your account level higher.<br />
                            If the cost of your ads is more than 25 USDT, based on your level, you get more credits as an upcode.
                        </p>
                    </StackDiscWrap>
                    <StackTable>
                        <StackHeadWrap>
                            <StackHeadRow>
                                {stackTableHead.map((header, index) => (
                                    <StackHeader key={index}>
                                        {header}
                                    </StackHeader>
                                ))}
                            </StackHeadRow>
                        </StackHeadWrap>
                        <StackBodyWrap>
                            {stackTableRow.map((row, index) => (
                                <StackBodyRow key={index}>
                                    <StackCell>
                                        {row.level}
                                    </StackCell>
                                    <StackCell>
                                        {row.profit}
                                    </StackCell>
                                    <StackCell>
                                        {row.upcode}
                                    </StackCell>
                                    <StackCell>
                                        {row.requirement}
                                    </StackCell>
                                </StackBodyRow>
                            ))}
                        </StackBodyWrap>
                    </StackTable>
                </StackWrap>
            </StackContainer>
        </>
    )
}
export default Stack;
