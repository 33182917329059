import React from 'react';
import { SidebarContainer, SidebarWrapper } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../PtcView/PtcViewElements';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../TaskView/TaskPageElements';
import { DescWrap } from '../History/DepositTabElements';
import { contestMoreTableHead } from '../../data/contestData';

const MoreContestInfoModal = ({ moreClick, setMoreClick, row }) => {
    return (
	<SidebarContainer isOpen={moreClick ? 1 : 0}>
	    <SidebarWrapper>
	        <CloseIconWrap onClick={() => setMoreClick(false)}>
	            <CloseIcon />
	        </CloseIconWrap>
	        <PtcHeaderWrap>
	            <PtcInfoWrap>
	                <InfoContainer>
	    		    <InfoWrap>
	        		<TitleInfoWrap>
	            		    <h2>reward</h2>
	        		</TitleInfoWrap>
	        		<DescWrap>
	            		    <h2>{row.reward} PC</h2>
	        		</DescWrap>
	    		    </InfoWrap>
	    		    <InfoWrap>
	        	        <TitleInfoWrap>
	            	            <h2>winners</h2>
	        	        </TitleInfoWrap>
	       		        <DescWrap>
	            		    <h2>{row.winners} </h2>
	        		</DescWrap>
	    		    </InfoWrap>
	    		</InfoContainer>
	            </PtcInfoWrap>
	        </PtcHeaderWrap>
	        <Table>
	    	    <HeadWrap>
	                <HeadRow>
	                    {contestMoreTableHead.map((header, index) => (
				<Header key={index}>
				    {header}
				</Header>
			    ))}
	                </HeadRow>
	            </HeadWrap>
	    	    <BodyWrap>
	                {row.winners_list.map((winner, index) => (
			    <BodyRow key={index}>
				<Cell>
				    {index + 1}
				</Cell>
				<Cell>
				    {winner.username}
				</Cell>
				<Cell>
				    {winner.reward} PC
				</Cell>
				<Cell>
				    {winner.number_done}
				</Cell>
			    </BodyRow>
			))}
	            </BodyWrap>
	    	</Table>
	    </SidebarWrapper>
	</SidebarContainer>
    )
}
export default MoreContestInfoModal;
