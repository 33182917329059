import React from 'react';
import { Wrapper, CardWrapper, Card, TitleWrap, ContentWrap } from './SmallCardInfoElements';

const SmallCardInfo = ({ allInfo }) => {
    return (
        <Wrapper>
            <CardWrapper>
                <Card>
                    <TitleWrap>
                        <h4>active users</h4>
                    </TitleWrap>
                    <ContentWrap>
                        <h4>{allInfo['active users']}</h4>
                    </ContentWrap>
                </Card>
                <Card>
                    <TitleWrap>
                        <h4>all paid</h4>
                    </TitleWrap>
                    <ContentWrap>
                        <h4>{allInfo['all paid']} USDT</h4>
                    </ContentWrap>
                </Card>
                <Card>
                    <TitleWrap>
                        <h4>all refrigerators</h4>
                    </TitleWrap>
                    <ContentWrap>
                        <h4>{allInfo['all refrigrators']}</h4>
                    </ContentWrap>
                </Card>
            </CardWrapper>
        </Wrapper>
    )
}
export default SmallCardInfo;
