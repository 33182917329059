import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { ProfitContainer, ProfitWrap, ProfitHeader, ProfitMainWrap, ProfitValueWrap, ValuesWrap, SpanWrap, StopIcon, PlayIcon } from './ProfitBoxElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { Circle } from '../Loading';
import { TinyFormButton } from '../ButtonElements';

const ProfitBox = () => {
    const [profit, setProfit] = useState();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [autoRun, setAutoRun] = useState(localStorage.getItem('autoProfit') ? false : true);
    const [claimed, setClaimed] = useState(false);
    const [apiError, setApiError] = useState('');
    const handleClaimProfit = () => {
	fetchCliamProfit();
    };
    const fetchCliamProfit = async () => {
	setLoading2(true);
	try {
	    await axiosInstance.get('interest/claim/');
	    setClaimed(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading2(false);
    };
    const handleAutoRun = (status) => {
	if (status === 'stop') {
	    localStorage.setItem('autoProfit', 'stop');
	    setAutoRun(false);
	} else {
	    localStorage.removeItem('autoProfit');
	    setAutoRun(true);
	};
    };
    const fetchProfitValue = async () => {
	try {
	    const { data } = await axiosInstance.get('interest/profit/');
	    setProfit(data);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    useEffect(() => {
	if (autoRun  && profit !== undefined && profit.profit > 0) {
	    const timer = setTimeout(() => {
		fetchProfitValue();
	    }, 15000);
	    return () => clearTimeout(timer);
	};
    });
    useEffect(() => {
	fetchProfitValue();
    }, []);
    return (
	<ProfitContainer>
	    <ProfitWrap>
	        <ProfitHeader>
	            <h2>Profit Box</h2>
	        </ProfitHeader>
	        {apiError &&
		    <MsgWrap primary={0} style={{ width: '80%' }}>
			<MsgText primary={0}>
			    {apiError}
			</MsgText>
		    </MsgWrap>
		}
	        {loading &&
		    <Circle style={{ margin: '35px auto', width: '15px', height: '15px' }} />
		}
	    	{!loading && !apiError && profit !== undefined && !claimed &&
		    <ProfitMainWrap>
			<ProfitValueWrap>
			    {autoRun ? <PlayIcon onClick={() => handleAutoRun('stop')} /> : <StopIcon onClick={() => handleAutoRun('play')} />}
			    <div style={{ marginBottom: '5px' }}>
				<h1>{profit.profit} {profit.profit > 1 ? 'icecoins' : 'icecoin'}</h1>
				<ValuesWrap>
				    <SpanWrap>
				        <span>{profit.level === 6 ? 'master' : `level ${profit.level}`}</span>
				    </SpanWrap>
				    <SpanWrap>
				        <span>+{profit.annual_profit}%</span>
				    </SpanWrap>
				</ValuesWrap>
			    </div>
			</ProfitValueWrap>
			<TinyFormButton big={0} style={{ width: '100%', height: '25px', margin: 'auto' }} onClick={handleClaimProfit}>
			    {loading2 ? <Circle style={{ margin: '0 auto', width: '15px', height: '15px' }} /> : 'claim'}
			</TinyFormButton>
		    </ProfitMainWrap>
		}
	    	{claimed &&
		    <MsgWrap primary={1} style={{ width: '80%' }}>
			<MsgText primary={1}>
			    You got your profit successfully. You can claim your profit again in the next 24 hours!
			</MsgText>
		    </MsgWrap>
		}
	    </ProfitWrap>
	</ProfitContainer>
    )
}
export default ProfitBox;
