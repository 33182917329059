import {
    SHOW_LOGIN,
    NOTSHOW_LOGIN,
    SHOW_REG,
    NOTSHOW_REG,
    ACTIVATE_CODE,
    NOT_ACTIVATE_CODE,
    CONFRIM_REG,
    NOT_CONFRIM_REG,
    SHOW_RESET,
    NOTSHOW_RESET,
    SHOW_CODE,
    NOTSHOW_CODE,
    SHOW_FORM,
    NOTSHOW_FORM
} from "../constants/authRedux";

export const loginTabAction = ({ prop }) => {
    if (prop) {
        return (dispatch) => {
            dispatch({
                type: SHOW_LOGIN
            })
        }
    } else {
        return (dispatch) => {
            dispatch({
                type: NOTSHOW_LOGIN
            })
        };
    };
};

export const regTabAction = ({ prop }) => {
    if (prop) {
        return (dispatch) => {
            dispatch({
                type: SHOW_REG
            })
        }
    } else {
        return (dispatch) => {
            dispatch({
                type: NOTSHOW_REG
            })
        };
    };
};

export const activateCodeAction = ({ prop }) => {
    if (prop) {
        return (dispatch) => {
            dispatch({
                type: ACTIVATE_CODE
            })
        }
    } else {
        return (dispatch) => {
            dispatch({
                type: NOT_ACTIVATE_CODE
            })
        };
    };
};

export const confirmRegAction = ({ prop }) => {
    if (prop) {
        return (dispatch) => {
            dispatch({
                type: CONFRIM_REG
            })
        }
    } else {
        return (dispatch) => {
            dispatch({
                type: NOT_CONFRIM_REG
            })
        };
    };
};

export const resetPasswordAction = ({ prop }) => {
    return (dispatch) => {
        if (prop === true) {
            dispatch({
                type: SHOW_RESET
            })
        } else {
            dispatch({
                type: NOTSHOW_RESET
            })
        };
    };
};

export const resetCodeAction = ({ prop }) => {
    return (dispatch) => {
        if (prop === true) {
            dispatch({
                type: SHOW_CODE
            })
        } else {
            dispatch({
                type: NOTSHOW_CODE
            })
        };
    };
};

export const resetPasswordFormAction = ({ prop }) => {
    return (dispatch) => {
        if (prop === true) {
            dispatch({
                type: SHOW_FORM
            })
        } else {
            dispatch({
                type: NOTSHOW_FORM
            })
        };
    };
};