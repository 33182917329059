import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../Stack/StackElements';
import { Form, Container, ImgContainer, ImgWrap, PersonalityImg, InfoContainer, InputWrap, Input, EmailIcon, UsernameIcon, TableWrap, Table, BodyWrap, BodyRow, Cell } from './PersonalityElements';
import { TinyFormButton } from '../ButtonElements';
import male from '../../images/male_avatar.svg';
import female from '../../images/female_avatar.svg';
import { MsgWrap, MsgText } from '../MessageElements';
import { useHistory } from 'react-router-dom';

const PersonalityInfo = ({ info, values, setValues, updateComplete }) => {
    const history = useHistory();
    const [completeDelete, setCompleteDelete] = useState(false);
    const [deleteError, setDeleteError] = useState('');
    const [imgSource, setImgSource] = useState('');
    const [fileSelected, setFileSelected] = useState(null);
    const [fileError, setFileError] = useState('');
    const [uniqueError, setUniqueError] = useState('');
    const fileFormats = ['image/jpeg', 'image/png', 'image/gif'];
    const hiddenFileInput = React.useRef(null);
    const handleBlurUsername = () => {
        values.username.length > 0 && fetchUniqueUsername();
    };
    const fetchUniqueUsername = async () => {
        try {
            await axiosInstance.post('settings/unique-username/', {
                username: values.username
            });
        } catch (error) {
            error.response && error.response.data.detail ? setUniqueError(error.response.data.detail) : setUniqueError(error.message);
        };
    };
    const fetchDeletePhoto = async () => {
        try {
            await axiosInstance.get('settings/delete-photo/');
            setTimeout(() => history.go(0), 1000);
            setCompleteDelete(true);
        } catch (error) {
            error.response && error.response.data.detail ? setDeleteError(error.response.data.detail) : setDeleteError(error.message);
        };
    };
    const deletePhotoClick = () => {
        !completeDelete && deleteError.length === 0 && fetchDeletePhoto();
    };
    const handleDimensionCheck = (e) => {
        var img = document.createElement("img");
        img.onload = function () {
            if (this.width > 800 || this.height > 800) {
                setFileError('The maximum size of images is 800 x 800');
            } else {
                setFileSelected(e.target.files[0]);
            };
        };
        var reader = new FileReader();
        reader.onloadend = function (ended) {
            img.src = ended.target.result;
            setImgSource(img.src);
        }
        reader.readAsDataURL(e.target.files[0]);
    };
    const handleFileChange = (e) => {
        fileError && setFileError('');
        imgSource && setImgSource('');
        if (e.target.files.length > 0) {
            const uplaodFileType = e.target.files[0].type;
            if (uplaodFileType && fileFormats.includes(uplaodFileType)) {
                const fileSize = e.target.files[0].size / 1024;
                if (fileSize <= 2048) {
                    handleDimensionCheck(e);
                } else {
                    setFileError('This file is too large to uplaod. The maximum supported size is 2048 kb.');
                }
            } else {
                setFileError('Not supported the extension of the file, please uplaod jpej family, png and gif formats.')
            };
        };
    };
    const fetchUploadPhoto = async () => {
        try {
            let fd = new FormData();
            fd.append('photo', fileSelected, fileSelected.name);
            await axiosInstance.put('settings/upload-photo/', fd);
            setTimeout(() => history.go(0), 1500);
        } catch (error) {
            error.response && error.response.data.detail ? setFileError(error.response.data.detail) : setFileError(error.message);
        };
    };
    const handleChange = (e) => {
        let { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        setUniqueError('');
    };
    useEffect(() => {
        updateComplete && fileSelected && fetchUploadPhoto();
    });
    return (
        <StackContainer primary={1}>
            <StackWrap primary={1}>
                <TitleWrap primary={1}>
                    <h2>
                        personality information
                    </h2>
                </TitleWrap>
                <StackDiscWrap>
                    <Form action='#'>
                        <Container>
                            <ImgContainer>
                                <Input
                                    type='file'
                                    name='file'
                                    accept='image/jpeg, image/png, image/gif'
                                    onChange={handleFileChange}
                                    ref={hiddenFileInput}
                                    style={{ display: 'none' }}
                                />
                                {(imgSource.length === 0 || fileError.length > 0) &&
                                    <ImgWrap>
                                        <PersonalityImg src={info.photo !== undefined ? info.photo : info.gender === 'F' ? female : male} alt={'Profile Image'} />
                                    </ImgWrap>
                                }
                                {fileError.length === 0 && imgSource.length !== 0 &&
                                    <ImgWrap>
                                        <PersonalityImg
                                            src={imgSource}
                                            alt={'banner'}
                                        />
                                    </ImgWrap>
                                }
                                {info.photo === undefined &&
                                    <TinyFormButton type='button' primary={1} big={0} style={{ width: '160px' }} onClick={() => { hiddenFileInput.current.click() }}>
                                        uplaod your image
                                    </TinyFormButton>
                                }
                                {info.photo !== undefined &&
                                    <TinyFormButton type='button' primary={1} big={0} style={{ width: '160px', background: 'rgba(255, 0, 0, 0.6)' }} onClick={deletePhotoClick}>
                                        delete your image
                                    </TinyFormButton>
                                }
                            </ImgContainer>
                            <InfoContainer>
                                <InputWrap>
                                    <EmailIcon />
                                    <Input
                                        type='text'
                                        name='email'
                                        disabled
                                        primary={1}
                                        value={info.email}
                                    >
                                    </Input>
                                </InputWrap>
                                <InputWrap>
                                    <UsernameIcon />
                                    <Input
                                        type='text'
                                        name='username'
                                        primary={1}
                                        disabled={info.username.length > 0 ? true : false}
                                        value={values.username}
                                        onChange={handleChange}
                                        placeholder='Enter your username'
                                        onBlur={handleBlurUsername}
                                    >
                                    </Input>
                                </InputWrap>
                                <TableWrap>
                                    <Table>
                                        <BodyWrap>
                                            {info.language !== undefined &&
                                                <BodyRow>
                                                    <Cell>
                                                        language
                                                    </Cell>
                                                    <Cell>
                                                        {info.language}
                                                    </Cell>
                                                </BodyRow>
                                            }
                                            {info.country !== undefined &&
                                                <BodyRow>
                                                    <Cell>
                                                        country
                                                    </Cell>
                                                    <Cell>
                                                        {info.country}
                                                    </Cell>
                                                </BodyRow>
                                            }
                                        </BodyWrap>
                                    </Table>
                                </TableWrap>
                            </InfoContainer>
                        </Container>
                    </Form>
                </StackDiscWrap>
                {uniqueError &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {uniqueError}
                        </MsgText>
                    </MsgWrap>
                }
                {fileError &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {fileError}
                        </MsgText>
                    </MsgWrap>
                }
                {deleteError &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {deleteError}
                        </MsgText>
                    </MsgWrap>
                }
                {completeDelete &&
                    <MsgWrap primary={1}>
                        <MsgText primary={1}>
                            Your image is removed successfully.
                        </MsgText>
                    </MsgWrap>
                }
            </StackWrap>
        </StackContainer>
    )
}
export default PersonalityInfo;