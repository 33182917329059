import React, { useState } from 'react';
import UseForm from './UseForm';
import { ValidateEmail, ValidateInfo } from './ValidateInfo';
import { FormContainer, FormWrap, Form, InputWrap } from './SignInElements';
import { RegFormInput, RegEmailIcon, RegPasswordIcon, RegPasswordConfirmIcon, RadioWrap, RadioLabel, RadioInput, RadioMark, CheckWrap, CheckLabel, CheckInput, CheckMark, TosLink, PrivacyLink } from './SignUpElements';
import { FormButton } from '../ButtonElements';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { Circle } from '../Loading';

const SignUp = ({ authDataLang }) => {
    const { values, handleChange, handleUnique, handleSubmit, errors, isChecked, isSubmitting, handleCheck, loading } = UseForm(ValidateEmail, ValidateInfo);
    const [showHelp, setShowHelp] = useState(false);
    return (
        <>
            <FormContainer>
                <FormWrap>
                    <Form action='#' onSubmit={handleSubmit}>
                        <h1>{authDataLang.regHeader}</h1>
                        <InputWrap>
                            <RegEmailIcon />
                            <RegFormInput
                                type='email'
                                name='email'
                                placeholder={authDataLang.emailPlaceHolder}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleUnique}
                            />
                        </InputWrap>
                        {errors.email && <p>{errors.email}</p>}
                        <RadioWrap onChange={handleChange}>
                            <h2>{authDataLang.genderText}</h2>
                            <RadioLabel>
                                <RadioInput type='radio' name='gender' value='M' />
                                <RadioMark />
                                {authDataLang.male}
                            </RadioLabel>
                            <RadioLabel>
                                <RadioInput type='radio' name='gender' value='F' />
                                <RadioMark />
                                {authDataLang.female}
                            </RadioLabel>
                        </RadioWrap>
                        {errors.gender && <p>{errors.gender}</p>}
                        <InputWrap>
                            <RegPasswordIcon />
                            <RegFormInput type='password' name='password' placeholder={authDataLang.passwordPlaceHolderReg} value={values.password} onChange={handleChange} />
                            <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                        </InputWrap>
                        <HelpWrap show={showHelp ? 1 : 0}>
                            <p style={{ color: '#01DBBB' }}>
                                {authDataLang.signupPasswordHelp}
                            </p>
                        </HelpWrap>
                        {errors.password && <p>{errors.password}</p>}
                        <InputWrap>
                            <RegPasswordConfirmIcon />
                            <RegFormInput type='password' name='password2' placeholder={authDataLang.passwordPlaceHolderConfirm} value={values.password2} onChange={handleChange} />
                        </InputWrap>
                        {errors.password2 && <p>{errors.password2}</p>}
                        <CheckWrap>
                            <CheckLabel>
                                <CheckInput type="checkbox" checked={isChecked} onChange={handleCheck}>
                                </CheckInput>
                                <CheckMark />
                                {authDataLang.agree} <TosLink to='TOS'>{authDataLang.tosLink}, </TosLink> <PrivacyLink to='privacy'>{authDataLang.ppLink}</PrivacyLink>
                            </CheckLabel>
                        </CheckWrap>
                        {!isChecked && isSubmitting && <p>You should read and apply terms of service and privacy policy.</p>}
	    		{!loading ?
			    <FormButton type='submit' primary={1}>{authDataLang.signupButton}</FormButton>
			    : <Circle style={{ margin: '20px auto 0 auto', width: '30px', height: '30px' }} />
			}
                    </Form>
                </FormWrap>
            </FormContainer>
        </>
    )
}

export default SignUp;
