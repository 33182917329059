import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const InfoContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 580px;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
`
export const InfoWrap = styled.div`
    display: flex;
    justify-content: center;
`
export const InfoRow = styled.div`
    display: grid;
    grid-gap: 10vw;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    padding: 0 25px;
    
    @media screen and ${device.md} {
        grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`
export const Column1 = styled.div`
    grid-area: col1;
`
export const Column2 = styled.div`
    grid-area: col2;
`
export const TextWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and ${device.md} {
        text-align: center;
    }
`
export const Text = styled.div`
    max-width: 500px;

    h3 {
        color: #01DBBB;
        font-size: clamp(1.1rem, 3.5vw, 2rem);
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 4px;
        text-transform: uppercase;
        margin-bottom: 16px;
    }
`
export const Title = styled.h2`
    font-size: clamp(1.5rem, 3.5vw, 3.5rem);
    margin-bottom: 28px;
    line-height: 1.1;
    font-weight: 700;
    color: ${({ blueText }) => (blueText ? '#1D6BBB' : '#277555')};
`
export const Subtitle = styled.p`
    font-size: clamp(0.9rem, 3.5vw, 1.3rem);
    margin-bottom: 45px;
    line-height: 1.3;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`
export const BtnWrap = styled.div`
    max-width: 300px;
    width: 75%;
`
export const ImgWrap = styled.div`
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    margin: auto;
    img {
        width: 100%;

        @media screen and ${device.md} {
            max-width: 400px;
        }
    }
`
