import React from 'react';
import { SidebarContainer, SidebarWrapper } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon, MoreDetailWrapItem, MoreDetailCard } from './MoreDetailElements';
import { AIContainer, AIImageWrap, AIImage, AIContext, AIContextItem } from '../AccountInfo/AccountInfoElements';
import male from '../../images/male_avatar.svg';
import female from '../../images/female_avatar.svg';
import moment from 'moment';

const MoreDetail = ({ isClicked, setIsClicked, gender, profileImg, lastLogin, level, icecoin, pcoin, adscoin, refNumber, refViewAdsClick, refFaucetClick, refAdsDeposit, username, dateJoined }) => {
    return (
        <SidebarContainer isOpen={isClicked ? 1 : 0}>
            <SidebarWrapper>
                <CloseIconWrap onClick={() => setIsClicked(false)}>
                    <CloseIcon />
                </CloseIconWrap>
                <AIContainer>
                    <MoreDetailWrapItem>
                        <MoreDetailCard>
                            <AIImageWrap>
                                <AIImage src={profileImg ? profileImg : gender === 'F' ? female : male} alt={'Profile referrals Image'} />
                            </AIImageWrap>
                            <AIContext>
                                {username !== undefined &&
                                    <AIContextItem>
                                        <span>username:</span>
                                        <span>{username}</span>
                                    </AIContextItem>
                                }
                                {dateJoined !== undefined &&
                                    <AIContextItem>
                                        <span>registarion date:</span>
                                        <span>{dateJoined}</span>
                                    </AIContextItem>
                                }
                                <AIContextItem>
                                    <span>last login time:</span>
                                    <span>{lastLogin ? moment(lastLogin).fromNow() : 'not login yet'}</span>
                                </AIContextItem>
                                <AIContextItem>
                                    <span>level:</span>
                                    <span>{level}</span>
                                </AIContextItem>
                                <AIContextItem>
                                    <span>icecoin:</span>
                                    <span>{icecoin}</span>
                                </AIContextItem>
                                <AIContextItem>
                                    <span>primecoin:</span>
                                    <span>{pcoin}</span>
                                </AIContextItem>
                                <AIContextItem>
                                    <span>adscoin:</span>
                                    <span>{adscoin}</span>
                                </AIContextItem>
                                <AIContextItem>
                                    <span>referrals:</span>
                                    <span>{refNumber}</span>
                                </AIContextItem>
                                <AIContextItem>
                                    <span>view ads click:</span>
                                    <span>{refViewAdsClick}</span>
                                </AIContextItem>
                                <AIContextItem>
                                    <span>faucet click:</span>
                                    <span>{refFaucetClick}</span>
                                </AIContextItem>
                                <AIContextItem>
                                    <span>ads purchase:</span>
                                    <span>{refAdsDeposit}</span>
                                </AIContextItem>
                            </AIContext>
                        </MoreDetailCard>
                    </MoreDetailWrapItem>
                </AIContainer>
            </SidebarWrapper>
        </SidebarContainer >
    )
}

export default MoreDetail;
