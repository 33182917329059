import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { CardWrap, ImgContainer, ImgBackground, ImgWrap, TitleWrap, EndTimeWrap, AbstractWrap, BottomWrap, LevelWrap, ButtonWrap, Timer } from './CardElements';
import { Circle } from '../Loading';
import Countdown, { zeroPad } from 'react-countdown';
import { MsgWrap, MsgText } from '../MessageElements';

const Card = ({ card, index, setIsIcecard }) => {
    const [loading, setLoading] = useState(false);
    const [fetchMining, setFetchMining] = useState(false);
    const [miningDone, setMiningDone] = useState(false);
    const [apiError, setApiError] = useState('');
    const handleCardClick = () => {
	setFetchMining(true);
	setLoading(true);
	fetchCradMiningDone();
    };
    const fetchCradMiningDone = async () => {
	try {
	    const { data } = await axiosInstance.post('mining/card/done/', {
		'id': Object.freeze(card.id)
	    });
	    setIsIcecard(data.icecard);
	    data.icecard > 0 && window.scroll(0, 130);
	    setMiningDone(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    const rendererBig = ({ days, hours, minutes, seconds }) => {
	return <Timer>{zeroPad(days)} days + {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    const renderer = ({ hours, minutes, seconds }) => {
	return <Timer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    return (
	<CardWrap lock={card.lock && ((card.lock_in_level <= card.level) || (miningDone && card.lock_in_level <= card.level + 1)) ? 1 : 0} primary={index % 2 === 0 ? 1 : 0} border={card.promo > 0 ? 1 : 0}>
	    <ImgContainer>
		<ImgBackground>
		    <img src={card.image} alt={'card pic'} />
		</ImgBackground>
		<ImgWrap primary={index % 2 === 0 ? 1 : 0}>
		     <img src={card.image} alt={'card pic'} />
		</ImgWrap>
	    </ImgContainer>
	    {apiError &&
		<MsgWrap primary={0} style={{ width: '90%', margin: '0 auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!apiError &&
		<>
		    {card.end_time_hour > 0 &&
			<EndTimeWrap>
			    <Countdown date={Date.now() + card.end_time_second * 1000} renderer={card.end_time_second > 24 * 3600 ? rendererBig : renderer} />
			</EndTimeWrap>
		    }
		    <TitleWrap>
			<h3>{card.title}</h3>
		    </TitleWrap>
		    <AbstractWrap primary={index % 2 === 0 ? 1 : 0}>
			<p>{card.abstract}</p>
		    </AbstractWrap>
		    <BottomWrap>
			<LevelWrap primary={index % 2 === 0 ? 1 : 0}>
			    <span>lvl {miningDone && card.level + 1 < card.max_level ? card.level + 1 : miningDone && card.level + 1 >= card.max_level ? card.max_level : card.level}</span>
			</LevelWrap>
			{card.claimable && (!card.lock || (!miningDone && card.lock && card.lock_in_level > card.level) || (miningDone && card.lock && card.lock_in_level > card.level + 1)) &&
			    <ButtonWrap primary={index % 2 === 0 ? 1 : 0} onClick={!fetchMining ? () => handleCardClick() : null}>
				{loading ? <Circle style={{ margin: 'auto', width: '15px', height: '15px' }} /> : miningDone && card.level + 1 < card.max_level ? <Countdown date={Date.now() + card.base_time * 60 * ((card.level + 1) / 2 + 1) * 1000} renderer={card.base_time * 60 * ((card.level + 1) / 2 + 1) > 24 * 3600 ? rendererBig : renderer} /> : miningDone && card.level + 1 >= card.max_level ? <span style={{ fontSize: '0.7rem' }}>master-card! you are in max level.</span> : <span>{card.icecoin} icecoins</span>}
				{card.promo > 0 && !miningDone && <span style={{ fontSize: '0.7rem' }}>(+{card.promo}%)</span>}
			    </ButtonWrap>
			}
			{card.claimable && card.lock && ((card.lock_in_level <= card.level) || (miningDone && card.lock_in_level <= card.level + 1)) &&
			    <ButtonWrap primary={index % 2 === 0 ? 1 : 0}>
				{card.referral_lock > 0 ? <span style={{ fontSize: '0.7rem' }}>You need {card.referral_lock} {card.referral_lock > 1 ? 'referrals' : 'referral'}</span> : <span style={{ fontSize: '0.7rem' }}>upgrade {card.card_lock} lvl {card.card_level_lock}</span>}
			    </ButtonWrap>
			}
			{!card.claimable && card.timer &&
			    <ButtonWrap primary={index % 2 === 0 ? 1 : 0}>
				<Countdown date={Date.now() + card.timer * 1000} renderer={card.timer > 24 * 3600 ? rendererBig : renderer} />
			    </ButtonWrap>
			}
		        {!card.claimable && !card.timer && !card.icecoin && card.level >= card.max &&
			    <ButtonWrap primary={index % 2 === 0 ? 1 : 0}>
				<span style={{ fontSize: '0.7rem' }}>master-card! you are in max level.</span>
			    </ButtonWrap>
			}
		    </BottomWrap>
		</>
	    }
	</CardWrap>
    )
}
export default Card;

