import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { PtcButtonwrap } from '../PtcDetail/ButtonTimerElements';
import { TinyButtonSurf } from '../ButtonElements';
import { MsgText, MsgWrap } from '../MessageElements';
import { MsgContainer, MsgItem } from '../PtcDetail/PtcDetailElements';
import { TinyButton } from '../ButtonElements';
import completeVoice from '../../voices/complete.mp3';
import errorVoice from '../../voices/error.mp3';
import { Circle } from '../Loading';

const ButtonTimer = ({ ads }) => {
  let history = useHistory();
  const [buttonTimer, setButtonTimer] = useState(7);
  const [dataPlay, setDataPlay] = useState();
  const [duration, setDuration] = useState();
  const [playAds, setPlayAds] = useState();
  const [isStart, setIsStart] = useState(false);
  const [error, setError] = useState('');
  const [apiError, setApiError] = useState('');
  const [timeError, setTimeError] = useState('');
  const [complete, setComplete] = useState('');
  const [windowActive, setWindowActive] = useState(false);
  const [stop, setStop] = useState(true);
  const [removeWatchers, setRemoveWatchers] = useState(false);
  const [loading, setLoading] = useState(false);
  const adsColor = ads.duration === 2.5 ? 'red' : ads.duration === 2 ? 'yellow' : ads.duration === 1.5 ? 'green' : 'blue';
  const endTime = localStorage.getItem(`clickTimer:${ads.code}`);
  const handleRightClick = (e) => {
    e.preventDefault();
  };
  const handleActivity = (forcedFlag) => {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setWindowActive(true) : setWindowActive(false);
    };
    return document.hidden ? setWindowActive(false) : setWindowActive(true);
  };
  const fetchSurfPlay = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`ptc/surf/${ads.code}/${ads.uid_enc}/${ads.token_enc}/`);
      setDataPlay(data);
      const play_ads = window.open(ads.org_url, '_blank');
      setPlayAds(play_ads);
      setDuration(ads.duration === 2.5 ? 60 : ads.duration === 2.0 ? 45 : ads.duration === 1.5 ? 30 : 15);
      setIsStart(true);
      setStop(false);
    } catch (error) {
      error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
    };
    setLoading(false);
  };
  const fetchSurfDone = async () => {
    setStop(true);
    if (endTime && parseInt(endTime, 10) - Date.now() > 0) {
      try {
        await axiosInstance.post('/ptc/done/surf/', {
          'id': dataPlay.id
        });
        setTimeout(() => history.push(`/surf/${localStorage.getItem('surf_page_number')}`), 1000);
        setComplete(`You earned ${ads.pcoin} prime-coin successfully.`);
	if (ads.device === 'M') {
	    var AudioPlay = new Audio(completeVoice);
	    AudioPlay.play();
	};
      } catch (error) {
        error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	if (ads.device === 'M') {
	    var Audio2Play = new Audio(errorVoice);
	    Audio2Play.play();
	};
      };
    localStorage.removeItem(`clickTimer:${ads.code}`);
    localStorage.removeItem(`clickRule:${dataPlay.user_id}`);
    localStorage.removeItem('clickCode');
    };
  };
  const watcherHandler = async () => {
    await axiosInstance.post('ptc/remove-watchers/', {
      code: ads.code
    });
    setRemoveWatchers(true);
  };
  useEffect(() => {
    if (buttonTimer === 0) {
      return;
    };
    const timer = setTimeout(() => {
      setButtonTimer(buttonTimer - 1);
    }, 1000);
    return () => clearTimeout(timer);
  });
  useEffect(() => {
    if (!stop && duration === 0 && complete.length === 0 && apiError.length === 0 && error.length === 0) {
      !removeWatchers && watcherHandler();
      fetchSurfDone();
      return;
    };
    if (!stop) {
      const adsTimer = setTimeout(() => {
        setDuration(duration - 1);
      }, windowActive ? 1500 : 900);
      return () => clearTimeout(adsTimer);
    };
  });
  useEffect(() => {
    if (endTime && parseInt(endTime, 10) - Date.now() <= 0) {
      setTimeError('You should watch ads less than 3min!');
      if (ads.device === 'M') {
	var Audio2Play = new Audio(errorVoice);
	Audio2Play.play();
      };
      setStop(true);
    };
    if (!endTime && duration === undefined) {
      setTimeError('Invalid token!');
      setStop(true);
    }
  }, [endTime, duration, ads.device]);
  useEffect(() => {
    const handleActivityFalse = () => handleActivity(false);
    const handleActivityTrue = () => handleActivity(true);
    document.addEventListener('visibilitychange', handleActivity);
    document.addEventListener('blur', handleActivityFalse);
    window.addEventListener('blur', handleActivityFalse);
    window.addEventListener('focus', handleActivityTrue);
    document.addEventListener('focus', handleActivityTrue);
    return () => {
      document.removeEventListener('visibilitychange', handleActivity);
      document.removeEventListener('blur', handleActivityFalse);
      window.removeEventListener('blur', handleActivityFalse);
      window.removeEventListener('focus', handleActivityTrue);
      document.removeEventListener('focus', handleActivityTrue);
    };
  });
  useEffect(() => {
    if (playAds && playAds.closed && duration > 0) {
      setStop(true);
      setError('you closed the ads!');
      if (ads.device === 'M') {
	var Audio2Play = new Audio(errorVoice);
	Audio2Play.play();
      };
    };
  }, [playAds, duration, ads.device]);
  useEffect(() => {
    if (timeError && stop && !removeWatchers) {
      watcherHandler();
    };
  });
  useEffect(() => {
    if (error && stop && !removeWatchers) {
      watcherHandler();
    };
  });
  useEffect(() => {
    document.title = isStart && duration > 0
      ? `${duration} sec`
      : isStart && duration === 0 && error.length === 0
        ? 'successfully earned' :
        'iceFaucet';
    return () => document.title = 'iceFaucet';
  }, [duration, isStart, error]);
  return (
    <PtcButtonwrap>
      {
        complete &&
        <MsgWrap primary={1}>
          <MsgText primary={1}>
            {complete}
          </MsgText>
        </MsgWrap>
      }
      {
        error && !complete &&
        <MsgContainer>
          <MsgItem>
            <MsgWrap primary={0}>
              <MsgText primary={0}>
                {error}
              </MsgText>
            </MsgWrap>
            <TinyButton to={`/surf/${localStorage.getItem('surf_page_number')}`} primary={1} big={1}>
              return
            </TinyButton>
          </MsgItem>
        </MsgContainer>
      }
      {
        apiError &&
        <MsgContainer>
          <MsgItem>
            <MsgWrap primary={0}>
              <MsgText primary={0}>
                {apiError}
              </MsgText>
            </MsgWrap>
            <TinyButton to={`/surf/${localStorage.getItem('surf_page_number')}`} primary={1} big={1}>
              return
            </TinyButton>
          </MsgItem>
        </MsgContainer>
      }
      {
        timeError &&
        <MsgContainer>
          <MsgItem>
            <MsgWrap primary={0}>
              <MsgText primary={0}>
                {timeError}
              </MsgText>
            </MsgWrap>
            <TinyButton to={`/surf/${localStorage.getItem('surf_page_number')}`} primary={1} big={1}>
              return
            </TinyButton>
          </MsgItem>
        </MsgContainer>
      }
      {!isStart && !error && !timeError && !apiError
        ? <TinyButtonSurf
          type='button'
          onClick={buttonTimer === 0 && !loading ? fetchSurfPlay : null}
          onContextMenu={handleRightClick}
          color={adsColor}
          big={1}
          title={ads.main_url}
        >
	  {buttonTimer > 0 ? buttonTimer + ' sec' : loading ? <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} /> : 'visit'}
        </TinyButtonSurf>
        : isStart && !error && !timeError
          ? <TinyButtonSurf type='button'
            onContextMenu={handleRightClick}
            color={adsColor}
            big={1}
          >
            {duration} sec
          </TinyButtonSurf>
          : null
      }
    </PtcButtonwrap>
  )
}
export default ButtonTimer;
