export const historyData = [
    'Deposit',
    'Withdarw',
    'Profit',
    'Spend',
    'Login'
];
export const headDepositTable = [
    'processor',
    'value',
    'coin type',
    'deposit amount',
    'status',
    'date'
];
export const smallHeadDepositTable = [
    'processor',
    'value',
    'status',
    'date'
];
export const headWithdrawTable = [
    'method',
    'processor',
    'value',
    'icecoin',
    'address',
    'status',
    'date'
];
export const smallHeadWithdrawTable = [
    'processor',
    'value',
    'address',
    'status'
];
export const spendCoinTypeData = [
    {
        name: 'P',
        title: 'pcoin'
    },
    {
        name: 'A',
        title: 'adscoin'
    }
];
export const headSpendTable = [
    'value',
    'action',
    'date'
];
export const headProfitTable = [
    'icecoin',
    'date'
];
export const headLoginTable = [
    'IP',
    'device',
    'browser',
    'os',
    'date'
];
export const smallHeadLoginTable = [
    'IP',
    'device',
    'date'
];