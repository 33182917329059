import React from 'react';
import { Helmet } from "react-helmet";
import ScrollToTop from '../components/ScrollToTop';
import Auth from '../components/Auth';

const AuthPage = () => {
    return (
        <>
	    <Helmet>
	        <meta name="description" content="Join us to earn cryptocurrencies together." />
	        <title>iceFaucet | login or registration</title>
	    </Helmet>
            <ScrollToTop />
            <Auth />
        </>
    )
}
export default AuthPage;
