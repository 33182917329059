export const socialTypeData = [
    {
        name: 'text',
    },
    {
        name: 'image',
    }
];
export const socialTypeReferralData = [
    {
        name: 'advertiser',
        value: 'A',
    },
    {
        name: 'earner',
        value: 'E',
    }
];
export const socialImageRatioData = [
    {
        name: '1:1',
        value: 1,
    },
    {
        name: '9:16',
        value: 2,
    },
    {
        name: '16:9',
        value: 3,
    },
];
