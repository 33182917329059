import React from 'react';
import { useSelector } from 'react-redux';
import { NavPtcContainer, NavPtcItem } from './SetUpAdsNavigateElements';

const SetUpAdsNavigate = () => {
    const showBannerSetUp = useSelector(state => state.bannerSetUp);
    const showBannerFilters = useSelector(state => state.bannerFilters);
    const showBannerFilters2 = useSelector(state => state.bannerFilters2);
    return (
        <NavPtcContainer>
            <NavPtcItem active={showBannerSetUp ? 1 : 0}>
                <span>1</span>
            </NavPtcItem>
            <NavPtcItem active={showBannerFilters ? 1 : 0}>
                <span>2</span>
            </NavPtcItem>
            <NavPtcItem active={showBannerFilters2 ? 1 : 0}>
                <span>3</span>
            </NavPtcItem>
        </NavPtcContainer>
    )
}
export default SetUpAdsNavigate
