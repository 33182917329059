import React from 'react';
import submit from '../../images/submit_ads.svg';
import { SuccessContainer, ImgWrap } from './SetUpPtcSuccessElements';
import { MsgWrap, MsgText } from '../MessageElements';

const SetUpPtcSuccess = () => {
    return (
        <SuccessContainer>
            <ImgWrap>
                <img src={submit} alt='successfully submit' />
            </ImgWrap>
            <MsgWrap primary={1}>
                <MsgText primary={1}>
                    Congratulation! Your ads were successfully submitted on iceFaucet. <br />
                    To manage and control your ads go to the control ads page.
                </MsgText>
            </MsgWrap>
        </SuccessContainer>
    )
}
export default SetUpPtcSuccess