import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { SponsorContainer, SponsorWrap, SponsorHeader, SponsorBody, RowWrap, RowTitleWrap, RowContentContainer, RowContentWrap, RowDescriptionWrap, SponsorLinkWrap, SponsorLink, SponsorIcon, SponsorArrow } from './SponsorElements';

const Sponsor = ({ data }) => {
    const [hover, setHover] = useState(false);
    const [hoverIndex, setHoverIndex] = useState();
    const fetchBannerClicked = async (id) => {
        await axiosInstance.post('sponsor/clicked/', {
            'id': id
        });
    };
    const handleClicked = (id) => {
        fetchBannerClicked(id);
    };
    const handleRightClick = (e) => {
        e.preventDefault();
    };
    return (
        <SponsorContainer>
            <SponsorWrap>
                <SponsorHeader>
                    <h3>sposnsor links</h3>
                </SponsorHeader>
                <SponsorBody>
                    {data.map((sponsor, index) => (
                        <RowWrap key={index}>
                            <RowTitleWrap even={index % 2 === 0 ? 1 : 0}>
                                <h2>{sponsor.title}</h2>
                            </RowTitleWrap>
                            <RowContentContainer>
                                <RowContentWrap>
                                    <RowDescriptionWrap even={index % 2 === 0 ? 1 : 0}>
                                        <p>{sponsor.description}</p>
                                    </RowDescriptionWrap>
                                    <SponsorLinkWrap
                                        even={index % 2 === 0 ? 1 : 0}
                                        onMouseEnter={() => { setHover(true); setHoverIndex(index) }}
                                        onMouseLeave={() => { setHover(false); setHoverIndex() }}
                                    >
                                        <SponsorLink href={sponsor.url} target='_blank' title={sponsor.url.split('/')[2]} onClick={() => handleClicked(sponsor.sponsor_id)}
                                            onContextMenu={handleRightClick}>
                                            {hover && hoverIndex === index ? <SponsorIcon /> : <SponsorArrow />}
                                        </SponsorLink>
                                    </SponsorLinkWrap>
                                </RowContentWrap>
                            </RowContentContainer>
                        </RowWrap>
                    ))}
                </SponsorBody>
            </SponsorWrap>
        </SponsorContainer>
    )
}
export default Sponsor;