import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const FilterWrap = styled.div`
    display: grid;
    grid-column-gap: 35px;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0;

    @media screen and ${device.md} {
        grid-column-gap: 20px;
    }}
`
export const RepeatFilterWrap = styled.div`
    display: grid;
    grid-column-gap: 35px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 10px 0;

    @media screen and ${device.md} {
        grid-column-gap: 20px;
    }}
    @media screen and ${device.tn} {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 20px;
    }}
`
