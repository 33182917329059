import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { Link } from 'react-router-dom';
import { GiReturnArrow } from 'react-icons/gi';
import { ImNewTab } from 'react-icons/im';

export const PlayContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 10px auto;
`
export const Bar = styled.div`
    width: ${props => props.width};
    height: 5px;
    background: #01DBBB;
`
export const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    overflow-x: hidden;
`
export const HeaderContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 23fr 1fr;
    grid-column-gap: 30px;
    align-items: center;;
    background: linear-gradient(135deg, #010606 0% , #1D6BBB 30%, #277555 70%, #010606 105%);
    padding: 5px 15px;
`
export const MsgItem = styled.div`
    width: 100%;
    max-width: 512px;
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-column-gap: 15px;
    align-items: center;
    margin: auto;
    @media screen and ${device.md} {
	grid-template-columns: 1fr;
	grid-row-gap: 5px;
    }
`
export const TitleWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
	color: #01DBBB;
	font-size: clamp(0.9rem, 2.4vw, 1.3rem);
	text-transform: uppercase;
	letter-spacing: 1px;
	word-spacing: 1.5px;
    }
`
export const StufWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const AdLinkWrap = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const LinkWrap = styled(Link)`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const IconStyle = css`
    color: #fff;
    font-size: 1.5rem;
    transition: 0.3s ease-out;
    &:hover {
	color: #01DBBB;
	transition: 0.3s ease-out;
    }
`
export const OpenAds = styled(ImNewTab)`
    ${IconStyle};
`
export const Return = styled(GiReturnArrow)`
    ${IconStyle};
`
export const NumberWrap = styled.div`
    margin: 5px auto;
    span {
	color: #01DBBB;
	font-size: clamp(0.8rem, 2.4vw, 1rem);
	font-weight: 700;
	letter-spacing: 2px;
    }
`
