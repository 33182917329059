import React, { useState } from 'react';
import { SpeedoMeterAICard, HeadWrap, TimingGift, SpeedoMeterWrap, MaxSpan, ContentWrap } from './SpeedometerElements';
import { HelpIcon, HelpWrap } from '../HelpElements';
import ReactSpeedometer from "react-d3-speedometer";

const SMCards = ({ info, values, buttonTimer }) => {
    const [showHelp, setShowHelp] = useState(false);
    const handleClick = () => {
	setShowHelp(!showHelp);
    };
    return (
	<SpeedoMeterAICard>
	    <HeadWrap>
	        <TimingGift green={info.name === 'view ads turbo' ? 1 : 0}>
	            <h3>{info.time}</h3>
	        </TimingGift>
	        <HelpIcon style={{ marginTop: '4px' }} onClick={() => handleClick()} show={showHelp ? 1 : 0} />
	    </HeadWrap>
	    <HelpWrap style={{ marginBottom: '20px' }} show={showHelp ? 1 : 0}>
	        <p>
		    {info.helpmsg}
	        </p>
	    </HelpWrap>
	    {!showHelp &&
	        <>
		    <SpeedoMeterWrap>
		        <ReactSpeedometer
		    	    fluidWidth={true}
		    	    minValue={0}
		    	    maxValue={info.name === 'daily turbo' ? 100 : 200}
		    	    value={info.name === 'daily turbo' ? values.daily : info.name === 'view ads turbo' ? values.visits : values.ref_turbo}
		    	    customSegmentStops={[0, info.name === 'daily turbo' ? values.daily : info.name === 'view ads turbo' ? values.visits : values.ref_turbo, info.name === 'daily turbo' ? 100 : 200]}
		    	    segmentColors={["#01DBBB", info.name === 'view ads turbo' ? "rgba(39, 117, 85, 0.4)" : "rgba(29, 107, 187, 0.4)"]}
		    	    ringWidth={10}
		    	    needleColor='rgba(1, 219, 187, 0.75)'
		    	    textColor='#01DBBB'
		    	    needleHeightRatio={0.6}
		    	    valueTextFontSize='0'
		    	    labelFontSize='0'
		    	    needleTransition="easeElastic"
		    	    needleTransitionDuration={1000}
		        />
		        <MaxSpan green={info.name === 'view ads turbo' ? 1 : 0}>{info.max}</MaxSpan>
		    </SpeedoMeterWrap>
		    <ContentWrap>
		        <h3>
		            {info.name}:
		        </h3>
		        <span>
		    	    {info.name === 'daily turbo' && (values.daily !== 0 ? '+' + values.daily + '%' : values.daily)}
		            {info.name === 'view ads turbo' && (values.visits !== 0 ? '+' + values.visits + '%' : values.visits)}
		            {info.name === 'offer turbo' && (values.ref_turbo !== 0 ? '+' + values.ref_turbo + '%' : values.ref_turbo)}
		        </span>
		    </ContentWrap>
	        </>
	    }
        </SpeedoMeterAICard>
    )
}
export default SMCards;
