import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { CategoryWrap } from '../HelpDesk/FAQElements';

export const ItemContainer = styled.div`
display: grid;
grid-column-gap: 25px;
grid-row-gap: 15px;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

@media screen and ${device.md} {
    grid-template-columns: 1fr 1fr 1fr;
}
@media screen and ${device.sm} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
}
@media screen and ${device.tn} {
    grid-template-columns: 1fr;
}
`

export const TicketContainer = styled(CategoryWrap)`
    background: rgba(29, 107, 187, 0.3);
    border: 1px solid rgba(29, 107, 187, 0.4);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.8);
`
export const FormWrap = styled.form`
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    width: 100%;
    background: rgba(29, 107, 187, 0.3);
    border: 1px solid rgba(29, 107, 187, 0.4);
    box-shadow: 0 2px 5px rgba(29, 107, 187, 0.8);
    margin: auto;
`
export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and ${device.md} {
        flex-direction: column;
    }

    h2 {
        font-size: clamp(0.85rem, 1.8vw, 1.05rem);
        color: #01DBBB;
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 4px;
        text-transform: uppercase;
    }
`
export const NumberTicketWrap = styled.div`
    width: 50%;
    background: rgba(29, 107, 187, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    @media screen and ${device.md} {
        width: 100%;
    }
`
export const StatusWrap = styled.div`
    width: 50%;
    background: ${({ red }) => (red ? 'rgba(255, 0, 0, 0.4)' : 'rgba(39, 117, 85, 0.4)')};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    @media screen and ${device.md} {
        width: 100%;
    }
`
export const InputWrap = styled.div`
    display: flex;
    justify-content:space-between;
    width: 90%;
    max-width: 600px;
    @media screen and ${device.sm} {
        width: 100%;
    }
`
export const TextArea = styled.textarea`
    width: 100%;
    height: 120px;
    padding: 5px 10px 6px 40px;
    margin: 15px 0;
    border: none;
    font-size: 1.05rem;
    resize: none;

    ::placeholder {
        color: rgba(29, 107, 187, 0.7);
        font-size: 0.9rem;
    }
    &:focus {
        outline: 4px solid #1D6BBB;;
    }

    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(1, 219, 187, 0.6);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(1, 219, 187, 0.85);
    }
`