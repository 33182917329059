import React, { useState } from 'react';
import { ABCard, ABImageWrap, ABImage, ABContext, ABContextItem } from './AccountBalanceElements';
import { AdscoinPromoWrap, AdscoinPromoSpan } from '../PromoStyles';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { TinyButton } from '../ButtonElements';

const ABCards = ({ info, serverInfo }) => {
    const [showHelp, setShowHelp] = useState(false);
    const [showPromo, setShowPromo] = useState(false);
    const handleClick = () => {
	setShowHelp(!showHelp);
	setShowPromo(false);
    };
    const handlePromo = () => {
	setShowPromo(!showPromo);
	setShowHelp(false);
    };
    return (
	<ABCard>
	    {info.name === 'primecoin' && serverInfo.ppromo > 0 && !showHelp &&
		<AdscoinPromoWrap onClick={() => handlePromo()}>
		    <AdscoinPromoSpan>
			+{serverInfo.ppromo}%
		    </AdscoinPromoSpan>
		</AdscoinPromoWrap>
	    }
	    {info.name === 'adscoin' && serverInfo.promo > 0 && !showHelp &&
		<AdscoinPromoWrap onClick={() => handlePromo()}>
		    <AdscoinPromoSpan>
			+{serverInfo.promo}%
		    </AdscoinPromoSpan>
		</AdscoinPromoWrap>
	    }
	    {!showPromo && <HelpIcon style={{ left: '77px' }} onClick={() => handleClick()} show={showHelp ? 1 : 0} />}
	    <HelpWrap style={{ marginBottom: '20px' }} show={showHelp && !showPromo ? 1 : 0}>
	        <p>
	            {info.helpmsg}
	        </p>
	    </HelpWrap>
	    <HelpWrap style={{ marginBottom: '20px' }} show={!showHelp && showPromo && info.name === 'primecoin' ? 1 : 0}>
	        <p>
	            {serverInfo.desc_promo}
	        </p>
	    </HelpWrap>
	    <HelpWrap style={{ marginBottom: '20px' }} show={!showHelp && showPromo && info.name === 'adscoin' ? 1 : 0}>
	        <p>
	            {serverInfo.desc_ppromo}
	        </p>
	    </HelpWrap>
	    {!showHelp && !showPromo &&
		<>
		    <ABImageWrap>
			<ABImage src={info.img} alt={info.alt} />
		    </ABImageWrap>
		    <ABContext promo={(info.name === 'primecoin' && serverInfo.ppromo > 0) || (info.name === 'adscoin' && serverInfo.promo > 0) ? 1 : 0}>
			<ABContextItem>
			    <span>{info.name}:</span>
			    <span>{serverInfo[`${info.name}`]}</span>
			</ABContextItem>
		    </ABContext>
		    <TinyButton to={info.name === 'icecoin' ? '/withdraw' : info.name === 'primecoin' ? '/deposit/pcoin' : 'deposit/adscoin'} primary={1}>{info.name === 'icecoin' ? 'withdraw' : 'deposit'}</TinyButton>
		</>
	    }
	</ABCard >
    )
}
export default ABCards;
