import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { homeNavbar, adsNavbar, makeMoneyNavbar, statisticsNavbar, langHomeNavbar, langAdsNavbar, langMakeMoneyNavbar, langStatisticsNavbar } from '../../data/navbarData';
import { headerOpenAction } from '../../actions/headerAction';
import { langData } from '../../data/langData';
import { LanguageName } from '../Navbar/NavbarElements';
import { SidebarContainer, SidebarWrapper, SidebarNav, SidebarMenu, SidebarLink, IconsWrap, AccountIconLink, AccountIcon, LangIcon, LanguageWrap } from './SidebarElements'

const Sidebar = ({ code }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isOpen = useSelector(state => state.isOpen);
    const [langClick, setLangClick] = useState(false);
    const pageLang = localStorage.getItem('pageLang');
    const homeNavbarLang = pageLang ? langHomeNavbar.find(data => data.lang === pageLang).info : homeNavbar;
    const adsNavbarLang = pageLang ? langAdsNavbar.find(data => data.lang === pageLang).info : adsNavbar;
    const makeMoneyNavbarLang = pageLang ? langMakeMoneyNavbar.find(data => data.lang === pageLang).info : makeMoneyNavbar;
    const statisticsNavbarLang = pageLang ? langStatisticsNavbar.find(data => data.lang === pageLang).info : statisticsNavbar;
    const handleClick = (e) => {
        const value = e.target.getAttribute('value');
        if (value !== 'en') {
            localStorage.setItem('pageLang', value);
        } else {
            localStorage.removeItem('pageLang', value);
        };
        window.location.reload(false);
    };
    return (
        <SidebarContainer isOpen={isOpen ? 1 : 0}>
            <SidebarWrapper>
                <SidebarNav>
                    <SidebarMenu>
                        {(location.pathname === '/' || location.pathname === `/ref/${code}`) && homeNavbarLang.map((item, index) => (
                            <li key={index}>
                                <SidebarLink to={item.link} onClick={() => dispatch(headerOpenAction({ prop: 'toggle' }))}>
                                    {item.title}
                                </SidebarLink>
                            </li>
                        ))}
                        {(location.pathname === '/advertise' || location.pathname === `/ref/${code}/advertise`) && adsNavbarLang.map((item, index) => (
                            <li key={index}>
                                <SidebarLink key={index} to={item.link} onClick={() => dispatch(headerOpenAction({ prop: 'toggle' }))}>
                                    {item.title}
                                </SidebarLink>
                            </li>
                        ))}
                        {(location.pathname === '/make-money' || location.pathname === `/ref/${code}/make-money`) && makeMoneyNavbarLang.map((item, index) => (
                            <li key={index}>
                                <SidebarLink key={index} to={item.link} onClick={() => dispatch(headerOpenAction({ prop: 'toggle' }))}>
                                    {item.title}
                                </SidebarLink>
                            </li>
                        ))}
                        {(location.pathname === '/statistics' || location.pathname === `/ref/${code}/statistics`) && statisticsNavbarLang.map((item, index) => (
                            <li key={index}>
                                <SidebarLink key={index} to={item.link} onClick={() => dispatch(headerOpenAction({ prop: 'toggle' }))}>
                                    {item.title}
                                </SidebarLink>
                            </li>
                        ))}
                    </SidebarMenu>
                    <IconsWrap>
                        <AccountIconLink to='/auth' onClick={() => dispatch(headerOpenAction({ prop: false }))}>
                            <AccountIcon />
                        </AccountIconLink>
                        <LangIcon onClick={() => setLangClick(!langClick)} />
                    </IconsWrap>
                    {langClick &&
                        <LanguageWrap>
                            {langData.map((lang, index) => (
                                <LanguageName key={index} value={lang.value} onClick={handleClick}>
                                    {lang.name}
                                </LanguageName>
                            ))}
                        </LanguageWrap>
                    }
                </SidebarNav>
            </SidebarWrapper>
        </SidebarContainer >
    )
}
export default Sidebar;
