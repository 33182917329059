import styled from 'styled-components';

export const OfferCard = styled.div`
    background: rgba(29, 107, 187, 0.5);
    opacity: ${({ active }) => (active ? '1' : '0.45')};
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 2px 10px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    width: 210px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding-bottom: 3px;
`
export const TitleWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(39, 117, 85, 0.7);
    border-radius: 5px 5px 0 0;
    h4 {
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: 0.74rem;
	font-weight: 400;
	letter-spacing: 1px;
	word-spacing: 1.5px;
	text-transform: uppercase;
	padding: 5px 3px;
    }
`
export const LogoWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
`
export const Logo = styled.img`
    width: 170px;
    height: 35px;
    border-radius: 10px;
`
export const ContentWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    span {
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: clamp(0.7rem, 2.4vw, 0.8rem);
    }
`
export const ProgressBarWrap = styled.div`
    width: 84%;
    outline: 2px solid rgba(1, 219, 187, 0.5);
    box-shadow: 0 0 5px rgba(1, 219, 187, 0.8);
    border-radius: 0 0 30px 0;
`
export const ProgressBar = styled.div`
    width: ${({ width }) => (width)};
    height: 6px;
    background: rgba(1, 219, 187, 0.7);
    border-radius: 0 0 30px 0;
`
export const ButtonWrap = styled.div`
    display: grid;
    grid-template-columns : ${({ promo }) => (promo ? '1fr 1fr' : '1fr')};
    grid-column-gap: 20px;
    align-items: center;
`
