export const SHOW_CREDITCHECK = 'SHOW_CREDITCHECK';
export const NOTSHOW_CREDITCHECK = 'NOTSHOW_CREDITCHECK';
export const SHOW_PTCSETUP = 'SHOW_PTCSETUP';
export const NOTSHOW_PTCSETUP = 'NOTSHOW_PTCSETUP';
export const SHOW_PTCFILTERS = 'SHOW_PTCFILTERS';
export const NOTSHOW_PTCFILTERS = 'NOTSHOW_PTCFILTERS';
export const SHOW_PTCFILTERS2 = 'SHOW_PTCFILTERS2';
export const NOTSHOW_PTCFILTERS2 = 'NOTSHOW_PTCFILTERS2';
export const SHOW_PTCFILTERS3 = 'SHOW_PTCFILTERS3';
export const NOTSHOW_PTCFILTERS3 = 'NOTSHOW_PTCFILTERS3';
export const SHOW_PTCSETUPEND = 'SHOW_PTCSETUPEND';
export const NOTSHOW_PTCSETUPEND = 'NOTSHOW_PTCSETUPEND';
export const SHOW_PTCSUCCESS = 'SHOW_PTCSUCCESS';
export const NOTSHOW_PTCSUCCESS = 'NOTSHOW_PTCSUCCESS';