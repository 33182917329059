import styled from 'styled-components';

export const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    width: 80%;
    height: 90%;
    padding: 5%;
`
export const ImgWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 80%;
        height: 250px;
    }
`