import styled, { keyframes, css } from 'styled-components/macro';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { GoThreeBars } from 'react-icons/go';
import { TiTimes } from 'react-icons/ti';
import { VscAccount } from 'react-icons/vsc';
import { MdLanguage } from 'react-icons/md';
import device from '../../constants/breakPoints';

export const navStartAnimation = keyframes`
    0% { opacity:0; top:-80px; }
    100%{ opacity:1; top:0; }
`

export const NavContainer = styled.header`
    background: ${({ scrollnav }) => (scrollnav ? '#010606' : 'transparent')};
    width: 100%;
    height: ${({ scrollnav }) => (scrollnav ? '60px' : '80px')};
    font-size: 0.8rem;
    position: fixed;
    z-index: 99;
    top: -80px;
    @media (prefers-reduced-motion: no-preference) {
	animation: ${navStartAnimation} 0.8s ease-in-out 1.5s forwards;
    }
`
export const NavWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const Nav = styled.nav`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
`
export const NavLogo = styled(LinkR)`
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    font-size: 1.1rem;
    font-wight: bold;
    text-decoration: none;
    margin-left: -5px;
    height: 60px;
`
export const ImgLogo = styled.img`
    width: ${({ scrollnav }) => (scrollnav ? '155px' : '185px')};
    height: ${({ scrollnav }) => (scrollnav ? '50px' : '60px')};
`
export const HamIcon = styled.div`
    cursor: pointer;
    color: ${({ scrollnav }) => (scrollnav ? '#1D6BBB' : '#fff')};
    display: none;

    @media screen and ${device.lg} {
        display: flex;
    }
`
export const Bars = styled(GoThreeBars)`
    font-size: 1.7rem;
`
export const CloseIcon = styled(TiTimes)`
    font-size: 1.8rem;
`
export const NavMenu = styled.ul`
    display: flex;
    list-style: none;
    margin-right: ${({ scrollnav }) => (scrollnav ? '90px' : '120px')};
    align-items: center;

    @media screen and ${device.lg} {
        display: none;
    }
    
    li {
        height: 60px;
        margin-left: 5px;

        &:hover {
            background: ${({ scrollnav }) => (scrollnav ? 'rgba(29, 107, 187, 0.3)' : 'transparent')};
        }
        
    }
`
export const RightIcons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 65px;

    @media screen and ${device.lg} {
        display: none;
    }
`
export const NavIcons = css`
    color: ${({ scrollnav }) => (scrollnav ? '#277555' : '#fff')};
    transition: 0.3s ease-out;

    &:hover {
        color: #01DBBB;
        transition: 0.3s ease-out;
`
export const LangIcon = styled(MdLanguage)`
    font-size: 1.85rem;
    cursor: pointer;
    ${NavIcons}
`
export const LanguageWrap = styled.ul`
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    list-style: none;
    background: rgba(39, 117, 85, 0.75);
    width: 90px;
`
export const LanguageName = styled.li`
    color: #fff;
    text-shadow:  0 0 2px #000;
    font-size: 0.9rem;
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 7px;
    transition: 0.3s ease-out;

    &:hover {
        color: #01DBBB;
        background: rgba(39, 117, 85, 1);
        transition: 0.3s ease-out;
`
export const NavLinks = styled(LinkS)`
    color: ${({ scrollnav }) => (scrollnav ? '#1D6BBB' : '#fff')};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1.2rem;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    text-shadow:  0 0 2px #000;

    &.active {
        color: #01DBBB;
        border-bottom: 3px solid #01DBBB;
    }
    &:hover {
        color: #01DBBB;
        transition: 0.3s ease-out;
    }
`
export const AccountIconLink = styled(LinkR)`
    display: flex;
    align-items: center;
`
export const AccountIcon = styled(VscAccount)`
    font-size: 1.6rem;
    ${NavIcons}
`
