import axiosInstance from '../../axios';
import { BannerFaucetWrap } from '../DesktopBanner/DesktopBannerElements';
import Sponsor from '../Sponsor';

const FaucetActiveMobileBanners = ({ mobileBanner }) => {
    const handleBannerClicked = (id) => {
	fetchBannerClicked(id);
    };
    const fetchBannerClicked = async (id) => {
	await axiosInstance.post('banner/clicked/', {
	    'id': id
	});
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    return (
	<>
	    {mobileBanner.p1 !== null && mobileBanner.plan === 'A' &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto 0 auto' }}>
		    <BannerFaucetWrap href={mobileBanner.p1.url} target='_blank' onClick={() => handleBannerClicked(mobileBanner.p1.banner_id)} onContextMenu={handleRightClick}>
		        <img src={mobileBanner.p1.banner_img ? mobileBanner.p1.banner_img : mobileBanner.p1.banner_url ? mobileBanner.p1.banner_url : null} alt={mobileBanner.p1.title} title={mobileBanner.p1.url.split('/')[2]} />
		    </BannerFaucetWrap>
		</div>
	    }
	    {mobileBanner.p1 !== null && mobileBanner.plan === 'B' &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto 0 auto' }}>
		    <iframe title='cryptocoinsad'
		        src={mobileBanner.p1.src}
		        style={{ width: `${mobileBanner.p1.width}px`, height: `${mobileBanner.p1.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	    {mobileBanner.p2 !== null && (
		mobileBanner.is_sponsor ?
		    <div style={{ maxWidth: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto 0 auto' }}>
		    	<Sponsor data={mobileBanner.p2} />
		    </div> :
		    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto 0 auto' }}>
		        <iframe title='cryptocoinsad'
		            src={mobileBanner.p2.src}
		            style={{ width: `${mobileBanner.p2.width}px`, height: `${mobileBanner.p2.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		        </iframe>
		    </div>
	    )}
	    {mobileBanner.p3 !== null &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto 0 auto' }}>
		    <ins id='surf2'></ins>
		</div>
	    }
	    {mobileBanner.p4 !== null &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto 0 auto' }}>
		    <iframe title='bittrafficads'
		        src={mobileBanner.p4.src}
		        style={{ width: `${mobileBanner.p4.width}px`, height: `${mobileBanner.p4.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	    {mobileBanner.p5 !== null && mobileBanner.plan === 'A' &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <ins id='surf3'></ins>
		</div>
	    }
	    {mobileBanner.p5 !== null && mobileBanner.plan === 'B' &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <iframe title='cryptocoinsad'
		        src={mobileBanner.p5.src}
		        style={{ width: `${mobileBanner.p5.width}px`, height: `${mobileBanner.p5.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	</>
    )
}
export default FaucetActiveMobileBanners;
