export const lotteryTableHead = [
    'lottery',
    'rewards',
    'date',
    'more info'
];

export const lotteryMoreTableHead = [
    '#',
    'username(id)',
    'ticket number',
    'rewards'
];

export const participantsTableHead = [
    '#',
    'your ticket number',
    'date'
];
