export const authData = {
    signin: 'Sign in',
    signup: 'Sign up',
    signinHeader: 'Sign in to your account',
    regHeader: 'Sign up for a new account',
    emailPlaceHolder: 'Enter your email address',
    passwordPlaceHolder: 'Enter your password',
    passwordPlaceHolderReg: 'Enter your strong password',
    passwordPlaceHolderConfirm: 'Confirm your password',
    signinButton: 'Login',
    signupButton: 'Register',
    forgotPass: 'Forgot password?',
    genderText: 'Select your gender:',
    male: 'Male',
    female: 'Female',
    agree: 'I agree with',
    tosLink: 'terms of service',
    ppLink: 'privacy policy',
    activateCodeHeader: 'Enter your activation code.',
    activateInstruction: 'Please enter your activation code in the following box in less than 1 hour:',
    activateCodeButton: 'Submit your code',
    activateSuccess: 'Activating your account has been successfully done. You may log in to your account now.',
    resetHeader: 'Reset Your Password',
    resetInstruction: 'Enter your email address below to receive an email with a code.',
    resetButton: 'Submit',
    resetCodeHeader: 'Enter your code',
    resetCodeInstruction: 'Please enter your code in the following box in less than 15 minutes:',
    resetCodeButton: 'Submit your code',
    resetFormHeader: 'Enter your new password',
    resetFormInstruction: 'You could change your password here.',
    signupPasswordHelp: 'The password must consist of alphabetic characters, numeric characters, and at least one [@, #, $, *, _, -, .] character. Password example: 1234@Abc'
};

export const langAuthData = [
    {
        lang: 'es',
        info: {
            signin: 'Iniciar sesión',
            signup: 'Inscribirse',
            signinHeader: 'Iniciar sesión en su cuenta',
            regHeader: 'Regístrate para una nueva cuenta',
            emailPlaceHolder: 'Ingrese su dirección de correo electrónico',
            passwordPlaceHolder: 'Ingresa tu contraseña',
            passwordPlaceHolderReg: 'Ingrese su contraseña segura',
            passwordPlaceHolderConfirm: 'Confirmar la contraseña',
            signinButton: 'Acceso',
            signupButton: 'Registro',
            forgotPass: '¿Has olvidado tu contraseña?',
            genderText: 'Selecciona tu género:',
            male: 'Masculino',
            female: 'Femenina',
            agree: 'estoy de acuerdo con',
            tosLink: 'términos de servicio',
            ppLink: 'política de privacidad',
            activateCodeHeader: 'Ingrese su codigo de activación.',
            activateInstruction: 'Por favor, introduzca su código de activación en el siguiente cuadro en menos de 1 hora:',
            activateCodeButton: 'Envía tu código',
            activateSuccess: 'La activación de su cuenta se ha realizado con éxito. Puede iniciar sesión en su cuenta ahora.',
            resetHeader: 'Restablecer su contraseña',
            resetInstruction: 'Ingrese su dirección de correo electrónico a continuación para recibir un correo electrónico con un código.',
            resetButton: 'Envía',
            resetCodeHeader: 'Ingrese su código',
            resetCodeInstruction: 'Por favor, introduzca su código en la siguiente casilla en menos de 15 minutos:',
            resetCodeButton: 'Envía tu código',
            resetFormHeader: 'Introduzca su nueva contraseña',
            resetFormInstruction: 'Puede cambiar su contraseña aquí.',
            signupPasswordHelp: 'La contraseña debe constar de caracteres alfabéticos, caracteres numéricos y al menos un carácter [@, #, $, *, _, -, .]. Ejemplo de contraseña: 1234@Abc'
        }
    },
    {
        lang: 'ru',
        info: {
            signin: 'Авторизоваться',
            signup: 'Зарегистрироваться',
            signinHeader: 'Войдите в свой аккаунт',
            regHeader: 'Зарегистрируйте новую учетную запись',
            emailPlaceHolder: 'Введите ваш адрес электронной почты',
            passwordPlaceHolder: 'Введите ваш пароль',
            passwordPlaceHolderReg: 'Введите надежный пароль',
            passwordPlaceHolderConfirm: 'Подтвердите ваш пароль',
            signinButton: 'Авторизоваться',
            signupButton: 'Зарегистрироваться',
            forgotPass: 'Забыли пароль?',
            genderText: 'Выбери свой пол:',
            male: 'Мужской',
            female: 'Женский',
            agree: 'я согласен с',
            tosLink: 'Условия использования',
            ppLink: 'политика конфиденциальности',
            activateCodeHeader: 'Введите Ваш код активации.',
            activateInstruction: 'Введите код активации в следующее поле менее чем за 1 час:',
            activateCodeButton: 'Отправьте свой код',
            activateSuccess: 'Активация вашей учетной записи успешно выполнена. Вы можете войти в свою учетную запись сейчас.',
            resetHeader: 'Сбросить пароль',
            resetInstruction: 'Введите свой адрес электронной почты ниже, чтобы получить электронное письмо с кодом.',
            resetButton: 'Отправьте',
            resetCodeHeader: 'Введите ваш код',
            resetCodeInstruction: 'Пожалуйста, введите свой код в следующее поле менее чем за 15 минут:',
            resetCodeButton: 'Отправьте свой код',
            resetFormHeader: 'Введите новый пароль',
            resetFormInstruction: 'Вы можете изменить свой пароль здесь.',
            signupPasswordHelp: 'Пароль должен состоять из буквенных символов, цифровых символов и хотя бы одного символа [@, #, $, *, _, -, .]. Пример пароля: 1234@Abc'
        }
    },
    {
        lang: 'pt',
        info: {
            signin: 'Conecte-se',
            signup: 'Inscrever-se',
            signinHeader: 'Faça login em sua conta',
            regHeader: 'Registe-se para uma nova conta',
            emailPlaceHolder: 'Digite seu e-mail',
            passwordPlaceHolder: 'Digite sua senha',
            passwordPlaceHolderReg: 'Digite sua senha forte',
            passwordPlaceHolderConfirm: 'Confirme sua senha',
            signinButton: 'Conecte-se',
            signupButton: 'Inscrever-se',
            forgotPass: 'Esqueceu sua senha?',
            genderText: 'Selecione o seu gênero:',
            male: 'Macho',
            female: 'Fêmea',
            agree: 'Eu concordo com',
            tosLink: 'termos de serviço',
            ppLink: 'política de Privacidade',
            activateCodeHeader: 'Digite seu código de ativação.',
            activateInstruction: 'Digite seu código de ativação na caixa a seguir em menos de 1 hora:',
            activateCodeButton: 'Envie seu código',
            activateSuccess: 'A ativação da sua conta foi realizada com sucesso. Você pode entrar em sua conta agora.',
            resetHeader: 'Redefina sua senha',
            resetInstruction: 'Digite seu endereço de e-mail abaixo para receber um e-mail com um código.',
            resetButton: 'Enviar',
            resetCodeHeader: 'Insira seu código',
            resetCodeInstruction: 'Digite seu código na caixa a seguir em menos de 15 minutos:',
            resetCodeButton: 'Envie seu código',
            resetFormHeader: 'Digite sua nova senha',
            resetFormInstruction: 'Você pode alterar sua senha aqui.',
            signupPasswordHelp: 'A senha deve consistir em caracteres alfabéticos, caracteres numéricos e pelo menos um caractere [@, #, $, *, _, -, .]. Exemplo de senha: 1234@Abc'
        }
    },
    {
        lang: 'fr',
        info: {
            signin: "S'identifier",
            signup: "S'inscrire",
            signinHeader: 'Connectez-vous à votre compte',
            regHeader: 'Inscrivez-vous pour un nouveau compte',
            emailPlaceHolder: 'Entrez votre adresse email',
            passwordPlaceHolder: 'Entrez votre mot de passe',
            passwordPlaceHolderReg: 'Entrez votre mot de passe fort',
            passwordPlaceHolderConfirm: 'Confirmer votre mot de passe',
            signinButton: "S'identifier",
            signupButton: "S'inscrire",
            forgotPass: 'Mot de passe oublié?',
            genderText: 'Sélectionnez votre genre:',
            male: 'Mâle',
            female: 'Femelle',
            agree: "je suis d'accord avec",
            tosLink: "conditions d'utilisation",
            ppLink: 'politique de confidentialité',
            activateCodeHeader: "Entrez votre code d'activation.",
            activateInstruction: "Veuillez entrer votre code d'activation dans la case suivante en moins d'une heure:",
            activateCodeButton: 'Soumettez votre code',
            activateSuccess: "L'activation de votre compte a été effectuée avec succès. Vous pouvez vous connecter à votre compte maintenant.",
            resetHeader: 'Réinitialisez votre mot de passe',
            resetInstruction: 'Entrez votre adresse e-mail ci-dessous pour recevoir un e-mail avec un code.',
            resetButton: 'Soumettez',
            resetCodeHeader: 'entrez votre code',
            resetCodeInstruction: 'Veuillez entrer votre code dans la case suivante en moins de 15 minutes :',
            resetCodeButton: 'Soumettez votre code',
            resetFormHeader: 'Entrez votre nouveau mot de passe',
            resetFormInstruction: 'Vous pouvez changer votre mot de passe ici.',
            signupPasswordHelp: "Le mot de passe doit être composé de caractères alphabétiques, de caractères numériques et d'au moins un caractère [@, #, $, *, _, -, .]. Exemple de mot de passe : 1234@Abc"
        }
    },
    {
        lang: 'de',
        info: {
            signin: 'Anmeldung',
            signup: 'Anmelden',
            signinHeader: 'Melden Sie sich bei Ihrem Konto an',
            regHeader: 'Registrieren sie sich für ein neues Konto',
            emailPlaceHolder: 'Geben sie ihre E-Mailadresse ein',
            passwordPlaceHolder: 'Geben Sie Ihr Passwort ein',
            passwordPlaceHolderReg: 'Geben Sie Ihr sicheres Passwort ein',
            passwordPlaceHolderConfirm: 'Bestätigen Sie Ihr Passwort',
            signinButton: 'Anmeldung',
            signupButton: 'Anmelden',
            forgotPass: 'Passwort vergessen?',
            genderText: 'Wähle dein Geschlecht:',
            male: 'Männlich',
            female: 'Weiblich',
            agree: 'Ich bin einverstanden mit',
            tosLink: 'Nutzungsbedingungen',
            ppLink: 'Datenschutz-Bestimmungen',
            activateCodeHeader: 'Geben Sie Ihren Aktivierungscode ein.',
            activateInstruction: 'Bitte geben Sie Ihren Aktivierungscode in weniger als 1 Stunde in das folgende Feld ein:',
            activateCodeButton: 'Einreichen Sie Ihren Code',
            activateSuccess: 'Die Aktivierung Ihres Kontos wurde erfolgreich durchgeführt. Sie können sich jetzt bei Ihrem Konto anmelden.',
            resetHeader: 'Setze dein Passwort zurück',
            resetInstruction: 'Geben Sie unten Ihre E-Mail-Adresse ein, um eine E-Mail mit einem Code zu erhalten.',
            resetButton: 'Einreichen',
            resetCodeHeader: 'Gib deinen Code ein',
            resetCodeInstruction: 'Bitte geben Sie Ihren Code in weniger als 15 Minuten in das folgende Feld ein:',
            resetCodeButton: 'Einreichen Sie Ihren Code',
            resetFormHeader: 'Gib dein neues Passwort ein',
            resetFormInstruction: 'Hier können Sie Ihr Passwort ändern.',
            signupPasswordHelp: 'Das Passwort muss aus Buchstaben, Ziffern und mindestens einem [@, #, $, *, _, -, .] Zeichen bestehen. Passwortbeispiel: 1234@Abc'
        }
    },
    {
        lang: 'it',
        info: {
            signin: 'Login',
            signup: 'Iscrizione',
            signinHeader: 'Accedi al tuo account',
            regHeader: 'Registrati per un nuovo account',
            emailPlaceHolder: 'Inserisci il tuo indirizzo email',
            passwordPlaceHolder: 'Inserisci la tua password',
            passwordPlaceHolderReg: 'Inserisci la tua password sicura',
            passwordPlaceHolderConfirm: 'Conferma la tua password',
            signinButton: 'Login',
            signupButton: 'Iscrizione',
            forgotPass: 'Hai dimenticato la password?',
            genderText: 'Seleziona il tuo sesso:',
            male: 'Maschio',
            female: 'Femmina',
            agree: "Sono d'accordo con",
            tosLink: 'Termini di servizio',
            ppLink: 'politica sulla riservatezza',
            activateCodeHeader: 'Inserisci il tuo codice di attivazione.',
            activateInstruction: 'Inserisci il tuo codice di attivazione nella seguente casella in meno di 1 ora:',
            activateCodeButton: 'Invia il tuo codice',
            activateSuccess: "L'attivazione del tuo account è stata completata con successo. Puoi accedere al tuo account ora.",
            resetHeader: 'Reimposta la tua password',
            resetInstruction: "Inserisci il tuo indirizzo email qui sotto per ricevere un'email con un codice.",
            resetButton: 'Invia',
            resetCodeHeader: 'Inserisci il tuo codice',
            resetCodeInstruction: 'Inserisci il tuo codice nella casella seguente in meno di 15 minuti:',
            resetCodeButton: 'Invia il tuo codice',
            resetFormHeader: 'Inserisci la tua nuova password',
            resetFormInstruction: 'Puoi cambiare la tua password qui.',
            signupPasswordHelp: 'La password deve essere composta da caratteri alfabetici, caratteri numerici e almeno un carattere [@, #, $, *, _, -, .]. Esempio di password: 1234@Abc'
        }
    }
];