export const helpData = {
    subject: 'help desk',
    abstract: 'Hi, How can we help you?',
    card: [
        {
            img: require('../images/help-blog.png').default,
            alt: 'blog tutorials',
            button: 'blog tutorials'
        },
        {
            img: require('../images/help-video.png').default,
            alt: 'video palylist',
            button: 'video palylist'
        },
        {
            img: require('../images/help-faq.png').default,
            alt: 'FAQs',
            button: 'faq'
        }
    ]

};
export const BlogCategories = [
    {
        value: 'earning',
        name: 'EA'
    },
    {
        value: 'advertising',
        name: 'AD'
    },
    {
        value: 'payment',
        name: 'PA'
    },
];
export const PlayListCategories = [
    {
        value: 'earning',
        name: 'EA'
    },
    {
        value: 'advertising',
        name: 'AD'
    },
    {
        value: 'payment',
        name: 'PA'
    },
];
export const FAQButtonsCategories = [
    {
        value: 'general',
        name: 'GE'
    },
    {
        value: 'earning',
        name: 'EA'
    },
    {
        value: 'affiliate',
        name: 'AF'
    },
    {
	value: 'stack',
	name: 'ST'
    },
    {
        value: 'advertising',
        name: 'AD'
    },
    {
        value: 'payment',
        name: 'PA'
    }
];
export const TicketCategories = [
    {
        value: 'general',
        name: 'GE'
    },
    {
        value: 'earning',
        name: 'EA'
    },
    {
        value: 'advertising',
        name: 'AD'
    },
    {
        value: 'payment',
        name: 'PA'
    },
    {
        value: 'bugs',
        name: 'BU'
    }
];
