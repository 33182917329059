import styled from 'styled-components';
import { AIContainer, AIWrapItem, AICard, AIImageWrap, AIImage, AIContext, AIContextItem } from '../AccountInfo/AccountInfoElements';

export const ABContainer = styled(AIContainer)`
    flex-direction: column;
    top: 12px;
    background: rgba(39, 117, 85, 0.2);
`
export const ABWrapItem = styled(AIWrapItem)`
    background: rgba(39, 117, 85, 0.2);
    border: 1px solid rgba(39, 117, 85, 0.3);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.7);
`
export const ABCard = styled(AICard)`
    background: rgba(39, 117, 85, 0.5);
    border: 1px solid rgba(39, 117, 85, 0.3);
    box-shadow: 0 2px 10px rgba(39, 117, 85, 0.7);
`
export const ABImageWrap = styled(AIImageWrap)`
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 0 10px rgba(29, 107, 187, 0.7);
`
export const ABImage = styled(AIImage)``
export const ABContext = styled(AIContext)`
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 2px 5px rgba(29, 107, 187, 0.7);
    margin: ${({ promo }) => (promo ? '10px' : '15px')};
`
export const ABContextItem = styled(AIContextItem)`
span {
    font-size: clamp(0.6rem, 1vw, 0.7rem);
}
`
