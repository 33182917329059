import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { TinyFormButton, TinyButton } from '../ButtonElements';
import { MsgText, MsgWrap } from '../MessageElements';
import { PlayContainer, Iframe, Bar, MessageContainer, MsgItem } from './PtcPlaylements';
import { Circle } from '../Loading';
import IcefaCaptcha from '../Captcha/IcefaCaptcha';

const PtcPlay = ({ data, removeTime }) => {
    let history = useHistory();
    const [width, setWidth] = useState(100);
    const [stop, setStop] = useState(false);
    const [showMsg, setShowMsg] = useState('');
    const [error, setError] = useState('');
    const [apiError, setApiError] = useState('');
    const [removeWatchers, setRemoveWatchers] = useState(false);
    const [loading, setLoading] = useState(false);
    const [iceCaptcha, setIceCaptcha] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const duration = data.duration === 2.5 ? 40 : data.duration === 2.0 ? 30 : data.duration === 1.5 ? 20 : 10;
    let timeStep = 10 * duration;
    const MousEnterHandler = () => {
        if (error.length === 0) {
            setStop(false);
        };
    };
    const completeTimer = () => {
        if (error.length === 0) {
            setShowMsg(`Press the button to earn ${data.pcoin} pc.`);
	    !removeWatchers && watcherHandler();
        };
    };
    const completeHandler = async () => {
	setLoading(true);
        try {
            await axiosInstance.post('ptc/done/', {
                id: data.id
            });
            setTimeout(() => history.push(`/ptc/${localStorage.getItem('ptc_page_number')}`), 1000);
            window.open(data.url, '_blank');
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
	removeTime();
	setTimeout(() => setLoading(false), 1000);
    };
    const watcherHandler = async () => {
	setRemoveWatchers(true);
        await axiosInstance.post('ptc/remove-watchers/', {
            code: data.code
        });
    };
    const deviceScreenhandler = () => {
        if (window.innerWidth < 1023 && data.device === 'D') {
            setStop(true);
            setError('The window width for desktop devices should be more than 1000px!');
        };
    };
    useEffect(() => {
	if (width === 0) {
	    if (data.need_captcha) {
		setIceCaptcha(true);
	        return;
	    } else {
		setCaptchaDone(true);
	    };
	};
    }, [width, data, captchaDone]);
    useEffect(() => {
	if (captchaDone) {
	    completeTimer();
	};
    });
    useEffect(() => {
        if (!stop && Object.keys(data).length > 0) {
            const timer = setTimeout(() => {
                setWidth(width - 1);
            }, timeStep);
            return () => clearTimeout(timer);
        };
    });
    useEffect(() => {
        window.addEventListener('resize', deviceScreenhandler);
        deviceScreenhandler();
        return () => {
            window.removeEventListener('resize', deviceScreenhandler);
        };
    });
    useEffect(() => {
        if ((error || apiError) && !removeWatchers) {
            watcherHandler();
        };
    });
    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                history.go(1);
            }
        };
    }, [history]);
    return (
        <PlayContainer>
	    {iceCaptcha && !captchaDone && !showMsg && !apiError && !error &&
		<MessageContainer>
		    <IcefaCaptcha appName='ptc' setCaptchaDone={setCaptchaDone} dark={1} />
		</MessageContainer>
	    }
            {showMsg && !apiError && !error &&
                <MessageContainer>
                    <MsgItem style={{ margin: 'auto', width: '80%' }}>
                        <MsgWrap primary={1}>
                            <MsgText primary={1}>
                                {showMsg}
                            </MsgText>
                        </MsgWrap>
                        <TinyFormButton type='button' primary={1} big={1} onClick={!loading ? completeHandler : null}>
		    	    {!loading ? 'complete' : <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} />}
                        </TinyFormButton>
                    </MsgItem>
                </MessageContainer>
            }
            {error &&
                <MessageContainer>
                    <MsgItem style={{ margin: 'auto', width: '80%' }}>
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {error}
                            </MsgText>
                        </MsgWrap>
                        <TinyButton to={`/ptc/${localStorage.getItem('ptc_page_number')}`} primary={1} big={1}>
                            return
                        </TinyButton>
                    </MsgItem>
                </MessageContainer>
            }
            {apiError &&
                <MessageContainer>
                    <MsgItem style={{ margin: 'auto', width: '80%' }}>
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {apiError}
                            </MsgText>
                        </MsgWrap>
                        <TinyButton to={`/ptc/${localStorage.getItem('ptc_page_number')}`} primary={1} big={1}>
                            return
                        </TinyButton>
                    </MsgItem>
                </MessageContainer>
            }
            {width > 0 && <Bar width={width + '%'} stop={stop ? 1 : 0} />}
            <Iframe
                src={data.url}
                title={data.url}
                onMouseLeave={() => setStop(true)}
                onMouseEnter={MousEnterHandler} />
        </PlayContainer>
    )
}
export default PtcPlay;
