import styled from 'styled-components';
import { PromotionTabWrap } from '../Promotions/PromotionElements';
import device from '../../constants/breakPoints';

export const HistoryTabWrap = styled(PromotionTabWrap)`
    grid-template-columns : 1fr 1fr 1fr 1fr 1fr;

    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
    }
`