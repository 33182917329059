import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const MotherContainer = styled.div`
    background: ${({ dark }) => (dark ? '#111121' : '#f9f9f9')};
    width: 100%;
    min-height: 100vh;
`
export const DetailWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 6fr 1fr;
    align-items: center;
    padding: 10px;

    @media screen and ${device.md} {
        padding: 10px 3px;
    }
`
export const Duration = styled.div`
    text-align: center;

    span {
        font-size: clamp(0.8rem, 2.7vw, 1.2rem);
        color: ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
    }

`
export const DetailTitle = styled.div`
    display: flex;
    align-items: cnter;
    justify-content: center;
    text-align: center;

    h1 {
        font-size: clamp(0.7rem, 3vw, 1.4rem);
        color: ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        letter-spacing: 0.5px;
        word-spacing: 1.5px;
    }
`
export const Pcoin = styled.div`
    text-align: center;

    span {
        font-size: clamp(0.8rem, 2.7vw, 1.2rem);
        color: ${({ color }) => (color === 'red'
        ? '#5a14c8'
        : color === 'yellow'
            ? '#787410'
            : color === 'green'
                ? '#277555'
                : '#1D6BBB')};
    }
`
export const MsgContainer = styled.div`
    width: 100%;
    background: rgba(255, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
`
export const MsgItem = styled.div`
    width: 80%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 30px;
    align-items: center;

    @media screen and ${device.md} {
        width: 100%;
        padding: 0 10px;
    }

    @media screen and ${device.tn} {
        grid-template-columns: 1fr;
    }
`
