import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';

export const CaptchaContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0)' : 'rgba(39, 117, 85, 0.2)')};
    padding: 10px;
    border-radius: ${({ surf }) => (surf ? '0 0 10px 10px' : '0')};
`
export const CaptchaWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0.5)' : 'rgba(39, 117, 85, 0.2)')};
    border: 1px solid ${({ dark }) => (dark ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 0 5px ${({ dark }) => (dark ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 10px;
    padding: 10px;
`
export const QuestionsWrap = styled.div`
    width: 100%;
    padding: 0 5px;
    p {
	font-size: clamp(0.75rem, 3vw, 0.9rem);
	color: #ffffff;
	letter-spacing: 1.1px;
	word-spacing: 1.7px;
	line-height: 1.45;
    }
`
export const ChoicesContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin: 10px auto;
`
export const ChoicesWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr 1fr;
    grid-column-gap: 10%;
    
    @media screen and ${device.md} {
	grid-template-columns : 1fr 1fr;
	grid-column-gap: 1px;
	grid-row-gap: 15px;
    }
`
export const Choices = css`
    width: 60px;
    height: 60px;
    background: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(29, 107, 187, 0.4)')};
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.8);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    @media screen and ${device.sm} {
	width: 45px;
	height: 45px;
    }

    &:hover {
	transition: all 0.3s ease-in-out;
	background: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(39, 117, 85, 0.4)')};
	border: 1px solid rgba(39, 117, 85, 0.5);
	box-shadow: 0 0 10px rgba(39, 117, 85, 0.8);
    }

    img {
	width: 100%;
	height: 100%;
    }

    span {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-size: clamp(0.7rem, 3vw, 0.85rem);
	color: #ffffff;
    }
`
export const ChoicesImg = styled.div`
    ${Choices}
`
export const ChoicesTxt = styled.div`
    ${Choices}
    width: 100px;
    height: 40px;

    @media screen and ${device.sm} {
	width: 90px;
	height: 35px;
    }
    @media screen and ${device.tn} {
	width: 80px;
	height: 35px;
    }
`
