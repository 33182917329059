import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { FiLink } from 'react-icons/fi';
import { MdDescription } from 'react-icons/md';

export const TaskContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    padding: 25px;
    opacity: ${({ complete }) => (complete ? '0.5' : '1')};
`
export const TaskWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : 'rgba(39, 117, 85, 0.4)')};
    box-shadow: 0 0 20px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 15px;
`
export const TaskTitleWrap = styled.div`
    width: 100%;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.25)' : 'rgba(39, 117, 85, 0.25)')};
    border-radius: 15px 15px 0 0;
    box-shadow: 0 0 7px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-column-gap: 15px;
    align-items: center;
    padding: 10px 20px;
    @media screen and ${device.tn} {
	padding: 10px 5px;
	grid-column-gap: 10px;
    }
`
export const TaskLevel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.35)' : 'rgba(39, 117, 85, 0.35)')};
    border-radius: 10px 10px 0 0;
    padding: 5px;
    h3 {
	font-size: clamp(0.65rem, 2.7vw, 0.9rem);
	color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	text-transform: uppercase;
	letter-spacing: 1.1px;
	text-align: center;
    }
`
export const TaskTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
	font-size: clamp(0.85rem, 2.7vw, 1.1rem);
	color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	text-transform: uppercase;
	letter-spacing: 0.5px;
	word-spacing: 1.5px;
	text-align: center;
    }
`
export const TaskNumber = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
	font-size: clamp(1.2rem, 2.7vw, 1.7rem);
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	text-align: center;
    }
`
export const ProgressBarWrap = styled.div`
    width: 75%;
    height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    outline: 2px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 0 0 30px 0;
    margin-top: 25px;
    span {
	position: absolute;
	color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	font-size: clamp(0.6rem, 2.7vw, 0.75rem);
	padding-left: 4px;
    }
    @media screen and ${device.md} {
	width: 90%;
    }
    @media screen and ${device.tn} {
	width: 97%;
    }
`
export const ProgressBar = styled.div`
    width: ${({ width }) => (width)};
    height: 13px;
    background: rgba(1, 219, 187, 0.9);
    border-radius: 0 0 30px 0;
`
export const TaskContentWrap = styled.div`
    width: ${({ multiValue }) => (multiValue ? '92%' : '75%')};
    display: grid;
    grid-template-columns: ${({ multiValue }) => (multiValue ? '1fr 7fr 4fr' : '3fr 9fr')};
    grid-column-gap: 40px;
    justify-content: center;
    margin: 25px auto 10px auto;
    @media screen and ${device.md} {
	width: ${({ multiValue }) => (multiValue ? '95%' : '90%')};
	grid-template-columns: ${({ multiValue }) => (multiValue ? '1fr 2fr' : '3fr 9fr')};
	grid-row-gap: 20px;
    }
    @media screen and ${device.tn} {
	grid-template-columns: 1fr;
	grid-row-gap: 20px;
    }
`
export const InfoWrap = styled.div`
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
`
export const TaskImgWrap = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.75)' : 'rgba(39, 117, 85, 0.75)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 50%;
    color: #fff;
    font-size: 0.7rem;
    img {
	width: 90%;
	height: 90%;
	position: absolute;
	border-radius: 50%;
    }
`
export const TaskImgLink = styled.a`

`
export const RepeatWrap = styled.div`
    margin-top: 2px;
    h3 {
	font-size: clamp(0.7rem, 2.7vw, 0.8rem);
	font-weight: 400;
	color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	text-align: center;
    }
`
export const TaskDescription = styled.div`
    width: 100%;
    max-height:160px;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : 'rgba(39, 117, 85, 0.4)')};
    border-radius: 5px;
    margin: auto;
    padding: 5px 10px;
    overflow: auto;
    @media screen and ${device.md} {
	grid-row-start : ${({ multiValue }) => (multiValue ? '2' : '1')};
	grid-column-start: ${({ multiValue }) => (multiValue ? '1' : '2')};
	grid-column-end: ${({ multiValue }) => (multiValue ? '3' : '2')};
    }
    @media screen and ${device.tn} {
	grid-row-start : ${({ multiValue }) => (multiValue ? '3' : '2')};
	grid-column-start: ${({ multiValue }) => (multiValue ? '1' : '1')};
	grid-column-end: ${({ multiValue }) => (multiValue ? '1' : '1')};
    }
    p {
	color: #fff;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	font-size: clamp(0.75rem, 2.7vw, 0.9rem);
	letter-spacing: 0.5px;
	word-spacing: 1.5px;
    }
    &::-webkit-scrollbar {
	width: 7px;
	height: 10px;
    }
    &::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : 'rgba(39, 117, 85, 0.8)')};
    }
`
export const TableWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
`
export const Table = styled.table`
    border-collapse: collapse;
`
export const HeadWrap = styled.thead`
`
export const HeadRow = styled.tr`
`
export const Head = styled.th`
    font-size: clamp(0.65rem, 2.7vw, 0.8rem);
    color: #fff;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    font-weight: 400;
    padding: 5px;
    &:nth-child(1) {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
    }
    &:nth-child(2) {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.75)' : 'rgba(39, 117, 85, 0.75)')};
    }
    &:nth-child(3) {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1)' : 'rgba(39, 117, 85, 1)')};
    }
`
export const BodyWrap = styled.tbody``
export const BodyRow = styled.tr`
`
export const Cell = styled.td`
    text-align: center;
    font-size: clamp(0.65rem, 2.7vw, 0.8rem);
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    padding: 5px;
    &:nth-child(1) {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.25)' : 'rgba(39, 117, 85, 0.25)')};
    }
    &:nth-child(2) {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.45)' : 'rgba(39, 117, 85, 0.45)')};
    }
    &:nth-child(3) {
	background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.65)' : 'rgba(39, 117, 85, 0.65)')};
    }
`
export const TaskPrice = styled.div`
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.35)' : 'rgba(39, 117, 85, 0.35)')};
    border-radius: 10px;
    padding: 5px 15px;
    h3 {
	font-size: clamp(0.7rem, 2.7vw, 0.9rem);
	color: rgba(1, 219, 187, 1);
	text-align: center;
    }
`
export const TaskInputWrap = styled.form`
    display: flex;  
    align-items: center;
    width: 60%;
    margin-top: 10px;
    @media screen and ${device.tn} {
	width: 90%;
    }
`
export const TaskFormInput = styled.input`
    width: 100%;
    padding: 5px 10px 6px 40px;
    border: none;
    border-radius: 20px;
    font-size: clamp(0.9rem, 2.7vw, 1.05rem);
    @media screen and ${device.sm} {
	padding: 5px 10px 6px 30px;
    }
    ::placeholder {
	color: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
	font-size: clamp(0.8rem, 2.7vw, 0.9rem);
    }
    &:focus {
	outline: 3px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    }
`
export const TaskLinkIcon = styled(FiLink)`
    position: absolute;
    transform: translate(75%, 5%);
    color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
    font-size: 1.1rem;
    opacity: 0.70;
    @media screen and ${device.sm} {
	font-size: 0.9rem;
    }
`
export const TaskDescIcon = styled(MdDescription)`
    position: absolute;
    transform: translate(75%, 5%);
    color: ${({ primary }) => (primary ? '#1D6BBB' : '#277555')};
    font-size: 1.1rem;
    opacity: 0.70;
    @media screen and ${device.sm} {
	font-size: 0.9rem;
    }
`
export const UploadButtonWrap = styled.div`
    display: flex;
    justify-content: center;
`
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 2px 8px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease-in-out;
    @media screen and ${device.sm} {
	width: 55px;
	height: 55px;
    }}
    &:hover {
	transform: scale(1.09);
	transition: 0.3s ease-in-out;
    }
`
export const Button = styled.span`
    color: #fff;
    font-size: clamp(0.55rem, 1.8vw, 0.65rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    background: ${({ itemActive, primary }) => (itemActive ? '#01DBBB' : primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    &:hover {
	background: ${({ itemActive, primary }) => (itemActive ? '#01DBBB' : primary ? '#1D6BBB' : '#277555')};
	transition: 0.3s ease-in-out;
    }
`
export const ImgUploadedWrap = styled.div`
    width: 60px;
    height: 60px;
    border: 1px solid rgba(1, 219, 187, 1);
    box-shadow: 0 0 5px rgba(1, 219, 187, 0.7);
    margin-left: 15px;
`
export const ImgUploaded = styled.img`
    width: 100%;
    height: 100%;
`
