import React from 'react';
import { Header, LeftWrap, LogoWrapLink, SubjectWrap } from '../TOS/TOSElements';
import { BigContainer, ContainerLogout, WrapperLogout, ContentWrap } from '../Auth/LogoutElements';
import logo from '../../images/logo.png';
import { MsgWrap, MsgText } from '../MessageElements.js';

const InvalidToken = () => {
    return (
        <BigContainer>
            <ContainerLogout>
	        <WrapperLogout>
	    	    <Header style={{ background: 'rgba(29, 107, 187, 0.4)' }}>
	        	<LeftWrap>
	            	    <LogoWrapLink to='/'>
	                	<img src={logo} alt={'logo of icefaucet'} />
	            	    </LogoWrapLink>
	        	</LeftWrap>
	        	<SubjectWrap>
	            	    <h1>invalid token page</h1>
	        	</SubjectWrap>
	    	    </Header>
	    	    <ContentWrap>
	        	<MsgWrap primary={0}>
	            	    <MsgText primary={0}>
	                	Your token is not valid anymore!
	            	    </MsgText>
	        	</MsgWrap>
	    	    </ContentWrap>
	    	</WrapperLogout>
	    </ContainerLogout>
        </BigContainer>
    )
};

export default InvalidToken;
