import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { BannerFaucetWrap, MobileDeactiveFaucetContainer } from '../DesktopBanner/DesktopBannerElements';
import Countdown, { zeroPad } from 'react-countdown';
import { FaucetTimer } from '../Mainbar/MainbarElements';
import { MsgText, MsgWrap } from '../MessageElements';
import LoadingContest from '../Contest/LoadingContest';
import LoadingContestOffer from '../ContestOffer/LoadingContest';
import WheelComponentPromo from '../LuckyWheel/SpinPromo';
import LotteryPromo from '../Lottery/LotteryPromo';

const FaucetDeactiveMobileBanners = ({ timer }) => {
    const [fetchBanner, setFetchBanner] = useState(false);
    const renderer = ({ minutes, seconds }) => {
	return <FaucetTimer>{zeroPad(minutes)}:{zeroPad(seconds)}</FaucetTimer>;
    };
    const [mobileBanner, setMobileBanner] = useState();
    useEffect(() => {
	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('src', 'https://appsha-pnd.ctengine.io/js/script.js?wkey=T5aQuSkaCC');
	document.body.appendChild(script);
	return new Promise(() => {
	    script.remove();
	});
    }, []);
    useEffect(() => {
	const fetchMobileBanner = async () => {
	const { data } = await axiosInstance.get('banner/deactive/faucet/mobile/');
	setMobileBanner(data);
	setFetchBanner(true);
	if (data.p3 !== null) {
	    const ins = document.getElementById('surf1');
	    ins.className = 'surfe-be';
	    ins.setAttribute('data-sid', data.p3.data);
	    const script = document.createElement('script');
	    script.innerHTML = `(adsurfebe = window.adsurfebe || []).push({});`;
	    document.body.appendChild(script);
	    return new Promise(() => {
		script.remove();
	    });
	};
    };
	fetchMobileBanner();
    }, []);
    const handleBannerClicked = (id) => {
	fetchBannerClicked(id);
    };
    const fetchBannerClicked = async (id) => {
	await axiosInstance.post('banner/clicked/', {
	    'id': id
	});
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    return (
	<MobileDeactiveFaucetContainer>
	    {mobileBanner !== undefined && mobileBanner.p1 !== null && mobileBanner.plan === 'A' &&
		<div style={{ margin: '15px auto' }}>
		    <BannerFaucetWrap href={mobileBanner.p1.url} target='_blank' onClick={() => handleBannerClicked(mobileBanner.p1.banner_id)} onContextMenu={handleRightClick}>
			<img src={mobileBanner.p1.banner_img ? mobileBanner.p1.banner_img : mobileBanner.p1.banner_url ? mobileBanner.p1.banner_url : null} alt={mobileBanner.p1.title} title={mobileBanner.p1.url.split('/')[2]} />
		    </BannerFaucetWrap>
		</div>
	    }
	    {mobileBanner !== undefined && mobileBanner.p1 !== null && mobileBanner.plan === 'B' &&
		<div style={{ margin: '15px auto' }}>
		    <iframe title='cryptocoinsad'
		        src={mobileBanner.p1.src}
		        style={{ width: `${mobileBanner.p1.width}px`, height: `${mobileBanner.p1.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	    {fetchBanner && timer !== undefined &&
		<div style={{ width: '300px', margin: 'auto' }}>
		    <MsgWrap primary={0}>
			<MsgText primary={0}>
			    <Countdown
		                date={Date.now() + timer * 1000}
		                renderer={renderer}
		            />
			</MsgText>
		    </MsgWrap>
		</div>
	    }
	    {mobileBanner !== undefined && mobileBanner.p2 !== null && mobileBanner.plan === 'A' &&
		<div style={{ display: 'flex', justifyContent: 'center', margin: '15px auto' }}>
		    <BannerFaucetWrap href={mobileBanner.p2.url} target='_blank' onClick={() => handleBannerClicked(mobileBanner.p2.banner_id)} onContextMenu={handleRightClick}>
			<img src={mobileBanner.p2.banner_img ? mobileBanner.p2.banner_img : mobileBanner.p2.banner_url ? mobileBanner.p2.banner_url : null} alt={mobileBanner.p2.title} title={mobileBanner.p2.url.split('/')[2]} />
		    </BannerFaucetWrap>
		</div>
	    }
	    {mobileBanner !== undefined && mobileBanner.p2 !== null && mobileBanner.plan === 'B' &&
		<div style={{ display: 'flex', justifyContent: 'center', margin: '15px auto' }}>
		    <iframe title='cryptocoinsad'
		        src={mobileBanner.p2.src}
		        style={{ width: `${mobileBanner.p2.width}px`, height: `${mobileBanner.p2.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	    {fetchBanner &&
		<LoadingContestOffer big={0} table={false} />
	    }
	    {fetchBanner &&
		<WheelComponentPromo
		    segments={["THUNDER", "25000 PC", "35000 PC", "50000 PC", "75000 PC", "ICEDAY", "100000 PC", "250000 PC"]}
		    segColors={[
			"rgba(29, 107, 187, 0.5)",
			"rgba(39, 117, 85, 0.5)",
			"rgba(120, 116, 16, 0.5)",
			"rgba(90, 20, 200, 0.5)",
			"rgba(29, 107, 187, 0.85)",
			"rgba(39, 117, 85, 0.85)",
			"rgba(120, 116, 16, 0.85)",
			"rgba(90, 20, 200, 0.85)",
		    ]}
		    primaryColor="rgba(1, 219, 187, 1)"
		    contrastColor="rgba(29, 107, 187, 1)"
		    size={100}
		    upDuration={200}
		    downDuration={1100}
		/>
	    }
	    {fetchBanner &&
		<LoadingContest big={0} table={false} />
	    }
	    {fetchBanner && <LotteryPromo big={0} />}
	    {mobileBanner !== undefined && mobileBanner.p3 !== null &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto' }}>
		    <ins id='surf1'></ins>
		</div>
	    }
	    {mobileBanner !== undefined && mobileBanner.p4 !== null &&
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px auto' }}>
		    <iframe title='bittrafficads'
		        src={mobileBanner.p4.src}
		        style={{ width: `${mobileBanner.p4.width}px`, height: `${mobileBanner.p4.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
		    </iframe>
		</div>
	    }
	</MobileDeactiveFaucetContainer>
    )
}
export default FaucetDeactiveMobileBanners;
