import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { Link } from 'react-router-dom';

export const SetUpContainer = styled.div`
    position: absolute;
    top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 1;

    :before {
        content: '';
        top: -60px;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`
export const SetUpBgWrap = styled.div`
    position: fixed;
    color: #fff;
    font-size: 1.5rem;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        background: #000;
        overflow: hidden;
    }
`
export const AdsSetUpContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: rgba(200, 200, 200, 0.25);
    overflow: hidden;
`
export const AdsContent = styled.div`
    position: absolute;
    top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-width: 660px;
    height: calc(100% - 120px);
    padding: 20px 5px;
    background: rgba(29, 107, 187, 0.35);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    overflow: auto;

    @media screen and ${device.lg} {  
        width: 85%;
        max-width: none;
    }

    @media screen and ${device.md} {  
        width: 95%;
        max-width: none;
    }
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5); 
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(1, 219, 187, 0.5);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background:rgba(1, 219, 187, 0.5);
    }
`
export const AdsToolWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const FormHeader = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    border-bottom: 1px solid #fff;

h2 {
    color: #fff;
    font-size: clamp(0.8rem, 3vw, 1rem);
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 2px;
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
}
`
export const ItemContainer = styled.div`
    display: grid;
    grid-column-gap: 25px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 20px 0;

    @media screen and ${device.md} {
        grid-column-gap: 20px;
    }
    @media screen and ${device.sm} {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
`
export const ItemWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 1px solid rgba(1, 219, 187, 0.5);
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    text-align: center;
    transition: 0.3s ease-in-out;

    @media screen and ${device.md} {
        width: 80px;
        height: 70px;
    }}
    @media screen and ${device.tn} {
        width: 70px;
        height: 60px;
    }}
    &:hover {
        transform: scale(1.09);
        transition: 0.3s ease-in-out;
    }
`
export const ItemLink = styled(Link)`
    color: #fff;
    font-size: clamp(0.75rem, 1.8vw, 0.9rem);
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    background: rgba(39, 117, 85, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;

    &:hover {
        background: rgba(29, 107, 187, 0.5);
        color: #01DBBB;
        transition: 0.3s ease-in-out;
    }
`