import styled from 'styled-components';
import device from '../../constants/breakPoints'

export const SFContainer = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;

    &:hover {
        opacity: 0.9;
        transition: all 0.3s ease-in-out;
    }
`
export const SFWrapper = styled.div`
    width: 100%;
    max-width: 500px;
    margin: auto;
    background: #010606;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #010606;

    @media screen and ${device.md}{
        width: 95%;
    }
`
export const SFTextWrap = styled.div`
    width: 90%;
    margin: 90px 30px 10px 30px;
    
    h3 {
        color: #fff;
        font-size: 1rem;
        font-weight: 400;
        text-shadow: 1px 2px 5px #000;
    }
    
`
export const SFImageWrap = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    img {
        width: 90%;
        max-width: 350px
        height: 350px;
    }    
`
