import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const ContentWrap = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 15px 0;
    margin: 25px auto;

    @media screen and ${device.md} {
        grid-template-columns : 1fr;
        grid-row-gap: 30px;
    }
`
export const TableWrap = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(39, 117, 85, 0.2);
    border: 1px solid rgba(39, 117, 85, 0.3);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.7);
    border-radius: 10px;
    padding: 15px;
    margin: 25px auto;
`
export const Table = styled.table`
    border-collapse: collapse;
    border-left: 2px solid rgba(39, 117, 85, 0.7);
    border-right: 2px solid rgba(39, 117, 85, 0.7);
    width: 90%;
`
export const HeadWrap = styled.thead`
    width: 100%;
    background: rgba(39, 117, 85, 0.7);
`
export const HeadRow = styled.tr`
    width: 100%;
`
export const Head = styled.th`
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.7rem, 1.3vw, 0.9rem);
    font-weight: 400;
    letter-spacing: 1.1px;
    word-spacing: 1.5px;
    text-transform: uppercase;
    padding: 10px 5px;
`
export const BodyWrap = styled.tbody``
export const BodyRow = styled.tr`
    &:nth-child(even) {
        background: rgba(29, 107, 187, 0.2);
    }
    &:nth-child(odd) {
        background: rgba(39, 117, 85, 0.2);
    }
`
export const Cell = styled.td`
    border-bottom: 2px solid rgba(39, 117, 85, 0.7);
    text-align: center;
    color: #fff;
    font-size: clamp(0.75rem, 1.3vw, 0.85rem);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    padding: 5px;
`
