import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const Container = styled.div`
    position: relative;
    background: ${({ black }) => (black ? '#010606' : '#f9f9f9')};
    width: 100%;
    height: 100vh;
    min-height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const Wrapper = styled.div`
    display: grid;
    grid-template-columns:${({ extra }) => (extra ? '1.5fr 9fr 1.5fr' : '1.5fr 10.5fr')};
    grid-gap: 10px;
    background: rgba(29, 107, 187, 0.50);
    margin: 10px auto;
    padding: 10px;
    width: 80%;
    height: 85px;
    border-radius: 5px;
    border: 1px solid rgba(29, 107, 187, 0.7);
    box-shadow: 0 0 10px rgba(29, 107, 187, 1);

    &:nth-child(2n) {
        background: rgba(39, 117, 85, 0.5);
        border: 1px solid rgba(39, 117, 85, 0.7);
        box-shadow: 0 0 10px rgba(39, 117, 85, 1);
    }
`
export const ImgWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50px;
    }
`
export const DescWrap = styled.div`
    width: 100%;
    padding: 0 10px;
    background: blue;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 10px 10px 0 0;

    h4 {
        color: #fff;
        font-size: clamp(0.68rem, 2vw, 1.1rem);
        font-weight: 400;
        letter-spacing: 1.1px;
        word-spacing: 1.6px;
        line-height: 1.3;
        text-shadow: 0 1px 3px #000; 
        @media screen and ${device.sm} {
            letter-spacing: 0.4px;
            word-spacing: 0.7px;
            line-height: 1;
        };
    }
`
export const PriceWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
        color: #01DBBB;
        font-size: clamp(0.75rem, 2vw, 1.1rem);
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 4px;
        text-transform: uppercase;
    }
`
