import { CLOSE_SIDEBAR, TOGGLE_SIDEBAR, SCROLL_HEADER } from "../constants/headerRedux";

export const headerOpenReducer = (state = false, action) => {
    switch (action.type) {
        case CLOSE_SIDEBAR:
            return false
        case TOGGLE_SIDEBAR:
            return !state
        default:
            return state
    }
}
export const headerScrollReducer = (state = false, action) => {
    switch (action.type) {
        case SCROLL_HEADER:
            return action.payload
        default:
            return state
    }
}

