import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import ViewAdsControl from '../components/AdsControl/ViewAdsControl';
import MiningAdsControl from '../components/AdsControl/MiningAdsControl';
import BannerAdsControl from '../components/AdsControl/BannerAdsControl';
import SponsorAdsControl from '../components/AdsControl/SponsorAdsControl';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { StackContainer, StackWrap, TitleWrap } from '../components/Stack/StackElements';
import { HelpIcon, HelpWrap } from '../components/HelpElements';
import { Circle } from '../components/Loading';
import { IconWrap, DownIcon, UpIcon } from '../components/HelpDesk/FAQElements';

const ControlAdsPage = () => {
    const [info, setInfo] = useState();
    const [adscoin, setAdscoin] = useState();
    const [error, setError] = useState('');
    const [infoMining, setInfoMining] = useState();
    const [errorMining, setErrorMining] = useState('');
    const [infoBanner, setInfoBanner] = useState();
    const [errorBanner, setErrorBanner] = useState('');
    const [infoSponsor, setInfoSponsor] = useState();
    const [errorSponsor, setErrorSponsor] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const [loading, setLoading] = useState(true);
    const [arrowClick1, setArrowClick1] = useState(true);
    const [arrowClick2, setArrowClick2] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [arrowClick3, setArrowClick3] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [arrowClick4, setArrowClick4] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const handleArrowClick2 = () => {
	setArrowClick2(!arrowClick2);
	!loading2 && infoMining === undefined && !errorMining && fetchControlMiningAdsInfo();
    };
    const handleArrowClick3 = () => {
	setArrowClick3(!arrowClick3);
	!loading3 && infoBanner === undefined && !errorBanner && fetchControlBannerAdsInfo();
    };
    const handleArrowClick4 = () => {
	setArrowClick4(!arrowClick4);
	!loading4 && infoSponsor === undefined && !errorSponsor && fetchControlSponsorAdsInfo();
    };
    const fetchControlMiningAdsInfo = async () => {
	setLoading2(true);
        try {
            const { data } = await axiosInstance.get('mining/control-ads/mining/');
            setInfoMining(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setErrorMining(error.response.data.detail) : setErrorMining(error.message);
        };
	setLoading2(false);
    };
    const fetchControlBannerAdsInfo = async () => {
	setLoading3(true);
        try {
            const { data } = await axiosInstance.get('banner/control-ads/banner/');
            setInfoBanner(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setErrorBanner(error.response.data.detail) : setErrorBanner(error.message);
        };
	setLoading3(false);
    };
    const fetchControlSponsorAdsInfo = async () => {
	setLoading4(true);
        try {
            const { data } = await axiosInstance.get('sponsor/control-ads/sponsor/');
            setInfoSponsor(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setErrorSponsor(error.response.data.detail) : setErrorSponsor(error.message);
        };
        setLoading4(false);
    };
    useEffect(() => {
	const fetchControlAdsInfo = async () => {
	    try {
		const { data } = await axiosInstance.get('ptc/control-ads/view-ads/');
		setInfo(data.info);
		setAdscoin(data.user_adscoin);
	    } catch (error) {
		error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
		error.response && error.response.data.detail && setAdscoin(error.response.data.user_adscoin);
	    };
	    setLoading(false);
	};
        fetchControlAdsInfo();
    }, []);
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
            {!loading &&
                <>
                    <StackContainer primary={1}>
                        <StackWrap primary={1}>
                            <TitleWrap primary={1} style={{ justifyContent: 'space-between', padding: '0 3%', cursor: 'pointer' }}>
			    	<div style={{ display: 'flex' }}>
                                    <h2>your view ads</h2>
                                    <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
			        </div>
			        <IconWrap onClick={() => setArrowClick1(!arrowClick1)}>
			            {arrowClick1 ? <UpIcon /> : <DownIcon />}
		                </IconWrap>
                            </TitleWrap>
                            <HelpWrap show={showHelp ? 1 : 0}>
                                <p>
                                    To edit or delete your ads on watching status,
                                    you should stop your ads first and wait until the watchers of ads become zero.
                                </p>
                            </HelpWrap>
                            {arrowClick1 && error &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        {error}
                                    </MsgText>
                                </MsgWrap>
                            }
                            {arrowClick1 && info !== undefined && adscoin !== undefined && error.length === 0 &&
                                <ViewAdsControl info={info} adscoin={adscoin} />
                            }
                        </StackWrap>
                    </StackContainer>
                    <StackContainer primary={0}>
                        <StackWrap primary={0}>
                            <TitleWrap primary={0} style={{ justifyContent: 'space-between', padding: '0 3%', cursor: 'pointer' }}>
                                <h2>your mining ads</h2>
			        <IconWrap onClick={() => handleArrowClick2()}>
			            {arrowClick2 && !loading2 ? <UpIcon /> : arrowClick2 && loading2 ? <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} /> : <DownIcon />}
		                </IconWrap>
                            </TitleWrap>
                            {arrowClick2 && errorMining &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        {errorMining}
                                    </MsgText>
                                </MsgWrap>
                            }
                            {arrowClick2 && infoMining !== undefined && adscoin !== undefined && errorMining.length === 0 &&
                                <MiningAdsControl info={infoMining} adscoin={adscoin} />
                            }
                        </StackWrap>
                    </StackContainer>
                    <StackContainer primary={1}>
                        <StackWrap primary={1}>
                            <TitleWrap primary={1} style={{ justifyContent: 'space-between', padding: '0 3%', cursor: 'pointer' }}>
                                <h2>your banner</h2>
			        <IconWrap onClick={() => handleArrowClick3()}>
			            {arrowClick3 && !loading3 ? <UpIcon /> : arrowClick3 && loading3 ? <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} /> : <DownIcon />}
		                </IconWrap>
                            </TitleWrap>
                            {arrowClick3 && errorBanner &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        {errorBanner}
                                    </MsgText>
                                </MsgWrap>
                            }
                            {arrowClick3 && infoBanner !== undefined && adscoin !== undefined && errorBanner.length === 0 &&
                                <BannerAdsControl info={infoBanner} adscoin={adscoin} />
                            }
                        </StackWrap>
                    </StackContainer>
                    <StackContainer primary={0}>
                        <StackWrap primary={0}>
                            <TitleWrap primary={0} style={{ justifyContent: 'space-between', padding: '0 3%', cursor: 'pointer' }}>
                                <h2>your sponsor link</h2>
			        <IconWrap onClick={() => handleArrowClick4()}>
			            {arrowClick4 && !loading4 ? <UpIcon /> : arrowClick4 && loading4 ? <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} /> : <DownIcon />}
		                </IconWrap>
                            </TitleWrap>
                            {arrowClick4 && errorSponsor &&
                                <MsgWrap primary={0}>
                                    <MsgText primary={0}>
                                        {errorSponsor}
                                    </MsgText>
                                </MsgWrap>
                            }
                            {arrowClick4 && infoSponsor !== undefined && adscoin !== undefined && errorSponsor.length === 0 &&
                                <SponsorAdsControl info={infoSponsor} adscoin={adscoin} />
                            }
                        </StackWrap>
                    </StackContainer>
                </>
            }
        </>
    )
}
export default ControlAdsPage;
