import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import { MotherContainer } from '../components/PtcDetail/PtcDetailElements';

const SLDonePage = ({ match }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [complete, setComplete] = useState('');
    const dark = localStorage.getItem('lightness') ? true : false;
    useEffect(() => {
	const fetchShortLinkDone = async () => {
	    try {
		await axiosInstance.get(`shortlink/complete/${match.params.code}/${match.params.token}/`);
		setTimeout(() => window.open(`https://icefaucet.com/shortlink`, '_self', 'noreferrer'), 1000);
		setComplete(true);
	    } catch (error) {
		error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    };
	    setLoading(false);
	};
	fetchShortLinkDone();
    }, [match.params.code, match.params.token]);
    return (
	<MotherContainer dark={dark ? 1 : 0}>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && !error && complete &&
		<MsgWrap primary={1} style={{ width: '80%', margin: 'auto', position: 'relative', top: '15px'  }}>
		    <MsgText primary={1}>
			Awesome! You have successfully earned.
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && error &&
		<MsgWrap primary={0} style={{ width: '80%', margin: 'auto', position: 'relative', top: '15px' }}>
		    <MsgText primary={0}>
			{error}
		    </MsgText>
		</MsgWrap>
	    }
	</MotherContainer>
    )
}
export default SLDonePage;
