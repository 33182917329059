import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import Lottery from '../components/Lottery';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../components/TaskView/TaskPageElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../components/PtcView/PtcViewElements';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { DescWrap } from '../components/History/DepositTabElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import { lotteryTableHead } from '../data/lotteryData';
import { TinyFormButton } from '../components/ButtonElements';
import MoreLotteryInfoModal from '../components/Lottery/Modal';

const LotteryPage = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    const [moreClick, setMoreClick] = useState(false);
    const [lotteryIndex, setLotteryIndex] = useState();
    useEffect(() => {
	const fetchLotteryInfo = async () => {
	    try {
		const { data } = await axiosInstance.get('lottery/info/');
		    setInfo(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchLotteryInfo();
    }, []);
    const handleMoreClick = (index) => {
	setMoreClick(true);
	setLotteryIndex(index);
    };
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {
		!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && !apiError &&
		<PtcHeaderWrap>
		    <PtcInfoWrap>
			<InfoContainer>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>total</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.all_info.number_end_lottery}</h2>
			        </DescWrap>
			    </InfoWrap>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>won</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.all_info.win_end_lottery / 10000} USDT</h2>
			        </DescWrap>
			    </InfoWrap>
			</InfoContainer>
		    </PtcInfoWrap>
		</PtcHeaderWrap>
	    }
	    {!loading && !apiError && info !== undefined && info.all_info.number_end_lottery > 0 &&
		<Table>
		    <HeadWrap>
			<HeadRow>
			    {lotteryTableHead.map((header, index) => (
				<Header key={index}>
				    {header}
				</Header>
			    ))}
			</HeadRow>
		    </HeadWrap>
		    <BodyWrap>
			{info.info.map((row, index) => (
			    <BodyRow key={index}>
				<Cell>
				    #{Object(info.info).length - index}
				</Cell>
				<Cell>
				    {row.tickets * 100} pc
				</Cell>
				<Cell>
				    {row.end_time.replace('-', '/').split('T')[0].replace('-', '/')}
				</Cell>
				<Cell>
				    <TinyFormButton style={{ margin: 'auto' }} primary={index % 2 === 0 ? 1 : 0} big={0} onClick={() => handleMoreClick(index)}>
					more
				    </TinyFormButton>
				</Cell>
				{
				    moreClick && lotteryIndex === index &&
				    <Cell>
					<MoreLotteryInfoModal moreClick={moreClick}
					    setMoreClick={setMoreClick}
					    row={row}
					/>
				    </Cell>
				}
			    </BodyRow>
			))}
		    </BodyWrap>
		</Table>
	    }
	    {!loading && !apiError && info !== undefined &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
		        <TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
		        <StackDiscWrap>
			    <p>
			        The price of each ticket is 100 primecoins. <br />
			        All tickets have the same chance of winning. <br />
			        More tickets, more winners.<br />
			        The more tickets you buy, the more chances you have to win.<br />
			        Every user can buy a maximum of 20 tickets.<br />
			        Each ticket is sold only once.<br />
			        The lottery is held automatically at 00:00 at the beginning of each month and prizes are awarded to the winners immediately.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer>
	    }
	    {!loading && !apiError && info !== undefined &&
		<StackContainer primary={0} style={{ top: '0' }}>
		    <StackWrap primary={0}>
			<TitleWrap primary={0}>
			    <h2>live lottery</h2>
			</TitleWrap>
			<Lottery />
		    </StackWrap>
		</StackContainer>
	    }
	</>
    )
}
export default LotteryPage;

