import React, { useState, useEffect } from 'react';
import { PtcContainer, PtcWrap, PtcTitleWrap, PtcRefIcon, PtcTitle, PtcVisits, RefIcon, VisitIcon, Number, PtcContentWrap, PtcDescription, PtcSocials, SocialWrap, PtcButtonwrap, PtcDetailWrap } from '../PtcView/PtcViewElements';
import { TinyButtonSurf } from '../ButtonElements';
import facebook from '../../images/facebook.svg';
import whatsapp from '../../images/whatsapp.svg';
import instagram from '../../images/instagram.svg';
import youtube from '../../images/youtube.svg';
import twitter from '../../images/twitter.svg';
import tiktok from '../../images/tiktok.png';
import telegram from '../../images/telegram.png';
import pinterest from '../../images/pinterest.svg';
import reddit from '../../images/reddit.svg';
import linkedin from '../../images/linkedin.svg';
import { MsgText, MsgWrap } from '../MessageElements';

const ViewSetUpPtc = () => {
    const [error, setError] = useState('');
    const [isStart, setIsStart] = useState(false);
    const [complete, setComplete] = useState('');
    const [playAds, setPlayAds] = useState();
    const [windowActive, setWindowActive] = useState(false);
    const [stop, setStop] = useState(true);
    const url = localStorage.getItem('url');
    const title = localStorage.getItem('title');
    const description = localStorage.getItem('description') ? localStorage.getItem('description') : null;
    const social1 = localStorage.getItem('social1') ? localStorage.getItem('social1') : null;
    const social2 = localStorage.getItem('social2') ? localStorage.getItem('social2') : null;
    const social3 = localStorage.getItem('social3') ? localStorage.getItem('social3') : null;
    const lDuration = parseFloat(localStorage.getItem('duration'));
    const [duration, setDuration] = useState(lDuration === 2.5 ? 40 : lDuration === 2 ? 30 : lDuration === 1.5 ? 20 : 10);
    const adsDuration = lDuration === 2.5 ? '40' : lDuration === 2 ? '30' : lDuration === 1.5 ? '20' : '10';
    const pcoin = lDuration * 8;
    const refshow = localStorage.getItem('refshow') === 'true' ? true : false;
    const adsColor = lDuration === 2.5 ? 'red' : lDuration === 2 ? 'yellow' : lDuration === 1.5 ? 'green' : 'blue';
    const handleClick = () => {
	const play_ads = window.open(url, '_blank');
	setPlayAds(play_ads);
	setIsStart(true);
	setStop(false);
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    const handleActivity = (forcedFlag) => {
	if (typeof forcedFlag === 'boolean') {
	    return forcedFlag ? setWindowActive(true) : setWindowActive(false);
	};
	return document.hidden ? setWindowActive(false) : setWindowActive(true);
    };
    const fetchMiningDone = async () => {
	setStop(true);
	setComplete('The validation of your ads is successfully done.');
    };
    useEffect(() => {
	if (playAds && playAds.closed) {
	    setError('You closed the ads!');
	    setStop(true);
	};
    }, [playAds, duration]);
    useEffect(() => {
	if (duration === 0 && !stop && complete.length === 0 && error.length === 0) {
	    fetchMiningDone();
	    return;
	};
	if (!stop) {
	    const adsTimer = setTimeout(() => {
		setDuration(duration - 1);
	    }, windowActive ? 1500 : 900);
	    return () => clearTimeout(adsTimer);
	};
    });
    useEffect(() => {
	const handleActivityFalse = () => handleActivity(false);
	const handleActivityTrue = () => handleActivity(true);
	document.addEventListener('visibilitychange', handleActivity);
	document.addEventListener('blur', handleActivityFalse);
	window.addEventListener('blur', handleActivityFalse);
	window.addEventListener('focus', handleActivityTrue);
	document.addEventListener('focus', handleActivityTrue);
	return () => {
	    document.removeEventListener('visibilitychange', handleActivity);
	    document.removeEventListener('blur', handleActivityFalse);
	    window.removeEventListener('blur', handleActivityFalse);
	    window.removeEventListener('focus', handleActivityTrue);
	    document.removeEventListener('focus', handleActivityTrue);
	};
    });
    useEffect(() => {
	document.title = isStart && duration > 0 ? `${duration} sec` : isStart && duration === 0 ? 'successfully validated' : 'IceFaucet';
	return duration === 0 && (() => document.title = 'IceFaucet');
    }, [duration, isStart]);
    return (
        <>
	    <PtcContainer color={adsColor} visitable={1}>
	        <PtcWrap color={adsColor}>
	            <PtcTitleWrap refshow={refshow ? 1 : 0} color={adsColor}>
	        	{refshow
			    && <PtcRefIcon>
			        <RefIcon coloricon={adsColor} />
			    </PtcRefIcon>
			}
	        	<PtcTitle refshow={refshow ? 1 : 0} color={adsColor}>
	            	    <h2>{title}</h2>
	        	</PtcTitle>
	        	<PtcVisits>
	            	    <VisitIcon coloricon={adsColor} />
	            	    <Number color={adsColor}>0</Number>
	        	</PtcVisits>
	            </PtcTitleWrap>
	    	    <PtcContentWrap>
	                {description
			    && <PtcDescription color={adsColor}>
			        <p>{description}</p>
			    </PtcDescription>
			}
	        	{social1 || social2 || social3
			    ? <PtcSocials state={description ? 1 : 0}>
				{social1
				     && <SocialWrap href={social1 && social1} target='_blank' rel='external' color={adsColor}>
					{social1.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
					{social1.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
				    </SocialWrap>
			        }
			        {social2
				    && <SocialWrap href={social2 && social2} target='_blank' rel='external' color={adsColor}>
					{social2 && social2.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
					{social2 && social2.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
				    </SocialWrap>
				}
			        {social3
				    && <SocialWrap href={social3 && social3} target='_blank' rel='external' color={adsColor}>
					{social3 && social3.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
					{social3 && social3.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
				    </SocialWrap>
				}
			    </PtcSocials>
		    	    : null
			}
	    	    </PtcContentWrap>
	    	    <PtcDetailWrap color={adsColor} show={1}>
	    		<span>{adsDuration} sec</span>
	    		{!isStart && !complete
			    ? <PtcButtonwrap>
				<TinyButtonSurf type='button'
				    onClick={handleClick}
				    onContextMenu={handleRightClick}
				    color={adsColor}
				    big={1}
				>
				    visit
				</TinyButtonSurf>
			    </PtcButtonwrap>
			    : isStart && !complete
				? <PtcButtonwrap>
				    <TinyButtonSurf type='button'
				        onContextMenu={handleRightClick}
				        color={adsColor}
				        big={1}
				    >
				        {duration} sec
				    </TinyButtonSurf>
				</PtcButtonwrap>
				: null
			}
	                <span>{pcoin} pc</span>
	            </PtcDetailWrap>
	    	    {complete &&
			<MsgWrap style={{ width: '50%' }} primary={1}>
			    <MsgText primary={1}>
				{complete}
			    </MsgText>
			</MsgWrap>
		    }
	    	    {error &&
			<MsgWrap style={{ width: '50%' }} primary={0}>
			    <MsgText primary={0}>
				{error}
			    </MsgText>
			</MsgWrap>
		    }
	        </PtcWrap>
	    </PtcContainer>
	</>
    )
}
export default ViewSetUpPtc;

