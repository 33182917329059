import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { Link } from 'react-router-dom';

export const PromotionContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #010606;
    border-radius: 9px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.9);
    opacity: 0.85;
`
export const PromotionBody = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-bottom: 25px;
    width: 90%;
    height: 96%;
    background: rgba(29, 107, 187, 0.4);
    border: rgba(29, 107, 187, 0.6);
    box-shadow: 0 2px 5px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 10px;
    overflow: auto;

    @media screen and ${device.sm} {
        margin-top: 140px;
    }
    @media screen and ${device.tn} {
        padding: 10px 3px;
    }

    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: 0 0 3px rgba(29, 107, 187, 0.5);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(29, 107, 187, 0.7);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(29, 107, 187, 0.85);
    }

    p {
        color: #fff;
        margin-bottom: 15px;
        font-size: clamp(0.85rem, 2.1vw, 1rem);
    }
`
export const RefTableContainer = styled.div`
    width: 85%;
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 15px;
    column-gap: 5px;
    margin: 0 auto 15px auto;

    @media screen and ${device.sm} {
        width: 100%;
    }
    @media screen and ${device.tn} {
        
        grid-template-rows: 1fr 1fr;
    }
`
export const RefTableCell = styled.div`
    background: rgba(29, 107, 187, 0.8);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    padding: 5px 7px 0 7px;

    @media screen and ${device.tn} {
        padding: 5px 1px 0 1px;
    }

    p {
    font-size: clamp(0.80rem, 2.1vw, 0.9rem);
    }
`
export const LinksTableContainer = styled.div`
    width: 85%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    row-gap: 10px;
    column-gap: 5px;
    margin: 0 auto 15px auto;

    @media screen and ${device.md} {
        grid-template-columns: 1fr;
        row-gap: 5px;
    }
`
export const LinksTableImg = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : 'rgba(39, 117, 85, 0.8)')};
    box-shadow: 0 0 5px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 5px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        width: 95%;

        @media screen and ${device.sm} {
            height: 160px;
        }

        @media screen and ${device.tn} {
            height: 130px;
        }
    }
`
export const LinksTableDetail = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : 'rgba(39, 117, 85, 0.8)')};
    box-shadow: 0 0 5px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 5px;
    padding: 15px 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    @media screen and ${device.md} {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        text-align: center;
        width: 100%;
    }
    @media screen and ${device.tn} {
        padding: 15px 2px;
    }

    span {
        color: #fff;
        font-size: clamp(0.70rem, 2.1vw, 0.85rem);
        letter-spacing: 1.3px;
        word-spacing: 2.5px;
        text-transform: uppercase;
    }
`
export const RefButtons = css`
    border-radius: 5px;
    background: rgba(1, 219, 187, 0.7);
    white-space: nowrap;
    width: 60px;
    height: 30px;
    color: #fff;
    font-size: clamp(0.70rem, 2.1vw, 0.85rem);
    letter-spacing: 2px;
    text-transform: uppercase;
    text-shdow: 0 0 1px rgba(0, 0, 0, 0.8);
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-left:2px;
    transition: all 0.3s ease-in-out;

    @media screen and ${device.sm} {
        width: 50px;
    }
    @media screen and ${device.tn} {
        width: 45px;
    }
    &:hover {
        transition: all 0.3s ease-in-out;
        background: rgba(1, 219, 187, 1);
        box-shadow: 0 0 3px rgba(1, 219, 187, 0.8);
    }
`
export const ViewButton = styled(Link)`
    text-decoration: none;
    ${RefButtons}
`
export const RefButton = styled.button`
    cursor: pointer;
    ${RefButtons}
`