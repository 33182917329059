import React from 'react';
import { SidebarContainer, SidebarWrapper } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../PtcView/PtcViewElements';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../TaskView/TaskPageElements';
import { DescWrap } from '../History/DepositTabElements';
import { lotteryMoreTableHead } from '../../data/lotteryData';

const MoreLotteryInfoModal = ({ moreClick, setMoreClick, row }) => {
    return (
	<SidebarContainer isOpen={moreClick ? 1 : 0}>
	    <SidebarWrapper>
	        <CloseIconWrap onClick={() => setMoreClick(false)}>
	            <CloseIcon />
	        </CloseIconWrap>
	        <PtcHeaderWrap>
	            <PtcInfoWrap>
	                <InfoContainer>
	                    <InfoWrap>
	                        <TitleInfoWrap>
	                            <h2>tickets</h2>
	                        </TitleInfoWrap>
	                        <DescWrap>
	                            <h2>{row.tickets}</h2>
	                        </DescWrap>
	                    </InfoWrap>
	                    <InfoWrap>
	                        <TitleInfoWrap>
	                            <h2>reward</h2>
	                        </TitleInfoWrap>
	                        <DescWrap>
	                            <h2>{row.lottery_reward / 10000} USDT </h2>
	                        </DescWrap>
	                    </InfoWrap>
	                </InfoContainer>
	            </PtcInfoWrap>
	        </PtcHeaderWrap>
	        <Table>
	            <HeadWrap>
	                <HeadRow>
	                    {lotteryMoreTableHead.map((header, index) => (
				<Header key={index}>
				    {header}
				</Header>
			    ))}
	                </HeadRow>
	            </HeadWrap>
	            <BodyWrap>
	                {row.winners_list.map((winner, index) => (
			    <BodyRow key={index}>
				<Cell>
				    {index + 1}
				</Cell>
				<Cell>
				    {winner.username}
				</Cell>
				<Cell>
			            {winner.ticket_number}
				</Cell>
			        <Cell>
				    {((row.tickets * 100 / row.winners) / 10000).toFixed(2)} USDT
				</Cell>
			    </BodyRow>
			))}
	            </BodyWrap>
	        </Table>
	    </SidebarWrapper>
	</SidebarContainer>
    )
}
export default MoreLotteryInfoModal;
