import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import axiosInstance from '../axios';
import { useParams } from 'react-router-dom';
import BlogArticle from '../components/HelpDesk/BlogArticle';

const HelpBlogPage = () => {
    const params = useParams();
    const blogCode = params.blogCode;
    const [infoArticle, setInfoArticle] = useState();
    const [error, setError] = useState('');
    const fetchBlogArticle = async () => {
        try {
            const { data } = await axiosInstance.get(`help/blog/${blogCode}/`);
            setInfoArticle(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
    };
    useEffect(() => {
        if (infoArticle === undefined && !error) {
            fetchBlogArticle();
        };
    });
    return (
        <>
	    <Helmet>
	        <title>iceFaucet | help desk</title>
	    </Helmet>
            {infoArticle !== undefined && !error &&
                <BlogArticle info={infoArticle} />
            }
        </>
    )
}
export default HelpBlogPage;
