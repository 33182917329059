import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import Contest from '../components/Contest';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../components/TaskView/TaskPageElements';
import { PtcHeaderWrap, PtcInfoWrap, InfoContainer, InfoWrap, TitleInfoWrap } from '../components/PtcView/PtcViewElements';
import { StackContainer, StackWrap, TitleWrap } from '../components/Stack/StackElements';
import { DescWrap } from '../components/History/DepositTabElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';
import { contestTableHead } from '../data/contestData';
import { TinyFormButton } from '../components/ButtonElements';
import MoreContestInfoModal from '../components/Contest/Modal';

const SLContestPage = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    const [moreClick, setMoreClick] = useState(false);
    const [contestIndex, setContestIndex] = useState();
    const [smallVeiw, setSmallView] = useState(false);
    const [smallVeiw2, setSmallView2] = useState(false);
    const viewSmallSize = () => {
	if (window.innerWidth < 400) {
	    setSmallView(true);
	} else if (window.innerWidth >= 400) {
	    setSmallView(false);
	};
	if (window.innerWidth < 770) {
	    setSmallView2(true);
	} else if (window.innerWidth >= 770) {
	    setSmallView2(false);
	};
    };
    useEffect(() => {
	window.addEventListener('resize', viewSmallSize);
	viewSmallSize();
	return () => {
	    window.removeEventListener('resize', viewSmallSize);
	};
    });
    useEffect(() => {
	const fetchContestInfo = async () => {
	    try {
		const { data } = await axiosInstance.get('earning/contest/sl/info/');
		setInfo(data);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchContestInfo();
    }, []);
    const handleMoreClick = (index) => {
	setMoreClick(true);
	setContestIndex(index);
    };
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && !apiError &&
		<PtcHeaderWrap>
		    <PtcInfoWrap>
			<InfoContainer>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>total</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.all_info.number_end_contest}</h2>
			        </DescWrap>
			    </InfoWrap>
			    <InfoWrap>
			        <TitleInfoWrap>
			            <h2>won</h2>
			        </TitleInfoWrap>
			        <DescWrap>
			            <h2>{info !== undefined && info.all_info.win_end_contest} PC</h2>
			        </DescWrap>
			    </InfoWrap>
			</InfoContainer>
		    </PtcInfoWrap>
		</PtcHeaderWrap>
	    }
	    {!loading && !apiError && info !== undefined && info.all_info.number_end_contest > 0 &&
		<Table>
		    <HeadWrap>
			<HeadRow>
			    {contestTableHead.map((header, index) => (
				<Header key={index}>
				    {header}
				</Header>
			    ))}
		        </HeadRow>
		    </HeadWrap>
		    <BodyWrap>
			{info.info.map((row, index) => (
			    <BodyRow key={index}>
				<Cell>
				    #{Object(info.info).length - index}
				</Cell>
				<Cell>
				    {row.reward} pc
				</Cell>
				<Cell>
				    {row.end_time.replace('-', '/').split('T')[0].replace('-', '/')}
				</Cell>
				<Cell>
				    <TinyFormButton style={{ margin: 'auto' }} primary={index % 2 === 0 ? 1 : 0} big={0} onClick={() => handleMoreClick(index)}>
					more
				    </TinyFormButton>
				</Cell>
				{moreClick && contestIndex === index &&
				    <Cell>
					<MoreContestInfoModal moreClick={moreClick}
					    setMoreClick={setMoreClick}
					    row={row}
					/>
				    </Cell>
				}
			    </BodyRow>
			))}
		    </BodyWrap>
		</Table>
	    }
	    {!loading && !apiError && info !== undefined &&
		<StackContainer primary={0} style={{ top: '0' }}>
		    <StackWrap primary={0}>
			<TitleWrap primary={0}>
			    <h2>live shortlink contest</h2>
			</TitleWrap>
			<Contest smallVeiw={smallVeiw} smallVeiw2={smallVeiw2} />
		    </StackWrap>
		</StackContainer>
	    }
	</>
    )
}
export default SLContestPage;
