import styled from 'styled-components';

export const AdscoinPromoWrap = styled.div`
    position: relative;
    width: 50px;
    cursor: pointer;
    background: rgba(39, 117, 85, 0.5);
    border: 1px solid rgba(1, 219, 187, 1);
    box-shadow: 0 2px 10px rgba(39, 117, 85, 0.7);
    border-radius: 5px;
    left: -65px;
    top: 7px;
    text-align: center;
    transition: 0.3s ease-in-out;
    &:hover {
	transform: scale(1.11);
	transition: 0.3s ease-in-out;
    }
`
export const AdscoinPromoSpan = styled.span`
    color: #01DBBB;
    font-size: 0.8rem;
    letter-spacing: 0.7px;
`
