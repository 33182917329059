import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { FiStopCircle, FiPlayCircle } from 'react-icons/fi';

export const ProfitContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const ProfitWrap = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(39, 117, 85, 0.3);
    border: 1px solid rgba(39, 117, 85, 0.4);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.7);
    border-radius: 15px;
    margin: 25px;
    @media screen and ${device.sm} {
	margin: 25px 5px;
	width: 98%;
    }
`
export const ProfitHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    background: rgba(39, 117, 85, 0.6);
    h2 { 
	color: #01DBBB;
	font-size: clamp(0.7rem, 2.4vw, 1rem);
	font-weight: 700;
	letter-spacing: 1.3px;
	text-transform: uppercase;
    }
`
export const ProfitMainWrap = styled.div`
    display: grid;
    grid-column-gap: 12%;
    grid-row-gap: 15px;
    grid-template-columns: 2fr 1fr;
    margin: 15px auto;
    @media screen and ${device.sm} {
	grid-template-columns: 1fr;
	grid-column-gap: 30px;
    };
`
export const ProfitValueWrap = styled.div`
    width: 250px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    background: rgba(39, 117, 85, 0.6);
    @media screen and ${device.md} {
	width: 200px;
	height: 70px;
    };
    @media screen and ${device.sm} {
	width: 180px;
	height: 60px;
    };
    @media screen and ${device.tn} {
	width: 150px;
	height: 55px;
    };
    h1 { 
	color: #01DBBB;
	font-size: clamp(0.8rem, 2.4vw, 1.1rem);
	font-weight: 700;
	letter-spacing: 1.3px;
	text-transform: uppercase;
    }
`
export const ValuesWrap = styled.div`
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    @media screen and ${device.tn} {
	width: 80px;
    };
`
export const SpanWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    background: rgba(29, 107, 187, 0.7);
    border: 1px solid rgba(1, 219, 187, 0.7);
    border-radius: 3px;
    span { 
	color: #01DBBB;
	font-size: clamp(0.6rem, 2.4vw, 0.75rem);
	letter-spacing: 1.1px;
    }
`
export const ButtonIcons = css`
    color: rgba(1, 219, 187, 0.85);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.95rem, 2.4vw, 1.15rem);
    position: relative;
    left: 40%;
    cursor: pointer;
`
export const StopIcon = styled(FiStopCircle)`
    ${ButtonIcons}
`
export const PlayIcon = styled(FiPlayCircle)`
    ${ButtonIcons}
`
