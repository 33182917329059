import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { Link } from 'react-router-dom';

export const Timer = styled.h1`
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 5px rgba(39, 117, 85, 0.8);
    font-size: clamp(1.3rem, 2.4vw, 1.8rem);
    letter-spacing: 1.3px;
`
export const LotteryHeaderWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const LotteryInfo = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(39, 117, 85, 0.3);
    border: 1px solid rgba(39, 117, 85, 0.4);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.7);
    border-radius: 15px;
    margin: 25px;
    @media screen and ${device.sm} {
	margin: 25px 5px;
	width: 98%;
    }
`
export const LotteryContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10%;
    grid-row-gap: 20px;
    margin: 5px auto;
    padding: 0 2%;
    @media screen and ${device.sm} {
	grid-template-columns: 1fr;
    }
`
export const LotteryInfoWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(39, 117, 85, 0.4);
    box-shadow: 0 0 5px rgba(39, 117, 85, 0.7);
    border-radius: 5px;
    padding: 5px 7px 0 7px;
`
export const LotteryTitle = styled.div`
    text-align: center;
    width: 100%;
    background: rgba(39, 117, 85, 0.6);
    h2 { 
	color: #01DBBB;
	font-size: clamp(0.7rem, 2.4vw, 1rem);
	font-weight: 700;
	letter-spacing: 1.3px;
	text-transform: uppercase;
    }
`
export const LotteryDesc = styled.div`
    text-align: center;
    padding: 10px;
    h2 { 
	color: #ffffff;
	font-size: clamp(0.8rem, 2.4vw, 1.1rem);
	font-weight: 400;
    }
`
export const ActWrap = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(39, 117, 85, 0.2);
    border: 1px solid rgba(39, 117, 85, 0.3);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.7);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 25px;
    @media screen and ${device.sm} {
	width: 98;
    }
    @media screen and ${device.tn} {
	padding: 0;
    }
`
export const ButtonWrapper = styled.div`
    width: 350px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(39, 117, 85, 0.3);
    border: 1px solid rgba(39, 117, 85, 0.4);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.7);
    border-radius: 15px;
    padding: 10px 15px;
    @media screen and ${device.sm} {
	width: 98%;
	height: 200px;
    }
`
export const BuyTicketWrap = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 25px;
    align-items: ${({ clicked }) => (clicked ? 'start' : 'center')};
    margin: auto;
    @media screen and ${device.tn} {
	grid-column-gap: 10px;
    }
`
export const ImgWrap = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(39, 117, 85, 0.3);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.7);
    border-radius: 10px;
    color: #fff;
    font-size: 0.7rem;
    @media screen and ${device.sm} {
	width: 150px;
	height: 150px;
    }
    @media screen and ${device.tn} {
	width: 120px;
	height: 120px;
    }
    img {
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 10px;
    }
`
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease-in-out;
    @media screen and ${device.sm} {
	width: 60px;
	height: 60px;
    }
    &:hover {
	transform: scale(1.09);
	transition: 0.3s ease-in-out;
    }
`
export const Button = styled.span`
    color: #fff;
    font-size: clamp(0.55rem, 1.8vw, 0.75rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#1D6BBB')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    &:hover {
	background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#277555')};
	transition: 0.3s ease-in-out;
    }
`
export const SelectWrap = styled.div`
    display: flex;
    flex-direction: column;
`
export const SelectDescWrap = styled.div`
    p {
	color: #fff;
	font-size: clamp(0.8rem, 3vw, 0.95rem);
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
	line-height: 2;
	letter-spacing: 1.2px;
	word-spacing: 1.7px;
    }
`
export const ButtonWrapperPromo = styled.div`
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(39, 117, 85, 0.4);
    border: 1px solid rgba(39, 117, 85, 0.6);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.5);
    margin: 15px auto 0 auto;
`
export const BuyTicketWrapPromo = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: ${({ big }) => (big ? '160px' : '30px')};
    align-items: center;
    margin: auto;
`
export const ImgWrapPromo = styled.div`
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(39, 117, 85, 0.3);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.7);
    border-radius: 10px;
    color: #fff;
    font-size: 0.7rem;
    img {
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 10px;
    }
`
export const ButtonWrapPromo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease-in-out;
    &:hover {
	transform: scale(1.09);
	transition: 0.3s ease-in-out;
    }
`
export const ButtonPromo = styled(Link)`
    color: #fff;
    font-size: clamp(0.55rem, 1.8vw, 0.75rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    background: #1D6BBB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    &:hover {
	background: #277555;
	transition: 0.3s ease-in-out;
    }
`
