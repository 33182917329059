import styled, { keyframes, css } from 'styled-components/macro';
import { NavWrap, Nav, NavLogo } from '../Navbar/NavbarElements';
import { Link } from 'react-router-dom';
import device from '../../constants/breakPoints';
import { AiOutlineBars } from 'react-icons/ai';
import { TiTimes } from 'react-icons/ti';
import { MdLiveHelp } from 'react-icons/md';
import { IoIosNotifications, IoMdPower } from 'react-icons/io';
import { FaSun, FaMoon } from 'react-icons/fa';

export const ANContainer = styled.div`
    background: #010606;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 0.8rem;
    position: fixed;
    z-index: 99;
`
export const ANWrap = styled(NavWrap)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const ANNav = styled(Nav)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const NavIcons = css`
    color: #fff;
    font-size: 1.2rem;
    transition: 0.3s ease-out;

    &:hover {
        color: #01DBBB;
        transition: 0.3s ease-out;
    }
`
export const HamWrap = styled.div`
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: none;

    @media screen and ${device.lg} {
        display: flex;
    };
`
export const MBHamIcon = styled.div`
    ${NavIcons}
`
export const ShowBar = styled(AiOutlineBars)``
export const CancleIcon = styled(TiTimes)``
export const CenterWrap = styled(NavLogo)`
    margin: 2px 0 0 -9px;
    @media screen and ${device.lg} {
        display: none;
    };

        img {
        width: 50px;
        height: 54px;
        float: left;
        }
`
export const RightWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 77px;
`
export const RightLink = styled(Link)`
    text-decoration: none;
`
export const HelpIcon = styled(MdLiveHelp)`
    margin-right: 2px;
    ${NavIcons}
`
export const DayIcon = styled(FaSun)`
    margin-right: 2px;
    ${NavIcons}
    font-size: 1.15rem;
`
export const NightIcon = styled(FaMoon)`
    margin-right: 2px;
    ${NavIcons}
    font-size: 0.97rem;
    margin-top: 2px;
`
export const bellAnimation = keyframes`
    0% { color: #fff; transform: rotateZ(0);}
    25%{ color: rgba(1, 219, 187, 0.8); transform: rotateZ(10deg); }
    75%{ color: #fff; transform: rotateZ(0); }
    100%{ color: rgba(1, 219, 187, 0.8); transform: rotateZ(-15deg); }
`
export const BellIcon = styled(IoIosNotifications)`
    ${NavIcons}
    @media (prefers-reduced-motion: no-preference) {
	animation: ${bellAnimation} 1s cubic-bezier(0.6, 0.45, 0.20, 0.30) 0s ${({ animation }) => (animation ? 'infinite' : '0')} forwards;
    }
`
export const PowerLink = styled.a`
    display: flex;
    justify-content: space-between;
`
export const PowerIcon = styled(IoMdPower)`
    ${NavIcons}

    &:hover {
        color: red;
    }

`
export const NumberWrap = styled.div`
    background: ${({ hover }) => (hover ? 'rgba(1, 219, 187, 0.8)' : 'rgba(29, 107, 187, 0.8)')};
    border: solid 1px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    position: absolute;
    padding: 1px 2px;
    transform: translate(-100%, -20%);
    transition: 0.3s ease-out;
    span {
        color: #ffffff;
    }
`
