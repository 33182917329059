import {
    SHOW_SPONSORSETUP,
    NOTSHOW_SPONSORSETUP,
    SHOW_SPONSORFILTERS,
    NOTSHOW_SPONSORFILTERS,
    SHOW_SPONSOREND,
    NOTSHOW_SPONSOREND,
    SHOW_SPONSORSUCCESS,
    NOTSHOW_SPONSORSUCCESS,
} from '../constants/sponsorSetUpRedux';

export const sponsorSetUpAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_SPONSORSETUP
            })
        } else {
            dispatch({
                type: NOTSHOW_SPONSORSETUP
            })
        };
    };
};

export const sponsorFiltersAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_SPONSORFILTERS
            })
        } else {
            dispatch({
                type: NOTSHOW_SPONSORFILTERS
            })
        };
    };
};

export const sponsorSetUpEndAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_SPONSOREND
            })
        } else {
            dispatch({
                type: NOTSHOW_SPONSOREND
            })
        };
    };
};

export const sponsorSuccessAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_SPONSORSUCCESS
            })
        } else {
            dispatch({
                type: NOTSHOW_SPONSORSUCCESS
            })
        };
    };
};