import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import OfferView from '../components/OfferView';
import LoadingContestOffer from '../components/ContestOffer/LoadingContest';
import OfferUserStatus from '../components/OfferUserStatus';
import { SLContainer, SLWrap, SLWrapper } from '../components/SLView/SLViewElements';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';

const OfferPage = () => {
    const [info, setInfo] = useState();
    const [level, setLevel] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    const [smallVeiw, setSmallView] = useState(false);
    const viewSmallSize = () => {
	if (window.innerWidth < 400) {
	    setSmallView(true);
	} else if (window.innerWidth >= 400) {
	    setSmallView(false);
	};
    };
    useEffect(() => {
	window.addEventListener('resize', viewSmallSize);
	viewSmallSize();
	return () => {
	    window.removeEventListener('resize', viewSmallSize);
	};
    });
    useEffect(() => {
	const fetchOffersList = async () => {
	    try {
		const { data } = await axiosInstance.get('offer/view/');
		setInfo(data.info);
		setLevel(data.level);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchOffersList();
    }, []);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && !apiError && info !== undefined &&
		<StackContainer primary={0} style={{ top: '0' }}>
		    <StackWrap primary={0}>
			<TitleWrap primary={0}>
			    <h2>guidance</h2>
			</TitleWrap>
			<StackDiscWrap>
			    <p>
			    	Don't forget that iceFaucet presents the highest conversion rate for offerwall in the market.<br />
			    	Check the offers several times a day to don't miss any offers.<br />
			        Invite your friends with your referral link to get 5% commission without reducing their income.<br />
			        Keep stay in level 5 to earn 10% more.<br />
			    	If the contest reward exceeds the user's offer earnings, the reward will not be awarded to the winner.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer>
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
		        {apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && info !== undefined && Object.keys(info.info).length > 0 && !apiError &&
		<SLContainer>
		    <SLWrap>
		        <SLWrapper>
			    {info.info.map((offer, index) => (
			    	<OfferView key={index} offer={offer} userLevel={level} />
			    ))}
		    	</SLWrapper>
		    </SLWrap>
		</SLContainer>
	    }
	    {!loading && info !== undefined && Object.keys(info.info).length > 0 && !apiError &&
		<OfferUserStatus smallVeiw={smallVeiw} />
	    }
	    {!loading && info !== undefined && Object.keys(info.info).length > 0 && !apiError &&
		<LoadingContestOffer big={1} table={true} />
	    }
	</>
    )
}
export default OfferPage;
