import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { PtcContainer, PtcWrap, PtcTitleWrap, PtcRefIcon, PtcTitle, PtcVisits, RefIcon, VisitIcon, Number, PtcContentWrap, PtcDescription, PtcSocials, SocialWrap, PtcButtonwrap, PtcTimerWrap, Timer, PtcDetailWrap } from '../PtcView/PtcViewElements';
import { TinyButtonPtc } from '../ButtonElements';
import facebook from '../../images/facebook.svg';
import whatsapp from '../../images/whatsapp.svg';
import instagram from '../../images/instagram.svg';
import youtube from '../../images/youtube.svg';
import twitter from '../../images/twitter.svg';
import tiktok from '../../images/tiktok.png';
import telegram from '../../images/telegram.png';
import pinterest from '../../images/pinterest.svg';
import reddit from '../../images/reddit.svg';
import linkedin from '../../images/linkedin.svg';
import Countdown, { zeroPad } from 'react-countdown';
import { MsgText, MsgWrap } from '../MessageElements';
import { Circle } from '../Loading';
import IcefaCaptcha from '../Captcha/IcefaCaptcha';

const SurfView = ({ ads, doneJS, setDoneJS, setErrorResult, setCompleteResult }) => {
    const leftTime = ads.timer ? ads.timer * 1000 : 0;
    const leftVisits = ads.visit - ads.visited - ads.watchers;
    const [error, setError] = useState('');
    const [errorClick, setErrorClick] = useState('');
    const [loading, setLoading] = useState(false);
    const [click, setClick] = useState(false);
    const [complete, setComplete] = useState('');
    const adsColor = ads.duration === 2.5 ? 'red' : ads.duration === 2 ? 'yellow' : ads.duration === 1.5 ? 'green' : 'blue';
    const adsDuration = ads.duration === 2.5 ? '40' : ads.duration === 2 ? '30' : ads.duration === 1.5 ? '20' : '10';
    const [duration, setDuration] = useState();
    const [dataPlay, setDataPlay] = useState();
    const [playAds, setPlayAds] = useState();
    const [isStart, setIsStart] = useState(false);
    const [stop, setStop] = useState(true);
    const [removeWatchers, setRemoveWatchers] = useState(false);
    const [windowActive, setWindowActive] = useState(false);
    const [iceCaptcha, setIceCaptcha] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const fetchClickRule = async () => {
	setClick(true);
	setLoading(true);
	try {
	    const { data } = await axiosInstance.post('ptc/view/click-rule/', {
		code: ads.code
	    });
	    data !== undefined && fetchSurfPlay(data.info);
	} catch (error) {
	    error.response && error.response.data.detail ? setErrorClick(error.response.data.detail) : setErrorClick(error.message);
	    setLoading(false);
	};
    };
    const fetchSurfPlay = async (info) => {
	try {
	    const { data } = await axiosInstance.get(`ptc/surf/${info.code}/${info.uid_enc}/${info.token_enc}/`);
	    setDataPlay(data);
	    const play_ads = window.open(ads.url, '_blank');
	    setPlayAds(play_ads);
	    setDuration(ads.duration === 2.5 ? '40' : ads.duration === 2 ? '30' : ads.duration === 1.5 ? '20' : '10');
	    setIsStart(true);
	    setStop(false);
	} catch (error) {
	    error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    setErrorResult(true);
	};
	setLoading(false);
    };
    const fetchSurfDone = async () => {
	setStop(true);
	try {
	    await axiosInstance.post('/ptc/done/surf/', {
		'id': dataPlay.id
	    });
	    setComplete(`You earned ${ads.pcoin} pc successfully.`);
	    setIsStart(false);
	    setDoneJS(doneJS + 1);
	    setCompleteResult(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    setErrorResult(true);
	};
    };
    const watcherHandler = async () => {
	setRemoveWatchers(true);
	await axiosInstance.post('ptc/remove-watchers/', {
	    code: ads.code
	});
    };
    useEffect(() => {
	if (!stop && duration === 0 && error.length === 0) {
	    if (dataPlay.need_captcha) {
	        setIceCaptcha(true);
	        return;
	    } else {
		setCaptchaDone(true);
	    };
	};
	if (!stop) {
	    const adsTimer = setTimeout(() => {
		setDuration(duration - 1);
	    }, windowActive ? 1500 : 900);
	    return () => clearTimeout(adsTimer);
	};
    }, [stop, duration, error, dataPlay, windowActive]);
    useEffect(() => {
	if (captchaDone && !stop) {
	    !removeWatchers && watcherHandler();
	    fetchSurfDone();
	};
    });
    useEffect(() => {
	if (captchaDone) {
	    setIceCaptcha(false);
	};
    }, [captchaDone]);
    const handleClick = (e) => {
	e.preventDefault();
	!loading && !click && fetchClickRule();
    };
    const handleClick2 = (e) => {
	e.preventDefault();
	setErrorClick('You should watch the ads that already have been clicked.')
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    const renderer = ({ hours, minutes, seconds }) => {
	return <Timer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    const handleActivity = (forcedFlag) => {
	if (typeof forcedFlag === 'boolean') {
	    return forcedFlag ? setWindowActive(true) : setWindowActive(false);
	};
	return document.hidden ? setWindowActive(false) : setWindowActive(true);
    };
    useEffect(() => {
	const handleActivityFalse = () => handleActivity(false);
	const handleActivityTrue = () => handleActivity(true);
	document.addEventListener('visibilitychange', handleActivity);
	document.addEventListener('blur', handleActivityFalse);
	window.addEventListener('blur', handleActivityFalse);
	window.addEventListener('focus', handleActivityTrue);
	document.addEventListener('focus', handleActivityTrue);
	return () => {
	    document.removeEventListener('visibilitychange', handleActivity);
	    document.removeEventListener('blur', handleActivityFalse);
	    window.removeEventListener('blur', handleActivityFalse);
	    window.removeEventListener('focus', handleActivityTrue);
	    document.removeEventListener('focus', handleActivityTrue);
	};
    });
    useEffect(() => {
	if (playAds && playAds.closed && duration > 0 && dataPlay !== undefined && !(dataPlay.device === 'M' && (dataPlay.url.toLowerCase().includes('https://t.me') || dataPlay.url.toLowerCase().includes('https://telegram.me')))) {
	    setStop(true);
	    setError('you closed the ads!');
	    setErrorResult(true);
	};
    }, [playAds, duration, setErrorResult, dataPlay]);
    useEffect(() => {
	if (error && !removeWatchers) {
	    watcherHandler();
	};
    });
    useEffect(() => {
	document.title = isStart && duration > 0
	    ? `${duration} sec`
	    : isStart && duration === 0 && error.length === 0
	        ? 'successfully earned' :
		'iceFaucet';
	return () => document.title = 'iceFaucet';
    }, [duration, isStart, error]);
    return (
	<>
	    <PtcContainer color={adsColor} visitable={ads.visitable ? 1 : 0}>
	        <PtcWrap color={adsColor}>
	    	    <PtcTitleWrap refshow={ads.refshow ? 1 : 0} color={adsColor}>
	    		{ads.refshow
			    && <PtcRefIcon>
				<RefIcon coloricon={adsColor} />
			    </PtcRefIcon>
			}
	    		<PtcTitle refshow={ads.refshow ? 1 : 0} color={adsColor}>
	                    <h2>{ads.title}</h2>
	                </PtcTitle>
	    		<PtcVisits>
	                    <VisitIcon coloricon={adsColor} />
	                    <Number color={adsColor}>
	                        {999 < ads.visited && ads.visited <= 999999
				    ? (ads.visited / 1000).toFixed(1) + 'k'
				    : 999999 < ads.visited
					? (ads.visited / 1000000).toFixed(3) + 'm'
					: ads.visited
				}
	                    </Number>
	                </PtcVisits>
	    	    </PtcTitleWrap>
	    	    <PtcContentWrap>
	    		{ads.description
			    && <PtcDescription color={adsColor}>
				<p>{ads.description}</p>
			    </PtcDescription>
			}
	    		{ads.social1 || ads.social2 || ads.social3
			    ? <PtcSocials state={ads.description ? 1 : 0}>
				{ads.social1
				    && <SocialWrap href={ads.social1 && ads.social1} target='_blank' rel='external' color={adsColor}>
					{ads.social1.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
					{ads.social1.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
				    </SocialWrap>
				}
				{ads.social2
				    && <SocialWrap href={ads.social2 && ads.social2} target='_blank' rel='external' color={adsColor}>
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
					{ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
				    </SocialWrap>
				}
				{ads.social3
				    && <SocialWrap href={ads.social3 && ads.social3} target='_blank' rel='external' color={adsColor}>
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
					{ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
				    </SocialWrap>
				}
			    </PtcSocials>
			    : null
			}
	    	    </PtcContentWrap>
	    	    {!iceCaptcha &&
		    	<PtcDetailWrap color={adsColor} show={ads.visitable && leftVisits > 0 ? 1 : 0}>
	    		    {ads.visitable && leftVisits > 0 && <span>{adsDuration} sec</span>}
	    		    {ads.visitable && leftVisits > 0 && !isStart
			        ? <PtcButtonwrap>
				    <TinyButtonPtc
				        to='#'
				    	onClick={click ? handleClick2 : handleClick}
				    	onContextMenu={handleRightClick}
				    	color={adsColor}
				    	big={0}
				    >
				    	{!loading ? `visit(${ads.watchers})` : <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} />}
				    </TinyButtonPtc>
			    	</PtcButtonwrap>
			        : ads.visitable && leftVisits > 0 && isStart
				    ? <PtcButtonwrap>
				        <TinyButtonPtc to='#'
				            onContextMenu={handleRightClick}
				            color={adsColor}
				            big={0}
				        >
					    {duration} sec
				        </TinyButtonPtc>
				    </PtcButtonwrap>
				    : ads.visitable && leftVisits <= 0
				        ? <PtcButtonwrap>
					    <TinyButtonPtc to='#' color={adsColor} big={0}>
					    	please wait...
					    </TinyButtonPtc>
				        </PtcButtonwrap>
				        : leftTime > 0
				            ? <PtcTimerWrap>
					        <Countdown
				                    date={Date.now() + leftTime}
				                    renderer={renderer}
				                />
					    </PtcTimerWrap>
					    : null
			    }
	    		    {ads.visitable && leftVisits > 0 && <span>{ads.pcoin} pc</span>}
	    	    	</PtcDetailWrap>
		    }
	    	    {iceCaptcha && !captchaDone &&
			<IcefaCaptcha appName='surf' setCaptchaDone={setCaptchaDone} dark={0} />
		    }
	    	    {complete &&
			<MsgWrap primary={1}>
			    <MsgText primary={1}>
			        {complete}
			    </MsgText>
			</MsgWrap>
		    }
	    	    {error &&
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
				{error}
			    </MsgText>
			</MsgWrap>
		    }
	    	    {errorClick &&
			<MsgWrap primary={0}>
			    <MsgText primary={0}>
				{errorClick}
			    </MsgText>
			</MsgWrap>
		    }
	    	</PtcWrap>
	    </PtcContainer>
	</>
    )
}
export default SurfView;
