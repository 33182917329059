import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { headerOpenReducer, headerScrollReducer } from './reducers/headerReducer';
import { hoverButtonReducer } from './reducers/hoverButtonReducer';
import { showMainbarReducer } from './reducers/showMainbarReducer';
import { loginTabReducer, regTabReducer, activateCodeReducer, confirmRegReducer, resetPasswordReducer, resetCodeReducer, resetPasswordFormReducer } from './reducers/authReducer';
import { creditCheckReducer, ptcSetUpReducer, ptcFiltersReducer, ptcFilters2Reducer, ptcFilters3Reducer, ptcSetUpEndReducer, ptcSuccessReducer } from './reducers/PtcSetUpReducer';
import { bannerSetUpReducer, bannerFiltersReducer, bannerFilters2Reducer, bannerSuccessReducer } from './reducers/bannerSetUpReducer';
import { sponsorSetUpReducer, sponsorFiltersReducer, sponsorSetUpEndReducer, sponsorSuccessReducer } from './reducers/sponsorSetUpReducer';
import { miningSetUpReducer, miningSetUpEndReducer, miningSuccessReducer } from './reducers/miningSetUpReducer';

const reducer = combineReducers({
    isOpen: headerOpenReducer,
    scrollNav: headerScrollReducer,
    hoverButton: hoverButtonReducer,
    loginTab: loginTabReducer,
    regTab: regTabReducer,
    confirmReg: confirmRegReducer,
    activateCode: activateCodeReducer,
    resetPassword: resetPasswordReducer,
    resetCode: resetCodeReducer,
    resetPasswordForm: resetPasswordFormReducer,
    showMainbar: showMainbarReducer,
    creditCheck: creditCheckReducer,
    ptcSetUp: ptcSetUpReducer,
    ptcFilters: ptcFiltersReducer,
    ptcFilters2: ptcFilters2Reducer,
    ptcFilters3: ptcFilters3Reducer,
    ptcSetUpEnd: ptcSetUpEndReducer,
    ptcSuccess: ptcSuccessReducer,
    bannerSetUp: bannerSetUpReducer,
    bannerFilters: bannerFiltersReducer,
    bannerFilters2: bannerFilters2Reducer,
    bannerSuccess: bannerSuccessReducer,
    sponsorSetUp: sponsorSetUpReducer,
    sponsorFilters: sponsorFiltersReducer,
    sponsorSetUpEnd: sponsorSetUpEndReducer,
    sponsorSuccess: sponsorSuccessReducer,
    miningSetUp: miningSetUpReducer,
    miningSetUpEnd: miningSetUpEndReducer,
    miningSuccess: miningSuccessReducer
})
const initialState = {}
const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;
