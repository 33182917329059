import styled, { css, keyframes } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { IoHeartOutline, IoHeartSharp } from 'react-icons/io5';
import { IoIosHelpCircleOutline, IoIosHelpCircle } from 'react-icons/io';

export const SLContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(29, 107, 187, 0.2);
    padding: 25px;
`
export const SLWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 10px 0;
    overflow: auto;
    &::-webkit-scrollbar {
	width: 8px;
	height: 8px;
    }
    &::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px rgba(29, 107, 187, 0.5); 
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
	background: rgba(29, 107, 187, 0.6);
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
	background: rgba(29, 107, 187, 0.9);
    }
`
export const SLWrapper = styled.div`
    display: grid;
    grid-template-columns : 1fr;
    grid-row-gap: 30px;
    @media screen and ${device.xl} {
	grid-template-columns : 1fr 1fr 1fr;
    }
`
export const SLCard = styled.div`
    background: rgba(29, 107, 187, 0.5);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 2px 10px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    opacity: ${({ active }) => (active ? '1' : '0.35')};
`
export const LevelInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`
export const DescSpan = styled.span`
    margin-left: 9px;
    color: #fff;
    font-size: 0.8rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    line-height: 1.1;
    letter-spacing: 1px;
    word-spacing: 1.2px;
`
export const NameWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(39, 117, 85, 0.7);
    border-radius: 5px 5px 0 0;
    h4 {
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: clamp(0.8rem, 2.4vw, 0.9rem);
	font-weight: 400;
	letter-spacing: 1.2px;
	word-spacing: 1.7px;
	text-transform: uppercase;
	padding: 7px 3px;
    }
`
export const ProgressBarWrap = styled.div`
    width: 92%;
    outline: 2px solid rgba(1, 219, 187, 0.5);
    box-shadow: 0 0 5px rgba(1, 219, 187, 0.8);
    border-radius: 0 0 30px 0;
    margin: 10px auto 0 auto;
`
export const ProgressBar = styled.div`
    width: ${({ width }) => (width)};
    height: 6px;
    background: rgba(1, 219, 187, 0.7);
    border-radius: 0 0 30px 0;

    span {
	position: relative;
	left: 130px;
	top: 7px;
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: clamp(0.7rem, 2.4vw, 0.8rem);
    }
`
export const ContentWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
    margin: 15px auto;
`
export const LevelPriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: ${({ promo }) => (promo ? '75px' : '50px')};
    justify-content: space-between;
    margin-top: 10px;
`
export const SpanWrapper = styled.div`
    background: rgba(39, 117, 85, 0.7);
    border: 1px solid rgba(39, 117, 85, 0.3);
    box-shadow: 0 0 5px rgba(39, 117, 85, 0.9);
    border-radius: 5px;
    padding: 0 10px;
    text-align: center;
    span {
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: clamp(0.8rem, 2.4vw, 0.9rem);
    }
`
export const Buttonwrap = styled.div`
    display: grid;
    grid-column-gap: 7px;
    grid-template-columns: 1fr 4fr 1fr;
`
export const Icons = css`
    opacity: 0.75;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s ease-out;
    &:hover {
	opacity: 1;
	transition: 0.3s ease-out;
    }
`
export const HeartIcon = styled(IoHeartOutline)`
    color: #fff;
    ${Icons}
`
export const HeartClickedIcon = styled(IoHeartSharp)`
    color: rgba(1, 219, 187, 1);
    ${Icons}
`
export const helpAnimation = keyframes`
    0% { color: #fff; transform: scale(0.95);}
    15%{ color: rgba(1, 219, 187, 0.8); transform: scale(1.05); }
    30%{ color: #fff; transform: scale(0.95); }
    45%{ color: rgba(1, 219, 187, 0.8); transform: scale(1.05); }
    100%{ color: transform: scale(1); }
`
export const HelpIcon = styled(IoIosHelpCircleOutline)`
    color: #fff;
    @media (prefers-reduced-motion: no-preference) {
	animation: ${helpAnimation} 1.7s cubic-bezier(0.6, 0.45, 0.20, 0.30) 1s ${({ animation }) => (animation ? 'infinite' : '0')} forwards;
    }
    ${Icons}
`
export const HelpClickedIcon = styled(IoIosHelpCircle)`
    color: rgba(1, 219, 187, 1);
    ${Icons}
`
export const CardContainer = styled.div`
    width: 100%;
    height: 165px;
`
export const IconContainer = styled.div`
    width: 100%;
    height: 165px;
    padding: 10px 5px;
    overflow: auto;
    @media screen and ${device.xl} {
        grid-template-columns : 1fr 1fr 1fr;
    }
    &::-webkit-scrollbar {
	width: 8px;
	height: 8px;
    }
    &::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px rgba(29, 107, 187, 0.5); 
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
	background: rgba(29, 107, 187, 0.6);
	border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
	background: rgba(29, 107, 187, 0.9);
    }
`
export const Text = styled.p`
    color: #fff;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    font-size: clamp(0.75rem, 2.4vw, 0.85rem);
`
