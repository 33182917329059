import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { PtcContainer, PtcWrap, PtcTitleWrap, PtcRefIcon, PtcTitle, PtcVisits, RefIcon, VisitIcon, Number, PtcContentWrap, PtcDescription, PtcSocials, SocialWrap, PtcButtonwrap, PtcTimerWrap, Timer, PtcDetailWrap } from './PtcViewElements';
import { TinyButtonPtc } from '../ButtonElements';
import facebook from '../../images/facebook.svg';
import whatsapp from '../../images/whatsapp.svg';
import instagram from '../../images/instagram.svg';
import youtube from '../../images/youtube.svg';
import twitter from '../../images/twitter.svg';
import tiktok from '../../images/tiktok.png';
import telegram from '../../images/telegram.png';
import pinterest from '../../images/pinterest.svg';
import reddit from '../../images/reddit.svg';
import linkedin from '../../images/linkedin.svg';
import Countdown, { zeroPad } from 'react-countdown';
import { MsgText, MsgWrap } from '../MessageElements';
import { Circle } from '../Loading';

const PtcView = ({ ads }) => {
    let history = useHistory();
    const leftTime = ads.timer ? ads.timer * 1000 : 0;
    const leftVisits = ads.visit - ads.visited - ads.watchers;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [click, setClick] = useState(false);
    const adsColor = ads.duration === 2.5 ? 'red' : ads.duration === 2 ? 'yellow' : ads.duration === 1.5 ? 'green' : 'blue';
    const adsDuration = ads.duration === 2.5 ? '40' : ads.duration === 2 ? '30' : ads.duration === 1.5 ? '20' : '10';
    const fetchClickRule = async () => {
	setClick(true);
	setLoading(true);
        try {
            const { data } = await axiosInstance.post('ptc/view/click-rule/', {
                code: ads.code
            });
            history.push(`/play/ptc/${data.info.code}/${data.info.uid_enc}/${data.info.token_enc}/`);
        } catch (error) {
            error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
        };
	setLoading(false);
    };
    const handleClick = (e) => {
	e.preventDefault();
        !loading && !click && fetchClickRule();
    };
    const handleClick2 = (e) => {
        e.preventDefault();
        setError('You should watch the ads that already have been clicked.')
    };
    const handleRightClick = (e) => {
        e.preventDefault();
    };
    const renderer = ({ hours, minutes, seconds }) => {
        return <Timer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    return (
        <>
            <PtcContainer color={adsColor} visitable={ads.visitable ? 1 : 0}>
                <PtcWrap color={adsColor}>
                    <PtcTitleWrap refshow={ads.refshow ? 1 : 0} color={adsColor}>
                        {ads.refshow
                            && <PtcRefIcon>
                                <RefIcon coloricon={adsColor} />
                            </PtcRefIcon>
                        }
                        <PtcTitle refshow={ads.refshow ? 1 : 0} color={adsColor}>
                            <h2>{ads.title}</h2>
                        </PtcTitle>
                        <PtcVisits>
                            <VisitIcon coloricon={adsColor} />
                            <Number color={adsColor}>
                                {
                                    999 < ads.visited && ads.visited <= 999999
                                        ? (ads.visited / 1000).toFixed(1) + 'k'
                                        : 999999 < ads.visited
                                            ? (ads.visited / 1000000).toFixed(3) + 'm'
                                            : ads.visited
                                }
                            </Number>
                        </PtcVisits>
                    </PtcTitleWrap>
                    <PtcContentWrap>
                        {ads.description
                            && <PtcDescription color={adsColor}>
                                <p>{ads.description}</p>
                            </PtcDescription>
                        }
                        {ads.social1 || ads.social2 || ads.social3
                            ? <PtcSocials state={ads.description ? 1 : 0}>
                                {ads.social1
                                    && <SocialWrap href={ads.social1 && ads.social1} target='_blank' rel='external' color={adsColor}>
                                        {ads.social1.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
                                        {ads.social1.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
                                    </SocialWrap>
                                }
                                {ads.social2
                                    && <SocialWrap href={ads.social2 && ads.social2} target='_blank' rel='external' color={adsColor}>
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
                                        {ads.social2 && ads.social2.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
                                    </SocialWrap>
                                }
                                {ads.social3
                                    && <SocialWrap href={ads.social3 && ads.social3} target='_blank' rel='external' color={adsColor}>
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('facebook') ? <img src={facebook} alt={'facebook'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('whatsapp') ? <img src={whatsapp} alt={'whatsapp'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('instagram') ? <img src={instagram} alt={'instagram'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('youtube') ? <img src={youtube} alt={'youtube'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('twitter') ? <img src={twitter} alt={'twitter'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('tiktok') ? <img src={tiktok} alt={'tiktok'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('telegram') ? <img src={telegram} alt={'telegram'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('pinterest') ? <img src={pinterest} alt={'pinterest'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('reddit') ? <img src={reddit} alt={'reddit'} /> : null}
                                        {ads.social3 && ads.social3.toLowerCase().split('/')[2].includes('linkedin') ? <img src={linkedin} alt={'linkedin'} /> : null}
                                    </SocialWrap>
                                }
                            </PtcSocials>
                            : null
                        }
                    </PtcContentWrap>
	    	    <PtcDetailWrap color={adsColor} show={ads.visitable && leftVisits > 0 ? 1 : 0}>
	    		{ads.visitable && leftVisits > 0 && <span>{adsDuration} sec</span>}
                        {ads.visitable && leftVisits > 0
                            ? <PtcButtonwrap>
                                <TinyButtonPtc
                                    to='#'
                                    onClick={click ? handleClick2 : handleClick}
                                    onContextMenu={handleRightClick}
                                    color={adsColor}
                                    big={0}
                                >
			            {!loading ? `visit(${ads.watchers})` : <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} />}
                                </TinyButtonPtc>
                            </PtcButtonwrap>
                            : ads.visitable && leftVisits <= 0
                                ? <PtcButtonwrap>
                                    <TinyButtonPtc to='#' color={adsColor} big={0}>
                                        please wait...
                                    </TinyButtonPtc>
                                </PtcButtonwrap>
                                : <PtcTimerWrap>
                                    <Countdown
                                        date={Date.now() + leftTime}
                                        renderer={renderer}
                                    />
                                </PtcTimerWrap>
                        }
	    		{ads.visitable && leftVisits > 0 && <span>{ads.pcoin} pc</span>}
	    	    </PtcDetailWrap>
                    {error &&
                        <MsgWrap primary={0}>
                            <MsgText primary={0}>
                                {error}
                            </MsgText>
                        </MsgWrap>
                    }
                </PtcWrap>
            </PtcContainer>
        </>
    )
}
export default PtcView;
