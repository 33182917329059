import styled from 'styled-components';

export const TimerWrap = styled.div`
    margin-top: 15px;
    padding: 5px;
    background: rgba(39, 117, 85, 0.4);
    border: 1px solid rgba(1, 219, 187, 0.3);
    box-shadow: 0 0 10px rgba(1, 219, 187, 0.7);
`
export const TimerCode = styled.span`
    color: #01DBBB;
    text-shadow: text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: 1.2rem;
    text-align: center;
`