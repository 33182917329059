export const
    ValidateEdit = (values) => {
        let errors = {};

        if (!values.title) {
            errors.title = 'The title is required.'
        } else if (values.title.length >= 65 || values.title.length <= 31) {
            errors.title = "The title needs to be 32-64 characters."
        } else if (/^[0-9]+$/i.test(values.title)) {
            errors.title = 'Your title shouldn\'t be entirely numbers.'
        };

        if (values.description !== undefined && values.description.length >= 257) {
            errors.description = 'The description needs to be less than 256 characters.'
        };
        return errors
    };
