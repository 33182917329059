export const privacyData = {
    subject: 'privacy policy',
    abstract: 'At iceFaucet, your privacy is important to us. This privacy policy describes how iceFaucet store, use, process and share your personal information.',
    card: [
        {
            title: 'personal information',
            desc: [
                'To create an account, we will collect your email and password. This information is used for authentication and authorization.',
                'Your email address will not be shown, given or sold to anyone.',
                'We will use your email address in order to send some necessary information and marketing messages. ',
                'Your password is stored encrypted so no one has access it.',
                'Icefaucet.com will use your username and profile image to introduce you to other users. They are optional to provide.',
            ]
        },
        {
            title: 'extra information and cookies',
            desc: [
                'Icefaucet.com will store the registration date, last login date, IP address, and some information about your device and network. We use this information to operate, maintain and secure our website and service.',
                'The third party service providers may collect information such as country location.',
                'All user activities save on Icefaucet database. This information is closely monitored in order to prevent fraud and advertising target.',
                'Icefaucet.com will use the cookies to save certain settings that are important to the account usage.',
                'Cookies are used for various tracking purposes. These do not contain any personal information.'
            ]
        },
        {
            title: 'rights and rules',
            desc: [
                'We do not knowingly collect personal information from anyone under the age of 16. If you are under the age of 16, do not register an account.',
                'We do not share or disclose any of your personal information without your consent.',
                'We may update and change this privacy policy from time to time by publishing a new version on our website.',
                'You have the right to access any personal information that Icefaucet.com processes about you.',
                'You have the right to contact us if you need further help understanding this policy or concerns about your personal data.',
                'You have the right to opt out of your email from the marketing messages.'

            ]
        }
    ]
};
