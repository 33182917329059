import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const OffersContainer = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    grid-row-gap: 30px;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 10px;
    @media screen and ${device.lg} {
	grid-template-columns : 1fr 1fr;
    }
    @media screen and ${device.sm} {
	grid-template-columns : 1fr;
    }
`
export const Card = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : 'rgba(39, 117, 85, 0.6)')};
    border: 1px solid rgba(1, 219, 187, 0.8);
    box-shadow: 0 1px 7px rgba(1, 219, 187, 0.5);
    border-radius: 5px;
    width: 200px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 5px;
    @media screen and ${device.tn} {
        width: 180px;
    }
`
export const ContentWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 160px;
`
export const LeftStuff = styled.div`
    display: flex;
    justify-content: space-between;
`
export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ top }) => (top ? 'space-between' : 'center')};
    height: 55px;
`
export const TextWrap = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : 'rgba(39, 117, 85, 0.6)')};
    border: 2px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    box-shadow: 0 1px 5px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.9)' : 'rgba(39, 117, 85, 0.9)')};
    width: 70px;
    border-radius: 5px;
    padding: 2px;
    text-align: center;
    h4 {
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 1px rgba(1, 219, 187, 0.8);
	font-size: clamp(0.7rem, 2.4vw, 0.8rem);
    }
`
