export const bannerTypeData = [
    {
        name: 'I',
        title: 'image'
    },
    {
        name: 'H',
        title: 'html5'
    }
];

export const bannerSizeData = [
    {
        size: '320x50',
        device: 'mobile',
        db_name: 'A'
    },
    {
        size: '320x100',
        device: 'mobile',
        db_name: 'B'
    },
    {
        size: '300x250',
        device: 'both',
        db_name: 'C'
    },
    {
        size: '728x90',
        device: 'desktop',
        db_name: 'D'
    },
    {
        size: '160x600',
        device: 'desktop',
        db_name: 'E'
    },
    {
        size: '300x600',
        device: 'desktop',
        db_name: 'F'
    }
];