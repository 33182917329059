import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { AIContainer } from '../AccountInfo/AccountInfoElements';
import { IcedayTimer, NewWrap, NewItemWrap, NewItemCard, LastCliam, CircleWrap, Title, WraperItem, WrapItem, MembershipWrap, ImgWrap, RangeContainer, RangeWrap, RangeFill, ValueWrap, Value, MaxValue, ThunderContainer, ThunderWrap, ThunderItem } from './SpeedometerElements';
import omega from '../../images/omega.png';
import beta from '../../images/beta.png';
import alpha from '../../images/alpha.png';
import { HelpIcon, HelpWrap } from '../HelpElements';
import Countdown, { zeroPad } from 'react-countdown';
import { TinyFormButton } from '../ButtonElements';
import { Circle } from '../Loading';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from '../Stack/RadialSeparators';
import { MsgWrap, MsgText } from '../MessageElements';
import SeparateRect from '../SeparateRect';
import { BannerFaucetWrap } from '../DesktopBanner/DesktopBannerElements';
import { SMData } from '../../data/faucetData';
import SMCards from './SMCards';

const Speedometer = ({ values, numClaim, snow, iceday, icedayExpireTimer, setIcedayExpireTimer, lastIcedayClaim, setLastIcedayClaim, buttonTimer, banner }) => {
    const history = useHistory();
    const [showHelpSnow, setShowHelpSnow] = useState(false);
    const [showHelpThunder, setShowHelpThunder] = useState(false);
    const [showHelpIceday, setShowHelpIceday] = useState(false);
    const [showHelpMembership, setShowHelpMembership] = useState(false);
    const [error, setError] = useState('');
    const [thunderLoading, setThunderLoading] = useState(false);
    const [thunderActive, setThunderActive] = useState(false);
    const handleClickSnow = () => {
	setShowHelpSnow(!showHelpSnow);
	setShowHelpThunder(false);
	setShowHelpIceday(false);
    };
    const handleClickThunder = () => {
	setShowHelpThunder(!showHelpThunder);
	setShowHelpSnow(false);
	setShowHelpIceday(false);
    };
    const handleClickIceday = () => {
	setShowHelpIceday(!showHelpIceday);
	setShowHelpSnow(false);
	setShowHelpThunder(false);
    };
    const handleBackClick = () => {
	setShowHelpIceday(false);
	setShowHelpSnow(false);
	setShowHelpThunder(false);
    };
    const handleThunderClick = async () => {
	if (values.thunder > 0) {
	    setThunderLoading(true);
	    try {
		await axiosInstance.get('faucet/thunder/active/');
		setThunderActive(true);
		setTimeout(() => history.go(0), 1000);
	    } catch (error) {
		error.response && error.response.data.detail ? setError(error.response.data.detail) : setError(error.message);
	    };
	    setThunderLoading(false);
	} else {
	    setError('Your thunder balance is 0.');
	};
    };
    const handleClickMembership = () => {
	setShowHelpMembership(!showHelpMembership);
    };
    const renderer2 = ({ hours, minutes, seconds }) => {
	return <IcedayTimer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</IcedayTimer>;
    };
    const fetchBannerClicked = async (id) => {
	await axiosInstance.post('banner/clicked/', {
	    'id': id
	});
    };
    const handleBannerClicked = (id) => {
	fetchBannerClicked(id);
    };
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    return (
        <AIContainer>
            <WraperItem>
	    	<NewWrap help={!showHelpSnow && !showHelpIceday && !showHelpThunder ? 0 : 1}>
	            <HelpWrap style={{ margin: '0 auto', flexDirection: 'column' }} show={showHelpSnow && !showHelpIceday && !showHelpThunder ? 1 : 0}>
	                <p>
	                    One two three four SNOW! It usually makes your reward double, enjoy it.<br />
	                    The mathematical formula to calculate your faucet claim:<br />
	                    (1 + (%daily turbo + %view ads turbo + %offer turbo + %membership turbo) / 100) * base reward(10 PC) * snow
	                </p>
	                <TinyFormButton big={0} primary={1} style={{ width: '100px', margin: '10px auto' }} onClick={handleBackClick}>
	                    back
	                </TinyFormButton>
	            </HelpWrap>
	    	    <HelpWrap style={{ margin: '0 auto', flexDirection: 'column' }} show={showHelpThunder && !showHelpSnow && !showHelpIceday ? 1 : 0}>
	        	<p>
	                    Spin the lucky wheel to win the thunder. <br />
	            	    If you have a thunder, press the "ON" button to activate it. <br />
	            	    By activating a thunder, you can claim 5 times instantly.
	                </p>
	        	<TinyFormButton big={0} primary={1} style={{ width: '100px', margin: '10px auto' }} onClick={handleBackClick}>
	            	    back
	        	</TinyFormButton>
	    	    </HelpWrap>
	    	    <HelpWrap style={{ margin: '0 auto', flexDirection: 'column' }} show={showHelpIceday && !showHelpSnow && !showHelpThunder ? 1 : 0}>
	        	<p>
	            	    For every thousand times you claim, you can have an ice day. This is really exciting because you can claim every 10 minutes for 12 hours. Of course, on some special days, the ice day is activated for all members by admin. <br />
	                    Your number claim: {numClaim}
	        	</p>
	        	<TinyFormButton big={0} primary={1} style={{ width: '100px', margin: '10px auto' }} onClick={handleBackClick}>
	            	    back
	        	</TinyFormButton>
	    	    </HelpWrap>
	    	    {!showHelpSnow && !showHelpThunder && !showHelpIceday &&
			<>
	    	    	    <NewItemCard activate={snow ? 1 : 0}>
	    		        {(!snow) &&
			    	    <HelpIcon style={{ left: '30px' }} onClick={() => handleClickSnow()} show={showHelpSnow ? 1 : 0} />
				}
	    			<CircleWrap>
	                    	    <Title activate={snow ? 1 : 0}>snow</Title>
	                	</CircleWrap>
	    			{!snow &&
			    	    <NewItemWrap>
				        <CircularProgressbarWithChildren
				            value={numClaim % 5 * 20}
				    	    maxValue={100}
				    	    text={0}
				    	    strokeWidth={6}
				    	    styles={buildStyles({
						strokeLinecap: "butt",
						textColor: 'rgba(1, 219, 187, 0.7)',
						pathColor: 'rgba(1, 219, 187, 0.7)',
						textSize: '1.2rem'
				    	    })}
				        >
				    	    <RadialSeparators
				        	count={5}
				        	style={{
					    	    background: "#fff",
					            width: "2px",
					            height: `${6}%`
						}}
				            />
			        	</CircularProgressbarWithChildren>
			    	    </NewItemWrap>
		                }
	            	    </NewItemCard>
	            	    {!error && !thunderActive &&
				<ThunderContainer active={values.thunder_active > 0 ? 1 : 0}>
			    	    {!values.thunder_active &&
					<>
				    	    <ThunderWrap>
						{[...Array(7)].map((x, i) => (
					    	    <ThunderItem key={i} active={i < values.thunder ? 1 : 0} />
						))}
						<HelpIcon style={{ width: '14px', height: '14px', top: '-10px' }} onClick={() => handleClickThunder()} show={showHelpThunder ? 1 : 0} />
				    	    </ThunderWrap>
				    	    {thunderLoading ? <Circle style={{ width: '15px', height: '15px', margin: '10px auto' }} /> :
						<TinyFormButton big={0} primary={1} style={{ margin: 'auto', padding: '3px 20px' }} onClick={handleThunderClick}>
					    	    on
						</TinyFormButton>
				    	    }
					</>
			    	    }
			    	    {values.thunder_active &&
					<>
				    	    <h2>left claims:</h2>
				    	    <SeparateRect currentNumber={5 - values.thunder_stage} maxNumber={5} />
					</>
			    	    }
				</ThunderContainer>
		    	    }
	    	    	    {thunderActive &&
				<MsgWrap primary={1} style={{ width: '77%', margin: 'auto' }}>
			    	    <MsgText primary={1}>
			        	Thunder is activated successfully.
			    	    </MsgText>
				</MsgWrap>
	    	    	    }
	    	    	    {error &&
				<MsgWrap primary={0} style={{ width: '77%', margin: 'auto' }}>
			    	    <MsgText primary={0}>
			        	{error}
		            	    </MsgText>
				</MsgWrap>
	    	    	    }
	            	    <NewItemCard activate={iceday ? 1 : 0}>
	                	{!iceday &&
			    	    <HelpIcon style={{ left: '30px' }} onClick={() => handleClickIceday()} show={showHelpIceday ? 1 : 0} />
		        	}
	                	{iceday && icedayExpireTimer > 0 && buttonTimer === 0 &&
			    	    <Countdown
			            	date={Date.now() + (icedayExpireTimer - 10) * 1000}
			        	renderer={renderer2}
			        	onComplete={() => { setIcedayExpireTimer(0); setLastIcedayClaim(true) }}
			    	    />
		        	}
	                	{iceday && icedayExpireTimer <= 0 && lastIcedayClaim &&
			    	    <LastCliam>
			        	<h3>last claim</h3>
			    	    </LastCliam>
		        	}
	                	<CircleWrap>
	                    	    <Title activate={iceday ? 1 : 0}>ice day</Title>
	                	</CircleWrap>
	    	    		{!iceday &&
			    	    <NewItemWrap>
					<CircularProgressbarWithChildren
				    	    value={numClaim % 1000 * 0.1}
				    	    maxValue={100}
				    	    text={0}
				    	    strokeWidth={6}
				    	    styles={buildStyles({
						strokeLinecap: "butt",
						textColor: 'rgba(1, 219, 187, 0.7)',
						pathColor: 'rgba(1, 219, 187, 0.7)',
						textSize: '1.2rem'
				    	    })}
					>
					</CircularProgressbarWithChildren>
			    	    </NewItemWrap>
		        	}
	            	    </NewItemCard>
			</>
		    }
	        </NewWrap>
                <WrapItem>
                    {SMData.map((item, index) => (
			<SMCards info={item} values={values} buttonTimer={buttonTimer} />
		    ))}
                </WrapItem>
	        {banner !== undefined && banner.p1 !== null && banner.plan === 'A' &&
		    <div style={{ display: 'flex', margin: '15px auto 0 auto' }}>
			<BannerFaucetWrap href={banner.p1.url} target='_blank' onClick={() => handleBannerClicked(banner.p1.banner_id)} onContextMenu={handleRightClick}>
			    <img src={banner.p1.banner_img ? banner.p1.banner_img : banner.p1.banner_url ? banner.p1.banner_url : null} alt={banner.p1.title} title={banner.p1.url.split('/')[2]} />
			</BannerFaucetWrap>
		    </div>
		}
	        {banner !== undefined && banner.p1 !== null && banner.plan === 'B' &&
		    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px auto 0 auto' }}>
			<iframe title='cryptocoinsad'
		            src={banner.p1.src}
		            style={{ width: `${banner.p1.width}px`, height: `${banner.p1.height}px`, border: '0px', padding: '0', overflow: 'hidden', backgroundColor: 'transparent' }}>
			</iframe>
		    </div>
	        }
                <MembershipWrap help={!showHelpMembership ? 0 : 1}>
	    	    <HelpWrap style={{ marginBottom: '10px', flexDirection: 'column' }} show={showHelpMembership ? 1 : 0}>
	            	<p>
	                    Upgrade your membership to earn more. Beta users earn +200% and alpha users earn +500%.
	                </p>
	                <TinyFormButton big={0} primary={1} style={{ width: '100px', margin: '10px auto' }} onClick={() => setShowHelpMembership(false)}>
	                    back
	                </TinyFormButton>
	            </HelpWrap>
	            {!showHelpMembership &&
			<>
                    	    <ImgWrap>
                        	<img src={values.membership === 0.75 ? beta : values.membership === 1.0 ? alpha : omega} alt={omega} />
                    	    </ImgWrap>
                    	    <RangeContainer>
                        	<RangeWrap>
                            	    <RangeFill membership={values.membership}>
                            	    </RangeFill>
                            	    <ValueWrap>
                                	<h3>
                                   	    Membership turbo:
                                	</h3>
                                	<Value>
                                    	    {values.membership === 0.75 ? '200%' : values.membership === 1.0 ? '500%' : 0}
                                	</Value>
                            	    </ValueWrap>
                        	</RangeWrap>
                        	<MaxValue>500</MaxValue>
			    	<HelpIcon style={{ bottom: '25px', right: '5px' }} onClick={() => handleClickMembership()} show={showHelpMembership ? 1 : 0} />
                    	    </RangeContainer>
			</>
		    }
                </MembershipWrap>
            </WraperItem>
        </AIContainer>
    )
}
export default Speedometer;
