import React, { useEffect, useState } from "react";
import axiosInstance from '../../axios';
import { WheelContainer, ImgLogo, UserSpinWrap, Title } from './LuckyWheelElements';
import { TinyFormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import logo from '../../images/logo-footer.png';
import SeparateRect from '../SeparateRect';
import { Circle } from '../Loading';

const WheelComponent = ({
    segments,
    segColors,
    onFinished,
    primaryColor,
    contrastColor,
    size,
    upDuration,
    downDuration,
    info,
    small
}) => {
    let currentSegment = '';
    let isStarted = false;
    const [click, setClick] = useState(false);
    const [error, setError] = useState(false);
    const [apiError, setApiError] = useState('');
    const [isFinished, setFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [backReward, setBackReward] = useState('');
    let timerHandle = 0;
    const timerDelay = segments.length;
    let angleCurrent = 0;
    let angleDelta = 0;
    let canvasContext = null;
    let maxSpeed = Math.PI / `${segments.length}`;
    const upTime = segments.length * upDuration;
    const downTime = segments.length * downDuration;
    let spinStart = 0;
    const centerX = !small ? 170 : 105;
    const centerY = !small ? 170 : 115;
    useEffect(() => {
	initCanvas();
	draw();
    }, []);
    const initCanvas = () => {
	let canvas = document.getElementById('canvas');
	if (navigator.userAgent.indexOf('MSIE') !== -1) {
	    canvas = document.createElement('canvas');
	    canvas.setAttribute('width', !small ? 340 : 210)
	    canvas.setAttribute('height', !small ? 340 : 230)
	    canvas.setAttribute('id', 'canvas');
	    document.getElementById('wheel').appendChild(canvas);
	};
	canvasContext = canvas.getContext('2d');
    };
    const handleClick = () => {
	setClick(true);
	const ctx = canvasContext;
	if (ctx !== undefined && ctx !== null) {
	    info.spin > 0 ? fetchRecogReward() : setError('Your spin balance is 0.');
	} else {
	    initCanvas();
	    info.spin > 0 ? fetchRecogReward() : setError('Your spin balance is 0.');
	};
    };
    const fetchRecogReward = async () => {
	setLoading(true);
	try {
	    const { data } = await axiosInstance.get('earning/spin/lucky-wheel/');
	    setBackReward(data.reward);
	    spin(data.reward);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
	setLoading(false);
    };
    const fetchSpinRewardComplete = async () => {
	await axiosInstance.get('earning/spin/reward-complete/');
    };
    const spin = (reward) => {
	isStarted = true;
	spinStart = new Date().getTime();
	maxSpeed = Math.PI / segments.length;
	timerHandle = setInterval(() => onTimerTick(reward), timerDelay);
    };
    const onTimerTick = (reward) => {
	draw();
	const duration = new Date().getTime() - spinStart;
	let progress = 0;
	let finished = false;
	if (duration < upTime) {
	    progress = duration / upTime;
	    angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
	} else {
	    progress = duration / downTime;
	    angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
	    if (progress > 0.8 && currentSegment === reward) {
		fetchSpinRewardComplete();
		finished = true;
	    } else if (progress > 1) {
		setError('Please spin the lucky wheel again.');
		finished = true;
	    };
	};
	angleCurrent += angleDelta;
	while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;
	if (finished) {
	    setFinished(true);
	    onFinished(currentSegment);
	    clearInterval(timerHandle);
	    timerHandle = 0;
	    angleDelta = 0;
	};
    };
    const draw = () => {
	clear();
	drawWheel();
	drawNeedle();
    };
    const drawSegment = (key, lastAngle, angle) => {
	const ctx = canvasContext;
	const value = segments[key];
	ctx.save();
	ctx.beginPath();
	ctx.moveTo(centerX, centerY);
	ctx.arc(centerX, centerY, size, lastAngle, angle, false);
	ctx.lineTo(centerX, centerY);
	ctx.closePath();
	ctx.fillStyle = segColors[key];
	ctx.fill();
	ctx.stroke();
	ctx.save();
	ctx.translate(centerX, centerY);
	ctx.rotate((lastAngle + angle) / 2);
	ctx.fillStyle = "rgba(1, 219, 187, 1)";
	ctx.font = `${!small ? 'bold 15px sans-serif' : 'bold 12px sans-serif'}`;
	ctx.fillText(value.substr(0, 21), size / 2 + 22, 0);
	ctx.restore();
    };
    const drawWheel = () => {
	const ctx = canvasContext;
	let lastAngle = angleCurrent;
	const len = segments.length;
	const PI2 = Math.PI * 2;
	ctx.lineWidth = 1;
	ctx.strokeStyle = primaryColor;
	ctx.font = 'bold 20px sans-serif';
	ctx.textBaseline = 'middle';
	ctx.textAlign = 'center';
	for (let i = 1; i <= len; i++) {
	    const angle = PI2 * (i / len) + angleCurrent;
	    drawSegment(i - 1, lastAngle, angle);
	    lastAngle = angle;
	};
	ctx.beginPath();
	ctx.arc(centerX, centerY, 33, 0, PI2, false);
	ctx.closePath();
	ctx.fillStyle = '#111121';
	ctx.fill();
	ctx.fillStyle = primaryColor;
	ctx.beginPath();
	ctx.arc(centerX, centerY, size, 0, PI2, false);
	ctx.closePath();
	ctx.lineWidth = 2;
	ctx.strokeStyle = primaryColor;
	ctx.stroke();
    };
    const drawNeedle = () => {
	const ctx = canvasContext;
	ctx.lineWidth = 1;
	ctx.strokeStyle = contrastColor;
	ctx.fileStyle = contrastColor;
	ctx.beginPath();
	ctx.moveTo(centerX + 20, centerY - `${!small ? 142 : 112}`);
	ctx.lineTo(centerX - 20, centerY - `${!small ? 142 : 112}`);
	ctx.lineTo(centerX, centerY - `${!small ? 115 : 80}`);
	ctx.closePath();
	ctx.fill();
	const change = angleCurrent + Math.PI / 2;
	let i = segments.length - Math.floor((change / (Math.PI * 2)) * segments.length) - 1;
	if (i < 0) i = i + segments.length;
	ctx.textAlign = 'center';
	ctx.textBaseline = 'middle';
	ctx.fillStyle = primaryColor;
	currentSegment = segments[i];
	isStarted && ctx.fillText(currentSegment, centerX + 10, centerY + size + 50);
    };
    const clear = () => {
	const ctx = canvasContext;
	!small ? ctx.clearRect(0, 0, 340, 340) : ctx.clearRect(0, 0, 210, 230);
    };
    return (
	<WheelContainer id='wheel'>
	    <UserSpinWrap>
	        <Title>balance</Title>
	        <SeparateRect currentNumber={!click ? info.spin : info.spin - 1} maxNumber={21} />
	    </UserSpinWrap>
	    <canvas
	        id='canvas'
	        width={!small ? 340 : 210}
	        height={!small ? 340 : 230}
	    />
	    <ImgLogo src={logo} alt={'IceFaucet'} small={!small ? 1 : 0} />
	    {!click &&
		<TinyFormButton primary={1} big={1} style={{ width: '60%', margin: '0 auto 15px auto' }} onClick={!click ? handleClick : null}>spin</TinyFormButton>
	    }
	    {click && loading &&
		<Circle style={{ marginBottom: '25px' }} />
	    }
	    {isFinished && !apiError && !error &&
		<MsgWrap primary={1} style={{ width: '80%' }}>
		    <MsgText primary={1}>
			You win {backReward}.
		    </MsgText>
		</MsgWrap>
	    }
	    {error &&
		<MsgWrap primary={0} style={{ width: '80%' }}>
		    <MsgText primary={0}>
			{error}
		    </MsgText>
		</MsgWrap>
	    }
	    {apiError &&
		<MsgWrap primary={0} style={{ width: '80%' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	</WheelContainer>
    )
}
export default WheelComponent;
