import React from 'react';
import styled from 'styled-components';
import device from '../constants/breakPoints';

const RectContainer = styled.div`
    display: flex;        
`
const RectItem = styled.div`
    width: 3px;
    height: 21px;
    background: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : '#c4c4c4')};
    margin-left: 5px;
    @media screen and ${device.tn} {
	margin-left: 4px;
    }
`
const SeparateRect = ({ currentNumber, maxNumber }) => {
    return (
	<RectContainer>
	    {[...Array(maxNumber)].map((x, i) => (
		<RectItem key={i} active={i < currentNumber ? 1 : 0} />
	    ))}
	</RectContainer>
    )
}
export default SeparateRect;
