import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const HeaderArticle = styled.div`
    width: 98%;
    text-align: center;
    background: rgba(29, 107, 187, 0.95);
    border-radius: 10px 10px 0 0;
    padding: 10px;
`

export const SectionWrap = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.9)' : ' rgba(39, 117, 85, 0.9)')};
    border-radius: 10px;
    width: 98%;
    padding: 15px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;

    @media screen and ${device.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
    };
`
export const ImgArticleWrap = styled.div`
width: 500px;
height: 500px;
color: #fff;
font-size: 1rem;
margin: auto;
display: flex;
flex-direction: column;
align-items: center;
justfy-content: center;
@media screen and ${device.lg} {
    width: 390px;
    height: 390px;
};
@media screen and ${device.sm} {
    width: 260px;
    height: 260px;
};
@media screen and ${device.tn} {
    width: 100%;
    height: 100%;
};
img {
    width: 80%;
    height: 80%;
    margin: auto;
    @media screen and ${device.tn} {
	width: 100%;
	height: 100%
    };
}
`
export const VideoLinkWrap = styled.div`
    width: 80%;
    padding: 10px;
    background: #1d6bbb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and ${device.tn} {
	padding: 10px;
	width: 100%;
    };
    img {
	width: 50px;
	height: 50px;
	@media screen and ${device.tn} {
	    width: 30px;
	    height: 30px;
	};
    }
`
export const DescWrap = styled.div`
padding: 15px;
margin-top: 15px;
p {
    color: #fff;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    font-size: clamp(0.85rem, 2.5vw, 0.95rem);
    letter-spacing: 1px;
    word-spacing: 1.2px;
    line-height: 1.5;
};
`
export const SectionContainer = styled.div`
background: rgba(39, 117, 85, 0.5);
border: 1px solid  rgba(39, 117, 85, 0.6);
box-shadow: 0 0 10px rgba(39, 117, 85, 0.9);
border-radius: 10px;
width: 98%;
padding: 15px;
margin: 20px auto;
display: flex;
flex-direction: column;
@media screen and ${device.md} {
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
};
`
export const SectionTitle = styled.div`
    background: rgba(39, 117, 85, 0.5);
    border: 1px solid rgba(39, 117, 85, 0.6);
    box-shadow: 0 0 10px rgba(39, 117, 85, 0.9);
    margin-bottom: 15px;
h2 {
    color: rgb(1, 219, 187);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.85rem, 2.5vw, 1.1rem);
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 1.7px;
    text-transform: uppercase;
    text-align: center;
}
`
export const SectionContentWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    @media screen and ${device.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 25px;
    };
`
export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justfy-content: center;
`
export const ImgSectionWrap = styled.div`
background: rgba(39, 117, 85, 0.5);
border: 1px solidrgba(39, 117, 85, 0.6);
box-shadow: 0 0 10px rgba(39, 117, 85, 0.9);
width: 400px;
height: 400px;
display: felx;
align-items: center;
justify-content: center;
position: relative;
overflow: hidden;
border-radius: 50px;
color: #fff;
font-size: 1rem;
margin: auto;
@media screen and ${device.lg} {
    width: 370px;
    height: 370px;
};
@media screen and ${device.sm} {
    width: 250px;
    height: 250px;
    border-radius: 30px;
};
@media screen and ${device.tn} {
    width: 175px;
    height: 175px;
};
img {
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 50px;

    @media screen and ${device.sm} {
        border-radius: 30px;
    };
}
`
export const DescSectionWrap = styled.div`
background: rgba(39, 117, 85, 0.5);
border: 1px solid rgba(39, 117, 85, 0.6);
box-shadow: 0 0 10px rgba(39, 117, 85, 0.9);
border-radius: 5px;
max-width: 400px;
padding: 15px;
margin: 15px auto;
p {
    color: #fff;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    font-size: clamp(0.85rem, 2.5vw, 0.95rem);
    letter-spacing: 1px;
    word-spacing: 1.2px;
    line-height: 1.5;
};
`
