import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { BiMessageSquareAdd } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { GiMoebiusStar } from 'react-icons/gi';
import { FiEdit, FiStopCircle, FiPlayCircle } from 'react-icons/fi';

export const ControlContainer = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    grid-row-gap: 50px;
    margin-bottom: 25px;

    @media screen and ${device.lg} {
        grid-template-columns : 1fr 1fr;
    }

    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
    }
`
export const ControlCard = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
`
export const ControlTitrWrap = styled.div`
    width: 100%;
    text-align: center;
    background: ${({ color }) => (color === 'P' ? 'rgba(29, 107, 187, 0.4)' : color === 'A' ? 'rgba(29, 107, 187, 0.7)' : color === 'C' ? 'rgba(39, 117, 85, 0.7)' : 'rgba(195, 75, 75, 0.7)')};
    border: 1px solid ${({ color }) => (color === 'P' ? 'rgba(29, 107, 187, 0.3)' : color === 'A' ? 'rgba(29, 107, 187, 0.3)' : color === 'C' ? 'rgba(39, 117, 85, 0.3)' : 'rgba(195, 75, 75, 0.3)')};
    box-shadow: 0 0 5px ${({ color }) => (color === 'P' ? 'rgba(29, 107, 187, 0.7)' : color === 'A' ? 'rgba(29, 107, 187, 0.7)' : color === 'C' ? 'rgba(39, 117, 85, 0.7)' : 'rgba(195, 75, 75, 0.7)')};
    margin-bottom: 7px;

    h3{
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(0.85rem, 3.5vw, 0.99rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
        padding: 10px 3px;
    }
`
export const ControlContentWrap = styled.div`
    width: 100%;
    background: ${({ color }) => (color === 'P' ? 'rgba(29, 107, 187, 0.5)' : color === 'A' ? 'rgba(29, 107, 187, 0.9)' : color === 'C' ? 'rgba(39, 117, 85, 0.9)' : 'rgba(195, 75, 75, 0.9)')};
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);
    margin-bottom: 7px;
`
export const ControlItemWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;

    span {
        color: #fff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
        font-size: 0.75rem;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
    }
`
export const ReferralActiveWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const ReferralActiveIcon = styled(GiMoebiusStar)`
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: 1.5rem;
    cursor: pointer;
`
export const ControlAdsUrl = styled.div`
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);
    margin: auto;
    transition: 0.3s ease-in-out;

    &:hover {
        background: rgba(1, 219, 187, 0.7);
        transition: 0.3s ease-in-out;
    }
`
export const ControlAdsLink = styled.a`
    text-decoration: none;
    font-size: 0.8rem;
    width: 150px;
    color: #fff;
    text-align: center;
    padding: 5px 2px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const IconWrap = styled.div`
    width: 50%;
    background: rgba(29, 107, 187, 0.5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 0 3px 0;
    transition: 0.3s ease-in-out;

    &:hover {
        background: rgba(29, 107, 187, 1);
        transition: 0.3s ease-in-out;
    }
    &:nth-child(even) {
        background: rgba(39, 117, 85, 0.5);
        &:hover {
            background: rgba(39, 117, 85, 1);
            transition: 0.3s ease-in-out;
        }
    }
`
export const ButtonIcons = css`
    color: rgba(1, 219, 187, 0.85);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: 1.4rem;
`
export const EditIcon = styled(FiEdit)`
    ${ButtonIcons}
`
export const AddCreditIcon = styled(BiMessageSquareAdd)`
    ${ButtonIcons}
`
export const DeleteIcon = styled(AiOutlineDelete)`
    ${ButtonIcons}
`
export const StopIcon = styled(FiStopCircle)`
    ${ButtonIcons}
`
export const PlayIcon = styled(FiPlayCircle)`
    ${ButtonIcons}
`
export const ControlButtonWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 7px;
`
export const AddCreditForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 0 10px 10px 10px;
`