import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import RefClickContainer from '../components/RefActivityContainer/RefClickContainer';
import RefTotalEarningContainer from '../components/RefActivityContainer/RefTotalEarningContainer';
import { Circle } from '../components/Loading';

const ReferralsActivitiesPage = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const fetchAccountInfo = async () => {
        const { data } = await axiosInstance.get('earning/activities/referrals/');
        setInfo(data.info);
        setLoading(false);
    };
    useEffect(() => {
        fetchAccountInfo();
    }, []);
    return (
        <>
            {loading &&
                <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
                    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
                </div>
            }
            {!loading && info !== undefined && <RefClickContainer info={info} />}
            {!loading && info !== undefined && <RefTotalEarningContainer info={info} />}
        </>
    )
}
export default ReferralsActivitiesPage;
