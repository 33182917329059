import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch } from 'react-redux';
import { activateCodeAction, confirmRegAction } from '../../actions/authAction';
import { ValidateInfoReset } from './ValidateInfo';
import { FormContainer, FormWrap, Form } from './SignInElements';
import { FormButton } from '../ButtonElements';
import { RPInstroduction } from './ResetPassElements';
import { ContentWrap, ResetCodeWrap, NumberFormatCode, TimerResetCode } from './ResetCodeElements';
import Countdown, { zeroPad } from 'react-countdown';
import { MsgWrap, MsgText } from '../MessageElements.js';
import { useLocation, useParams } from 'react-router-dom';

const ResetCode = ({ authDataLang }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { code } = useParams();
    const [value, setValue] = useState(Object.freeze({ code: '' }));
    const [errors, setErrors] = useState({ code: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [expired, setExpired] = useState(false);
    const [timer, setTimer] = useState(parseInt(localStorage.getItem('activateTimer')));
    const renderer = ({ hours, minutes, seconds }) => {
        return <TimerResetCode>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</TimerResetCode>;
    };
    const handleChangeCode = (e) => {
        setValue({
            code: e.target.value.replace(/ /g, '').replace('_', '')
        });
        setErrors({});
        setIsSubmitting(false);
    };
    const handleSubmitCode = (e) => {
        e.preventDefault();
        setErrors(ValidateInfoReset(value));
        setIsSubmitting(true);
    };
    const fetchActivateCode = async () => {
        try {
            await axiosInstance.post('sign-up/confirm/code/', {
                code: value.code,
                id: localStorage.getItem('id')
            });
            localStorage.removeItem('id');
            localStorage.removeItem('activateTimer');
            dispatch(activateCodeAction({ prop: false }));
            dispatch(confirmRegAction({ prop: true }));
        } catch (error) {
            setErrors({ code: error.response && error.response.data.detail ? error.response.data.detail : error.message });
        };
        setIsSubmitting(false);
    };
    const fetchRefActivateCode = async () => {
        try {
            await axiosInstance.post(`ref/${code}/confirm/code/`, {
                code: value.code,
                id: localStorage.getItem('id')
            });
            localStorage.removeItem('id');
            localStorage.removeItem('activateTimer');
            dispatch(activateCodeAction({ prop: false }));
            dispatch(confirmRegAction({ prop: true }));
        } catch (error) {
            setErrors({ code: error.response && error.response.data.detail ? error.response.data.detail : error.message });
        };
        setIsSubmitting(false);
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting && location.pathname === '/auth') {
            fetchActivateCode();
        };
        if (Object.keys(errors).length === 0 && isSubmitting && location.pathname === `/auth/ref/${code}`) {
            fetchRefActivateCode();
        };
    });
    return (
        <FormContainer>
            <FormWrap>
                <Form action='#' onSubmit={handleSubmitCode}>
                    {!expired &&
                        <ContentWrap>
                            <h1>{authDataLang.activateCodeHeader}</h1>
                            <RPInstroduction>{authDataLang.activateCodeInstruction}</RPInstroduction>
                            <Countdown
                                date={timer}
                                renderer={renderer}
                                onComplete={() => {
                                    if (localStorage.getItem('id') && localStorage.getItem('activateTimer')) {
                                        setExpired(true);
                                        localStorage.removeItem('id');
                                        localStorage.removeItem('activateTimer');
                                        setTimer(0);
                                    };
                                }}
                            />
                            <ResetCodeWrap>
                                <NumberFormatCode
                                    format={"#  #  #  #  #  #"}
                                    allowEmptyFormatting mask="_"
                                    value={value.code}
                                    onChange={handleChangeCode}
                                />
                            </ResetCodeWrap >
                            {errors.code && <p>{errors.code}</p>}
                            <FormButton type='submit' primary={0}>{authDataLang.activateCodeButton}</FormButton>
                        </ContentWrap>
                    }
                    {expired && <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            The code is expired. Please try again.
                        </MsgText>
                    </MsgWrap>
                    }
                </Form>
            </FormWrap>
        </FormContainer>
    )
}

export default ResetCode;
