import {
    SHOW_CREDITCHECK,
    NOTSHOW_CREDITCHECK,
    SHOW_PTCSETUP,
    NOTSHOW_PTCSETUP,
    SHOW_PTCFILTERS,
    NOTSHOW_PTCFILTERS,
    SHOW_PTCFILTERS2,
    NOTSHOW_PTCFILTERS2,
    SHOW_PTCFILTERS3,
    NOTSHOW_PTCFILTERS3,
    SHOW_PTCSETUPEND,
    NOTSHOW_PTCSETUPEND,
    SHOW_PTCSUCCESS,
    NOTSHOW_PTCSUCCESS,
} from '../constants/ShowPtcSetUpRedux';

export const creditCheckAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_CREDITCHECK,
            })
        } else {
            dispatch({
                type: NOTSHOW_CREDITCHECK,
            })
        };
    };
};

export const PtcSetUpAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_PTCSETUP
            })
        } else {
            dispatch({
                type: NOTSHOW_PTCSETUP
            })
        };
    };
};

export const PtcFiltersAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_PTCFILTERS
            })
        } else {
            dispatch({
                type: NOTSHOW_PTCFILTERS
            })
        };
    };
};

export const PtcFilters2Action = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_PTCFILTERS2
            })
        } else {
            dispatch({
                type: NOTSHOW_PTCFILTERS2
            })
        };
    };
};

export const PtcFilters3Action = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_PTCFILTERS3
            })
        } else {
            dispatch({
                type: NOTSHOW_PTCFILTERS3
            })
        };
    };
};

export const PtcSetUpEndAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_PTCSETUPEND
            })
        } else {
            dispatch({
                type: NOTSHOW_PTCSETUPEND
            })
        };
    };
};

export const PtcSuccessAction = ({ prop }) => {
    return (dispatch) => {
        if (prop) {
            dispatch({
                type: SHOW_PTCSUCCESS
            })
        } else {
            dispatch({
                type: NOTSHOW_PTCSUCCESS
            })
        };
    };
};
