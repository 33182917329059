export const manageRefTableHead = [
    'number',
    'username',
    'registraion date',
    'status',
    'details',
    'mark'
];
export const manageSmallRefTableHead = [
    'number',
    'status',
    'details',
    'mark'
];