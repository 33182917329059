import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const LevelContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 25px auto;
    padding-left: 10px;
`
export const LevelWrap = styled.div`
    width: 67px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and ${device.md} {
        width: 67px;
    };
    @media screen and ${device.sm} {
	width: 57px;
    };
    @media screen and ${device.tn} {
	width: 35px;
    };
`
export const ProgressBarWrap = styled.div`
    width: 50px;
    margin: auto;
    outline: 1px solid rgba(29, 107, 187, 1);
    box-shadow: 0 0 2px rgba(29, 107, 187, 1);
    @media screen and ${device.md} {
	width: 55px;
    };
    @media screen and ${device.sm} {
	width: 45px;
    };
    @media screen and ${device.tn} {
	width: 23px;
    };
`
export const ProgressBar = styled.div`
    width: ${({ width }) => (width)};
    height: 4px;
    background: rgba(1, 219, 187, 1);
    overflow: hidden;
`
export const Desc1 = styled.span`
    position: relative;
    top: 11px;
    color: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(29, 107, 187, 1)')};
    font-size: clamp(0.7rem, 1.24vw, 0.8rem);
    @media screen and ${device.md} {
	top: 6px;
    };
`
export const Desc2 = styled.span`
    position: relative;
    top: 4px;
    color: ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(29, 107, 187, 1)')};
    font-size: clamp(0.7rem, 1.24vw, 0.8rem);
`
export const Ring = styled.div`
    background: ${({ active }) => (active ? 'rgba(29, 107, 187, 1)' : 'none')};
    position: relative;
    left: -50%;
    top: 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    outline: 2px solid ${({ active }) => (active ? 'rgba(1, 219, 187, 1)' : 'rgba(29, 107, 187, 1)')};
    z-index: 2;
    @media screen and ${device.md} {
	width: 10px;
	height: 10px;
	top: 8px;
    };
`
export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: 15px auto;
    p {
	color: #fff;
	font-size: clamp(0.9rem, 1.8vw, 1rem);
	text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
	letter-spacing: 1.2px;
    }
`
export const FilterWraper = styled.div`
    display: grid;
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 15px auto 0 auto;
    @media screen and ${device.md} {
	grid-column-gap: 30px;
    }
    @media screen and ${device.tn} {
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
    }
`
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease-in-out;
    @media screen and ${device.sm} {
	width: 55px;
	height: 55px;
    };
    &:hover {
        transform: scale(1.09);
	transition: 0.3s ease-in-out;
    }
`
export const ButtonFilter = styled.span`
    color: #fff;
    font-size: clamp(0.55rem, 1.8vw, 0.65rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#1D6BBB')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    &:hover {
	background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#277555')};
	transition: 0.3s ease-in-out;
    }
`
export const DisplayNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease-in-out;
    margin: 15px auto;
    h2 {
	color: #fff;
	font-size: clamp(0.75rem, 1.8vw, 0.9rem);
	font-weight: 400;
	text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
	letter-spacing: 1.2px;
    }
`
export const RangeInput = styled.input`
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    margin-top: 15px;
    background: rgba(39, 117, 85, 0.75);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5);
    border: 1px solid #01DBBB; 
    border-radius: 5px;
    transition: 0.3s ease-in-out;

    &::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5);
	background: #277555;
	cursor: pointer;
	transition: 0.3s ease-in-out;

	&:hover {
	    background: #01DBBB;
	    transition: 0.3s ease-in-out;
	}
    }

    &::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border-bos
	background: #04AA6D;
	cursor: pointer;
    }

    &:hover {
	opacity: 1;
	transition: 0.3s ease-in-out;
    }
`
