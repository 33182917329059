import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import device from '../../constants/breakPoints';

export const Container = styled.div`
    position: relative;
    top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.2);
    padding: 25px;
    overflow: none;
`
export const Wrap = styled.div`
    width: 100%;
    max-width: 94vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 25px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: 0 0 3px rgba(29, 107, 187, 0.5);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(29, 107, 187, 0.7);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(29, 107, 187, 0.85);
    }
`
export const TitleWrap = styled.div`
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(29, 107, 187, 0.5);
    h2 {
        color: #01DBBB;
        font-size: clamp(0.8rem, 2.4vw, 1.1rem);
        font-weight: 700;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        padding: 3px 5px;
    }
`
export const CodeWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
`
export const NumberFormatCode = styled(NumberFormat)`
    color: #1d6bbb;
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    font-weight: bold;
    max-width: 300px;
    width: 100%;
    text-align: center;

    &:focus {
        outline: 3px solid #1d6bbb;
    }
`
export const ProgressBarWrap = styled.div`
    width: 80%;
    outline: 2px solid rgba(1, 219, 187, 0.5);
    box-shadow: 0 0 5px rgba(1, 219, 187, 0.8);
    border-radius: 0 0 30px 0;
    margin: 25px;

    @media screen and ${device.sm} {
        width: 95%;
    }
`
export const ProgressBar = styled.div`
    width: ${({ width }) => (width)};
    height: 17px;
    background: ${({ primary }) => (primary ? 'rgba(39, 117, 85, 0.7)' : 'rgba(29, 107, 187, 0.7)')};
    border-radius: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(0.7rem, 2.2vw, 0.8rem);
    }
`
export const DescriptionWrap = styled.div`
    width: 100%;
    margin-top: 25px;
    p {
        color: #fff;
        font-size: clamp(0.8rem, 3vw, 0.95rem);
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
        line-height: 2;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
    }
`