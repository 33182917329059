import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { HiOutlineMail } from 'react-icons/hi';
import { RiKey2Line } from 'react-icons/ri';

export const FormContainer = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
`
export const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 410px;
    &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5); 
        border-radius: 0 5px 5px 0;
        margin: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(1, 219, 187, 0.5);
        border-radius: 0 5px 5px 0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background:rgba(1, 219, 187, 0.75);
    }
`
export const Form = styled.form`
    background: #010606;
    display: flex;
    flex-direction: column;
    padding: 50px 25px 15px 25px;
    border-radius: 9px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.9);
    opacity: 0.85;
    transition: all 0.3s ease-in-out;

    @media screen and ${device.sm} {
        padding: 85px 25px 15px 25px;
    }

    &:hover {
        opacity: 0.9;
        transition: all 0.3s ease-in-out;
    }

    h1 {
        color: #fff;
        font-size: clamp(1rem, 2.8vw, 1.3rem);
        font-weight: 400;
        text-align: center;
        text-shadow: 1px 2px 5px #000;
    }
    p {
        color: red;
        font-size: 0.95rem;
    }
`
export const InputWrap = styled.div`
    position: relative;
    display: flex;  
    align-items: center;
`
export const FormInput = styled.input`
    padding: 8px 10px 10px 45px;
    margin-top: 15px;
    border: none;
    border-raduis: 50px;
    font-size: 1rem;
    width: 100%;

    ::placeholder {
        color: #277555;
        opacity: 0.70;
    }
    &:focus {
        outline: 3px solid #277555;
    }
`
export const StyleIcon = css`
    position: absolute;
    transform: translate(50%, 40%);
    color: #277555;
    font-size: 1.2rem;
    opacity: 0.70;
`
export const EmailIcon = styled(HiOutlineMail)`
    ${StyleIcon}
`
export const PasswordIcon = styled(RiKey2Line)`
    ${StyleIcon}
`
export const ForgotPasswordWrap = styled.div`
    margin: 15px auto 0 auto;
    cursor: pointer;

    p {
        color: #01DBBB;
        font-size: clamp(0.85rem, 2.8vw, 1rem);
        letter-spacing: 1px;
        word-spacing: 2px;
        transition: all 0.3s ease-in-out;
    
        &:hover {
            letter-spacing: 1.5px;
            word-spacing: 4px;
            transition: all 0.3s ease-in-out;
        }
    }
`