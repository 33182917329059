export const loadingContestTableHead = [
    'position',
    'username(id)',
    'completed'
];
export const offerLoadingContestTableHead = [
    'position',
    'username(id)',
    'earned'
];
export const contestTableHead = [
    'contest',
    'reward',
    'date',
    'more info'
];
export const contestMoreTableHead = [
    '#',
    'username(id)',
    'reward',
    'completed'
];
export const offerContestMoreTableHead = [
    '#',
    'username(id)',
    'reward',
    'earned'
];
export const liveContestTableHead = [
    'position',
    'username(id)',
    'completed',
    'last-complete'
];
export const offerLiveContestTableHead = [
    'position',
    'username(id)',
    'earned',
    'last-earn'
];
export const smallLiveContestTableHead = [
    'position',
    'username(id)',
    'completed'
];
export const offerSmallLiveContestTableHead = [
    'position',
    'username(id)',
    'earned'
];
export const infinityReward = [
    {
	'level': 1,
	'up': 'PR x 1.0',
	'reward': 500,
	'next_reward': 1500,
	'winners': '3 winner'
    },
    {
	'level': 2,
	'up': 'x 1.2',
	'reward': 1500,
	'next_reward': 3000,
	'winners': 5
    },
    {
	'level': 3,
	'up': 'x 1.5',
	'reward': 3000,
	'next_reward': 6000,
	'winners': 7
    },
    {
	'level': 4,
	'up': 'x 1.7',
	'reward': 6000,
	'next_reward': 10000,
	'winners': 9
    },
    {
	'level': 5,
	'up': 'x 2.0',
	'reward': 10000,
	'next_reward': 20000,
	'winners': 11
    },
    {
	'level': 6,
	'up': 'x 2.5',
	'reward': 20000,
	'next_reward': 'infinity',
	'winners': 15
    }
];
export const offerInfinityReward = [
    {
	'level': 1,
	'up': 'PR x 1.0',
	'reward': 1500,
	'next_reward': 4500,
	'winners': '3 winner'
    },
    {
	'level': 2,
	'up': 'x 1.2',
	'reward': 4500,
	'next_reward': 9000,
	'winners': 4
    },
    {
	'level': 3,
	'up': 'x 1.5',
	'reward': 9000,
	'next_reward': 15000,
	'winners': 5
    },
    {
	'level': 4,
	'up': 'x 1.7',
	'reward': 15000,
	'next_reward': 25000,
	'winners': 6
    },
    {
	'level': 5,
	'up': 'x 2.0',
	'reward': 25000,
	'next_reward': 50000,
	'winners': 7
    },
    {
	'level': 6,
	'up': 'x 2.5',
	'reward': 50000,
	'next_reward': 'infinity',
	'winners': 10
    }
];
export const smallInfinityReward = [
    {
	'level': 1,
	'up': 'x 1.0',
	'reward': 500,
	'next_reward': 1500,
	'winners': 3
    },
    {
	'level': 2,
	'up': 'x 1.2',
	'reward': 1500,
	'next_reward': 3000,
	'winners': 5
    },
    {
	'level': 3,
	'up': 'x 1.5',
	'reward': 3000,
	'next_reward': 6000,
	'winners': 7
    },
    {
	'level': 4,
	'up': 'x 1.7',
	'reward': 6000,
	'next_reward': 10000,
	'winners': 9
    },
    {
	'level': 5,
	'up': 'x 2.0',
	'reward': 10000,
	'next_reward': 20000,
	'winners': 11
    },
    {
	'level': 6,
	'up': 'x 2.5',
	'reward': 20000,
	'next_reward': 'infinity',
	'winners': 15
    }
];
export const offerSmallInfinityReward = [
    {
	'level': 1,
	'up': 'x 1.0',
	'reward': 1500,
	'next_reward': 4500,
	'winners': 3
    },
    {
	'level': 2,
	'up': 'x 1.2',
	'reward': 4500,
	'next_reward': 9000,
	'winners': 4
    },
    {
	'level': 3,
	'up': 'x 1.5',
	'reward': 9000,
	'next_reward': 15000,
	'winners': 5
    },
    {
	'level': 4,
	'up': 'x 1.7',
	'reward': 15000,
	'next_reward': 25000,
	'winners': 6
    },
    {
	'level': 5,
	'up': 'x 2.0',
	'reward': 25000,
	'next_reward': 50000,
	'winners': 7
    },
    {
	'level': 6,
	'up': 'x 2.5',
	'reward': 50000,
	'next_reward': 'infinity',
	'winners': 10
    }
];
export const infinityRewardTableHead = [
    'PR required',
    'Ice Bonus',
    'Winners'
];
export const infinityRewardTableBody = [
    {
	'PR_required': 500,
	'ice_bonus': 'PR x 1.0',
	'winners': 3
    },
    {
	'PR_required': 1500,
	'ice_bonus': 'PR x 1.2',
	'winners': 5
    },
    {
	'PR_required': 3000,
	'ice_bonus': 'PR x 1.5',
	'winners': 7
    },
    {
	'PR_required': 6000,
	'ice_bonus': 'PR x 1.7',
	'winners': 9
    },
    {
	'PR_required': 10000,
	'ice_bonus': 'PR x 2.0',
	'winners': 11
    },
    {
	'PR_required': 20000,
	'ice_bonus': 'PR x 2.5',
	'winners': 15
    }
];
export const offerInfinityRewardTableBody = [
    {
	'PR_required': 1500,
	'ice_bonus': 'PR x 1.0',
	'winners': 3
    },
    {
	'PR_required': 4500,
	'ice_bonus': 'PR x 1.2',
	'winners': 4
    },
    {
	'PR_required': 9000,
	'ice_bonus': 'PR x 1.5',
	'winners': 5
    },
    {
	'PR_required': 15000,
	'ice_bonus': 'PR x 1.7',
	'winners': 6
    },
    {
	'PR_required': 25000,
	'ice_bonus': 'PR x 2.0',
	'winners': 7
    },
    {
	'PR_required': 50000,
	'ice_bonus': 'PR x 2.5',
	'winners': 10
    }
];
