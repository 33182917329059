import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const RefrigContainer = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    grid-row-gap: 50px;
    margin-bottom: 25px;

    @media screen and ${device.lg} {
        grid-template-columns : 1fr 1fr;
    }

    @media screen and ${device.sm} {
        grid-template-columns : 1fr;
    }
`
export const RefrigCard = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
`
export const TitrWrap = styled.div`
    width: 100%;
    text-align: center;
    background: ${({ color }) => (color === 'F' ? 'rgba(29, 107, 187, 0.4)' : color === 'W' ? 'rgba(29, 107, 187, 0.7)' : 'rgba(195, 75, 75, 0.7)')};
    border: 1px solid ${({ color }) => (color === 'F' ? 'rgba(29, 107, 187, 0.3)' : color === 'W' ? 'rgba(29, 107, 187, 0.3)' : 'rgba(195, 75, 75, 0.3)')};;
    box-shadow: 0 0 5px ${({ color }) => (color === 'F' ? 'rgba(29, 107, 187, 0.7)' : color === 'W' ? 'rgba(29, 107, 187, 0.7)' : 'rgba(195, 75, 75, 0.7)')};;
    margin: 7px auto;

    h3{
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(0.85rem, 3.5vw, 0.99rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
        padding: 10px 3px;
    }
`
export const ContentWrap = styled.div`
    width: 100%;    
    background: ${({ color }) => (color === 'F' ? 'rgba(29, 107, 187, 0.5)' : color === 'W' ? 'rgba(29, 107, 187, 0.9)' : 'rgba(195, 75, 75, 0.9)')};
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 5px rgba(29, 107, 187, 0.7);
`
export const ItemWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;

    span {
        color: #fff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
        font-size: 0.8rem;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;

        &:nth-child(1) {
            width: 50%;
        }
    }
`