import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import ScrollToTop from '../components/ScrollToTop';
import SmallCardInfo from '../components/GeneralStatistics/SmallCardInfo';
import NewUsersChart from '../components/GeneralStatistics/NewUsersChart';
import CircleCardChart from '../components/GeneralStatistics/CircleCardChart';
import ClickChart from '../components/EarningStatistics/ClickChart';
import EarningChart from '../components/EarningStatistics/EarningChart';
import NewAdsChart from '../components/AdsStatistics/NewAdsChart';
import AdscoinMiningChart from '../components/AdsStatistics/AdscoinMiningChart';
import { Container } from '../components/GeneralStatistics/SmallCardInfoElements';
import { statisticsMessageData, statisticsMessageLangData } from '../data/statisticsData';
import { MsgWrap, MsgText } from '../components/MessageElements';

const StatisticsPage = () => {
    const [info, setInfo] = useState();
    const [allInfo, setAllInfo] = useState();
    const [earningInfo, setEarningInfo] = useState();
    const [adsInfo, setAdsInfo] = useState();
    const [apiError, setApiError] = useState('');
    const pageLang = localStorage.getItem('pageLang');
    const msgDataLang = pageLang ? statisticsMessageLangData.find(data => data.lang === pageLang).info : statisticsMessageData;
    const fetchGeneralInfo = async () => {
        try {
            const { data } = await axios.get('https://icefaucet.com/api/earning/statistics/');
            setInfo(data.info);
            setAllInfo(data.all_info);
            fetchEarningInfo()
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const fetchEarningInfo = async () => {
        try {
            const { data } = await axios.get('https://icefaucet.com/api/earning/statistics/earning/');
            setEarningInfo(data.info);
            fetchAdsInfo()
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const fetchAdsInfo = async () => {
        try {
            const { data } = await axios.get('https://icefaucet.com/api/earning/statistics/ads/');
            setAdsInfo(data.info);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    useEffect(() => {
        info === undefined && allInfo === undefined && !apiError && fetchGeneralInfo();
    });
    return (
        <>
	    <Helmet>
	        <meta name="description" content="Graphical data about iceFaucet activities" />
	        <title>iceFaucet | real data and useful information</title>
	    </Helmet>
            <ScrollToTop />
            <Container id='general data' dark={1}>
                <MsgWrap primary={1} style={{ width: '70%', margin: '15px auto' }}>
                    <MsgText primary={1}>
                        {msgDataLang.msg}
                    </MsgText>
                </MsgWrap>
                {allInfo !== undefined && <SmallCardInfo allInfo={allInfo} />}
                {info !== undefined && <NewUsersChart info={info} />}
                {allInfo !== undefined && <CircleCardChart allInfo={allInfo} />}
                {apiError &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
            </Container>
            {!apiError &&
                <Container id='earning data' dark={0}>
                    {earningInfo !== undefined && <ClickChart info={earningInfo} />}
                    {earningInfo !== undefined && <EarningChart info={earningInfo} />}
                </Container>
            }
            {!apiError &&
                <Container id='ads data' dark={1}>
                    {adsInfo !== undefined && <NewAdsChart info={adsInfo} />}
                    {adsInfo !== undefined && <AdscoinMiningChart info={adsInfo} />}
                </Container>
            }
        </>
    )
}
export default StatisticsPage;
