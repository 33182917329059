import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch } from 'react-redux';
import { resetPasswordAction, resetCodeAction } from '../../actions/authAction';
import { RPInstroduction } from './ResetPassElements';
import { FormContainer, FormWrap, Form, InputWrap, FormInput, EmailIcon } from './SignInElements';
import { FormButton } from '../ButtonElements';
import { ValidateEmail } from './ValidateInfo';

const ResetPass = ({ authDataLang }) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState(Object.freeze({ email: '' }));
    const [errors, setErrors] = useState({ email: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleChange = (e) => {
        setValues({
            email: e.target.value
        });
        setErrors({});
        setIsSubmitting(false);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateEmail(values));
        setIsSubmitting(true);
    };
    const fetchResetPass = async () => {
        try {
            const { data } = await axiosInstance.post('reset/password/', {
                email: values.email,
            });
            localStorage.setItem('id', data.id);
            localStorage.setItem('resetPassTimer', Date.now() + data.timer * 1000);
            dispatch(resetCodeAction({ prop: true }));
            dispatch(resetPasswordAction({ prop: false }));
        } catch (error) {
            setErrors({
                email: error.response && error.response.data.detail ? error.response.data.detail : error.message
            });
        };
    };
    useEffect(() => {
        if (localStorage.getItem('id') && localStorage.getItem('resetPassTimer') && (parseInt(localStorage.getItem('resetPassTimer')) - Date.now() > 0)) {
            dispatch(resetCodeAction({ prop: true }));
            dispatch(resetPasswordAction({ prop: false }));
        };
        if (localStorage.getItem('id') && localStorage.getItem('resetPassTimer') && (parseInt(localStorage.getItem('resetPassTimer')) - Date.now() <= 0)) {
            localStorage.removeItem('id');
            localStorage.removeItem('resetPassTimer');
        };
        if (Object.keys(errors).length === 0 && !localStorage.getItem('id') && !localStorage.getItem('resetPassTimer') && isSubmitting) {
            fetchResetPass();
        };
    });
    return (
        <FormContainer>
            <FormWrap>
                <Form action='#' onSubmit={handleSubmit}>
                    <h1>{authDataLang.resetHeader}</h1>
                    <RPInstroduction>{authDataLang.resetInstruction}</RPInstroduction>
                    <InputWrap>
                        <EmailIcon />
                        <FormInput
                            type='email'
                            name='email'
                            placeholder={authDataLang.emailPlaceHolder}
                            value={values.email}
                            onChange={handleChange}
                        />
                    </InputWrap>
                    {errors.email && <p>{errors.email}</p>}
                    <FormButton type='submit' primary={0}>{authDataLang.resetButton}</FormButton>
                </Form>
            </FormWrap>
        </FormContainer>
    )
}
export default ResetPass;
