import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { StackContainer, StackWrap, TitleWrap } from '../components/Stack/StackElements';
import { Circle } from '../components/Loading';
import { MsgWrap, MsgText } from '../components/MessageElements';

const OfferListEWall = () => {
    const [src, setSrc] = useState();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState('');
    useEffect(() => {
	const fetchOfferListEWall = async () => {
	    try {
		const { data } = await axiosInstance.get(`offer/click/ewall/`);
		setSrc(data.src);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchOfferListEWall();
    }, []);
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && src !== undefined && !apiError &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>ewall</h2>
			</TitleWrap>
			<iframe
		            style={{ width: '100%', height: 'calc(100vh - 200px)', border: 'none', overflowX: 'hidden', overflowY: 'auto' }}
		            title="EWall"
		            src={src} />
		    </StackWrap>
		</StackContainer>
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	</>
   )
}
export default OfferListEWall;
