export const ValidateBannerForm = (values) => {
    let errors = {};
    const accept_url = ['http', 'https'];
    if (!values.url) {
        errors.url = 'The URL address is required.'
    } else if (!accept_url.includes(values.url.toLowerCase().split('://')[0])) {
        errors.url = "The URL address should start with 'https://' or 'http://'."
    } else if (values.url.length > 128) {
        errors.url = "The URL address needs to be less than 128 characters."
    };

    if (!values.title) {
        errors.title = 'The title is required.'
    } else if (values.title.length >= 65 || values.title.length <= 31) {
        errors.title = "The Title needs to be 32-64 characters."
    } else if (/^[0-9]+$/i.test(values.title)) {
        errors.title = 'Your title should not be entirely number.'
    };
    return errors
};