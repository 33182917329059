import axios from 'axios';

axios.defaults.baseURL = 'https://icefaucet.com/api/';
const axiosInstance = axios.create({
    baseURL: 'https://icefaucet.com/api/',
    timeout: 25000,
    headers: {
        'Authorization': localStorage.getItem('access_token')
            ? 'Bearer ' + localStorage.getItem('access_token')
            : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});
axiosInstance.interceptors.response.use(
    response => response,
    async function (error) {
        const originalRequest = error.config;
        if (typeof error.response === 'undefined') {
            alert(
                'A network or server error occurred. Looks like a weak network or CORS might be the problem. ' +
                'If you are sure this problem is not about your side, let us be aware of that.'
            );
            return Promise.reject(error);
        };
        if (error.response.status === 404) {
            window.location.href = '/notfound';
            return Promise.reject(error);
        };
        if (error.response.status === 401 &&
            error.response.data.code === 'token_not_valid' &&
            originalRequest.url !== 'token/refresh/') {
            const mem = localStorage.getItem('memorized') ? parseInt(localStorage.getItem('memorized')) : null;
            if (mem === null || mem - Date.now() < 0) {
                localStorage.setItem('memorized', Date.now() + 25000);
                const refreshToken = localStorage.getItem('refresh_token');
                if (refreshToken) {
                    const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
                    const now = Math.ceil(Date.now() / 1000);
                    if (tokenParts.exp > now) {
                        return axiosInstance
                            .post('token/refresh/', { refresh: refreshToken })
                            .then(response => {
                                localStorage.setItem('access_token', response.data.access);
                                localStorage.setItem('refresh_token', response.data.refresh);
                                axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + response.data.access;
                                originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access;
                                return axiosInstance(originalRequest);
                            })
                            .catch(error => {
                                return Promise.reject(error);
                            })
                    } else {
			axios.post('https://icefaucet.com/api/logout/blacklist/', { refresh_token: refreshToken });
                        localStorage.clear();
                        axiosInstance.defaults.headers['Authorization'] = null;
                        window.location.href = '/auth';
                    }
                } else {
                    localStorage.clear();
                    axiosInstance.defaults.headers['Authorization'] = null;
                    window.location.href = '/auth';
                }
            } else {
                axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
                originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
                return axiosInstance(originalRequest);
            };
        };
        return Promise.reject(error);
    }
);
export default axiosInstance;
