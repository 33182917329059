import React from 'react';
import { TinyButton } from '../ButtonElements';
import { AIContainer, AIWrapItem, AICard, AIImageWrap, AIImage, AIContext, AIContextItem } from './AccountInfoElements';
import male from '../../images/male_avatar.svg';
import female from '../../images/female_avatar.svg';
import omega from '../../images/omega.png';
import beta from '../../images/beta.png';
import alpha from '../../images/alpha.png';
import referrals from '../../images/referrals.png';
import Countdown, { zeroPad } from 'react-countdown';

const AccountInfo = ({ info }) => {
    const renderer = ({ hours, minutes, seconds }) => {
        return <span style={{ color: 'red', fontSize: '0.8rem', textShadow: '0 0 3px red' }}>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    };

    return (
        <AIContainer>
            <AIWrapItem>
                <AICard>
                    <AIImageWrap>
                        <AIImage src={info.profileImg ? info.profileImg : info.gender === 'F' ? female : male} alt={'Profile Image'} />
                    </AIImageWrap>
                    <AIContext>
                        <AIContextItem>
                            <span>username(id):</span>
                            <span>{info.userName ? info.userName : info.userId}</span>
                        </AIContextItem>
                        <AIContextItem>
                            <span>level:</span>
                            <span>{info.level}</span>
                        </AIContextItem>
                        <AIContextItem>
                            <span>country:</span>
                            <span>{info.country}</span>
                        </AIContextItem>
                    </AIContext>
                </AICard>
                <AICard>
                    <AIImageWrap>
                        <AIImage src={info.membership === '0.75' ? beta : info.membership === '1.0' ? alpha : omega} alt={'membership logo'} />
                    </AIImageWrap>
                    <AIContext>
                        <AIContextItem>
                            <span>date joined:</span>
                            <span>{info.memberSince}</span>
                        </AIContextItem>
                        <AIContextItem>
                            <span>expire:</span>
                            {info.timer === undefined && <span>never</span>}
                            {info.timer !== undefined && info.days !== undefined && info.timer >= 24 * 3600 && <span>{info.days} days</span>}
                            {
                                info.timer !== undefined && info.timer < 24 * 3600 &&
                                <span>
                                    <Countdown
                                        date={Date.now() + info.timer * 1000}
                                        renderer={renderer}
                                    />
                                </span>
                            }
                        </AIContextItem>
                    </AIContext>
                    <TinyButton to='/upgrade' primary={0}>upgrade</TinyButton>
                </AICard>
                <AICard>
                    <AIImageWrap>
                        <AIImage src={referrals} alt={'refrral logo'} />
                    </AIImageWrap>
                    <AIContext>
                        <AIContextItem>
                            <span>referrals:</span>
                            {info.membership === '0.5' && (info.referrals < 1000
                                ? <span>{info.referrals}</span>
                                : info.referrals >= 1000
                                    ? <span style={{ color: 'red', textShadow: '0 0 3px red' }}>1000 (max)</span>
                                    : null
                            )}
                            {info.membership === '0.75' && (info.referrals < 5000
                                ? <span>{info.referrals}</span>
                                : info.referrals >= 5000
                                    ? <span style={{ color: 'red', textShadow: '0 0 3px red' }}>5000 (max)</span>
                                    : null
                            )}
                            {info.membership === '1.0' && (info.referrals < 10000
                                ? <span>{info.referrals}</span>
                                : info.referrals >= 10000
                                    ? <span style={{ color: 'red', textShadow: '0 0 3px red' }}>10000 (max)</span>
                                    : null
                            )}
                        </AIContextItem>
                        <AIContextItem>
                            <span>earned:</span>
                            <span>{info.ref_earn} pc</span>
                        </AIContextItem>
                    </AIContext>
                    <TinyButton to='/manage-referrals/1' primary={0}>manage</TinyButton>
                </AICard>
            </AIWrapItem>
        </AIContainer>
    )
}

export default AccountInfo
