import React from 'react';
import { useSelector } from 'react-redux';
import { SetUpContainer, SetUpBgWrap, AdsSetUpContainer, AdsContent } from '../SetUpAds/SetUpAddsElements';
import setupPtcBg from '../../images/Auth_background.jpg';
import SetUpAdsNavigate from './SetUpAdsNavigate';
import CreditCheck from '../SetUpPtc/CreditCheck';
import SetUpBannerForm from './SetUpBannerForm';
import SetUpBannerFilter from './SetUpBannerFilter';
import SetUpBannerFilter2 from './SetUpBannerFilter2';
import SetUpPtcSuccess from '../SetUpPtc/SetUpPtcSuccess';

const SetUpBanner = () => {
    const showCreditCheck = useSelector(state => state.creditCheck);
    const showBannerSetUp = useSelector(state => state.bannerSetUp);
    const showBannerFilters = useSelector(state => state.bannerFilters);
    const showBannerFilters2 = useSelector(state => state.bannerFilters2);
    const showBannerSuccess = useSelector(state => state.bannerSuccess);
    return (
        <SetUpContainer>
            <SetUpBgWrap>
                <img src={setupPtcBg} alt={'background of set up PTC page'} />
            </SetUpBgWrap>
            <AdsSetUpContainer>
                <AdsContent>
                    {!showCreditCheck && !showBannerSuccess && <SetUpAdsNavigate />}
                    {showCreditCheck && !showBannerSetUp && !showBannerFilters && !showBannerFilters2 && !showBannerSuccess
                        ? <CreditCheck />
                        : showBannerSetUp && !showCreditCheck && !showBannerFilters && !showBannerFilters2 && !showBannerSuccess
                            ? <SetUpBannerForm />
                            : showBannerFilters && !showCreditCheck && !showBannerSetUp && !showBannerFilters2 && !showBannerSuccess
                                ? <SetUpBannerFilter />
                                : showBannerFilters2 && !showCreditCheck && !showBannerSetUp && !showBannerFilters && !showBannerSuccess
                                    ? <SetUpBannerFilter2 />
                                    : showBannerSuccess && !showCreditCheck && !showBannerSetUp && !showBannerFilters && !showBannerFilters2
                                        ? <SetUpPtcSuccess />
                                        : null}
                </AdsContent>
            </AdsSetUpContainer>
        </SetUpContainer>
    )
};
export default SetUpBanner;
