import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const ContentWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1%;
    grid-row-gap: 20px;
    margin: auto;
    @media screen and ${device.lg} {
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 1%;
    }
    @media screen and ${device.md} {
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1%;
    }
    @media screen and ${device.sm} {
	grid-template-columns: 1fr;
    }
`
export const OfferCard = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 2px 10px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    width: 150px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 7px 0;
`
export const TitleWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(39, 117, 85, 0.7);
    border-radius: 5px 5px 0 0;
    h4 {
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
	font-size: 0.74rem;
	font-weight: 400;
	letter-spacing: 1px;
	word-spacing: 1.5px;
	text-transform: uppercase;
	padding: 5px 3px;
    }
`
export const LogoWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
`
export const Logo = styled.img`
    width: 140px;
    height: 35px;
    border-radius: 10px;
`
export const AdscoinPromoWrap = styled.div`
    position: relative;
    width: 50px;
    cursor: pointer;
    background: rgba(39, 117, 85, 0.5);
    border: 1px solid rgba(1, 219, 187, 1);
    box-shadow: 0 2px 10px rgba(39, 117, 85, 0.7);
    border-radius: 5px;
    left: -65px;
    top: 7px;
    text-align: center;
    transition: 0.3s ease-in-out;
    &:hover {
	transform: scale(1.11);
	transition: 0.3s ease-in-out;
    }
`
export const AdscoinPromoSpan = styled.span`
    color: #01DBBB;
    font-size: 0.8rem;
    letter-spacing: 0.7px;
`
