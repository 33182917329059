import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PtcFilters2Action, PtcFilters3Action, PtcFiltersAction } from '../../actions/PtcSetUpAction';
import { ptcDeviceData, ptcGenderData, ptcRepeatData } from '../../data/ptcFiltersData';
import { TinyFormButton } from '../ButtonElements';
import { FormButtonsWrap } from './CreditCheckElements';
import { PtcForm, PtcFormInputContainer } from './SetUpPtcFormElements.js';
import { FormHeader } from '../SetUpAds/SetUpAddsElements';
import { FilterContainer, InstructionWrap, Instruction, ItemWrap, ItemName } from './SetUpPtcFilterElements.js';
import { FilterWrap, RepeatFilterWrap } from './SetUpPtcFilter3Elements.js';
import { MsgWrap, MsgText } from '../MessageElements';

const SetUpPtcFilter3 = () => {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState('');
    const [devices, setDevices] = useState(Object.freeze(['M', 'D']));
    const [deviceIndexList, setDeviceIndexList] = useState([0, 1]);
    const [genders, setGenders] = useState(Object.freeze(['M', 'F']));
    const [genderIndexList, setGenderIndexList] = useState([0, 1]);
    const [repeat, setRepeat] = useState(Object.freeze(1));
    const [repeatIndex, setRepeatIndex] = useState(0);
    const deviceClick = (e, index) => {
        const deviceName = e.target.getAttribute('name');
        setErrors('');
        setIsSubmitting(false);
        if (deviceName && !devices.includes(deviceName) && devices.length <= 2) {
            setDevices(devices => [...devices, deviceName]);
            setDeviceIndexList(deviceIndexList => [...deviceIndexList, index]);
        } else if (deviceName && devices.includes(deviceName)) {
            setDevices(devices.filter(removeDevice => removeDevice !== deviceName));
            setDeviceIndexList(deviceIndexList.filter(removeDeviceIndex => removeDeviceIndex !== index));
        };
    };
    const genderClick = (e, index) => {
        const genderName = e.target.getAttribute('name');
        setErrors('');
        setIsSubmitting(false);
        if (genderName && !genders.includes(genderName) && genders.length <= 2) {
            setGenders(genders => [...genders, genderName]);
            setGenderIndexList(genderIndexList => [...genderIndexList, index]);
        } else if (genderName && genders.includes(genderName)) {
            setGenders(genders.filter(removeGender => removeGender !== genderName));
            setGenderIndexList(genderIndexList.filter(removeGenderIndex => removeGenderIndex !== index));
        };
    };
    const repeatClick = (e, index) => {
        const repeatName = e.target.getAttribute('name');
        if (repeatName && repeat !== parseInt(repeatName)) {
            setRepeat(parseInt(repeatName));
            setRepeatIndex(index);
        };
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (devices.length === 0 || genders.length === 0) {
            setErrors('Select at least one item.');
        };
        setIsSubmitting(true);
    };
    const fetchExtraFilters = async () => {
        localStorage.setItem('target_device', JSON.stringify(devices));
        localStorage.setItem('target_gender', JSON.stringify(genders));
        localStorage.setItem('repeat', repeat);
        dispatch(PtcFilters3Action({ porop: false }));
        dispatch(PtcFiltersAction({ prop: true }));
    };
    useEffect(() => {
        if (errors.length === 0 && isSubmitting) {
            fetchExtraFilters();
        };
    });
    return (
        <>
            <PtcForm action='#' onSubmit={handleSubmit}>
                <PtcFormInputContainer>
                    <FormHeader>
                        <h2>set extra optional filters</h2>
                    </FormHeader>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                Select the kind of devices that could display your ads.
                            </Instruction>
                        </InstructionWrap>
                        <FilterWrap>
                            {ptcDeviceData.map((device, index) => (
                                <ItemWrap key={index}>
                                    <ItemName name={device.name} onClick={(e) => { deviceClick(e, index) }} itemActive={deviceIndexList.includes(index) ? 1 : 0}>{device.device}</ItemName>
                                </ItemWrap>
                            ))}
                        </FilterWrap>
                    </FilterContainer>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                Select the gender of the user who could watch your ads.
                            </Instruction>
                        </InstructionWrap>
                        <FilterWrap>
                            {ptcGenderData.map((gender, index) => (
                                <ItemWrap key={index}>
                                    <ItemName name={gender.name} onClick={(e) => { genderClick(e, index) }} itemActive={genderIndexList.includes(index) ? 1 : 0}>{gender.gender}</ItemName>
                                </ItemWrap>
                            ))}
                        </FilterWrap>
                    </FilterContainer>
                    <FilterContainer>
                        <InstructionWrap>
                            <Instruction>
                                Select the duration that users could watch your ads again.
                            </Instruction>
                        </InstructionWrap>
                        <RepeatFilterWrap>
                            {ptcRepeatData.map((repeat, index) => (
                                <ItemWrap key={index}>
                                    <ItemName name={repeat.name} onClick={(e) => { repeatClick(e, index) }} itemActive={repeatIndex === index ? 1 : 0}>{repeat.duration}</ItemName>
                                </ItemWrap>
                            ))}
                        </RepeatFilterWrap>
                    </FilterContainer>
                </PtcFormInputContainer>
                {errors.length > 0 &&
                    <MsgWrap>
                        <MsgText>
                            {errors}
                        </MsgText>
                    </MsgWrap>}
                <FormButtonsWrap>
                    <TinyFormButton type='submit' primary={1} big={0}>
                        Next
                    </TinyFormButton>
                    <TinyFormButton type='button' primary={1} big={0} onClick={() => { dispatch(PtcFilters3Action({ prop: false })); dispatch(PtcFilters2Action({ prop: true })) }}>
                        back
                    </TinyFormButton>
                </FormButtonsWrap>
            </PtcForm>
        </>
    )
}
export default SetUpPtcFilter3;