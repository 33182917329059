import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { SidebarContainer, SidebarWrapper } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { FilterContainer, ButtonWrap, ButtonFilter } from '../OfferUserStatus/OfferUserStatusElements';
import { FilterWraper, InputSectionWrap, SectionNumberWrap, InputWrap, UplaodContainer, ImgInput, ImgSectionWrap, ImgSection, DescInputWrap, DescInput, SectionInput, PlusContainer, PlusWrap } from './SLUserStatusElements';
import { Circle } from '../Loading';
import { MsgWrap, MsgText } from '../MessageElements';
import { TinyFormButton } from '../ButtonElements';
import { slDeterminePriceForAssistance } from '../../data/slUserStatusData';

const EditModal = ({ editClick, setEditClick, assistance }) => {
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState();
    const [apiError2, setApiError2] = useState();
    const fileFormats = ['image/jpeg', 'image/png', 'image/gif'];
    const hiddenFileInput = React.useRef(null);
    const [numberSectionList, setnumberSectionList] = useState()
    const [activeSectionIndex, setActiveSectionIndex] = useState();
    const [desc, setDesc] = useState(Object.freeze({
	desc1: '',
	desc2: '',
	desc3: '',
	desc4: '',
	desc5: '',
	desc6: '',
    }));
    const [descError, setDescError] = useState({
	desc1: '',
	desc2: '',
	desc3: '',
	desc4: '',
	desc5: '',
	desc6: '',
    });
    const [file, setFile] = useState({
	file1: '',
	file2: '',
	file3: '',
	file4: '',
	file5: '',
	file6: '',
    });
    const [fileSelected, setFileSelected] = useState(Object.freeze({
	file1: '',
	file2: '',
	file3: '',
	file4: '',
	file5: '',
	file6: '',
    }));
    const [fileError, setFileError] = useState({
	file1: '',
	file2: '',
	file3: '',
	file4: '',
	file5: '',
	file6: '',
    });
    const [section, setSection] = useState({
	sec1: '',
	sec2: '',
	sec3: '',
	sec4: '',
	sec5: '',
	sec6: '',
    });
    const [blogID, setBlogID] = useState({
	id1: '',
	id2: '',
	id3: '',
	id4: '',
	id5: '',
	id6: '',
    });
    const [price, setPrice] = useState();
    const [priceIndex, setPriceIndex] = useState();
    const [composeDone, setComposeDone] = useState(false);
    useEffect(() => {
	const fetchSLStartEdit = async () => {
	    try {
		const { data } = await axiosInstance.post('shortlink/assistant/start-edit/', { 'id': assistance.id });
		setInfo(data.info);
		let numberList = []
		data.info.blog_assistant_sl.map((item, index) => numberList.push(index + 1));
		setnumberSectionList(numberList);
		setDesc({
		    desc1: data.info.blog_assistant_sl.map((item) => item['description'])[0],
		    desc2: data.info.blog_assistant_sl.map((item) => item['description'])[1],
		    desc3: data.info.blog_assistant_sl.map((item) => item['description'])[2] !== undefined ? data.info.blog_assistant_sl.map((item) => item['description'])[2] : '',
		    desc4: data.info.blog_assistant_sl.map((item) => item['description'])[3] !== undefined ? data.info.blog_assistant_sl.map((item) => item['description'])[3] : '',
		    desc5: data.info.blog_assistant_sl.map((item) => item['description'])[4] !== undefined ? data.info.blog_assistant_sl.map((item) => item['description'])[4] : '',
		    desc6: data.info.blog_assistant_sl.map((item) => item['description'])[5] !== undefined ? data.info.blog_assistant_sl.map((item) => item['description'])[5] : '',
		});
		setFile({
		    file1: data.info.blog_assistant_sl.map((item) => item['image'])[0],
		    file2: data.info.blog_assistant_sl.map((item) => item['image'])[1],
		    file3: data.info.blog_assistant_sl.map((item) => item['image'])[2] !== undefined ? data.info.blog_assistant_sl.map((item) => item['image'])[2] : '',
		    file4: data.info.blog_assistant_sl.map((item) => item['image'])[3] !== undefined ? data.info.blog_assistant_sl.map((item) => item['image'])[3] : '',
		    file5: data.info.blog_assistant_sl.map((item) => item['image'])[4] !== undefined ? data.info.blog_assistant_sl.map((item) => item['image'])[4] : '',
		    file6: data.info.blog_assistant_sl.map((item) => item['image'])[5] !== undefined ? data.info.blog_assistant_sl.map((item) => item['image'])[5] : '',
		});
		setSection({
		    sec1: data.info.blog_assistant_sl.map((item) => item['section'])[0],
		    sec2: data.info.blog_assistant_sl.map((item) => item['section'])[1],
		    sec3: data.info.blog_assistant_sl.map((item) => item['section'])[2] !== undefined ? data.info.blog_assistant_sl.map((item) => item['section'])[2] : '',
		    sec4: data.info.blog_assistant_sl.map((item) => item['section'])[3] !== undefined ? data.info.blog_assistant_sl.map((item) => item['section'])[3] : '',
                    sec5: data.info.blog_assistant_sl.map((item) => item['section'])[4] !== undefined ? data.info.blog_assistant_sl.map((item) => item['section'])[4] : '',
		    sec6: data.info.blog_assistant_sl.map((item) => item['section'])[5] !== undefined ? data.info.blog_assistant_sl.map((item) => item['section'])[5] : '',
		});
		setBlogID({
		    id1: data.info.blog_assistant_sl.map((item) => item['id'])[0],
		    id2: data.info.blog_assistant_sl.map((item) => item['id'])[1],
		    id3: data.info.blog_assistant_sl.map((item) => item['id'])[2] !== undefined ? data.info.blog_assistant_sl.map((item) => item['id'])[2] : '',
		    id4: data.info.blog_assistant_sl.map((item) => item['id'])[3] !== undefined ? data.info.blog_assistant_sl.map((item) => item['id'])[3] : '',
		    id5: data.info.blog_assistant_sl.map((item) => item['id'])[4] !== undefined ? data.info.blog_assistant_sl.map((item) => item['id'])[4] : '',
		    id6: data.info.blog_assistant_sl.map((item) => item['id'])[5] !== undefined ? data.info.blog_assistant_sl.map((item) => item['id'])[5] : '',
		});
		setPrice(data.info.pcoin);
		setPriceIndex(data.info.pcoin === 100 ? 1 : data.info.pcoin === 150 ? 2 : data.info.pcoin === 200 ? 3 : 0);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	};
	fetchSLStartEdit();
    }, [assistance.id]);
    const handleUploadClick = (i) => {
	setActiveSectionIndex(i);
    };
    const handleDimensionCheck = (e) => {
	var img = document.createElement("img");
	img.onload = function () {
	    if (this.width > 1080 || this.height > 1080) {
	        setFileError({
		    ...fileError,
		    ['file' + activeSectionIndex]: 'The maximum size of images is 1080 x 1080'
	        });
	    } else {
	        setFileSelected(e.target.files[0]);
	        setFileSelected({
		    ...fileSelected,
		    ['file' + activeSectionIndex]: e.target.files[0]
	        });
	    };
        };
	var reader = new FileReader();
	reader.onloadend = function (ended) {
	    img.src = ended.target.result;
	    setFile({
		...file,
		['file' + activeSectionIndex]: img.src
	    });
	}
	reader.readAsDataURL(e.target.files[0]);
    };
    const handleFileChange = (e) => {
	setFileError({
	    ...fileError,
	    ['file' + activeSectionIndex]: ''
	});
	setFile({
	    ...file,
	    ['file' + activeSectionIndex]: ''
	});
	if (e.target.files.length > 0) {
	    const uplaodFileType = e.target.files[0].type;
	    if (uplaodFileType && fileFormats.includes(uplaodFileType)) {
		const fileSize = e.target.files[0].size / 1024;
		if (fileSize <= 1024) {
		    handleDimensionCheck(e);
		} else {
		    setFileError({
			...fileError,
			['file' + activeSectionIndex]: 'This file is too large to uplaod. The maximum supported size is 1024 kb.'
		    });
		}
	    } else {
		setFileError({
		    ...fileError,
		    ['file' + activeSectionIndex]: 'Not supported the extension of the file, please uplaod jpej family, png and gif formats.'
		});
	    };
	};
    };
    const handleChange = (e) => {
	setDescError({
	    ...descError,
	    ['desc' + activeSectionIndex]: ''
	});
	const { name, value } = e.target;
	setDesc({
	    ...desc,
	    [name]: value,
	});
    };
    const handleChangeSectionNumber = (e) => {
	const { name, value } = e.target;
	setSection({
	    ...section,
	    [name]: value,
	});
    };
    const handlePlusClick = () => {
	setnumberSectionList(numberSectionList.concat(numberSectionList.length + 1));
    };
    const handlePriceCLick = (PRICE, index) => {
	setPrice(PRICE);
	setPriceIndex(index);
	ValidateForm();
    };
    const ValidateForm = () => {
	Object.keys(file).map((img, index) => file[img].length > 0 && desc['desc' + (index + 1)].length === 0 && setDescError({ ...descError, ['desc' + (index + 1)]: 'Please enter a description for your image!' }));
	Object.keys(desc).map((d, index) => desc[d].length > 0 && file['file' + (index + 1)].length === 0 && setFileError({ ...fileError, ['file' + (index + 1)]: 'Please uplaod an image!' }));
    };
    const handleClickEdit = (e) => {
	e.preventDefault();
	fetchSLAssistantEndEdit();
    };
    const fetchSLAssistantEndEdit = async () => {
	try {
	    let fd = new FormData();
	    Object.entries(fileSelected).map(([key, value]) => fd.append(key, value));
	    Object.entries(desc).map(([key, value]) => fd.append(key, value));
	    Object.entries(section).map(([key, value]) => fd.append(key, value));
	    Object.entries(blogID).map(([key, value]) => fd.append(key, value));
	    fd.append('sl_model', assistance.id);
	    fd.append('pcoin', price);
	    await axiosInstance.post('shortlink/assistant/end-edit/', fd, { headers: { "Content-Type": "multipart/form-data" } });
	    setComposeDone(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError2(error.response.data.detail) : setApiError2(error.message);
	};
    };
    return (
	<SidebarContainer isOpen={editClick ? 1 : 0}>
	    <SidebarWrapper>
		<CloseIconWrap onClick={() => setEditClick(false)}>
	            <CloseIcon />
	        </CloseIconWrap>
	        {loading &&
		    <div style={{ width: '100%', height: '90%', display: 'flex', alignItems: 'center' }}>
		        <Circle style={{ margin: 'auto', width: '50px', height: '50px' }} />
		    </div>
	        }
	        {!loading && apiError &&
		    <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto auto auto' }}>
			<MsgText primary={0}>
			    {apiError}
		        </MsgText>
		    </MsgWrap>
	        }
	    	{!loading && info !== undefined && Object.keys(info).length > 0 &&
		    <form style={{ width: '100%' }} action='#'>
			{numberSectionList !== undefined && numberSectionList.map((i, index) => (
			    <InputSectionWrap key={index}>
				<SectionNumberWrap>
				    <h4>
				        section<br />
				        {i}
				    </h4>
				</SectionNumberWrap>
				<InputWrap>
				    <UplaodContainer>
				        <ImgInput
				            type='file'
				            multiple
				            name={'file' + i}
				            accept='image/jpeg, image/png, image/gif'
				            onChange={handleFileChange}
				            ref={hiddenFileInput}
				            style={{ display: 'none' }}
				        />
				        {file['file' + i] && !fileError['file' + i] &&
					    <ImgSectionWrap>
						<ImgSection src={file['file' + i]} alt='section image' />
					    </ImgSectionWrap>
					}
					<TinyFormButton type='button' primary={1} big={0} style={{ width: '160px', marginTop: '5px' }} onClick={() => { hiddenFileInput.current.click(); handleUploadClick(i) }}>
				            uplaod your image
				        </TinyFormButton>
				    </UplaodContainer>
				    <DescInputWrap>
				    	<DescInput
				            multiple
				            name={'desc' + i}
				            placeholder='Enter your description about your image.'
				            onChange={handleChange}
				            value={desc['desc' + i]}
				        />
				        <SectionInput
				            multiple
				            type='text'
				            name={'sec' + i}
				            placeholder='Enter the number of section.'
				            onChange={handleChangeSectionNumber}
				            value={section['sec' + i]}
				        />
				        {fileError['file' + i] &&
					    <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
					        <MsgText primary={0}>
					            {fileError['file' + i]}
					        </MsgText>
					    </MsgWrap>
					}
				        {descError['desc' + i] &&
					    <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
						<MsgText primary={0}>
						    {descError['desc' + i]}
					        </MsgText>
					    </MsgWrap>
					}
				    </DescInputWrap>
				</InputWrap>
			    </InputSectionWrap>
			))}
			{numberSectionList.length <= 5 &&
			    <PlusContainer>
			    	<PlusWrap onClick={handlePlusClick}>
				    <span>+</span>
			    	</PlusWrap>
			    </PlusContainer>
			}
			<FilterContainer>
			    <p>Change your price:</p>
			    <FilterWraper>
				{slDeterminePriceForAssistance.map((button, index) => (
				    <ButtonWrap key={index}>
					<ButtonFilter onClick={() => handlePriceCLick(button.price, index)} itemActive={index === priceIndex ? 1 : 0}>{button.name}</ButtonFilter>
				    </ButtonWrap>
				))}
			    </FilterWraper>
			</FilterContainer>
			{composeDone &&
			    <MsgWrap primary={1} style={{ width: '80%', margin: '15px auto' }}>
				<MsgText primary={1}>
				    Your edit was successfully done. Your assistance needs to be reviewed again.
				</MsgText>
			    </MsgWrap>
			}
			{apiError2 &&
			    <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto' }}>
				<MsgText primary={0}>
				    {apiError2}
				</MsgText>
			    </MsgWrap>
			}
			{!composeDone && !apiError2 &&
			    <TinyFormButton style={{ width: '200px', margin: '15px auto' }} type='button' onClick={handleClickEdit} primary={1} big={0}>
				update all
			    </TinyFormButton>
			}
		    </form>
		}
	    </SidebarWrapper>
	</SidebarContainer>
    )
}
export default EditModal;
