import {
    SHOW_SPONSORSETUP,
    NOTSHOW_SPONSORSETUP,
    SHOW_SPONSORFILTERS,
    NOTSHOW_SPONSORFILTERS,
    SHOW_SPONSOREND,
    NOTSHOW_SPONSOREND,
    SHOW_SPONSORSUCCESS,
    NOTSHOW_SPONSORSUCCESS,
} from '../constants/sponsorSetUpRedux';

export const sponsorSetUpReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_SPONSORSETUP):
            return true
        case (NOTSHOW_SPONSORSETUP):
            return false
        default:
            return state
    }
};

export const sponsorFiltersReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_SPONSORFILTERS):
            return true
        case (NOTSHOW_SPONSORFILTERS):
            return false
        default:
            return state
    }
};

export const sponsorSetUpEndReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_SPONSOREND):
            return true
        case (NOTSHOW_SPONSOREND):
            return false
        default:
            return state
    }
};

export const sponsorSuccessReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_SPONSORSUCCESS):
            return true
        case (NOTSHOW_SPONSORSUCCESS):
            return false
        default:
            return state
    }
};