import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const StackContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    padding: 25px;
`
export const StackWrap = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.2)' : 'rgba(39, 117, 85, 0.2)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : 'rgba(39, 117, 85, 0.3)')};
    box-shadow: 0 0 20px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    border-radius: 10px;
    padding: 10px;
`
export const StackDiscWrap = styled.div`
    width: 100%;
    p {
        color: #fff;
        font-size: clamp(0.8rem, 3vw, 0.95rem);
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
        line-height: 2;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
    }
`
export const TitleWrap = styled.div`
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : 'rgba(39, 117, 85, 0.5)')};
    h2 {
        color: rgba(1, 219, 187, 1);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(0.85rem, 3.5vw, 0.99rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
        padding: 10px 3px;
    }
`
export const RentContainer = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 8%;
    margin: 15px 0;
    align-items: center;
    justify-content: center;

    @media screen and ${device.lg} {
        grid-template-columns: 1fr;
        row-gap: 25px;
    }
`
export const RentForm = styled.form`
    background: rgba(29, 107, 187, 0.5);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 2px 10px  rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;

    @media screen and ${device.lg} {
        width: 75%;
    }
    @media screen and ${device.sm} {
        width: 100%;
    }
`
export const RentInstruction = styled.div`
    margin-top: 5px; 
    p {
        color: #fff;
        font-size: clamp(0.8rem, 3vw, 0.95rem);
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
        letter-spacing: 1.1px;
        word-spacing: 1.5px;
    }
`
export const ButtonWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 8%;
    margin: 5px auto;

    @media screen and ${device.lg} {
        grid-template-columns: 1fr 1fr;
        row-gap: 15px;
    }
    @media screen and ${device.sm} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`
export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
`
export const StackTable = styled.table`
    border-collapse: collapse;
    border-left: 2px solid rgba(29, 107, 187, 0.7);
    border-right: 2px solid rgba(29, 107, 187, 0.7);
    width: 90%;
    margin: 15px 0;
`
export const StackHeadWrap = styled.thead`
    width: 100%;
`
export const StackHeadRow = styled.tr`
    width: 100%;
`
export const StackHeader = styled.th`
    color: rgba(1, 219, 187, 1);
    text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
    font-size: clamp(0.6rem, 3.5vw, 0.85rem);
    font-weight: 400;
    letter-spacing: 1.2px;
    word-spacing: 1.5px;
    text-transform: uppercase;
    padding: 15px 3px;
    background: rgba(39, 117, 85, 1);

    @media screen and ${device.lg} {
        padding: 15px 2px;
    }
`
export const StackBodyWrap = styled.tbody``
export const StackBodyRow = styled.tr`
    &:nth-child(2) {
        background: rgba(29, 107, 187, 0.1);
    }
    &:nth-child(3) {
        background: rgba(29, 107, 187, 0.25);
    }
    &:nth-child(4) {
        background: rgba(29, 107, 187, 0.35);
    }
    &:nth-child(5) {
        background: rgba(29, 107, 187, 0.48);
    }
    &:nth-child(6) {
        background: rgba(29, 107, 187, 0.6);
    }
    &:nth-child(7) {
        background: rgba(29, 107, 187, 0.75);
    }
    &:nth-child(8) {
        background: rgba(29, 107, 187, 1);
    }
`
export const StackCell = styled.td`
    border-bottom: 2px solid rgba(29, 107, 187, 0.7);
    text-align: center;
    color: #fff;
    font-size: clamp(0.7rem, 3.5vw, 0.85rem);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    padding: 10px 3px;
    

    &:nth-child(1) {
        letter-spacing: 1.2px;
        word-spacing: 1.5px;
        text-transform: uppercase;
    }

    &:nth-child(even) {
        background: rgba(39, 117, 85, 0.1);
    }
`
