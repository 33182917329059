import styled, { css } from 'styled-components';
import device from '../../constants/breakPoints';
import { FiLink } from 'react-icons/fi';
import { MdDescription } from 'react-icons/md';
import { ItemWrap, ItemName } from './SetUpPtcFilterElements';

export const PtcForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    width: 80%;
    height:96%;
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(39, 117, 85, 0.5);
    box-shadow: 0 2px 5px rgba(39, 117, 85, 0.7);
    padding: 0 10px 10px 10px;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and ${device.lg} {
        width: 90%;
    }}

    @media screen and ${device.sm} {
        width: 100%;
    }}

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5); 
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(1, 219, 187, 0.5);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background:rgba(1, 219, 187, 0.5);
    }
`
export const PtcFormInputContainer = styled.div`
    display: flex;  
    flex-direction: column;
    align-items: center;
    width: 90%;
`
export const FilterLangWrap = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0;
`
export const FormLangContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
export const FormLangWrap = styled.div`
    display: grid;
    grid-column-gap: 25px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media screen and ${device.lg} {
        grid-column-gap: 10px;
    }}

    @media screen and ${device.sm} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
    }}
`
export const LangWrap = styled(ItemWrap)`
    width: 72px;
    height: 35px;

    @media screen and ${device.sm} {
        width: 60px;
        height: 35px;
    }}
`
export const LangName = styled(ItemName)`
    font-size: clamp(0.65rem, 1.7vw, 0.72rem);
`
export const PtcInputWrap = styled.div`
    position: relative;
    display: flex;  
    align-items: center;
    width: 100%;
`
export const PtcFormInput = styled.input`
    padding: 5px 10px 6px 40px;
    margin: 10px 0 5px 0;
    border: none;
    border-radius: 20px;
    font-size: 1.05rem;
    width:100%;

    ::placeholder {
        color: rgba(29, 107, 187, 0.7);
        font-size: 0.9rem;
    }
    &:focus {
        outline: 4px solid #1D6BBB;;
    }
`
export const FormSocialContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
`
export const FormSocialWrap = styled.div`
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 7px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @media screen and ${device.md} {
        grid-column-gap: 20px;
    }}
    @media screen and ${device.sm} {
        grid-column-gap: 8px;
    }}
`
export const SocialIconWrap = styled.div`
    width: 40px;
    height: 40px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: ${({ iconActive }) => (iconActive === 'active'
        ? '#01DBBB'
        : iconActive === 'clicked'
            ? '#1D6BBB'
            : '#277555')};
    border-radius: 50%;
    
    color: #fff;
    font-size: 1rem;
    transition: 0.3s ease-in-out;

    &:hover {
        transform: scale(1.11);
        background: ${({ iconActive }) => (iconActive === 'active'
        ? '#01DBBB'
        : iconActive === 'clicked'
            ? '#1D6BBB'
            : '#1D6BBB')};
        transition: 0.3s ease-in-out;
    }
`
export const SocialImg = styled.img`
    width: 85%;
    height: 85%;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
`
export const PtcIcons = css`
    position: absolute;
    transform: translate(75%, 18%);
    color: #1D6BBB;
    font-size: 1.1rem;
    opacity: 0.70;
`
export const PtcLinkIcon = styled(FiLink)`
    ${PtcIcons}
`
export const PtcTitleIcon = styled(MdDescription)`
    ${PtcIcons}
`
export const VideoWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(1, 219, 187, 1);
    box-shadow: 0 0 5px rgba(1, 219, 187, 0.7);
    width: 95%;
    height: 330px;
    padding: 15px;
    margin: 10px auto;

    @media screen and ${device.sm} {
        height: 280px;
    }}

    @media screen and ${device.tn} {
        padding: 5px;
        width: 100%;
        height: 220px;
    }}
`