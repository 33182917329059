import React from 'react';
import { Helmet } from "react-helmet";
import TOS from '../components/TOS';
import { TOSData } from '../data/TOSData';

const TOSPage = ({ code }) => {
    return (
	<>
	    <Helmet>
	        <meta name="description" content="These terms of service apply to you as a user of iceFaucet." />
	        <title>iceFaucet | terms of service</title>
	    </Helmet>
            <TOS code={code} info={TOSData} />
	</>
    )
}
export default TOSPage;
