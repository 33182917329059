import React from 'react';
import { Helmet } from "react-helmet";
import { Header, LeftWrap, LogoWrapLink, SubjectWrap } from '../components/TOS/TOSElements';
import { BigContainer, ContainerLogout, WrapperLogout, ContentWrap } from '../components/Auth/LogoutElements';
import logo from '../images/logo.png';
import { MsgWrap, MsgText } from '../components/MessageElements.js';

const NotFoundPage = () => {
    return (
        <>
	    <Helmet>
	        <meta name="description" content="There is no such page on iceFaucet." />
	        <title>iceFaucet | 404 not found</title>
	    </Helmet>
            <BigContainer>
                <ContainerLogout>
                    <WrapperLogout>
                        <Header style={{ background: 'rgba(29, 107, 187, 0.4)' }}>
                            <LeftWrap>
                                <LogoWrapLink to='/'>
                                    <img src={logo} alt={'logo of icefaucet'} />
                                </LogoWrapLink>
                            </LeftWrap>
                            <SubjectWrap>
                                <h1>not found page</h1>
                            </SubjectWrap>
                        </Header>
                        <ContentWrap>
                            <MsgWrap primary={0}>
                                <MsgText primary={0}>
                                    The page you are looking for DOES NOT EXIST.
                                </MsgText>
                            </MsgWrap>
                        </ContentWrap>
                    </WrapperLogout>
                </ContainerLogout>
            </BigContainer>
        </>
    )
}
export default NotFoundPage;
