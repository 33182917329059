import {
    SHOW_MININGSETUP,
    NOTSHOW_MININGSETUP,
    SHOW_MININGEND,
    NOTSHOW_MININGEND,
    SHOW_MININGSUCCESS,
    NOTSHOW_MININGSUCCESS
} from '../constants/miningSetUpRedux';

export const miningSetUpReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_MININGSETUP):
            return true
        case (NOTSHOW_MININGSETUP):
            return false
        default:
            return state
    }
};

export const miningSetUpEndReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_MININGEND):
            return true
        case (NOTSHOW_MININGEND):
            return false
        default:
            return state
    }
};

export const miningSuccessReducer = (state = false, action) => {
    switch (action.type) {
        case (SHOW_MININGSUCCESS):
            return true
        case (NOTSHOW_MININGSUCCESS):
            return false
        default:
            return state
    }
};