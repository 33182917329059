import React from 'react';
import { Helmet } from "react-helmet";
import AboutUs from '../components/AboutUs';
import { AboutUsData } from '../data/AboutUsData';

const AboutUsPage = ({ code }) => {
    return (
	<>
	    <Helmet>
	        <meta name="description" content="You could know about iceFaucet website and icefa team here." />
	        <title>iceFaucet | about iceFaucet</title>
	    </Helmet>
            <AboutUs code={code} info={AboutUsData} />
	</>
    )
}
export default AboutUsPage;
