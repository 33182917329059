import styled from 'styled-components';
import { MdHelp } from 'react-icons/md';
import device from '../constants/breakPoints';

export const HelpIcon = styled(MdHelp)`
    color: ${({ show }) => (show ? '#01DBBB' : '#FFF')};
    margin-left: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    position: relative;
    &:hover {
        color: #01DBBB;
        transform: scale(1.11);
        transition: 0.3s ease-in-out;
    }
`
export const HelpWrap = styled.div`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(1, 219, 187, 0.5);
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    width: 90%;
    max-width: 350px;
    height: 90px;
    margin: 10px auto 0 auto;
    padding: 5px;
    overflow: auto;

    @media screen and ${device.tn} {
        width: 98%;
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(1, 219, 187, 0.5); 
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(1, 219, 187, 0.5);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background:rgba(1, 219, 187, 0.5);
    }
    p {
        color: #01DBBB;
        font-size: 0.85rem;
        space-letter: 0.5px;
        space-word: 1px;
    }
    strong {
        color: red;
    }
`
