import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useDispatch } from 'react-redux';
import { resetCodeAction, resetPasswordFormAction } from '../../actions/authAction';
import { ValidateInfoReset } from './ValidateInfo';
import { FormContainer, FormWrap, Form } from './SignInElements';
import { FormButton } from '../ButtonElements';
import { RPInstroduction } from './ResetPassElements';
import { ContentWrap, ResetCodeWrap, NumberFormatCode, TimerResetCode } from './ResetCodeElements';
import Countdown, { zeroPad } from 'react-countdown';
import { MsgWrap, MsgText } from '../MessageElements.js'

const ResetCode = ({ authDataLang }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(Object.freeze({ code: '' }));
    const [errors, setErrors] = useState({ code: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [expired, setExpired] = useState(false);
    const [timer, setTimer] = useState(parseInt(localStorage.getItem('resetPassTimer')));
    const renderer = ({ minutes, seconds }) => {
        return <TimerResetCode>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerResetCode>;
    };
    const handleChangeCode = (e) => {
        setValue({
            code: e.target.value.replace(/ /g, '').replace('_', '')
        });
        setErrors({});
        setIsSubmitting(false);
    };
    const handleSubmitCode = (e) => {
        e.preventDefault();
        setErrors(ValidateInfoReset(value));
        setIsSubmitting(true);
    };
    const fetchResetCode = async () => {
        try {
            await axiosInstance.post('confirm/reset/password/', {
                code: value.code,
                id: localStorage.getItem('id')
            });
            dispatch(resetCodeAction({ prop: false }));
            dispatch(resetPasswordFormAction({ prop: true }));
        } catch (error) {
            setErrors({ code: error.response && error.response.data.detail ? error.response.data.detail : error.message });
        };
        setIsSubmitting(false);
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            fetchResetCode();
        };
    });
    return (
        <FormContainer>
            <FormWrap>
                <Form action='#' onSubmit={handleSubmitCode}>
                    {!expired &&
                        <ContentWrap>
                            <h1>{authDataLang.resetCodeHeader}</h1>
                            <RPInstroduction>{authDataLang.resetCodeInstruction}</RPInstroduction>
                            <Countdown
                                date={timer}
                                renderer={renderer}
                                onComplete={() => {
                                    if (localStorage.getItem('id') && localStorage.getItem('resetPassTimer')) {
                                        setExpired(true);
                                        localStorage.removeItem('id');
                                        localStorage.removeItem('resetPassTimer');
                                        setTimer(0);
                                    };
                                }}
                            />
                            <ResetCodeWrap>
                                <NumberFormatCode
                                    format={"#  #  #  #  #  #"}
                                    allowEmptyFormatting mask="_"
                                    value={value.code}
                                    onChange={handleChangeCode}
                                />
                            </ResetCodeWrap >
                            {errors.code && <p>{errors.code}</p>}
                            <FormButton type='submit' primary={0}>{authDataLang.resetCodeHeader}</FormButton>
                        </ContentWrap>
                    }
                    {expired && <MsgWrap primary={0}>
                        <MsgText primary={0}>
                            The code is expired. Please try again.
                        </MsgText>
                    </MsgWrap>
                    }
                </Form>
            </FormWrap>
        </FormContainer>
    )
}
export default ResetCode;
