import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const SizeBannerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0;
`
export const SizeBannerWrap = styled.div`
    display: grid;
    grid-column-gap: 35px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    margin: 10px 0;

    @media screen and ${device.tn} {
        grid-template-columns: 1fr 1fr;
    }
`
export const ImgBannerWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(39, 117, 85, 0.75);
    border: 1px solid rgba(1, 219, 187, 1);
    box-shadow: 0 0 5px rgba(1, 219, 187, 0.7);
    width: 95%;
    padding: 15px 0;
`
export const ImgBanner = styled.img`
    width: ${({ size }) => (size === 'd' ? '95%' : size === 'e' ? '160px' : '75%')};
    height: ${({ size }) => (size === 'd' ? '80px' : null)};
`