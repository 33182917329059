import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { HistoryBody, CountTotalContainer, CountTotalWrap, TitleWrap, DescWrap } from './DepositTabElements';
import { PromotionContainer } from '../Promotions/ReferralLinkTabElements';
import { Table, HeadWrap, HeadRow, Head, BodyWrap, BodyRow, Cell } from '../Payments/PaymentsElements';
import { headProfitTable } from '../../data/historyData';
import { MsgWrap, MsgText } from '../MessageElements';
import moment from 'moment';
import { Circle } from '../Loading';

const ProfitTab = () => {
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const fetchProfitHistory = async () => {
        setLoading(true);
        try {
            const { data } = await axiosInstance.get('earning/history/profit/');
            setInfo(data);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
        setLoading(false);
    };
    useEffect(() => {
        fetchProfitHistory();
    }, []);
    return (
        <PromotionContainer>
            <HistoryBody>
                {loading &&
                    <div style={{ marginTop: '50px' }}>
                        <Circle style={{ margin: 'auto', width: '50px', height: '50px' }} />
                    </div>
                }
                {!loading && info !== undefined && apiError.length === 0 &&
                    <>
                        <CountTotalContainer>
                            <CountTotalWrap>
                                <TitleWrap>
                                    <h2>count</h2>
                                </TitleWrap>
                                <DescWrap>
                                    <h2>{info.count}</h2>
                                </DescWrap>
                            </CountTotalWrap>
                            <CountTotalWrap>
                                <TitleWrap>
                                    <h2>total</h2>
                                </TitleWrap>
                                <DescWrap>
                                    <h2>{info.total} icecoin</h2>
                                </DescWrap>
                            </CountTotalWrap>
                        </CountTotalContainer>
                        <Table style={{ width: '100%' }}>
                            <HeadWrap>
                                <HeadRow>
                                    {headProfitTable.map((item, index) => (
                                        <Head key={index}>
                                            {item}
                                        </Head>
                                    ))}
                                </HeadRow>
                            </HeadWrap>
                            <BodyWrap>
                                {info.info.map((row, index) => (
                                    <BodyRow key={index}>
                                        <Cell>
                                            {row.icecoin}
                                        </Cell>
                                        <Cell style={{ fontSize: '0.8rem' }}>
                                            {moment(row.created).fromNow()}
                                        </Cell>
                                    </BodyRow>
                                ))}
                            </BodyWrap>
                        </Table>
                    </>
                }
                {!loading && apiError.length > 0 &&
                    <MsgWrap style={{ width: '80%', margin: '0 auto' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
            </HistoryBody>
        </PromotionContainer >
    )
}
export default ProfitTab;
