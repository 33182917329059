import React from 'react';
import { ABContainer, ABWrapItem } from './AccountBalanceElements';
import ABCards from './ABCards';
import { ABData } from '../../data/dashboardData';

const AccountBalance = ({ info }) => {
    return (
	<ABContainer>
	    <ABWrapItem>
		{ABData.map((item, index) => (
		    <ABCards key={index} info={item} serverInfo={info} />
		))}
	    </ABWrapItem>
	</ABContainer>
    )
}
export default AccountBalance
