import React from 'react';
import { useSelector } from 'react-redux';
import { SetUpContainer, SetUpBgWrap, AdsSetUpContainer, AdsContent } from '../SetUpAds/SetUpAddsElements';
import setupPtcBg from '../../images/Auth_background.jpg';
import SetUpAdsNavigate from './SetUpAdsNavigate';
import CreditCheck from './CreditCheck';
import SetUpPtcForm from './SetUpPtcForm';
import SetUpPtcFilter from './SetUpPtcFilter';
import SetUpPtcFilter2 from './SetUpPtcFilter2';
import SetUpPtcFilter3 from './SetUpPtcFilter3';
import SetUpPtcEnd from './SetUpPtcEnd';
import SetUpPtcSuccess from './SetUpPtcSuccess';

const SetUpPtc = () => {
    const showCreditCheck = useSelector(state => state.creditCheck);
    const showPtcSetUp = useSelector(state => state.ptcSetUp);
    const showPtcFilters = useSelector(state => state.ptcFilters);
    const showPtcFilters2 = useSelector(state => state.ptcFilters2);
    const showPtcFilters3 = useSelector(state => state.ptcFilters3);
    const showPtcSetUpEnd = useSelector(state => state.ptcSetUpEnd);
    const showPtcSuccess = useSelector(state => state.ptcSuccess);
    return (
        <SetUpContainer>
            <SetUpBgWrap>
                <img src={setupPtcBg} alt={'background of set up PTC page'} />
            </SetUpBgWrap>
            <AdsSetUpContainer>
                <AdsContent>
                    {!showCreditCheck && !showPtcSuccess && <SetUpAdsNavigate />}
                    {showCreditCheck && !showPtcSetUp && !showPtcFilters && !showPtcFilters2 && !showPtcFilters3 && !showPtcSetUpEnd && !showPtcSuccess
                        ? <CreditCheck />
                        : showPtcSetUp && !showCreditCheck && !showPtcFilters && !showPtcFilters2 && !showPtcFilters3 && !showPtcSetUpEnd && !showPtcSuccess
                            ? <SetUpPtcForm />
                            : showPtcFilters && !showCreditCheck && !showPtcSetUp && !showPtcFilters2 && !showPtcFilters3 && !showPtcSetUpEnd && !showPtcSuccess
                                ? <SetUpPtcFilter />
                                : showPtcFilters2 && !showCreditCheck && !showPtcSetUp && !showPtcFilters && !showPtcFilters3 && !showPtcSetUpEnd && !showPtcSuccess
                                    ? <SetUpPtcFilter2 />
                                    : showPtcFilters3 && !showCreditCheck && !showPtcSetUp && !showPtcFilters && !showPtcFilters2 && !showPtcSetUpEnd && !showPtcSuccess
                                        ? <SetUpPtcFilter3 />
                                        : showPtcSetUpEnd && !showCreditCheck && !showPtcSetUp && !showPtcFilters && !showPtcFilters2 && !showPtcFilters3 && !showPtcSuccess
                                            ? <SetUpPtcEnd />
                                            : showPtcSuccess && !showCreditCheck && !showPtcSetUp && !showPtcFilters && !showPtcFilters2 && !showPtcFilters3 && !showPtcSetUpEnd
                                                ? <SetUpPtcSuccess />
                                                : null}
                </AdsContent>
            </AdsSetUpContainer>
        </SetUpContainer>
    )
}
export default SetUpPtc