import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { HistoryBody } from './DepositTabElements';
import { PromotionContainer } from '../Promotions/ReferralLinkTabElements';
import { Table, HeadWrap, HeadRow, Head, BodyWrap, BodyRow, Cell } from '../Payments/PaymentsElements';
import { headLoginTable, smallHeadLoginTable } from '../../data/historyData';
import { MsgWrap, MsgText } from '../MessageElements';
import moment from 'moment';
import { Circle } from '../Loading';

const LoginTab = ({ smallView }) => {
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const fetchProfitHistory = async () => {
        setLoading(true);
        try {
            const { data } = await axiosInstance.get('history/login/');
            setInfo(data);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
        setLoading(false);
    };
    useEffect(() => {
        fetchProfitHistory();
    }, []);
    return (
        <PromotionContainer>
            <HistoryBody>
                {loading &&
                    <div style={{ marginTop: '50px' }}>
                        <Circle style={{ margin: 'auto', width: '50px', height: '50px' }} />
                    </div>
                }
                {!loading && info !== undefined && apiError.length === 0 &&
                    <Table style={{ width: '100%' }}>
                        <HeadWrap>
                            <HeadRow>
                                {!smallView && headLoginTable.map((item, index) => (
                                    <Head key={index}>
                                        {item}
                                    </Head>
                                ))}
                                {smallView && smallHeadLoginTable.map((item, index) => (
                                    <Head key={index}>
                                        {item}
                                    </Head>
                                ))}
                            </HeadRow>
                        </HeadWrap>
                        <BodyWrap>
                            {info.info.map((row, index) => (
                                <BodyRow key={index}>
                                    <Cell>
                                        {row.ip}
                                    </Cell>
                                    <Cell>
                                        {row.device}
                                    </Cell>
                                    {!smallView &&
                                        <>
                                            <Cell>
                                                {row.browser}
                                            </Cell>
                                            <Cell>
                                                {row.os}
                                            </Cell>
                                        </>
                                    }
                                    <Cell style={{ fontSize: '0.8rem' }}>
                                        {moment(row.created).fromNow()}
                                    </Cell>
                                </BodyRow>
                            ))}
                        </BodyWrap>
                    </Table>
                }
                {apiError.length > 0 &&
                    <MsgWrap style={{ width: '80%', margin: '0 auto' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
            </HistoryBody>
        </PromotionContainer >
    )
}
export default LoginTab;