import React, { useState, useEffect } from 'react';
import { FormContainer, FormWrap, Form, InputWrap, FormInput, EmailIcon, PasswordIcon, ForgotPasswordWrap } from './SignInElements';
import { FormButton } from '../ButtonElements';
import { ValidateInfoLog } from './ValidateInfo';
import axiosInstance from '../../axios';
import { useDispatch, useSelector } from 'react-redux';
import { loginTabAction, resetPasswordAction } from '../../actions/authAction';
import ResetPass from './ResetPass';
import { Circle } from '../Loading';

const SignIn = ({ authDataLang }) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState(Object.freeze({
        email: '',
        password: '',
    }));
    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const resetPass = useSelector(state => state.resetPassword);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        setErrors({ email: '' });
        setIsSubmitting(false);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(ValidateInfoLog(values));
        setIsSubmitting(true);
    };
    const fetchLogin = async () => {
	setLoading(true);
        try {
            await axiosInstance.post('login/', {
                email: values.email,
                password: values.password,
            });
            fetchJWT();
        } catch (error) {
            setErrors({
		email: error.response && error.response.data.detail ? error.response.data.detail : error.message
            });
	    setLoading(false);
        };
    };
    const fetchJWT = async () => {
        const { data } = await axiosInstance.post('token/', {
            email: values.email,
            password: values.password,
        });
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
        axiosInstance.defaults.headers['Authorization'] =
            'JWT ' + localStorage.getItem('access_token');
        window.location.href = '/dashboard';
	setComplete(true);
	setLoading(false);
    };
    useEffect(() => {
        if (!loading && !complete && Object.keys(errors).length === 0 && isSubmitting) {
            fetchLogin();
        };
    });
    return (
        <FormContainer>
            <FormWrap>
                <Form action='#' onSubmit={handleSubmit}>
                    <h1>{authDataLang.signinHeader}</h1>
                    <InputWrap>
                        <EmailIcon />
                        <FormInput
                            type='email'
                            name='email'
                            placeholder={authDataLang.emailPlaceHolder}
                            value={values.email}
                            onChange={handleChange}
                        />
                    </InputWrap>
                    {errors.email && <p>{errors.email}</p>}
                    <InputWrap>
                        <PasswordIcon />
                        <FormInput
                            type='password'
                            name='password'
                            placeholder={authDataLang.passwordPlaceHolder}
                            value={values.password}
                            onChange={handleChange}
                        />
                    </InputWrap>
                    {errors.password && <p>{errors.password}</p>}
	    	    {!loading && !complete ?
			<FormButton type='submit' primary={0}>{authDataLang.signinButton}</FormButton>
			: <Circle style={{ margin: '20px auto 0 auto', width: '30px', height: '30px' }} />
		    }
                    <ForgotPasswordWrap onClick={() => { dispatch(loginTabAction({ prop: false })); dispatch(resetPasswordAction({ prop: true })) }}>
                        <p>{authDataLang.forgotPass}</p>
                        {resetPass && <ResetPass />}
                    </ForgotPasswordWrap>
                </Form>
            </FormWrap>
        </FormContainer>
    )
}
export default SignIn;
