import styled from 'styled-components';


export const TypeBannerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0;
`
export const TypeBannerWrap = styled.div`
    display: grid;
    grid-column-gap: 35px;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0;
`
