import React, { useState } from 'react';
import { Card, ContentWrap, LeftStuff, TextContainer, TextWrap } from './CPXCardElements';
import { TinyButtonAnchor } from '../ButtonElements';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from '../Stack/RadialSeparators';
import { HelpIcon, HelpWrap } from '../HelpElements';

const CPXCard = ({ info, surveyNum }) => {
    const [showHelp, setShowHelp] = useState(false);
    const handleRightClick = (e) => {
	e.preventDefault();
    };
    const handleClick = () => {
	setShowHelp(!showHelp);
    };
    return (
	<>
	    <HelpWrap show={showHelp ? 1 : 0}>
	        <p>
	            User rating value (average) from 1 to 5 stars, 5 = best
	        </p>
	    </HelpWrap>
	    <Card primary={surveyNum % 2 === 0 ? 1 : 0}>
	        <ContentWrap>
	            <LeftStuff>
	                <div style={{ width: '60px', height: '60px' }}>
	                    <CircularProgressbarWithChildren
	                        value={info.statistics_rating_avg}
	                        maxValue={5}
	                        strokeWidth={4}
	                        text={`#${surveyNum}`}
	                        styles={buildStyles({
				    strokeLinecap: "butt",
				    textColor: 'rgba(1, 219, 187, 0.9)',
				    pathColor: 'rgba(1, 219, 187, 0.9)',
				    textSize: '1.4rem'
				})}
			    >
	                        <RadialSeparators
	                            count={5}
	                            style={{
					background: "#fff",
					width: "2px",
					height: `${4}%`
				    }}
	                        />
	                    </CircularProgressbarWithChildren>
	                </div>
	    		{surveyNum === 1 &&
			    <HelpIcon style={{ width: '14px', height: '14px' }} onClick={() => handleClick()} show={showHelp ? 1 : 0} />
			}
	    	    </LeftStuff>
	    	    <TextContainer top={info.top === 1 ? 1 : 0}>
	                {info.top === 1 &&
			    <TextWrap primary={surveyNum % 2 === 0 ? 1 : 0}>
				<h4>top</h4>
			    </TextWrap>
			}
	                <TextWrap primary={surveyNum % 2 === 0 ? 1 : 0}>
	                    <h4>{info.payout}</h4>
	                </TextWrap>
	            </TextContainer>
	        </ContentWrap>
	        <TinyButtonAnchor
	            href={info.href_new}
	            primary={surveyNum % 2 === 0 ? 1 : 0}
	            big={0}
	            onContextMenu={handleRightClick}
	        >
	            start
	        </TinyButtonAnchor>
	    </Card >
	</>
    )
}
export default CPXCard;
