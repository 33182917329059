import styled from 'styled-components';
import device from '../../constants/breakPoints';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export const CategoryWrap = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    background: rgba(39, 117, 85, 0.3);
    border: 1px solid rgba(39, 117, 85, 0.4);
    box-shadow: 0 0 20px rgba(39, 117, 85, 0.8);
    border-radius: 10px;
    padding: 15px 50px;
    margin-top: 25px;

    @media screen and ${device.sm} {
        padding: 15px;
    };
`
export const TitleWrap = styled.div`
    p {
        color: #fff;
        font-size: clamp(0.85rem, 2vw, 1rem);
        font-weight: 400;
        letter-spacing: 1px;
        word-spacing: 1.8px;
        text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
        text-align: center;
    }
`
export const ButtonsWrap = styled.div`
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 25px 0;
    @media screen and ${device.md} {
        grid-column-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 20px;
    };
    @media screen and ${device.sm} {
        grid-column-gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 20px;
    };
    @media screen and ${device.tn} {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    };
`
export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    cursor: pointer;
    text-align: center;
    margin: auto;

    @media screen and ${device.sm} {
        width: 55px;
        height: 55px;
    };
`
export const ButtonsName = styled.div`
    background: ${({ itemActive }) => (itemActive ? '#01DBBB' : 'rgba(1, 219, 187, 0.3)')};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: clamp(0.55rem, 1.8vw, 0.65rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    transition: 0.3s ease-in-out;

    &:hover {
        transform: scale(1.09);
        background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#277555')};
        transition: 0.3s ease-in-out;
    };
`
export const FAQWrap = styled.div`
    width: 90%;
    margin: auto;
    @media screen and ${device.sm} {
        width: 100%;
    };
`
export const QuestionWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.3)' : ' rgba(39, 117, 85, 0.3)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.4)' : ' rgba(39, 117, 85, 0.4)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : ' rgba(39, 117, 85, 0.8)')};
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    cursor: pointer;

    @media screen and ${device.sm} {
        padding: 10px 5px;
    };
`
export const Question = styled.div`
    width: 90%;
    h4 {
        color: rgb(1, 219, 187);
        text-shadow: 0 0 3px rgba(1, 219, 187, 0.7);
        font-size: clamp(0.85rem, 2.5vw, 1.1rem);
        font-weight: 400;
        letter-spacing: 1.2px;
        word-spacing: 1.7px;
        text-transform: uppercase;
    }

    @media screen and ${device.sm} {
        width: 100%;
    };
`
export const IconWrap = styled.div`
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const DownIcon = styled(IoIosArrowDown)`
    font-size: 20px;
    color: rgb(1, 219, 187);
`
export const UpIcon = styled(IoIosArrowUp)`
    font-size: 20px;
    color: rgb(1, 219, 187);
`
export const AnswerWrap = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.5)' : ' rgba(39, 117, 85, 0.5)')};
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : ' rgba(39, 117, 85, 0.6)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.9)' : ' rgba(39, 117, 85, 0.9)')};
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    display: ${({ active }) => (active ? 'block' : 'none')};

    @media screen and ${device.sm} {
        padding: 10px 5px;
    };

    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.9s;
    animation-name: fadeIn;
    animation-duration: 0.9s;

    @-webkit-keyframes fadeIn { 
        0% { opacity: 0.05; }
        10% { opacity: 0.22; }
        20% { opacity: 0.37; }
        30% { opacity: 0.50; }
        40% { opacity: 0.62; }
        50% { opacity: 0.73; }
        60% { opacity: 0.83; }
        70% { opacity: 0.90; }
        80% { opacity: 0.95; }
        90% { opacity: 0.98; }
        100% { opacity: 1; }
    };
      
    @keyframes fadeIn {
        0% { opacity: 0.05; }
        10% { opacity: 0.22; }
        20% { opacity: 0.37; }
        30% { opacity: 0.50; }
        40% { opacity: 0.62; }
        50% { opacity: 0.73; }
        60% { opacity: 0.83; }
        70% { opacity: 0.90; }
        80% { opacity: 0.95; }
        90% { opacity: 0.98; }
        100% { opacity: 1; }
    };
    p {
        color: #fff;
        text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
        font-size: clamp(0.85rem, 2.5vw, 0.95rem);
        letter-spacing: 1px;
        word-spacing: 1.2px;
    };
`